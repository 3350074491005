import React, {useState, useEffect} from 'react';
import axios from  'axios';
import { Auth, Hub } from 'aws-amplify';
import NativeSelect from '@material-ui/core/NativeSelect';
import "react-google-flight-datepicker/dist/main.css";
import { useDispatch, connect } from 'react-redux';
import { showLoader } from 'redux/actions/loader.actions';
import InputModal from './inputModal';
import * as Modal from 'redux/actions/modal.actions'
import * as APILI from 'helpers/apiLI';
import Switch from '@material-ui/core/Switch';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { getCampaign } from 'helpers/apiLI';
import { v4 } from 'uuid'
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button"
import '../moderation/style.scss'

const AddProfile = ({ userRol, title, showModal, _showAlertModal }) => {
    
    const currentUser = JSON.parse(localStorage.getItem('CurrentUser')) ?? [];
    const [email, setemail] = useState('')
    const [name, setname] = useState('')
    const [lastname, setlastname] = useState('')
    const [phoneNumber, setphoneNumber] = useState('')
    const [influencers, setInfluencers] = useState([])
    const [campaigns,setCampaigns] = useState([]);
    const [influencersData, setinfluencersData] = useState([])
    const [showInfluencers, setshowInfluencers] = useState(false)
    const [campaignData, setCampaignData] = useState({
        "name" : '',
        "id":'',
        "activity": [],
        "endDate" : '',
        "socialmedia" : [],
        "price": 0
    })
    const [influencerData, setinfluencerData] = useState({
        "id": "",
        "phoneNumber": "",
        "dateBirth": "",
        "status": "",
        "email": "",
        "country": "",
        "name": "",
        "gender": "",
        "language": "",
        "lastname": "",
        "direction": "",
        "username": ""
       }
    )
    const [activityData, setactivityData] = useState({
        "name": "",
        "quantity": "",
        "id": "",
        "activity": "",
        "price": "",
        "status": true
    })
    const [endDate,setendDate] =  useState('')
    const [influencerID,setinfluencerID] =  useState('')
    const [socialmedia,setsocialmedia] =  useState('')
    const [scope,setscope] =  useState('')
    const [price,setprice] =  useState('')
    const [campaignID,setcampaignID] =  useState('')
    const [showOn, setshowOn] = useState(false)  
    const dispatch = useDispatch()
   
    const getUser = () => {
    
        return Auth.currentAuthenticatedUser()
        .then((userData) => userData)
        .catch(() => console.log('Not signed in'))
    }
   const status = async (data) => {
        const url = data;
        let response = await axios.get(url);
        return response;
      }

    const saveProfile = async (influencerid, username,url, socialmedia, imageurl) => {
        let type = ''
        if (socialmedia==='TikTok'){
            type = 'Channel'
        }
        if (socialmedia==='Facebook'){
            type = 'FanPage'
        }
        if (socialmedia==='Instagram'){
            type = 'Profile'
        }
        if (socialmedia==='Twitter'){
            type = 'Wall'
        }
        const body = {
             id: v4(),
             userprofile: username,
             socialmedia: socialmedia,
             url: url,
             image: imageurl,
             influencerid: influencerid,
             status: 'Pendiente', 
             type: type,
             scope: 0,
             updatedAt: new Date(),
             createdAt: new Date()
         }

        throw await APILI.createProfile(body).then
         (res => {
            const bodyLog = {
                Tipo: "Insert",
                Accion: "Anclar nuevo perfil",
                Data: body,
                Historico: {},
                CodeHttp: res.status,
                UsuarioId: currentUser.username,
                UsuarioName: currentUser.attributes.name,
                Email: currentUser.attributes.email,
            }
            saveLog(bodyLog)
    

       }).catch(err => {

        const bodyLog = {
          Tipo: "Insert error",
          Accion: "Anclar nuevo perfil",
          Data: body,
          Historico: {},
          CodeHttp: err,
          UsuarioId: currentUser.username,
          UsuarioName: currentUser.attributes.name,
          Email: currentUser.attributes.email,
      }

      saveErrorLog(bodyLog)
             console.log(err);
         })




     }
    const MySwal = async (e, datos) => {
        e.preventDefault()
        Swal.fire({
            title: 'Agregar un perfil',
            html: `<p>${datos.id}</p>
            <input type="text" id="username" class="swal2-input" placeholder="Username">
            <select class="swal2-input" id="socialmedia" name="select">
            <option value="Facebook" selected>Facebook</option>
            <option value="TikTok" >TikTok</option>
            <option  value="Instagram">Instagram</option>
            <option  value="Twitter">Twitter</option>
            </select>
           `,
            confirmButtonText: 'Registrar',
            allowOutsideClick: false,   
            focusConfirm: true,
            preConfirm: () => {
              const username = Swal.getPopup().querySelector('#username').value
              const socialmedia = Swal.getPopup().querySelector('#socialmedia').value
              if (!username || !socialmedia) {
                Swal.showValidationMessage(`Ingrese un usuario y red social`)
              }
              return { username: username, socialmedia: socialmedia }
            }
          }).then((result) => { 
            let imageurl = ''
            let url = ''
              if ( result.value.socialmedia === 'Facebook'){
                 imageurl = `https://graph.facebook.com/${result.value.username}/picture?type=large`
                url = `https://facebook.com/${result.value.username}`
              }
              else if ( result.value.socialmedia === 'Tik Tok' || result.value.socialmedia === 'TikTok' ){
               url = `https://www.tiktok.com/@${result.value.username}`
             }
              else{
                url = `${result.value.username}`
              }
              
            
      
            
              Swal.fire({
                title: datos.id,
                
                icon: 'question',
                text: 'Usuario:, ' + result.value.username + ' Desea agregar este perfil?',
                imageUrl:imageurl,
                showCancelButton: true,
                showDenyButton: true,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: '#00CA72',
                denyButtonText: '#d33',
                denyButtonText:
              '<i class="fa fa-thumbs-down"></i>',
                confirmButtonText:
              '<i class="fa fa-thumbs-up"></i> Aprobar',
            }).then((resultado) => {
                /* Read more about isConfirmed, isDenied below */
                if (resultado.isConfirmed) {
                    saveProfile(datos.id, result.value.username,url,result.value.socialmedia, imageurl)     
                  Swal.fire({
                      title: 'Perfil  agregado!', 
                        icon: 'success',
                          timer: 1500})
                } else if (resultado.isDenied) {
                   
                    Swal.fire({
                        title: 'Perfil no agregado!', 
                          icon: 'error',
                            timer: 1500})
                }
              })
          })
          
      }

      const saveLog = async (body) => {
        try {
          APILI.createNewLog(body).then(res => {
            console.log('Log Creado')
          }).catch(err => {
          console.log('err', err) 
          })
          
      } catch (err) {
          console.log("No se pudo guardar el log", err)
      }
      }

      const saveErrorLog = async (body) => {
        try {
          APILI.createNewErrorLog(body).then(res => {
            console.log('Error log Creado')
          }).catch(err => {
          console.log('err', err) 
          })
          
      } catch (err) {
          console.log("No se pudo guardar el log de error", err)
      }
      }


    const saveTask = async () => {
        for (let i = 0; i <campaignData.activity.length; i++) {
            const body = {
                id: v4(),
                activity: campaignData.activity[i].activity,
                campaignName: campaignData.name,
                endDate: campaignData.endDate,
                userID: influencerData.id,
                status: 'Pendiente',
                socialmedia: campaignData.activity[i].name,
                scope: scope,
                price: campaignData.price,
                campaignID: campaignData.id,
                enable: true
            }
            
            throw await APILI.createTask(body).then
         (res => {
            const bodyLog = {
                Tipo: "Insert",
                Accion: "Crear nuevo perfil",
                Data: body,
                Historico: {},
                CodeHttp: res.status,
                UsuarioId: currentUser.username,
                UsuarioName: currentUser.attributes.name,
                Email: currentUser.attributes.email,
            }
            saveLog(bodyLog)

       }).catch(err => {
        const bodyLog = {
            Tipo: "Insert error",
            Accion: "Crear nuevo perfil",
            Data: body,
            Historico: {},
            CodeHttp: err,
            UsuarioId: currentUser.username,
            UsuarioName: currentUser.attributes.name,
            Email: currentUser.attributes.email,
        }

      saveErrorLog(bodyLog)
             console.log('error log res', err);
         })

        }
      
    }
    useEffect( () => {
        APILI.getCampaign().then(res => {
                if (res.data && res.data.campaigns && res.data.campaigns.length > 0) {                    
                    getCampaignView(res.data.campaigns, res.data.count)
                    console.log(res.data)
                }
            }).catch(console.error)
            
    }, []);

    const findInfluencerData = () => {
        
      
        let body = {
            'id': email,
            'name': name,
            'lastname': lastname,
            'phoneNumber': phoneNumber,
          }
          console.log(body);
        APILI.findInfluencer(body
          /*  { id: email, 
            name: name, 
            lastname: lastname, 
            phoneNumber: phoneNumber
        }*/).then(res => {
                if (res.data && res.data.influencer && res.data.influencer.length > 0) {                    
                    console.log(res)
                    getInfluencersData(res.data.influencer)
                    setshowInfluencers(true)
                                 } 
                    
            }).catch(console.error)
            
    };
    
    const getCampaignView = (data) => {
        setCampaigns (data)
    }

    const getInfluencersData = (data) => {
        setinfluencersData (data)
        console.log("daaaaaat",data)
    }

    const dataCamp = (dat) =>{
        setshowOn(true)
        let data = campaigns.filter(data => data.id ===dat)
       
       let objeto = {
            name: data[0].name,
            id: data[0].id,
            activity: data[0].activity,
            endDate : data[0].endDate,
            socialmedia: data[0].socialNetworks,
            price: data[0].reward
        }
        setCampaignData(objeto)
    }
    function amount(item){
        return item.scope;
        }

        function sum(prev, next){
        return prev + next;
        }

    const activityCamp = (dat) => {
        let data = campaignData.activity.filter(data => data.id ===dat)
        console.log(data, dat)
        let thisscope = 0
    
        let objeto ={
            "name": data[0].name,
        "quantity": data[0].quantity,
        "id": data[0].id,
        "activity": data[0].activity,
        "price": data[0].price,
        "status": true,
        "scope": thisscope
        }
        setactivityData(objeto)
        console.log(objeto)
        
    }
    const influencerCamp = (dat) =>{
        

       

        let data = influencers.filter(data => data.id ===dat)
        let scope = 0
    
        if (data.socialNetworks && data.socialNetworks.length>0){
             scope = data.socialNetworks.map.map(amount).reduce(sum,0)
        } 
        
        console.log(scope);
        let objeto = {
        id: data[0].id,
        dateBirth: data[0].dateBirth,
        status: data[0].status,
        name: data[0].name,
        gender: data[0].gender,
        language: data[0].language,
        lastname: data[0].lastname,
        direction: data[0].direction,
        username: data[0].username,
        socialNetworks: data[0].socialNetworks,
        scope: scope
        }
        setinfluencerData(objeto)
    }
    const getInfluencersInfoView = (camps) => {
        setInfluencers(camps)
    }
  
    
    const [state, setState] = React.useState({
        checkedA: false,
        checkedB: false,
      });
    
      console.log(campaignData)
      const [modifyProfile, setModifyProfile] = useState(false)
      const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        setModifyProfile(!modifyProfile)
      };
    
    return (
        <div className="blur-div">
            <div className="content-modal">
                <div className="closeModal-content">
                    <i className="fas fa-times-circle" onClick={e => showModal(false)}></i>
                </div>
                <div>
                    <h2 className="titleModal">{title}</h2>
                </div>
                <div className="body-modal">
                    <div className="part-BodyModal">
                    <p>Buscar Influenciadores</p>
                    <InputModal lockornot={true} title="Email" placeholder={email} value={email} change={setemail}  />
                    <InputModal lockornot={true} title="Nombre" placeholder={name} value={name} change={setname}  />
                    <InputModal lockornot={true} title="Apellido" placeholder={lastname} value={lastname} change={setlastname}  />
                    <InputModal lockornot={true} title="Telefono" placeholder={phoneNumber} value={phoneNumber} change={setphoneNumber}  />
                    
                    
                    <Button 
                      onClick={() => findInfluencerData()} 
                      variant="contained" 
                      className='btnSerach1'
                      
                    >
                        Buscar
                      </Button>
                    
                 </div>
     
                    <div className="part-BodyModal">
                    Resultado: 
                    {showInfluencers ?  
                    <div class="list-group">
                    { influencersData.map(datos => (
                      <div >  <span  onClick={e => MySwal(e, datos)} className="list-group-item list-group-item-action">{datos.name}{" "}{datos.lastname}{" "}{datos.email} <i className="fa fa-file-plus-fill"></i></span>
                      </div>
                    )
                      )}
                        
                        
                    </div>
                    : <p> No hay influencers seleccionados para mostrar</p> }
                   </div>
                </div>
                <div className="footer-Modal">
           
                    <div className="buttons-Modal-content">
                        <button onClick={e => showModal(false)}>Cancelar</button>
                        <button onClick={e => saveTask()}>Guardar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
    _showLoader: show => dispatch(showLoader(show)),
    _showAlertModal: (title, message, type, callback) => dispatch(Modal.showAlertModal(title, message, type, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddProfile)