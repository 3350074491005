import React, { useRef } from 'react'
import { connect } from 'react-redux'
import { Auth } from 'aws-amplify'
import { v4 } from 'uuid'

import './assets/css/styles.css'
import { useHistory, useLocation } from 'react-router-dom';
import grupo from './assets/img/grupo.png'

import * as Modal from 'redux/actions/modal.actions'
import { showLoader } from 'redux/actions/loader.actions'
import { addBudget } from 'redux/actions/dashboard.actions'
import { createBudget_logout } from 'redux/actions/budgets.actions'
import { createAccount_logout } from 'redux/actions/accounts.actions'
import { createCategory_logout } from 'redux/actions/categories.actions'
import { saveCategory, saveAccount } from 'redux/actions/campana.actions'
import SignInform from'./signin_form';

import { withUtils } from 'utils'
import swal from 'sweetalert2'

let counter=0
const TAG = 'ConfirmSignUpForm'

const ConfirmSignUpForm = props => {
  console.log('propsLogin', props)
  let code = useRef(null)
  const location = useLocation();
  const confirmSignUp = async e => {
    e.preventDefault()
    props._showLoader(true)
    let error = 0

    try {
      props.utils.log(TAG, 'code', code.current.value)
      let valor = await Auth.confirmSignUp(props.authData.username, code.current.value)
      console.log("desde Auth ",valor);
      console.log("desde props.authData.username ",props.authData.username);
      console.log("desde  props.authData.password ", props.authData.password);

      await Auth.signIn(props.authData.username, props.authData.password)
      props.utils.log(TAG, 'username', props.authData.username)
      props.utils.log(TAG, 'password', props.authData.password)

      // await Auth.signIn(props.authData.username, props.authData.password)

    //   let user = await Auth.currentAuthenticatedUser()
    //   console.log("user autenticacion",user)
      // let accountId = v4()

      // console.log("creacion de usuariooooooooooooo")

      // props._createAccount_logout(accountId,"General",user.username,0,null,null,null
      // ).then(result =>{

      //   props._saveAccount(accountId)

      //   let budgetId = v4()
      //   console.log("creacion de usuariooooooooooooo2")
      //   props._createBudget_logout(budgetId,"general",accountId,props.campaign.budget,null,null).then(result =>{
      //     props._addBudget(budgetId)
      //   })
      //   console.log("creacion de usuariooooooooooooo3")
      //   let categoryId = v4()
      //   props._createCategory_logout(categoryId,"General",accountId,null,null).then(result =>{
      //     props._saveCategory(categoryId)

      //   })
      // })
      // props.onStateChange('signedIn', {})
    } catch (err) {
      let { code } = err
      console.log('error confirm up', err)

      switch (code) {
        case 'CodeMismatchException':
          error +=1 
          swal.fire({
            icon: 'error',
            title: '¡Error!',
            html: 'El código que ingresaste es incorrecto. ¡Inténtalo de nuevo!',
            focusConfirm: false,
          })
          break
        default:
          swal.fire({
            icon: 'error',
            title: '¡Error!',
            html: 'Ocurrió un error inesperado',
            focusConfirm: false,
          })
         error +=1 
      }
    }

    props._showLoader(false)

    if (error>0) {
      // swal.fire({
      //   icon: 'error',
      //   title: '¡Error!',
      //   html: 'Ocurrió un error inesperado',
      //   focusConfirm: false,
      // })
      // props._showAlertModal('Error!', error, Modal.MODAL_TYPE_ERROR)
    }else{



      swal.fire({
        icon: 'success',
        title: 'Confirmado',
        html: props.message,
        focusConfirm: false,
      }).then((result)=>{
        props.onStateChange('signedIn', {})
        props._showLoader(false)
        // props.onStateChange('signUp', {})
      })
        // let modal = props._showAlertModal('Sucess!', "Confirmado",Modal.MODAL_TYPE_CONFIRMATION_LOGIN,        () => {
        //   window.location.reload(true);
        // });
    }
  }

  // const goToSignIn = e => {
  //   e.preventDefault()
  //   props.onStateChange('signIn', {})
  // }

  return(
    <div className="form-login">
      <div className="form-margin-login">
        <div className="lado">
          <h1 className="title-form">¡Confirma tu Cuenta!</h1>
          <p className="textos">Correo electr&oacute;nico</p>
          <input type="text" 
          value={props.authData.username} 
          // onChange={(e) => setEmailValue(e.target.value)}
          className="formInput" 
          placeholder="Ingresa tu correo electr&oacute;nico" />
          <p className="textos">C&oacute;digo de Confirmaci&oacute;n</p>
          <input ref={code} className="formInput" type="text" placeholder="Ingresa tu c&oacute;digo" />
          <span className="textos">Revisa tu correo electrónico registrado, te hemos enviado un código de confirmación. ¡Ingrésalo para confirmar tu cuenta!</span>
          <a onClick={(e) =>confirmSignUp(e)} className="btn-plus-form" href="/#">
            CONFIRMAR
          </a>
        </div>
        <div className="lado">
          <img src={grupo} className="people" alt="" />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  campaign: state.campaign,
})

const mapDispatchToProps = dispatch => ({
  _showLoader: show => dispatch(showLoader(show)),
  _showAlertModal: (title, message, type, callback) => dispatch(Modal.showAlertModal(title, message, type, callback)),

  _createAccount_logout: (id, name, username, credit, edit, createAt, members) => dispatch(createAccount_logout(id, name, username, credit, edit, createAt, members)),
  _createBudget_logout: (id, title, account, amount, edit, createAt) => dispatch(createBudget_logout(id, title, account, amount, edit, createAt)),
  _addBudget: (id) => dispatch(addBudget(id)),
  _createCategory_logout: (id, name, account, edit, createAt) => dispatch(createCategory_logout(id, name, account, edit, createAt)),
  _saveCategory: (category) => dispatch(saveCategory(category)),
  _saveAccount: (account) => dispatch(saveAccount(account)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withUtils(ConfirmSignUpForm))
