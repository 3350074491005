import React, {useState} from 'react'

import './assets/css/styles.css'

const GraphBox = (props) => {
    const {activeColor} = props
    
    return (
        <div className={activeColor ? `selectorBox clickActive` : `selectorBox`}>
            <i className={props.className} />
            <a>{props.text}</a>
        </div>
    )
}

export default GraphBox