import * as Modal from './modal.actions'

export const PWA_ACCOUNTS_SET_DEFAULT = 'PWA:ACCOUNTS:SET:DEFAULT'
export const PWA_ACCOUNTS_UPSERT = 'PWA:ACCOUNTS:UPSERT'

export const PWA_AUDIENCES_UPSERT = 'PWA:AUDIENCES:UPSERT'
export const PWA_AUDIENCES_REMOVE = 'PWA:AUDIENCES:REMOVE'

export const PWA_BUDGETS_UPSERT = 'PWA:BUDGETS:UPSERT'
export const PWA_BUDGETS_REMOVE = 'PWA:BUDGETS:REMOVE'

export const PWA_CATEGORIES_UPSERT = 'PWA:CATEGORIES:UPSERT'
export const PWA_CATEGORIES_REMOVE = 'PWA:CATEGORIES:REMOVE'

export const PWA_CREATIVE_UPLOAD_FILES = 'PWA:CREATIVE:UPLOAD:FILES'
export const PWA_CREATIVE_REMOVE = 'PWA:CREATIVE:REMOVE'

export const pwaSetDefault = (account) => ({
  type: PWA_ACCOUNTS_SET_DEFAULT,
  account,
})

export const pwaUpsertAccount = (id, name, phone, email, address, edit) => (dispatch) => {
  const now = Date.now()

  let account = {
    id,
    name,
    phone,
    email,
    address,
    credit: 0,
    owner: true,
    status: true,
    updateAt: now,
  }
  
  if (!edit) {
    account = {
      ...account,
      createAt: now,
    }
  }

  dispatch({
    type: PWA_ACCOUNTS_UPSERT,
    account,
  })

  const title = edit ? 'Cuenta Editada' : 'Cuenta Creada'
  const message = edit
    ? 'La cuenta fue editada exitosamente.'
    : 'La cuenta fue creada exitosamente.'

  dispatch(
    Modal.showAlertModal(title, message, Modal.MODAL_TYPE_SUCCESS, () => {
      dispatch(pwaSetDefault(id))
    })
  )
}

export const pwaUpsertBudget = (id, title, account, amount, tags, status, edit) => (dispatch) => {
  const now = Date.now()

  let budget = {
    id,
    tags,
    title,
    amount,
    status,
    account,
    available: amount,
    updateAt: now,
  }

  if (!edit) {
    budget = {
      ...budget,
      createAt: now,
    }
  }

  dispatch({
    type: PWA_BUDGETS_UPSERT,
    budget,
  })

  const mTitle = edit ? 'Presupuesto Editado' : 'Presupuesto Creado'
  const message = edit
    ? 'El presupuesto fue editado exitosamente.'
    : 'El presupuesto fue creado exitosamente.'

  dispatch(
    Modal.showAlertModal(mTitle, message, Modal.MODAL_TYPE_SUCCESS, () => {})
  )
}

export const pwaUpsertCategory = (id, name, account, edit) => (dispatch) => {
  const now = Date.now()

  let category = {
    id,
    name,
    account,
    updateAt: now,
  }

  if (!edit) {
    category = {
      ...category,
      createAt: now,
    }
  }

  dispatch({
    type: PWA_CATEGORIES_UPSERT,
    category,
  })

  const title = edit ? 'Categoría Editada' : 'Categoría Creada'
  const message = edit
    ? 'El categoría fue editada exitosamente.'
    : 'El categoría fue creada exitosamente.'

  dispatch(
    Modal.showAlertModal(title, message, Modal.MODAL_TYPE_SUCCESS, () => {})
  )
}

export const pwaUpsertAudience = (id, name, account, tags, edit) => (dispatch) => {
  const now = Date.now()

  let audience = {
    id,
    name,
    account,
    tags,
    updateAt: now,
  }

  if (!edit) {
    audience = {
      ...audience,
      createAt: now,
    }
  }

  dispatch({
    type: PWA_AUDIENCES_UPSERT,
    audience,
  })

  const title = edit ? 'Público Editado' : 'Público Creado'
  const message = edit
    ? 'El público fue editado exitosamente.'
    : 'El público fue creado exitosamente.'

  dispatch(
    Modal.showAlertModal(title, message, Modal.MODAL_TYPE_SUCCESS, () => {})
  )
}

export const pwaUploadFiles = (files, account) => async (dispatch) => {
  console.log('PWA', 'pwaUploadFiles')
  console.log('PWA', 'files', files)
  
  for (const file of files) {
    console.log('PWA', 'file', file)
    const fileInfo = {
      id: file.path,
      url: file.path,
      name: file.name,
      size: file.size,
      type: file.type,
      lastModified: file.lastModifiedDate,
      data: await getBase64Image(file),
      account,
    }

    dispatch({
      type: PWA_CREATIVE_UPLOAD_FILES,
      fileInfo,
    })
  }

  dispatch(
    Modal.showAlertModal(
      'Archivos Subidos',
      'Los archivos fueron subidos exitosamente.',
      Modal.MODAL_TYPE_SUCCESS,
      () => {}
    )
  )
}

export const pwaDeleteBudget = (id) => ({
  type: PWA_BUDGETS_REMOVE,
  id,
})

export const pwaDeleteCategory = (id) => ({
  type: PWA_CATEGORIES_REMOVE,
  id,
})

export const pwaDeleteAudience = (id) => ({
  type: PWA_AUDIENCES_REMOVE,
  id,
})

export const pwaDeleteFile = (id) => ({
  type: PWA_CREATIVE_REMOVE,
  id,
})


const getBase64Image = (img) => {
  return new Promise((resolve, reject) => {
    let fr = new FileReader()
    
    fr.onload = () => {
      console.log('PWA', 'result', fr.result)
      resolve(fr.result)
    }

    fr.onerror = reject

    fr.readAsDataURL(img)
  })
}