/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'


import './assets/css/styles.css'

const Home = props => {
  const [counter, setCounter] = useState(0)    
  /* console.log(setCounter) */
  const componentes = ({ counter }) => {
      return (
        <div className='form'>
          <div className='form-margin'>
            <div className='text-container text-home'>
              <p className='subTitle'>Te damos la bienvenida a Tru</p>
            
              <h1 className='title'>Vamos a empezar</h1>
            </div>
          </div>
        </div>
      )
  }

  return(componentes({ counter }))
}

export default Home