import React, {useState, useEffect} from 'react';
import { MapContainer, useMapEvents, Marker, TileLayer, Popup,
    CircleMarker, useMap } from 'react-leaflet'
    import { makeStyles } from '@material-ui/core/styles';
  import ZoomInIcon from '@material-ui/icons/ZoomIn';
  import ZoomOutIcon from '@material-ui/icons/ZoomOut';
  import IconButton from '@material-ui/core/IconButton';
  import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
  import Slider from '@material-ui/core/Slider';
  
  import "leaflet/dist/leaflet.css";
  import L from "leaflet";
  import { v4 } from 'uuid'
  import "./styles.css";
  
  
  
  
  const useStyles = makeStyles({
    root: {
      height: 300,
    },
  });
  
  const icon = L.icon({
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    iconUrl: "https://unpkg.com/leaflet@1.7/dist/images/marker-icon.png",
    shadowUrl: "https://unpkg.com/leaflet@1.7/dist/images/marker-shadow.png"
  });
  
  
export default function AddMarkerToClick(props) {
    const {markers,setMarkers} = props
    const [zoomValue, setZoomValue] = useState(12)
  const [counter, setCounter] = useState(0);
  const [operator, setOperator] = useState(false)
  const [rangeSlider, setRangeSlider] = useState([20, 37]);
  const [pixelPerMeter, setPixelPerMeter] = useState(0)
  const [zoomVariant,setZoomVariant] = useState(1)
  const [maxRange,setMaxRange] = useState(200)
const redOptions = { color: 'orangered' }
  
  const increase = (e, marker) => {
    const indexToUpdate = markers.findIndex((todo) => todo.uid === marker.uid);
    const updatedTodos = [...markers];

    updatedTodos[indexToUpdate].range = marker.range;
    setMarkers(updatedTodos);   
 
  }
  
  const classes = useStyles();
  const handleChange  = (e, marker) => {
    e.preventDefault();
    console.log('Valor cordenada',e.target)
    console.log('Valor market',marker)
    const indexToUpdate = markers.findIndex((todo) => todo.uid === marker.uid);
    const updatedTodos = [...markers];

    if (e.target.ariaValueNow){
        updatedTodos[indexToUpdate].range = e.target.ariaValueNow;
    }
    
    setMarkers(updatedTodos);  
    
  };

  function valuetext(value) {
    return value;
  }

 
  
  const decrease = (e, marker) => {
    const indexToUpdate = markers.findIndex((todo) => todo.uid === marker.uid);
    const updatedTodos = [...markers];

    if (marker.range>0){
    updatedTodos[indexToUpdate].range = marker.range - 10;
    }
    setMarkers(updatedTodos);   
    
  }

  const deleteMarker = (e, marker) => {
    

      const newMarks = markers.filter(tag => tag.uid !== marker.uid)
      
      setMarkers(newMarks)
    
    
  }

  const map = useMapEvents({

        zoomend: (e) => {
          console.log("current zoom", zoomValue)
          console.log("new Zoom", e.target._zoom)
          if (zoomValue<e.target._zoom){
            setZoomValue(e.target._zoom)
            setZoomVariant(zoomVariant*2)
            console.log("maxRange", maxRange)
            setMaxRange(maxRange/2)
            
            
            
            
          }
          else{
            setZoomValue(e.target._zoom)
            setZoomVariant(zoomVariant/2)
            console.log("maxRange", maxRange)
            setMaxRange(maxRange*2)
            
          }
        },
        
          click(e) {
          console.log("click",e.latlng)
         
          const newMarker = e.latlng
          const zoomlevel = e.target._zoom
          const metros = zoomArray[zoomlevel].mtperpix
          setPixelPerMeter(zoomArray[e.target._zoom].mtperpix); 
          setMarkers([...markers, {
            uid: v4(),
            lat: newMarker.lat, 
            lng: newMarker.lng,
            range: counter,
            radius: parseInt(counter*metros*2/1000),
            zoom: zoomlevel,
            metersperpixel: metros}]);  
        },
  })



      return (
        <>
          {markers.map(marker => 
            <CircleMarker data="customdata" center={marker} pathOptions={redOptions} 
            radius={  zoomValue===marker.zoom ?marker.range*zoomVariant : marker.range*zoomVariant}
          stroke={false}>
          <Marker position={marker} icon={icon}>
          <Popup autoClose="false">
          <p>Radius Map</p>
          <span>{parseInt(marker.range*marker.metersperpixel*2/1000)+" KM"}</span>
          <IconButton className="d-flex justify-content-center"
            color="secondary" aria-label="upload picture" component="span"
            onClick={(e) => 
              {deleteMarker(e, marker)}}>
            <DeleteForeverIcon />
          </IconButton>
          <Slider
          defaultValue={20}
          onChange={(e) => 
            {handleChange(e, marker)}}
          getAriaValueText={valuetext}
          aria-labelledby="discrete-slider-custom"
          step={1}
          min={1}
          max={maxRange}
        
          valueLabelDisplay="off"
         
        />
         </Popup>
          </Marker>
          </CircleMarker>
          )}
        </>
      )
    }
    
    const zoomArray = [
      { zoom: 0, mtperpix: 	156412 },
      { zoom: 1, mtperpix: 78206 },
      { zoom: 2, mtperpix: 39103 },
      { zoom: 3, mtperpix: 19551 },
      { zoom: 4, mtperpix: 9776 },
      { zoom: 5, mtperpix: 4888 },
      { zoom: 6, mtperpix: 2444 },
      { zoom: 7, mtperpix: 1222 },
      { zoom: 8, mtperpix: 610.984 },
      { zoom: 9, mtperpix: 305.492},
      { zoom: 10, mtperpix: 152.746 },
      { zoom: 11, mtperpix: 76.373 },
      { zoom: 12, mtperpix: 38.187 },
      { zoom: 13, mtperpix: 19.093 },
      { zoom: 14, mtperpix: 9.547 },
      { zoom: 15, mtperpix: 4.773 },
      { zoom: 16, mtperpix: 2.387 },
      { zoom: 17, mtperpix: 1.193 },
      { zoom: 18, mtperpix: 0.596 },
      { zoom: 19, mtperpix: 0.298 },
      { zoom: 20, mtperpix: 0.149 }
       ];