//for list

export const listGeneralStatistics = [
    {
      text: "Influencers actiaavos: ",
      number: "1,296"
    },
    {
      text: "Influencers rechazados: ",
      number: "1,215"
    },
    {
      text: "Influencers banneados: ",
      number: "0"
    },
    {
      text: "Presupuesto de la campaña promedio: ",
      number: "110,733.49"
    },
    {
      text: "CPM base: ",
      number: "25.00"
    }
  ]

  export const campaignsByState = [
    {
      text: "Pendiente: ",
      number: "0"
    },
    {
      text: "Activo: ",
      number: "15"
    },
    {
      text: "Pausado: ",
      number: "3"
    },
    {
      text: "Inactivo: ",
      number: "4"
    },
    {
      text: "Finalizado: ",
      number: "425"
    }
  ]

  export const dataGraphUsers = {
    labels: ['16 - 17 años', '18 - 24 años', '25 - 29 años',
      '30 - 35 años', '36 - + años'],
    datasets: [
      {
        label: 'Rainfall',
        backgroundColor: [
          '#FF3D57',
          '#FDAB3D',
          '#00CA72',
          '#009AFF',
          '#794FCF'
        ],
        hoverBackgroundColor: [
          '#501800',
          '#4B5000',
          '#175000',
          '#003350',
          '#35014F'
        ],
        data: [30, 25, 25, 15, 5]
      }
    ]
  }

  export const dataUsersAge = {
    range: [
      {ageRange: "16 - 17 años",color:'color1'}, 
      {ageRange: '18 - 24 años',color:'color2'}, 
      {ageRange: '25 - 29 años',color:'color3'},
      {ageRange: '30 - 35 años',color:'color4'}, 
      {ageRange: '36 - + años',color:'color5'}],
    porcentage: [30, 25, 25, 15, 5],
    gender: [
      {
        women: 581,
        mens: 581,
        color:'color1'
      },
      {
        women: 581,
        mens: 581,
        color:'color2'
      },
      {
        women: 581,
        mens: 581,
        color:'color3'
      },
      {
        women: 581,
        mens: 581,
        color:'color4'
      },
      {
        women: 581,
        mens: 581,
        color:'color5'
      }
    ]
  }

  export const postByState = [
    {
        text: "Aceptado: ",
        number: "96"
    },
    {
        text: "Pendiente: ",
        number: "19"
    },
    {
        text: "Rechazado",
        number: "1,854"
    }
]

  export let myContacts = []