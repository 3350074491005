import React from 'react'
import { Link} from "react-router-dom";
//import './style/style.css'

// components
// import Home from 'components/home'
import MetaTags from 'components/metatags'
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';


const MenuPage = () => (
    <>
        <MetaTags title='Escaner' description='Escaner de páginas' />

       
        <ButtonGroup className="m-5" variant="contained" aria-label="outlined primary button group">
        <Link  to="scan/asociar"><Button>Asociar Fanpage</Button></Link>
            <Link  to="scan/datos"><Button>Ver datos Fanpage</Button></Link>
            <Link to="scan/mensajes"><Button>Asociar Perfil FB/IG</Button></Link>
            <Link  to="scan/datos-web"><Button>Ver Datos IG</Button></Link>
    </ButtonGroup>
           
        
    </>
)

export default MenuPage
