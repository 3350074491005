export const ADD_USER = 'ADD_USER'
export const GET_USER = 'GET_USER'

export const addUser = (myUser) => ({
    type: ADD_USER,
    myUser    
}) 

export const getMyUser = () => ({
    type: GET_USER
})