import thunk from 'redux-thunk'
import storage from 'redux-persist/lib/storage'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore, persistReducer } from 'redux-persist'
import { createStore, applyMiddleware } from 'redux'

import rootReducer from './reducers/root.reducer'

const persistConfig = {
  key: 'lets-advertise',
  storage: storage,
  whitelist: ['pwa', 'accounts'],
}

const pReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
  pReducer,
  undefined,
  composeWithDevTools(applyMiddleware(thunk))
)

const persistor = persistStore(store)

export { persistor, store }
