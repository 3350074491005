import React from 'react'

// components
import Dashboard from 'components/resume'

const DashboardPage = (props) => {
  const {userRol} = props;
  return (
    <>
  {userRol==='admin'|| userRol==='anunciante'?
    <Dashboard />:
    <h1>No tienes privilegios</h1>
    }
    </>
    )  
  }

export default DashboardPage
