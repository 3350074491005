import React, { useState, useEffect } from 'react'
import * as utils from 'data.js'
import { connect } from 'react-redux';
import { showLoader } from 'redux/actions/loader.actions';
import { Auth } from 'aws-amplify';
import * as STORAGE from 'helpers/storageLI';
import _ from 'lodash'
import { getLogosListByNames, getClassStyleByStatus } from 'myUtil'

//Components
import ImageBox from 'components/imageBox'
import TargetContent from 'components/targetContent'
import Info from 'components/campaign/info'
import DataTableUtils,
{
  Share,
  ShowColumn,
  FilterColumn
} from '@easygosanet/react-datatable-utils'
import ReactDataTable, { DateRange } from '@easygosanet/react-datatable'

import './style.scss'

//images
import Logo_facebook from './assets/img/logo_facebook.svg'
import Logo_instagram from './assets/img/logo_instagram.png'
import Logo_tiktok from './assets/img/logo_tiktok.svg'
import logo_whatsapp from './assets/img/whatsapp.png'
import NewMediaCategory from 'components/newMediaCategory'
import orderBy from'lodash/orderBy'
//some code
import * as APILI from 'helpers/apiLI';
import NewCampaign from 'components/newCampaign';
import { CSVLink, CSVDownload } from "react-csv";
import * as Modal from 'redux/actions/modal.actions'
const DeliveryCol = ({ text, className, onClick }) => (
    <div className={"deliverycampaign" + (text)}>
      {text}
    </div>
)

const Moderation = (props) => {
  // console.log("en teoria si viene",props)
  const {myUser} = props;
  // console.log("en teoria si viene props",props)
  
  // const {userRol} = "admin".attributes['custom:userRol'];
   const userRol = myUser['custom:userRol'];
  // console.log("en teoria si viene myUser",myUser['custom:userRol'])
  // console.log("en teoria si viene userRol",userRol)
  const [filterText, setFilterText] = useState('');
  const currentUser = JSON.parse(localStorage.getItem('CurrentUser')) ?? [];
  const [showModal, setshowModal] = useState(false);
  const [showModalCampaing, setshowModalCampaing] = useState(false);
  const [campaings, setCampaings] = useState([]);
  const [editCamp, setEditCamp] = useState(null);
  const [campaingFacebook, setCampaingFacebook] = useState(0);
  const [campaingInstagram, setCampaingInstagram] = useState(0);
  const [campaingTikTok, setCampaingTikTok] = useState(0);
  const [campaingLinkedin, setCampaingLinkedin] = useState(0);
  const [refresh,setRefresh] = useState(false)
  const [filters, setFilters] = useState({})
  const [fbReach,setFbReach]=useState('')
  const [instaReach,setInstaReach]=useState('')
  const [fbInteraction,setFbInteraction]= useState('')
  const [instaInteraction,setInstaInteraction]=useState('')
  const [fbCount,setFbCount]=useState(0)
  const [instaCount,setInstaCount]=useState(0)
  const [tiktokCount,setTiktokCount]=useState(0)
  const [whatsappCount,setWhatsappCount]=useState(0)
  const [postState,setPostsState]= useState([])
  const [generalData,setGeneralData]= useState([])
  const [coords,setCoords] = useState({})
  
  const [window, setWindow] = useState(1);
  const [currentCampaign, setCurrentCampaign] = useState({})
  const [categories, setCategories] = useState([]);
  const [hashtags, setHashtags] = useState([]);
  const [infoCampaign, setInfoCampaign] = useState([])
  const [campaignNormal,setCampaignNormal] = useState([])
  const [fCamp, setFCamp] = useState([])
  //for table
  function formatNumberCurrency(number) {
    return new Intl.NumberFormat("en-US", {style: 'currency',currency: 'USD', minimumFractionDigits: 2}).format(number)
  }
  function formatNumber(number) {
    return new Intl.NumberFormat("es-MX").format(number)
  }
  function getTotalInteraction(reactionsWebHook,saves){
    let total= reactionsWebHook + saves
    return total
  }
  function getTotalInteractionPercent(totalInteractions,actualImpact){
    if(actualImpact===0){
      return 0;
    }
    if(totalInteractions==0){
      return 0;
    }
    let total= (actualImpact/totalInteractions)*100
    total= formatNumber(total)
    return total
  }

  function getConsumidoPercent(actualImpact,contractedImpact){
    if(contractedImpact===0){
      return 0;
    }
    if(actualImpact===0){
      return 0;
    }
    let total= (actualImpact/contractedImpact)*100
    return total
  }
  const getAdvertiserName = async (id) =>{
    let res = await APILI.getAdvertiserById({id:id})
    return res.data.advertiser[0].name
  }

  function getNameState(status) {
    switch (status) {
        case "Pendiente":
            return "Pendiente"
        case "Active":
             return "Activa"
                
        case "Finalizada":
            return "Finalizada"
        case "active":
            return "Activa"
        case "En Revision":
            return "En revisión"
        case "rejected":
        return "Rechazado"
        case "Rejected":
            return "Rechazado"
        case "Completado":
                return "Completado"
        case "Finalizado":
        return "Finalizado"
        case "Cerrada":
                return "Cerrada"
        default:
            return "Desconocido"
    }
}

  const [columns, setColumns] = useState([
    {
      id: 'id',
      name: 'ID',
      selector: 'id',
      sortable: true,
      omit:false,
      minWidth: '200px',
      style: {
        color: '#02025b',
      },
      cell: (row) => (
        <DetailCol
          text={row.id}
          Camp={row}
        />
      ),
    },
    {
      id: 'advertiserName',
      name: 'Anunciante',
      selector: 'advertiserName',
      sortable: true,
      omit:false,
      center: true,
      style: {
        fontWeight: 'bold',
      },
    },
    {
      id: 'name',
      name: 'Nombre Campaña',
      selector: 'name',
      sortable: true,
      omit:false,
      center: true,
      minWidth: '200px',
      style: {
        color: '#02025b',
      }
    },
    {
      id: 'source',
      name: 'Red Social',
      selector: 'source',
      sortable: true,
      omit:false,
      center: true,
      style: {
        fontWeight: 'bold',
      },
      cell: (row) => <div>
        {row.source.map(rowa => (
          <img className="source-img" src={rowa} alt="" />
        ))}
      </div>
    },
    {
      id: 'date',
      name: 'Rango de fecha',
      selector: 'date',
      sortable: true,
      omit:false,
      center: true,
      minWidth: '250px',
      style: {
        fontWeight: 'bold',
      },
      type: "text",
      cell: (row) => (
        <DateRange
          start={new Date(getDate(row.date.start))}
          end={new Date(getDate(row.date.end))}
          format='dd/mm/yyyy'
        />
      )
    },
    {
      id: 'budget',
      name: 'Presupuesto',
      selector: 'budget',
      sortable: true,
      omit:false,
      center: true,
      type: "text",
      style: {
        fontWeight: 'bold',
      },
    },
    {
      id: 'delivery',
      name: 'Status',
      selector: 'delivery',
      sortable: true,
      omit:false,
      center: true,
      style: {
        fontWeight: 'bold',
      },
      type: 'text',
      cell: (row) => (
        <DeliveryCol
          text={row.delivery.name}
          className={row.delivery.className}
        />
      )
    },
    {
      id: 'scope',
      name: 'Impactos contratados',
      selector: 'scope',
      sortable: true,
      omit:false,
      center: true,
      style: {
        fontWeight: 'bold',
      },
    },
    {
      id: 'actualReach',
      name: 'Impactos actuales',
      selector: 'actualReach',
      sortable: true,
      omit:false,
      center: true,
      style: {
        fontWeight: 'bold',
      },
    },
    {
      id: 'actualInteracciones',
      name: 'Interacciones totales',
      selector: 'actualInteracciones',
      sortable: true,
      omit:false,
      center: true,
      style: {
        fontWeight: 'bold',
      },
    },
    {
      id: 'interaccionTasa',
      name: 'Tasa de interacción',
      selector: 'interaccionTasa',
      sortable: true,
      omit:false,
      center: true,
      style: {
        fontWeight: 'bold',
      },
    },
    {
      id: 'approvedTruers',
      name: 'Truers',
      selector: 'approvedTruers',
      sortable: true,
      omit:false,
      center: true,
      style: {
        fontWeight: 'bold',
      },
    },
    {
      id: 'consumido',
      name: 'Consumido',
      selector: 'consumido',
      sortable: true,
      omit:false,
      center: true,
      style: {
        fontWeight: 'bold',
      },
    },
  ])

  useEffect(() => {
    props._showLoader(true)
    Auth.currentAuthenticatedUser()
      .then((userData) => {
        if(userData.attributes["custom:userRol"]==='admin'){
          APILI.getCampaignList()
          // APILI.getCampaignByModerator({ moderator: userData.username })
             .then(res => {
               let arrayData= res.data.campaigns
                   arrayData.forEach(element => {
                       let updated = element.initDate.split(['/'])
                       let newDate = updated[1]+'/'+updated[0]+'/'+updated[2];
                       element.initDate = newDate;
                   })
   
                   for (let x = 0; x < arrayData.length; x++) {
                     for (let y = 0; y < arrayData.length - 1; y++) {
                         let elementoActual = arrayData[y];
                         let elementoSiguiente = arrayData[y + 1];
                         if (new Date(elementoActual.initDate) < new Date(elementoSiguiente.initDate)) {
                             arrayData[y] = elementoSiguiente;
                             arrayData[y + 1] = elementoActual;
                         }
                     }
                   }
                   arrayData.forEach(element => {
                     let updated = element.initDate.split(['/'])
                     let newDate = updated[1]+'/'+updated[0]+'/'+updated[2];
                     element.initDate = newDate;
                 })
               getInfoView(arrayData)
               getStadistics()
             })
             .catch(console.error)
        }
        else{
          console.log('ES ANUNCIANTE', userData)
          APILI.getCampaignByUser({ account: userData.username })
          .then((res) => {
            let arrayData= res.data.campaigns
                   arrayData.forEach(element => {
                       let updated = element.initDate.split(['/'])
                       let newDate = updated[1]+'/'+updated[0]+'/'+updated[2];
                       element.initDate = newDate;
                   })
   
                   for (let x = 0; x < arrayData.length; x++) {
                     for (let y = 0; y < arrayData.length - 1; y++) {
                         let elementoActual = arrayData[y];
                         let elementoSiguiente = arrayData[y + 1];
                         if (new Date(elementoActual.initDate) < new Date(elementoSiguiente.initDate)) {
                             arrayData[y] = elementoSiguiente;
                             arrayData[y + 1] = elementoActual;
                         }
                     }
                   }
                   arrayData.forEach(element => {
                     let updated = element.initDate.split(['/'])
                     let newDate = updated[1]+'/'+updated[0]+'/'+updated[2];
                     element.initDate = newDate;
                 })
               getInfoView(arrayData)
               getStadistics()
               props._showLoader(false)
            
          })
          .catch(console.error);
        }
        // console.log("username",userData.username)
       
      })
      .catch(() =>  props._showLoader(false))
    }, [refresh]);


  const getStadistics = () => {
    APILI.stadisticBySocialMediaCampaing()
      .then(res => {
        /* setFbReach(res.data.campaigns[0].reachAvg) */
        setFbReach(0)
       /*  setFbInteraction(res.data.campaigns[0].interactionAvg) */
        setFbInteraction(0)
        setFbCount(res.data.campaigns[0].count)
        /* setInstaReach(res.data.campaigns[1].reachAvg) */
        setInstaReach(0)
        /* setInstaInteraction(res.data.campaigns[1].interactionAvg) */
        setInstaInteraction(0)
        setInstaCount(res.data.campaigns[1].count)
        setTiktokCount(res.data.campaigns[2].count)
        setWhatsappCount(res.data.campaigns[3].count)
      })
      .catch(console.error)

    APILI.postActivity().then(res => {
      setPostsState([
        {
          text: "Pendiente: ",
          number: res.data.task[1].data
        },
        {
          text: "Activo: ",
          number:  res.data.task[0].data
        },
        {
          text: "Rechazados: ",
          number: res.data.task[2].data
        },
        {
          text: "Finalizado: ",
          number: res.data.task[3].data
        }
      ])
    })
    APILI.getGeneralStadisticsInfluencerAndCampaign().then(res => {
      setGeneralData([
        {
          text: "Truers activos: ",
          number: res.data.generalData[0].data
        },
        {
          text: "Truers rechazados: ",
          number: res.data.generalData[2].data
        },
        {
          text: "Truers banneados: ",
          number: res.data.generalData[3].data
        },
        userRol=='admin' &&
        {
          text: "Presupuesto de la campaña promedio: ",
          number: formatNumberCurrency(res.data.generalData[4].data > 0 ? res.data.generalData[4].data : 0)
        },
        userRol=='admin' &&
        {
          text: "CPM base: ",
          number: formatNumberCurrency(res.data.generalData[5].data > 0 ? res.data.generalData[5].data : 0)
        }
      ])
    })
  }

  const handleFilterPerColumn = (column, type, value) => {
    setFilters((prev) => ({ ...prev, [column]: { type, value } }))
  }

  const handleShowColumnChange = (column, show) => {
    let index = columns.findIndex((col) => col.selector === column)
    let aux = columns.map((col) => col)
    aux[index].omit = show
    setColumns(aux)
  }

  const handleColumnSortChange = (positions) => {

    let aux = columns.map((col) => col)

    positions.forEach((columnId, position) => {
      let index = columns.findIndex((col) => col.selector === columnId)
      aux[index].position = position
    })
    aux = _.orderBy(aux, ['position'])

    setColumns(aux)
  }

  function getDate(initDate) {
    var initial = initDate.split(/\//);
    var date = initial[1] + '/' + initial[0] + '/' + initial[2]
    // console.log("fechassss: ", date)
    return date;
  }

  const goToDetailPage = (camp, original) => {
    console.log('camp recipt', camp)
    setCurrentCampaign(camp)
    // console.log("camp ", camp)
    const category = camp.mediaCategories
    // console.log("categ ", category)
    setInfoCampaign([
      {
        text: "Estado: ",
        number: camp.delivery.name
      },
      {
        text: "Presupuesto: ",
        number: camp.budget,
      },
      {
        text: "Recompensa: ",
        number: camp.reward
      },
      {
        text: "Inscripcion: ",
        number: camp.inscription
      },
      {
        text: "Fecha de Inicio: ",
        number: camp.date.start
      },
      {
        text: "Fecha de Finalización: ",
        number: camp.date.end
      }
    ])
   setCategories(category.map(categ => ({
      name: categ.name,
      operador: categ.operador,
      quantity: categ.quantity,
      uid: categ.uid
    })))
    setHashtags(camp.hastags.map(hash => ({
      text: hash.name,
      number: ""
    })))
    setCoords(camp.locationList.map(data => ({
      text: data.locationList,
      number: ""
    })))
    setWindow(0);
  }

  const saveLog = async (body) => {
    try {
      APILI.createNewLog(body).then(res => {
        console.log('Log Creado')
      }).catch(err => {
      console.log('err', err) 
      })
      
  } catch (err) {
      console.log("No se pudo guardar el log", err)
  }
  }


  
  const saveErrorLog = async (body) => {
    try {
      APILI.createNewErrorLog(body).then(res => {
        console.log('Error log Creado')
      }).catch(err => {
      console.log('err', err) 
      })
      
  } catch (err) {
      console.log("No se pudo guardar el log de error", err)
  }
  }

  
  const removeCampaing = (camp) => {
    console.log("camp to delete",camp)
    console.log("remove state",camp.id)
    props._showAlertModal('Eliminar Campaña?', `Esta seguro que desea eliminar la campaña`, Modal.MODAL_TYPE_CONFIRMATION, () => {
        APILI.deleteCampaing({id: camp.id}).then(res => {
          const bodyLog = {
            Tipo: "Delete",
            Accion: "Eliminar campaña",
            Data: {"id":camp.id, "name":camp.name},
            Historico: camp,
            CodeHttp:  res.status,
            UsuarioId: currentUser.username,
            UsuarioName: currentUser.attributes.name,
            Email: currentUser.attributes.email,
        }
        saveLog(bodyLog)
        console.log('Eliminado')
        props._showLoader(true);
        APILI.getCampaignList()
            .then((res) => {
              console.log('data', res.data)
              setRefresh(res.data.campaigns)
              props._showLoader(false);
            })
            .catch(console.error);
      }).catch(err => {
        const bodyLog = {
            Tipo: "Delete error",
            Accion: "Eliminar campaña",
            Data: {"id":camp.id, "name":camp.name},
            Historico: camp,
            CodeHttp:  err,
            UsuarioId: currentUser.username,
            UsuarioName: currentUser.attributes.name,
            Email: currentUser.attributes.email,
        }
        saveErrorLog(bodyLog)
      console.log('err', err) 
      })
      
    })
  }


  const DetailCol = ({ text, Camp }) => (
    <div className="firstColl w-100">
      <div className="icon-container">
        <i class="fas fa-eye" onClick={e => {
          goToDetailPage(Camp, Camp.original)
        }}></i>
        { userRol === "admin" &&
        <>
        <i className="fas fa-edit" onClick={e => {
          console.log('campaing rtecipts', Camp)
          setEditCamp(Camp)
          // console.log('camp a editar', Camp)
          setshowModalCampaing(true)
        }}></i>
        <i class="fas fa-trash"  onClick={() => removeCampaing(Camp)}
        ></i>
        <i class="fab fa-telegram-plane"></i>
        <i class="fas fa-ellipsis-v"></i>
        </>
      }
      </div>
      <p className="name-fc">
        {text}
      </p>
    </div>
  )

  // 23-07-2021 comment by LM
 // const getImageUrl = async (key) => {
 //   const url = await STORAGE.listFiles(key)
 //   console.log("URL::::", url)
 // }

  const changeWindow = (number) => {
    setWindow(number);
  }

  const getInfoView = async (camps) => {
    const socialNetwork = camps.map(camp => camp.socialNetworks)
    // console.log(socialNetwork);
    let fbCount = 0
    let igCount = 0
    let lkCount = 0
    let tkCount = 0
    let temp = []
    socialNetwork.forEach(sn => {
      const Fb = sn.find(o => o.name === "Facebook");
      Fb && (fbCount = fbCount + 1)
      const Ig = sn.find(o => o.name === "Instagram");
      Ig && (igCount = igCount + 1)
      const Tk = sn.find(o => o.name === "TikTok");
      Tk && (tkCount = tkCount + 1)
      const Lk = sn.find(o => o.name === "Linkedin");
      Lk && (lkCount = lkCount + 1)
    });

    setCampaings(camps)
    setCampaignNormal(camps.map(campaign => ({
      campOriginal: campaign,
      name: campaign.name,
      delivery: getClassStyleByStatus(campaign.state),
      budget: formatNumberCurrency(campaign.budget ? campaign.budget : 0.00),
      scope: formatNumber(campaign?.reach),
      actualReach: formatNumber(campaign.insights?.reach ? campaign.insights?.reach : 0),
      actualInteracciones: formatNumber(getTotalInteraction(campaign.reactionsWebHook ? campaign.reactionsWebHook : 0 ,campaign.insights?.saved ? campaign.insights?.saved : 0  )),
      interaccionTasa: new Number(getTotalInteractionPercent(campaign.insights?.reach ? campaign.insights?.reach : 0,getTotalInteraction(campaign.reactionsWebHook ? campaign.reactionsWebHook : 0 ,campaign.insights?.saved ? campaign.insights?.saved : 0  ))).toFixed(2) + '%',
      consumido: new Number(getConsumidoPercent(campaign.insights?.reach ? campaign.insights?.reach : 0,campaign?.reach ? campaign?.reach: 0)).toFixed(2) + '%',
      date: {
        start: campaign.initDate,
        end: campaign.endDate
      },
      source: getLogosListByNames(campaign.socialNetworks),
            inscription: formatNumberCurrency(campaign.CPM),
            hastags: campaign.hastags,
            description: campaign.description,
            logo: campaign.logo,
            postImages: campaign.postImages,
            locationList: campaign.locationList,
            mediaCategories: campaign.mediaCategories,
            id: campaign.id,
            ageValue: campaign.ageValue,
            advertiser: campaign.advertiser,
            advertiserName:campaign.advertiserName,
            CPM: campaign.CPM,
            budget: formatNumberCurrency(campaign.budget),
            activity: campaign.activity,
            totalAlcance: campaign.totalAlcance,
            totalInteraction: campaign.totalInteraction,
            totalProfit: campaign.totalProfit,
            totalRemainder: campaign.totalRemainder,
            totalCreatividad: campaign.totalCreatividad,
            state: campaign.state,
            slogan: campaign.slogan,
            nonMediaCategories: campaign.nonMediaCategories,
            socialStatusValue: campaign.socialStatusValue,
            initDate: campaign.initDate,
            endDate: campaign.endDate,
            reach: campaign.reach,
            approvedTruers: campaign.approvedTruers,
            genderCampaign: campaign.genderCampaign,
            socialNetworks: campaign.socialNetworks,
            statusExport: getNameState(campaign.state)
    })))
    setCampaingFacebook(fbCount)
    setCampaingInstagram(igCount)
    setCampaingLinkedin(lkCount)
    setCampaingTikTok(tkCount)
    for(let i=0; i<camps.length; i++)
    {
      temp.push({
        campOriginal: camps[i],
      name: camps[i].name,
      delivery: getClassStyleByStatus(camps[i].state),
      budget: formatNumberCurrency(camps[i].budget ? camps[i].budget : 0.00),
      scope: formatNumber(camps[i]?.reach),
      actualReach: formatNumber(camps[i].insights?.reach ? camps[i].insights?.reach : 0),
      actualInteracciones: formatNumber(getTotalInteraction(camps[i].reactionsWebHook ? camps[i].reactionsWebHook : 0 ,camps[i].insights?.saved ? camps[i].insights?.saved : 0  )),
      interaccionTasa: new Number(getTotalInteractionPercent(camps[i].insights?.reach ? camps[i].insights?.reach : 0,getTotalInteraction(camps[i].reactionsWebHook ? camps[i].reactionsWebHook : 0 ,camps[i].insights?.saved ? camps[i].insights?.saved : 0  ))).toFixed(2) + '%',
      consumido: new Number(getConsumidoPercent(camps[i].insights?.reach ? camps[i].insights?.reach : 0,camps[i]?.reach ? camps[i]?.reach: 0)).toFixed(2) + '%',
      date: {
        start: camps[i].initDate,
        end: camps[i].endDate
      },
      source: getLogosListByNames(camps[i].socialNetworks),
            inscription: formatNumberCurrency(camps[i].CPM),
            hastags: camps[i].hastags,
            description: camps[i].description,
            logo: camps[i].logo,
            postImages: camps[i].postImages,
            locationList: camps[i].locationList,
            mediaCategories: camps[i].mediaCategories,
            id: camps[i].id,
            ageValue: camps[i].ageValue,
            advertiser: camps[i].advertiser,
            advertiserName:camps[i]?.advertiserName,
            CPM: camps[i].CPM,
            budget: formatNumberCurrency(camps[i].budget),
            activity: camps[i].activity,
            totalAlcance: camps[i].totalAlcance,
            totalInteraction: camps[i].totalInteraction,
            totalProfit: camps[i].totalProfit,
            totalRemainder: camps[i].totalRemainder,
            totalCreatividad: camps[i].totalCreatividad,
            state: camps[i].state,
            slogan: camps[i].slogan,
            nonMediaCategories: camps[i].nonMediaCategories,
            socialStatusValue: camps[i].socialStatusValue,
            initDate: camps[i].initDate,
            endDate: camps[i].endDate,
            reach: camps[i].reach,
            maxRating: camps[i].maxRating,
            interaction: camps[i].interaction,
            approvedTruers: camps[i].approvedTruers,
            genderCampaign: camps[i].genderCampaign,
            socialNetworks: camps[i].socialNetworks,
            statusExport: getNameState(camps[i].state)
    })
    } 

    setFCamp(temp)
    props._showLoader(false)
  }

  const customStyles = {
    headCells: {
      style: {
        '&:nth-of-type(2n)': {
          backgroundColor: '#E3E4E6',
          borderRadius: '12px 12px 0 0',
          width: '40px',
          textAlign: 'center'
        },
      },
    },
    cells: {
      style: {
        "&:nth-of-type(2n)": {
          backgroundColor: "#E3E4E6"
        },
      },
    }
  }
  let DatosN= campaings.map(campaign => ({
    campOriginal: campaign,
    name: campaign.name,
    delivery: getClassStyleByStatus(campaign.state),
    budget: formatNumberCurrency(campaign.budget ? campaign.budget : 0.00),
    scope: formatNumber(campaign?.reach),
    actualReach: formatNumber(campaign.insights?.reach ? campaign.insights?.reach : 0),
    actualInteracciones: formatNumber(getTotalInteraction(campaign.reactionsWebHook ? campaign.reactionsWebHook : 0 ,campaign.insights?.saved ? campaign.insights?.saved : 0  )),
    interaccionTasa:new Number( getTotalInteractionPercent(campaign.insights?.reach ? campaign.insights?.reach : 0,getTotalInteraction(campaign.reactionsWebHook ? campaign.reactionsWebHook : 0 ,campaign.insights?.saved ? campaign.insights?.saved : 0  ))).toFixed(2) + '%',
    consumido: new Number(getConsumidoPercent(campaign.insights?.reach ? campaign.insights?.reach : 0,campaign?.reach ? campaign?.reach: 0)).toFixed(2) + '%',
    date: {
      start: campaign.initDate,
      end: campaign.endDate
    },
    source: getLogosListByNames(campaign.socialNetworks),
          inscription: formatNumberCurrency(campaign.CPM),
          hastags: campaign.hastags,
          description: campaign.description,
          logo: campaign.logo,
          postImages: campaign.postImages,
          locationList: campaign.locationList,
          mediaCategories: campaign.mediaCategories,
          id: campaign.id,
          ageValue: campaign.ageValue,
          advertiser: campaign.advertiser,
          advertiserName:campaign.advertiserName,
          CPM: campaign.CPM,
          budget: formatNumberCurrency(campaign.budget),
          activity: campaign.activity,
          totalAlcance: campaign.totalAlcance,
          totalInteraction: campaign.totalInteraction,
          totalProfit: campaign.totalProfit,
          totalRemainder: campaign.totalRemainder,
          totalCreatividad: campaign.totalCreatividad,
          state: campaign.state,
          slogan: campaign.slogan,
          nonMediaCategories: campaign.nonMediaCategories,
          socialStatusValue: campaign.socialStatusValue,
          initDate: campaign.initDate,
          endDate: campaign.endDate,
          reach: campaign.reach,
          approvedTruers:campaign.approvedTruers,
          genderCampaign: campaign.genderCampaign,
          socialNetworks: campaign.socialNetworks,
          statusExport: getNameState(campaign.state)
  }))
  let Datos = campaings.map(campaign => ({
    campOriginal: campaign,
    name: campaign.name,
    delivery: getClassStyleByStatus(campaign.state),
    budget: formatNumberCurrency(campaign.budget ? campaign.budget : 0.00),
    scope: formatNumber(campaign?.reach),
    actualReach: formatNumber(campaign.insights?.reach ? campaign.insights?.reach : 0),
    actualInteracciones: formatNumber(getTotalInteraction(campaign.reactionsWebHook ? campaign.reactionsWebHook : 0 ,campaign.insights?.saved ? campaign.insights?.saved : 0  )),
    interaccionTasa: new Number(getTotalInteractionPercent(campaign.insights?.reach ? campaign.insights?.reach : 0,getTotalInteraction(campaign.reactionsWebHook ? campaign.reactionsWebHook : 0 ,campaign.insights?.saved ? campaign.insights?.saved : 0  ))).toFixed(2) + '%',
    consumido: new Number(getConsumidoPercent(campaign.insights?.reach ? campaign.insights?.reach : 0,campaign?.reach ? campaign?.reach: 0)).toFixed(2) + '%',
    date: {
      start: campaign.initDate,
      end: campaign.endDate
    },
    source: getLogosListByNames(campaign.socialNetworks),
          inscription: formatNumberCurrency(campaign.CPM),
          hastags: campaign.hastags,
          description: campaign.description,
          logo: campaign.logo,
          postImages: campaign.postImages,
          locationList: campaign.locationList,
          mediaCategories: campaign.mediaCategories,
          id: campaign.id,
          ageValue: campaign.ageValue,
          advertiser: campaign.advertiser,
          advertiserName:campaign.advertiserName,
          CPM: campaign.CPM,
          budget: formatNumberCurrency(campaign.budget),
          activity: campaign.activity,
          totalAlcance: campaign.totalAlcance,
          totalInteraction: campaign.totalInteraction,
          totalProfit: campaign.totalProfit,
          totalRemainder: campaign.totalRemainder,
          totalCreatividad: campaign.totalCreatividad,
          state: campaign.state,
          slogan: campaign.slogan,
          nonMediaCategories: campaign.nonMediaCategories,
          socialStatusValue: campaign.socialStatusValue,
          initDate: campaign.initDate,
          endDate: campaign.endDate,
          reach: campaign.reach,
          approvedTruers: campaign.approvedTruers,
          genderCampaign: campaign.genderCampaign,
          socialNetworks: campaign.socialNetworks,
          statusExport: getNameState(campaign.state)
  }))

  const changeFilteredData= (filterText) =>  {
    setFCamp(Datos.filter(item => 
      item?.name?.toLowerCase().includes(filterText.toLowerCase()) ||
      item?.advertiserName?.toLowerCase().includes(filterText.toLowerCase()) ||
      item?.socialNetworks[0]?.name?.toLowerCase().includes(filterText.toLowerCase()) ||
      item?.delivery?.name?.toLowerCase().includes(filterText.toLowerCase()) ||
      item?.email?.toLowerCase().includes(filterText.toLowerCase()))
    )
    
  }

  const handleFilterTextChange = (value) => {
    // console.log(value);
    changeFilteredData(value)
}

const handleShareContact = (contact) => {
  props.utils.log('handleShareContact')
  props.utils.log('contact', contact)

  const currentAccountInfo = _.find(props.accounts, { id: props.currentAccount })
  let members = currentAccountInfo.members || []

  members = members.filter((member) => member !== contact.id)
  props._updateAccountMembers(props.currentAccount, members)
}
const exportDataToExcel = () =>{
  console.log('FCampToExport', fCamp)
  let headers = []
  let dataExcel=[]
  let selectors = []
  let excelData = [];
  var filteredColumns=columns.filter(function(col){
    return col.omit===false
  })
  
  filteredColumns.forEach((element)=>{
    headers.push(element.name)
    selectors.push(element.selector)
  })  
  
  dataExcel.push(headers)

       if(fCamp.length>0)
       {
        fCamp?.forEach((element) => {
           excelData = [];
             for(let k=0; k<selectors.length; k++)
             {
                 excelData.push(
                   selectors[k]=="source" ? element.socialNetworks[0].name:
                   selectors[k]=="date" ? element.initDate + " " +element.endDate:
                   selectors[k]=="delivery" ? element.statusExport:
                   element[selectors[k]],
                 );
             }
          
             dataExcel.push(excelData);
           });
           
       }
   console.log('dataParaExportar', dataExcel)
   return dataExcel;
}

const sort= (rows,selector,direction)=>{
  if(selector==='date'){
      console.log('DIRECTION',direction)
      if(direction==='asc'){
        setFCamp(fCamp.reverse())
      }else{
        setFCamp(campaignNormal)
      }
      
      return orderBy(rows, direction)
  }else{
      return orderBy(rows, selector, direction)
  }
}
// console.log("Datos especiales",Datos)
  /* let filteredData = Datos.filter(item => yo comente esto
    item.name.toLowerCase().includes(filterText.toLowerCase()) ||
    item.delivery?.name?.toLowerCase().includes(filterText.toLowerCase()) ||
    item.email?.toLowerCase().includes(filterText.toLowerCase())
    // item.age?.toString().toLowerCase().includes(filterText.toLowerCase()) ||
    // item.gender?.toLowerCase().includes(filterText.toLowerCase()) ||
    // item.country?.toLowerCase().includes(filterText.toLowerCase()) ||
    // item.phone?.toString().toLowerCase().includes(filterText.toLowerCase())
); */
  //other  comment by LM 23-07-2021
//    const goToEditPage = (id, name, createAt) => {
//      let edit = {
//        id,
//        name,
//        createAt,
//      }
//      props._setEdit(edit)
//
//      window.$.fancybox.open({
//        src: '#new-category-modal',
 //       //type: 'modal',
 //       opts: {
 //         modal: true,
 //         afterClose: () => props._clearEdit(),
  //      },
 //     })
   // }
 {/* <Info refresh={refresh} setRefresh={(dat) => setRefresh(dat)} setWindow={(data)=> setWindow(data)} currentCampaign={currentCampaign} infoCampaign={infoCampaign} categories={categories} hashtags={hashtags} 
      hashtags={hashtags} coords={coords} changeWindow={changeWindow}/> */}
  if (window === 0) {
    return (
     
      <Info  showLoader={props._showLoader} userRol={userRol} refresh={refresh} setRefresh={(dat) => setRefresh(dat)} setWindow={(data)=> setWindow(data)} currentCampaign={currentCampaign} infoCampaign={infoCampaign} categories={categories} hashtags={hashtags}
       coords={coords} changeWindow={changeWindow}/>
    
    )
  }
  // console.log('filteredData', filteredData)
  return (
    <div className='form-dashboard'>
      <div id='shadow' className='shadow hide'></div>
      <div className='Bar-content'>
        <div className='content-menu list'>
           <select
            id='viewSelect'
            className='select-dashboard fa'
          >
            <option className='fa' value='1'>
              {' '}
              &#xf0ce; Vista Campañas
            </option>
            {/* <option className='fa' value='0'>
              {' '}
              &#xf0db; Vista de Kanban{' '}
            </option>
            <option className='fa' value='2'>
              {' '}
              &#xf073; Vista de calendario
            </option> */}
          </select> 
          < DataTableUtils onChangeFilterText={handleFilterTextChange}>
            <Share
              contacts={utils.myContacts}
              data={exportDataToExcel()}
              isArray={true}
              exportXLSX={true}
              exportTXT={true}
              exportDropbox={true}
              exportDrive={true}
              exportCSV={true}
              printable={true}
              exportPNG={true}
              exportLink={true}
            />
            <ShowColumn
              columns={columns}
              showColumn={handleShowColumnChange}
              onSortChange={handleColumnSortChange}
            />
            {/* <FilterColumn
              columns={columns}
              onFilterColumn={handleFilterPerColumn}
            /> */}
          </DataTableUtils>
        {userRol === "admin" && 
          <div
            className='btnOrange-dash'
            onClick={e => setshowModal(true)}
          >
            <i className='fas fa-plus'></i>
          </div>}
        </div>
      </div>
      {/* <div className="row-section">
        <div className="content-section">
          <div className="row-info">
            <div className="circle" />
            <p className="title-text"> Campañas por red social</p>
          </div>
        </div>
        <div className="content-section social-networkModeration">
        
          <ImageBox className="rectangle-img-info" image={Logo_facebook} num={fbCount} text1={`Alcance promedio ${fbReach}`} text2={`Tasa de interaccion promedio ${fbInteraction}`} />
          <ImageBox className="rectangle-img-info" image={Logo_instagram} num={instaCount} text1={`Alcance promedio ${instaReach}`} text2={`Tasa de interaccion promedio ${instaInteraction}`} />
          <ImageBox className="rectangle-img-info" image={Logo_tiktok} num={tiktokCount} text1="Alcance promedio: 0" text2="Tasa de interaccion promedio: 0" />
          <ImageBox className="rectangle-img-info" image={logo_whatsapp} num={whatsappCount} text1="Alcance promedio: 0" text2="Tasa de interaccion promedio: 0" /> 
        </div>
      </div> */}
      {/* <div className="row-section">
        <div className="content-section containersSeconds">
          <div className='Box'>
            <div className="row-info">
              <div className="circle" />
              <p className="title-text">   Tareas por status</p>
            </div>
            <TargetContent listGeneralStatistics={postState} nameClass="targetContent-small" />
          </div>
          <div className='Box'>
            <div className="row-info">
              <div className="circle" />
              <p className="title-text">   Status de Truers</p>
            </div>
            <TargetContent listGeneralStatistics={generalData} nameClass="targetContent" />
          </div>
        </div>
      </div> */}
      <div className="row-section performance-container">
        <div className="content-section">
          <div className="row-info">
            <div className="circle" />
            <p className="title-text">   Estado de Campañas</p>
          </div>
          {/* <select className='select-dashboard'>
            <option value='1'>
              &#xf0ce; Últimos 30 dias
            </option>
          </select> */}
        </div>
        {/* <CSVLink data={campaings}>Descssargar</CSVLink> */}
        <ReactDataTable
          columns={columns}
          customStyles={customStyles}
          data={fCamp}
          persistTableHead
          pagination
          noDataComponent={"No hay datos para mostrar"}
          paginationComponentOptions={{
            rowsPerPageText: 'Elementos por página',
            rangeSeparatorText: 'de',
          }}
          noHeader
          sortFunction={sort}
        />
      </div>
      {/* {showModal ? <NewMediaCategory title="Crear nueva categoria" showModal={setshowModal} /> : <div />} */}
      {/* {showModal ? <NewMediaCategory title="Crear nueva categoria" showModal={setshowModal} /> : <div />} */}
      {showModal ? <NewCampaign title="Crear nueva campaña" showModal={setshowModal} refresh={refresh} setRefresh={(dat) => setRefresh(dat)} /> : <div />}
      {showModalCampaing ? <NewCampaign title="Edita tu campaña" campaign={editCamp} showModal={setshowModalCampaing} editCamp={editCamp} /> : <></>}
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  _showLoader: show => dispatch(showLoader(show)),
  _showAlertModal: (title, message, type, callback) =>
  dispatch(Modal.showAlertModal(title, message, type, callback)),
})

const mapStateToProps = (state) => ({
  isLoading: state.app.isLoading,
  myUser: state.users.myUser
})

export default connect(mapStateToProps, mapDispatchToProps)(Moderation)