import React, {useState, useEffect, Fragment} from 'react';
import { Auth, Hub } from 'aws-amplify';
import NativeSelect from '@material-ui/core/NativeSelect';
import "react-google-flight-datepicker/dist/main.css";
import { useDispatch, connect } from 'react-redux';
import { showLoader } from 'redux/actions/loader.actions';
import Input from '@material-ui/core/Input';
import inputModal from './inputModal'
import * as Modal from 'redux/actions/modal.actions'
import * as APILI from 'helpers/apiLI';
import Switch from '@material-ui/core/Switch';
import { getCampaign } from 'helpers/apiLI';
import { v4 } from 'uuid'
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import { getLogoByName, getClassNameByStatus } from 'myUtil'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import moment from 'moment/moment.js'
import IconButton from '@mui/material/IconButton';
import LaunchIcon from '@mui/icons-material/Launch';
import TextField from '@material-ui/core/TextField'


import Dropzone from 'libs/dropzone';
import * as Logger from 'utils/logger'
import * as STORAGE from 'helpers/storageLI';

import Maps from "./Maps"
import Tag from './tag';
import AutoComplete from './autoComplete';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import { RangeDatePicker, SingleDatePicker } from "react-google-flight-datepicker";


//import DateAdapter from '@mui/lab/AdapterDateFns';
//import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

//import BasicDateTimePicker from './time'

import HoverRating from "./starRetroaliment";






//import { FacebookProvider, EmbeddedPost } from 'react-facebook';
 //import PostEmbed from 'components/embedPost'
import ShowSocialMedia from '../showSocialMedia'
import PostAddIcon from '@material-ui/icons/PostAdd';
import { DateTimePicker } from "@material-ui/pickers";
import Paper from '@mui/material/Paper';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import "./style.css"
import Multiselect from 'multiselect-react-dropdown';
import MultiSelect from  'react-multiple-select-dropdown-lite'
import  'react-multiple-select-dropdown-lite/dist/index.css'
import FormFileInput from 'react-bootstrap/esm/FormFileInput';
import { string } from 'prop-types';


const TAG = 'Images'


const Notificator = ({ userRol, title, showNotificator, _showAlertModal, children }) => {
    const [socialNetworks, setSocialNetworks] = useState([])
    const [userRoles, setUserRoles] = useState([])
    const [categories, setCategories] = useState([])
    const [campaign, setCampaign] = useState([])
    const [task, setTask] = useState([])
    const [ratings, setRatings] = useState([])

    const [logo, setLogo] = useState([])
    const [postImages, setPostImages] = useState([])
    const dispatch = useDispatch()
    ///////////////////////non category
    const [nonCategoryTags, setNonCategoryTags] = useState([]);
    const [socialTags, setSocialTags] = useState([]);
    const [countryTags, setCountryTags] = useState([]);
    const [moderator,setModerator] = useState([]);
    const [openCountry, setOpenCountry] = useState(false);
    const loading = open && categories.length === 0;
    const [markers, setMarkers] = useState([]);
    const [showMap, setShowMap] = useState(false)
    const [tags, setTags] = useState([]);
    const [open, setOpen] = useState(false);
    const [initDate, setInitDateText] = useState(new Date());
    const [value, setValue] = useState(new Date());
    const handleChange = (newValue) => {
        setValue(newValue);
      };



    const [checkCandidates, setCheckCanditates] = useState(false)
    const [checkCampaignParticipants, setCheckCampaignParticipants] = useState(false)
    const [checkInfluencerCategories, setCheckInfluencerCategories] = useState(false)
    const [checkAgeRange, setCheckAgeRange] = useState(false)
    const [checkSocioEconomicLevel, setCheckSocioEconomicLevel] = useState(false)
    const [checkCategories, setCheckCategories] = useState(false)
    const [checkSocialNetworks, setCheckSocialNetworks] = useState(false)
    const [checkRatings, setCheckRatings] = useState(false)
    const [checkUserType, setCheckUserType] = useState(false)
    const [checkDateRange, setCheckDateRange] = useState(false)
    const [checkDateRangeTask, setCheckDateRangeTask] = useState(false)
    const [checkAdr, setCheckAdr] = useState(false)
    const [checkFollowersRange, setCheckFollowersRange] = useState(false)
    const [selectedDate, handleDateChange] = useState(new Date());

    const [name, setNameText] = useState('');
    const [description, setDescription] = useState('')
    const [link, setLink] =useState('')
    const [minSeguidores, setMinSeguidores] = useState('')
    const [maxSeguidores, setMaxSeguidores] = useState('')
    
    let edad = [] 
    for (let x=16; x<=70; x++){
        edad.push(x)
    }


    useEffect(() => {
        (async () => {
          const res = await APILI.getSocialNetworks()
          console.log(res);
          if ( res.data && res.data.socialNetworks && res.data.socialNetworks.length > 0) {
            
            setSocialNetworks(res.data.socialNetworks)
          }

        })();
        (async () => {
            const res = await APILI.getUserRolesList()
            console.log(res);
            if ( res.data && res.data.userRoles && res.data.userRoles.length > 0) {
                setUserRoles(res.data.userRoles)
            }
  
          })();
        (async () => {
       const res = await APILI.getMediaCategories()
          console.log(res);
          if (res.data && res.data.categories && res.data.categories.length > 0) {
            
            setCategories(res.data.categories)
          }
        })();
        (async () => {
       const res = await APILI.getCampaign()
          console.log(res);
          if (res.data && res.data.campaigns && res.data.campaigns.length > 0) {
            
            setCampaign(res.data.campaigns)
          }
        })();
        (async () => {
       const res = await APILI.getTask()
          console.log(res);
          if (res.data && res.data.task && res.data.task.length > 0) {
            
            setTask(res.data.task)
          }
        })();
        
    }, []);
    const deleteLogoLoader = image => {
        console.log(image)
        const newArr = logo.filter(img => img.name !== image.name)
        setLogo(newArr)
    }
    const deleteImageLoader = image => {
        console.log(image)
        const newArr = postImages.filter(img => img.name !== image.name)
        setPostImages(newArr)
    }

   

    /************************************** */
    const handleFileChange = files => {
        console.log(files);
    }
    const handleUpload = async files => {
        dispatch( showLoader(true) )
        
        const logoArr = logo.map(image => (image))
        console.log(logoArr)
        //STORAGE.uploadFile(files)
        for (const file of files) {
            let options = {
                contentType: file.type,
                progressCallback(progress) {
                Logger.log(TAG, `uploaded: ${progress.loaded}/${progress.total}`)
                }
            }
    
            const uploadedFile = await STORAGE.uploadFile(file.name, file, options)
            const url = await STORAGE.listFiles(uploadedFile.key)
            const newLogo = {
                name: uploadedFile.key,
                link: url
            }
            logoArr.push(newLogo)
            Logger.log(TAG, newLogo)
        }
        if (logoArr.length > 0) {
            setLogo(logoArr)    
        }
        dispatch( showLoader(false) )
    }

    const handleUploadPostImages = async files => {
        dispatch( showLoader(true) )
        const imagesArr = postImages.map(image => (image))
        //STORAGE.uploadFile(files)
        for (const file of files) {
            let options = {
                contentType: file.type,
                progressCallback(progress) {
                Logger.log(TAG, `uploaded: ${progress.loaded}/${progress.total}`)
                }
            }
    
            const uploadedFile = await STORAGE.uploadFile(file.name, file, options)
            const url = await STORAGE.listFiles(uploadedFile.key)
            const newImage = {
                name: uploadedFile.key,
                link: url
            }
            console.log("NEWIMAGE::: ",newImage)
            imagesArr.push(newImage)
            Logger.log(TAG, newImage)
        }
        if (imagesArr.length > 0) {
            setPostImages(imagesArr)    
        }
        dispatch( showLoader(false) )
    }
    /****map */
    
    const deleteCountryTag = tagName => {
        console.log(tagName);
        const newTags = tags.filter(tag => tag.name !== tagName)
        setCountryTags(newTags)
    }

    const saveCountryTag = noncat => {
        if (noncat) {
            const textHastag = noncat.name
            let newTags = countryTags.filter(tag => tag.name !== textHastag.trim())
            newTags.push(noncat)
            setCountryTags(newTags)   
        }  
    }

    const useStyles = makeStyles({
        root: {
        width: 300,
        },
    });

    const btnGroupStyle = makeStyles((theme) => ({
        root: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          '& > *': {
            margin: theme.spacing(1),
          },
        },
      }));

      const groupBtnClass = btnGroupStyle();

      const onDateChange = (startDate) => {
        startDate ? setInitDateText(startDate) : setInitDateText('')
    }

    return (
        <>
        <div className="blur-div">
            <div className="content-modal firstmodal">
                <div className="closeModal-content">
                    
                   
                    <i className="fas fa-times-circle" onClick={e => showNotificator(false)}></i>
                </div>
                <div>
                    <h2 className="titleModal">{title}</h2>
                </div>
                <div className="body-modal">
                    <div className="part-BodyModal">
                       
                        
                      <div className="container">
                          <h3 className="subtitle-modal"> Selecciona los parámetros por los que deseas
                              segmentar tu nueva notificación Push, luego por favor 
                              selecciona sus características:
                          </h3>
                          <div className="row">
                            <div className="col-sm-1">
                            <Checkbox className="check" checked={checkCandidates} onClick={e=> setCheckCanditates(!checkCandidates)}/>
                            </div>
                            <div className="col-sm-1">
                            </div>
                            <div className="col-sm-10">
                                <div className="row text-sm-left">
                                   <p className="textPush" > Candidatos</p>
                                </div>
                                <div className="row">
                                 <div className="w-90">
                                <NativeSelect  className="border-0 w-90">
                                <option   value="" disabled selected>Seleciona un grupo de candidatos</option>
                                {campaign.map(adr  => (<option value={adr.id}> {adr.name} </option>))}
                                </NativeSelect>
                                
                                 <div className="mb-15"/>
                                 </div>
                                </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-1">
                            <Checkbox checked={checkCampaignParticipants} onClick={e=> setCheckCampaignParticipants(!checkCampaignParticipants)}/>
                            </div>
                            <div className="col-sm-1">
                            </div>
                            <div className="col-sm-10">
                                <div className="row text-sm-left">
                                   <p className="textPush" > Participantes de una campaña</p>
                                </div>
                                <div className="row">
                                 <div className="w-90">
                               
                                <NativeSelect  className="border-0 w-90">
                                <option value="" disabled selected>Selecciona un grupo de participantes</option>
                                    {campaign.map(adr => (<option value={adr.id}>{adr.name}</option>))}
                                </NativeSelect>
                                <div className="mb-15"/>
                                 </div>
                                </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-1">
                            <Checkbox checked={checkInfluencerCategories} onClick={e=> setCheckInfluencerCategories(!checkInfluencerCategories)}/>
                            </div>
                            <div className="col-sm-1">
                            </div>
                            <div className="col-sm-10">
                                <div className="row text-sm-left">
                                   <p className="textPush" > Categorías de influenciador</p>
                                </div>
                                <div className="row">
                                 <div className="w-90">
                               
                                {/* <NativeSelect  className="border-0 w-90">
                                <option value="" disabled selected>Selecciona un grupo de categorías</option>
                            {categories.map(adr => (<option value={adr.name}>{adr.name}</option>))}
                                </NativeSelect> */}
                                <Multiselect 
                                    isObject={false}
                                    onRemove={(event) => {console.log(event);}}
                                    onSelect={(event) => {console.log(event);}}
                                    options={categories.map((item) => item.name)} 
                                    placeholder="Custom Placeholder"
                                    showArrow={true}
                                    hidePlaceholder={true}
                                    closeOnSelect={true}	
                                    />
                                <div className="mb-15"/>
                                 </div>
                                </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-1">
                            <Checkbox checked={checkAgeRange} onClick={e=> setCheckAgeRange(!checkAgeRange)}/>
                            </div>
                            <div className="col-sm-1">
                            </div>
                            <div className="col-sm-10">
                                <div className="row text-sm-left">
                                   <p className="textPush"> Rango de edad</p>
                                </div>
                                
                                <div className="row first-row">
                                <p className="txt">de</p>
                                <div className="col-sm-5">
                                <Paper elevation={4}>
                                    <NativeSelect  className="w-90 select">
                                        <option className="Options" value="" disabled selected>Edad</option>
                                        {edad.map(adr => (<option value={adr}>{adr}{" AÑOS"}</option>))}
                                    </NativeSelect>
                                </Paper>
                                 </div>
                                 <p className="txt">a</p>
                                 <div className="col-sm-5">
                                <Paper elevation={5} >
                                    <NativeSelect  className="border-0 w-90 select">
                                        <option value="" disabled selected>Edad</option>
                                        {edad.map(adr => (<option value={adr}>{adr}{" AÑOS"}</option>))}
                                    </NativeSelect>
                                </Paper>
                                <div className="mb-15"/>
                                 </div>
                                </div>
                            </div>   
                          </div>

                          <div className="row">
                            <div className="col-sm-1">
                            <Checkbox checked={checkSocioEconomicLevel} onClick={e=> setCheckSocioEconomicLevel(!checkSocioEconomicLevel)}/>
                            </div>
                            <div className="col-sm-1">
                            </div>
                            <div className="col-sm-10">
                                <div className="row text-sm-left">
                                   <p className="textPush" > Rango de nivel socio económico</p>
                                </div>
                                <div className="row">
                                 <div className="w-90">
                               
                                {/* <NativeSelect  className="border-0 w-90">
                                <option value="" disabled selected>Selecciona el nivel socio económico</option>
                                    <option value="Bajo">Bajo</option>
                                    <option value="Medio-Bajo">Medio-Bajo</option>
                                    <option value="Medio">Medio</option>
                                    <option value="Medio-Alto">Medio-Alto</option>
                                    <option value="Alto">Alto</option>
                                </NativeSelect> */}
                                <Multiselect 
                                    isObject={false}
                                    onRemove={(event) => {
                                        console.log(event);
                                    }}
                                    onSelect={(event) => {
                                        console.log(event);
                                    }}
                                    options={[
                                        'Bajo',
                                        'Medio-Bajo',
                                        'Medio',
                                        'Medio-Alto',
                                        'Alto'
                                    ]}
                                    showArrow={true}
                                />
                                <div className="mb-15"/>
                                 </div>
                                </div>
                            </div>
                          </div>

                        <div className="row"> 
                          <div className="col-sm-1">
                            <Checkbox checked={checkSocioEconomicLevel} onClick={e=> setCheckSocioEconomicLevel(!checkSocioEconomicLevel)}/>
                            </div>
                            <div className="col-sm-1">
                            </div>
                            <div className="col-sm-10"> 
                          <div className="row text-sm-left">
                            <p className="textPush char"> Por rango de seguidores en su perfil </p>
                          </div>
                            <div className="imageSetter-content paper-container">
                            <Paper elevation={4} className="paper">
                            <Input className="textPush" placeholder="De" multiline={true} name='number' value={minSeguidores} 
                            onChange={(e) => setMinSeguidores(e.target.value) } />
                            </Paper>
                            <Paper elevation={5} className="paper">
                            <Input className="textPush" placeholder="Hasta" multiline={true} value={maxSeguidores} 
                            onChange={(e) => setMaxSeguidores(e.target.value) } />
                            </Paper>
                          </div>
                          </div>
                          </div>
                      
                          <div className="row">
                            <div className="col-sm-1">
                            <Checkbox checked={checkSocialNetworks} onClick={e=> setCheckSocialNetworks(!checkSocialNetworks)}/>
                            </div>
                            <div className="col-sm-1">
                            </div>
                            <div className="col-sm-10">
                                <div className="row text-sm-left">
                                   <p className="textPush" > Por red social</p>
                                </div>
                                
                                <div className="row">
                                
                                 <div className="w-90">
                               
                                {/* <NativeSelect  className="border-0 w-90">
                                <option value="" disabled selected>Red Social</option>
                                {socialNetworks.map(adr => (<option value={adr.name}>{adr.name}</option>))}    
                                </NativeSelect> */}
                                <Multiselect 
                                    isObject={false}
                                    onRemove={(event) => {console.log(event);}}
                                    onSelect={(event) => {console.log(event);}}
                                    options={socialNetworks.map((item) => item.name)} 
                                    placeholder="Custom Placeholder"
                                    showArrow={true}
                                    hidePlaceholder={true}
                                    closeOnSelect={true}	
                                />
                               
                                <div className="mb-15"/>
                                 </div>
                                </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-1">
                            <Checkbox checked={checkRatings} onClick={e=> setCheckRatings(!checkRatings)}/>
                            </div>
                            <div className="col-sm-1">
                            </div>
                            <div className="col-sm-10">
                                <div className="row text-sm-left">
                                   <p className="textPush">Rating de estrellas</p>
                                </div>
                                
                                <div className="row">
                                
                                 <div className="w-90">
                               
                                {/* <NativeSelect  className="border-0 w-90">
                                <option value="" disabled selected>Seleccionar rating</option>
                                    <option value={1}> 1 Estrella {" "} ⭐</option>
                                    <option value={2}> 2 Estrellas ⭐⭐</option>
                                    <option value={3}> 3 Estrellas ⭐⭐⭐</option>
                                    <option value={4}> 4 Estrellas ⭐⭐⭐⭐</option>
                                    <option value={5}> 5 Estrellas ⭐⭐⭐⭐⭐</option>
                                    
                                </NativeSelect> */}
                                <div className="App border-0 w-90">
                                <HoverRating/>
                                </div>                              
                               
                                <div className="mb-15"/>
                                 </div>
                                </div>
                            </div>
                            
                           
                          </div>
                          <div className="row">
                            <div className="col-sm-1">
                            <Checkbox checked={checkUserType} onClick={e=> setCheckUserType(!checkUserType)}/>
                            </div>
                            <div className="col-sm-1">
                            </div>
                            <div className="col-sm-10">
                                <div className="row text-sm-left">
                                   <p className="textPush">Por tipo de usuario</p>
                                </div>
                                
                                <div className="row">
                                
                                 <div className="w-90">
                               
                                <NativeSelect  className="border-0 w-90">
                                <option value="" disabled selected>Tipo de Usuario</option>  
                               
                                {userRoles.map(adr  => (<option value={adr.description}> {adr.description} </option>))}
                                                                 
                                </NativeSelect>
                               
                                <div className="mb-15"/>
                                 </div>
                                </div>
                            </div>
                            
                           
                          </div>

                          <div className="row">
                            <div className="col-sm-1">
                            <Checkbox checked={checkDateRange} onClick={e=> setCheckDateRange(!checkDateRange)}/>
                            </div>
                            <div className="col-sm-1">
                            </div>
                            <div className="col-sm-10">
                                <div className="row text-sm-left">
                                   <p className="textPush" > Rango de fecha de antiguedad</p>
                                </div>
                                <div className="mb-15">
                                <SingleDatePicker
                                startDate={initDate}
                                onChange={(startDate) => onDateChange(startDate)}
                                startDatePlaceholder="Fecha"
                                dateFormat="MM/DD/YYYY"
                                singleCalendar={true}
                                />
                                </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-1">
                            <Checkbox checked={checkDateRangeTask} onClick={e=> setCheckDateRangeTask(!checkDateRangeTask)}/>
                            </div>
                            <div className="col-sm-1">
                            </div>
                            <div className="col-sm-10">
                                <div className="row text-sm-left">
                                   <p className="textPush" > Rango de fecha Ultimo Task</p>
                                </div>
                                <div className="mb-15">
                                <SingleDatePicker
                                startDate={initDate}
                                onChange={(startDate) => onDateChange(startDate)}
                                startDatePlaceholder="Fecha"
                                dateFormat="MM/DD/YYYY"
                                singleCalendar={true}
                                />
                                </div>
                            </div>
                          </div>
                      </div>
                      
                 </div>
     
                    <div className="part-BodyModal">
                    <div className="row text-sm-left">
                        <p className="textPush char"> Descripción de la notificación</p>
                    </div>
                    <div className="imageSetter-content">
                        <Input className="textPush" placeholder="Descripción" multiline={true} min="0" max="5" type={string}  value={description} 
                        onChange={(e) => setDescription(e.target.value)} />
                    </div>
                    <div className="imageSetter-content">
                            <p className="textPush">Logo del anunciante</p>
                            <Dropzone onUpload={handleUpload} onChange={handleFileChange}/>
                            <div className="content-section">
                                {logo.map(log =>(
                                    <div className="imageLoaderContent">
                                        <i className="fas fa-times-circle deleteImageLoader" onClick={e => deleteLogoLoader(log)}></i>
                                        <img className="imageLoader" src={log.link}></img>
                                    </div>
                                ))}
                            </div>
                        </div> 
                        <div className="imageSetter-content">
                            <p className="textPush">Imagen de la publicacion</p>
                            <Dropzone onUpload={handleUploadPostImages} onChange={handleFileChange}/>
                            <div className="content-section">
                                {postImages.map(image =>(
                                    <div className="imageLoaderContent">
                                        <i className="fas fa-times-circle deleteImageLoader" onClick={e => deleteImageLoader(image)}></i>
                                        <img className="imageLoader" src={image.link}></img>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="row text-sm-left">
                            <p className="textPush char"> Link o redireccionamiento de la notificación </p>
                        </div>
                        <div className="imageSetter-content">
                        <Input className="textPush" placeholder="  Ingresa el link a donde redireccionaremos" multiline={true} value={link} 
                        onChange={(e) => setLink(e.target.value) } />
                        
                        </div>

                        <div className="mb-15"/>
                        <p className="textPush geo">Geolocalización</p>          
                        <div className={groupBtnClass.root}>
                        <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                        <Button  onClick={e => setShowMap(false)}>Pais/Zona</Button>
                        <Button onClick={e => setShowMap(true)}>Mapa</Button>
                        </ButtonGroup>
                        </div>
                       
                        { showMap ? 
                            /////Map picker
                            <div> <Maps changeCoords={value => setMarkers(value)}/> 
                            </div>: 
                            ////Country picker
                            <div>  
                            <AutoComplete data={countryList} open={openCountry} loading={loading} setOpen={setOpenCountry} saveTag={saveCountryTag} placeholder="  Ingresa Pais / Zona" />
                            <div className="tagContent">
                            {countryTags.map(tag => (<Tag key={tag.name} name={tag.name} deleteTag={deleteCountryTag} />))}
                        
                   
                            </div>
                       
                         </div>
                        
                        }
                    </div>
                </div>
              
                <div className="footer-Modal">
                    <div className="buttons-Modal-content">
                        <button onClick={e => showNotificator(false)}>Cancelar</button>
                        <button onClick={e => showNotificator(false)}>Guardar</button>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

const countryList = [
    { name: "Guatemala, Guatemala", short: "GT" },
    { name: "Guatemala", short: "GT" },
    { name: "Ciudad de Guatemala, Guatemala, Guatemala", short: "GT" },
    { name: "Fraijanes, Guatemala, Guatemala", short: "GT" },
    { name: "San Cristobal, Guatemala, Guatemala", short: "GT" },
    { name: "Mixco, Guatemala, Guatemala", short: "GT" },
    { name: "Boca del Monte, Guatemala, Guatemala", short: "GT" },
    { name: "Amatitlan, Guatemala, Guatemala", short: "GT" },
    { name: "San Jose Pinula, Guatemala, Guatemala", short: "GT" },
    { name: "Costa Rica", short: "CR" },
    { name: "San Jose, Costa Rica", short: "CR" },
    { name: "Antigua Guatemala, Sacatepequez, Guatemala", short: "CR" }
];

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
    _showLoader: show => dispatch(showLoader(show)),
    _showAlertModal: (title, message, type, children, callback) => dispatch(Modal.showAlertModal(title, message, type,children, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Notificator)