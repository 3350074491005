import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Auth, Hub } from 'aws-amplify';
import { VerifyContact } from 'aws-amplify-react'
import { addUser} from 'redux/actions/users.actions'

//Theme
import Theme from './theme';

//components
import NavBar from './components/navBar'
import SideMenu from './components/sideMenu/nav'

import { withAuthentication } from 'config/session'
import Content from './content';
import ContentInfluencer from './contentInfluencer';
import PageLoader from 'components/page_loader';
import Loader from 'components/loader';
import Influencer from './components/testing/influencer'
import Admin  from './components/testing/admin'
import * as APILI from 'helpers/apiLI';

function App({ authState, isLoading, onStateChange, addUser, myUser}) {


  const [user, setUser] = useState(null)
  const [userRol, setUserRol] = useState('')
  const [isAdminTestingUser, setIsAdminTestingUser] = useState(false)
  const [isInfluencerTestingUser, setIsInfluencerTestingUser] = useState(false)
  const validAdminUser= 'luismenendez.dev@gmail.com'
  const validInfluencerUser= 'lmenendez.tpp@gmail.com'

  const getUser = () => { 
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.log('Not signed in'))
  }
  /* console.log("getUser",getUser) */

  useEffect(() => {
    console.log('user', user)
    localStorage.setItem('CurrentUser', JSON.stringify( user ));
    if (user != null) {
      //imprimir id de rol
      /* console.log('rol', user.username) */
      //guardar en localStorage por el momento nombre de rol
      localStorage.setItem('UserRol', JSON.stringify( user.attributes['custom:userRol'] ));
      validAdminUser === user?.attributes?.email ? setIsAdminTestingUser(true) : setIsAdminTestingUser(false)
      validInfluencerUser === user?.attributes?.email ? setIsInfluencerTestingUser(true) : setIsInfluencerTestingUser(false)
      setUserRol(user.attributes['custom:userRol'])
      addUser(user.attributes)
      /* console.log("myUser1:",myUser); */
    }
    //setUserRol(user.CognitoUser.attributes.userRol);
  }, [user])

  const saveLog = async (body) => {
    try {
      APILI.createNewLog(body).then(res => {
        console.log('Log Creado')
      }).catch(err => {
      console.log('err', err) 
      })
      
  } catch (err) {
      console.log("No se pudo guardar el log", err)
  }
  }

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then((userData) => setUser(userData))
          break
        case 'signOut':
          const currentUser = JSON.parse(localStorage.getItem('CurrentUser')) ?? []; 
          console.log('desde case')
          console.log('usuario que cierra sesion', currentUser)
          const bodyLog = {
            Tipo: "Logout",
            Accion: "Cierre de sesión",
            Data: currentUser.attributes,
            UsuarioId: currentUser.username,
            UsuarioName:currentUser.attributes.name,
            Email: currentUser.attributes.email,
            Historico: {},
            CodeHttp: 200
        }
        saveLog(bodyLog)

          setUser(null)
          break
        default:
          break
      }
    })

    getUser().then((userData) => setUser(userData))
  }, [])

  if(isLoading){
    <PageLoader/> 
  }

  if (authState !== 'signedIn' && authState !== 'register' && authState !== 'confirmSignUp'&& authState !== 'forgotPassword'&& authState !== 'requireNewPassword') {
    // console.log("Hola Luis",authState)
    onStateChange('signUp', {})
  }

  // console.log("authState: ",authState)

  let URLactual = window.location.pathname;
  /* console.log(("pathname", URLactual)); */
 let pathSearch = URLactual.includes('/myProfile/');
 /* console.log("pathsearch",pathSearch) */
  if (user === null && pathSearch ){
     /* console.log("oh yeeeees") */
       return(
        <h1> Hola </h1> 
       )
  }
  
  if (user != null) {
    
    return (
      <>
        <Theme>
          {isAdminTestingUser && <h1><Admin/></h1> }
          {isInfluencerTestingUser && <Influencer/> }
          {isInfluencerTestingUser === false && isAdminTestingUser === false && 
            <>
            <NavBar authState={authState} onStateChange={onStateChange} />
            <div className="content-all accounts-page">
              <Loader/>
              <SideMenu userRol={userRol}/>
              <Content />
            </div>
            </>}
        </Theme>
      </>
    );
  }
  console.log('authDataLog', user)

  if (authState === 'verifyContact') {
    return <VerifyContact authData={user} user={user} authState='verifyContact' />
  }

  return null
}

const mapStateToProps = (state) => ({
  isLoading: state.app.isLoading,
  myUser:state.users.myUser 
})

export default connect(mapStateToProps,{addUser})(withAuthentication(App));
