import React from 'react';
import Input from '@material-ui/core/Input';
import TextField from '@mui/material/TextField';

const InputModal = ({title, error, helperText, placeholder, value, change, type}) => {
  
  const tipo = type ? type : 'text'

  return(
    <>
      <p>{title}</p>
      <form autoComplete="off">
      <TextField variant="standard" error={error} helperText={helperText} placeholder={placeholder} value={value} onChange={e => change(e.target.value)} className="w-90" type={tipo} />
      {/* <Input placeholder={placeholder} value={value} onChange={e => change(e.target.value)} className="w-90" type={tipo} /> */}
      </form>
      <div className="mb-15" />
    </>
  )
}

export default InputModal