import React, {useState, useEffect} from 'react';
import { Auth, Hub } from 'aws-amplify';
import NativeSelect from '@material-ui/core/NativeSelect';
import "react-google-flight-datepicker/dist/main.css";
import { useDispatch, connect } from 'react-redux';
import { showLoader } from 'redux/actions/loader.actions';
import InputModal from './inputModal';
import * as Modal from 'redux/actions/modal.actions'
import * as APILI from 'helpers/apiLI';
import Switch from '@material-ui/core/Switch';
import Swal from 'sweetalert2/dist/sweetalert2.js'

const AdminPanel = ({ userRol, title, showAdminModal, _showAlertModal }) => {

    const [user, setUser] = useState(null)
    const [userTxt, setUserTxt] = useState('');
    const [nameRegistered, setNameRegistered] = useState('')
    const [emailRegistered, setEmailRegistered] = useState('')
    const [passwordTxt, setPasswordTxt] = useState('');
    const [countryTxt, setCountryTxt] = useState('');
    const [languageTxt, setLanguageTxt] = useState('');
    const [companyTxt, setCompanyTxt] = useState('');
    const [nameContactTxt, setNameContactTxt] = useState('');
    const [stallContactTxt, setStallContactTxt] = useState('');
    const [phoneContactTxt, setPhoneContactTxt] = useState('');
    const [nitTxt, setNitTxt] = useState([]);
    const [billingTxt, setBillingTxt] = useState('');
    const [countryBillTxt, setCountryBillTxt] = useState('');
    const [cityBillTxt, setCityBillTxt] = useState('');
    const [streetBillTxt, setStreetBillTxt] = useState('');
    const [cityMailTxt, setCityMailTxt] = useState('');
    const [streetMailTxt, setStreetMailTxt] = useState('');
    const [zipCodeTxt, setZipCodeTxt] = useState('');
    const [countries, setCountries] = useState([])
    const [advertisers, setAdvertisers] = useState([])
    const [moderator,setModerator] = useState();
    
    const dispatch = useDispatch()
    
    const getUser = () => {
    
        return Auth.currentAuthenticatedUser()
        .then((userData) => userData)
        .catch(() => console.log('Not signed in'))
    }

   

    const saveAdvertiserInfo = async (id) => {
        const body = {
            id: id,
            name: companyTxt,
            moderator: moderator,
            country: countryTxt,
            language: languageTxt,
            nameContact: nameContactTxt,
            stallContact: stallContactTxt,
            phoneContact: phoneContactTxt,
            nit: nitTxt,
            billingCode: billingTxt,
            countryBill: countryBillTxt,
            cityBill: cityBillTxt,
            streetBill: streetBillTxt,
            cityMail: cityMailTxt,
            streetMail: streetMailTxt,
            zipCode: zipCodeTxt
        }
        throw await APILI.createAdvertiserInfo(body)
    }
    useEffect(() => {
        Hub.listen('auth', ({ payload: { event } }) => {
          switch (event) {
            case 'signIn':
            case 'cognitoHostedUI':
              getUser().then((userData) => {
                  setUser(userData)
                  setModerator(userData.attributes['custom:moderator'])
                  
                })
              break
            case 'signOut':
              setUser(null)
              break
            default:
              break
          }
          
        })
        
        getUser().then((userData) => {setUser(userData)
            parserData(userData)
            console.log(userData)
            APILI.getAdvertiserByAccount({ account: userData.username }).then(res => {
                console.log(res.data.advertiser)
                
              
            })
                .catch(console.error)
        }
        )
       
    }, [])
    
    const saveAdvertiser = async (e) => {
        if (userRol === 'admin') {
            dispatch(showLoader(true))
            try {
                const newUser = await Auth.signUp({
                    username: userTxt,
                    password: passwordTxt,
                    attributes: {
                        name: "nameValue",
                        email: userTxt,
                        'custom:userRol': "anunciante",
                        //'custom:phone': phoneValue,
                    },
                    
                })
                saveAdvertiserInfo(newUser.userSub)
                console.log("Creado")
                Swal.fire(
                    '¡Anunciante creado!',
                    'Anunciante creado exitosamente',
                    'success'
                  )
            } catch (err) {
                console.log("Error")
                if(err.code =="UsernameExistsException")
                {
                  Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Este correo ya ha sido registrado anteriormente'
                  })
                }
                else if(err.code =="InvalidPasswordException")
                {
                  Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: 'Tu contraseña debe tener un mínimo de 8 caracteres e incluir al menos una letra mayúscula.',
                })
                }
                else
                {
                  Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'No se pudo crear el anunciante'
                  })
                }
            }
            dispatch(showLoader(false))
        }
    }

    const parserData = camps => {
        const userDataParse = camps
      setNameRegistered(userDataParse.attributes.name)
      setEmailRegistered(userDataParse.attributes.email)

       
    }
    
    const [state, setState] = React.useState({
        checkedA: false,
        checkedB: false,
      });
    
      const [modifyProfile, setModifyProfile] = useState(false)
      const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        setModifyProfile(!modifyProfile)
      };
    
    return (
        <div className="blur-div">
            <div className="content-modal">
                <div className="closeModal-content">
                    {modifyProfile ? <p className="mt-2 text-danger font-weight-bold text-sm-center">Modificar Perfil</p> :
                    <p className="mt-2 font-weight-bold text-sm-center">Panel de Administracion</p> }                    
                    <Switch
                       checked={state.checkedA}
                        onChange={handleChange}
                        name="checkedA"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    <i className="fas fa-times-circle" onClick={e => showAdminModal(false)}></i>
                </div>
                <div>
                    <h3 className="titleModal">{title}</h3>
                </div>
                <div className="body-modal bodyNewAdvertiser">
                    <div className="part-BodyModal">
                  
                    <InputModal lockornot={modifyProfile} title="Usuario" placeholder={emailRegistered} value={emailRegistered} change={setUserTxt}  />
                    <InputModal lockornot={modifyProfile} title="Nombre de contacto" placeholder={nameRegistered} value={nameRegistered} change={setNameContactTxt} />
                        <InputModal lockornot={modifyProfile} title="Contraseña Actual" placeholder="  Ingresa contraseña" value={passwordTxt} change={setPasswordTxt} type="password" />
                        <InputModal lockornot={modifyProfile} title="Contraseña Nueva" placeholder="  Ingresa contraseña" value={passwordTxt} change={setPasswordTxt} type="password" />
                        <InputModal lockornot={modifyProfile}  title="Repetir Contraseña" placeholder="  Repetir contraseña" value={passwordTxt} change={setPasswordTxt} type="password" />
                        <p>Pais</p>
                        <NativeSelect disabled value={countryTxt} onChange={e => setCountryTxt(e.target.value)} name="age" className="w-90">
                            <option value="" disabled>Selecciona un pais</option>
                            {advertisers.map(adr => (<option value={adr.id}>{adr.name}</option>))}
                            <option value="4">Guatemala</option>
                            <option value="4">Costa Rica</option>
                        </NativeSelect>
                        <div className="mb-15" />
                        <InputModal lockornot={modifyProfile}  title="Idioma" placeholder="  Ingresa un idioma" value={languageTxt} change={setLanguageTxt} />
                        <InputModal lockornot={modifyProfile}  title="Nombre de la compañia" placeholder="  Ingresa un nombre" value={companyTxt} change={setCompanyTxt} />

                        <InputModal lockornot={modifyProfile} title="Puesto de contacto" placeholder="  Ingresa un puesto" value={stallContactTxt} change={setStallContactTxt} />
                        <InputModal lockornot={modifyProfile} title="Telefono de contacto" placeholder="  Ingresa un telefono" value={phoneContactTxt} change={setPhoneContactTxt} />
                    </div>
                    <div className="part-BodyModal">
                        <InputModal lockornot={modifyProfile}  title="Nit" placeholder="  Ingresa un nit" value={nitTxt} change={setNitTxt} />
                    </div>
                </div>
                <div className="footer-Modal">
                    <div className="buttons-Modal-content">
                        <button onClick={e => showAdminModal(false)}>Cancelar</button>
                        <button onClick={e => saveAdvertiser()}>Guardar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
    _showLoader: show => dispatch(showLoader(show)),
    _showAlertModal: (title, message, type, callback) => dispatch(Modal.showAlertModal(title, message, type, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminPanel)