import React from 'react'
// components
import Moderation from 'components/moderation'

const ModerationPage = (props) => {
  const {userRol} = props;
  return (
    <>
    {userRol==='admin' ||  userRol === 'anunciante'?
    <Moderation />:
    <h1>No tienes privilegios</h1>
    }
    </>
    )  
}
export default ModerationPage
