import * as API from 'helpers/api'
import * as Logger from 'utils/logger'
import * as Modal from './modal.actions'
import { showLoader } from './loader.actions'

export const FB_APPS = 'FACEBOOK:APPS'
export const FB_PROP = 'FACEBOOK:PROP'
export const FB_PAGES = 'FACEBOOK:PAGES'

const TAG = 'FACEBOOK:ACTION'

export const getApps = () => dispatch => {
    dispatch(showLoader(true))
    API.getApps()
        .then(response => {
            Logger.log(TAG, 'getApps', response)

            dispatch({
                type: FB_APPS,
                apps: response.data
            })
        })
        .catch(err => {
            Logger.log(TAG, 'getApps', err)
        })
        .then(() => {
            dispatch(showLoader(false))
        })
}

export const setProp = prop => dispatch => {
    dispatch({
        type: FB_PROP,
        prop,
    })
}
export const getPages = (userToken) => (dispatch, getState) => {
    dispatch(showLoader(true))
    const {fanpageAsociar} = getState()
    API.getFanpages(fanpageAsociar.appId, userToken)
        .then(response => {
            Logger.log(TAG, 'getFanpages', response)

            dispatch({
                type: FB_PAGES,
                pages: response.data
            })
        })
        .catch(err => {
            Logger.log(TAG, 'getFanpages', err)
        })
        .then(() => {
            dispatch(showLoader(false))
        })
}

export const savePage = (pageOption) => (dispatch) => {
    dispatch(showLoader(true))
    const  page = {
        id: pageOption.value,
        name: pageOption.label,
        access_token: pageOption.token,
    }
    API.saveFanpage(page)
        .then(response => {
            Logger.log(TAG, 'savePage', response)

            window.FB.api('me/subscribed_apps', 'post', {
                subscribed_fields: 'feed',
                access_token: pageOption.token,
            }, function (res) {
                if (!res || res.error) {
                    Logger.log(TAG, 'savePage', res)
                    dispatch(Modal.showAlertModal('Error!', 'Hubo un error al guardar la fanpage', Modal.MODAL_TYPE_ERROR))
                } else {
                    dispatch(Modal.showAlertModal('', 'La fanpage fue guardada exitosamente', Modal.MODAL_TYPE_SUCCESS, () => {}));
                }
            });
        })
        .catch(err => {
            Logger.log(TAG, 'savePage', err)
            dispatch(Modal.showAlertModal('Error!', 'Hubo un error al guardar la fanpage', Modal.MODAL_TYPE_ERROR))
        })
        .then(() => {
            dispatch(showLoader(false))
        })
}