import React, {useEffect, useState} from 'react';

import './assets/css/styles.css'
import Logo_facebook from "../resume/assets/img/logo_facebook.svg";
import Logo_instagram from "../resume/assets/img/logo_instagram.png";
import Logo_tiktok from "../resume/assets/img/logo_tiktok.svg";
import logo_linkedin from "../resume/assets/img/logo_linkedin.svg";
import logo_whatsapp from "../resume/assets/img/whatsapp.png";

const GenderChart = (props) => {
    const { generalGenderState } = props
    const [maleArray, setmaleArray] = useState([])
    const [femaleArray, setfemaleArray] = useState([])
    let totalusers = generalGenderState.Masculino +
                     generalGenderState.Femenino 

    const onlyFacebook = props.onlyFacebook
    const onlyInstagram = props.onlyInstagram
    const onlyTiktok = props.onlyTikTok

    let onlyMaleFacebook = onlyFacebook.filter(item => item.gender === "Masculino")
    let onlyfeminineFacebook = onlyFacebook.filter(item => item.gender === "Femenino")

    let onlyMaleInstagram = onlyInstagram.filter(item => item.gender === "Masculino")
    let onlyfeminineInstagram = onlyInstagram.filter(item => item.gender === "Femenino")

    let onlyMaleTiktok = onlyTiktok.filter(item => item?.gender === "Masculino")
    let onlyfeminineTiktok = onlyTiktok.filter(item => item?.gender === "Femenino")

    let useRol = props.userRol
    
    let maleValue = Math.round(20/totalusers*generalGenderState.Masculino);

    let femaleValue = Math.round(20/totalusers*generalGenderState.Femenino);
    
    useEffect(() =>{
        let mArray = [];
        let fArray = []; 
        for (let x=0; x<=19; x++){
            if(x<=maleValue){
                mArray.push({"gender":true})
            } else {
                mArray.push({"gender":false})
            }
            if(x<=femaleValue){
                fArray.push({"gender":true})
            } else {
                fArray.push({"gender":false})
            }
        }
        setmaleArray(mArray)
        setfemaleArray(fArray)

    },[generalGenderState])

    return (
        <>
            <div class="maleWrap">
                <div class="bigIco">
                    <i class="fas fa-male"></i>
                </div>
                <div class="calcGrid">
                     {maleArray.map(gnr => <>{gnr.gender  ? 
                   <i class="fas fa-male active"></i> : 
                   <i class="fas fa-male"></i>}</>)}
                </div>
                <div class="calcResult">
                    <p>{Math.round(100/totalusers*generalGenderState.Masculino)}%</p>
                </div>
            </div>
            <div class="femaleWrap">
                <div class="bigIco">
                    <i class="fas fa-female"></i>
                </div>
                <div class="calcGrid">
                {femaleArray.map(gnr => <>{gnr.gender  ? 
                   <i class="fas fa-female active"></i> : 
                   <i class="fas fa-female"></i>}</>)}
                </div>
                <div class="calcResult">
                    <p>{Math.round(100/totalusers*generalGenderState.Femenino)}%</p>
                </div>
                
            </div>
            {useRol  === "admin" ?
            <div className="boxGenderSocialNetwork">
                <div className='box'>
                    <img src={Logo_facebook}/>
                    <div>
                        <p>Hombres:<span>{onlyMaleFacebook.length ? onlyMaleFacebook.length : "0"}</span></p>
                        <p>Mujeres:<span>{onlyfeminineFacebook.length ? onlyfeminineFacebook.length : "0"}</span></p>
                    </div>
                </div>
                <div className='box'>
                    <img src={Logo_instagram}/>
                    <div>
                        <p>Hombres:<span>{onlyMaleInstagram.length ? onlyMaleInstagram.length : "0"}</span></p>
                        <p>Mujeres:<span>{onlyfeminineInstagram.length ? onlyfeminineInstagram.length : "0"}</span></p>
                    </div>
                </div>
                <div className='box'>
                    <img src={Logo_tiktok}/>
                    <div>
                        <p>Hombres:<span>{onlyMaleTiktok.length ? onlyMaleTiktok.length : "0"}</span></p>
                        <p>Mujeres:<span>{onlyfeminineTiktok.length ? onlyfeminineTiktok.length : "0"}</span></p>
                    </div>
                </div>
                <div className='box'>
                    <img src={logo_whatsapp}/>
                    <div>
                        <p>Hombres:<span>0</span></p>
                        <p>Mujeres:<span>0</span></p>
                    </div>
                </div>
            </div> : ""}
        </>
    )
}

export default GenderChart