import React, { useState, useEffect, useRef} from 'react'
import classnames from 'classnames'
import { Link, useLocation, useRouteMatch } from 'react-router-dom'
import  './asserts/css/styles.css'
import NewAdvertiser from '../newAdvertiser'
//import { useMousePosition } from "./useMousePosition";
import { Auth, Hub } from "aws-amplify";
const NavItem = ({ title, icon, expanded }) => (
  expanded ? (
    <>
      <i className={icon} /> {title}
    </>
    
  ) : <i className={icon} />
)

const NavSettings = ({userRol}) => {
  const [showModal, setshowModal] = useState(false);
  const [expanded, setExpanded] = useState(false)
  const [userState,setUserState] = useState('user')
  const match = useRouteMatch()
  const location = useLocation()
  //const position = useMousePosition();
  /* console.log('path ', match.path) */
  const ref = useRef()
  /* console.log('desde la navegacion userRol',userRol) */

 /*  console.log('location', location.pathname) */



  //Ocultamos el menu
  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (expanded && ref.current && !ref.current.contains(e.target)) {
        setExpanded(false)
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [expanded])

  useEffect(() => {
    Auth.currentAuthenticatedUser()
    .then((userData) => {
      //  APILI.getCampaignByUser({ account: userData.username })
      if(userData.attributes["custom:userRol"]==='admin'){
        setUserState('admin')
      }else{
        console.log('ES ANUNCIANTE SIDEBAR')
        setUserState('user')
      }
    })
  })

  return (
    <>
    
    <div ref={ref}
      className={classnames('settings-nav d-flex flex-column h-100', {
        'col-md-3': expanded,
        'col-md-1': !expanded,
      })}
    >
      <div
        className={classnames('w-100', {
          titleOneStep: expanded,
          titleOneStepClose: !expanded,
        })}
      >
        <i
          onClick={() => setExpanded(!expanded)}
          className={classnames('', {
            'fas fa-angle-double-left': expanded,
            'fas fa-angle-double-right': !expanded,
          })}
        />
        {expanded && <p className='m-0 btnColaps' onClick={() => setExpanded(!expanded)}>Colapsar</p>}
      </div>
        
      <div
        className={classnames({
          'settings-nav-item': expanded,
          'settings-nav-itemClose': !expanded,
        })}
      >
        <Link to="/dashboard" className='settings-nav-link'>
          <NavItem
            expanded={expanded}
            title='Dashboard'
            icon='fas fa-columns'
          />
        </Link>
      </div>

      {userState === 'admin' && <div
        className={classnames({
          'settings-nav-item': expanded,
          'settings-nav-itemClose': !expanded,
        })}
      >
        <Link to="campaign" className='settings-nav-link'>
          <NavItem
            expanded={expanded}
            title='campañas'
            icon='fas fa-bullhorn'
          />
        </Link>
      </div>
    }
      
      <div
        className={classnames({
          'settings-nav-item': expanded,
          'settings-nav-itemClose': !expanded,
        })}
      >
        <Link to="Moderation" className='settings-nav-link'>
          <NavItem
            expanded={expanded}
            title='Moderación'
            icon='fas fa-eye'
          />
        </Link>
        {userState === 'admin' ?  
           <>
           {expanded && 
            <ul>
              <li><Link to="moderation">Lista de campañas</Link></li>
              <li><Link to="listTask">Lista de tareas</Link></li>
              <li><Link to="profileList">Lista de perfiles</Link></li>
              <li><Link to="userList">Lista de usuarios</Link></li>
              {/* <li><Link to="postList">Lista de Posts</Link></li> */}
              </ul>}
            {!expanded && 
            <ul>
              <li><Link to="moderation">Lista de campañas</Link></li>
              <li><Link to="listTask">Lista de tareas</Link></li>
              <li><Link to="profileList">Lista de perfiles</Link></li>
              <li><Link to="userList">Lista de usuarios</Link></li>
              {/* <li><Link to="postList">Lista de Posts</Link></li> */}
              </ul>}
           </>
        :
          <>
          {expanded && 
            <ul>
              <li><Link to="moderation">Lista de campañas</Link></li>
              </ul>}
            {!expanded && 
            <ul>
              <li><Link to="moderation">Lista de campañas</Link></li>
              </ul>} 
          </>
        }
         
        </div>

        {userState === 'admin' &&
      <div
        className={classnames({
          'settings-nav-item': expanded,
          'settings-nav-itemClose': !expanded,
        })}
      >
        <Link to="finance" className='settings-nav-link'>
          <NavItem
            expanded={expanded}
            title='Finanzas'
            icon='fas fa-chart-line'
          />
        </Link>
      </div>}
{/* 
      <div
        className={classnames({
          'settings-nav-item': expanded,
          'settings-nav-itemClose': !expanded,
        })}
      >
        <Link to="debug" className='settings-nav-link'>
          <NavItem
            expanded={expanded}
            title='Debug'
            icon='fas fa-exclamation-triangle'
          />
        </Link>
      </div> */}
      {userState === 'admin' &&
      <div
        className={classnames({
          'settings-nav-item': expanded,
          'settings-nav-itemClose': !expanded,
        })}
      >
        <Link to="administration" className='settings-nav-link'>
          <NavItem
            expanded={expanded}
            title='Administración'
            icon='fas fa-key'
          />
        </Link>
        {
        expanded && userRol=='admin'&& 
        <ul><li><Link to="advertiser">Lista de anunciantes</Link></li>
        <li><a href="#" onClick={e => setshowModal(true)}>Agregar anunciante</a></li>
        </ul>
        }
        {
        !expanded && userRol=='admin'&& 
        <ul><li><Link to="advertiser">Lista de anunciantes</Link></li>
        <li><a href="#" onClick={e => setshowModal(true)}>Agregar anunciante</a></li>
        </ul>
        }
      </div>}

      {userState === 'admin' &&
      <div
        className={classnames({
          'settings-nav-item': expanded,
          'settings-nav-itemClose': !expanded,
        })}
      >
        <Link to="administrationPanel"className='settings-nav-link'>
          <NavItem
            expanded={expanded}
            title='Panel de Administración'
            icon='fas fa-cog'
          />
        </Link>
      </div>}
      
      <div
        className={classnames({
          'settings-nav-item': expanded,
          'settings-nav-itemClose': !expanded,
        })}
      >
        <Link  className='settings-nav-link'>
          <NavItem
            expanded={expanded}
            title='Registros'
            icon='fas fa-table'
          />
        </Link>
        {
        expanded && userRol=='admin'&& 
        <ul>
        <li><Link to="logs">Bitacoras</Link></li>
        <li><Link to="errorLogs">Logs</Link></li>
        </ul>
        }
        {
        !expanded && userRol=='admin'&& 
        <ul>
        <li><Link to="logs">Bitacoras</Link></li>
        <li><Link to="errorLogs">Logs</Link></li>
        </ul>
        }

      </div>
      {userState === 'admin' &&
      <div
        className={classnames({
          'settings-nav-item': expanded,
          'settings-nav-itemClose': !expanded,
        })}
      >
        <Link to="system"className='settings-nav-link'>
          <NavItem
            expanded={expanded}
            title='Sistema'
            icon='fas fa-laptop-code'
          />
          
        </Link>
        
      </div>}
      {userState === 'admin' &&
      <div
        className={classnames({
          'settings-nav-item': expanded,
          'settings-nav-itemClose': !expanded,
        })}
      >
        <Link to="scan"className='settings-nav-link'>
          <NavItem
            expanded={expanded}
            title='Scan'
            icon='fas fa-book-open'
          />
        </Link>
      </div>}
      <div

className={classnames({

  'settings-nav-item': expanded,

  'settings-nav-itemClose': !expanded,

})}

>

{/* <Link to="scan"className='settings-nav-link'>

  <NavItem

    expanded={expanded}

    title='Escaner'

    icon='fas fa-address-card'

  />

</Link> */}

</div>
    </div>
    {showModal ? <NewAdvertiser title="Crear nuevo anunciante" showAdvertiser={setshowModal} userRol={userRol}/> : <div/>}
    </>
  )
}


export default NavSettings
