import React, { useState, useEffect } from 'react'
import * as utils from 'data.js'
import * as APILI from 'helpers/apiLI';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import ModalModeration from 'components/modalModeration'

import { Auth } from 'aws-amplify';
import _ from 'lodash'
import { getLogoByName, getClassNameByStatus } from 'myUtil'
import DataTableUtils, {
    Share,
    ShowColumn,
    FilterColumn
} from '@easygosanet/react-datatable-utils'
import ReactDataTable from '@easygosanet/react-datatable'
import AddPost from 'components/addPost'
//images
import Logo_facebook from './assets/img/logo_facebook.svg'
import Logo_instagram from './assets/img/logo_instagram.png'
import Logo_tiktok from './assets/img/logo_tiktok.svg'
import logo_linkedin from './assets/img/logo_linkedin.svg'
import { filtro } from "../../helpers/utils";
import { isEmpty, orderBy } from "lodash-es";

// let filteredData = [];
const PostList = (props) => {

    const[tables,setTables]= useState([]);
    const[posts,setPost]= useState([]);
    const [showModal, setshowModal] = useState(false);
    let [taskTable, setTaskTable] = useState([])
    const [filters, setFilters] = useState({})
    const [dataModeration, setdataModeration] = useState('')
    const [showModeration, setshowModeration] = useState(false)
    const [refreshTable, setrefreshTable] = useState(false)
    // const [filterText, setFilterText] = useState('')
    // const [filteredData, setFilteredData] = useState([])
     //add Luis
  const [filterText, setFilterText] = useState('')
  let [table, setTable] = useState([]);
  //add finish Luis
  const [filteredData, setFilteredData] = useState([])

    // console.log("DAta POSTS", posts)
//    filteredData = posts?.filter(
//        item =>item.activity.toLowerCase().includes(filterText.toLowerCase()),
//        item =>item.name.toLowerCase().includes(filterText.toLowerCase()),
//        item =>item.socialmedia.toLowerCase().includes(filterText.toLowerCase()),
//     //    item =>item.activity.toLowerCase().includes(filterText.toLowerCase()),
//    );
    // function matchFilter(text, list) {
    //     const filter = text.toLowerCase()
    //     return list.some(item => item.toLowerCase().includes(filter))
    //   }
    // if (!isEmpty(filters)) {
    //     filteredData = filtro(filters, filteredData);
    //     // getDataFilter(filteredData)
    // }
// console.log(filteredData);
const MySwal = (data) => {
    console.log(data)
    let influencerName = ''
    let influencerLastName = ''
    console.log(influencerName)
    console.log(influencerLastName);

  const updateTaskStatus = async(taskID, taskStatus) => {
    const update = {
        id: taskID,
        status: taskStatus
    }
    const updateTable = ()=>{
        setrefreshTable(!refreshTable)
    }
    await APILI.updateTaskStatusByID(update).then((state) => {
        updateTable()
    })
    }

     
        Swal.fire({
            title: data.campaignName,
            text: 'Usuario:, ' + data.userID + ' Seguidores: '+ data.scope +
            'Desea participar del reto:, ' + data.campaignName,
            html:
    '<a href="//sweetalert2.github.io">Ver publicacion</a> ' +
    'and other HTML tags',
            icon: 'info',
            showCancelButton: true,
            showDenyButton: true,
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: '#00CA72',
            denyButtonText: '#d33',
            denyButtonText:
          '<i class="fa fa-thumbs-down"></i>',
            confirmButtonText:
          '<i class="fa fa-thumbs-up"></i> Aprobar',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                updateTaskStatus(data.id,"Active")      
              Swal.fire({
                  title: 'Confirmado!', 
                    icon: 'success',
                      timer: 1500})
            } else if (result.isDenied) {
                updateTaskStatus(data.id,"Rejected")
                Swal.fire({
                    title: 'Rechazado!', 
                      icon: 'error',
                        timer: 1500})
            }
          })
    
}
const WatchPost = (data) => {
    /* console.log(data) */
    let influencerName = ''
    let influencerLastName = ''
    /* console.log(influencerName)
    console.log(influencerLastName); */

  const updatePostStatusFN = async (postID, postStatus) =>{
    const update = {
        id: postID,
        status: postStatus
    }
    const updateTable = () => {
        setrefreshTable(!refreshTable)
    }
    await APILI.updatePostStatusByID(update).then((state) =>{
        updateTable();
    })
    }

     
        Swal.fire({
            title: data.campaignName,
            html: `Ver publicacion:  ${data.userID} ${data.campaignName}<b>test</b>
            `,  
            icon: 'info',
            showCancelButton: true,
            showDenyButton: true,
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: '#00CA72',
            denyButtonText: '#d33',
            denyButtonText:
          '<i class="fa fa-thumbs-down"></i>',
            confirmButtonText:
          '<i class="fa fa-thumbs-up"></i> Aprobar',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                /* console.log(data.id) */
                updatePostStatusFN(data.id,"Active")      
              Swal.fire({
                  title: 'Confirmado!', 
                    icon: 'success',
                      timer: 1500})
            } else if (result.isDenied) {
                updatePostStatusFN(data.id,"Rejected")
                Swal.fire({
                    title: 'Rechazado!', 
                      icon: 'error',
                        timer: 1500})
            }
          })
    
}
const DeliveryCol = ({ text, className, onClick }) => (
    <div className={className} >
        <div className="delivery-text">
            {text}
        </div>
    </div>
)
const customStyles = {
    headCells: {
        style: {
            '&:nth-last-of-type(2)': {
                backgroundColor: '#E3E4E6',
                width: '40px'
            },
            '&:nth-last-of-type(4)': {
                backgroundColor: '#E3E4E6',
                width: '40px'
            },
            '&:nth-last-of-type(6)': {
                backgroundColor: '#E3E4E6',
            },
        },
    },
}
    useEffect(() => {
        Auth.currentAuthenticatedUser().then((userData) => {
            APILI.getInfluencerPosts().then((influencerPosts) => {
                setPost(influencerPosts.data.influencerPosts)
                getDataView(influencerPosts.data.influencerPosts)
            })
        })
    }, [refreshTable])
    // useEffect(() => {
    //     // if(filters.length>0){
    //     setTables({
    //         titles: [
    //             {
    //                 name: 'Nombre del reto o tarea',
    //                 selector: 'name',
    //                 sortable: true,
    //                 minWidth: '200px',
    //                 style: {
    //                     color: '#02025b',
    //                 },
    //                 cell: (row) => (
    //                     <DetailCol
    //                         text={row.name}
    //                         data={row}
    //                     />
    //                 ),
    //             },
    //             {
    //                 name: 'Estado',
    //                 selector: 'delivery',
    //                 sortable: true,
    //                 center: true,
    //                 style: {
    //                     backgroundColor: '#E3E4E6',
    //                     fontWeight: 'bold',
    //                 },
    //                 type: 'text',
    //                 cell: (row) => (
    //                     <DeliveryCol 
    //                     onClick={() => setshowModeration(true)}
    //                         text={row.delivery.name}
    //                         className={row.delivery.className}
    //                     />
    //                 )
    //             },
    //             {
    //                 name: 'Campaña',
    //                 selector: 'campaignName',
    //                 sortable: true,
    //                 center: true,
    //                 style: {
    //                     fontWeight: 'bold',
    //                 }
    //             },
    //             {
    //                 name: 'Tipo de Publicacion',
    //                 selector: 'activity',
    //                 sortable: true,
    //                 center: true,
    //                 style: {
    //                     backgroundColor: '#E3E4E6',
    //                     fontWeight: 'bold',
    //                 },
    //             },
    //             {
    //                 name: 'POST',
    //                 selector: 'source',
    //                 sortable: true,
    //                 center: true,
    //                 style: {
    //                     fontWeight: 'bold',
    //                 },
    //                 cell: (row) => <img style={{ maxWidth:"100px", maxHeight:"100px"}} className="w-70" src={row.source} alt="" />
    //             },
    //             {
    //                name: 'Red Social',
    //                selector: 'socialMedia',
    //                sortable: true,
    //                center: true,
    //                style: {
    //                 backgroundColor: '#E3E4E6',
    //                   fontWeight: 'bold',
    //                 },
    //                cell: (row) => <img className="source-img" src={row.socialMedia} alt="" />
    //             },
    //             {
    //                 name: 'Alcance',
    //                 selector: 'scope',
    //                 sortable: true,
    //                 center: true,
    //                 style: {
    //                     fontWeight: 'bold',
    //                 },
    //             },
    //         ],
    //         data: filteredData.map(post => ({
    //           name: post.name,//post.message ? post.message : post.activity,
    //           delivery: {
    //             name: post.status,
    //             className: getClassNameByStatus(post.status)
    //         },
    //         campaignName: 'post.campaign,',
    //         hashtags: 'post.campaign,',
    //         activity: post.activity,
    //         source: post.picture,
    //         socialMedia: getLogoByName(post.socialmedia),
    //         scope: post.scope,
    //         id: post.id,
    //         userID: post.userID,
    //         scope: post.scope
    
    //         }))
    //     }
    //     )
        
    // // }
    // }, [filteredData])

    const handleFilterTextChange = (value) => {
        setFilterText(value)
      }

    // useEffect(() => {
    //     const filtered = tables.map(table => table.data.filter((item) =>
    //       matchFilter(filterText, [
    //         item.activity,
    //         item.name,
    //         item.rating,
    //         item.socialmedia,
    //         item.status,
    //       ])
    //     )
    //     )
    //     setFilteredData(filtered)
    //   }, [filterText, tables])

    
    const goToEditPage = (id, name, createAt) => {
        let edit = {
            id,
            name,
            createAt,
        }
        props._setEdit(edit)

        window.$.fancybox.open({
            src: '#new-category-modal',
            //type: 'modal',
            opts: {
                modal: true,
                afterClose: () => props._clearEdit(),
            },
        })
    }

    const showModerationPopup = (data) => {
        setshowModeration(true)
        let objeto = {
            "title": data.campaignName,
            "date": data.date,
            "id": data.id,
            "name": data.name,
            "source": data.source,
            "username": data.userID,
        }
        setdataModeration(objeto)
       
    }
    const DetailCol = ({ text, data}) => (
        <div className="firstColl w-100">
            <div className="icon-container">
                <i onClick={() =>WatchPost(data)}   className="fas fa-eye"></i>
                <i onClick={() => showModerationPopup(data)} className="fas fa-edit"></i>
                <i class="fas fa-trash"></i>
                <i class="fab fa-telegram-plane"></i>
                <i class="fas fa-ellipsis-v" ></i>
            </div>
            <p className="name-fc">
                {text}
            </p>
        </div>
    )

    const [columns, setColumns] = useState([
        {
            name: 'Nombre del reto o tarea',
            selector: 'name',
            sortable: true,
            minWidth: '200px',
            style: {
                color: '#02025b',
            },
            cell: (row) => (
                <DetailCol
                    text={row.name}
                    data={row.id, row.name, row.createAt}
                />
            ),
        },
        {
            name: 'Estado',
            selector: 'delivery',
            sortable: true,
            center: true,
            style: {
                backgroundColor: '#E3E4E6',
                fontWeight: 'bold',
            },
            type: 'text',
            cell: (row) => (
                <DeliveryCol
                    text={row.status}
                    className={getClassNameByStatus(row.status)}
                    
                />
            )
        },
        {
            name: 'Campaña',
            selector: 'hashtags',
            sortable: true,
            center: true,
            style: {
                fontWeight: 'bold',
            }
        },
        {
            name: 'Fecha de vencimiento',
            selector: 'scope',
            sortable: true,
            center: true,
            style: {
                backgroundColor: '#E3E4E6',
                fontWeight: 'bold',
            },
        },
        {
            name: 'Post',
            selector: 'source',
            sortable: true,
            center: true,
            style: {
                fontWeight: 'bold',
            },
            cell: (row) => <img  src={row.source} alt="" />
        },
        {
            name: 'Alcance',
            selector: 'inscription',
            sortable: true,
            center: true,
            style: {
                fontWeight: 'bold',
            },
        },
    ])

    const handleFilterPerColumn = (column, type, value) => {
        setFilters((prev) => ({ ...prev, [column]: { type, value } }))
    }

    const handleShowColumnChange = (column, show) => {
        let index = columns.findIndex((col) => col.selector === column)
        let aux = columns.map((col) => col)
        aux[index].omit = show
        setColumns(aux)
    }

    const handleColumnSortChange = (positions) => {

        let aux = columns.map((col) => col)

        positions.forEach((columnId, position) => {
            let index = columns.findIndex((col) => col.selector === columnId)
            aux[index].position = position
        })
        aux = _.orderBy(aux, ['position'])

        setColumns(aux)
    }

/******************************************************** */
const getDataView = (data) => {
    /* console.log("post list",data) */
    setTables({
        titles: [
            {
                name: 'Nombre del reto o tarea',
                selector: 'name',
                sortable: true,
                minWidth: '200px',
                style: {
                    color: '#02025b',
                },
                cell: (row) => (
                    <DetailCol
                        text={row.name}
                        data={row}
                    />
                ),
            },
            {
                name: 'Estado',
                selector: 'delivery',
                sortable: true,
                center: true,
                style: {
                    backgroundColor: '#E3E4E6',
                    fontWeight: 'bold',
                },
                type: 'text',
                cell: (row) => (
                    <DeliveryCol 
                    onClick={() => setshowModeration(true)}
                        text={row.delivery.name}
                        className={row.delivery.className}
                    />
                )
            },
            {
                name: 'Campaña',
                selector: 'campaignName',
                sortable: true,
                center: true,
                style: {
                    fontWeight: 'bold',
                }
            },
            {
                name: 'Tipo de Publicacion',
                selector: 'activity',
                sortable: true,
                center: true,
                style: {
                    backgroundColor: '#E3E4E6',
                    fontWeight: 'bold',
                },
            },
            {
                name: 'POST',
                selector: 'source',
                sortable: true,
                center: true,
                style: {
                    fontWeight: 'bold',
                },
                cell: (row) => <img style={{ maxWidth:"100px", maxHeight:"100px"}} className="w-70" src={row.source} alt="" />
            },
            {
               name: 'Red Social',
               selector: 'socialMedia',
               sortable: true,
               center: true,
               style: {
                backgroundColor: '#E3E4E6',
                  fontWeight: 'bold',
                },
               cell: (row) => <img className="source-img" src={row.socialMedia} alt="" />
            },
            {
                name: 'Alcance',
                selector: 'scope',
                sortable: true,
                center: true,
                style: {
                    fontWeight: 'bold',
                },
            },
        ],
        data: data.map(post => ({
          name: post.name,//post.message ? post.message : post.activity,
          delivery: {
            name: post.status,
            className: getClassNameByStatus(post.status)
        },
        campaignName: 'post.campaign,',
        hashtags: 'post.campaign,',
        activity: post.activity,
        source: post.picture,
        socialMedia: getLogoByName(post.socialmedia),
        scope: post.scope,
        id: post.id,
        userID: post.userID,
        scope: post.scope

        }))
    }
    )
    const filtered = data.filter((item) =>
    matchFilter(filterText, [
   
      item.name,
      item.campaignName,
       item.hashtags,
       item.activity,
      // item.budget,
      // item.reward,
      // item.inscription,
      // item.delivery.name,
    ])
    )
    setFilteredData(filtered)

  }
  const getDataFilter = (data) => {
    console.log(data)
  
  }
/******************************************************** */
  function getTasks(listCampaigns, category) {
    let myCampaigns = []
    listCampaigns.forEach(campaign => {
      campaign.mediaCategories.forEach(categoryE => {
        if (categoryE.name === category) {
          myCampaigns.push(campaign)
        }
      });
    });
    return myCampaigns
  }
/******************************************************** */

//Luis copy mario
function matchFilter(text, list) {
   /*  console.log("texto de matchFilter ",text)
    console.log("texto de list ",list) */
    const filter = text.toLowerCase()
    /* console.log("texto de filter ",filter) */
    return list.some(item => item?.toLowerCase().includes(filter))
  }
  
  useEffect(() => {
      /* console.log("desdeee mapa",tables) */
    //   const tabla = tables.data.map(table=>{
    //   console.log("desdeee mapa",table)
    // })
  
    // console.log("tablaaa",tabla)
    const filtered = tables.data?.filter((item) =>
    matchFilter(filterText, [
        item.name,
        item.campaignName,
         item.hashtags,
         item.activity,
    //   item.authorizedAt,
      // item.budget,
      // item.reward,
      // item.inscription,
      // item.delivery.name,
    ])
  )
    setFilteredData(filtered)
  }, [filterText, tables])

return (
        <div className='form-dashboard'>
        {showModeration ?  
        <ModalModeration functionName={"post"} data={dataModeration} showModeration={setshowModeration} /> 
        : null}
            <div id='shadow' className='shadow hide'></div>
            <div className='Bar-content'>
                <div className='content-menu list'>
                    <select
                        id='viewSelect'
                        className='select-dashboard fa'
                    >
                        <option className='fa' value='1'>
                            {' '}
                            &#xf0ce;  Listado de Posts
                        </option>
                        {/* <option className='fa' value='0'>
                            {' '}
                            &#xf0db; Vista de Kanban{' '}
                        </option>
                        <option className='fa' value='2'>
                            {' '}
                            &#xf073; Vista de calendario
                        </option> */}
                    </select>
                    < DataTableUtils onChangeFilterText={value => setFilterText(value)}>
                        <Share
                            contacts={utils.myContacts}
                        />
                        <ShowColumn
                            columns={columns}
                            showColumn={handleShowColumnChange}
                            onSortChange={handleColumnSortChange}
                        />
                        <FilterColumn
                            columns={columns}
                            onFilterColumn={handleFilterPerColumn}
                        />
                    </DataTableUtils>
                    <div
                        className='btnOrange-dash'
                        onClick={e => setshowModal(true)}>
                        <i className='fas fa-plus'></i>
                    </div>
                </div>
            </div>
            <div className="body-container">
                <div className="content-row">
                    <div className="title-grid">
                        Listado de Posts
                    </div>

                    {/* <select className='select-dashboard'>
                        <option value='1'>
                            &#xf0ce; Últimos 30 dias
                        </option>
                    </select> */}
                </div>
            </div>
        <div className="row-section">
          <ReactDataTable
            columns={tables.titles}
            customStyles={customStyles}
            data={filteredData}
            noDataComponent={"No hay datos para mostrar"}
            persistTableHead
            pagination
            noHeader
          />
        </div>
        {showModal ? <AddPost title="Agregar Post" showModal={setshowModal} /> : null}

        </div>
    )
}


export default PostList
