import React, { useState, useEffect } from 'react'
import * as STORAGE from 'helpers/storageLI';
import ImageGallery from 'react-image-gallery';
import { Carousel } from 'antd';


//Style
import "react-image-gallery/styles/css/image-gallery.css"


const Galery = ({ names }) => {
    let imagesArray=[];
    let [images, setImages] = useState([]);

    async function getImage(name) {
        let img = await STORAGE.listFiles(name.name);
       imagesArray.push({
        original: img,
        disableKeyDown: true,
      },);
    }

    useEffect(() => {
        loadImages();
    }, [])
    
    function loadImages() {
        names.forEach(name =>  getImage(name))
        setImages(imagesArray)
    }

    return (
        <>
            <ImageGallery items={images} disableKeyDown={true} />
        
        
        </>
    )
}

export default Galery