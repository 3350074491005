import Storage from '@aws-amplify/storage'
import * as Logger from 'utils/logger'

const TAG = 'listImages'

export const uploadFile = (name, content, options) => {
  console.log("upload a file")
  console.log(name, content, options);
  return Storage.put(name, content, options)
}

export const listFiles = (path) => {
  Logger.log(TAG, 'listing files')

  let options = {
    level: 'public'
  }

  /* console.log("path::: ",path) */

  Logger.log(TAG, 'with path', path)
  Logger.log(TAG, 'with options', options)

  return Storage.get(path, options)
}

  export const getUrlImage =(key) => {
  try {
    const options = {
      level: 'public',
    };
    return Storage.get(key);
  } catch (error) {
    console.log('error en imagen---', error);
  }
}