import React from 'react';

const Tag = ({name, deleteTag}) => {

  return (
    <div className="hashtag color">
      <p>{name}</p>
      <i class="fas fa-times" onClick={e => deleteTag(name)}></i>
    </div>   
  )
}

export default Tag