export const APP_LOADED = 'APP:LOADED'
export const APP_SET_CURRENT_USER = 'APP:SET:CURRENT:USER'
export const APP_CLEAR_CURRENT_USER = 'APP:CLEAR:CURRENT:USER'

export const appLoaded = () => ({ type: APP_LOADED })

export const setCurrentUser = (user) => ({
  type: APP_SET_CURRENT_USER,
  user,
})

export const clearCurrentUser = () => ({
  type: APP_CLEAR_CURRENT_USER,
})
