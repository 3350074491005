import { find } from 'lodash'

import * as API from 'helpers/api'
import * as Logger from 'utils/logger'
import { showLoader } from './loader.actions'

export const CLIENTS_CLEAR = 'CLIENTS:CLEAR'
export const CLIENTS_ADD_ONE = 'CLIENTS:ADD:ONE'
export const CLIENTS_ADD_LIST = 'CLIENTS:ADD:LIST'

const TAG = 'CLIENTS:ACTION'

export const getClients = () => dispatch => {
  dispatch(showLoader(true))
  API.getClients()
    .then(response => {
      Logger.log(TAG, 'getClients', response)
      let { clients } = response.data

      clients.forEach(client_response => {
        let nameAttr = find(client_response.Attributes, { 'Name': 'name' })
        let emailAttr = find(client_response.Attributes, { 'Name': 'email' })

        let client = {
          id: client_response.Username,
          name: nameAttr ? nameAttr.Value : '',
          email: emailAttr ? emailAttr.Value : '',
        }

        dispatch({
          type: CLIENTS_ADD_ONE,
          client
        })
      })
    })
    .catch(err => {
      Logger.log(TAG, 'getClients', err)
    })
    .then(() => {
      dispatch(showLoader(false))
    })
}

export const clearClients = () => dispatch => {
  dispatch({
    type: CLIENTS_CLEAR
  })
}

export const addClient = (client) => dispatch => {
  dispatch({
    type: CLIENTS_ADD_ONE,
    client
  })
}

export const addClientList = clients => dispatch => {
  dispatch({
    type: CLIENTS_ADD_LIST,
    clients
  })
}