import React, { useState, useEffect, useRef } from 'react'
import * as utils from 'data.js'

import DataTableUtils, { Share } from '@easygosanet/react-datatable-utils';
import ReactDataTable, { DateRange } from '@easygosanet/react-datatable';

//helpers
import * as APILI from '../../helpers/apiLI';

//Images
import Logo_facebook from './assets/img/logo_facebook.svg';
import Logo_instagram from './assets/img/logo_instagram.png';

//components
import SelectorBox from 'components/selector';
import TargetContentMap from 'components/targetContentMap';
import TargetContentText from 'components/targetContentText';
import TargetContentNetworks from 'components/targetContentNetworks';
import StackBarGraph from 'components/stackBarGraph';
import AutoComplete from './autoComplete';
import Tag from './tag';
import TargetContent from 'components/targetContent';
import InfoBox from 'components/infoBox';
import InfluencerTasks from '../influencerTasks';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import Typography from '@material-ui/core/Typography';
import NativeSelect from '@material-ui/core/NativeSelect';
import Paper from '@material-ui/core/Paper';
import IconBack from '../campaign/assets/img/arrow-left.png'
//Delivery Column
import { getLogoByName, getClassNameByStatus } from 'myUtil'

//css
import './style.scss'
import { AmplifyLoadingSpinner } from '@aws-amplify/ui-react'

const DeliveryCol = ({ text, className, onClick }) => (
  <div className={className}>
    <div className={"delivery" + (text)}>
      {text}
    </div>
  </div>
)

//Tables style

const customStyles = {
  headCells: {
    style: {
      '&:nth-last-of-type(2)': {
        backgroundColor: '#E3E4E6',
        width: '40px'
      },
      '&:nth-last-of-type(4)': {
        backgroundColor: '#E3E4E6',
        width: '40px'
      },
      '&:nth-last-of-type(6)': {
        backgroundColor: '#E3E4E6',
      },
      '&:nth-last-of-type(7)': {
        font: 'normal normal bold 16px / 12px PierSans',
        color: '#05252D'
      },
    },
  },
}

function calculate_age(dob) { 
  var diff_ms = Date.now() - dob.getTime();
  var age_dt = new Date(diff_ms); 

  return Math.abs(age_dt.getUTCFullYear() - 1970);
}

const Influencer = ({influencerInfoPage, refreshTable, setrefreshTable, showInfluencerPage}) => {

const {username,email,name,lastname,socialStatus,
      phone,direction,status,categories,
      interested,dateBirth,gender,id, campaigns, location
      }=influencerInfoPage

      // change
const [tableTitle,setTableTitle] = useState('Iniciado')
const[tables,setTables]= useState([]); 
const [firstState,setFirstState] = useState(true);
const [secondState,setSecondState] = useState(false);
const [thirdState,setThirdState] = useState(false);
const [fourthState,setFourthState] = useState(false);
const [mediaCategories, setMediaCategories] = useState([]);
const [averageScope, setAverageScope] = useState(0);
const [averageInteraction, setAverageInteraction] = useState(0);
const [percentInteraction, setPercentInteraction] = useState(0);
const [editAverageScope,setEditAverageScope] = useState(false);
const [editAverageInteraction,setEditAverageInteraction] = useState(false);
const [editPercentInteraction,setEditPercentInteraction] = useState(false);

const [socialStatusText, setSocialStatus] = useState([])
const [open, setOpen] = useState(false);
const loading = open
const [tags, setTags] = useState([]);

const [categoryTags,setCategoryTags] = useState([]) 
const [socialStatusValue, setSocialStatusValue] = useState(socialStatus);
const [paymentData,setPaymentData] = useState({
  "amount": 0,
"campaignName": "",
"createdAt": "",
"currency": "USD",
"id": "",
"socialmedia": "",
"state": "",
"updatedAt": "",
"userId": ""

})
const [walletData,setwalletData] = useState({
  "balance":0,
  "currency":'USD',
  "id":'',
  "updatedAt":'',
})
const [influencerData, setInfluencerData] = useState({
  "id": "0",
  "phoneNumber": "0",
  "dateBirth": "01-01-1990",
  "status": "",
  "tasks": [],
  "email": "",
  "country": "",
  "name": "",
  "gender": "",
  "lastname": "",
  "bankAccount": "",
  "direction": "",
  "socialNetworks": [],
  "username": "",
  "interested":[],
  "rating":0,
  "location": {coords:{
    latitud:0,
    longitud:0
  }}
})

const [walletStats,setWalletStats] = useState([
  {
    text: 'Saldo',
    number: 0,
  },
  {
    text: 'Ultimo monto de pago',
    number: 0,
  },
  {
    text: 'Ultimo fecha de pago',
    number: 0,
  }
])
const [paymentStats,setPaymentStats]= useState([
  {
    text: 'Cost Promedio de post',
    number: 0
  },
  {
    text: 'Pago Promedio',
    number: 0
  }
])
const [actStats,setActStats]= useState([
  {
    text: 'Total de campañas',
    number: 0,
  },
  {
    text: 'Total de post',
    number: 0,
  }
])
const [postAct,setPostAct]= useState([
  {
    text: 'En revisión',
    number: 0,
  },
  {
    text: 'Aceptados',
    number: 0,
  },
  {
    text: 'Rechazados',
    number: 0,
  },
  {
    text: 'Listo para pagar',
    number: 0,
  },
  {
    text: 'Pagado',
    number: 0,
  }
])
const [testMarker, setTestMarkers] = useState([])

const refGeneral = useRef(null);
const refRedes = useRef(null);
const refPagos = useRef(null);
const refAcciones = useRef (null);

  useEffect(() => {
    if(influencerInfoPage?.location && influencerInfoPage?.location?.coords){
    setTestMarkers([
      {"uid": "8763a5d0-25ac-48e7-af1b-491d0bd59bb9",
      "lat": influencerInfoPage?.location?.coords?.latitude,
         "lng": influencerInfoPage?.location?.coords?.longitude,
         "metersperpixel": 0, 
         zoom:12,
         range:  "0",
         }
    ])
  }
  }, [influencerInfoPage])

  console.log("location",influencerData.location)
  console.log("testMarker",testMarker)

  if(!categories)
  setCategoryTags([])
let intereses = ''
if(interested !== undefined){

  interested?.forEach(element => {
    intereses += element.name + ','  
    });
    intereses= intereses.slice(0, -1) 

}

useEffect(() => {
  if(categories!==undefined||categories!==null){
    setCategoryTags(categories)
  }
  if(socialStatus){
    setSocialStatusValue(socialStatus)
  }
}, [categories,socialStatus])
//useEffect get wallet data 
useEffect( async() => {
  //wallet
  let body = {
    id:influencerInfoPage.id
  }
  await APILI.getWalletbyId(body).then(res => {
    
    getWalletData(res.data.wallet)
  }).catch(err => {
    console.log(err)
  })
  //registerpayment
   body = {
    userId:influencerInfoPage.id,
    state:"Payment"
  }
  await APILI.getPaidbyUser(body).then(res => {
    getPaymentData(res.data.registerPayment)
    
  }).catch(err => {
    console.log(err)
  })
    }, [])

    //load media categories
    useEffect(async() => {
    
     
        await APILI.getMediaCategories().then(res => {
          getMediaCategories(res.data.categories)
          console.log(res.data.categories)
        })
        
        await APILI.walletStats({userID:id}).then(res => {
          
          setWalletStats([
            {
              text: 'Saldo',
              number: res.data.postActivity[0].value,
            },
            {
              text: 'Ultimo monto de pago',
              number: res.data.postActivity[1].value,
            },
            {
              text: 'Ultimo fecha de pago',
              number: res.data.postActivity[2].value,
            }
          ])
        })
        await APILI.paymentAvgStats({userID:id}).then(res => {
          
          setPaymentStats([
            {
              text: res.data.postActivity[0].name,
              number: res.data.postActivity[0].value
            },
            {
              text: res.data.postActivity[1].name,
              number: res.data.postActivity[1].value
            }
          ])
        })
        await APILI.activitieStats({userID:id}).then(res => {
          console.log('Activitie Stats',res.data)
          setActStats([
            {
              text: 'Total de campañas',
              number: res.data.postActivity[0].value,
            },
            {
              text: 'Total de post',
              number: res.data.postActivity[1].value,
            }
          ])
          
        })

        await APILI.profileStats({userID:id}).then(res => {
          console.log('Profile Stats',res.data)
          setPostAct([
            {
              text: 'En revisión',
              number: res.data.postActivity[0].value,
            },
            {
              text: 'Aceptados',
              number: res.data.postActivity[1].value,
            },
            {
              text: 'Rechazados',
              number: res.data.postActivity[2].value,
            },
            {
              text: 'Listo para pagar',
              number: res.data.postActivity[3].value,
            },
            {
              text: 'Pagado',
              number: res.data.postActivity[4].value,
            }
          ])
         
        })

  }, []);
  useEffect(() => {
    uptadeCategories()
  }, [open])

const getMediaCategories =  (data) => {
  
  setMediaCategories(data)
  
}

const uptadeCategories = () => {
  let cats = mediaCategories 
  for(let i = 0; i < categoryTags.length; i++)
  {
    console.log('categoryTags[i]',categoryTags[i])
    let categoriesFiltered = cats.filter((item) =>
    item.name!=categoryTags[i]
  )
  cats = categoriesFiltered
  /* setMediaCategories(categoriesFiltered) */
  }
  console.log('cats', cats)
  setMediaCategories(cats)
}
  //savecategory tag: create an array of categories listed
  const saveCategoryTag = cat => {
    if (cat) {
        const textHastag = cat.name
        let newTags = categoryTags.filter(tag => tag !== textHastag.trim())
        newTags.push(textHastag.trim())
        setCategoryTags(newTags)
        saveCategories(newTags)
        
    }  
}
const deleteCategoryTag = (tagName) => {
  
  const newTags = categoryTags.filter(tag => tag !== tagName)
  setCategoryTags(newTags)
  saveCategories(newTags)
  
}

//const {influencerData}=influencerData

const getWalletData = (data) => {
  if(data){
    setwalletData(data)
  }else{
    setwalletData({
      "balance":0,
      "currency":'USD',
      "id":'',
      "updatedAt":'',
    })
  }
  
}

const saveSocialStatus = async (status) => {
  setSocialStatusValue(status)

  let body = {
    id:id,
    socialStatus:status
  }
  await APILI.updateInfluencerSocialStatus(body).then((res) => {
    console.log("Actualizado")
  }).catch((err) => {
    console.log(err)
  })
  
}
const getPaymentData = (data) => {
 // data.length > 0 ? setPaymentData(data) : setPaymentData([])
  setPaymentData(data[data.length-1])
  
  //let lastPayment = data[data.length-1]
}
const emptyArray = []
const listGeneralStatistics = [
{
  text: 'Cuenta',
  number: id,
},
{
  text: 'Nombre completo',
  number: name,
},
{
  text: 'Fecha de nacimiento',
  number: dateBirth,
},
{
  text: 'Edad',
  number: calculate_age(new Date(dateBirth)),
},
{
  text: 'Genero',
  number: gender,
},
{
  text: 'Email',
  number: email,
},
{
  text: 'Preferencias',
  number: intereses,
},
]
 /******************************************************** */
 
const dataAccess = [
  {
    text: 'Ultima direccion IP',
    number: '200.119.187.128',
  },
  {
    text: 'Ultimo ingreso',
    number: '16 de febrero de 2020 H: 15:02:01',
  },
  {
    text: 'Ultimo Backend',
    number: 'Facebook',
  },
  {
    text: 'Ultimo cliente',
    number: 'LikeInc/1.2.4; IOS 14.4.0',
  },
  {
    text: 'Ultima version',
    number: '1.2.4',
  },
  ]
   /******************************************************** */
   let dataBalance=[]
  if(walletData.balance){ 
 dataBalance = [
  {
    text: 'Saldo',
    number: walletData.currency+" "+walletData.balance,
  },
  {
    text: 'Ultimo monto de pago',
    number: paymentData?.currency+" "+(paymentData?.amount),
  },
  {
    text: 'Ultimo fecha de pago',
    number: walletData.updatedAt,
  }
  ]
   /******************************************************** */
}
else{
   dataBalance = [
    {
      text: 'Saldo',
      number: '0.00 USD',
    },
    {
      text: 'Ultimo monto de pago',
      number: '0.00 USD',
    },
    {
      text: 'Ultimo fecha de pago',
      number: '',
    }
    ]}
      /******************************************************** */
const activityData = [
  {
    text: 'Total de campañas',
    number: campaigns.length,
  },
  {
    text: 'Total de post',
    number: '10',
  }
  ]
   /******************************************************** */
       
const postActivityData = [
  {
    text: 'Incompletas',
    number: '20',
  },
  {
    text: 'En revisión',
    number: '10',
  },
  {
    text: 'Aceptados',
    number: '35',
  },
  {
    text: 'Rechazados',
    number: '10',
  },
  {
    text: 'Listo para pagar',
    number: '10',
  },
  {
    text: 'Pagado',
    number: '10',
  }
  ]
   /******************************************************** */
         
const socialActivityData = [
  {
    text: 'Alcance promedio',
    number: '0',
  },
  {
    text: 'Interacción promedio',
    number: '0',
  },
  {
    text: 'Tasa de interacción promedio',
    number: '0',
  },
  {
    text: 'Redes Sociales',
    number: '',
  }
  ]
   /******************************************************** */
const getDataView = (data) => {
  
  
  setTables({
      titles: [
          {
              name: 'Nombre del reto o tarea',
              selector: 'name',
              sortable: true,
              minWidth: '200px',
              style: {
                  color: '#02025b',
              },
              cell: (row) => (
                  <DetailCol
                      text={row.name}
                      data={row}
                  />
              ),
          },
          {
              name: 'Estado',
              selector: 'delivery',
              sortable: true,
              center: true,
              style: {
                  backgroundColor: '#E3E4E6',
                  fontWeight: 'bold',
              },
              type: 'text',
              cell: (row) => (
                  <DeliveryCol
                      text={row.delivery.name}
                      className={row.delivery.className}
                  />
              )
          },
          {
              name: 'Activity',
              selector: 'campaignName',
              sortable: true,
              center: true,
              style: {
                  fontWeight: 'bold',
              }
          },
          {
              name: 'Ultimo cambio',
              selector: 'updatedAt',
              sortable: true,
              center: true,
              style: {
                  backgroundColor: '#E3E4E6',
                  fontWeight: 'bold',
              },
          },
          {
              name: 'Red Social',
              selector: 'source',
              sortable: true,
              center: true,
              style: {
                  fontWeight: 'bold',
              },
              cell: (row) => <img className="source-img" src={row.source} alt="" />
          },
          {
              name: 'Pago',
              selector: 'amount',
              sortable: true,
              center: true,
              style: {
                  fontWeight: 'bold',
              },
          },
          {
            name: 'Influencer',
            selector: 'userId',
            sortable: true,
            center: true,
            style: {
                fontWeight: 'bold',
            },
        },
      ],
      data: data.map(dat => ({
        name: dat.campaignName,
        delivery: {
          name: dat.state,
          className: getClassNameByStatus(dat.state)
      },
      campaignName: dat.campaignName,
      updatedAt: new Date(dat.updatedAt).toString(),
      source: getLogoByName(dat.socialmedia),
      inscription: dat.scope,
      id: dat.id,
      userId: dat.userId,
      price: dat.price,
      socialmedia: dat.socialmedia,
      activityName: dat.name,
      amount: dat.currency+" "+parseFloat(dat.amount).toFixed(2)

       
      }))
  }
  )
}

/******************************************************** */
const DetailCol = ({ text, data}) => (
  <div className="firstColl w-100">
      <div className="icon-container" style={{backgroundColor: "#FF9015",}}>
          <i onClick={() =>MySwal(data)} class="fas fa-eye"></i>
          <i className="fas fa-edit"></i>
          <i class="fas fa-trash"></i>
          <i class="fab fa-telegram-plane"></i>
          <i class="fas fa-ellipsis-v"></i>
      </div>
      <p className="name-fc">
          {text}
      </p>
  </div>
)

//Pendiente first
  useEffect(() => {
    let tempstate
    if (firstState) tempstate = "Pendiente"
    if (secondState) tempstate = "Approved"
    if (thirdState) tempstate = "Billing"
    if (fourthState) tempstate = "Payment"
    const body ={
      state:tempstate
    }
     APILI.getPaymentByStatus(body).then(res => {
      getPaymentsData(res.data.registerPayment)
    }).catch(err => {
      console.log(err)
    })
   
    const body2 ={
      "id":id
    }

  }, [refreshTable])

 
  //get influencer data
  const getInfluencerData = (data) => {
    setInfluencerData(data)
    setAverageScope(data.averageScope)
    console.log(data)
    setAverageInteraction(data.averageInteraction)
    setPercentInteraction(data.interactionPercent) 
  }
//paymentsDat
//Pendiente first
useEffect(async() => {
 
  const body2 ={
    "id":id
  }

  await APILI.getInfluencerDatabyUser(body2).then(res => {

    
    getInfluencerData(res.data.influencer[0])
  }).catch(err => {
    console.log(err)
  } )
}, [refreshTable])
  const getPayments = async(dato) => {
    const body ={
      state:dato
    }
    await APILI.getPaymentByStatus(body).then(res => {
      getPaymentsData(res.data.registerPayment)
    }).catch(err => {
      console.log(err)
    })
  }
 
  const getPaymentsData = (paidsData) =>{
        getDataView(paidsData)
   //return response.data
 }


const handleClick = (e,data) =>{
  e.preventDefault();
  
  setTableTitle(data)
  if(data==='Iniciado'){
    getPayments('Pendiente');
    setFirstState(true);
    setSecondState(false);
    setThirdState(false);
    setFourthState(false);
    refGeneral.current.scrollIntoView();
  }
  if (data==='Liquidacion'){
    getPayments('Approved');
    setFirstState(false);
    setSecondState(true);
    setThirdState(false);
    setFourthState(false);
    refRedes.current.scrollIntoView();
  }
  if (data==='Facturacion'){
    getPayments('Billing');
    setFirstState(false);
    setSecondState(false);
    setThirdState(true);
    setFourthState(false);
    refPagos.current.scrollIntoView();
  }
  if (data==='Completado') {
    getPayments('Payment');
    setFirstState(false);
    setSecondState(false);
    setThirdState(false);
    setFourthState(true);
    refAcciones.current.scrollIntoView();
  }
  let scrolledY = window.scrollY;
  window.scroll(0, scrolledY - 90);
}
//saveModeration

const saveCategories = async(newTags) => {
  const body ={
    "id":id,
    "categories":newTags
  }
  await APILI.updateInfluencerCategories(body).then(res => {
    console.log(res.data) }).catch(err => {
      console.log(err)
    })

}
  
/* update social values */
const updateSocialScopeValues = (data, tableState) =>{
  console.log("data",data, "tablestate",tableState)
  console.log({"id":influencerData.id,tableState:data})
    if (tableState === 'averageScope')
         APILI.updateAverageScope({"id":influencerData.id,averageScope:data}).then(res => console.log("update scope")).catch(err => console.log(err))
    if (tableState=== 'averageInteraction')
      APILI.updateAverageInteraction({"id":influencerData.id,averageInteraction:data}).then(res => console.log("update interaction")).catch(err => console.log(err))
    if (tableState=== 'percentInteraction')
        APILI.updateInteractionPercentage({"id":influencerData.id,interactionPercent:data}).then(res => console.log("update percent interaction")).catch(err => console.log(err))
  
   
}

//alert moderation
const MySwal = (data) => {
  
  let influencerName = ''
  let influencerLastName = ''

  const updateInfluencerStatus = async (taskID, taskStatus) => {
    const update = {
        id: taskID,
        status: taskStatus
    }
    const bodyNotification = {
      "influencerID": taskID,
      "title": taskStatus==='Active' ?  "El usuario ha sido aprobado" : "El usuario ha sido rechazado",
      "message": taskStatus==='Active' ? "Ahora ya puedes participar de los retos de LikeInc" : "El usuario no pudo ser aprobado",
      "image":"https://i.postimg.cc/m2dZWQTD/likeinclogo.jpg",
      "type":"userApproval",
      "idType":""
    }
   
    const updateTable= () => {
        setrefreshTable(!refreshTable)
    }
   await APILI.updateInfluencerStatusByID(update).then((status) => {
    APILI.sendNotification(bodyNotification).then(data => {
      console.log({bodyNotification},"bodyNotification",data)
    }).catch(err => {
        console.log(err)
    })
       updateTable()
       console.log({bodyNotification})
      

   })
    }
     
       Swal.fire({
            title: 'Moderación usuario',
            icon: 'question',
            showCancelButton: true,
            showDenyButton: true,
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: '#00CA72',
            denyButtonText: '#d33',
            denyButtonText:
          '<i class="fa fa-thumbs-down"></i> Rechazar',
            confirmButtonText:
          '<i class="fa fa-thumbs-up"></i> Activar',
        }).then((result) => {
            
            if (result.isConfirmed) {
             
                updateInfluencerStatus(data.id,"Active") 
                
              Swal.fire({
                  title: '¡Confirmado!', 
                    icon: 'success',
                      timer: 1500})
            } else if (result.isDenied) {
                updateInfluencerStatus(data.id,"Rejected")
               
                Swal.fire({
                    title: '¡Rechazado!', 
                      icon: 'error',
                        timer: 1500})
            }
          })

  if(data.delivery.name ==="Payment"){
      
      Swal.fire({
          title: 'Ninguna actividad pendiente!', 
          icon: 'success',
          timer: 1500
      })
      return
  }
  let aprobeTask = ''
  let dennyTask = ''
  if(data.delivery.name ==="Rejected"){
      aprobeTask = 'Pendiente'
      dennyTask = 'Rejected'
  }
  if(data.delivery.name ==="Pendiente"){
      aprobeTask = 'Approved'
      dennyTask = 'Rejected'
  }
  if(data.delivery.name ==="Approved"){
      aprobeTask = 'Billing'
      dennyTask = 'Pendiente'
  }
  if(data.delivery.name ==="Billing"){
      aprobeTask = 'Payment'
      dennyTask = 'Approved'
  }

}

//para subir todo xd
useEffect(()=> {
  window.scroll(0,0);
},[]);

//alert moderation
  return (
    <>
    <div className='form-dashboard contUserData'>
      <div className="content-submenu-userList">
        <div onClick={e=> {handleClick(e,"Iniciado")}} ><SelectorBox activeColor={firstState} className="fas fa-user" text="Información de perfil" /></div> 
        <div onClick={e=> {handleClick(e,"Liquidacion")}} ><SelectorBox activeColor={secondState} className="fas fa-list" text="Información de redes" /></div> 
        <div onClick={e=> {handleClick(e,"Facturacion")}}><SelectorBox activeColor={thirdState} className= "fas fa-receipt" text="Informacion de pagos" /></div> 
        <div onClick={e=> {handleClick(e,"Completado")}}><SelectorBox activeColor={fourthState} className="fas fa-wrench" text="Acciones" /></div> 
      </div>
      <div className="buttons pl-btn-backuserlist -5 pt-4">
        <div className="contBtnBack" onClick={() => showInfluencerPage(false)} style={{ cursor: "pointer" }}>
            <i class="btnBackUser" onClick={() => showInfluencerPage(false)} ><img src={IconBack} /></i>
            <a>Volver a lista de usuarios</a>
        </div>
      </div>
      <div ref={refGeneral} className="sectionProfile">
          <div className='Box'>
            <div className="row-info">
                <div className="circle" />
                <div className="m-30" />
                <p className="title-text mt-15">   General</p>
            </div>
            <TargetContent listGeneralStatistics={listGeneralStatistics} nameClass="targetContent ml-15 pl-3" />
          </div>
          <div className='Box'>
            <Paper className="p-3"elevation={5} >
              <p>Categoria</p>
              <AutoComplete 
                data={mediaCategories} 
                open={open} 
                loading={loading} 
                setOpen={setOpen} 
                saveTag={saveCategoryTag} 
                placeholder="  Ingresa categoria" 
              />
              <div className="tagContent">
                  {categoryTags.map(tag => (
                    <Tag 
                    key={tag} 
                    name={tag} 
                    deleteTag={(e) => deleteCategoryTag(tag)} />
                  ))}
              </div>
              
              <Typography id="range-slider" gutterBottom>
                  Nivel Socio Economico
              </Typography>
              <NativeSelect value={socialStatusValue} onChange={e => saveSocialStatus(e.target.value)} name="age" className="w-90">
                <option value="" disabled>Selecciona un status socio economico</option>
                <option value="Bajo">Bajo</option>
                <option value="Medio-Bajo">Medio-Bajo</option>
                <option value="Medio">Medio</option>
                <option value="Medio-Alto">Medio-Alto</option>
                <option value="Alto">Alto</option>
              </NativeSelect>
              <div className="mb-15"/>
            </Paper>
          </div>
          <div className='Box'>
            <div className="row-info">
                <div className="circle" />
                <p className="title-text">   Ubicación</p>
            </div>
            <TargetContentMap markers={testMarker} nameClass="targetContent-small" className="h-100" />
        </div>
      </div>
      <div ref={refRedes} className="contentNetworks">
          <div className='Box'>
              <div className="row-info">
                  <div className="circle" />
                  <p className="title-text">   Estado de la Cuenta</p>
              </div>
              <TargetContentText action={()=>MySwal(influencerInfoPage)} dataText={influencerData} nameClass="targetContent-small" />
          </div>
          <div className='Box'>
              <div className="row-info">
                  <div className="circle" />
                  <p className="title-text">   Información de Acceso</p>
              </div>
              <TargetContent listGeneralStatistics={dataAccess} nameClass="targetContent" />
          </div>
      </div>
      <div ref={refPagos} className="contentPay">
          <div className='Box'>
              <div className="row-info">
                  <div className="circle" />
                  <p className="title-text">   Estadisticas de Saldo</p>
              </div>
              <TargetContent listGeneralStatistics={walletStats}  nameClass="targetContent-small" />
          </div>
          <div className='Box'>
              <div className="row-info">
                  <div className="circle" />
                  <p className="title-text">   Estadisticas de Pago</p>
              </div>
              <TargetContent listGeneralStatistics={paymentStats}  nameClass="targetContent-small" />
          </div>
        {/*   <div>
              <div className="row-info">
                  <div className="circle" />
                  <p className="title-text">   Estadisticas de Bono</p>
              </div>
              <StackBarGraph  nameClass="targetContent-small" />
          </div> */}
      </div>
      <div ref={refAcciones} className="contentActions">
        <div className='Box Box1'>
            <div className="row-info">
                <div className="circle" />
                <p className="title-text">   Actividad</p>
            </div>
            <TargetContent listGeneralStatistics={actStats}  nameClass="targetContent-small" />
        </div>
        <div className='Box Box2'>
            <div className="row-info">
                <div className="circle" />
                <p className="title-text">   Actividad de Post</p>
            </div>
            <TargetContent listGeneralStatistics={postAct}  nameClass="targetContent-small" />
        </div>
        <div className='Box Box3'>
            <div className="row-info">
                <div className="circle" />
                <p className="title-text">   Actividad Social</p>
            </div>
            <TargetContentNetworks 
            averageScope={averageScope} 
            averageInteraction={averageInteraction} 
            setAverageInteraction={(dat)=>{setAverageInteraction(dat)}}
            setAverageScope={(dat)=>{setAverageScope(dat)}}
            setPercentInteraction={(dat)=>{setPercentInteraction(dat)}}
            percentInteraction={percentInteraction}
            editAverageScope={editAverageScope} 
            editAverageInteraction={editAverageInteraction} 
            editPercentInteraction={editPercentInteraction}
            setEditAverageInteraction={(data) => setEditAverageInteraction(data)}
            setEditAverageScope={(data) => setEditAverageScope(data)}
            setEditPercentInteraction={(data) => setEditPercentInteraction(data)} 
            updateSocialScopeValues={(data,tableValue)=>updateSocialScopeValues(data,tableValue)}
            nameClass="targetContent-small"
            influencerID={influencerData.id} />
              
        </div>
        <div className='Box Box4'>
          <InfluencerTasks id={id}/>
        </div>
      </div>
      
    </div>
    </>
  )
}

export default Influencer