import React from 'react'
// components
import Administration from 'components/administration'

const AdministrationPage = (props) => { 
  const {userRol} = props;
  return (
    <>
    {userRol==='admin'?
    <Administration />:
    <h1>No tienes privilegios</h1>
    }
    </>
    )  
  }

export default AdministrationPage