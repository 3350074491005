import React from 'react'

// components
import Home from '../../components/home'

const SignIn = props => (
  <>
    <h1>Inicio de Sesion</h1>
    <Home {...props}/>
    {/* <SignInForm {...props} /> */}
  </>
)

export default SignIn
