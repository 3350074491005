import React, { useState, useEffect} from 'react'
import _ from 'lodash'
import * as utils from 'data.js'
import DataTableUtils, { Share, ShowColumn, FilterColumn } from '@easygosanet/react-datatable-utils'
import ReactDataTable from '@easygosanet/react-datatable'
import * as APILI from 'helpers/apiLI';
import orderBy from'lodash/orderBy'
import * as Modal from 'redux/actions/modal.actions'
import { connect } from 'react-redux';
import { showLoader } from 'redux/actions/loader.actions';

//Components
import ImageBox from 'components/imageBox'
import GraphBox from 'components/graphBox'
import TargetContent from 'components/targetContent'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import AddInfluencer from 'components/addInfluencer'
import Influencer from 'components/influencer'

//Charts
import GenderChart from 'components/genderChart'
import PieChart from 'components/pieChart'

//images
import Logo_facebook from './assets/img/logo_facebook.svg'
import Logo_instagram from './assets/img/logo_instagram.png'
import Logo_tiktok from './assets/img/logo_tiktok.svg'
import logo_linkedin from './assets/img/logo_linkedin.svg'
import { getLogoByName, getClassNameByStatus } from 'myUtil'
import { editpautalets } from 'helpers/apiLA'
//import NewAdvertiser from 'components/newAdvertiser'
//COMMENT BY LM 23-07-2021

const UserList = (props) => {
    const currentUser = JSON.parse(localStorage.getItem('CurrentUser')) ?? [];
    const[tables,setTables]= useState({titles:[],data:[]});
    const [filters, setFilters] = useState({});
    const [showModal, setshowModal] = useState(false);
    const [generalGenderState, setGeneralGenderState] = useState('');
    const [gender,setGender] = useState([]);
    const [generalUserStates, setGeneralUserStates] = useState('');
    const [generalAgeState, setGeneralAgeState] = useState('');
    const [countProfiles, setCountProfiles] = useState(0);
    const [countInfluencers, setCountInfluencers] = useState(0);
    const [countPendiente, setCountPendiente] = useState(0);
    const [userStatusArray, setuserStatusArray] = useState([]);
    const [userAgeArray, setuserAgeArray] = useState([]);
    const [showInfluencerPage, setshowInfluencerPage] = useState(false);
    const [countFanPages, setCountFanPages] = useState(0);
    const [scopeFanPages, setScopeFanPages] = useState(0);
    const [totalActiveScope, setTotalActiveScope] = useState(0);
    const [averageScope, setAverageScope] = useState(0);
    const [refreshTable, setrefreshTable] = useState(false);
    const [influencerInfoPage, setInfluencerInfoPage] = useState({});
    const [filterText, setFilterText] = useState('');
    const [socialNetworks,setSocialNetworks] = useState([]);
    const [filteredDataNormal,setFilteredDataNormal]= useState([])
    const [reversedData,setReversedData]= useState([])
    const [rol , setRol]=useState("")
    const [permisos, setPermisos]=useState([])
    const [finalPermisos, setFinalPermisos]=useState([])

    const [onlyFacebook, setOnlyFacebook] = useState([])
    const [onlyInstagram, setOnlyInstagram] = useState([])
    const [onlyTikTok, setOnlyTikTok] = useState([])

    const infoPage = (data) => {
        setInfluencerInfoPage(data)
        setshowInfluencerPage(true)
    }

    //consumo de roles 
    const getRoles= async()=>{
        let roles= await APILI.getUserRoles()
        let auxRol=roles.data.userRoles
        let localRol = localStorage.getItem("UserRol");
        localRol=localRol.replace(/['"]+/g, '');
        auxRol.forEach(e=>{
            if(e.name==localRol){
                setRol(localRol)
            }
        })
      }


      //consumo de rolpages
      const getPermissions = () =>{
        APILI.getPermissions().then((permissions) => {
          let arrayData= permissions.data.permissions
           setPermisos(arrayData);
      })
      }

      //useEffectConsumosPermisos
      useEffect( () => {
          console.log(permisos, rol, "DataPermisos")
          let auxRutes =[]
          permisos?.forEach(e=>{
              if(e.name==rol){
                  auxRutes.push(e.menuRoutes)
              }
          })
          let permisosFinales=[]
          auxRutes?.forEach(e=>{
              console.log(e,"RUTAS")
              let aux=e
              e.forEach(e=>{
                  if(e.module=="/userList"){
                      permisosFinales.push(e)
                  }
              })
          })
          setFinalPermisos(permisosFinales)
    }, [permisos]);
    
    //declararpermisos
    let create= false
    let edit= false
    let read= false

    finalPermisos.forEach(e=>{
        create=e.create
        edit=e.edit
        read=e.read
    })



    const InfluencerPage = (data) => {
        
        let influencerName = ''
        let influencerLastName = ''
        
        infoPage(data)
    
        console.log("infopage",data)
        const updateInfluencerStatus = async (taskID, taskStatus) => {
            const update = {
                id: taskID,
                status: taskStatus
            }
            await APILI.updateInfluencerStatusByID(update).then((status) => {
                
            })
        }
        /*Swal.fire({
            title: data.campaignName,
            text: 'Usuario:, ' + data.name + ' Edad: '+ data.age + " Email: "+data.email+
            ' Pais: ' + data.country + " Direccion: " +data.address + " Genero: "+ data.gender,
            icon: 'question',
            showCancelButton: true,
            showDenyButton: true,
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: '#00CA72',
            denyButtonText: '#d33',
            denyButtonText:
            '<i class="fa fa-thumbs-down"></i>',
            confirmButtonText:
            '<i class="fa fa-thumbs-up"></i> Aprobar',
        }).then((result) => {
            
            if (result.isConfirmed) {
                updateInfluencerStatus(data.id,"Active")      
                Swal.fire({
                    title: 'Confirmado!', 
                    icon: 'success',
                        timer: 1500})
            } else if (result.isDenied) {
                updateInfluencerStatus(data.id,"Rejected")
                Swal.fire({
                    title: 'Rechazado!', 
                        icon: 'error',
                        timer: 1500})
            }
        })*/
    }

    const saveLog = async (body) => {
        try {
          APILI.createNewLog(body).then(res => {
            console.log('Log Creado')
          }).catch(err => {
          console.log('err', err) 
          })
          
      } catch (err) {
          console.log("No se pudo guardar el log", err)
      }
      }


      const saveErrorLog = async (body) => {
        try {
          APILI.createNewErrorLog(body).then(res => {
            console.log('Error log Creado')
          }).catch(err => {
          console.log('err', err) 
          })
          
      } catch (err) {
          console.log("No se pudo guardar el log de error", err)
      }
      }


    const removeUser = (user) => {
        
        console.log("remove state",user)
        props._showAlertModal('Eliminar Usuario?', `Esta seguro que desea eliminar al usuario`, Modal.MODAL_TYPE_CONFIRMATION, () => {
            APILI.deleteInfluencer({id: user.id}).then(res => {
            console.log('Eliminado')
            props._showLoader(true);

            const bodyLog = {
                Tipo: "Delete",
                UsuarioId: currentUser.username,
                UsuarioName: currentUser.attributes.name,
                Email: currentUser.attributes.email,
                Accion: "Eliminar usuario",
                Data: {"id":user.id, "campaignamenName":user.name,"email":user.email},
                Historico: user,
                CodeHttp: res.status
            }
            saveLog(bodyLog)


              APILI.getInfluencersData()
                .then((res) => {
                  setrefreshTable(res.data.influencerdata) 
                  props._showLoader(false);
                })
                .catch(console.error);
          }).catch(err => {

            const bodyLog = {
                Tipo: "Delete error",
                UsuarioId: currentUser.username,
                UsuarioName: currentUser.attributes.name,
                Email: currentUser.attributes.email,
                Accion: "Eliminar usuario",
                Data: {"id":user.id, "campaignamenName":user.name,"email":user.email},
                Historico: user,
                CodeHttp: err
            }
            saveErrorLog(bodyLog)

          console.log('err', err) 
          })
          
        })
      }
    
    const DetailCol = ({ text, data }) => (
        <div className="firstColl w-100">
                <div className="icon-container">
                    <i onClick={() =>InfluencerPage(data)} class="fas fa-eye"></i>
                    <i className="fas fa-edit"></i>
                    <i class="fas fa-trash" onClick={() => removeUser(data)}></i>
                    <i class="fab fa-telegram-plane"></i>
                    <i class="fas fa-ellipsis-v"></i>
                </div>
                <p className="name-fc">
                    {text}
                </p>
            </div>
    )
    
    const DeliveryCol = ({ text, className, onClick }) => (
        <div >
            <div className={"delivery" +(text)}>
                {text}
            </div>
        </div>
    )

    const customStyles = {
        headCells: {
            style: {
                '&:nth-last-of-type(1)': {
                    backgroundColor: '#E3E4E6',
                    width: '40px'
                },
                '&:nth-last-of-type(3)': {
                    backgroundColor: '#E3E4E6',
                    width: '40px'
                },
                '&:nth-last-of-type(5)': {
                    backgroundColor: '#E3E4E6',
                },
                '&:nth-last-of-type(7)': {
                    backgroundColor: '#E3E4E6',
                },
            },
        },
    }    
        
    const goToEditPage = (id, name, createAt) => {
        let edit = {
            id,
            name,
            createAt,
        }
        props._setEdit(edit)

        window.$.fancybox.open({
            src: '#new-category-modal',
            //type: 'modal',
            opts: {
                modal: true,
                afterClose: () => props._clearEdit(),
            },
        })
    }

    const [columns, setColumns] = useState(
        [
            {
                id: 'name',
                name: 'Usuario',
                selector: 'name',
                sortable: true,
                omit: false,
                minWidth: '200px',
                cell: (row) => (
                    
                    <DetailCol
                        text={row.name}
                        data={row}
                    />
                ),
            },
            {
                id: 'delivery',
                name: 'Estado',
                selector: 'delivery',
                sortable: true,
                omit: false,
                center: true,
                style: {
                    backgroundColor: '#E3E4E6',
                    fontWeight: 'bold',
                },
                type: 'text',
                cell: (row) => (
                    <DeliveryCol
                        text={row.delivery.name}
                        className={row.delivery.className}
                    />
                )
            },
            {
                id: 'email',
                name: 'Email',
                selector: 'email',
                sortable: true,
                omit: false,
                center: true,
                style: {
                    fontWeight: 'bold',
                }
            },
            {
                id: 'age',
                name: 'Edad',
                selector: 'age',
                sortable: true,
                omit: false,
                minWidth: '100px',
                center: true,
                style: {
                    backgroundColor: '#E3E4E6',
                    fontWeight: 'bold',
                },
            },
            {
                id: 'gender',
                name: 'Genero',
                selector: 'gender',
                sortable: true,
                omit: false,
                center: true,
                style: {
                    fontWeight: 'bold',
                }
            },
            {
                id: 'country',
                name: 'Pais',
                selector: 'country',
                sortable: true,
                omit: false,
                center: true,
                style: {
                    backgroundColor: '#E3E4E6',
                    fontWeight: 'bold',
                },
                
            },
            {
                id: 'phone',
                name: 'Telefono',
                selector: 'phone',
                sortable: true,
                omit: false,
                center: true,
            },
            {
                id: 'createdAt',
                name: 'Creado en',
                selector: 'createdAt',
                sortable: true,
                omit: false,
                center: true,
                style: {
                    backgroundColor: '#E3E4E6',
                    fontWeight: 'bold',
                },
                
            },
        ])

    const handleFilterPerColumn = (column, type, value) => {
        setFilters((prev) => ({ ...prev, [column]: { type, value } }))
    }

    const handleShowColumnChange = (column, show) => {
        let index = columns.findIndex((col) => col.selector === column)
        let aux = columns.map((col) => col)
        aux[index].omit = show
        setColumns(aux)
    }

    const handleColumnSortChange = (positions) => {

        let aux = columns.map((col) => col)

        positions.forEach((columnId, position) => {
            let index = columns.findIndex((col) => col.selector === columnId)
            aux[index].position = position
        })
        aux = _.orderBy(aux, ['position'])

        setColumns(aux)
    }

    async function  getDataGenderPerSocialProfile(){
        await APILI.getSocialProfiles().then(res =>{
            console.log("datos perfiles sociales", res)
              let perfilesRedes = res.data.socialprofiles
              let onlyTiktok = perfilesRedes.filter(item => item.socialmedia === "TikTok" )
                setOnlyTikTok(onlyTiktok)
              let onlyinstagram = perfilesRedes.filter(item => item.socialmedia === "Instagram")
                setOnlyInstagram(onlyinstagram)
              let onlyfacebook = perfilesRedes.filter(item => item.socialmedia === "Facebook")
                setOnlyFacebook(onlyfacebook)
          }).catch(err => console.error(err))
    }
    useEffect(()=>{
        getDataGenderPerSocialProfile()
    },[])
    useEffect( () => {
        APILI.getInfluencersData().then(res => {
            if (res.data && res.data.influencerdata && res.data.influencerdata.length > 0) {                    
                
                console.log('data Influencers',res.data.influencerdata)
                let arrayData= res.data.influencerdata
                arrayData.forEach(element => {
                    if(element.createdAt){
                    let updated = element.createdAt.split(['-'])
                    let day= updated[2].split(['T'])
                    let newDate = updated[1]+'/'+day[0]+'/'+updated[0];
                    element.createdAt = newDate;
                    }
                })

                for (let x = 0; x < arrayData.length; x++) {
                    for (let y = 0; y < arrayData.length - 1; y++) {
                        let elementoActual = arrayData[y];
                        let elementoSiguiente = arrayData[y + 1];
                        if (new Date(elementoActual.createdAt) < new Date(elementoSiguiente.createdAt)) {
                            arrayData[y] = elementoSiguiente;
                            arrayData[y + 1] = elementoActual;
                        }
                    }
                  }
                  getInfluencersInfoView(arrayData, res.data.count)
                // setinfluencersData(res.data.influencerdata)
                    props._showLoader(false)
            }
        }).catch(console.error);
        APILI.influencersBySocialNetworks().then(res => {
            console.log('SOCIAL NETWORK');
            console.log(res.data.profiles);
            setSocialNetworks(res.data.profiles);
        }).catch(console.error);
      //obtenerRoles
      getRoles();
      //obtener permisos
      getPermissions();      
    }, [refreshTable]);
    
    const getInfluencersInfoView = (camps,count) => {

        const genderData = camps.map(camp => camp.gender)
        const statusData = camps.map(camp => camp.status)
        const ageData = camps.map(camp => camp.dateBirth)
        
        let genderArray = []
        let statusArray = []
        let ageArray = [] 
        let maleCount = 0;
        let femaleCount = 0;
        let activeCount = 0;
        let bannedCount = 0;
        let rejectedCount = 0;
        let PendienteCount = 0;
   
        for (let x=0; x<genderData.length; x++){
            if(genderData[x]==='Masculino' || genderData[x]==='Otro'){
                maleCount = maleCount + 1
            }
            if(genderData[x]==='Femenino'){
                femaleCount = femaleCount + 1
            }
        }        
        for (let x=0; x<statusData.length; x++){
            if(statusData[x]==='Active' || !statusData[x]){
                activeCount = activeCount + 1
            }
            if(statusData[x]==='Rejected'){     
                rejectedCount = rejectedCount + 1
            }
            if(statusData[x]==='Banned'){
                bannedCount = bannedCount + 1
            }
            if(statusData[x]==='Pendiente' || statusData[x]==='Pending'){
                PendienteCount = PendienteCount + 1
            }
        }        
        
        setGeneralGenderState({"Masculino":maleCount,"Femenino":femaleCount});
        setGeneralUserStates({"Active":activeCount, "Banned":bannedCount, "Rejected":rejectedCount, "Pendiente":PendienteCount});
     
        genderData.forEach(element => genderArray.push({"gender":element}));
        statusData.forEach(element => statusArray.push({"status":element}));
        ageData.forEach(element => ageArray.push(calculate_age(new Date(element))));
        
        setGender(genderArray);
        setuserStatusArray(statusArray);
        setuserAgeArray(ageArray); 
        setCountInfluencers(count)

        /******************************************************** */
        console.log(camps);
        setTables({
            data: camps.map(dat => ({
                name: dat.name+" "+dat.lastname,
                delivery: { 
                    name: getName(dat.status),
                    className: getClassNameByStatus(dat.status)
                },
                email: dat?.email,
                age: calculate_age(new Date(dat.dateBirth)),
                gender: dat?.gender,
                country: dat?.country,
                phone: dat?.phoneNumber,
                usernarme: dat?.username,
                id: dat?.id,
                userID: dat?.userID,
                address: dat?.direction,
                socialmedia: dat?.socialNetworks,
                bankAccount: dat?.bankAccount,
                dateBirth: dat?.dateBirth,
                interested: dat?.interested,
                language: dat?.language,
                categories: dat?.categories ? dat.categories : [],
                socialStatus: dat?.socialStatus,
                status: dat.status,
                campaigns: dat.tasks ? dat.tasks : [],
                createdAt: dat.createdAt ? new Date(dat?.createdAt).toLocaleString('es-GT') : '-',
                location: dat?.location
            }))
        })
        /******************************************************** */

        setReversedData(camps.map(dat => ({
            name: dat.name+" "+dat.lastname,
            delivery: { 
                name:getName(dat.status),
                className: getClassNameByStatus(dat.status)
            },
            email: dat?.email,
            age: calculate_age(new Date(dat.dateBirth)),
            gender: dat?.gender,
            country: dat?.country,
            phone: dat?.phoneNumber,
            usernarme: dat?.username,
            id: dat?.id,
            userID: dat?.userID,
            address: dat?.direction,
            socialmedia: dat?.socialNetworks,
            bankAccount: dat?.bankAccount,
            dateBirth: dat?.dateBirth,
            interested: dat?.interested,
            language: dat?.language,
            categories: dat?.categories ? dat.categories : [],
            socialStatus: dat?.socialStatus,
            status: dat.status,
            campaigns: dat.tasks ? dat.tasks : [],
            createdAt: dat.createdAt ? new Date(dat?.createdAt).toLocaleString('es-GT') : '-',
            location: dat?.location
        })))
        setFilteredDataNormal(camps.map(dat => ({
            name: dat.name+" "+dat.lastname,
            delivery: { 
                name: getName(dat.status),
                className: getClassNameByStatus(dat.status)
            },
            email: dat?.email,
            age: calculate_age(new Date(dat.dateBirth)),
            gender: dat?.gender,
            country: dat?.country,
            phone: dat?.phoneNumber,
            usernarme: dat?.username,
            id: dat?.id,
            userID: dat?.userID,
            address: dat?.direction,
            socialmedia: dat?.socialNetworks,
            bankAccount: dat?.bankAccount,
            dateBirth: dat?.dateBirth,
            interested: dat?.interested,
            language: dat?.language,
            categories: dat?.categories ? dat.categories : [],
            socialStatus: dat?.socialStatus,
            status: dat.status,
            campaigns: dat.tasks ? dat.tasks : [],
            createdAt: dat.createdAt ? new Date(dat?.createdAt).toLocaleString('es-GT') : '-',
            location: dat?.location
        })))
        
    }

    function calculate_age(dob) { 
        var diff_ms = Date.now() - dob.getTime();
        var age_dt = new Date(diff_ms); 
      
        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }
    function getName(status) {
        switch (status) {
            case "Pending":
                return "Pendiente"
                case "Active":
                    return "Activo"
            case "Finalizada":
                return "Finalizada"
            case "active":
                return  "Activa"
            case "En Revision":
                return "En revisión"
            case "rejected":
            return "Rechazado"
            case "Rejected":
                return "Rechazado"
            case "Completado":
                    return "Completado"
            case "Finalizado":
            return "Finalizado"
            case "Cerrada":
                    return "Cerrada"
            case "Solicitado":
                return "Solicitado"
            case "En progreso":
            return "En progreso"
            case "Completado":
                return("Completado")
            default:
                return "Desconocido"
        }
    }
    function percentAge(range){
        let count =0
        
        let percentage = 0; 
        let array = userAgeArray
        //let array = [16,17,18,19,20,21,22,23,24,25,16,17,18,19,20,21,22,23,24,25,
         //   25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,70,80]
        let max = array.length
        if(range===0){
            for(let x=0; x<max; x++){
                if(array[x]>=16 && array[x]<=17){
                    count++
                }
            }
            percentage = parseInt(100/max*count)
            return (percentage)
        }
        if(range===1){
            for(let x=0; x<max; x++){
                if(array[x]>=18 && array[x]<=24){
                    count++
                }
            }
            percentage = parseInt(100/max*count)
            return (percentage)
        }
        if(range===2){
            for(let x=0; x<max; x++){
                if(array[x]>=25 && array[x]<=29){
                    count++
                }
            }
            percentage = parseInt(100/max*count)
            return (percentage)
        }
        if(range===3){
            for(let x=0; x<max; x++){
                if(array[x]>=30 && array[x]<=35){
                    count++
                }
            }
            percentage = parseInt(100/max*count)
            return (percentage)
        }
        if(range===4){
            for(let x=0; x<max; x++){
                if(array[x]>=36){
                    count++
                }
            }
            percentage = parseInt(100/max*count)
            return (percentage)
        }
    
    }
     const dataUsersAge = {
        range: [
          {ageRange: "16 - 17 años",color:'color1'}, 
          {ageRange: '18 - 24 años',color:'color2'}, 
          {ageRange: '25 - 29 años',color:'color3'},
          {ageRange: '30 - 35 años',color:'color4'}, 
          {ageRange: '36 - + años',color:'color5'}],
        porcentage: [percentAge(10), percentAge(1), percentAge(2), percentAge(3), percentAge(4)],
        gender: [
          { 
            women: 581,
            mens: 581,
            color:'color1'
          },
          {
            women: 581,
            mens: 581,
            color:'color2'
          },
          {
            women: 581,
            mens: 581,
            color:'color3'
          },
          {
            women: 581,
            mens: 581,
            color:'color4'
          },
          {
            women: 581,
            mens: 581,
            color:'color5'
          }
        ]
    }
      
      const listGeneralStatistics = [

        {
            text: "Truers Activos: ",
            number: generalUserStates.Active
        },
        {
            text: "Truers Pendientes: ",
            number: generalUserStates.Pendiente
        },
        {
            text: "Truers Rechazados: ",
            number: generalUserStates.Rejected
        },
        {
            text: "Truers Baneados: ",
            number: generalUserStates.Banned
        }
    ]

    const handleFilterTextChange = (value) => {
        console.log(value);
        setFilterText(value)
    }

    let filteredData = tables.data.filter(item => 
        item?.name?.toLowerCase().includes(filterText.toLowerCase()) ||
        item.delivery?.name?.toLowerCase().includes(filterText.toLowerCase()) ||
        item.email?.toLowerCase().includes(filterText.toLowerCase()) ||
        item.age?.toString().toLowerCase().includes(filterText.toLowerCase()) ||
        item.gender?.toLowerCase().includes(filterText.toLowerCase()) ||
        item.country?.toLowerCase().includes(filterText.toLowerCase()) ||
        item.phone?.toString().toLowerCase().includes(filterText.toLowerCase())
    );

    const sort= (rows,selector,direction)=>{
        if(selector==='createdAt'){
            console.log('DIRECTION',direction)
            if(direction==='asc'){
                filteredData= reversedData
            }else{
                filteredData= filteredDataNormal
            }
            
            
            return orderBy(rows, direction)
        }else{
            return orderBy(rows, selector, direction)
        }
    }
    const exportDataToExcel = () =>{
        console.log('FCampToExport', tables.data)
        let headers = []
        let dataExcel=[]
        let selectors = []
        let excelData = [];
        var filteredColumns=columns.filter(function(col){
          return col.omit===false
        })
        
        filteredColumns.forEach((element)=>{
          headers.push(element.name)
          selectors.push(element.selector)
        })  
        
        dataExcel.push(headers)
      
             if(filteredData.length>0)
             {
                filteredData?.forEach((element) => {
                 excelData = [];
                   for(let k=0; k<selectors.length; k++)
                   {
                       excelData.push(
                         selectors[k]=="delivery" ? element.delivery.name:
                         selectors[k]=="source" ? element.socialmedia:
                         element[selectors[k]],
                       );
                   }
                
                   dataExcel.push(excelData);
                 });
                 
             }
         console.log('dataParaExportar', dataExcel)
         return dataExcel;
      }

    return (
        <>
        {showInfluencerPage ?
        <Influencer 
            refreshTable={refreshTable} 
            setrefreshTable={setrefreshTable} 
            influencerInfoPage={influencerInfoPage} 
            showInfluencerPage={setshowInfluencerPage}
        /> : 
        <div className='form-dashboard contUserList'>
            <div id='shadow' className='shadow hide'></div>
            <div className='Bar-content'>
                <div className='content-menu list'>
                    <select
                        id='viewSelect'
                        className='select-dashboard fa'
                    >
                        <option className='fa' value='1'>
                            {' '}
                            &#xf0ce; Vista de usuarios
                        </option>
                        {/* <option className='fa' value='0'>
                            {' '}
                            &#xf0db; Vista de Kanban{' '}
                        </option>
                        <option className='fa' value='2'>
                            {' '}
                            &#xf073; Vista de calendario
                        </option> */}
                    </select>
                    < DataTableUtils onChangeFilterText={handleFilterTextChange}>
                        <Share
                        contacts={utils.myContacts}
                        data={exportDataToExcel()}
                        isArray={true}
                        exportXLSX={true}
                        exportTXT={true}
                        exportDropbox={true}
                        exportDrive={true}
                        exportCSV={true}
                        printable={true}
                        exportPNG={true}
                        exportLink={true}
                        />
                        <ShowColumn
                            columns={columns}
                            showColumn={handleShowColumnChange}
                            onSortChange={handleColumnSortChange}
                        />
                        {/* <FilterColumn
                            columns={columns}
                            onFilterColumn={handleFilterPerColumn}
                        /> */}
                    </DataTableUtils>
                        <div
                        className='btnOrange-dash'
                        onClick={e => setshowModal(true)}>
                        <i className='fas fa-plus'></i>
                        </div>
                    
                </div>
            </div>
            <div className="title-grid titleUserList">
                Truers por red social
            </div>
            <div className="content-section redSocial">
                <ImageBox className="rectangle-img-info"
                    image={Logo_facebook} num={socialNetworks.length > 0 ? socialNetworks[0].count: "0"}
                    text1={socialNetworks.length > 0 ? 'Alcance promedio: ' + socialNetworks[0].reachAvg : "0"}
                    text2={socialNetworks.length > 0 ? "Tasa de interaccion promedio: " + socialNetworks[0].interactionAvg : "0"}
                />
                <ImageBox className="rectangle-img-info"
                    image={Logo_instagram} num={socialNetworks.length > 0 ? socialNetworks[1].count: "0"}
                    text1={socialNetworks.length > 0 ? 'Alcance promedio: ' + socialNetworks[1].reachAvg : "0"}
                    text2={socialNetworks.length > 0 ? "Tasa de interaccion promedio: " + socialNetworks[1].interactionAvg : "0"}
                />
                <ImageBox className="rectangle-img-info"
                    image={Logo_tiktok} num={socialNetworks.length > 0 ? socialNetworks[1].count: "0"}
                    text1={socialNetworks.length > 0 ? 'Alcance promedio: ' + socialNetworks[1].reachAvg : "0"}
                    text2={socialNetworks.length > 0 ? "Tasa de interaccion promedio: " + socialNetworks[1].interactionAvg : "0"}
                />
            </div>
            <div className="content-section sectionSecond">
                <div className='box'>
                    <div className="title-grid">
                        Truers por Género
                    </div>
                    <GraphBox nameClass="rectangle-graph-meddium margin" >
                        <div className="userByGender">
                            <GenderChart 
                                generalGenderState={generalGenderState} 
                                onlyFacebook={onlyFacebook}
                                onlyInstagram={onlyInstagram}
                                onlyTikTok={onlyTikTok}
                            />
                        </div>
                    </GraphBox>
                </div>
                <div className='box'>
                    <div className="title-grid">
                        Truers por Edad
                    </div>
                    <GraphBox nameClass="rectangle-graph-meddium graph" >
                        <div className="userByState">
                         <PieChart data={utils.dataGraphUsers}
                                    dataUsersAge={dataUsersAge}
                                    width={170}
                                    height={170} />
                            
                        </div>
                    </GraphBox>
                </div>
                <div className='box'>
                    <div className="title-grid">
                        Status de Truers
                    </div>
                    <TargetContent listGeneralStatistics={listGeneralStatistics} nameClass="targetContent-small" />
                </div>
            </div>
            <div className="content-section table1">
              

                {/* <select className='select-dashboard'>
                    <option value='1'>
                        &#xf0ce; Últimos 30 dias
                    </option>
                </select> */}
            </div>
                      <div className="title-grid">
                    Listado de Truers
                </div>
                <ReactDataTable
                columns={columns}
                customStyles={customStyles}
                data={filteredData}
                persistTableHead
                pagination={true}
                noDataComponent={"No hay datos para mostrar"}
                defaultSortField="createdAt"
                noHeader
                sortFunction={sort}
                paginationComponentOptions={{
                    rowsPerPageText: 'Elementos por página',
                    rangeSeparatorText: 'de',
                  }}
                />
           
           {showModal ? <AddInfluencer title="Agregar Usuario" showModal={setshowModal} /> : null}
        </div>
        }
        </>
    );
};
const mapDispatchToProps = dispatch => ({
    _showLoader: show => dispatch(showLoader(show)),
    _showAlertModal: (title, message, type, callback) =>
    dispatch(Modal.showAlertModal(title, message, type, callback)),
  })
  
  const mapStateToProps = (state) => ({
    isLoading: state.app.isLoading,
    myUser: state.users.myUser
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(UserList)
