import React, {useState, useEffect} from 'react';
import "react-google-flight-datepicker/dist/main.css";


import InputModal from './inputModal';
import * as APILI from 'helpers/apiLI';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import logo from '../galleryPost/icons/logo.png'



const CustomTask = ({ userRol, showModal, setShowModal, currentFormat, _showAlertModal, influencerName,taskPage,refreshSubtasks,setRefreshSubtasks, setrefreshTable,refreshTable}) => {
    
   console.log("currentFormat", currentFormat)
   console.log("taskPage", taskPage)
    const [message, setMessage] = useState('')
    const [urlPost, setUrlPost] = useState('')
    const [alcance, setAlcance] = useState(0)
    const [interacciones, setInteracciones] = useState(0)
    const [visualizaciones, setVisualizaciones] = useState(0)
    const [comment, setComment] = useState(0)
    const [likes, setLikes] = useState(0)
    const [saved, setSaved] = useState(0)
    /* const [typeCustom, setTypeCustom] = useState(["CUSTOMTIKTOK", "CUSTOMINSTAGRAM", "CUSTOMFACEBOOK"]) */
    const [type, setType] = useState(["VIDEO", "IMAGE", "CAROUSEL_ALBUM"])

    const [myInstagramPost, setMyInstagramPost] = useState(null)
    const [hashtag, setHashtag] = useState([])
    let hashtagWord = ''
    let startHashTag = false

    const updateStates = (data) => {
        setMessage(data.message)
        data?.urlPost.includes('facebook') ? setUrlPost(data.urlPost) : setMyInstagramPost(data.urlPost)
        setAlcance(data.insights.reach)
        setInteracciones(data.engagement)
        setVisualizaciones(data.impressions)
        setLikes(data.likes_count)
        setComment(data.comments_count)
        setSaved(data.saved)
        setType(data.activity)
    }

    useEffect(() => {
        APILI.getPostById({ id: currentFormat?.data.id })
            .then(res => {
                console.log('ress efect data', res.data)
                if (res.data.count > 0) {
                    updateStates(res.data.influencerPosts[0])
                }
            })
    }, [currentFormat])
   
    useEffect(() => {
        if (message.includes('#')) {
            let tempHashtag = []
            for (let x = 0; x <= message.length - 1; x++) {
                if (message[x] === '#')
                    startHashTag = true
                hashtagWord = ''
                if (startHashTag === true && message[x] !== ' ')
                    hashtagWord = hashtagWord.concat(message[x])
                if (message[x] === ' ' && startHashTag === true || x === message.length - 1 && startHashTag === true) {
                    startHashTag = false

                    tempHashtag.push({ "name": hashtagWord.toLowerCase() })
                }
            }
            setHashtag([tempHashtag])
        }

    }, [message])
    const saveFacebookPost = (data) => {
        setMyInstagramPost(null)
    }

    const saveInstagramPost = (data) => {
        setMyInstagramPost(data)
    }
    const updateTaskStatus = async (taskID, taskStatus) => {
        const update = {
          id: taskID,
          status: taskStatus,
        };
        APILI.updateTaskStatusByID(update)
          .then((res) => {
            /* console.log('response',res); */
          })
          .catch((err) => {
            console.log("err", err);
          });
          
      }; 

    const updateStatusSubTask = async (subTaskID, statusSubtask, taskID, statusTask) => {
        const body = {
          id: subTaskID,
          status:statusSubtask,
        };
        /* console.log("bodySubTask", body); */
        await APILI.updateSubTaskStatus(body)
          .then((res) => {
            
            if(taskID!='' && statusTask!='') {
              updateTaskStatus(taskID,statusTask)
            }
            
            /* console.log('response',res); */
          })
          .catch((err) => {
            console.log('error',err);
          });    
           
      };

    const saveCustomTask = async () => {
        let type = ''
        if(currentFormat.data.activity=="Post Video")
        {
            type = 'VIDEO'
        }
        else if(currentFormat.data.activity==="Post Estático")
        {
            type = 'IMAGE'
        }
        let body = {
            id: currentFormat.data.id,
            taskID: taskPage.id,
            subtaskID: currentFormat.data.id,
            activitypost: 'CUSTOM',
            socialmedia:taskPage.socialmedia,
            message: message,
            campaign: taskPage.campaignID,
            urlPost: urlPost ? urlPost : myInstagramPost,
            picture: urlPost ? urlPost : myInstagramPost,
            activity: 'CUSTOMTYPE',
            likes_count: likes > 0 ? likes:0,
            comments_count: comment> 0 ? comment:0,
            insights: {
                engagement: interacciones > 0 ? interacciones:0,
                impressions: visualizaciones > 0 ? visualizaciones:0,
                saved: saved > 0 ? saved:0,
                reach: alcance > 0 ? alcance:0
            },
            hashtag: hashtag,
        }

        console.log('customData',body)
        if (!message || !urlPost) {
            Swal.fire({
                title: 'Error',
                icon: 'error',
                text: 'llenar todos los campos',
                type: 'error',
                confirmButtonText: 'Ok'
            })
            return
        } else {
            /* console.log('body customTask', body)
            console.log('current Format', currentFormat)
            APILI.createPosts(body).then(res => {
                console.log('res create pposts', res)
                updateStatusSubTask(currentFormat.data.id, 'En revisión', taskPage.id, 'En progreso')
            }).catch(err => {
                console.log('error create posts', err)
            })
            Swal.fire({
                title: 'Guardado',
                icon: 'success',
                text: 'Guardado correctamente',
                confirmButtonText: 'Ok'

            })
            setTimeout(() => {
                setRefreshSubtasks(!refreshSubtasks)
                setrefreshTable(!refreshTable)
              }, 2000);
            
            setShowModal(false) */
        }

    }

    const cleanCustomTask = () => {
        setShowModal(false)
        setMessage('')
        setUrlPost('')
        setAlcance(0)
        setInteracciones(0)
        setVisualizaciones(0)
        setComment(0)
        setLikes(0)
        setSaved(0)
        setMyInstagramPost(null)

    }
    
    return (
        <div className="blur-div">
            <div className="content-modal">
                <div className="closeModal-content">
                    <i className="fas fa-times-circle" onClick={e => setShowModal(false)}></i>
                </div>
                <div>
                    <h3 className="titleModal">{currentFormat.data.activity}</h3>
                </div>
                <div className="body-modal bodyPadre">
                    <div className="part-BodyModal parte1 parteInput">
                        <InputModal 
                            title="Mensaje Publicación" 
                            placeholder="Ingresa el texto de la publicación"
                            value={message}
                            change={setMessage}
                        />
                        <InputModal 
                            title="Url Post" 
                            placeholder="Ingresa la url de la publicación"
                            value={urlPost}
                            change={setUrlPost}
                        />
                        <div className='boxesInputs'>
                        <div className='boxInputCustom'>
                            <p>Alcance</p>
                            <input 
                                min="0" 
                                type="number" 
                                value={alcance}
                                onChange={e => setAlcance(e.target.value)}
                            />
                        </div>
                        {/* <div className='boxInputCustom'>
                            <p>Interacciones</p>
                            <input 
                                min="0" 
                                type="number" 
                                value={interacciones}
                                onChange={e => setInteracciones(e.target.value)}
                            />
                        </div> */}
                        <div className='boxInputCustom'>
                            <p>Visualizaciones</p>
                            <input 
                                min="0" 
                                type="number" 
                                value={visualizaciones}
                                onChange={e => setVisualizaciones(e.target.value)}
                            />
                        </div>
                        <div className='boxInputCustom'>
                            <p>Comentarios</p>
                            <input 
                                min="0" 
                                type="number"
                                value={comment}
                                onChange={e => setComment(e.target.value)} 
                            />
                        </div>
                        <div className='boxInputCustom'>
                            <p>Likes</p>
                            <input 
                                min="0" 
                                type="number"
                                value={likes}
                                onChange={e => setLikes(e.target.value)}
                            />
                        </div>
                        <div className='boxInputCustom'>
                            <p>Guardado</p>
                            <input 
                                min="0" 
                                type="number"
                                value={saved}
                                onChange={e => setSaved(e.target.value)}
                            />
                        </div>
                        </div>
                    </div>
                    <div className="part-BodyModal parte2 parteLogo">
                        <img src={logo} />
                    </div>
                </div>

                <div className="footer-Modal">
                    <div className="buttons-Modal-content">
                        <button onClick={e => setShowModal(false)}>Cancelar</button>
                        {/* <button onClick={e => saveCustomTask()}>Guardar</button> */}
                        <button onClick={e => saveCustomTask()}>Guardar</button>
                    </div>
                </div>
            </div>
        </div>

    )
}



export default CustomTask