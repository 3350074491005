import API from '@aws-amplify/api'
import { Auth, Hub } from 'aws-amplify';
import { API_NAMELI } from '../config/api'
import { v4 } from 'uuid'
/*import * as crypto from 'crypto-js'


function getAmzDate(dateStr) {
  var chars = [":","-"];
  for (var i=0;i<chars.length;i++) {
    while (dateStr.indexOf(chars[i]) != -1) {
      dateStr = dateStr.replace(chars[i],"");
    }
  }
  dateStr = dateStr.split(".")[0] + "Z";
  return dateStr;
}
// this function gets the Signature Key, see AWS documentation for more details, this was taken from the AWS samples site
function getSignatureKey(Crypto, key, dateStamp, regionName, serviceName) {
    var kDate = Crypto.HmacSHA256(dateStamp, "AWS4" + key);
    var kRegion = Crypto.HmacSHA256(regionName, kDate);
    var kService = Crypto.HmacSHA256(serviceName, kRegion);
    var kSigning = Crypto.HmacSHA256("aws4_request", kService);
    return kSigning;
}


const access_key = process.env.AWS_ACCESS_KEY_ID;
const secret_key = process.env.AWS_SECRET_ACCESS_KEY;
  // our variables
  // var access_key = 'ACCESS_KEY_VALUE';
  // var secret_key = 'SECRET_KEY_VALUE';
  var region = 'eu-west-1';
  // var url = 'my-bucket-name.s3.amazonaws.com';
  // var myService = 's3';
  // var myMethod = 'GET';
  var myPath = '/';
 
  // get the various date formats needed to form our request
  var amzDate = getAmzDate(new Date().toISOString());
  var authDate = amzDate.split("T")[0];
 
  // we have an empty payload here because it is a GET request
  var payload = '';
  // get the SHA256 hash value for our payload
  var hashedPayload = crypto.SHA256(payload).toString();
 
  // create our canonical request
  var canonicalReq =  
                      // myMethod + '\n' +
                      // myPath + '\n' +
                      // '\n' +
                      // 'host:' + url + '\n' +
                      'x-amz-content-sha256:' + hashedPayload + '\n' +
                      'x-amz-date:' + amzDate + '\n' +
                      '\n' +
                      'host;x-amz-content-sha256;x-amz-date' + '\n' +
                      hashedPayload;
 
  // hash the canonical request
  var canonicalReqHash = crypto.SHA256(canonicalReq).toString();
 
  // form our String-to-Sign
  var stringToSign =  'AWS4-HMAC-SHA256\n' +
                      amzDate + '\n' +
                      authDate+'/'+region+'/aws4_request\n'+
                      canonicalReqHash;
 
  // get our Signing Key
  var signingKey = getSignatureKey(crypto, secret_key, authDate, region);
 
  // Sign our String-to-Sign with our Signing Key
  var authKey = crypto.HmacSHA256(stringToSign, signingKey);
 
  // Form our authorization header
  var authString  = 'AWS4-HMAC-SHA256 ' +
                    'Credential='+
                    access_key+'/'+
                    authDate+'/'+
                    region+'/'+
                    // myService+'/aws4_request,'+
                    'SignedHeaders=host;x-amz-content-sha256;x-amz-date,'+
                    'Signature='+authKey;

const header = {
  'Authorization' : authString,
  // 'Host' : url,
  'X-Amz-date' : amzDate,
  'X-Amz-content-sha256' : hashedPayload
};
*/



const globalOptions = {
  response: true,
}
/*
const globalOptionsTest = {
  response: true,
}
*/
export const bigQueryUpdate= (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.put(API_NAMELI, '/bigQueryApi/query/description', options)
}


export const createAdvertiserInfo = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/advertiser', options)
}

export const createCampaign = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/campaing', options)
}

export const createMediaCategory = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/mediaCategory', options)
}

export const createTask = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/task', options)
}

export const getMediaCategories = () => {
  let options = {
    ...globalOptions
  }

  return API.post(API_NAMELI, '/mediaCategory/myMediaCategory', options)
}
export const getSocialNetworks = () => {
  let options = {
    ...globalOptions
  }

  return API.post(API_NAMELI, '/socialNetwork/mySocialNetworks', options)
}

export const getCampaign = () => {
    let options = {
      ...globalOptions
    }
  
    return API.post(API_NAMELI, '/campaing/myCampaigns', options)
}

export const getCampaignByUser = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/campaing/findCampaignsByUser', options)
}

export const getCampaignById = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/campaing/findCampaignByID', options)
}

export const updateCampaignState = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/campaing/updateCampaignState', options)
}

export const updateApprovedTruers = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/campaing/updateApprovedTruers', options)
}

export const getCampaignByModerator = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/campaing/findCampaignsByModerator', options)
}
  
export const getAdvertiser = () => {
  let options = {
    ...globalOptions
  }

  return API.post(API_NAMELI, '/advertiser/myAdvertisers', options)
}


export const updateAdvertiserStatus = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/advertiser/updateAdvertiserStatus', options)
}

export const deleteAdvertiser = (body)=>{
  const options = {
    ...globalOptions,
    body,
  }

  return API.post(API_NAMELI, '/advertiser/deleteAdvertiser', options)
}

export const getAgencyList = () => {
  let options = {
    ...globalOptions
  }

  return API.post(API_NAMELI, '/advertiser/getAgencyList', options)
}

export const getTask = () => {
  let options = {
    ...globalOptions
  }

  return API.post(API_NAMELI, '/task/myTasks', options)
}

export const getCountries = () => {
  let options = {
    ...globalOptions
  }

  return API.post(API_NAMELI, '/countries/myCountries', options)

}

export const getSocialMediaPrice = () => {
  let options = {
    ...globalOptions
  }

  return API.post(API_NAMELI, '/socialmediaprice/mysocialmediaprice', options)
}

export const getSocialMediaUsers = () => {
  let options = {
    ...globalOptions
  }

  return API.post(API_NAMELI, '/socialMediaUsers/mySocialMediaUsers', options)
}

export const getInfluencersData = () => {
  let options = {
    ...globalOptions
  }

  return API.post(API_NAMELI, '/influencerdata/myInfluencerData', options)
}

export const getInfluencerDatabyUser = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/influencerdata/findInfluencerByUser', options)
}

export const getInfluencerDynamic = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/influencerdata/findInfluencerDynamic', options)
}

export const getUserRolesList = () => {
  let options = {
    ...globalOptions,
  }

  return API.post(API_NAMELI, '/userRoles/myRoles', options)
}


export const getSocialProfiles = () => {
  let options = {
    ...globalOptions
  }

  return API.post(API_NAMELI, '/socialprofiles/mysocialprofiles', options)
}

export const getProfilesByState = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/socialprofiles/filterProfilesByState', options)
}

export const updateTaskStatusByID = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/task/updateTaskStatus', options)
}

export const deleteTask = (body)=>{
  const options = {
    ...globalOptions,
    body,
  }

  return API.post(API_NAMELI, '/task/deleteTask', options)
}

export const updateTaskRating = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/task/updateTaskRating', options)
}

export const updateTaskInteractions  = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/task/updateTaskInteractions', options)
}
export const updateTaskScope = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/task/updateTaskScope', options)
}
export const findTaskByCampaignID = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/task/findTaskByCampaignID', options)
}

export const updateInfluencerStatusByID = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/influencerdata/updateInfluencerStatus', options)
}

export const deleteInfluencer = (body)=>{
  const options = {
    ...globalOptions,
    body,
  }

  return API.post(API_NAMELI, '/influencerdata/deleteInfluencer', options)
}

export const updateInfluencerCategories = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/influencerdata/updateCategories', options)
}

export const updateInfluencerSocialStatus = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/influencerdata/updateSocialStatus', options)
}
export const updateProfileStatusByID = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/socialprofiles/updateProfileStatus', options)
}

export const getInfluencerPosts = () => {
  let options = {
    ...globalOptions
  }

  return API.post(API_NAMELI, '/influencerPosts/myInfluencerPosts', options)
}

export const updatePostStatusByID = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/influencerPosts/updatePostStatus', options)
}
export const updatePostRating = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/influencerPosts/updatePostRating', options)
}

export const getPostById = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/influencerPosts/findPostById', options)
}

export const getAdvertiserById = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/advertiser/findAdvertiserByID', options)
}

export const getAdvertiserByAccount = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/advertiser/findAdvertiserByAccount', options)
}

export const getPostsByHashtagAndUser = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/influencerPosts/findPostsByHashtagAndUser', options)
}


export const getPostsBySubTask = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/influencerPosts/findPostsBySubTask', options)
}

export const getAdvertiserInfo = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/advertiser/findAdvertiserById', options)
}


export const createInfluencerData = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/influencerdata', options)
}

export const createProfile = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/socialprofiles', options)
}


export const createProfiles = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/socialprofiles', options)
}

export const createPosts = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/influencerPosts', options)
}

export const getCampaignList = () => {
  let options = {
    ...globalOptions
  }

  return API.post(API_NAMELI, '/campaing/myCampaigns', options)
}

export const deleteCampaing = (body)=>{
  const options = {
    ...globalOptions,
    body,
  }

  return API.post(API_NAMELI, '/campaing/deleteCampaing', options)
}

export const getTasksByUser = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/task/findTaskByUser', options)
}


export const getSubTasksByTask = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/subTasks/findSubTaskByTask', options)
}

export const getSubTaskById = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/subTasks/findSubTaskById', options)
}

export const updateSubTaskStatus = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/subTasks/updateSubTaskStatus', options)
}
export const updateSubTaskRating = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/subTasks/updateSubTaskRating', options)
}
export const updateSubTaskComment = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/subTasks/updateSubTaskComment', options)
}
export const getProfilesByUserorEmail = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/socialprofiles/findProfileByUserorEmail', options)
}

export const createSubTask = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/subTasks', options)
}


export const findInfluencer = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/influencerdata/findInfluencer', options)
}

export const updateAverageScope = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/influencerdata/updateAverageScope', options)
}

export const updateAverageInteraction = (body) => {
  let options = {
    ...globalOptions,
    body
  }
  
  return API.post(API_NAMELI, '/influencerdata/updateAverageInteraction', options)
}

export const updateInteractionPercentage = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/influencerdata/updateInteractionPercentage', options)
}
export const getPostByUser = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/influencerPosts/findPostByUser', options)
}


export const createFacebookProfile = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/facebookUsersData', options)
}

export const getProfilesByEmail = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/facebookUsersData/findProfileByEmail', options)
}
/***************Api Instagram *********** */
export const getMyUserData = () => {
  let options = {
    ...globalOptions,
  }

  return API.post(API_NAMELI, '/instagramApi/myUserData', options)
}
export const getMyUserPosts = () => {
  let options = {
    ...globalOptions,
  }

  return API.post(API_NAMELI, '/instagramApi/myUserPosts', options)
}

export const getMyMediaData = () => {
  let options = {
    ...globalOptions,
  }

  return API.post(API_NAMELI, '/instagramApi/myMediaData', options)
}

export const getMyStories = () => {
  let options = {
    ...globalOptions,
  }

  return API.post(API_NAMELI, '/instagramApi/myStories', options)
}

export const getMyStoryData = () => {
  let options = {
    ...globalOptions,
  }

  return API.post(API_NAMELI, '/instagramApi/myStoryData', options)
}

export const getMyUserProfile = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/instagramApi/userProfile', options)
}

export const getStoriesByUser = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/instagramApi/storiesByUser', options)
}

export const createPayment = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/registerPayment', options)
}

export const getPaymentByStatus = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/registerPayment/findPaymentByStatus', options)
  
}
export const getPaymentByCampaign = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/registerPayment/findPaymentByCampaign', options)
  
}

export const updatePaymentStatusByID = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/registerPayment/updatePaymentStatus', options)
}

export const getWalletbyId = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/wallet/findWalletbyId', options)
}

export const updateWallet = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/wallet/updateBalance', options)
}

export const getPaidbyUser = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/registerPayment/findPaidByStateAndUser', options)
}

export const getProfilesByUser = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/socialprofiles/findProfileByUser', options)
}


export const sendNotification = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/notificator', options)
}

export const myPages= ()=>{
  let options = {
    ...globalOptions
  }

  return API.post(API_NAMELI, '/dataPage/myPages', options)
}

export const findPageById=(body)=>{
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/dataPage/findPageById', options)
}

export const savePage= (body)=>{
  let options = {
    ...globalOptions,
    body
  }
  return API.post(API_NAMELI, '/dataPage', options)
}

export const getTemporalSubTask= (body)=>{
  let options = {
    ...globalOptions,
    body
  }
  return API.post(API_NAMELI, '/temporalSubTask/findTemporalSubtaskByUserIdAndCampaign', options)
}

export const deleteTemporalSubTask= (body)=>{
  let options = {
    ...globalOptions,
    body
  }
  return API.delete(API_NAMELI, '/temporalSubTask/deleteTemporalByCampaignId', options)
}

export const getGenderDataInfluencer = () => {
  let options = {
    ...globalOptions
  }

  return API.post(API_NAMELI, '/stadistics/getGenderDataInfluencer', options)
}

export const profilesByState = () => {
  let options = {
    ...globalOptions
  }

  return API.post(API_NAMELI, '/socialprofiles/profilesByState', options)
}

export const influencersBySocialNetworks = () => {
  let options = {
    ...globalOptions
  }

  return API.post(API_NAMELI, '/socialStadistics/influencersBySocialNetworks', options)
}



//exporthelperFernando
export const getCampañasPorRedSocial= ()=>{
  let options = {
    ...globalOptions
  }
  return API.post(API_NAMELI, '/campaing/campaingsBySocialNetwork', options)
}

export const getCampañasPorRedSocialUsuario = (body) => {
  let options = {
    ...globalOptions,
    body
  }
  return API.post(API_NAMELI, '/campaing/campaingsBySocialNetworkAndUser', options)
} 

export const getAgeDataInfluencer = () => {
  let options = {
    ...globalOptions
  }
  return API.post(API_NAMELI, '/stadistics/getAgeDataInfluencer', options)
}
export const getCampaingsStadistic = () => {
  let options = {
    ...globalOptions
  }
  return API.post(API_NAMELI, '/campaing/activeCampaignsStadistic', options)
}
export const getInfluencerCount = () => {
  let options = {
    ...globalOptions
  }
  return API.post(API_NAMELI, '/stadistics/accountPerformanceInfluencerList', options)
}
export const getProfileCount = () => {
  let options = {
    ...globalOptions
  }
  return API.post(API_NAMELI, '/socialprofiles/mySocialProfilesStadistics', options)
} 
export const getProfileStadistics = () => {
  let options = {
    ...globalOptions
  }
  return API.post(API_NAMELI, '/socialprofiles/stadisticProfiles', options)
} 

export const getGeneralStadisticsInfluencerAndCampaign = () => {
  let options = {
    ...globalOptions
  }
  return API.post(API_NAMELI, '/campaingStadistics/getGeneralDataCampaing', options)
} 
export const findCampaignsByState = () => {
  let options = {
    ...globalOptions
  }
  return API.post(API_NAMELI, '/campaing/findCampaignsByState', options)
}  

export const findCampaignsByStateAndUser = (body) => {
  let options = {
    ...globalOptions,
    body
  }
  return API.post(API_NAMELI, '/campaing/findCampaignsByStateAndUser', options)
} 

export const postActivity = () => {
  let options = {
    ...globalOptions
  }
  return API.post(API_NAMELI, '/task/postActivity', options)
} 
export const postActivityByUser = (body) => {
  let options = {
    ...globalOptions,
    body
  }
  return API.post(API_NAMELI, '/task/postActivityByUser', options)
} 
export const getSocialProfilesInfo = () => {
  let options = {
    ...globalOptions
  }
  return API.post(API_NAMELI, '/socialprofiles/myProfilesStadisticsBySocialMedia', options)
}  
export const getTop5Hashtags = () => {
  let options = {
    ...globalOptions
  }
  return API.post(API_NAMELI, '/campaing/topHashtags', options)
}  
export const stadisticBySocialMediaCampaing = () => {
  let options = {
    ...globalOptions
  }
  return API.post(API_NAMELI, '/campaing/stadisticBySocialMediaCampaing ', options)
} 

export const subTaskGraph = () => {
  let options = {
    ...globalOptions
  }
  return API.post(API_NAMELI, '/subTasks/subtaskGraph ', options)
} 

export const walletStats= (body)=>{
  let options = {
    ...globalOptions,
    body
  }
  return API.post(API_NAMELI, '/userDetailStadistics/walletStats', options)
}
export const paymentAvgStats= (body)=>{
  let options = {
    ...globalOptions,
    body
  }
  return API.post(API_NAMELI, '/userInfluencerStats/paymentAvgStats', options)
}
export const activitieStats= (body)=>{
  let options = {
    ...globalOptions,
    body
  }
  return API.post(API_NAMELI, '/userDetailStadistics/activitieStats', options)
}
export const profileStats= (body)=>{
  let options = {
    ...globalOptions,
    body
  }
  return API.post(API_NAMELI, '/userDetailStadistics/profileStats', options)
}


/*****************************************
 * Bitacoras *
 *****************************************/


export const createNewLog = (data) => {
  var body = {}
  var tipificado

  //Tipificacion de codigos
  switch (data.CodeHttp) {
    case 200:
      tipificado = "Peticion exitosa al servidor";
      break;
  }
  var hoy = new Date();
  var fecha = hoy.getDate() + '-' + ( hoy.getMonth() + 1 ) + '-' + hoy.getFullYear();
  var hora = hoy.getHours() + ':' + hoy.getMinutes() + ':' + hoy.getSeconds();
  var fechaYHora = fecha + ' ' + hora;
  body = {
    id: v4(),
    Origen: 'Web',
    Tipo: data.Tipo,
    UsuarioId: data.UsuarioId,
    UsuarioName:data.UsuarioName,
    Email: data.Email,
    Accion: data.Accion,
    Data: data.Data,
    Historico: data.Historico,
    Tipificacion: tipificado,
    FechayHora: fechaYHora,
    CodeHttp: data.CodeHttp,
  }
  console.log('body para enviar', body)

  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/bitacoras', options)
}


export const myLogs= ()=>{
  let options = {
    ...globalOptions
  }

  return API.post(API_NAMELI, '/bitacoras/myLogs', options)
}


//Log de errores
export const createNewErrorLog = (data) => {
  var body = {}
  var tipificado
  
  //Tipificacion de errores

  if(!isNaN(data.CodeHttp))
  {
    switch (data.CodeHttp) {
      case 1:
        tipificado = "Error en la posición de cuentas";
        break;
        case 10:
        tipificado = "Token vencido";
        break;
      case 400:
        tipificado = "Petición de cliente erronea";
        break;
        case 500:
          tipificado = "Error en la petición al servidor";
        break;
    }
  }
  else if(data.CodeHttp.code=="UserNotFoundException")
  {
    tipificado = "Usuario no encontrado"
  }
  else if(data.CodeHttp.code=="NotAuthorizedException")
  {
    tipificado = "Contraseña incorrecta"
  }
  else{
    tipificado = "Error desconocido"
  }

  var hoy = new Date();
  var fecha = hoy.getDate() + '-' + ( hoy.getMonth() + 1 ) + '-' + hoy.getFullYear();
  var hora = hoy.getHours() + ':' + hoy.getMinutes() + ':' + hoy.getSeconds();
  var fechaYHora = fecha + ' ' + hora;
  body = {
    id: v4(),
    Origen: 'Web',
    Tipo: data.Tipo,
    UsuarioId: data.UsuarioId,
    UsuarioName:data.UsuarioName,
    Email: data.Email,
    Accion: data.Accion,
    Data: data.Data,
    Historico: data.Historico,
    Tipificacion: tipificado,
    FechayHora: fechaYHora,
    CodeHttp: data.CodeHttp,
  }
  console.log('body para enviar', body)

  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELI, '/errorLog', options)
}

export const myErrorLogs= ()=>{
  let options = {
    ...globalOptions
  }

  return API.post(API_NAMELI, '/errorLog/myErrorLogs', options)
}

/*****************************************
 * Permisos *
 *****************************************/
 export const getClients = () => {
  let options = {
    ...globalOptions
  }

  return API.post(API_NAMELI, '/permissionsv2/myClients', options)
}

 export const getPermissions= ()=>{
  let options = {
    ...globalOptions
  }

  return API.post(API_NAMELI, '/permissionsv2/getPermissions', options)
}

//obtener todos los permisos
export const getUserRoles = () => {
  let options = {
    ...globalOptions
  }

  return API.post(API_NAMELI, '/userRoles/myRoles', options)
}

export const updatePermissions = (body) => {
  let options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELI, "/permissionsv2", options);
};

export const updateUserAttributes = (body) => {
  let options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELI, "/permissionsv2/updateUserAttributes", options);
};