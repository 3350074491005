import React, {useState, useEffect} from 'react';
import { MapContainer, TileLayer, useMapEvents,
   } from 'react-leaflet'
  import { makeStyles } from '@material-ui/core/styles';


import AddMarkerToClick from './AddMarkerToClick'
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { v4 } from 'uuid'
import "./styles.css";



const useStyles = makeStyles({
  root: {
    height: 300,
  },
});




export default function Maps(props) {
 
  
  const [markers, setMarkers] = useState([]);
return (

<div className="App" style={{ width: "100%", height: "100vh" }}>
    
  <MapContainer  
  center={[14.628434, -90.522713]}
  zoom={12}
  style={{ height: "60vh" }} > {/* omit onClick */}
  <TileLayer
    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  />
  
    <AddMarkerToClick changeCoords={value => setMarkers(value)} />
  </MapContainer>
                  
</div>

);
}