/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Select from 'react-select'


import ReactDataTable from '@easygosanet/react-datatable'
import TitlebarImageList from './GalleryPosts'
import TitlebarStoryList from './GalleryStories'
import StarsShow from 'components/starsShow'


import './assets/css/styles.css'
import { getLogoByName, getClassNameByStatus } from 'myUtil'

/****material components */
import NativeSelect from '@material-ui/core/NativeSelect';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import FaceIcon from '@mui/icons-material/Face';


import { getDatos, setProp } from 'redux/actions/fanpageWebHook.actions'
import { withUtils } from 'utils'
import * as APILI from 'helpers/apiLI';
import { v4 } from 'uuid'

import Swal from 'sweetalert2/dist/sweetalert2.js'

import Instagram from './Instagram'
import Facebook from './Facebook'
import TikTok from './TikTok'

import "./styles.css";



const Influencer = (props) => {
  
  const {importedUser} = props
 
/******************************************************** */
  return (
    <>
    {importedUser?.type === "Facebook" ?
    <Facebook importedUser={importedUser}/> :
    <Instagram importedUser={importedUser}/>
    }
     {importedUser?.type === "TikTok" &&
      <TikTok importedUser={importedUser}/>
    }
</>
  )
}

const mapStateToProps = (state) => ({
  info: state.fanpageDatosWeb.info,
})

const mapDispatchToProps = {
  setProp,
  getDatos,
}

export default connect(mapStateToProps, mapDispatchToProps)(withUtils(withRouter(Influencer)))
