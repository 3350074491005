import React from 'react';

import Advertiser from 'components/advertiser'

const AdvertiserPage = (props) => {
    const {userRol} = props;
    return (
      <>
      {userRol==='admin'?
      <Advertiser />:
      <h1>No tienes privilegios</h1>
      }
      </>
      )  };

export default AdvertiserPage;