import React, { useState, useEffect } from 'react'
import * as utils from 'data.js'


//helpers
import * as APILI from '../../helpers/apiLI'
import _ from 'lodash'
//css
import './style.css'
import { AmplifyLoadingSpinner } from '@aws-amplify/ui-react'

import DataTableUtils,
{
  Share,
  ShowColumn,
  FilterColumn
} from '@easygosanet/react-datatable-utils'
import ReactDataTable, { DateRange } from '@easygosanet/react-datatable'


//Tables style
const customStyles = {
  headCells: {
    style: {
      '&:nth-last-of-type(2)': {
        backgroundColor: '#E3E4E6',
        width: '40px'
      },
      '&:nth-last-of-type(4)': {
        backgroundColor: '#E3E4E6',
        width: '40px'
      },
      '&:nth-last-of-type(6)': {
        backgroundColor: '#E3E4E6',
      },
      '&:nth-last-of-type(7)': {
        font: 'normal normal bold 16px / 12px ',
        color: '#05252D'
      },
    },
  },
}


const ErrorLogs = (props) => {
  const [refresh,setRefresh] = useState(false)
  const [tableData, setTableData]= useState([])
  const [filtered, setFiltered] = useState([])
  const [filters, setFilters] = useState({})

  useEffect(()=>{
    APILI.myErrorLogs()
    .then(res=>{
      let filteredData=res.data.pages.filter(function(info){
        return info.Origen==='Web'
      })

    setTableData(filteredData)
    setFiltered(filteredData)
    })
    .catch(err=>{
      console.log('err myPages',err)
    })

  },[refresh])

const [columns, setColumns] = useState(
  [
    {
      id: 'Accion',
      name: 'Accion',
      selector: 'Accion',
      sortable: true,
      style: {
        color: '#05252D'
      }
    },
    {
      id: 'Tipo',
      name: 'Tipo',
      selector: 'Tipo',
      sortable: true,
      style: {
        color: '#05252D'
      }
    },
    {
      id: 'Tipificacion',
      name: 'Resultado',
      selector: 'Tipificacion',
      sortable: true,
      style: {
        color: '#05252D'
      }
    },
    {
      id: 'FechayHora',
      name: 'Fecha y Hora',
      selector: 'FechayHora',
      sortable: true,
      center: true,
      style: {
        fontWeight: 'bold',
      },
    },
    {
      id: 'Email',
      name: 'Usuario',
      selector: 'Email',
      sortable: true,
      center: true,
      style: {
        fontWeight: 'bold',
      },
    },
    {
      id: 'Origen',
      name: 'Origen',
      selector: 'Origen',
      sortable: true,
      center: true,
      style: {
        backgroundColor: '#E3E4E6',
        fontWeight: 'bold',
      },
    }
])

const handleFilterPerColumn = (column, type, value) => {
  setFilters((prev) => ({ ...prev, [column]: { type, value } }))
}

const handleShowColumnChange = (column, show) => {
  let index = columns.findIndex((col) => col.selector === column)
  let aux = columns.map((col) => col)
  aux[index].omit = show
  setColumns(aux)
}

const handleColumnSortChange = (positions) => {

  let aux = columns.map((col) => col)

  positions.forEach((columnId, position) => {
    let index = columns.findIndex((col) => col.selector === columnId)
    aux[index].position = position
  })
  aux = _.orderBy(aux, ['position'])

  setColumns(aux)
}

const handleFilterTextChange = (value) => {
  let filtered = tableData.filter(item => 
      item.Accion.toLowerCase().includes(value.toLowerCase()) ||
      item.FechayHora.toLowerCase().includes(value.toLowerCase())||
      item.Email.toLowerCase().includes(value.toLowerCase())||
      item.Tipo.toLowerCase().includes(value.toLowerCase())
      )
  setFiltered(filtered)
}
const exportDataToExcel = () =>{
  let headers = []
  let dataExcel=[]
  let selectors = []
  let excelData = [];
  var filteredColumns=columns.filter(function(col){
    return col.omit===false
  })
  
  filteredColumns.forEach((element)=>{
    headers.push(element.name)
    selectors.push(element.selector)
  })  
  
  dataExcel.push(headers)

       if(filtered.length>0)
       {
        filtered?.forEach((element) => {
           excelData = [];
             for(let k=0; k<selectors.length; k++)
             {
                 excelData.push(
                   element[selectors[k]]
                 );
             }
          
             dataExcel.push(excelData);
           });
           
       }
   console.log('dataParaExportar', dataExcel)
   return dataExcel;
}
  
  return (
    <div className='form-dashboard'>
      <div className='Bar-content'>
      <p className="title-text">Log de errores</p>
        <div className='content-menu list'>
        < DataTableUtils onChangeFilterText={handleFilterTextChange}>
              <Share
              contacts={utils.myContacts}
              data={exportDataToExcel()}
              isArray={true}
              exportXLSX={true}
              exportTXT={true}
              exportDropbox={true}
              exportDrive={true}
              exportCSV={true}
              printable={true}
              exportPNG={true}
              exportLink={true}
              />
             <ShowColumn
              columns={columns}
              showColumn={handleShowColumnChange}
              onSortChange={handleColumnSortChange}
            />
            <FilterColumn
              columns={columns}
              onFilterColumn={handleFilterPerColumn}
            />
          </DataTableUtils>
        </div>
      </div>
      
        <div className="content-submenu-userList">
       
      </div>
      <div className="row-section">
      <ReactDataTable
        columns={columns}
        data={filtered}
        persistTableHead
        pagination
        noHeader
      />
      </div>
    </div>
  )
}

export default ErrorLogs