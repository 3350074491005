import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import "./style.scss";
import { Progress } from "antd";

const ProgresiveBar = ({Estado}) => {
  const [percent1, setPercent1] = useState(0);
  const [success1, setSuccess1] = useState({ percent: 0 });

  const [percent2, setPercent2] = useState(0);
  const [success2, setSuccess2] = useState({ percent: 0 });

  const [percent3, setPercent3] = useState(0);
  const [success3, setSuccess3] = useState({ percent: 0 });

  const [percent4, setPercent4] = useState(0);
  const [success4, setSuccess4] = useState({ percent: 0 });

  const [percent5, setPercent5] = useState(0);
  const [success5, setSuccess5] = useState({ percent: 0 });

  useEffect(() => {
    if(Estado === "Desconocido"){
      setPercent1(100)
      setSuccess1({ percent: 100 })
    }
    if(Estado === "Activa"){
      setPercent1(100)
      setSuccess1({ percent: 100 })
      setPercent2(100)
      setSuccess2({ percent: 100 })
    }
    if(Estado === "Aprobada"){
      setPercent1(100)
      setSuccess1({ percent: 100 })
      setPercent2(100)
      setSuccess2({ percent: 100 })
      setPercent3(100)
      setSuccess3({ percent: 100 })
    }
    if(Estado === "Completado"){
      setPercent1(100)
      setSuccess1({ percent: 100 })
      setPercent2(100)
      setSuccess2({ percent: 100 })
      setPercent3(100)
      setSuccess3({ percent: 100 })
      setPercent4(100)
      setSuccess4({ percent: 100 })
    }
    if(Estado === "Finalizada"){
      setPercent1(100)
      setSuccess1({ percent: 100 })
      setPercent2(100)
      setSuccess2({ percent: 100 })
      setPercent3(100)
      setSuccess3({ percent: 100 })
      setPercent4(100)
      setSuccess4({ percent: 100 })
      setPercent5(100)
      setSuccess5({ percent: 100 })
    }
  }, [Estado]);

  return (
    <>
      <div className="progresiveBar">
        <div className="box">
          <Progress
            percent={percent1}
            success={success1}
            format={() => <p className="texto">Pendiente</p>}
            className="progresive"
            strokeColor="#57D4FD"
          />
          <Progress
            type="circle"
            percent={percent1}
            width={30}
            className="circulo"
            strokeColor="#57D4FD"
          />
        </div>
        <div className="box">
          <Progress
            percent={percent2}
            success={success2}
            format={() => <p className="texto">Activa</p>}
            className="progresive"
          />
          <Progress
            type="circle"
            percent={percent2}
            width={30}
            className="circulo"
            strokeColor="#57D4FD"
          />
        </div>
        <div className="box">
          <Progress
            percent={percent3}
            success={success3}
            format={() => <p className="texto">Aprobada</p>}
            className="progresive"
          />
          <Progress
            type="circle"
            percent={percent3}
            width={30}
            className="circulo"
            strokeColor="#57D4FD"
          />
        </div>
        <div className="box">
          <Progress
            percent={percent4}
            success={success4}
            format={() => <p className="texto">Completada</p>}
            className="progresive"
          />
          <Progress
            type="circle"
            percent={percent4}
            width={30}
            className="circulo"
            strokeColor="#57D4FD"
          />
        </div>
        <div className="box">
          <Progress
            percent={percent5}
            success={success5}
            format={() => <p className="texto">Finalizada</p>}
            className="progresive"
          />
          <Progress
            type="circle"
            percent={percent5}
            width={30}
            className="circulo"
            strokeColor="#57D4FD"
          />
        </div>
      </div>
    </>
  );
};

export default ProgresiveBar;