import React, { useState, useEffect } from 'react'
import * as utils from 'data.js'


//helpers
import * as APILI from '../../helpers/apiLI'



//components
import SelectorBox from 'components/selector'
import TargetContentMap from 'components/targetContentMap'
import TargetContentText from 'components/targetContentText'
import TargetContentNetworks from 'components/targetContentNetworks'
import StackBarGraph from 'components/stackBarGraph'
import PageModal from 'components/pageModal'
import TargetContent from 'components/targetContentPages'
import InfoBox from 'components/infoBox'
import InfluencerTasks from '../influencerTasks'

import Swal from 'sweetalert2/dist/sweetalert2.js'
import Typography from '@material-ui/core/Typography';
import NativeSelect from '@material-ui/core/NativeSelect';
import Paper from '@material-ui/core/Paper';

//Delivery Column
import { getLogoByName, getClassNameByStatus } from 'myUtil'

//css
import './style.css'
import { AmplifyLoadingSpinner } from '@aws-amplify/ui-react'

import ReactDataTable  from '@easygosanet/react-datatable'


const DeliveryCol = ({ text, className, onClick }) => (
  <div className={className}>
    <div className="delivery-text">
      {text}
    </div>
  </div>
)

//Tables style

const customStyles = {
  headCells: {
    style: {
      '&:nth-last-of-type(2)': {
        backgroundColor: '#E3E4E6',
        width: '40px'
      },
      '&:nth-last-of-type(4)': {
        backgroundColor: '#E3E4E6',
        width: '40px'
      },
      '&:nth-last-of-type(6)': {
        backgroundColor: '#E3E4E6',
      },
      '&:nth-last-of-type(7)': {
        font: 'normal normal bold 16px / 12px PierSans',
        color: '#05252D'
      },
    },
  },
}


const System = ({influencerInfoPage, refreshTable, setrefreshTable}) => {
  const [pages,setPages]= useState([])
  const [showModalPage, setShowModalPage] = useState(false)
  const [activePage, setActivePage]= useState(null)
  const [refresh,setRefresh] = useState(false)

  let [tableData, setTableData]= useState()
  const getPages =(data)=>{
    setPages(data)
    console.log('DataPages', data)
    setTableData(data.map(pag=>({
      name: pag.title,
      createdAt: pag.createdAt,
      target: pag?.target ? pag?.target : 'No definido',
      id: pag.id
    })))
    console.log('tableData', tableData)
  }

  useEffect(()=>{
    APILI.myPages().then(res=>getPages(res.data.pages)).catch(err=>console.log('err myPages',err))
  },[refresh])


const emptyArray = []
const listGeneralStatistics = [
{
  text: 'Cuenta',
  number: 'id',
},
{
  text: 'Nombre completo',
  number: 'name',
},
{
  text: 'Fecha de nacimiento',
  number: 'dateBirth',
},
{
  text: 'Edad',
  number: 'fecha Nacimiento',
},
{
  text: 'Genero',
  number: 'gender',
},
{
  text: 'Email',
  number: 'email',
},
{
  text: 'Preferencias',
  number: 'intereses',
},
]
 /******************************************************** */
 
const dataAccess = [
  {
    text: 'Ultima direccion IP',
    number: '200.119.187.128',
  },
  {
    text: 'Ultimo ingreso',
    number: '16 de febrero de 2020 H: 15:02:01',
  },
  {
    text: 'Ultimo Backend',
    number: 'Facebook',
  },
  {
    text: 'Ultimo cliente',
    number: 'LikeInc/1.2.4; IOS 14.4.0',
  },
  {
    text: 'Ultima version',
    number: '1.2.4',
  },
  ]
   /******************************************************** */
  const openModalPage= (id)=>{
     APILI.findPageById({id: id})
    .then(res=>{
      console.log('Page by Id', res.data.pages[0])
      setActivePage(res.data.pages[0])
      setShowModalPage(true)
    })
    .catch(err=>{console.log('err by id')})
    
  }


const DetailCol = ({ text, Camp }) => (
  <div className="firstColl w-100">
    <div className="icon-container">
      <i class="fas fa-eye" onClick={e => {
        openModalPage(Camp.id)
        console.log('Pagina a abrir', Camp.id)
      }}></i>
    </div>
    <p className="name-fc">
      {text}
    </p>
  </div>
)

let [tables, setTables] = useState(
    [
      {
        name: 'Título',
        selector: 'name',
        sortable: true,
        minWidth: '300px',
        style: {
          color: '#05252D'
        },
        cell: (row) => (
          <DetailCol
            text={row.name}
            Camp={row}
          />
        ),
      },
      {
        name: 'Fecha de creación',
        selector: 'createdAt',
        sortable: true,
        center: true,
        style: {
          fontWeight: 'bold',
        },
      },
      {
        name: 'Target',
        selector: 'target',
        sortable: true,
        center: true,
        style: {
          backgroundColor: '#E3E4E6',
          fontWeight: 'bold',
        },
      }
  ])
  
  return (
    <div className='form-dashboard'>
      <div className='Bar-content'>
        <div className='content-menu list'>
  
          {/* <div
            className='btnOrange-dash'
          >
            <i className='fas fa-plus' onClick={openNewModal()}></i>
          </div> */}
        </div>
      </div>
      
        <div className="content-submenu-userList">
       
      </div>
      
      
     
             {/*    { pages.map(data=>(
                  <div className="content-pay">
                  <div>
                      <div className="row-info">
                          <div className="circle" />
                          <p className="title-text"> {data.title}</p>
                          <span onClick={()=>openModalPage(data)} className='editar' style={{float: 'right', paddingRight: '5px'}}>Editar</span>
                      </div>
                      <div style={{display:"flex", margin: "25px"}}>
                      {data.content.length >0 ? data.content.map(dataContent=> (
                        <TargetContent valor={data.id}  listGeneralStatistics={emptyArray}  nameClass="targetContent-small" />
                      )) : <h5>sin contenidoo</h5>}
                      </div>
                  </div>
                  </div>
                )) } */}
              
                  <div className="row-section">
                  <ReactDataTable
                    columns={tables}
                    data={tableData}
                    persistTableHead
                    noDataComponent={"No hay datos para mostrar"}
                    pagination
                    noHeader
                    paginationComponentOptions={{
                      rowsPerPageText: 'Elementos por página',
                      rangeSeparatorText: 'de',
                    }}
                />
                  </div>
      {showModalPage && <PageModal  page={activePage}  showModal={(data)=>setShowModalPage(data)} refresh={refresh} setRefresh={(dat) => setRefresh(dat)} />}
    </div>
  )
}

export default System