import React, {useState} from 'react'

import './asserts/styles.css'
import { getLogoByName, getClassNameByStatus } from 'myUtil'

const TargetContentText = (props) => {

    const {dataText,action} = props
    
    const nameClass = props.nameClass;

    

    console.log(dataText)
    const RenderText = () => {
   
        return (
            
                <div >
                  
                    <p>El usuario se encuentra <span  className={getClassNameByStatus(dataText.status)} >{dataText.status}</span></p> 
                    <button className="btn btn-info" onClick={action}>Moderar usuario</button>
                   
                </div>
            
        )
    
}

    return (
        <div className={nameClass}>
            <div className="marginTarget">
                   <RenderText/>
            </div>
        </div>
    )

}

export default TargetContentText