import React from 'react'
import * as utils from 'data.js'
import MultiSelect from 'libs/multiselect'
import * as APILI from 'helpers/apiLI';
import {useEffect, useState} from 'react'
import { Auth } from 'aws-amplify'
import Swal from 'sweetalert2'
import './style.scss'
import { ConsoleLogger } from '@aws-amplify/core';
const AdministrationPanel = () => {
  const [clients, setClients] = useState([])
  const [currentPermissions, setCurrentPermissions] = useState([])
  const [permissions, setPermissions] = useState([])
  const [statePermissions, setStatePermissions] = useState([])
  const [members,setMembers]= useState([])
  const getClients= async()=>{
    let clients= await APILI.getClients()
    console.log('ClientsDynamo',clients.data.clients)
     let clientsData=   clients.data.clients
    let arrayAux=[]
    let index = 0
    clientsData.forEach(el=>{
      arrayAux.push({
        id: el?.Attributes[0]?.Value,
        role: el?.Attributes[4]?.Value,
        name: el?.Attributes[5]?.Value,
        email: el?.Attributes[6]?.Value,
        value: index
      })
      index++
    })
    console.log('Clients',arrayAux)
    setClients({
      options: [...arrayAux],
      value: []
    }) 
  }
  const getPermissions = () =>{
    APILI.getPermissions().then((permissions) => {
      let arrayData= permissions.data.permissions
      setPermissions(arrayData);
  })
  }
  const updateUser = async()=>{
    const user = await Auth.currentAuthenticatedUser();
    console.log('USER',user)
    let response=await Auth.updateUserAttributes(user, {
      'custom:userRol': 'moderador'
    });
  console.log('Response User',response)
  }
  const selectRole = (name) =>{
    let current = permissions.filter((item) => item.name == name)
    {console.log('current',current[0].name)}
    console.log('clients',clients)
    let clientsArray= clients.options
    let arrayAux= []
    let cont= 0
    console.log('ClientsArray',clientsArray)
    clientsArray.forEach(el=>{
      if (el.role === current[0].name){
        console.log('Se encontro con el rol', el.role)
        arrayAux.push(cont)
      }
      cont++;
    })
    setCurrentPermissions(current[0].menuRoutes)
    setStatePermissions(current)
   console.log('ArrayAux',arrayAux)
  }
  const updatePermitsRole =(currentList, target) =>{  
    var current = statePermissions[0].menuRoutes.filter((item) => item.name == currentList)
    /* current.permits[target[0]] = !target[1] */
  console.log('current2', current)
  console.log('state', statePermissions)
  console.log('bodyToChange', currentList, target)
  let permits= statePermissions
  permits[0].menuRoutes.forEach((el)=>{
    if(el.name===currentList){
      console.log('Cambiar en', el.name)
      console.log('Cambiar titulo',target[0])
      if(target[0] === 'Crear'){
        el.permits.Crear = !el.permits.crear
        console.log('Se cambio a', el.permits.Crear)
      }
      if(target[0] === 'Editar'){
        el.permits.Editar = !el.permits.Editar
        console.log('Se cambio a', el.permits.Editar)
      }
      if(target[0] === 'Leer'){
        el.permits.Leer = !el.permits.Leer
        console.log('Se cambio a', el.permits.Leer)
      }
    }
  })
  updatePermissions(permits[0].menuRoutes,permits)
}
const updatePermissions = (val,val1)=>{
  setCurrentPermissions([...val])          
  setStatePermissions([...val1])
}
  useEffect(() => {
      getClients()
      getPermissions()
  }, [])
  const sendPermits = async ()=>{
    console.log('Clients',clients)
    for(let i=0; i<clients.value.length; i++){
      console.log('Value', clients.value[i])
      let client= clients.options[clients.value[i]]
      console.log('Cliente a editar', client)
      console.log('currentPermissions',statePermissions[0].name)
      let response=await APILI.updateUserAttributes({
          user: client.email,
          userRol: statePermissions[0].name 
      });
      console.log('Response user', response)
    }
    console.log('Enviar a dynamo',statePermissions[0])
    const response = await APILI.updatePermissions(statePermissions[0])
    console.log('Response',response)
    await Swal.fire(
      { 
        title: 'Roles y permisos',
         text: "Se han modificado correctamente",
          icon: 'success',
           showCancelButton: false,
            confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok'
             }).then((result) => { 
        })
  }
  const value = [0]
  const handleUsersChange = (value) => {
    console.log('handleUsersChange')
    console.log(value)
    console.log('UsersAle',clients)
    if(value){
      setClients((prev)=>({
        ...prev,
        value: value
      }))
    }
  }
  return (
    <div className='form-dashboard'>
      <h2 className='titleRol'>Selección de usuarios</h2>
      <div className='content-menu'>
        {console.log('UsersAle',clients)}
        <MultiSelect
          className="SelectTwoSides"
          availableHeader='Disponibles'
          selectedHeader='Selecionados' 
          showControls
          searchable
          labelKey='email'
          {...clients}  
          onChange={handleUsersChange}
        />
        <select className='SelectRoll' onChange={e => selectRole(e.target.value)}>
        <option value="" disabled>Selecciona el rol</option>
        {permissions.map((option) => (
        <option value={option.name}>{option.label}</option>
        ))}
        </select>
      </div>
      <div className="grid">
        {currentPermissions!==[]?currentPermissions?.map(function(element ){
          return(
      <div className='grid__container'>
        <h3 className="grid__title">{element.name}</h3>
        <div className='grid__Box'>
        {Object.entries(element.permits).map((a) =>(
          <div className='grid__Box-child'>
            <p>{a[0]}</p>
            <input type='checkbox' className='checkbox' checked={a[1]} onChange={e => updatePermitsRole(element.name, a)}/>
          </div>
        ))}
        </div>
      </div>
          )
        }):""}
      </div>
      <button className='BtnPanelAdmin' onClick={e=>sendPermits()}>Aceptar</button>
    </div>
  )
}
export default AdministrationPanel