import React from 'react';
// components
import UserList from 'components/moderation/userList'
const UserListPage = (props) => {
    const {userRol} = props;
  return (
    <>
    {userRol==='admin'?
    <UserList />:
    <h1>No tienes privilegios</h1>
    }
    </>
    )  
  }


export default UserListPage;