import React, { useState } from 'react'
import _ from 'lodash'
import { Auth } from 'aws-amplify'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap'
import PasswordRecoveryForm from './password_recovery_form'
import { AmplifyForgotPassword } from '@aws-amplify/ui-react';
import { Link } from "react-router-dom";
import * as APILI from 'helpers/apiLI';

import './assets/css/styles.css'

import grupo from './assets/img/grupo.png'
import google from './assets/img/google.png'

import * as Modal from 'redux/actions/modal.actions'
import { showLoader } from 'redux/actions/loader.actions'

import SignInWithGoogle from './signing_google'
import swal from 'sweetalert2'

//import PageLoader from 'components/page_loader';
//import Loader from 'components/loader';
import { withUtils } from 'utils'

const TAG = 'LoginForm'

const SignInForm = props => {

  const [userToLog, setUserToLog]=useState({})
  const getUser = () => { 
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.log('Not signed in'))
  }

  const [user, setUser]=useState({})
  const [state, setState] = useState({ email: '', password: '' })
  const [errors, setErrors] = useState({ email: false, password: false })


  const handleInputChange = (e) => {
    let name = e.target.name
    let value = e.target.value

    setState((prev) => ({
      ...prev,
      [name]: value,
    }))

    setErrors((prev) => ({
      ...prev,
      [name]: _.isEmpty(value),
    }))
  }

  const signInWithGoogle = async e => {
    e.preventDefault()

    try {
      await Auth.federatedSignIn({ provider: 'Google' })
    } catch (err) {
      console.log(err)
    }
  }

  const signInWithFacebook = async e => {
    e.preventDefault()

    try {
      await Auth.federatedSignIn({ provider: 'Facebook' })
    } catch (err) {
      console.log(err)
    }
  }

  const saveLog = async (body) => {
    try {
      APILI.createNewLog(body).then(res => {
        console.log('Log Creado')
      }).catch(err => {
      console.log('err', err) 
      })
      
  } catch (err) {
      console.log("No se pudo guardar el log", err)
  }
  }

  const saveErrorLog = async (body) => {
    try {
      APILI.createNewErrorLog(body).then(res => {
        console.log('Error log Creado')
      }).catch(err => {
      console.log('err', err) 
      })
      
  } catch (err) {
      console.log("No se pudo guardar el log de error", err)
  }
  }

  const areValidInputs = () => {
    const mErrors = {
      email: state.email === '',
      password: state.password === '',
    }

    setErrors((prev) => ({
      ...prev,
      ...mErrors,
    }))

    return !_.some(mErrors, (e) => e)
  }
  let error = ''
  const signIn = async e => {
    e.preventDefault()
    getUser().then((userData) => setUser(userData))
    localStorage.setItem('CurrentUser', JSON.stringify( user ));
    if (!areValidInputs()) return

    props._showLoader(true)


    console.log("props no refresh", props)
    try {
      props.utils.log(TAG, 'username', state.email)
      props.utils.log(TAG, 'password', state.password)
       await Auth.signIn(state.email, state.password).then
       (data => {
        setUserToLog(data)
        console.log("props no refresh", props)
        console.log("Inicio de sesion de", data)
        props.onStateChange('signedIn', {})
        // console.log("data", data)

        const bodyLog = {
          Tipo: "Login",
          Accion: "Inicio de sesión",
          Data: data.attributes,
          UsuarioId: data.username,
          UsuarioName:data.attributes.name,
          Email: data.attributes.email,
          Historico: {},
          CodeHttp: 200
      }
      saveLog(bodyLog)

      })

      // .catch(err => {
      //   console.log("errores de signin", err)
      //   swal.fire({
      //     icon: 'error',
      //     title: 'Error',
      //     html:  'El usuario o la contraseña son inválidos, porfavor inténtalo de nuevo',
      //     onClose: props._hide,
      //     focusConfirm: false,
      //   })

      //   // props._showAlertModal('Error!', 'Credenciales invalidas', Modal.MODAL_TYPE_ERROR)
      // })
      // props._showAlertModal('Error!', 'Credenciales invalidas', Modal.MODAL_TYPE_ERROR)

    } catch (err) {
      let { code } = err
      props.utils.log(TAG, 'error signing in', err)
      console.log("error en ", err)
      console.log("code error en ", code)
      switch (code) {
        case 'UserNotConfirmedException':
          /* await Auth.resendSignUp(state.email) */
          props.onStateChange('confirmSignUp', { username: state.email, password: state.password })
          break
        case 'NotAuthorizedException':
          error = 'El usuario o contraseña no son válidos. ¡Inténtalo de nuevo!'
          break
        case 'UserNotFoundException':
          error = 'El usuario o contraseña no son válidos. ¡Inténtalo de nuevo!'
          break
        default:
          error = 'Ha ocurrido un error, porfavor inténtalo de nuevo'
      }


      const bodyLog = {
        Tipo: "Login error",
        Accion: "Inicio de sesión",
        Data: " ",
        UsuarioId: " ",
        UsuarioName:state.email,
        Email: state.email,
        Historico: {},
        CodeHttp: err
    }

    saveErrorLog(bodyLog)
    }

    props._showLoader(false)

    if (error !== '') {
      console.log('mostrar mensaje', error)
      /* props._showAlertModal('¡Error!', error, Modal.MODAL_TYPE_ERROR) */
      swal.fire({
           icon: 'error',
           title: '¡Error!',
            html:  error,
           onClose: props._hide,
          focusConfirm: false,
      })
  
    }
  }

  const passwordRecovery = e => {
    // console.log("Hola desde gotoPAsswordRecovery")
    e.preventDefault()

    props.onStateChange('forgotPassword', {})
    props.onStateChange('forgotPassword', {})
    console.log("Imprimiendo props", props.onStateChange)
  }
  const codeConfirm = e => {
    // console.log("Hola desde gotoPAsswordRecovery")
    e.preventDefault()

    props.onStateChange('confirmSignUp', {})
    props.onStateChange('confirmSignUp', {})
    // console.log("Imprimiendo props", props.onStateChange)
  }

  const goToRegister = e => {
    e.preventDefault()
    props.onStateChange('register', {})
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      signIn(e)
    }
  }

  return (
    <div className='form-login'>
      <div className='form-margin-login'>
        <div className='lado'>
          <h1 className='title-form'>¡Que alegre verte de nuevo!</h1>

          <FormGroup className='w-100 d-flex flex-column align-items-center'>
            <Label className='textos'>Correo electr&oacute;nico</Label>
            <Input
              type='text'
              name='email'
              value={state.email}
              invalid={errors.email}
              onChange={handleInputChange}
              placeholder='Ingresa tu correo electr&oacute;nico'
              className='formInput'
            />
            <FormFeedback>Ingresa un correo v&aacute;lido</FormFeedback>
          </FormGroup>

          <FormGroup className='w-100 d-flex flex-column align-items-center' id='contBtnPass'>
            <Label className='textos'>Contrase&ntilde;a</Label>
            <Input
              type='password'
              name='password'
              value={state.password}
              invalid={errors.password}
              onKeyPress={handleKeyPress}
              onChange={handleInputChange}
              placeholder='Ingresa tu contrase&ntilde;a'
              className='formInput'
            />
            
            <FormFeedback>Ingresa una contrase&ntilde;a</FormFeedback>
          </FormGroup>
          
          <div className='passForget'>
            {/* <a className ='passForgetA'onClick={codeConfirm} href='/#'>
              Confirmar código
            </a> */}
            <a className ='passForgetA'href='/#'>
              
            </a>
            <a onClick={passwordRecovery} href='/#'>
              Olvidé mi contrase&ntilde;a
            </a>
            {/* <AmplifyForgotPassword/> */}
            {/* <PasswordRecoveryForm/> */}
            {/* <Link  to="password_recovery">Olvide mi contraseñaaaa</Link> */}

          </div>

          <a onClick={signIn} className='btn-plus-form' href='/#'>
            INGRESAR
          </a>
          {/* <div className='redes'> */}
       {/* <h5 className='subTitle-form'>Tambi&eacute;n puedes</h5> */}

          <div className='redes'>
            {/* <a onClick={signInWithFacebook} className='facebook' href='/#'>
              <i className='fab fa-facebook'></i>
              Iniciar sesi&oacute;n con Facebook
            </a>

            <a onClick={signInWithGoogle} className='google' href='/#'>
              <img src={google} alt='G' />
              Iniciar sesi&oacute;n con Google
            </a>

            <SignInWithGoogle props={props}/> */}
          </div> 
  {/* </div> */}
          <div className='d-flex w-100 text-center link my-3'>
            <a onClick={goToRegister} className='w-100' href='/#'>
              ¿No tienes una cuenta?
            </a>
          </div>
        </div>

        <div className='lado'>
          <img src={grupo} className='people' alt='' />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  _showLoader: show => dispatch(showLoader(show)),
  _showAlertModal: (title, message, type, callback) => dispatch(Modal.showAlertModal(title, message, type, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withUtils(withRouter(SignInForm)))
