import React from 'react'
import { Link } from "react-router-dom";

// components
import Datos from 'components/fanpageDatos'
import MetaTags from 'components/metatags'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

const DatosPage = () => (
    <>
      <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
   
        },
      }}
    >

      <Paper className="m-10 p-5" elevation={3} >
        <MetaTags title='Datos' description='Datos de páginas' />
        <Datos />
      
        </Paper>
    </Box>
       
    </>
)

export default DatosPage
