import * as API from 'helpers/api';
import * as Logger from 'utils/logger'
import * as Modal from './modal.actions'
import { showLoader } from './loader.actions'

export const ACCOUNTS_CLEAR = 'ACCOUNTS:CLEAR'
export const ACCOUNTS_REMOVE = 'ACCOUNTS:REMOVE'
export const ACCOUNTS_ADD_TAG = 'ACCOUNTS:ADD:TAG'
export const ACCOUNTS_ADD_ONE = 'ACCOUNTS:ADD:ONE'
export const ACCOUNTS_ADD_LIST = 'ACCOUNTS:ADD:LIST'
export const ACCOUNTS_SET_EDIT = 'ACCOUNTS:SET:EDIT'
export const ACCOUNTS_TAGS_CLEAR = 'ACCOUNTS:TAGS:CLEAR'
export const ACCOUNTS_CLEAR_EDIT = 'ACCOUNTS:CLEAR:EDIT'
export const ACCOUNTS_SET_DEFAULT = 'ACCOUNTS:SET:DEFAULT'

const TAG = 'ACCOUNTS:ACTION'

export const getAccounts = (client, useDefault) => async (dispatch) => {
  dispatch(showLoader(true))
  dispatch(clearAccounts())

  try {
    const response = await API.getAccounts(client)

    Logger.log(TAG, 'getAccounts', response)
    const { accounts } = response.data

    accounts.forEach((account, idx) => {
      const owner = client === account.client

      account = {
        ...account,
        owner,
      }

      dispatch({
        type: ACCOUNTS_ADD_ONE,
        account,
      })

      if (idx === 0 && useDefault) {
        dispatch(setDefault(account.id))
      }
    })
  } catch (err) {
    Logger.log(TAG, 'getAccounts', err)
  }

  dispatch(showLoader(false))
}

export const getAccountTags = (account) => async (dispatch) => {
  dispatch(clearAccountTags())

  try {
    const response = await API.getAccountTags(account)

    Logger.log(TAG, 'getAccountTags', response)
    const { tags } = response.data

    tags.forEach((tag) => {
      dispatch(addTag(tag))
    })
  } catch (err) {
    Logger.log(TAG, 'getAccountTags', err)
  }

  dispatch(showLoader(false))
}

export const createAccount_logout = (id, name, username, credit, edit, createAt, members) => dispatch => {
  dispatch(showLoader(true))
  let now = Date.now()

  let body = {
    id,
    name,
    members,
    status: true,
    updateAt: now,
    client: username,
    credit: credit || 0,
    createAt: edit ? createAt : now,
  }

  return API.createAccount(body)
}

export const createAccount = (id, name, username, phone, email, address, credit, members, edit) => async (dispatch) => {
  dispatch(showLoader(true))

  const body = {
    id,
    name,
    phone,
    email,
    address,
    members,
    status: true,
    client: username,
    credit: edit ? credit : 0,
  }

  try {
    const response = edit
      ? await API.updateAccount(body)
      : await API.createAccount(body)

    Logger.log(TAG, 'createAccount', response)
    let title = edit ? 'Cuenta Editada' : 'Cuenta Creada'
    let message = edit
      ? 'La cuenta fue editada exitosamente.'
      : 'La cuenta fue creada exitosamente.'

    dispatch(
      Modal.showAlertModal(title, message, Modal.MODAL_TYPE_SUCCESS, () => {
        dispatch(getAccounts(username))
        dispatch(setDefault(id))
      })
    )
  } catch (err) {
    Logger.log(TAG, 'error createAccount', err)
    let message = edit
      ? 'Ocurrió un error al editar la cuenta'
      : 'Ocurrió un error al crear la cuenta'
    dispatch(Modal.showAlertModal('Error!', message, Modal.MODAL_TYPE_ERROR))
  }

  dispatch(showLoader(false))
}

export const upsertAccountTag = (id, name, account, edit) => async (dispatch) => {
  const body = {
    id,
    name,
    account,
  }

  try {
    const response = edit
      ? await API.updateAccountTag(body)
      : await API.createAccountTag(body)

    Logger.log(TAG, 'upsertAccountTag', response)

    dispatch(getAccountTags(account))
  } catch (err) {
    Logger.log(TAG, 'error upsertAccountTag', err)
  }
}

export const updateAccountMembers = (id, members) => async dispatch => {
  dispatch(showLoader(true))

  const body = {
    id,
    members,
  }

  try {
    const response = await API.updateAccount(body)
    Logger.log(TAG, 'updateAccount', response)

    dispatch(
      Modal.showAlertModal(
        'Cuenta Compartida',
        'La cuenta fue compartida exitosamente.',
        Modal.MODAL_TYPE_SUCCESS,
        () => {}
      )
    )
  } catch (err) {
    Logger.log(TAG, 'error updateAccount', err)
    dispatch(
      Modal.showAlertModal(
        'Error!',
        'Ocurrió un error al compartir la cuenta',
        Modal.MODAL_TYPE_ERROR
      )
    )
  }

  dispatch(showLoader(false))
}

export const deleteAccount = id => dispatch => {
  dispatch(showLoader(true))

  // API.deleteAccount(id)
  //   .then(response => {
  //     Logger.log(TAG, 'deleteAccount', response)
  //     dispatch(Modal.showAlertModal('Cuenta Eliminada', 'La cuenta fue eliminada exitosamente.', Modal.MODAL_TYPE_SUCCESS, () => {
  //       dispatch(removeAccount(id))
  //     }))
  //   })
  //   .catch(err => {
  //     Logger.log(TAG, 'error deleteAccount', err)
  //     dispatch(Modal.showAlertModal('Error!', 'Ocurrió un error al eliminar la cuenta', Modal.MODAL_TYPE_ERROR))
  //   })
  //   .then(() => {
  //     dispatch(showLoader(false))
  //   })
}

export const clearAccounts = () => ({
  type: ACCOUNTS_CLEAR,
})

export const clearAccountTags = () => ({
  type: ACCOUNTS_TAGS_CLEAR,
})

export const setDefault = account => ({
  type: ACCOUNTS_SET_DEFAULT,
  account,
})

export const setEdit = edit => ({
  type: ACCOUNTS_SET_EDIT,
  edit,
})

export const clearEdit = () => ({
  type: ACCOUNTS_CLEAR_EDIT,
})

export const addAccount = account => ({
  type: ACCOUNTS_ADD_ONE,
  account
})

const addTag = (tag) => ({
  type: ACCOUNTS_ADD_TAG,
  tag,
})

const removeAccount = account => ({
  type: ACCOUNTS_REMOVE,
  account
})
