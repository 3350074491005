import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';


const DoughnutChart = (props) => {
    console.log("datos dona grafica ", props.data)

    let datos = props.data
   
    
    return (
        <Doughnut
            data={props.data}
            options={{
                maintainAspectRatio: true,
                responsive: true,
                pieceLabel: {
                    render: function(args){
                        return args.value+"%";
                    },
                    fontSize: 15,
                    fontColor: "#fff"
                },
                plugins: {
                    title: {
                        display: false
                    },
                    legend: {
                        display: true,
                        labels: {
                            usePointStyle: true,
                        },
                        position: "right"
                    },
                    labels:{
                        render: 'percentage',
                        fontColor: ['green', 'white', 'red'],
                        precision: 2
                    },
                    datalabels: {
                        display: true,
                        anchor: "center",
                        formatter: (dato) => dato + "%",
                        color: "#fff",
                        font: {
                          size: "14",
                          weight: "normal",
                        },
                    },
                }
            }}
        />
    )
}

export default DoughnutChart