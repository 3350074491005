import React from 'react'
// components
import Influencer from '../../components/testing/influencer'
import { useParams } from 'react-router-dom'
import * as CryptoJS from 'crypto-js'
const InfluencerPage = (props) => {
  const {ruta} = props
  const { userToken } = useParams();
  var decryptedData

 let appData 
  if (ruta){
    appData = ruta.replace('/myProfile/', '')
    var bytes = CryptoJS.AES.decrypt(appData, 'likeinc');
  if (bytes.sigBytes>0) {
    decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 
  }
  }
    
  
    console.log("reemplazo",appData)

  console.log("ruta from influencer", ruta)

 // console.log("usertokeeeeeeeeen",userToken)
 if (userToken){
  let keyT= 'U2FsdGVkX18I3l0ybf3BDfo34oFiV2eeynUesQoVQGJMsIU152NUPmM0twxY2y9zmbFPzsCxS8uy4RdIvKmYFxuIRXYvKy0nrMBO7PiAqiY='
  var bytes = CryptoJS.AES.decrypt(userToken, 'likeinc');
  if (bytes.sigBytes>0) {
    decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 
  }
 }


  return(
  
  <>
    <Influencer importedUser={decryptedData} />
  </>
)
}
export default InfluencerPage