import React, { useState, useEffect } from 'react'
import * as utils from 'data.js'
import * as APILI from 'helpers/apiLI';
import Swal from 'sweetalert2/dist/sweetalert2.js'

import { Auth } from 'aws-amplify';
import _ from 'lodash'
import { getLogoByName, getClassNameByStatus } from 'myUtil'
import DataTableUtils, {
    Share,
    ShowColumn,
    FilterColumn
} from '@easygosanet/react-datatable-utils'
import ReactDataTable from '@easygosanet/react-datatable'
import AddTask from 'components/addTask'

//images
import Logo_facebook from './assets/img/logo_facebook.svg'
import Logo_instagram from './assets/img/logo_instagram.png'
import Logo_tiktok from './assets/img/logo_tiktok.svg'
import logo_linkedin from './assets/img/logo_linkedin.svg'



const InfluencerTasks = (props) => {
    const {id}=props
    
    const[tables,setTables]= useState([]);
    const [showModal, setshowModal] = useState(false);
    let [taskTable, setTaskTable] = useState([])
    const [filters, setFilters] = useState({})
    const [refreshTable, setrefreshTable] = useState(false)
    console.log(filters);


    const MySwal = (data) => {
        console.log("data",data)
        let influencerName = ''
        let influencerLastName = ''
        console.log(influencerName)
        console.log(influencerLastName);
       
      const updateTaskStatus = async (taskID, taskStatus, data) => {
          console.log("taskID",taskID)
          console.log("taskStatus",taskStatus)
        const update = {
            id: taskID,
            status: taskStatus
        }
        const updateTable =() => {
            setrefreshTable(!refreshTable)
        }
         APILI.updateTaskStatusByID(update).then((status) => {
            updateTable()
            if(taskStatus === 'Payment'){
                console.log(data)
            const body = {
                id: taskID,
               currency: "USD",
               userId: data.userID,
               updatedAt: new Date().toISOString(),
               createdAt: new Date().toISOString(),
               amount: data.price,
               activityName: data.activityName,
               campaignName: data.campaignName,
               socialmedia: data.socialmedia,
               state: "Pendiente"
                   }
            
             APILI.createPayment(body).then((status) => {
                console.log("status",status)
            }).catch((err) => {
                console.log("err",err)
            })
                }
        }).catch((err) => {
            console.log("err",err)
        })
        }
      
        if(data.delivery.name ==="Payment"){
            console.log(data.delivery.name)
            Swal.fire({
                title: 'Ninguna actividad pendiente!', 
                icon: 'success',
                timer: 1500
            })
            return
        }
        let aprobeTask = ''
        let dennyTask = ''
        if(data.delivery.name ==="Rejected"){
            aprobeTask = 'Pendiente'
            dennyTask = 'Rejected'
        }
        if(data.delivery.name ==="Pendiente"){
            aprobeTask = 'Active'
            dennyTask = 'Rejected'
        }
        if(data.delivery.name ==="Active"){
            aprobeTask = 'Completed'
            dennyTask = 'Pendiente'
        }
        if(data.delivery.name ==="Completed"){
            aprobeTask = 'Payment'
            dennyTask = 'Active'
        }


            Swal.fire({
                title: data.campaignName,
                text: 'Usuario:, ' + data.userID + ' Seguidores: '+ data.scope +
                'Desea participar del reto:, ' + data.campaignName,
                icon: 'info',
                showCancelButton: true,
                showDenyButton: true,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: '#00CA72',
                denyButtonText: '#d33',
                denyButtonText:
              '<i class="fa fa-thumbs-down"></i>',
                confirmButtonText:
              '<i class="fa fa-thumbs-up"></i> Aprobar',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    updateTaskStatus(data.id, aprobeTask, data)      
                  Swal.fire({
                      title: 'Confirmado!', 
                        icon: 'success',
                          timer: 1500})
                } else if (result.isDenied) {
                    updateTaskStatus(data.id,dennyTask)
                    Swal.fire({
                        title: 'Rechazado!', 
                          icon: 'error',
                            timer: 1500})
                }
              })
        
    }
    
    
    
    
    const DeliveryCol = ({ text, className, onClick }) => (
        <div className={className}>
            <div className="delivery-text">
                {text}
            </div>
        </div>
    )
    
    const customStyles = {
        headCells: {
            style: {
                '&:nth-last-of-type(3)': {
                    backgroundColor: '#E3E4E6',
                    width: '40px'
                },
                '&:nth-last-of-type(5)': {
                    backgroundColor: '#E3E4E6',
                },
            },
        },
    }
    
    useEffect( async () => {
        const body = {
            userID: id
        }
                APILI.getTasksByUser(body).then((tasks) => {
                console.log(tasks.data.tasks)
               getDataView(tasks.data.tasks)
            }).catch((err) => {
                console.log(err)
            })
        
    }, [refreshTable])

 
    
    const goToEditPage = (id, name, createAt) => {
        let edit = {
            id,
            name,
            createAt,
        }
        props._setEdit(edit)

        window.$.fancybox.open({
            src: '#new-category-modal',
            //type: 'modal',
            opts: {
                modal: true,
                afterClose: () => props._clearEdit(),
            },
        })
    }

    const DetailCol = ({ text, data}) => (
        <div className="firstColl w-100">
            <div className="icon-container">
                <i onClick={() =>MySwal(data)} class="fas fa-eye"></i>
                <i className="fas fa-edit"></i>
                <i class="fas fa-trash"></i>
                <i class="fab fa-telegram-plane"></i>
                <i class="fas fa-ellipsis-v"></i>
            </div>
            <p className="name-fc">
                {text}
            </p>
        </div>
    )

    const [columns, setColumns] = useState([
        {
            name: 'Nombre del reto o tarea',
            selector: 'name',
            sortable: true,
            minWidth: '200px',
            style: {
                color: '#02025b',
            },
            cell: (row) => (
                <DetailCol
                    text={row.name}
                    data={row.id, row.name, row.createAt}
                />
            ),
        },
        {
            name: 'Estado',
            selector: 'delivery',
            sortable: true,
            center: true,
            style: {
                backgroundColor: '#E3E4E6',
                fontWeight: 'bold',
            },
            type: 'text',
            cell: (row) => (
                <DeliveryCol
                    text={row.status}
                    className={getClassNameByStatus(row.status)}
                    
                />
            )
        },
        {
            name: 'Campaña',
            selector: 'campaignName',
            sortable: true,
            center: true,
            style: {
                fontWeight: 'bold',
            }
        },
        {
            name: 'Fecha de vencimiento',
            selector: 'scope',
            sortable: true,
            center: true,
            style: {
                backgroundColor: '#E3E4E6',
                fontWeight: 'bold',
            },
        },
        {
            name: 'Red Social',
            selector: 'source',
            sortable: true,
            center: true,
            style: {
                fontWeight: 'bold',
            },
            cell: (row) => <img className="source-img" src={row.source} alt="" />
        },
        {
            name: 'Alcance',
            selector: 'inscription',
            sortable: true,
            center: true,
            style: {
                fontWeight: 'bold',
            },
        },
    ])

    const handleFilterPerColumn = (column, type, value) => {
        setFilters((prev) => ({ ...prev, [column]: { type, value } }))
    }

    const handleShowColumnChange = (column, show) => {
        let index = columns.findIndex((col) => col.selector === column)
        let aux = columns.map((col) => col)
        aux[index].omit = show
        setColumns(aux)
    }

    const handleColumnSortChange = (positions) => {

        let aux = columns.map((col) => col)

        positions.forEach((columnId, position) => {
            let index = columns.findIndex((col) => col.selector === columnId)
            aux[index].position = position
        })
        aux = _.orderBy(aux, ['position'])

        setColumns(aux)
    }

/******************************************************** */
const getDataView = (data) => {
  
    console.log("getdataview",data)
    setTables({
        titles: [
            {
                name: 'Nombre del reto o tarea',
                selector: 'name',
                sortable: true,
                minWidth: '200px',
                style: {
                    color: '#02025b',
                },
                cell: (row) => (
                    <DetailCol
                        text={row.name}
                        data={row}
                    />
                ),
            },
            {
                name: 'Estado',
                selector: 'delivery',
                sortable: true,
                center: true,
                style: {
                    backgroundColor: '#E3E4E6',
                    fontWeight: 'bold',
                },
                type: 'text',
                cell: (row) => (
                    <DeliveryCol
                        text={row.delivery.name}
                        className={row.delivery.className}
                    />
                )
            },
            {
                name: 'Campaña',
                selector: 'campaignName',
                sortable: true,
                center: true,
                style: {
                    fontWeight: 'bold',
                }
            },
            {
                name: 'Fecha de vencimiento',
                selector: 'scope',
                sortable: true,
                center: true,
                style: {
                    backgroundColor: '#E3E4E6',
                    fontWeight: 'bold',
                },
            },
            {
                name: 'Red Social',
                selector: 'source',
                sortable: true,
                center: true,
                style: {
                    fontWeight: 'bold',
                },
                cell: (row) => <img className="source-img" src={row.source} alt="" />
            },
            {
                name: 'Alcance',
                selector: 'inscription',
                sortable: true,
                center: true,
                style: {
                    fontWeight: 'bold',
                },
            },
        ],
        data: data.map(tasks => ({
          name: tasks.activity,
          delivery: {
            name: tasks.status,
            className: getClassNameByStatus(tasks.status)
        },
        campaignName: tasks.campaignName,
        scope: new Date(tasks.endDate).toString(),
        source: getLogoByName(tasks.socialmedia),
        inscription: tasks.scope,
        id: tasks.id,
        userID: tasks.userID,
        scope: tasks.scope,
        price: tasks.price,
        socialmedia: tasks.socialmedia,
        activityName: tasks.name

         
        }))
    }
    )
  }

/******************************************************** */
  function getTasks(listCampaigns, category) {
    let myCampaigns = []
    listCampaigns.forEach(campaign => {
      campaign.mediaCategories.forEach(categoryE => {
        if (categoryE.name === category) {
          myCampaigns.push(campaign)
        }
      });
    });
    return myCampaigns
  }
/******************************************************** */
    return (
       
           
        <div className="row-section">
          <ReactDataTable
            columns={tables.titles}
            customStyles={customStyles}
            data={tables.data}
            persistTableHead
            noHeader
          />
        </div>
        

      
    )
}


export default InfluencerTasks
