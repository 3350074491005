import * as Actions from '../actions/files.actions'

const initialState = []
    //files: [],
 // }

  export default (state = initialState, action) => {
    switch (action.type) {
      case Actions.FILE_CLEAR:
        return {
            ...state,
            files:initialState,
        }

      case Actions.FILE_ADD_FILE:
        return {
          ...state,
          files: action.file,
        }
      case Actions.FILE_REMOVE_FILE:
        return {
          ...state,
          files: state.files.filter((file) => file.name !== action.file),
        }
      
      default:
        return state
    }
  }