import * as APILA from 'helpers/apiLA'
import * as Logger from 'utils/logger'

import {
  saveSocial,
  saveMedium,
  saveProduct,
  saveIdmedium,
} from './campana.actions'
import { showLoader } from './loader.actions'

const TAG = 'Campaign:Actions'

export const LIST_MEDIUM = 'MEDIUMBANNER:ADD'
export const LIST2_MEDIUM = 'MEDIUMBANNER2:ADD'
export const CAMPAIGNS_CLEAR = 'MEDIUMBANNER:CLEAR'
export const CAMPAIGNS2_CLEAR = 'MEDIUMBANNER2:CLEAR'

export const saveMediumm = (medium) => (dispatch) => {
  dispatch(saveMedium(medium))
}

export const saveIdmediumm = (id_medium) => (dispatch) => {
  dispatch(saveIdmedium(id_medium))
}

export const saveproduct = (product) => (dispatch) => {
  dispatch(saveProduct(product))
}

export const saveSocials = (red) => (dispatch) => {
  dispatch(saveSocial(red))
}

export const getMediumBanner = (body) => async (dispatch) => {
  Logger.log('medioCamp', 'getMediumBanner', body)

  if (body === '') {
    dispatch({ type: CAMPAIGNS_CLEAR })
    dispatch({ type: CAMPAIGNS2_CLEAR })
  } else {
    dispatch({ type: CAMPAIGNS2_CLEAR })
  }

  dispatch(showLoader(true))

  try {
    const response = await APILA.getMediumBanner(body)

    Logger.log('algo entro en service api *:*')
    Logger.log(response)

    if (response !== 777) {
      response.data.data.forEach((medium) => {
        if (body === '') {
          dispatch(addmedium(medium))
        } else {
          dispatch(addmediumtype(medium))
        }
      })
    }
  } catch (err) {
    Logger.log(TAG, 'error serviceapi *:*', err)
  }

  dispatch(showLoader(false))
}

const addmedium = (mediumbanners) => ({
  type: LIST_MEDIUM,
  mediumbanners,
})

const addmediumtype = (mediumbannerstype) => ({
  type: LIST2_MEDIUM,
  mediumbannerstype,
})