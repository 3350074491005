import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'
import awsconfig from 'aws-exports'
import Amplify from 'aws-amplify'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { persistor, store } from 'redux/configureStore'
import initFacebookSDK from "./initFacebookSDK";
import Influencer from 'pages/influencer'


Amplify.configure(awsconfig)

const isLocalhost = Boolean(
  window.location.hostname === "localhost" || window.location.hostname === "[::1]" || window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
)

/*

const [
  localRedirectSignIn,
  productionRedirectSignInLetsAdvertise,
] = awsconfig.oauth.redirectSignIn.split(',')

const [
  localRedirectSignOut,
  productionRedirectSignOutLetsAdvertise,
] = awsconfig.oauth.redirectSignOut.split(',')

*/
const renderApp = () => {
  //console.log("authState: ",authState)

  let URLactual = window.location.pathname;
 /*  console.log(("pathname", URLactual)); */
 let pathSearch = URLactual.includes('/myProfile/');
 /* console.log("pathsearch",pathSearch) */
 
  ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
        {pathSearch ? <Influencer ruta={URLactual}/>: 
          <App awsConfig={awsconfig} />
        }
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
    document.getElementById("root")
  );
};

initFacebookSDK().then(() => {
  renderApp();
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
