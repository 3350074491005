import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { showLoader } from 'redux/actions/loader.actions';
import * as utils from 'data.js';
import * as APILI from 'helpers/apiLI';
import _ from 'lodash'
import { Auth } from 'aws-amplify';

//Libraries
import ReactDataTable from '@easygosanet/react-datatable'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import DataTableUtils, {
    Share,
    ShowColumn,
    FilterColumn
} from '@easygosanet/react-datatable-utils'
import NewAdvertiser from '../newAdvertiser'
import AdvertiserEdit from '../advertiserEdit'
import { updateCampaignState } from '../../helpers/apiLI';
import { CSVLink, CSVDownload } from "react-csv";
import  "./style.css";
// or<CSVDownload data={csvData} target="_blank" />;
import * as Modal from 'redux/actions/modal.actions'


const DeliveryCol = ({ text, className, onClick }) => (
    <div className={"delivery" + (text)}>
        {text}
    </div>
)


const Advertiser = (props) => {
    const currentUser = JSON.parse(localStorage.getItem('CurrentUser')) ?? [];
    const [showAdvertiser, setShowAdvertiser] = useState(false);
    const [showAdvertiserEdit, setShowAdvertiserEdit] = useState(false);
    const [advertiserId, setAdvertiserId] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [dataCsv, setDataCsv] = useState([]);
    //add Diego
    const [filterText, setFilterText] = useState('')

  /*   const csvData = [
        ["firstname", "lastname", "email"],
        ["Ahmed", "Tomi", "ah@smthing.co.com"],
        ["Raed", "Labes", "rl@smthing.co.com"],
        ["Yezzi", "Min l3b", "ymin@cocococo.com"]
    ]; */
    //let [advertisers, setAdvertisers] = useState([]);
    let [table, setTable] = useState([]);
    const [filters, setFilters] = useState({})
    console.log(filters);
    useEffect(() => {
        props._showLoader(true)
        Auth.currentAuthenticatedUser()
            .then((userData) => {
                APILI.getAdvertiser().then(res => {
                    console.log(res.data.advertisers)
                    getInfoView(res.data.advertisers)
                    props._showLoader(false)
                })
                    .catch(console.error)
            })
            .catch(() => 
            props._showLoader(false),
            console.log('Not signed in'))
    }, [refresh]);


    const handleFilterPerColumn = (column, type, value) => {
        setFilters((prev) => ({ ...prev, [column]: { type, value } }))
    }

    const handleShowColumnChange = (column, show) => {
        console.log('column', column)
        console.log('show', show)
        
        let index = columns.findIndex((col) => col.selector === column)
    
        let aux = columns.map((col) => col)
        console.log('Colums',aux)
        console.log('ColumsIndex',aux[index])
        aux[index].omit = show
        setColumns(aux)
        }
    const handleColumnSortChange = (positions) => {

        let aux = columns.map((col) => col)

        positions.forEach((columnId, position) => {
            let index = columns.findIndex((col) => col.selector === columnId)
            aux[index].position = position
        })
        aux = _.orderBy(aux, ['position'])

        setColumns(aux)
    }

    function validateClass(status){
        if(status==='Active')
        return "delivery-running" 
        else if(status==='Inactive')
            return 'delivery-stop'
        else return "delivery-tail"

    }

    function getInfoView(advertisers) {
        setTable(
            advertisers.map(advertiser => ({
                name: advertiser.name,
                delivery: {
                    name: advertiser.status,
                    className: validateClass(advertiser.status)
                },
                budget: advertiser.nameContact,
                scope: advertiser.phoneContact,
                id: advertiser.id,
            }))
        )
        setDataCsv(
            advertisers.map(advertiser => ({
                name: advertiser.name,
                delivery: advertiser.status,
                budget: advertiser.nameContact,
                phone: advertiser.phoneContact,
                id: advertiser.id,
            }))
        )

    }

    const removeAdvertiser = (camp) => {
        
        console.log("remove state",camp)
        props._showAlertModal('Eliminar Anunciante?', `Esta seguro que desea eliminar el anunciante`, Modal.MODAL_TYPE_CONFIRMATION, () => {
            APILI.deleteAdvertiser({id: camp}).then(res => {
            console.log('Eliminado')
            props._showLoader(true);
              APILI.getAdvertiser()
                .then((res) => {/* 
                  getInfoView(res.data.advertisers); */
                  setRefresh(res.data.advertisers)
                  props._showLoader(false);
                })
                .catch(console.error);
          }).catch(err => {
          console.log('err', err) 
          })
          
        })
      }
    

    const showDetails = (camp) => {
        setAdvertiserId(camp)
        setShowAdvertiserEdit(true)
    }

    const saveLog = async (body) => {
        try {
          APILI.createNewLog(body).then(res => {
            console.log('Log Creado')
          }).catch(err => {
          console.log('err', err) 
          })
          
      } catch (err) {
          console.log("No se pudo guardar el log", err)
      }
      }

      const saveErrorLog = async (body) => {
        try {
          APILI.createNewErrorLog(body).then(res => {
            console.log('Error log Creado')
          }).catch(err => {
          console.log('err', err) 
          })
          
      } catch (err) {
          console.log("No se pudo guardar el log de error", err)
      }
      }

    const updateAdvertiserState = (id, status) => {
        APILI.updateAdvertiserStatus({"id":id, "status":status}).then(res => {
            console.log('respuesta', res)
            console.log('usuario modificador', currentUser)
            const bodyLog = {
                Tipo: "Update",
                UsuarioId: currentUser.username,
                UsuarioName: currentUser.attributes.name,
                Email: currentUser.attributes.email,
                Accion: "Modificar estado anunciante",
                Data: {"id":id, "status":status},
                Historico: {"id":id, "status":'Pendiente'},
                CodeHttp: res.status
            }
            saveLog(bodyLog)

            console.log("actualizado")
        }).catch(error => {

            const bodyLog = {
                Tipo: "Update error",
                UsuarioId: currentUser.username,
                UsuarioName: currentUser.attributes.name,
                Email: currentUser.attributes.email,
                Accion: "Modificar estado anunciante",
                Data: {"id":id, "status":status},
                Historico: {"id":id, "status":'Pendiente'},
                CodeHttp: error
            }
            saveErrorLog(bodyLog)

            console.log(error)
        })
    }


    const editState = (camp) => {
        console.log("edit state",camp)
        Swal.fire({
            title: 'Modificar Anunciante',
            text: 'Esta seguro que sea modificar el estado del anunciante?',
            icon: 'info',
            showCancelButton: true,
            showDenyButton: true,
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: '#00CA72',
            denyButtonText: '#d33',
            denyButtonText:
          '<i class="fa fa-thumbs-down"> </i>',
            confirmButtonText:
          '<i class="fa fa-thumbs-up"></i> Aprobar',
        }).then((result) => {
            ///* Read more about isConfirmed, isDenied below 
            if (result.isConfirmed) {
              console.log("aprobar");
              updateAdvertiserState(camp, "Active");
              props._showLoader(true);
              APILI.getAdvertiser()
                .then((res) => {/* 
                  getInfoView(res.data.advertisers); */
                  setRefresh(res.data.advertisers)
                  props._showLoader(false);
                })
                .catch(console.error);
              // updateTaskStatus(data.id, aprobeTask, data)
              Swal.fire({
                title: "Confirmado!",
                icon: "success",
              });
             
                  
            } else if (result.isDenied) {
              updateAdvertiserState(camp, "Rejected");
              //updateTaskStatus(data.id,dennyTask)
              Swal.fire({
                title: "Rechazado!",
                icon: "error",
                timer: 1500,
              });
            }
          })
    }

    const DetailCol = ({ text, Camp }) => (
        <div className="firstColl w-100">
            <div className="icon-container">
                <i onClick={()=>showDetails(Camp)} className="fas fa-eye"></i>
                <i onClick={()=>editState(Camp)} className="fas fa-edit"></i>
                <i onClick={()=>removeAdvertiser(Camp)} class="fas fa-trash"></i>
                <i class="fab fa-telegram-plane"></i>
                <i class="fas fa-ellipsis-v"></i>
            </div>
            <p className="name-fc">
                {text}
            </p>
        </div>
    )


    const [columns,setColumns] = useState([
        {
            id: 'name',
            name: 'Anunciante',
            selector: 'name',
            sortable: true,
            omit: false,
            minWidth: '200px',
            style: {
                color: '#02025b',
            },
            cell: (row) => (
                <DetailCol
                    text={row.name}
                    Camp={row.id}
                />
            ),
        },
        {
            id: 'delivery',
            name: 'Estado',
            selector: 'delivery',
            sortable: true,
            omit: false,
            center: true,
            style: {
                backgroundColor: '#E3E4E6',
                fontWeight: 'bold',
            },
            type: 'text',
            cell: (row) => (
                <DeliveryCol
                    text={row.delivery.name}
                    className={row.delivery.className}
                />
            )
        },
        {
            id: 'budget',
            name: 'Nombre',
            selector: 'budget',
            sortable: true,
            omit: false,
            center: true,
            style: {
                fontWeight: 'bold',
            },
        },
        {
            id: 'scope',
            name: 'Telefono',
            selector: 'scope',
            sortable: true,
            omit: false,
            center: true,
            style: {
                backgroundColor: '#E3E4E6',
                fontWeight: 'bold',
            },
        }
    ])
    //addDiego
    const handleFilterTextChange = (value) => {
        console.log(value);
        setFilterText(value)
      }


    //  this.campaigns.filter((campaign) => camp
    let filteredData = table.filter( (item) =>  
        item.name.toLowerCase().includes(filterText.toLowerCase()) || 
        item.scope.toLowerCase().includes(filterText.toLowerCase()) || 
        item.budget.toLowerCase().includes(filterText.toLowerCase()) ||
        item.delivery.name.toLowerCase().includes(filterText.toLowerCase())
    )

    const customStyles = {
        headCells: {
            style: {
                '&:nth-of-type(2n)': {
                    backgroundColor: '#E3E4E6',
                    width: '40px',
                    borderTopLeftRadius: '5px',
                    borderTopRightRadius: '5px',
                },
            },
        },
    }    
        
    const exportDataToExcel = () =>{
        console.log('tablesDataTopExport', table)
        let headers = []
        let dataExcel=[]
        let selectors = []
        let excelData = [];
        var filteredColumns=columns.filter(function(col){
          return col.omit===false
        })
        
        filteredColumns.forEach((element)=>{
          headers.push(element.name)
          selectors.push(element.selector)
        })  
        
        dataExcel.push(headers)
      
             if(filteredData.length>0)
             {
                filteredData?.forEach((element) => {
                 excelData = [];
                   for(let k=0; k<selectors.length; k++)
                   {
                       excelData.push(
                         selectors[k]=="delivery" ? element.delivery.name:
                         selectors[k]=="source" ? element.socialNetwork:
                         element[selectors[k]],
                       );
                   }
                
                   dataExcel.push(excelData);
                 });
                 
             }
         console.log('dataParaExportar', dataExcel)
         return dataExcel;
      }
    return (
        <div className='form-dashboard'>
            <div className='Bar-content'>
                <div className='content-menu list'>
                    <select id='viewSelect' className='select-dashboard fa'>
                        <option className='fa' value='1'>
                            {' '}
                            &#xf0ce; Vista Anunciantes
                        </option>
                        {/* <option className='fa' value='0'>
                            {' '}
                            &#xf0db; Vista de Kanban{' '}
                        </option>
                        <option className='fa' value='2'>
                            {' '}
                            &#xf073; Vista de calendario
                        </option> */}
                    </select>
                    {/* add Diego */}
                   < DataTableUtils className='container-btn d-flex' 
                   onChangeFilterText={handleFilterTextChange}>
                        <Share
                        contacts={utils.myContacts}
                        data={exportDataToExcel()}
                        isArray={true}
                        exportXLSX={true}
                        exportTXT={true}
                        exportDropbox={true}
                        exportDrive={true}
                        exportCSV={true}
                        printable={true}
                        exportPNG={true}
                        exportLink={true}
                        />
                        <ShowColumn
                            columns={columns}
                            showColumn={handleShowColumnChange}
                            onSortChange={handleColumnSortChange}
                        />
                        {/* <FilterColumn
                            columns={columns}
                            onFilterColumn={handleFilterPerColumn}
                        /> */}
                    </DataTableUtils>
                    <div
                        className='btnOrange-dash'>
                        <i onClick={e=> {setShowAdvertiser(true)}} className='fas fa-plus'></i>
                    </div>
                </div>
            </div>
            {/* <CSVLink data={table}>Descargar</CSVLink>; */}
            <div className="row-section">
                <ReactDataTable
                    columns={columns}
                    data={filteredData}
                    persistTableHead
                    noDataComponent={"No hay datos para mostrar"}
                    pagination
                    noHeader
                    customStyles={customStyles}
                    paginationComponentOptions={{
                        rowsPerPageText: 'Elementos por página',
                        rangeSeparatorText: 'de',
                    }}
                />
            </div>
            {showAdvertiser ? <NewAdvertiser title="Crear nuevo anunciante" showAdvertiser={setShowAdvertiser} userRol={"admin"}
            refresh={refresh} setRefresh={(dat)=>setRefresh(dat)}/> : <div/>}
            {showAdvertiserEdit ? <AdvertiserEdit title="Informacion del anunciante" showAdvertiserEdit={setShowAdvertiserEdit} advertiserId={advertiserId}/> : <div/>}
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    _showLoader: show => dispatch(showLoader(show)),
    _showAlertModal: (title, message, type, callback) =>
      dispatch(Modal.showAlertModal(title, message, type, callback)),
})

const mapStateToProps = (state) => ({
    isLoading: state.app.isLoading,
    myUser: state.users.myUser
})

export default connect(mapStateToProps, mapDispatchToProps)(Advertiser);