import React from 'react'
// components
import Logs from 'components/logs'

const LogPage= (props) => { 
  const {userRol} = props;
  return (
    <>
    {userRol==='admin'?
    <Logs />:
    <h1>No tienes privilegios</h1>
    }
    </>
    )  
  }
  

export default LogPage