import { APP_CLEAR_CURRENT_USER } from '../actions/app.actions'
import * as Actions from '../actions/report.actions'

const initialState = {
  edit: {},
  list: [],
  filter_text: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.REPORT_SET_EDIT:
      return {
        ...state,
        edit: action.edit,
      }
    case Actions.REPORT_CLEAR_EDIT:
      return {
        ...state,
        edit: {},
      }
    case Actions.REPORT_CLEAR:
      return {
        ...state,
        list: [],
      }
    case Actions.REPORT_ADD_ONE:
      return {
        ...state,
        list: [
          ...state.list.filter(
            (report) => report.id !== action.report.id
          ),
          action.report,
        ],
      }
    case Actions.REPORT_REMOVE:
      return {
        ...state,
        list: [
          ...state.list.filter((report) => report.id !== action.report),
        ],
      }
    case Actions.REPORT_SET_FILTER_TEXT:
      return {
        ...state,
        filter_text: action.text,
      }
    case APP_CLEAR_CURRENT_USER:
      return initialState
    default:
      return state
  }
}
