import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

const AutoComplete = ({data, open, loading, setOpen, saveTag, placeholder}) => {

    const [textInput, setTextInput] = useState(null)

    return (
        <Autocomplete
            options={data}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            loading={loading}
            getOptionLabel={(option) => option.name ? option.name : ""}
            value={textInput}
            onChange={ (e, newValue) => {
                console.log(newValue)
                saveTag(newValue)
                setTextInput([])
            }}
            getOptionSelected={(option, value) => option.value === value.value}
            renderInput={(params) => <TextField {...params} placeholder={placeholder} margin="normal" 
            InputProps={{
                ...params.InputProps,
                endAdornment: (
                    <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                    </React.Fragment>
                ),
            }}
            />}
        />
    )
}

export default AutoComplete