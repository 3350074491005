import { v4 } from 'uuid'
import { Auth } from 'aws-amplify'

import * as API from 'helpers/api'
import * as APILA from 'helpers/apiLA'
import * as Logger from 'utils/logger'
import * as Modal from './modal.actions'
import { showLoader } from './loader.actions'
import { getBudgets } from './budgets.actions'
import { upsertCategory } from './categories.actions'

export const CAMPAIGNS_UPD = 'CAMPAIGNS:UPD'
export const CAMPAIGNS_CLEAR = 'CAMPAIGNS:CLEAR'
export const CAMPAIGNS_BUDGET = 'CAMPAIGNS:BUDG'
export const CAMPAIGNS_ADD_ONE = 'CAMPAIGNS:ADD:ONE'
export const CAMPAIGNS_UPD_CLEAR = 'CAMPAIGNS:UPDCLEAR'
export const CAMPAIGNS_BUDGET_CLEAR = 'CAMPA IGNS:BUDGCLEAR'
export const CAMPAIGNS_UPDATE_STATUS = 'CAMPAIGNS:UPDATE:STATUS'

export const CAMPAIGNS_CHANGE_TOTAL = 'CAMPAIGNS:TOTAL:CHANGE'
export const CAMPAIGNS_CHANGE_LOADING = 'CAMPAIGNS:LOADING:CHANGE'
export const CAMPAIGNS_CHANGE_FILTER_TEXT = 'CAMPAIGNS:FILTER:TEXT:CHANGE'
export const CAMPAIGNS_CHANGE_FILTER_SORT = 'CAMPAIGNS:FILTER:SORT:CHANGE'
export const CAMPAIGNS_CHANGE_FILTER_PAGE = 'CAMPAIGNS:FILTER:PAGE:CHANGE'
export const CAMPAIGNS_CHANGE_FILTER_PAGINATION = 'CAMPAIGNS:FILTER:PAGINATION:CHANGE'
export const CAMPAIGNS_CHANGE_PAGINATION_PER_PAGE = 'CAMPAIGNS:PAGINATION:CHANGE'

const TAG = 'Campaign:Actions'

export const msgsPersonalizado = (text, type) => dispatch => {
  Logger.log(TAG, 'recibio', type)
  
  if (type == 'error') {
    dispatch(Modal.showAlertModal('Error',text, Modal.MODAL_TYPE_ERROR, () => {}))
  } else if (type == 'success') {
    dispatch(Modal.showAlertModal('Exito', text, Modal.MODAL_TYPE_SUCCESS, () => {}))
  } else {
    dispatch(Modal.showAlertModal('Información', text, Modal.MODAL_TYPE_INFO, () => {}))
  }
}

export const msgsuccess = (campaignupd) => dispatch => {
  dispatch(Modal.showAlertModal('Solicitud Enviada', 'Su campaña fue enviado exitosamente.', Modal.MODAL_TYPE_SUCCESS, () => {}))
}

export const funcampaignupd = (campaignupd) => ({
  type: CAMPAIGNS_UPD,
  campaignupd
})

export const updateCampaingStatus = (campaingId, status, prevStatus) => async dispatch => {
  Logger.log(TAG, 'updateCampaignStatus')
  
  dispatch(showLoader(true))

  const body = {
    id: campaingId,
    status,
  }

  try {
    const response = await APILA.updateCampaignStatus(body)

    Logger.log(TAG, 'updateCampaignStatus', response)
    dispatch(
      Modal.showAlertModal(
        'Solicitud Enviada',
        'Su campaña fue enviado exitosamente.',
        Modal.MODAL_TYPE_SUCCESS,
        () => {}
      )
    )
    dispatch(updateStatus(campaingId, status))
  } catch (err) {
    Logger.log(TAG, 'error updateCampaignStatus', err)
    dispatch(
      Modal.showAlertModal(
        'Error!',
        'Ocurrió un error al actualizar la campaña',
        Modal.MODAL_TYPE_ERROR
      )
    )
    dispatch(updateStatus(campaingId, prevStatus))
  }

  dispatch(showLoader(false))
}

export const updateCampaign = (
  id,
  name,
  medio,
  fechaIni,
  fechafin,
  objetivo,
  budget,
  category,
  publico,
  idMixDetaill,
  idMixChange,
  idMix,
  idCampaign,
  id_medium,
  medioo,
  id_ad_product,
  banner,
  estado,
  idBudget,
  account,
  creativity,
  observation,
  copy,
  id_sale
) => async (dispatch) => {
  dispatch(showLoader(true))

  const categories = await parseCategories(category, account, dispatch)
  Logger.log(TAG, 'categories', categories)

  const body = {
    id,
    name,
    medio,
    status: estado,
    fechaIni,
    fechafin,
    objetivo,
    budget,
    category: categories,
    publico,
    idMixDetaill,
    idMixChange,
    idMix,
    idCampaign,
    id_medium,
    medioo,
    id_ad_product,
    banner,
    idBudget,
    account: account.id,
    creativity,
    observation,
    copy,
    id_sale
  }

  try {
    Logger.log(TAG, 'updateCampaign','Request', body)

    const response = await APILA.updateCampaign(body)

    Logger.log(TAG, 'updateCampaign', response)
    dispatch(
      Modal.showAlertModal(
        'Solicitud Enviada',
        'Su campaña fue enviado exitosamente.',
        Modal.MODAL_TYPE_SUCCESS,
        () => {}
      )
    )

    const data = {
      idMixDetaill:idMixDetaill,
      name:name,
      fechaIni:fechaIni,
      fechafin:fechafin,
      budget:budget,
      id_ad_product:id_ad_product,
      id_publico:null,
      id_ad_status: estado,
      comments: observation+" ----- "+copy
    }
    sendUpdateCamping(data).then()
  } catch (err) {
    Logger.log(TAG, 'error createCampaing', err)
    dispatch(
      Modal.showAlertModal(
        'Error!',
        'Ocurrió un error al actualizar la campaña',
        Modal.MODAL_TYPE_ERROR
      )
    )
  }

  dispatch(showLoader(false))
}

const sendUpdateCamping = async (campaign, dispatch) => {
  var fi = campaign.fechaIni.split('.')
  var nfi = fi[2] + '-' + fi[1] + '-' + fi[0]
  var ff = campaign.fechafin.split('.')
  var nff = ff[2] + '-' + ff[1] + '-' + ff[0]

  //if (campaign.idMixDetaill && campaign.idMixChange && campaign.idMix && campaign.idCampaign) {
    const data = {
      id: campaign.idMixDetaill,
      name: campaign.name,
      fecha_inicio: nfi,
      fecha_fin: nff,
      budget: campaign.budget,
      id_ad_product: campaign.id_ad_product,
      id_publico:campaign.id_publico,
      id_ad_status: campaign.id_ad_status,
      comments:campaign.comments
    }

    try {
      const response = await APILA.editpautalets(data)

      if (response.data !== 777) {
        console.log('Se edito correctamente')
      }
    } catch (err) {
      console.log('actualizo el lets advertising *.*.*')
      console.log(err)
    }
  /*} else {
    sendCampToEAdvertise(campaign, dispatch)
  }*/
}

const parseCategories = async (array, account, dispatch) => {
  const result = []

  if (array) {
    const categoriesCreated = array.filter((category) => !category.__isNew__)
    const categoriesToCreate = array.filter((category) => category.__isNew__)
    Logger.log(TAG, 'categoriesCreated', categoriesCreated)
    Logger.log(TAG, 'categoriesToCreate', categoriesToCreate)

    categoriesCreated.forEach((category) => {
      result.push(category.value)
    })

    for (const category of categoriesToCreate) {
      const categoryId = v4()

      await dispatch(upsertCategory(categoryId, category.label, account.id, false))

      result.push(categoryId)
    }
  }

  return result
}

export const createCampaign = (
  id,
  name,
  medio,
  fechaIni,
  fechafin,
  objetivo,
  budget,
  category,
  publico,
  id_medium,
  medioo,
  id_ad_product,
  banner,
  estado,
  idBudget,
  account,
  creativity,
  id_sale,
  observation,
  copy,
  urls,
  availableAmount
) => async (dispatch) => {
  dispatch(showLoader(true))

  const categories = await parseCategories(category, account, dispatch)
  Logger.log(TAG, 'categories', categories)

  const body = {
    id,
    name,
    medio,
    status: estado,
    fechaIni,
    fechafin,
    objetivo,
    budget,
    category: categories,
    publico,
    id_medium,
    medioo,
    id_ad_product,
    banner,
    idBudget,
    account: account.id,
    creativity,
    id_sale,
    observation,
    copy,
  }

  Logger.log(TAG, 'body', body)

  try {
    const response = await APILA.createCampaign(body)
    
    Logger.log(TAG, 'createCampaing', response)
    Logger.log(TAG, 'availableAmount', availableAmount)
    API.updateBudget({ id: idBudget, available: availableAmount - budget })
    
    if (estado === 1) {
      let obbser = observation+" ----- "+copy
      if(urls !== ""){
        obbser = obbser+" ----- "+urls
      }
      const body_eadvertise = {
        id,
        name,
        medio,
        status: estado,
        fechaIni,
        fechafin,
        objetivo,
        budget,
        category: categories,
        publico,
        id_medium,
        medioo,
        id_ad_product,
        banner,
        idBudget,
        account: account.id,
        creativity,
        id_sale,
        observation:obbser,
        copy,
      }
      sendCampToEAdvertise(body_eadvertise, account, dispatch)
    }

    dispatch(getBudgets(account.id))
  } catch (err) {
    Logger.log(TAG, 'error createCampaing', err)
    dispatch(
      Modal.showAlertModal(
        'Error!',
        'Ocurrió un error al crear la campaña',
        Modal.MODAL_TYPE_ERROR
      )
    )
  }

  dispatch(showLoader(false))
}

const getUserId = async (email, firstName, lastName) => {
  const data = {
    phone: '0',
    email: email,
    username: email,
    rol: 'ROLE_ADMIN',
    lastName: lastName,
    password: 'Passw0rd',
    firstName: firstName,
  }

  try {
    const response = await APILA.findUserByEmail(data)
    if (response.data.existe) {
      console.log(response.data.idUser)
      return response.data.idUser
    } else {
      const res = await APILA.newUser(data)
      return res.data.user
    }
  } catch (err) {
    // this.showError()
    console.log(err)
    return err
  }
}

const sendCampToEAdvertise = async (campaign, account, dispatch) => {
  const fi = campaign.fechaIni.split(".")
  const nfi = fi[2] + "-" + fi[1] + "-" + fi[0]
  const ff = campaign.fechafin.split(".")
  const nff = ff[2] + "-" + ff[1] + "-" + ff[0]
  
  let mixDetail, mix, camp, mixChange

  try {
    const userData = await Auth.currentAuthenticatedUser()

    const email = userData.attributes.email
    console.log(email)

    const userId = await getUserId(email, 'Test', 'Test1')

    const data = {
      name: campaign.name,
      clientAppSocial: {
        id: account.eAdvertising || 0,
        email: account.email || '',
      },
      accountInfo: {
        name: account.name,
        email: account.email,
        phone: account.phone,
        address: account.address,
      },
      appProduct: campaign.id_ad_product,
      adminAppSocialId: userId,
      monthYear: nfi,
      iniDate: nfi,
      endDate: nff,
      buget: campaign.budget,
      idSale: campaign.id_sale,
      observation: campaign.observation,
      segmentation: campaign.publico.join(','),
      payment: {
        name: 'saul',
        nit: '12345678',
        address: '2 calle residencial el prado 2',
        email: 'herviasg@gmail.com',
        phone: '45963958',
      },
    }

    const createPautaResponse = await APILA.createpauta(data)

    mix = createPautaResponse.data.mix
    camp = createPautaResponse.data.campaing
    mixDetail = createPautaResponse.data.mixDetail
    mixChange = createPautaResponse.data.mixChange

    if (!account.eAdvertising) {
      updateAccountEAdvertising(account.id, createPautaResponse.data.idClient)
    }

    campaign.idMix = mix
    campaign.idCampaign = camp
    campaign.idMixChange = mixChange
    campaign.idMixDetaill = mixDetail
    let cadena = campaign.observation
    cadena = cadena.split("-----")
    campaign.observation = cadena[0]


    await APILA.updateCampaign(campaign)

    dispatch(
      Modal.showAlertModal(
        'Solicitud Enviada',
        'Su campaña fue enviada exitosamente.',
        Modal.MODAL_TYPE_SUCCESS,
        () => {}
      )
    )
    dispatch(addCampaign(campaign))
  } catch (err) {
    console.log(err)
    Logger.log(TAG, 'error createCampaing', err)
    dispatch(
      Modal.showAlertModal(
        'Error!',
        'Ocurrió un error al crear la campaña',
        Modal.MODAL_TYPE_ERROR
      )
    )
  }

  dispatch(showLoader(false))
}

export const aproveCampaign = (mixChange, account, filter) => async (dispatch) => {
  dispatch(showLoader(true))

  try {
    const userData = await Auth.currentAuthenticatedUser()

    const email = userData.attributes.email
    console.log(email)

    const userId = await getUserId(email, 'Test', 'Test1')

    const body = {
      id: mixChange,
      idUser: userId,
    }

    const reviewResponse = await APILA.review(body)

    const bod = {
      id: reviewResponse.data.mix_change,
      idUser: userId,
    }

    await APILA.aprobe(bod)

    dispatch(
      Modal.showAlertModal(
        'Pauta Aprobada',
        'Su pauta fue aprobada exitosamente.',
        Modal.MODAL_TYPE_SUCCESS,
        async () => {
          await APILA.updatePautasLets()
          dispatch(getCampaigns(account, filter))
        }
      )
    )
  } catch (err) {
    Logger.log(TAG, 'error aproveCampaign', err)
    dispatch(
      Modal.showAlertModal(
        'Error!',
        'Ocurrió un error al aprobar la pauta',
        Modal.MODAL_TYPE_ERROR
      )
    )
  }

  dispatch(showLoader(false))
}

const updateAccountEAdvertising = async (id, eAdvertising) => {
  const body = {
    id,
    eAdvertising,
  }

  try {
    const response = await API.updateAccount(body)
    Logger.log(TAG, 'updateAccount', response)
  } catch (err) {
    Logger.log(TAG, 'error updateAccount', err)
  }
}

export const getCampaigns = (account, filter) => async dispatch => {
  dispatch(changePaginationLoading(true))
  dispatch(clearCampaigns())

  try {
    const response = await APILA.getCampaigns({ account, ...filter })

    Logger.log(TAG, 'getCampaings', response)
    const { campaigns, count } = response.data

    if (campaigns) {
      campaigns.forEach((campaign) => {
        dispatch(addCampaign(campaign))
      })
    }

    dispatch(changePaginationTotal(count))
  } catch (err) {
    Logger.log(TAG, 'error getCampaigns', err)
  }
  
  dispatch(changePaginationLoading(false))
}

export const clearCampaigns = () => ({
  type: CAMPAIGNS_CLEAR
})

export const clearCampaignsBudget = () => ({
  type: CAMPAIGNS_BUDGET_CLEAR
})

export const clearCampaignupd = () => ({
  type: CAMPAIGNS_UPD_CLEAR
})

export const updatePautasLets = () => async dispatch => {
  Logger.log(TAG, 'updatePautasLets')

  try {
    await APILA.updatePautasLets()
  } catch (err) {
    Logger.log(TAG, 'error updatePautasLets', err)
  }
}

export const getCampaignsBudget = (id_budget) => async dispatch => {
  try {
    const response = await APILA.getCampaigns()

    const { campaigns } = response.data

    dispatch(clearCampaignsBudget())
    let monto = 0

    campaigns.forEach((campaign) => {
      if (campaign.idBudget != undefined && campaign.idBudget == id_budget) {
        if (campaign.budget != undefined && campaign.budget > 0) {
          monto = monto + parseInt(campaign.budget)
        }

        let campaignsbudget = {
          monto: monto,
          id_budget: id_budget,
        }

        dispatch(clearCampaignsBudget())
        dispatch(addCampaignBudget(campaignsbudget))
      } else {
        let campaignsbudget = {
          monto: monto,
          id_budget: id_budget,
        }

        dispatch(clearCampaignsBudget())
        dispatch(addCampaignBudget(campaignsbudget))
      }
    })
  } catch (err) {
    Logger.log(TAG, 'error getCampaigns', err)
  }
  
  dispatch(showLoader(false))
}

export const addBudget = (id_budget) => dispatch => {
  const campaignsbudget = {
    monto: 0,
    id_budget: id_budget
  }

  dispatch(clearCampaignsBudget())
  dispatch(addCampaignBudget(campaignsbudget))
}

export const changePaginationPerPage = (paginationPerPage) => ({
  type: CAMPAIGNS_CHANGE_PAGINATION_PER_PAGE,
  paginationPerPage,
})

export const changePaginationTotal = (total) => ({
  type: CAMPAIGNS_CHANGE_TOTAL,
  total,
})

export const changePaginationLoading = (loading) => ({
  type: CAMPAIGNS_CHANGE_LOADING,
  loading,
})

export const changeSortFilter = (sortDirection, column) => ({
  type: CAMPAIGNS_CHANGE_FILTER_SORT,
  sortDirection,
  column,
})

export const changePageFilter = (page) => ({
  type: CAMPAIGNS_CHANGE_FILTER_PAGE,
  page,
})

export const changePaginationFilter = (paginationPerPage) => ({
  type: CAMPAIGNS_CHANGE_FILTER_PAGINATION,
  paginationPerPage,
})

export const changeTextFilter = (text) => ({
  type: CAMPAIGNS_CHANGE_FILTER_TEXT,
  text,
})

const addCampaign = campaign => ({
  type: CAMPAIGNS_ADD_ONE,
  campaign
})

const updateStatus = (id, status) => ({
  type: CAMPAIGNS_UPDATE_STATUS,
  id,
  status,
})

const addCampaignBudget = campaignsbudget => ({
  type: CAMPAIGNS_BUDGET,
  campaignsbudget
})