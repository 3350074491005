import moment from 'moment'

import * as API from 'helpers/api'
import * as Logger from 'utils/logger'
import * as Modal from './modal.actions'
import { showLoader } from './loader.actions'
import axios from "axios"
export const FB_PROP = 'FACEBOOK:PROP'
export const FB_PAGES = 'FACEBOOK:PAGES'
export const FB_DATOS = 'FACEBOOK:DATOS'
export const FB_COMMENTS = 'FACEBOOK:COMMENTS'

const TAG = 'FACEBOOK:ACTION'

export const setProp = prop => dispatch => {
    dispatch({
        type: FB_PROP,
        prop,
    })
}

export const getPages = () => (dispatch) => {
    dispatch(showLoader(true))
    API.getPages()
        .then(response => {
            Logger.log(TAG, 'getPages', response)

            dispatch({
                type: FB_PAGES,
                pages: response.data
            })
        })
        .catch(err => {
            Logger.log(TAG, 'getPages', err)
        })
        .then(() => {
            dispatch(showLoader(false))
        })
}


export const setDatos = (token) => async (dispatch) => {
    dispatch(showLoader(true))
    
    const [ response1, response2, response3 ] = await Promise.all([
       new Promise(resolve => window.FB.api('/me/feed', { access_token: token, fields: ['message', 'picture', 'created_time', 'status_type', 'from']}, response => {  
        const profileURL =  `https://graph.facebook.com/me?fields=picture,name,id&access_token=${token}`
        axios.get(profileURL)
  .then(function (responseAxios) {
    // handle success
    console.log(responseAxios.body);
  })
       
        resolve(response)
   })),
        new Promise(resolve => window.FB.api('/me/insights/page_actions_post_reactions_total', { access_token: token, period: 'month', since: moment().add(-1, 'm').format('YYYY-MM-DD'), until: moment().add(+2, 'd').format('YYYY-MM-DD') }, response => resolve(response))),
        new Promise(resolve => window.FB.api('/me/insights/page_posts_impressions_unique', { access_token: token, period: 'month', since: moment().add(-1, 'm').format('YYYY-MM-DD'), until: moment().add(+2, 'd').format('YYYY-MM-DD') }, response => resolve(response))),
    ]);
    dispatch({
        type: FB_DATOS,
        posts: response1.data,
        reactions: response2.data[0].values,
        alcance: response3.data[0].values,
    })
    dispatch(showLoader(false))
}

export const setComments = (postId, token) => dispatch => {
    dispatch(showLoader(true))
    window.FB.api(`/${postId}/comments`, { access_token: token, fields: ['message', 'created_time', 'from'] }, function(response) {
        console.log('FBapi', response);
        dispatch({
            type: FB_COMMENTS,
            postId,
            comments: response.data,
        })
        dispatch(showLoader(false))
    });
}
