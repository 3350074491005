/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Select from 'react-select'


import ReactDataTable from '@easygosanet/react-datatable'
import TitlebarImageList from './GalleryPosts'
import TitlebarStoryList from './GalleryStories'


import './assets/css/styles.css'
/****material components */
import NativeSelect from '@material-ui/core/NativeSelect';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import FaceIcon from '@mui/icons-material/Face';

import { getDatos, setProp } from 'redux/actions/fanpageWebHook.actions'
import { withUtils } from 'utils'
import * as APILI from 'helpers/apiLI';

import "./styles.css";



const Datos = (props) => {

  const [userData, setUserData] = useState([])
  const [userPosts, setUserPosts] = useState([])
  const [storyData, setStoryData] = useState([])
  
  
  const [user, setUser] = useState({
      "user_id": "1234567890",
      "media_count": 11,
      "username": "DummyUser",
      "account_type": "MARKET_PLACE"
  })
  const [posts, setPosts] = useState([])
  const [media, setMedia] = useState('')
  const [mystories, setmyStories] = useState([])
  const [story, setStory] = useState('')
  const [userProfile, setuserProfile] = useState('')
  const [imageUrl, setImageUrl] = useState("");
  const [postCaption, setPostCaption] = useState("");
  const [isSharingPost, setIsSharingPost] = useState(false);
  const [facebookUserAccessToken, setFacebookUserAccessToken] = useState("");
  const [responseData, setResponseData] = useState({
    biography: "",
    followers_count: 0,
    follows_count: 0,
    id: "",
    media:{
      data:[]
    },
    stories:{
      data:[]
    },
    media_count: 0,
    name: "",
    profile_picture_url: "",
    username: "",
    website: "",
  });

  
  const [mediaData,setMediaData]=useState([])
  const [stories,setStories]= useState([])
  const [refresh,setRefresh]=useState(true)
  const [insightsStory,setInsightsStory] = useState([])
  const [insights,setInsights] = useState([])
  const [comments,setComments] = useState([])
  const [email, setEmail] = useState("");
  /* --------------------------------------------------------
   *                      FACEBOOK LOGIN
   * --------------------------------------------------------
   */

  // Check if the user is authenticated with Facebook
  useEffect(() => {
    window.FB.getLoginStatus((response) => {
      saveToken(response.authResponse?.accessToken);
    });
  }, []);

  const saveToken =(token)=>{
    setFacebookUserAccessToken(token)
    getUserData(token)
  }
  const logInToFB = () => {
    window.FB.login(
      (response) => {
        saveToken(response.authResponse?.accessToken);
        
      },
      {
        scope: "instagram_basic,pages_show_list",
      }
    );
  };



  const  savePost =  () => {
   
    mediaData.forEach(element => {
      let objecto = insights.filter(dataInsight => element.id === dataInsight.id)
      let objecto2 = comments.filter(dataComment => element.id === dataComment.id)
      const caption= element.caption
      let hashtagWord =''
      let startHashTag = false
      let hashtag =[]
      
      for(let x=0; x<=caption.length-1; x++){
        if(caption[x]==='#')
          startHashTag=true
       if(startHashTag===true && caption[x]!==' ')
           hashtagWord= hashtagWord.concat(caption[x]) 
      if(caption[x]===' ' && startHashTag===true ||  x===caption.length-1 && startHashTag===true){
         startHashTag=false
         hashtag.push({"name":hashtagWord.toLowerCase()})
       hashtagWord=''
        }
      } 

  const body={
  "influencerid": email,
  "name":responseData.name,
  "createdAt": element.created_time,
  "message":   element.caption, 
  "id": element.id,
  "urlPost": element.permalink, 
  "socialmedia":"instagram",
  "picture": element.media_url, 
  "activity": element.media_type,
  "reactions": element.comments_count + element.likes_count,
  "comments_count": element.comments_count,
  "likes_count": element.like_count,
  "status":"Pendiente",
  "hashtag": hashtag,
  "campaign": hashtag,
  "insights": {
    "engagement":objecto[0].response.data[0].values[0].value,
    "impressions":objecto[0].response.data[1].values[0].value,
    "reach":objecto[0].response.data[2].values[0].value,
    "saved":objecto[0].response.data[3].values[0].value},  
  "comments": objecto2[0].response.data
    }
    
 APILI.createPosts(body).then(res => {
    console.log(res) }).catch(err => {
    console.log(err)
  })
})
  }

  //console.log(getInstagramData())

  const logOutOfFB = () => {
    window.FB.logout(() => {
      setFacebookUserAccessToken(undefined);
    });
  };

  /* --------------------------------------------------------
   *             INSTAGRAM AND FACEBOOK GRAPH APIs
   * --------------------------------------------------------
   */

  const getFacebookPages = () => {
    return new Promise((resolve) => {
      window.FB.api(
        "me/accounts",
        "GET",
        {

          fields: "name,id,instagram_business_account{id,biography,followers_count,follows_count,media_count,name,profile_picture_url,username,media,stories,live_media,website,tags}",
        },
        { access_token: facebookUserAccessToken },
        (response) => {
          resolve(response.data);
          console.log("data",response)
        }
      );
    });
  };

   
  const getResponseData =async (data,arrayPosts)=>{
    setResponseData(data)
  //  console.log("loooooooooooooog",arrayPosts)
  await  setMediaData(arrayPosts[0])
  await setInsights(arrayPosts[1])
  await setComments(arrayPosts[2])

  }
  
  const  getUserData =  (token) => {
    let arrayPosts =[]
    let arrayStories =[]
    if(token){
      window.FB.api(
        '/me/accounts',
        'GET',
        {"fields":"name,id,instagram_business_account{id,biography,followers_count,follows_count,media_count,name,profile_picture_url,username,media,stories,live_media,website,tags}"},
        function(response) {
        
         //getPosts(response.data[0].instagram_business_account?.media?.data)
         //getStories(response.data[0].instagram_business_account?.stories?.data)
         arrayPosts=getPosts(response.data[0].instagram_business_account?.media?.data)
        // console.log(getPosts(response.data[0].instagram_business_account?.media?.data))
         getResponseData(response.data[0]?.instagram_business_account,getPosts(response.data[0].instagram_business_account?.media?.data))
        arrayStories=response.data[0].instagram_business_account?.stories?.data
        
       }
      );
    
      }
    window.FB.api(
      '/me',
      'GET',
      {"fields":"email",token:token},
      function(response) {
        setEmail(response.email)
      }
    );
    
  }
 
  const saveStories = (data,data2) => {
    if(data){
      setStories(data)
    }
    if(data2){
      setInsightsStory(data2)
    }
    
    
  }

  const getStories = (stories) =>{
    let array = []
    let array2 = []
    if(stories!==undefined){
      stories.forEach((element) =>{
        window.FB.api(
          '/'+element.id+'',
          'GET',
          {"fields":"id,media_type,media_url,username,timestamp,caption, like_count,media_product_type,permalink"},
          function(response) {
            array.push(response)
              //console.log(response)
             
          }
        )
        window.FB.api(
          '/'+element.id+'/insights',
          'GET',
          {"metric":"exits,impressions,reach,replies,taps_forward,taps_back"},
          function(response) {
            response.error?
            array2.push({
              id:element.id,
              error: true,
              message:"El numero de visualizaciones es muy bajo para tomar una metrica"})
              :
              array2.push({
                id:element.id,
                error:false,
                response:response})
              //console.log(response)
             
          }
        );
      }
      )
      saveStories(array,array2)
    }
   
  }

  
  const getPosts =  (media) => {
    let array = []
    let array2 = []
    let array3 = []
    media.forEach((element) => {
       window.FB.api(
        '/'+element.id+'',
        'GET',
        {"fields":"id,media_type,media_url,username,timestamp,caption,comments_count, like_count,is_comment_enabled,media_product_type,permalink"},
        function(response) {
          array.push(response)
            //console.log(response)
           
        }
      );
      
      window.FB.api(
        '/'+element.id+'/insights',
        'GET',
        {"metric":"engagement,impressions,reach,saved"},
        function(response) {
          array2.push({
            id:element.id,
            response:response})
            //console.log(response)
           
        }
      );

      window.FB.api(
        '/'+element.id+'/comments',
        'GET',
        {},
        function(response) {
          array3.push({
            id:element.id,
            response:response})
            //console.log(response)
           
        }
      );
  });
  //saveMedia(array,array2,array3)
  //console.log([array,array2,array3]);
  return([array,array2,array3])
}

const saveMedia = (data1,data2,data3)=> {
  //setMediaData(data1)
  //setInsights(data2)
  //setComments(data3)
  savePost()
  setRefresh(true)
}



  const getInstagramAccountId = (facebookPageId) => {
    return new Promise((resolve) => {
      window.FB.api(
        facebookPageId,
        {
          access_token: facebookUserAccessToken,
          fields: "name,id,instagram_business_account{id,biography,followers_count,follows_count,media_count,name,profile_picture_url,username,media,stories,live_media,website,tags}",
        },
        (response) => {
          resolve(response.instagram_business_account.id);
          console.log(response.instagram_business_account)
        }
      );
    });
  };

  const createMediaObjectContainer = (instagramAccountId) => {
    return new Promise((resolve) => {
      window.FB.api(
        `${instagramAccountId}/media`,
        "POST",
        {
          access_token: facebookUserAccessToken,
          image_url: imageUrl,
          caption: postCaption,
        },
        (response) => {
          resolve(response.id);
        }
      );
    });
  };

  const publishMediaObjectContainer = (
    instagramAccountId,
    mediaObjectContainerId
  ) => {
    return new Promise((resolve) => {
      window.FB.api(
        `${instagramAccountId}/media_publish`,
        "POST",
        {
          access_token: facebookUserAccessToken,
          creation_id: mediaObjectContainerId,
        },
        (response) => {
          resolve(response.id);
        }
      );
    });
  };

  const shareInstagramPost = async () => {
    setIsSharingPost(true);
    const facebookPages = await getFacebookPages();
    const instagramAccountId = await getInstagramAccountId(facebookPages[0].id);
    const mediaObjectContainerId = await createMediaObjectContainer(
      instagramAccountId
    );

    await publishMediaObjectContainer(
      instagramAccountId,
      mediaObjectContainerId
    );

    setIsSharingPost(false);

    // Reset the form state
    setImageUrl("");
    setPostCaption("");
  };
  const theme = useTheme();

  
  return (
    <>
<div className="container">
<div className="row">
<div className="col-md-3" />
<div className="col-md-6">

        <Link to='/scan'>Regresar</Link>
        </div>
        <div className="col-md-3" />
      </div>
      <div className="row">
        <div className="col-md-3" />
 
        <div className="col-md-6">
          <div className="row"> 
          <main id="app-main">
        <section className="app-section">
          {!facebookUserAccessToken ?
            <>
             <h3>Inicia sesion con Facebook</h3>
          <h6>Recuerda que tu cuenta de instagram y Facebook deben ser business y estar asociadas previamente</h6>
         
            </>
            :null}
          {facebookUserAccessToken ? (
            <button onClick={logOutOfFB} className="btn action-btn">
              Log out of Facebook
            </button>
          ) : (
            <button onClick={logInToFB} className="btn action-btn">
              Login with Facebook
            </button>
          )}
        </section>
      </main>
        </div>
        <div className="row ">
         <Card sx={{ display: 'flex', width:'100%' }}>
        <CardMedia className="ml-3" 
        component="img"
        sx={{ width: 151 }}
        image={responseData.profile_picture_url}
        alt="Live from space album cover"
      />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h5">
            {responseData.name}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            {responseData.username}
          </Typography>
          <Typography variant="overline" color="text.secondary" component="div">
            {responseData.biography}
          </Typography>
          <Typography variant="caption" color="text.secondary" component="div">
            <a href={responseData.website}>{responseData.website}</a>
          </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
        <Typography variant="subtitle1" color="text.secondary" component="div">
            {responseData.followers_count}
          </Typography>
          <IconButton aria-label="play/pause">
            <FaceIcon sx={{ height: 38, width: 38 }} />
          </IconButton>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            {responseData.follows_count}
          </Typography>
        </Box>
      </Box>
      
    </Card>
        </div>
        
</div>
       
      <div className="col-md-3" />
      
      </div>
      <div className="row mb-10">
    
      </div>
      
      <div className="row mt-10">
       <div className="col-md-12">
       <h2>POSTS</h2>
      
        <TitlebarImageList mediaData={mediaData}/>
        
       </div>
        

      
      <div className="col-md-12">
        <h2>STORIES</h2>
        {refresh?
        <TitlebarStoryList stories={stories}/>
        :null}
      </div>
     
      </div>
      
</div>
      
    </>
  )
}

const mapStateToProps = (state) => ({
  info: state.fanpageDatosWeb.info,
})

const mapDispatchToProps = {
  setProp,
  getDatos,
}

export default connect(mapStateToProps, mapDispatchToProps)(withUtils(withRouter(Datos)))
