import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';
import { useState, useEffect } from 'react'
import * as APILI from "../../helpers/apiLI";

const labels = {
  0.5: '0.5',
  1: '1',
  1.5: '1.5',
  2: '2',
  2.5: '2.5',
  3: '3',
  3.5: '3.5',
  4: '4',
  4.5: '4.5',
  5: '5',
};

export default function StarsRater(props) {
  
  const {rating, updateRating, setRating, id} = props
  const [value, setValue] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const [hover, setHover] = useState(-1);

  const getSubTaskRating =  async()=>{
    let res =  await APILI.getSubTaskById({id :id })
    setValue(res.data.subTasks[0].rating)
  }
  useEffect(() => {
    setTimeout(() => {
      getSubTaskRating()
    }, 1000);
  }, [refresh])

  const updateRate =(newValue)=>{
    updateRating(id, newValue);
    setRefresh(!refresh)
  }
  return (
    <Box
      sx={{
        width: 300,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Rating
        name="hover-feedback"
        value={value}
        precision={0.5}
        style={{ fontSize: 40 }}
        onChange={(event, newValue) => {
          //setRating(newValue)
          updateRate(newValue)
          
        }}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
        emptyIcon={<StarIcon tyle={{  opacity: 0.25, fontSize: 40}} fontSize="inherit" />}
      />
      {value !== null && (
        <Box sx={{ ml: 0.1  }}>{labels[hover !== -1 ? hover : value]}</Box>
      )}
    </Box>
  );
}