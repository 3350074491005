import React, { useState, useEffect } from 'react'
import * as utils from 'data.js'

import DataTableUtils, {
  Share
} from '@easygosanet/react-datatable-utils'
import ReactDataTable, { DateRange } from '@easygosanet/react-datatable'

//helpers
import * as APILI from '../../helpers/apiLI'

//Images
import Logo_facebook from './assets/img/logo_facebook.svg'
import Logo_instagram from './assets/img/logo_instagram.png'

//components
import SelectorBox from 'components/selector'
import Swal from 'sweetalert2/dist/sweetalert2.js'

//Delivery Column
import { getLogoByName, getClassNameByStatus } from 'myUtil'
import { showLoader } from 'redux/actions/loader.actions';
import { connect } from 'react-redux';
//css
import './style.css'


const DeliveryCol = ({ text, className, onClick }) => (
  <div   className="">
    <div className={"delivery" + (text)}>
      {text}
    </div>
  </div>
)

//Tables style

const customStyles = {
  headCells: {
    style: {
      '&:nth-last-of-type(2)': {
        backgroundColor: '#E3E4E6',
        width: '40px'
      },
      '&:nth-last-of-type(4)': {
        backgroundColor: '#E3E4E6',
        width: '40px'
      },
      '&:nth-last-of-type(6)': {
        backgroundColor: '#E3E4E6',
      },
      '&:nth-last-of-type(8)': {
        backgroundColor: '#E3E4E6',
      },
      '&:nth-last-of-type(10)': {
        backgroundColor: '#E3E4E6',
      },
      '&:nth-last-of-type(12)': {
        backgroundColor: '#E3E4E6',
      },
      '&:nth-last-of-type(14)': {
        backgroundColor: '#E3E4E6',
      },
    },
  },
}

const Finance = (props) => {
const [tableTitle,setTableTitle] = useState('Iniciado')
const[tables,setTables]= useState([]); 
const [firstState,setFirstState] = useState(true);
const [secondState,setSecondState] = useState(false);
const [thirdState,setThirdState] = useState(false);
const [fourthState,setFourthState] = useState(false);
const [fiveState,setFiveState] = useState(false);
const [refreshTable, setrefreshTable] = useState(false)

  //add Luis
  const [filterText, setFilterText] = useState('')
  let [table, setTable] = useState([]);
  //add finish Luis
  const [filteredData, setFilteredData] = useState([])
  const [columns, setColumns] = useState([
    {
        name: 'Nombre del reto o tarea',
        selector: 'userId',
        // selector: 'activityName',
        sortable: true,
        minWidth: '200px',
       
        cell: (row) => (
            <DetailCol
                text={row.userId}
                data={row}
            />
        ),
    },
  {
        name: 'Estado',
        // selector: 'delivery',
        selector: 'delivery',
        sortable: true,
        center: true,
        style: {
            backgroundColor: '#E3E4E6',
            fontWeight: 'bold',
        },
        type: 'text',
        cell: (row) => (
            <DeliveryCol
                text={row.state}
                className={getClassNameByStatus(row.state)}
            />
        )
    },
    {
        name: 'Campaña',
        // selector: 'campaignName',
        selector: 'campaignName',
        sortable: true,
        center: true,
        style: {
            fontWeight: 'bold',
        },
        cell: (row) => row.campaignName
    },
    {
        name: 'Fecha Solicitado',
        // selector: 'createdAt',
        selector: 'createdAt',
        sortable: true,
        center: true,
        style: {
            backgroundColor: '#E3E4E6',
            fontWeight: 'bold',
        },
        cell: (row) => row.createdAt
    },
    /* {
      name: 'Fecha Autorizado',
      // selector: 'authorizedAt',
      selector:  (row) => row.authorizedAt,
      sortable: true,
      center: true,
      style: {
          
          fontWeight: 'bold',
      },
      selector:  (row) => row.authorizedAt
  }, */
    /* {
      name: 'Fecha Acreditado',
      // selector: 'creditedAt',
      selector:  (row) => row.creditedAt,
      sortable: true,
      center: true,
      style: {
          backgroundColor: '#E3E4E6',
          fontWeight: 'bold',
      },
  }, */
    {
      name: 'Pago creatividad',
      // selector: 'ratingFee',
      selector: 'ratingFee',
      sortable: true,
      center: true,
      style: {
          fontWeight: 'bold',
      },
      cell:  (row) => '$ ' + row.ratingFee,
  },
    {
        name: 'Pago alcance',
        // selector: 'scopeFee',
        selector: 'scopeFee',
        sortable: true,
        center: true,
        style: {
          backgroundColor: '#E3E4E6',
            fontWeight: 'bold',
        },
        cell:  (row) => '$ ' + row.scopeFee,
    },
    {
        name: 'Pago interacción',
        selector: 'interactionFee',
        // selector: 'interactionFee',
        sortable: true,
        center: true,
        style: {
            fontWeight: 'bold',
        },
        cell:  (row) => '$ ' + row.interactionFee,
    },
    {
        name: 'Pago',
        // selector: 'amount',
        selector: 'amount',
        sortable: true,
        center: true,
        style: {
          backgroundColor: '#E3E4E6',
            fontWeight: 'bold',
        },
        cell:  (row) => '$ ' + row.amount,
    },
    {
      name: 'Bank',
      // selector: 'bank',
      selector: 'bank',
      sortable: true,
      center: true,
      style: {
          fontWeight: 'bold',
      },
      cell:  (row) => row?.bankAccount?.bank
  },
  {
    name: 'No. de cuenta',
    // selector: 'accountNumber',
    selector: 'accountNumber',
    sortable: true,
    center: true,
    style: {
      backgroundColor: '#E3E4E6',
        fontWeight: 'bold',
    },
    cell:  (row) => row?.bankAccount?.accountNumber,
},
{
  name: 'Nombre de Cuenta',
  // selector: 'accountName',
  selector: 'accountName',

  sortable: true,
  center: true,
  style: {
      fontWeight: 'bold',
  },
  cell:  (row) =>row?.bankAccount?.accountName
},
{
name: 'Tipo de Cuenta',
// selector: 'accountType',
selector: 'accountType',

sortable: true,
center: true,
style: {
  backgroundColor: '#E3E4E6',
    fontWeight: 'bold',
},
cell:  (row) =>row?.bankAccount?.accountType
},
  {
    name: 'Billetera',
    // selector: 'wallet',
    selector: 'wallet',

    sortable: true,
    center: true,
    style: {
        fontWeight: 'bold',
    },
    cell:  (row) =>'$ ' +  row.wallet.toFixed(2),
},
    {
      name: 'Influencer',
      // selector: 'userId',
      selector:'userId',

      sortable: true,
      center: true,
      style: {
        backgroundColor: '#E3E4E6',
          fontWeight: 'bold',
      },
      cell: (row) => row.userId,
  },
    {
        name: 'Red Social',
        // selector: 'source',
        selector: 'source',
        sortable: true,
        center: true,
        style: {
            fontWeight: 'bold',
        },
        cell: (row) => <img className="source-img" src={row.source} alt="" />
    },
])
 /******************************************************** */
const getDataView = (data) => {
  setTables({
      data: data.map(dat => ({
        id: dat.id,
        name: dat.userId,
        delivery: {
          name: dat.state,
          className: getClassNameByStatus(dat.state)
      },
      campaignName: dat.campaignName,
      createdAt: new Date(dat.createdAt).toISOString().substring(0, 10),
      source: getLogoByName(dat.socialmedia),
      inscription: dat.scope,
      userId: dat.userId,
      price: dat.price,
      socialmedia: dat.socialmedia,
      activityName: dat.activityName,
      value: dat.amount,
      interactionFee: dat.paymentInteraction > 0 ? dat.currency+" "+dat.paymentInteraction.toFixed(2):0,
      ratingFee:dat.paymentCreativity> 0 ? dat.currency+" "+dat.paymentCreativity.toFixed(2): 0,
      scopeFee:dat.paymentReach>0? dat.currency+" "+dat.paymentReach.toFixed(2):0,
      amount: dat.totalAmount>0?dat.currency+" "+dat.totalAmount.toFixed(2):0,
      wallet: dat.currency+" "+dat.wallet,
      authorizedAt: dat?.authorizedAt  ? dat.authorizedAt : "Sin Autorizar",
      creditedAt: dat?.creditedAt ? dat.creditedAt : "Sin Acreditar",
      bank: dat?.bankAccount?.bank ? dat.bankAccount.bank : "",
      accountNumber: dat?.bankAccount?.accountNumber ? dat.bankAccount.accountNumber : "",
      accountName: dat?.bankAccount?.accountName ? dat.bankAccount.accountName : "Sin Cuenta",
      accountType: dat?.bankAccount?.accountType ? dat.bankAccount.accountType : "",
      }))
  })

}

const handleFilterTextChange = (value) => {
  setFilterText(value)
}

/******************************************************** */
const updatePaymentStatus = async (taskID, taskStatus, data) => {
  let walletAvailable = Number(data.wallet)
  let paidAmount = Number(data.amount)
  let newAvailable = paidAmount + walletAvailable 
  let newAvailableAfterPay =  walletAvailable - paidAmount
   let response = await APILI.updatePaymentStatusByID({id: taskID, state: taskStatus})
   if(taskStatus === 'Acreditado')
    {
      console.log('newAvailable',newAvailable)
      let response2 = await  APILI.updateWallet({id:data.userId, balance:newAvailable})
      .then(() => {
        setFilteredData([])
          getPayments('Solicitado')
          setFirstState(true)
          setSecondState(false)
          setThirdState(false);
          setFourthState(false);
          setFiveState(false)
      })
    }
    else if(taskStatus === 'Pagado')
    {
      console.log('newAvailableAfterPay',newAvailableAfterPay)
      let response2 = await  APILI.updateWallet({id:data.userId, balance:newAvailableAfterPay})
      .then(() => {
        setFilteredData([])
        getPayments('Acreditado')
        setFirstState(false)
        setSecondState(true)
        setThirdState(false);
        setFourthState(false);
        setFiveState(false)
      })
    }
   
    
      Swal.fire(
        '¡' + taskStatus +'!',
        ' ',
        'success'
      )
      
  }

const moderatePayment =(data)=>{
  console.log('datainfo', data)
  if(data.state=="Solicitado")
  {
    Swal.fire({
      title: 'Acreditar',
      html: 'Deseas acreditar el pago al wallet del Truer: <strong>' + data.userId + '</strong>  en la campaña: '+ data.campaignName +
      ' por el monto de: $' + data.amount,
      icon: 'info',
      showCancelButton: true,
      showDenyButton: true,
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonColor: '#00CA72',
      denyButtonText: '#d33',
      denyButtonText:
    '<i class="fa fa-thumbs-down"></i> Rechazar',
      confirmButtonText:
    '<i class="fa fa-thumbs-up"></i> Acreditar',
  }).then((result) => {
    
      if (result.isConfirmed) {
        updatePaymentStatus(data.id, '¡Acreditado!', data)
      }
      /*  else if (result.isDenied) {
        (data.id,dennyTask, data)
          Swal.fire({
              title: 'Rechazado!', 
                icon: 'error',
                  timer: 1500})
      } */
    })
  }
  else if(data.state=="Acreditado")
  {
    if(data.bankAccount.accountNumber=="" || data.bankAccount.accountNumber==" ")
    {
      Swal.fire({
        icon: 'error',
        title: 'Ups...',
        text: 'El Truer debe tener una cuenta registrada para realizar el pago'
      })
      
    }
    else
    {
      Swal.fire({
        title: 'Pagar',
        html: 'Deseas confirmar el pago a la cuenta del Truer: <strong>' + data.userId + '</strong>  en la campaña: '+ data.campaignName +
        ' por el monto de: $' + data.amount,
        icon: 'info',
        showCancelButton: true,
        showDenyButton: true,
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonColor: '#00CA72',
        denyButtonText: '#d33',
        denyButtonText:
      '<i class="fa fa-thumbs-down"></i> Rechazar',
        confirmButtonText:
      '<i class="fa fa-thumbs-up"></i> Confirmar',
    }).then((result) => {
      if (result.isConfirmed) {
      updatePaymentStatus(data.id, '¡Pagado!', data) 
      }
      else if (result.isDenied) {
  
      }
      })
    }
    
  }
}

const DetailCol = ({ text, data}) => (
  <div className="firstColl w-100">
      <div className="icon-container" style={{backgroundColor: "#FF9015",}}>
          <i onClick={() =>moderatePayment(data)} class="fas fa-eye"></i>
          <i className="fas fa-edit"></i>
          <i class="fas fa-trash"></i>
          <i class="fab fa-telegram-plane"></i>
          <i class="fas fa-ellipsis-v"></i>
      </div>
      <p className="name-fc">
          {text}
      </p>
  </div>
)

//Pendiente first
  useEffect(async() => {
    props._showLoader(true)
    let tempstate
    if (firstState) tempstate = "Solicitado"
    if (secondState) tempstate = "Acreditado"
    if (thirdState) tempstate = "Billing"
    if (fourthState) tempstate = "Payment"
    if (fiveState) tempstate = "Credited"
    const body ={
      state:tempstate
    }
    await APILI.getPaymentByStatus(body)
    .then(res => {
      getPaymentsData(res.data.registerPayment)
    }).catch(err => {
    })
  }, [refreshTable])

//paymentsDat
  const getPayments = async(dato) => {
    const body ={
      state:dato
    }
    await APILI.getPaymentByStatus(body).then(res => {
      getPaymentsData(res.data.registerPayment)
    }).catch(err => {
    })
  }
  function formatNumber(number) {
    return new Intl.NumberFormat('es-GT').format(Number.parseFloat(number,10).toFixed(2))
  }

  const getPaymentsData = async (paidsData) =>{
   let temp = []
   if (paidsData.length===0){
      setTables({
        titles: [],
        data: []
      })
      props._showLoader(false)
   }
   
   else if (paidsData.length>0)
   {
    for(let i=0; i<paidsData.length; i++)
    {
      let body = {
        id:paidsData[i].userId
      }
      let res = await  APILI.getWalletbyId(body)
      temp.push({
        amount: formatNumber(paidsData[i].totalAmount),
        campaignName: paidsData[i].campaignName,
        createdAt:  new Date(paidsData[i].createdAt).toISOString().substring(0, 10),
        currency: paidsData[i].currency,
        authorizedAt: paidsData[i].authorizedAt,
        creditedAt: paidsData[i].creditedAt,
        id: paidsData[i].id,
        source: getLogoByName(paidsData[i].socialmedia),
        state: paidsData[i].state,
        updatedAt: paidsData[i].updatedAt,
        userId: paidsData[i].userId,
        wallet:+ res.data.wallet.balance,
        interactionFee:formatNumber(paidsData[i].paymentInteraction),
        ratingFee:formatNumber(paidsData[i].paymentCreativity),
        scopeFee:formatNumber(paidsData[i].paymentReach),
        activityName: paidsData[i].activityName,
        bankAccount: res.data.bankAccount
    })
    } 

    setFilteredData(temp)
    props._showLoader(false)
   }
   
} 

//Luis copy mario
function matchFilter(text, list) {
  const filter = text.toLowerCase()
  return list.some(item => item.toLowerCase().includes(filter))
}

const handleClick = (e,data) =>{
  e.preventDefault()
  setTableTitle(data)
  setFilteredData([])
  if(data==='Solicitado'){
    getPayments('Solicitado')
    setFirstState(true)
    setSecondState(false)
    setThirdState(false);
    setFourthState(false);
    setFiveState(false)
  }
  if (data==='Acreditado'){
    getPayments('Acreditado')
    setFirstState(false)
    setSecondState(true)
    setThirdState(false);
    setFourthState(false);
    setFiveState(false)
  }
  if (data==='Pagado'){
    getPayments('Pagado')
    setFirstState(false)
    setSecondState(false)
    setThirdState(true);
    setFourthState(false);
    setFiveState(false)
  }
  setrefreshTable(!refreshTable)
}
//alert moderation
const MySwal = (data) => {
  let influencerName = ''
  let influencerLastName = ''
 
const updatePaymentStatus = async (taskID, taskStatus, data) => {
   
  const update = {
      id: taskID,
      state: taskStatus//,
     // updatedAt: new Date().toISOString()
  }
  const updateTable =() => {
      setrefreshTable(!refreshTable)
  }
   APILI.updatePaymentStatusByID(update).then((status) => {
     if (taskStatus === 'Payment' || taskStatus === 'Credited' || taskStatus === 'Pendiente') {
       let value =  0
       value = taskStatus === 'Payment' ? parseFloat(data.value) : parseFloat(data.value)*-1
       const body2={
         id:data.userId,
         amount:value
       }
        APILI.updateWallet(body2).then(res => {
          updateTable()
        }).catch(err => {
        }) 
     }
    updateTable()
  }).catch((err) => {
  })
  }

  if(data.delivery.name ==="Credited"){
      Swal.fire({
          title: 'Ninguna actividad pendiente!', 
          icon: 'success',
          timer: 1500
      })
      return
  }
  let aprobeTask = ''
  let dennyTask = ''
  if(data.delivery.name ==="Rejected"){
      aprobeTask = 'Pendiente'
      dennyTask = 'Rejected'
  }
  if(data.delivery.name ==="Pendiente"){
      aprobeTask = 'Payment'
      dennyTask = 'Rejected'
  }
  if(data.delivery.name ==="Approved"){
      aprobeTask = 'Billing'
      dennyTask = 'Pendiente'
  }
  if(data.delivery.name ==="Billing"){
      aprobeTask = 'Payment'
      dennyTask = 'Approved'
  }
  if(data.delivery.name ==="Payment"){
    aprobeTask = 'Credited'
    dennyTask = 'Pendiente'
}

      Swal.fire({
          title: data.campaignName,
          text: 'Usuario:, ' + data.userID + ' Seguidores: '+ data.scope +
          'Desea participar del reto:, ' + data.campaignName,
          icon: 'info',
          showCancelButton: true,
          showDenyButton: true,
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonColor: '#00CA72',
          denyButtonText: '#d33',
          denyButtonText:
        '<i class="fa fa-thumbs-down"></i>',
          confirmButtonText:
        '<i class="fa fa-thumbs-up"></i> Aprobar',
      }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            updatePaymentStatus(data.id, aprobeTask, data)      
            Swal.fire({
                title: '¡Confirmado!', 
                  icon: 'success',
                    timer: 1500})
          } else if (result.isDenied) {
            updatePaymentStatus(data.id,dennyTask, data)
              Swal.fire({
                  title: '¡Rechazado!', 
                    icon: 'error',
                      timer: 1500})
          }
        })
  
}

  return (
    <div className='form-dashboard'>
      <div className='Bar-content'>
        <div className='content-menu list'>
          <select
            id='viewSelect'
            className='select-dashboard fa'
          >
            <option className='fa' value='1'>
              {' '}
            &#xf0ce; Vista de pagos
          </option>
            {/* <option className='fa' value='0'>
              {' '}
            &#xf0db; Vista de Kanban{' '}
            </option>
            <option className='fa' value='2'>
              {' '}
            &#xf073; Vista de calendario
          </option> */}
          </select>
          {/* add Luis */}
          
          < DataTableUtils className='container-btn d-flex' onChangeFilterText={handleFilterTextChange}>
          {/* Finish Luis */}
            {/* <Share
              contacts={utils.myContacts}
            />
            <a id="colum-btn" className="btn-dashboard" href="/#">
            <i className="fas fa-columns"></i>
          </a>
          <a id="filter-btn" className="btn-dashboard" href="/#">
            <i className="fas fa-filter"></i>
          </a> */}
          </DataTableUtils>
          {/* <div
            className='btnOrange-dash'
          >
            <i className='fas fa-plus'></i>
          </div> */}
        </div>
      </div>
        <div className="content-submenu-finance">
      <div onClick={e=> {handleClick(e,"Solicitado")}} ><SelectorBox activeColor={firstState}  className="fas fa-handshake"  text="Solicitados" /></div> 
      <div onClick={e=> {handleClick(e,"Acreditado")}}><SelectorBox  activeColor={secondState}  className="fas fa-coins" text="Acreditados" /></div> 
      <div onClick={e=> {handleClick(e,"Pagado")}}><SelectorBox  activeColor={thirdState}  className="fas fa-coins" text="Pagados" /></div> 
      </div>
      <div className="row-section" style={{marginBottom: "2rem"}}>
        <h5>{tableTitle}</h5>
        {firstState ?
         <ReactDataTable
         columns={columns}
         pagination
         noDataComponent={"No hay datos para mostrar"}
         paginationComponentOptions={{
          rowsPerPageText: 'Elementos por página',
          rangeSeparatorText: 'de',
        }}
         customStyles={customStyles}
        //  data={tables.data}
        data={filteredData}
         persistTableHead
         noHeader
       /> :
        null}
         {secondState ?
         <ReactDataTable
          columns={columns}
         customStyles={customStyles}
         pagination
         noDataComponent={"No hay datos para mostrar"}
         paginationComponentOptions={{
          rowsPerPageText: 'Elementos por página',
          rangeSeparatorText: 'de',
        }}
        //  data={tables.data}
        data={filteredData}
         persistTableHead
         noHeader
       /> :
        null}
         {thirdState ?
         <ReactDataTable
         columns={columns}
         pagination
         noDataComponent={"No hay datos para mostrar"}
         paginationComponentOptions={{
          rowsPerPageText: 'Elementos por página',
          rangeSeparatorText: 'de',
        }}
         customStyles={customStyles}
        //  data={tables.data}
        data={filteredData}
         persistTableHead
         noHeader
       /> :
        null}
      </div>
      
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  _showLoader: show => dispatch(showLoader(show))
})

const mapStateToProps = (state) => ({
  isLoading: state.app.isLoading,
  myUser: state.users.myUser
})

export default connect(mapStateToProps, mapDispatchToProps)(Finance)