import React,{useState} from 'react'

// components
import Campaign from 'components/campaign'

const DashboardPage = (props) => {
  const {userRol} = props;
   return (
    <>
    {userRol==='admin'|| userRol==='anunciante'?
    <Campaign userRol={userRol}/>:
    <h1>No tienes privilegios</h1>
    }
    </>
    )
}

export default DashboardPage