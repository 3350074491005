import React from 'react';
// components
import PostList from 'components/moderation/postList'
const PostListPage = (props) => {
    const {userRol} = props;
  return (
    <>
    {userRol==='admin'?
    <PostList />:
    <h1>No tienes privilegios</h1>
    }
    </>
    )  
  }


export default PostListPage;