export const SHOW_MODAL = 'MODAL:SHOW'
export const HIDE_MODAL = 'MODAL:HIDE'

export const MODAL_TYPE_INFO = 'MODAL:TYPE:INFO'
export const MODAL_TYPE_ERROR = 'MODAL:TYPE:ERROR'
export const MODAL_TYPE_SUCCESS = 'MODAL:TYPE:SUCCESS'
export const MODAL_TYPE_WARNING = 'MODAL:TYPE:WARNING'
export const MODAL_TYPE_CONFIRMATION = 'MODAL:TYPE:CONFIRMATION'
export const MODAL_TYPE_CONFIRMATION_LOGIN = 'MODAL:TYPE:CONFIRMATION'
export const MODAL_TYPE_LOGOUT = 'MODAL:TYPE:LOGOUT'

export const showAlertModal = (title, message, type, callback) => ({
  type: SHOW_MODAL,
  modal_type: type,
  title,
  message,
  callback
})

export const hideAlertModal = () => ({ type: HIDE_MODAL })