import profilePicture from './pictureProfile.jpg'
import React, { useState, useEffect } from "react";

export default function DataProfile ({currentProfile}) {
  console.log('currentPRofile', currentProfile)
  return (
    <>
      <aside className='contProfile'>
        <div className="headProfile">
          <img src={currentProfile?.instagram_business_account?.profile_picture_url} width={90} height={90}/>
          <div className='text'>
            <p className="headProfileTitle">{currentProfile?.instagram_business_account?.username ?'@' + currentProfile?.instagram_business_account?.username :currentProfile.name}</p>
           {/*  <p>Tipo de cuenta</p> */}
          </div>
        </div>
        <div className="bodyProfile">
          <div className="boxData">
            <p>{currentProfile?.instagram_business_account?.media_count ? currentProfile?.instagram_business_account?.media_count: currentProfile?.posts?.data?.length}</p>
            <span>Publicaciones</span>
          </div>
          <div className="boxData">
            <p>{currentProfile?.instagram_business_account?.followers_count}</p>
            <span>seguidores</span>
          </div>
          <div className="boxData">
            <p>{currentProfile?.instagram_business_account?.follows_count}</p>
            <span>Seguidos</span>
          </div>
        </div>
      </aside>
    </>
  )
}