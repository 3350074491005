import * as Actions from '../actions/fanpageMessage.actions'

const initialState = {
  pages: [],
  selectedPage: null,
  conversationId: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.FB_PROP:
      return {
        ...state,
        [action.prop.name]: action.prop.value,
      }
    case Actions.FB_PAGES:
      return {
        ...state,
        pages: action.pages,
      }
    case Actions.FB_DATOS:
      return {
        ...state,
        conversations: action.conversations,
        messages: [],
        conversationId: '',
      }
    case Actions.FB_MESSAGES:
      return {
        ...state,
        conversationId: action.conversationId,
        messages: action.messages,
      }
    default:
      return state
  }
}