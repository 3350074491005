import React from 'react'

// components
import SignInForm from '../../components/auth/signin_form'

const SignUp = props => (
  <>
    <SignInForm {...props} />
    {/* <SignUpForm {...props} /> */}
  </>
)

export default SignUp
