import React from 'react'

import './asserts/styles.css'
import Divider from '@material-ui/core/Divider';
const TargetContent = (props) => {

    const listGeneralStatistics = props.listGeneralStatistics;
    const nameClass = props.nameClass;

    

    const listItems = listGeneralStatistics.map((generalStatistic) =>
        <div className="dataBox">
            <a>{generalStatistic.text}</a> &#160;
                    <a className="numberValues">{generalStatistic.number}</a>
        </div>
    );
    return (
        <div className={nameClass}>
            <div className="marginTarget">
                    {listItems}
            </div>
        </div>
    )

}

export default TargetContent