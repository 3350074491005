import React from 'react'
// components
import TaskList from 'components/moderation/taskList'

const TaskListPage = (props) =>  {
  const {userRol} = props;
  return (
    <>
    {userRol==='admin'?
    <TaskList />:
    <h1>No tienes privilegios</h1>
    }
    </>
    )  
}


export default TaskListPage
