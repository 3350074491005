import React from 'react'

// components
import Home from 'components/home'

const HomePage = () => (
  <>
    <Home />
  </>
)

export default HomePage
