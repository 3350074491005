import API from '@aws-amplify/api'

import { API_NAME, API_NAMELI } from '../config/api'
import * as Logger from 'utils/logger'

const TAG = 'API'

const globalOptions = {
  response: true,
}

/*
 * Clients
 */

export const getClients = () => {
  let options = {
    ...globalOptions,
    body: {},
  }

  Logger.log(TAG, 'getClients', options)

  return API.post(API_NAME, '/getClients', options)
}

/*
 * Accounts
 */

export const getAccounts = (client) => {
  let options = {
    ...globalOptions,
    body: {
      client
    },
  }

  Logger.log(TAG, 'getAccounts', options)

  return API.post(API_NAME, '/accounts/myAccounts', options)
}

export const createAccount = (body) => {
  let options = {
    ...globalOptions,
    body,
  }

  Logger.log(TAG, 'createAccount', options)

  return API.post(API_NAME, '/accounts/new', options)
}

export const updateAccount = (body) => {
  let options = {
    ...globalOptions,
    body,
  }

  Logger.log(TAG, 'updateAccount', options)

  return API.post(API_NAME, '/accounts/update', options)
}

export const deleteAccount = (id) => {
  let options = {
    ...globalOptions,
  }

  Logger.log(TAG, 'deleteAccount', options)

  return API.del(API_NAME, `/accounts/object/${id}`, options)
}

/*
 * Bills
 */

export const getBillingByClient = (client) => {
  let options = {
    ...globalOptions,
    body: {
      client
    },
  }

  Logger.log(TAG, 'getBillingByClient', options)

  return API.post(API_NAME, '/getBillingByClient', options)
}

/*
 * Budgets
 */

export const getBudgets = account => {
  let options = {
    ...globalOptions,
    body: {
      account,
    },
  }

  Logger.log(TAG, 'getBudgets', options)

  return API.post(API_NAME, '/budget/myBudgets', options)
}

export const createBudget = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  Logger.log(TAG, 'createBudget', options)

  return API.post(API_NAME, '/budget/new', options)
}

export const updateBudget = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  Logger.log(TAG, 'updateBudget', options)

  return API.post(API_NAME, '/budget/update', options)
}

/*
 * Audiences
 */

export const getAudiences = account => {
  let options = {
    ...globalOptions,
    body: {
      account
    },
  }

  Logger.log(TAG, 'getAudiences', options)

  return API.post(API_NAME, "/audience/myAudiences", options);
}

export const createAudience = body => {
  let options = {
    ...globalOptions,
    body
  }

  Logger.log(TAG, 'createAudience', options)

  return API.post(API_NAME, '/audience/new', options)
}

export const updateAudience = body => {
  let options = {
    ...globalOptions,
    body
  }

  Logger.log(TAG, 'updateAudience', options)

  return API.post(API_NAME, '/audience/update', options)
}

export const deleteAudience = id => {
  let options = {
    ...globalOptions,
  }

  Logger.log(TAG, 'deleteAudience', options)

  return API.del(API_NAME, `/audience/object/${id}`, options)
}

/*
 * Categories
 */

export const getCategories = account => {
  let options = {
    ...globalOptions,
    body: {
      account
    },
  }

  Logger.log(TAG, 'getCategories', options)

  return API.post(API_NAME, '/category/myCategories', options)
}

export const createCategory = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  Logger.log(TAG, 'createCategory', options)

  return API.post(API_NAME, '/category/new', options)
}

export const updateCategory = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  Logger.log(TAG, 'updateCategory', options)

  return API.post(API_NAME, '/category/update', options)
}

export const deleteCategory = id => {
  let options = {
    ...globalOptions,
  }

  Logger.log(TAG, 'deleteCategory', options)

  return API.del(API_NAME, `/category/object/${id}`, options)
}

/*
 * Profile
 */

export const getProfileInfo = (accessToken) => {
  let options = {
    ...globalOptions,
    body: {
      accessToken,
    },
  }

  Logger.log(TAG, 'getProfileInfo', options)

  return API.post(API_NAME, '/profile', options)
}

export const updateProfileInfo = (username, attributes) => {
  let options = {
    ...globalOptions,
    body: {
      username,
      attributes,
    },
  }

  Logger.log(TAG, 'updateProfileInfo', options)

  return API.post(API_NAME, '/updateUser', options)
}

/*
 * Creative
 */


export const getMyFiles = (account) => {
  let options = {
    ...globalOptions,
    body: {
      account,
    },
  }

  Logger.log(TAG, 'getMyFiles', options)

  return API.post(API_NAME, '/creative/myFiles', options)
}

export const associateFile = (id, account) => {
  let options = {
    ...globalOptions,
    body: {
      id,
      account,
    },
  }

  Logger.log(TAG, 'associateFile', options)

  return API.post(API_NAME, '/creative/associateFile', options)
}

export const disassociateFile = (id, account) => {
  let options = {
    ...globalOptions,
    body: {
      id,
      account,
    },
  }

  Logger.log(TAG, 'disassociateFile', options)

  return API.post(API_NAME, '/creative/disassociateFile', options)
}

/*
 * Status
 */

export const getStatusList = () => {
  let options = {
    ...globalOptions,
  }

  Logger.log(TAG, 'getStatusList', options)

  return API.get(API_NAME, '/status', options)
}

export const createStatus = (body) => {
  let options = {
    ...globalOptions,
    body,
  }

  Logger.log(TAG, 'createStatus', options)

  return API.post(API_NAME, '/status/new', options)
}

export const updateStatus = (body) => {
  let options = {
    ...globalOptions,
    body,
  }

  Logger.log(TAG, 'updateStatus', options)

  return API.post(API_NAME, '/status/update', options)
}

export const deleteStatus = (id) => {
  let options = {
    ...globalOptions,
  }

  Logger.log(TAG, 'deleteStatus', options)

  return API.del(API_NAME, `/status/object/${id}`, options)
}

/*
 * Tags
 */
export const getAccountTags = (account) => {
  let options = {
    ...globalOptions,
    body: {
      account,
    },
  }

  Logger.log(TAG, 'getAccountTags', options)

  return API.post(API_NAME, '/tags/myTags', options)
}

export const createAccountTag = (body) => {
  let options = {
    ...globalOptions,
    body,
  }

  Logger.log(TAG, 'createTag', options)

  return API.post(API_NAME, '/tags/new', options)
}

export const updateAccountTag = (body) => {
  let options = {
    ...globalOptions,
    body,
  }

  Logger.log(TAG, 'updateTag', options)

  return API.post(API_NAME, '/tags/update', options)
}

export const deleteAccountTag = (id) => {
  let options = {
    ...globalOptions,
  }

  Logger.log(TAG, 'deleteTag', options)

  return API.del(API_NAME, `/tags/object/${id}`, options)
}

/*
 * Reports
 */

export const getReports = (account) => {
  let options = {
    ...globalOptions,
    body: {
      account,
    },
  }

  Logger.log(TAG, 'getReports', options)

  return API.post(API_NAME, '/report/myReports', options)
}

export const createReport = (body) => {
  let options = {
    ...globalOptions,
    body,
  }

  Logger.log(TAG, 'createReport', options)

  return API.post(API_NAME, '/report/new', options)
}

export const updateReport = (body) => {
  let options = {
    ...globalOptions,
    body,
  }

  Logger.log(TAG, 'updateReport', options)

  return API.post(API_NAME, '/report/update', options)
}

export const deleteReport = (id) => {
  let options = {
    ...globalOptions,
  }

  Logger.log(TAG, 'deleteReport', options)

  return API.del(API_NAME, `/report/object/${id}`, options)
}

/** Facebook **/
/** Facebook **/

export const getApps = () => {
  let options = {
    ...globalOptions,
  }

  Logger.log('API', 'getApps', options)

  return API.post(API_NAMELI, '/facebookApi/apps', options)
}

export const getFanpages = (appId, userToken) => {
  let options = {
    ...globalOptions,
    body: {
      appId,
      userToken,
    }
  }

  Logger.log('API', 'getFanpages', options)

  return API.post(API_NAMELI, '/facebookApi/fanpages', options)
}

export const saveFanpage = (data) => {
  let options = {
    ...globalOptions,
    body: data,
  }

  Logger.log('API', 'saveFanpage', options)

  return API.post(API_NAMELI, '/facebookApi/save-fanpage', options)
}

export const getPages = () => {
  let options = {
    ...globalOptions,
  }

  Logger.log('API', 'getPages', options)

  return API.post(API_NAMELI, '/facebookApi/pages', options)
}

export const getDatosWH = (data) => {
  let options = {
    ...globalOptions,
    body: data,
  }

  Logger.log('API', 'getDatosWH', options)

  return API.post(API_NAMELI, '/facebookApi/datawh', options)
}

export const getUserdata = (appId, userToken) => {
  let options = {
    ...globalOptions,
    body: {
      appId,
      userToken,
    }
  }

  Logger.log('API', 'getUserdata', options)

  return API.post(API_NAMELI, '/facebookApi/userdata', options)
}
