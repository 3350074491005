import { APP_CLEAR_CURRENT_USER } from '../actions/app.actions'
import * as Actions from '../actions/dashboard.actions'

const initialState = {
  total: 0,
  loading: false,
  filter: {
    text: '',
    page: 1,
    paginationPerPage: 10,
    sortDirection: '',
    column: '',
  },
  list: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.CAMPAIGNS_CLEAR:
      return {
        ...state,
        list: [],
        total: 0,
      }
    case Actions.CAMPAIGNS_ADD_ONE:
      return {
        ...state,
        list: [
          ...state.list.filter(
            (campaing) => campaing.id !== action.campaign.id
          ),
          action.campaign,
        ],
      }
    case Actions.CAMPAIGNS_UPDATE_STATUS:
      return {
        ...state,
        list: [
          ...state.list.map((campaing) =>
            campaing.id === action.id
              ? {
                  ...campaing,
                  status: action.status,
                }
              : campaing
          ),
        ],
      }
    case Actions.CAMPAIGNS_CHANGE_TOTAL:
      return {
        ...state,
        total: action.total,
      }
    case Actions.CAMPAIGNS_CHANGE_LOADING:
      return {
        ...state,
        loading: action.loading,
      }
    case Actions.CAMPAIGNS_CHANGE_FILTER_SORT:
      return {
        ...state,
        filter: {
          ...state.filter,
          sortDirection: action.sortDirection,
          column: action.column,
        },
      }
    case Actions.CAMPAIGNS_CHANGE_FILTER_PAGE:
      return {
        ...state,
        filter: {
          ...state.filter,
          page: action.page,
        },
      }
    case Actions.CAMPAIGNS_CHANGE_FILTER_TEXT:
      return {
        ...state,
        filter: {
          ...state.filter,
          text: action.text,
        },
      }
    case Actions.CAMPAIGNS_CHANGE_PAGINATION_PER_PAGE:
    case Actions.CAMPAIGNS_CHANGE_FILTER_PAGINATION:
      return {
        ...state,
        filter: {
          ...state.filter,
          paginationPerPage: action.paginationPerPage,
        },
      }
    case APP_CLEAR_CURRENT_USER:
      return initialState
    default:
      return state
  }
}