import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { showLoader } from 'redux/actions/loader.actions';
import { Auth } from 'aws-amplify';
import * as utils from 'data.js'
import * as APILI from 'helpers/apiLI';
import * as Modal from 'redux/actions/modal.actions'
import { getLogosListByNames, getClassStyleByStatus } from 'myUtil'
import DataTableUtils, {
  Share,
} from '@easygosanet/react-datatable-utils'
import '@easygosanet/react-datatable-utils/dist/style.css'

//components

import SelectorBox from 'components/selector'
import ReactDataTable, { DateRange } from '@easygosanet/react-datatable'
import NewCampaign from 'components/newCampaign'
import Galery from 'components/galery';
import Info from 'components/campaign/info'
import ModalModeration from 'components/modalModeration'
import { CSVLink, CSVDownload } from "react-csv";

//images
import Logo_facebook from './assets/img/logo_facebook.svg'
import Logo_instagram from './assets/img/logo_instagram.png'

//Delivery Column

const DeliveryCol = ({ text, className, onClick }) => (
  <div className={className}>
    <div className={"deliverycampaign" + (text)}>
      {text}
    </div>
  </div>
)

//Tables style


const customStyles = {
  headCells: {

    style: {
      fontWeight: "600",
      color: "#000",
      fontSize: "1rem",
      textAlign: "center",
      justifyContent: "center",

      "&:nth-of-type(1)": {
        fontWeight: "100",
        justifyContent: "left",

      },
      "&:nth-of-type(2n)": {
        backgroundColor: "#E3E4E6",
        fontWeight: "600",
        color: "#000",
        fontSize: "1rem",
        textAlign: "center",
        borderRadius: "10px 10px 0 0",
        maxWidth: "250px !important",
      },

    },

  },

  cells: {

    style: {

      justifyContent: "center",

      "&:nth-of-type(1)": {

        justifyContent: "left",

      },

      "&:nth-of-type(2n)": {
        backgroundColor: "#E3E4E6",
        maxWidth: "250px !important",
      },

    },

  },
}

// const customStyles: TableStyles = {



// };

function matchFilter(text, list) {
  const filter = text.toLowerCase()
  return list.some(item => item.toLowerCase().includes(filter))
}

function getCampaignByCategory(listCampaigns, category) {
  let myCampaigns = []
  listCampaigns.forEach(campaign => {
    campaign.mediaCategories.forEach(categoryE => {
      if (categoryE.name === category) {
        myCampaigns.push(campaign)
      }
    });
  });
  return myCampaigns
}

const Campaign = (props) => {
  const currentUser = JSON.parse(localStorage.getItem('CurrentUser')) ?? [];
  const { userRol } = props;
  const [showModal, setshowModal] = useState(false);
  const [tableCsv, setTableCsv] = useState([]);
  const [showModeration, setshowModeration] = useState(false)
  const [dataModeration, setdataModeration] = useState('')
  const [refresh, setRefresh] = useState(false)
  const [filterText, setFilterText] = useState('')
  const [filteredData, setFilteredData] = useState([])
  const tableCsvData = []
  //const [hasLoaded, setHasLoaded] = useState();
  let [campaings, setCampaings] = useState([]);
  let [window, setWindow] = useState(1);
  let [currentCampaign, setCurrentCampaign] = useState({})
  let [coords, setCoords] = useState({})

  let [categories, setCategories] = useState([]);
  let [hashtags, setHashtags] = useState([]);
  let [tables, setTables] = useState([])
  let [infoCampaign, setInfoCampaign] = useState([])
  //props._showLoader(false)
  console.log("campaings desde log", campaings);

  const goToDetailPage = (camp) => {
    setCurrentCampaign(camp)
    console.log(camp)

    const category = camp.mediaCategories
    setInfoCampaign([
      {
        text: "Estado: ",
        number: camp.delivery.name
      },
      {
        text: "Presupuesto: ",
        number: camp.budget
      },
      {
        text: "Recompensa: ",
        number: camp.reward
      },
      {
        text: "Inscripcion: ",
        number: camp.inscription
      },
      {
        text: "Fecha de Inicio: ",
        number: camp.date.start
      },
      {
        text: "Fecha de Finalización: ",
        number: camp.date.end
      }
    ])
    setCategories(category.map(categ => ({
      name: categ.name,
      operador: categ.operador,
      quantity: categ.quantity,
      uid: categ.uid
    })))
    setHashtags(camp.hastags.map(hash => ({
      text: hash.name,
      number: ""
    })))
    setCoords(camp.locationList.map(data => ({
      text: data.locationList,
      number: ""
    })))
    setWindow(0);
  }

  const showModerationPopup = (data) => {
    setshowModeration(true)
    let objeto = {
      "title": data.name,
      "date": data.date,
      "id": data.id,
      "name": data.name,
      "source": data.source,
      "username": data.userID,
    }
    setdataModeration(objeto)

  }

  const saveLog = async (body) => {
    
    props._showLoader(false);
    try {
      APILI.createNewLog(body).then(res => {
        console.log('Log Creado')
      }).catch(err => {
      console.log('err', err) 
      })
      
  } catch (err) {
      console.log("No se pudo guardar el log", err)
  }
  }

  const saveErrorLog = async (body) => {
    try {
      APILI.createNewErrorLog(body).then(res => {
        console.log('Error log Creado')
      }).catch(err => {
      console.log('err', err) 
      })
      
  } catch (err) {
      console.log("No se pudo guardar el log de error", err)
  }
  }
  
  
  const removeCampaing = (camp) => {
    console.log("camp to delete",camp)
    console.log("remove state",camp.id)
    props._showAlertModal('Eliminar Campaña?', `Esta seguro que desea eliminar la campaña`, Modal.MODAL_TYPE_CONFIRMATION, () => {
        APILI.deleteCampaing({id: camp.id}).then(res => {
          props._showLoader(true);
          const bodyLog = {
            Tipo: "Delete",
            Accion: "Eliminar campaña",
            Data: {"id":camp.id, "name":camp.name},
            Historico: camp,
            CodeHttp:  res.status,
            UsuarioId: currentUser.username,
            UsuarioName: currentUser.attributes.name,
            Email: currentUser.attributes.email,
        }
        saveLog(bodyLog)
        console.log('Eliminado')
        setRefresh(!refresh)
      }).catch(err => {

        const bodyLog = {
          Tipo: "Delete error",
          Accion: "Eliminar campaña",
          Data: {"id":camp.id, "name":camp.name},
          Historico: camp,
          CodeHttp:  err,
          UsuarioId: currentUser.username,
          UsuarioName: currentUser.attributes.name,
          Email: currentUser.attributes.email,
      }
      saveErrorLog(bodyLog)

      console.log('err', err) 
      })
      
    })
  }

  const DetailCol = ({ text, Camp }) => (
    <div className="firstColl w-100">
      <div className="icon-container">
        <i className="fas fa-eye" onClick={e => {
          goToDetailPage(Camp)
        }}></i>
        <i onClick={() => showModerationPopup(Camp)} className="fas fa-edit"></i>
        <i className="fas fa-trash" onClick={() => removeCampaing(Camp)}
        ></i>
        <i className="fab fa-telegram-plane"></i>
        <i className="fas fa-ellipsis-v"></i>
      </div>
      <p className="name-fc">
        {text}
      </p>
    </div>
  )

  const getTableCsv = (data) => {
    let tempArray = [];
    data.forEach(
      dat => {
        let tempCategory = '';
        dat.mediaCategories.forEach(cat => {
          tempCategory = tempCategory.concat(cat.name).concat('/')
        })
        tempArray.push({
          campaingName: dat.name,
          status: dat.state,
          budget: dat.budget,
          initDate: dat.initDate,
          endDate: dat.endDate,
          socialNetworks: dat.socialNetworks[0].name,
          cpm: dat.CPM,
          categories: tempCategory
        })
      })
    setCampaings(tempArray)
  }
  useEffect(() => {
    setWindow(1)
    Auth.currentAuthenticatedUser()
      .then((userData) => {
        APILI.getCampaignByUser({ account: userData.username })
          .then(camp => {
            console.log("userData.username ", userData.username)
            console.log("campaings ", camp)
            APILI.getMediaCategories().then(res => {
              setCategories(res.data.categories)
              getInfoView(res.data.categories, camp.data.campaigns)
              getTableCsv(camp.data.campaigns)
              props._showLoader(false)
            })
          })
      })
  }, [refresh]);

  useEffect(() => {
   // props._showLoader(true)
  }, [])

  function formatNumber(number) {
    return new Intl.NumberFormat("en-US", {style: 'currency',currency: 'USD', minimumFractionDigits: 2}).format(number)
  }

  const getInfoView = (categ, camp) => {

    setTables(
      categ.map(categorie => ({
        titles: [
          {
            name: categorie.name,
            selector: (row) => row.name,
            sortable: true,
            minWidth: '300px',
            style: {
              color: '#05252D'
            },
            cell: (row) => (
              <DetailCol
                text={row.name}
                Camp={row}
              />
            ),
          },
          {
            name: 'Entrega',
            selector: (row) => row.delivery,
            sortable: true,
            center: true,
            minWidth: '90px',
            style: {
              backgroundColor: '#E3E4E6',
            },
            cell: (row) => (
              <DeliveryCol
                text={row.delivery.name}
                className={row.delivery.className}
              />
            )
          },
          {
            name: 'Presupuesto',
            selector: (row) => formatNumber(row.budget),
            sortable: true,
            center: true,
            minWidth: '150px',
            style: {
              fontWeight: 'bold',
            },
          },
          {
            name: 'Recompensa',
            selector: (row) => formatNumber(row.reward),
            sortable: true,
            center: true,
            minWidth: '150px',
            style: {
              backgroundColor: '#E3E4E6',
              fontWeight: 'bold',
            },
          },
          {
            name: 'Rango de fecha',
            selector: (row) => row.date,
            sortable: true,
            center: true,
            minWidth: '210px',
            style: {
              fontWeight: 'bold',
            },
            type: "text",
            cell: (row) => (
              <DateRange
                start={new Date(getDate(row.date.start))}
                end={new Date(getDate(row.date.end))}
                format='dd/mm/yyyy'
              />
            )
          },
          {
            name: 'Medio',
            selector: (row) => row.source,
            sortable: true,
            center: true,
            style: {
              backgroundColor: '#E3E4E6',
              fontWeight: 'bold',
            },
            cell: (row) =>  <div>
              {row.source.map((rowa, i) => (
                <img key={i} className="source-img" src={rowa} alt="" />
              ))}
            </div>
          },
          {
            name: 'Inscripcion',
            selector: (row) => formatNumber(row.inscription),
            sortable: true,
            center: true,
            style: {
              fontWeight: 'bold',
            },
          }
        ],
        data: getCampaignByCategory(camp, categorie.name).map(campaign => ({
          campOriginal: campaign,
          name: campaign.name,
          delivery: getClassStyleByStatus(campaign.state),
          budget: "$" + parseFloat(campaign.budget),
          reward: campaign.budget,
          date: {
            start: campaign.initDate,
            end: campaign.endDate
          },
          source: getLogosListByNames(campaign.socialNetworks),
          inscription: (campaign.CPM),
          hastags: campaign.hastags,
          description: campaign.description,
          logo: campaign.logo,
          postImages: campaign.postImages,
          locationList: campaign.locationList,
          mediaCategories: campaign.mediaCategories,
          id: campaign.id,
          ageValue: campaign.ageValue,
          advertiser: campaign.advertiser,
          CPM: campaign.CPM,
          budget: campaign.budget,
          activity: campaign.activity,
          totalAlcance: campaign.totalAlcance,
          totalInteraction: campaign.totalInteraction,
          totalProfit: campaign.totalProfit,
          totalRemainder: campaign.totalRemainder,
          totalCreatividad: campaign.totalCreatividad,
          state: campaign.state,
          slogan: campaign.slogan,
          reach: campaign?.reach ? campaign.reach : 0,
          nonMediaCategories: campaign.nonMediaCategories,
          socialStatusValue: campaign.socialStatusValue,
          initDate: campaign.initDate,
          endDate: campaign.endDate,
        }))
      }))
    )
  }

  const handleFilterTextChange = (value) => {
    setFilterText(value)
  }

  useEffect(() => {
    console.log("tables de mario",tables)
    const filtered = tables.map(table => table.data.filter((item) =>
      matchFilter(filterText, [
        item.name,
        item.budget,
        item.reward,
        item.inscription,
        item.delivery.name,
      ])
    )
    )
    setFilteredData(filtered)
  }, [filterText, tables])

  const changeWindow = (number) => {
    setWindow(number);
  }

  function getDate(initDate) {

    var initial = initDate.split(/\//);
    var date = initial[1] + '/' + initial[0] + '/' + initial[2]
    // console.log("fechassss: ", date)
    return date;
  }

  if (window === 0) {
    return (
      <Info 
        userRol={userRol} 
        refresh={refresh} 
        setRefresh={(dat) => setRefresh(dat)} 
        setWindow={(data) => setWindow(data)} 
        currentCampaign={currentCampaign} 
        infoCampaign={infoCampaign} 
        categories={categories} 
        hashtags={hashtags} 
        coords={coords} 
        changeWindow={changeWindow} 
      />
    )
  }

  return (

    <div className='form-dashboard'>
      {showModeration ?
        <ModalModeration functionName={"campaign"} data={dataModeration} showModeration={setshowModeration} />
        :
        null}
      <div id='shadow' className='shadow hide'></div>
      <div className='Bar-content Bar-contentCampaign'>
        <div className='content-menu list'>
          <select
            id='viewSelect'
            className='select-dashboard fa'
          >
            <option className='fa' value='1'>
              {' '}
              &#xf0ce; Vista de campañas
            </option>
            {/* <option className='fa' value='0'>
              {' '}
              &#xf0db; Vista de Kanban{' '}
            </option>
            <option className='fa' value='2'>
              {' '}
              &#xf073; Vista de calendariox
            </option> */}
          </select>
          < DataTableUtils className='container-btn d-flex' onChangeFilterText={handleFilterTextChange}>
            {/* <Share className="fist-bt"
              contacts={utils.myContacts}
            /> */}


            <a id="colum-btn" className="btn-dashboard btnCsv" >
              {/* {tables.map(table => ( */}
              <CSVLink
                data={campaings}
                //  headers={table.titles}  
                className="btn-csv">
                  <i className='fas fa-share-square'></i>
              </CSVLink>
              {/* ))} */}
              <span className='span-csv'>Descargar CSV</span>
            </a>

            {/* <a id="colum-btn" className="btn-dashboard" href="#">
            <i class="fas fa-share-square"></i>
          </a> */}
            {/* <a id="colum-btn" className="btn-dashboard" href="#">
              <i className="fas fa-columns"></i>
            </a>
            <a id="filter-btn" className="btn-dashboard" href="#">
              <i className="fas fa-filter"></i>
            </a> */}
          </DataTableUtils>
          {userRol === "admin" ?
          <div
            className='btnOrange-dash' onClick={e => setshowModal(true)}>
            <i className='fas fa-plus'></i>
          </div> : ""
        }
        </div>
      </div>

      {


        tables.map((table, idx) => (
          <div key={idx} className="row-section" hidden={filterText && filteredData[idx].length === 0}>
            <ReactDataTable
              columns={table.titles}
              customStyles={customStyles}
              data={filteredData[idx]}
              paginationComponentOptions={{
                rowsPerPageText: 'Elementos por página',
                rangeSeparatorText: 'de',
              }}
              noDataComponent={"No hay datos para mostrar"}
              persistTableHead
              noHeader
            />



          </div>
        ))}
      {showModal ? <NewCampaign title="Crear nueva campaña" showModal={setshowModal} refresh={refresh} setRefresh={(dat) => setRefresh(dat)} /> : <div />}
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  _showLoader: show => dispatch(showLoader(show)),
  _showAlertModal: (title, message, type, callback) => dispatch(Modal.showAlertModal(title, message, type, callback)),

})

const mapStateToProps = (state) => ({
  isLoading: state.app.isLoading,
  myUser: state.users.myUser
})

export default connect(mapStateToProps, mapDispatchToProps)(Campaign)
