import React, {useState, useEffect} from 'react';
import { Auth, Hub } from 'aws-amplify';
import NativeSelect from '@material-ui/core/NativeSelect';
import "react-google-flight-datepicker/dist/main.css";
import { useDispatch, connect } from 'react-redux';
import { showLoader } from 'redux/actions/loader.actions';
import InputModal from './inputModal';
import * as Modal from 'redux/actions/modal.actions'
import * as APILI from 'helpers/apiLI';
import * as STORAGE from 'helpers/storageLI';

import Switch from '@material-ui/core/Switch';
import { v4 } from 'uuid'
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import { getLogoByName, getClassNameByStatus } from 'myUtil'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import moment from 'moment/moment.js'
import IconButton from '@mui/material/IconButton';
import LaunchIcon from '@mui/icons-material/Launch';
import TextField from '@material-ui/core/TextField'
import { FacebookProvider, EmbeddedPost } from 'react-facebook';
 import PostEmbed from 'components/embedPost'
import ShowSocialMedia from '../showSocialMedia'
import PostAddIcon from '@material-ui/icons/PostAdd';
import { DateTimePicker } from "@material-ui/pickers";
import Dropzone from 'libs/dropzone';
import * as Logger from 'utils/logger';
import Paper from '@mui/material/Paper';
import Typography from '@material-ui/core/Typography';

import './styles.css';
import { StorageGateway } from 'aws-sdk';
 
const TAG = 'Images'
const PageModal = ({ userRol, page, showModal,refresh,setRefresh, _showAlertModal }) => {
    const [contentPage,setContentPage]= useState([])
    const [contentError, setContentError]= useState([])
    const [finalContent,setFinalContent]= useState([])
    const [titlePage, setTitlePage]= useState('')
    const [logo, setLogo] = useState([])
    const [postImages, setPostImages] = useState([])
    const [select,setSelect]= useState('Inicio')
    
    const dispatch = useDispatch()
  
    
    useEffect(()=>{
       getData()
       
    },[]) 
    const getData= ()=>{
        if(page){
            console.log('paginaRecibida', page);
        setTitlePage(page.title)
        setSelect(page.target)
        setContentPage(page.content)
        if(page.logo.length>0){
            STORAGE.getUrlImage(page.logo[0].name).then(res=>{
                setLogo([{
                    link: res.toString(),
                    name:  page.logo[0].name
                }])
            })
            
        }
        if(page.banner.length>0){
            STORAGE.getUrlImage(page.banner[0].name).then(res=>{
                setPostImages([{
                    link: res.toString(),
                    name:  page.banner[0].name
                }])
            })
        }
        if(page.content){
                if(page.id==='pageTerms'){
                    console.log('Pagina términos', page)
                }else{
                    console.log('pageContent',page.content)
            const auxArray=[]
            for( const pag of page.content){
                if(pag.imgBackground!==''){
                    STORAGE.getUrlImage(pag.imgBackground.name).then(res=>{
                        let object={
                            id: pag.id,
                            title: pag.title,
                            description: pag.description,
                            imgBackground: {
                                link: res.toString(),
                                name:  pag.imgBackground.name
                            }
                        }
                        auxArray.push(object)
                        
                    })
                }else{
                    let object={
                        id: pag.id,
                        title: pag.title,
                        description: pag.description,
                        imgBackground: {
                            link: '',
                            name:  pag.imgBackground.name
                        }
                    }
                    auxArray.push(object)
                    
                }
                }
            
                console.log('ContentArray',auxArray)
            
                setContentPage(auxArray)  
                console.log('contentPage',contentPage)
            }
            
        }

        }
        if(page.contentError){
            setContentError(page.contentError)
        }
        
    }
    const handleFileChange = files => {
        console.log(files);
    }
    const handleUpload = async files => {
        dispatch( showLoader(true) )
        
        const logoArr = logo.map(image => (image))
        console.log(logoArr)
        //STORAGE.uploadFile(files)
        for (const file of files) {
            let options = {
                contentType: file.type,
                progressCallback(progress) {
                Logger.log(TAG, `uploaded: ${progress.loaded}/${progress.total}`)
                }
            }
    
            const uploadedFile = await STORAGE.uploadFile(file.name, file, options)
            const url = await STORAGE.listFiles(uploadedFile.key)
            const newLogo = {
                name: uploadedFile.key,
                link: url
            }
            logoArr.push(newLogo)
            Logger.log(TAG, newLogo)
        }
        if (logoArr.length > 0) {
            setLogo(logoArr)    
        }
        dispatch( showLoader(false) )
    }

    const handleUploadPostImages = async files => {
        dispatch( showLoader(true) )
        const imagesArr = postImages.map(image => (image))
        //STORAGE.uploadFile(files)
        for (const file of files) {
           if(file.link){
            imagesArr.push(file)
           }else{
            let options = {
                contentType: file.type,
                progressCallback(progress) {
                Logger.log(TAG, `uploaded: ${progress.loaded}/${progress.total}`)
                }
            }
    
            const uploadedFile = await STORAGE.uploadFile(file.name, file, options)
            const url = await STORAGE.listFiles(uploadedFile.key)
            const newImage = {
                name: uploadedFile.key,
                link: url
            }
            console.log("NEWIMAGE::: ",newImage)
            imagesArr.push(newImage)
            Logger.log(TAG, newImage)
           }
        }
        if (imagesArr.length > 0) {
            setPostImages(imagesArr)    
        }
        dispatch( showLoader(false) )
    }

    const deleteLogoLoader = image => {
        console.log(image)
        const newArr = logo.filter(img => img.name !== image.name)
        setLogo(newArr)
    }
    const deleteImageLoader = image => {
        console.log(image)
        const newArr = postImages.filter(img => img.name !== image.name)
        setPostImages(newArr)
    }

    const savePage = async ()=> {
        console.log('data error', contentError)
        try {
            dispatch( showLoader(true) )
        const imagesArr = []
        for (const page of contentPage) {
           if(page.imgBackground.link){
            imagesArr.push({
                name: page.imgBackground.name,
                link: page.imgBackground.link
            })
           }else{
            let options = {
                contentType: page.imgBackground.type,
                progressCallback(progress) {
                Logger.log(TAG, `uploaded: ${progress.loaded}/${progress.total}`)
                }
            }
    
            const uploadedFile = await STORAGE.uploadFile(page.imgBackground.name, page.imgBackground, options)
            const url = await STORAGE.listFiles(uploadedFile.key)
            const newImage = {
                name: uploadedFile.key,
                link: url
            }
            console.log("NEWIMAGE::: ",newImage)
            imagesArr.push(newImage)
            Logger.log(TAG, newImage)
           }
        }
        if (imagesArr.length > 0) {
            console.log('imageArray', imagesArr)
        }
        dispatch( showLoader(false) )
        setRefresh(!refresh)    
        let auxArray= contentPage
        for (let i=0; i<imagesArr.length; i++){
           auxArray[i].imgBackground= imagesArr[i]
        }
        setFinalContent(auxArray)
        } catch (error) {
            
        }
        let body= {
            id: page.id,
            target: select,
            title: titlePage,
            createdAt: new Date(),
            logo: logo,
            banner: postImages,
            content: contentPage,
            contentError: contentError
        }
        console.log('enviar Data', body)
        APILI.savePage(body).then(res=>{
            console.log('resDataPage', res)
            cleanDataPage()
        }).catch(err => {console.log('errDataPage', err)})
    }
    const cleanDataPage= ()=>{
        showModal(false)
        setContentPage([])
        
        dispatch( showLoader(false) )
        setLogo([])
        setPostImages([])
    }

    const addContent=()=>{
        setContentPage([...contentPage,{
            id: v4(),
            title: '',
            description: '',
            imgBackground: '',
        }])
    }

    const addContentError=()=>{
        setContentError([...contentError,{
            id: v4(),
            title: '',
            description: '',
        }])
    }

    const handleChangeTitle=(id, title)=>{
        let array=[]
        console.log('pageid',id)
        contentPage.forEach(page=>{
            console.log('comp',id,page.id)
            if(page.id=== id)
            {
                let object = {
                    id: page.id,
                    title: title,
                    description: page.description,
                    imgBackground: page.imgBackground,
                }
                array.push(object)
            }else{
                array.push(page)
            }
            setContentPage(array)
        })
    }
    
    const handleChangeTitleError=(id, title)=>{
        let array=[]
        console.log('pageid',id)
        contentError.forEach(page=>{
            console.log('comp',id,page.id)
            if(page.id=== id)
            {
                let object = {
                    id: page.id,
                    title: title,
                    description: page.description,
                }
                array.push(object)
            }else{
                array.push(page)
            }
            setContentError(array)
        })
    }
  /*  const getNewTitle = (event)=>{
        setTitlePage(event.replace(" ", "_"))

    }
    */
    const refreshSetPage=()=>{
        setContentPage([...contentPage])
    }
   
    const handleChangeDescription=(id, description)=>{
        let array=[]
     
        contentPage.forEach(page=>{
            console.log('comp',id,page.id)
            if(page.id=== id)
            {
                let object = {
                    id: page.id,
                    title: page.title,
                    description: description,
                    imgBackground: page.imgBackground,
                }
                array.push(object)
            }else{
                array.push(page)
            }
            setContentPage(array)
        })
    }
    
    const handleChangeDescriptionError=(id, description)=>{
        let array=[]
     
        contentError.forEach(page=>{
            console.log('comp',id,page.id)
            if(page.id=== id)
            {
                let object = {
                    id: page.id,
                    title: page.title,
                    description: description,
                }
                array.push(object)
            }else{
                array.push(page)
            }
            setContentError(array)
        })
    }

    const setHandleImage= (id, image)=>{
        let array=[]
        console.log('handleImage',image)
        contentPage.forEach(page=>{
            
            if(page.id=== id)
            {
                let object = {
                    id: page.id,
                    title: page.title,
                    description: page.description,
                    imgBackground: image,
                }
                array.push(object)
            }else{
                array.push(page)
            }
            setContentPage(array)
        })
    }
    const deleteContent= (id)=>{
        let array = contentPage.filter(dat=>dat.id!==id)
        setContentPage(array)
    }

    const deleteContentError=(id)=>{
        let array = contentError.filter(dat=>dat.id!==id)
        setContentError(array)
    }
    

    return (
        <div className="blur-div">
            <div className="content-modal firstmodal">
                <div className="closeModal-content">
                    
                    <i className="fas fa-times-circle" onClick={e => showModal(false)}></i>
                </div>
                <div>
                    <h2 className="titleModal">{titlePage ? titlePage : page.title}</h2>
                    
                </div>
                <div className="body-modal">
                <div className="part-BodyModal">
                    
                    <input placeHolder="Ingrese título de página" value={titlePage} onChange={(event) => setTitlePage(event.target.value)} className="w-90 form-control" />
                    <div styles={{display: 'flex', flexDirection: 'column',float: 'right'}}>
                    <p className='pb-2'>Contenido multimedia y texto</p>
                    <span className='btnSysPlus' onClick={()=>addContent()}><i className='fas fa-plus'></i></span>
                    <span className='btnSysEye' onClick={()=>refreshSetPage()}><i className='fas fa-eye'></i></span>
                    </div>
                    { contentPage.map(data=>(
                        <>
                        <hr/>  
                        <div styles={{display: 'flex', flexDirection: 'column',float: 'right'}}>
                        <i className="fas fa-times-circle text-danger" onClick={() => deleteContent(data.id)}></i>
                        </div>

                        <input type="text" placeHolder={`Ingresa título`} className="w-90 mb-2 form-control" value={data.title} onChange={(e)=>handleChangeTitle(data.id,e.target.value)}  />
                        <input type="text" placeHolder={`Ingresa una descripción`} className="w-90 form-control" value={data.description} onChange={(e) => handleChangeDescription(data.id, e.target.value)} />
                        { page.id==='pageTerms' ? 
                            <>
                            <label for="avatar">Archivo:</label>
                    
                    {
                        <>
                        {data.imgBackground?.name && <h5>{data.imgBackground?.name}</h5>}
                        <input type="file"
                        id="avatar" name="avatar"
                        accept="application/pdf" onChange={e=> setHandleImage(data.id,e.target.files[0])}></input>
                        </>
                    }
                        </>
                        :
                            <>
                                <label style={{ display: 'flex', paddingTop: '10px' }} for="avatar">Imagen:</label>
                        
                        {
                            <>
                            {data.imgBackground?.link && <img style={{width:"100px", height:"100px", display: 'flex'}} className="imageLoader" alt="imagen" src={data.imgBackground?.link}/>}
                                <input type="file"
                            id="avatar" name="avatar"
                            accept="image/png, image/jpeg" onChange={e=> setHandleImage(data.id,e.target.files[0])}></input>
                            </>
                        }
                            </>
                        }
                        
                        
                        </>
                    ))}
                    
                </div>
     
                <div className="part-BodyModal">
                <div className="imageSetter-content">
                            <p>Logo</p>
                            <Dropzone onUpload={handleUpload} onChange={handleFileChange}/>
                            <div className="content-section">
                                {logo.map(log =>(
                                    <div className="imageLoaderContent">
                                        <i className="fas fa-times-circle deleteImageLoader" onClick={e => deleteLogoLoader(log)}></i>
                                        <img className="imageLoader" src={log.link}></img>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="imageSetter-content">
                            <p>Banner</p>
                            <Dropzone onUpload={handleUploadPostImages} onChange={handleFileChange}/>
                            <div className="content-section">
                                {  postImages.map(image =>(
                                    <div className="imageLoaderContent">
                                        <i className="fas fa-times-circle deleteImageLoader" onClick={e => deleteImageLoader(image)}></i>
                                        <img className="imageLoader" src={image.link}></img>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <p>Plataforma</p>
                        <Paper elevation={4} style={{marginTop:"20px"}}>
                                <NativeSelect  className="w-90"  value={select} onChange={(e)=> setSelect(e.target.value)}>
                                <option value="Inicio" disabled>Inicio</option>
                           <option value="App">App</option>
                           <option value="Web">Web</option>
                                </NativeSelect>
                                </Paper>
                        <p className='pb-2'>Mensajes de error</p>
                        <div styles={{display: 'flex', flexDirection: 'column',float: 'right'}}>
                        <span className='btnSysPlus' onClick={()=>addContentError()}><i className='fas fa-plus'></i></span>
                        {/* <i className='fas fa-eye' onClick={()=>refreshSetPage()}></i> */}
                        </div>
                        { contentError.map(data=>(
                        <>
                        <hr/>  
                        <div styles={{display: 'flex', flexDirection: 'column',float: 'right'}}>
                        <i className="fas fa-times-circle text-danger" onClick={() => deleteContentError(data.id)}></i>
                        </div>

                        <input type="text" placeHolder={`Ingresa título`} className="w-90 form-control mb-2" value={data.title} onChange={(e)=>handleChangeTitleError(data.id,e.target.value)}  />
                        <input type="text" placeHolder={`Ingresa una descripción`} className="w-90 form-control" value={data.description} onChange={(e) => handleChangeDescriptionError(data.id, e.target.value)} />
                        </>
                    ))}
                </div>
              
                
            </div>
            <div className="footer-Modal">
                    <div className="buttons-Modal-content">
                        <button onClick={e => showModal(false)}>Cancelar</button>
                        <button onClick={e =>   savePage()}>Guardar</button>
                    </div>
            </div>
        </div>
        </div>
    )
}


export default PageModal