import React from 'react'

// components
import RegisterForm from '../../components/auth/register'

const Register = props => (
  <>
    <RegisterForm {...props}/>
    {/* <SignInForm {...props} /> */}
  </>
)

export default Register
