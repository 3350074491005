import { find } from 'lodash'

import * as API from 'helpers/api'
import * as Logger from 'utils/logger'
import * as Modal from './modal.actions'
import { showLoader } from './loader.actions'

export const PROFILE_ADD_INFO = 'PROFILE:ADD:INFO'

const TAG = 'PROFILE:ACTION'

export const getProfileInfo = token => dispatch => {
  dispatch(showLoader(true))
  API.getProfileInfo(token)
    .then(response => {
      Logger.log(TAG, 'getProfileInfo', response)
      let attributes = response.data.data.UserAttributes

      let name = find(attributes, { 'Name': 'name' })
      let email = find(attributes, { 'Name': 'email' })
      let phone = find(attributes, { 'Name': 'phone' })
      let country = find(attributes, { 'Name': 'country' })

      dispatch({
        type: PROFILE_ADD_INFO,
        name: name ? name.Value : '',
        email: email ? email.Value : '',
        phone: phone ? phone.Value : '',
        country: country ? country.Value : '',
      })
    })
    .catch(err => {
      Logger.log(TAG, 'getClients', err)
    })
    .then(() => {
      dispatch(showLoader(false))
    })
}

export const save = (username, attributes) => dispatch => {
  dispatch(showLoader(true))
  API.updateProfileInfo(username, attributes)
    .then(response => {
      Logger.log(TAG, 'updateProfileInfo', response)
      let { success } = response.data

      if (success) {
        dispatch(Modal.showAlertModal('Perfil Actualizado', 'La información fue actualizada exitosamente.', Modal.MODAL_TYPE_SUCCESS, () => {
          dispatch(getProfileInfo(username))
        }))
      }
    })
    .catch(err => {
      Logger.log(TAG, 'updateProfileInfo', err)
    })
    .then(() => {
      dispatch(showLoader(false))
    })
}