import React, { useState, useEffect } from 'react'

import * as APILI from 'helpers/apiLI';

import './asserts/styles.css'
import { getLogoByName, getClassNameByStatus } from 'myUtil'
import Divider from '@material-ui/core/Divider';

import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import IconButton from '@mui/material/IconButton';
import Typography from '@material-ui/core/Typography';
import { createTheme } from '@mui/material/styles';
import Swal from 'sweetalert2'
import StarsShow from '../starsShow'

const TargetContentTasks = (props) => {

    const { approved, rejected, Pendiente, rating, completed, scope, interaction } = props.statistics

    const { count, completeCampaign, campaignState, reach, changeCampaignState, userRol } = props
    const nameClass = props.nameClass;

    console.log('desde Target ContentTask', userRol)


    const ApproveTask = () => {
        let completeTask = completed === count && count > 0 ? true : false
        let hidde = campaignState === 'Pendiente' || campaignState === 'Completed' ? true : false
        return (
            <><div style={{ display: 'flex' }}>{!hidde ?
                <>

                    {completeTask && scope / reach >= 1 ? <>

                        <button className="boton" style={{ background: "#00CA72" }} onClick={() => completeCampaign()}> Aprobar </button>
                    </> : <button className="botonDisabled" disabled style={{ backgroundColor: "#808080" }}> Aprobar </button>
                    } </> :
                <button className="botonDisabled" disabled style={{ backgroundColor: "#808080" }}> Aprobar </button>}
                <button style={{ display: 'block' }} className="boton" style={{ backgroundColor: "orangered" }} onClick={() => changeCampaignState('Holded')}> Pausar </button>
                <button className="boton" style={{ backgroundColor: "red" }} onClick={() => changeCampaignState('Rejected')}> Rechazar </button>
                <button className="boton" style={{ backgroundColor: "blue" }} onClick={() => changeCampaignState('Completed')}>Finalizar </button>
            </div>
            </>
        )
    }

    const DisableTask = () => {
        let completeTask = completed === count && count > 0 ? true : false
        let hidde = campaignState === 'Pendiente' || campaignState === 'Completed' ? true : false
        return (
            <>
                <div style={{ display: 'flex' }}>
                    <button className="botonDisabled" disabled style={{ backgroundColor: "#808080" }}> Aprobar </button>
                    <button className="botonDisabled" disabled style={{ backgroundColor: "#808080" }}> Pausar </button>
                    <button className="botonDisabled" disabled style={{ backgroundColor: "#808080" }}> Rechazar </button>
                    <button className="botonDisabled" disabled style={{ backgroundColor: "#808080" }}>Finalizar </button>
                </div>
            </>
        )
    }


    const RenderText = () => {
        return (
            <div className="container flex-column" style={{ flexdirection: "column-reverse" }}>
                <div className="row" style={{ display: "inline-block" }}>
                    <Typography variant="body2" gutterBottom style={{ margin: "15px" }}>
                        <span style={{ color: "#04ADBF" }}>Tareas: {count} </span>
                        <span style={{ marginLeft: "15px", color: "#04ADBF" }}>Aprobadas: {approved} </span>
                        <span style={{ marginLeft: "15px", color: "#04ADBF" }}>Completadas: {completed} </span>
                        <span style={{ marginLeft: "15px", color: "#04ADBF" }}>Rechazadas: {rejected} </span>

                        <span style={{ marginLeft: "15px", color: "#04ADBF" }}>Score: {rating}</span>
                        <span style={{ marginLeft: "15px", color: "#04ADBF" }}>Interaction: {interaction} </span>
                        <span style={{ marginLeft: "15px", color: "#04ADBF" }}>Reach: {reach}/{scope} </span>
                        <div>
                            <div style={{ width: '100%', backgroundColor: '#ddd' }} >
                                <div style={{
                                    width: scope / reach > 1 ? '100%' : `${scope / reach * 100}%`,
                                    height: '20px',
                                    backgroundColor: '#04AA6D',
                                    textAlign: 'center',
                                    lineHeight: '20px',
                                    color: 'white'
                                }}>{scope / reach > 1 ? '100%' : `${scope / reach * 100}%`}</div>
                            </div>
                        </div>



                    </Typography>

                </div>
                <div className="" style={{ width: "100%" }} >

                    {
                        userRol === 'admin' ?
                            <ApproveTask style={{ margin: "0 auto" }} />
                            :
                            <DisableTask style={{ margin: "0 auto" }} />
                    }
                </div>
            </div>
        )
    }
    return (
        <div className={nameClass}>
            <div >
                <RenderText />
            </div>
        </div>
    )

}

export default TargetContentTasks