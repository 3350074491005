import React from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import './style/style.css'
// pages
import MenuPage from './menu'
import AsociarPage from './asociar'
import DatosPage from './datos'
import DatosWB from './datoswh'
import Mensajes from './message'

const ScanPage = props => {

    const {userRol} = props;
  return (
        <>
        {userRol==='admin'?
        <Switch>
            <Route path={`${props.match.path}`} component={MenuPage} exact />
            <Route path={`${props.match.path}/asociar`} component={AsociarPage} exact />
            <Route path={`${props.match.path}/datos`} component={DatosPage} exact />
            <Route path={`${props.match.path}/datos-web`} component={DatosWB} exact />
            <Route path={`${props.match.path}/mensajes`} component={Mensajes} exact />
            <Route path="*">
            <Redirect to={`${props.match.path}`} />
            </Route>
        </Switch>
        :
        <h1>No tienes privilegios</h1>
        }
        </>
        ) 
}

export default withRouter(ScanPage)