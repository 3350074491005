import React, { useState, useEffect } from 'react'
import * as utils from 'data.js'
import _ from 'lodash'
import DataTableUtils, {
    Share,
    ShowColumn,
    FilterColumn
} from '@easygosanet/react-datatable-utils'
import ReactDataTable from '@easygosanet/react-datatable'
import * as APILI from 'helpers/apiLI';
import { Auth, Hub } from 'aws-amplify';
import Swal from 'sweetalert2/dist/sweetalert2.js'

//Components
import ImageBox from 'components/imageBox'
import GraphBox from 'components/graphBox'
import TargetContent from 'components/targetContent'
import { getLogoByName, getClassNameByStatus } from 'myUtil'
import AddProfile from 'components/addProfile'

//Charts
import GenderChart from 'components/genderChart'
import PieChart from 'components/pieChart'

//images
import Logo_facebook from './assets/img/logo_facebook.svg'
import Logo_instagram from './assets/img/logo_instagram.png'
import Logo_tiktok from './assets/img/logo_tiktok.svg'
import logo_whatsapp from './assets/img/whatsapp.png'
import orderBy from'lodash/orderBy'
//comment by LM 23/07/2021
//import NewAdvertiser from 'components/newAdvertiser'


const ProfileList = (props) => {

    let [campaigns, setCampaigns] = useState([]);
let [influencers, setInfluencers] = useState([]);

const[tables,setTables]= useState([]);
const [filteredData,setFilteredData]= useState([])
const [filteredDataNormal,setFilteredDataNormal]= useState([])
const [campaingFacebook, setCampaingFacebook] = useState(0);
const [campaingInstagram, setCampaingInstagram] = useState(0);
const [influencersData, setinfluencersData] = useState([]);
const [campaingTikTok, setCampaingTikTok] = useState(0);
const [gender,setGender] = useState([]);
const [campaingLinkedin, setCampaingLinkedin] = useState(0);
const [showModal, setShowModal] = useState(false);
const [userStatusArray, setuserStatusArray] = useState([])
const [userAgeArray, setuserAgeArray] = useState([])
const [showAdvertiser, setShowAdvertiser] = useState(false);
const [generalUserStates, setGeneralUserStates] = useState('');
const [generalGenderState, setGeneralGenderState] = useState('')
const [campaignsByState, setCampaignsByState] = useState([]);
const [generalAgeState, setGeneralAgeState] = useState('')
const [countProfiles, setCountProfiles] = useState(0)
const [countInfluencers, setCountInfluencers] = useState(0)
const [countPendienteProfiles, setCountPendienteProfiles] = useState(0)
const [countFanPages, setCountFanPages] = useState(0)
const [scopeFanPages, setScopeFanPages] = useState(0)
const [totalActiveScope, setTotalActiveScope] = useState(0)
const [averageScope, setAverageScope] = useState(0)
const [refreshTable, setrefreshTable] = useState(false)

const [dataGraphUsers, setDataGraphUsers] = useState({});
const [dataFacebook, setDataFacebook] = useState({});
const [dataInstagram, setDataInstagram] = useState({})


const [onlyFacebook, setOnlyFacebook] = useState([])
const [onlyInstagram, setOnlyInstagram] = useState([])
const [onlyTikTok, setOnlyTikTok] = useState([])
// import Logo_instagram from './img/logo_instagram.png'

const [columns, setColumns] = useState(
    [
        {
            id:'name',
            name: 'Usuario',
            selector: 'name',
            sortable: true,
            minWidth: '200px',
            omit: false,
            style: {
                color: '#02025b',
            },
            cell: (row) => (
                <DetailCol
                    text={row.name}
                    data={row}
                />
            ),
        },
        {
            id: 'delivery',
            name: 'Estado',
            selector: 'delivery',
            sortable: true,
            center: true,
            omit: false,
            style: {
                backgroundColor: '#E3E4E6',
                fontWeight: 'bold',
            },
            type: 'text',
            cell: (row) => (
                <DeliveryCol
                    text={row.delivery.name}
                    className={row.delivery.className}
                />
            )
        },
        {
            id: 'socialid',
            name: 'Social Type',
            selector: 'socialid',
            sortable: true,
            center: true,
            omit: false,
            style: {
                fontWeight: 'bold',
            }
        },
        {
            id: 'updatedAt',
            name: 'Fecha de Creacion',
            selector: 'updatedAt',
            sortable: true,
            center: true,
            style: {
                backgroundColor: '#E3E4E6',
                fontWeight: 'bold',
            },
        },
        {
            id: 'source',
            name: 'Red Social',
            selector: 'source',
            sortable: false,
            center: true,
            omit: false,
            style: {
                fontWeight: 'bold',
            },
            cell: (row) =>{
               /* console.log("Desde la celda de RedSocial ",row);*/
               return <a href={row.source.url} target="_blank">
                   <img className="source-img" src={row.source.img || row.source} alt="" />
                   </a>
            } 
        },
        {
            id: 'followers',
            name: 'Alcance',
            selector: 'followers',
            sortable: true,
            center: true,
            omit: false,
            style: {
                fontWeight: 'bold',
            },
        },
    ]
)

const MySwal = (data) => {
    let influencerName = ''
    let influencerLastName = ''
    /* console.log(influencerName)
    console.log(influencerLastName); */
   // const getInfluencerDataView = (data) => {
   //     influencerName = data[0].name
   //     influencerLastName = data[0].lastname
   //     console.log(influencerName)
   //     console.log(influencerLastName);

 
   // APILI.getInfluencerDatabyUser({id: data.userID}).then((info) => {
   //     getInfluencerDataView(info.data.influencer)
   //     
   // })
 
  // }
  const updateProfileStatus =  async (taskID, taskStatus) => {
    const update = {
        id: taskID,
        status: taskStatus
    }
    const updateTable = ()=>{
        setrefreshTable(!refreshTable)
    }
    await APILI.updateProfileStatusByID(update).then((status) => {
        updateTable()
    })
}
  /*  Swal.fire({
        title: data.campaignName,
        icon: 'info',
        html:
          'Usuario:, ' + data.userID + ' Seguidores: '+ data.scope +
          'Desa participar del reto:, ' + data.campaignName,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText:
          '<i class="fa fa-thumbs-up"></i> Aprobar',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonText:
          '<i class="fa fa-thumbs-down"></i>',
        cancelButtonAriaLabel: 'Thumbs down'
      }) */
      let age = data.age ? ' Edad: '+ data.age : ""
      let email = data.email ?  " Email: "+data.email : ""
      let gender = data.gender ?  " Género: "+data.gender : ""
      let string = 'Usuario: ' + data.name + age  + email + gender
      Swal.fire({
        title: data.campaignName,
        text: string,
        icon: 'question',
        showCancelButton: true,
        showDenyButton: true,
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonColor: '#00CA72',
        denyButtonText: '#d33',
        denyButtonText:
      '<i class="fa fa-thumbs-down"></i>',
        confirmButtonText:
      '<i class="fa fa-thumbs-up"></i> Aprobar',
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            updateProfileStatus(data.id,"Active")      
          Swal.fire({
              title: 'Confirmado!', 
                icon: 'success',
                  timer: 1500})
        } else if (result.isDenied) {
            updateProfileStatus(data.id,"Rejected")
            Swal.fire({
                title: 'Rechazado!', 
                  icon: 'error',
                    timer: 1500})
        }
      })
    
}

const dataGenderInfluencer = async () => {
    await APILI.getGenderDataInfluencer().then(res => {
        /* console.log('GenderData',res); */
        if(res.data.generalData){
            /* console.log(res.data.generalData) */
            setGeneralGenderState({"Masculino":res.data.generalData[0].data,"Femenino":res.data.generalData[1].data});
        }else {
            setGeneralGenderState({"Masculino":0,"Femenino":0});
        }
    });

    await APILI.getSocialProfiles().then(res =>{
        console.log("datos perfiles sociales", res)
          let perfilesRedes = res.data.socialprofiles
          let onlyTiktok = perfilesRedes.filter(item => item.socialmedia === "TikTok" )
            setOnlyTikTok(onlyTiktok)
          let onlyinstagram = perfilesRedes.filter(item => item.socialmedia === "Instagram")
            setOnlyInstagram(onlyinstagram)
          let onlyfacebook = perfilesRedes.filter(item => item.socialmedia === "Facebook")
            setOnlyFacebook(onlyfacebook)
    }).catch(err => console.error(err))
}

const influencersBySocialNetworks = async () => {
    await APILI.influencersBySocialNetworks().then(res => {
        /* console.log('GenderData',res); */
        if(res.data.profiles){
            /* console.log(res.data.profiles) */
            for(let profile of res.data.profiles ){
                let data = {
                    num:  profile.count,
                    text1: 'Alcance promedio: '+ Math.round(profile.reachAvg),
                    text2: 'Tasa de interaccion promedio: '+ Math.round(profile.interactionAvg)
                }
                /* console.log(data); */

                if(profile.socialNetwork === 'Facebook'){
                    setDataFacebook(data);
                }else if(profile.socialNetwork === 'Instagram'){
                    setDataInstagram(data);
                }
            }
            // setGeneralGenderState({"Masculino":res.data.generalData[0].data,"Femenino":res.data.generalData[1].data});
        }
    });
    
}

const profilesByState = async () => {
    await APILI.profilesByState().then(res => {
        /* console.log('GenderData',res); */
        let profilesStates = []
        if(res.data.socialprofiles){
            for(let value of res.data.socialprofiles){
                let state = {
                    text: value.name,
                    number: value.data
                }
                profilesStates.push(state);
            }

            setCampaignsByState(profilesStates) ;
        }
    });
    
}


const dataAgeInfluencer = async () =>{

    let listColors = ['color1', 'color2', 'color3', 'color4', 'color5',]

    await APILI.getAgeDataInfluencer().then(res => {
        /* console.log('GenderData',res); */
        if(res.data.ageData){
            const dataUsersAge = {
                range : [],
                gender: [],
                porcentage: []
            }
            for(let i = 0; i< res.data.ageData.length; i++ ){
                let value = res.data.ageData[i];
                let color = listColors[i];
                let range = {
                    ageRange: value.name, 
                    color: color
                }
                /* console.log(range); */
                dataUsersAge.range.push(range)
                if(value.data){
                   let gender = {
                        women: value.data[0].femenino,
                        mens: value.data[0].masculino,
                        color: color
                   }
                   let percentage = gender.women + gender.mens;

                   /* console.log(gender); */
                   dataUsersAge.gender.push(gender)
                   dataUsersAge.porcentage.push(percentage)
                }
            }   
            setDataGraphUsers(dataUsersAge);

          
        }
    });
}


const DetailCol = ({ text, className, onClick, data }) => (
    <div className="firstColl w-100">
        <div className="icon-container">
        <i onClick={() =>MySwal(data)} class="fas fa-eye"></i>
       {/*  {console.log(data)} */}
            {/* <i className="fas fa-edit"></i>
            <i class="fas fa-trash"></i>
            <i class="fab fa-telegram-plane"></i>
            <i class="fas fa-ellipsis-v"></i> */}
        </div>
        <p className="name-fc">
            {text}
        </p>
    </div>
)

const DeliveryCol = ({ text, className, onClick }) => (
    <div className={className}>
        <div className={"delivery" + (text)}>
            {text}
        </div>
    </div>
)

const customStyles = {
    headCells: {
        style: {
            '&:nth-last-of-type(3)': {
                backgroundColor: '#E3E4E6',
                width: '40px'
            },
            '&:nth-last-of-type(5)': {
                backgroundColor: '#E3E4E6',
            },
        },
    },
}



useEffect( () => {
    Auth.currentAuthenticatedUser()
        .then((userData) => {
            APILI.getCampaignByUser({ account: userData.username })
                .then(res => {
                    getInfoView(res.data.campaigns)
                })
                .catch(console.error)
        }).catch(() => {/* console.log('Not signed in') */})
         APILI.getInfluencersData().then(res => {
            if (res.data && res.data.influencerdata && res.data.influencerdata.length > 0) {
                
                
                getInfluencersInfoView(res.data.influencerdata, res.data.count)
                dataGenderInfluencer();
                profilesByState();
                dataAgeInfluencer();
                influencersBySocialNetworks();
                
               // setinfluencersData(res.data.influencerdata)
            }
        }).catch(console.error)

        APILI.getSocialProfiles().then(res => {
            console.log('allProfilesData', res)
            if (res.data && res.data.socialprofiles && res.data.socialprofiles.length > 0) {                    
                
                /* console.log('PROFILES',res.data.socialprofiles) */
                
                let arrayData= res.data.socialprofiles
                arrayData.forEach(element => {
                    let updated = element.updatedAt.split(['-'])
                    let day= updated[2].split(['T'])
                    let newDate = updated[1]+'/'+day[0]+'/'+updated[0];
                    element.updatedAt = newDate;
                })

                for (let x = 0; x < arrayData.length; x++) {
                    for (let y = 0; y < arrayData.length - 1; y++) {
                        let elementoActual = arrayData[y];
                        let elementoSiguiente = arrayData[y + 1];
                        if (new Date(elementoActual.updatedAt) < new Date(elementoSiguiente.updatedAt)) {
                            arrayData[y] = elementoSiguiente;
                            arrayData[y + 1] = elementoActual;
                        }
                    }
                  }
                  
                getProfilesView(res.data.count)
                getDataView(arrayData)

            }
        }).catch(console.error)
        
      
}, [refreshTable]);

const getDate= (date)=>{
   /*  console.log('DATE',date) */
    if(date != undefined){
        let updated = date.split(['-'])
        let day= updated[2].split(['T'])
        let newDate = updated[1]+'/'+day[0]+'/'+updated[0];
        /* console.log('newDate',new Date(newDate).toLocaleString('es-GT')) */
        return new Date(newDate).toLocaleString('es-GT');
    }
}


const getInfoView = camps => {
    const socialNetwork = camps.map(camp => camp.socialNetworks)
    const myInfluencers = camps.map(camp => camp.influencers)

    let fbCount = 0
    let igCount = 0
    let lkCount = 0
    let tkCount = 0

    socialNetwork.forEach(sn => {
        const Fb = sn.find(o => o.name === "Facebook");
        Fb && (fbCount = fbCount + 1)
        const Ig = sn.find(o => o.name === "Instagram");
        Ig && (igCount = igCount + 1)
        const Tk = sn.find(o => o.name === "TikTok");
        Tk && (tkCount = tkCount + 1)
        const Lk = sn.find(o => o.name === "Linkedin");
        Lk && (lkCount = lkCount + 1)
    });

    
    setInfluencers(getInfluencers(camps))
    
    
    setCampaingFacebook(fbCount)
    setCampaingInstagram(igCount)
    setCampaingLinkedin(lkCount)
    setCampaingTikTok(tkCount)
}


const getProfilesView = (count) => {
    setCountProfiles(count)
}

const getPendienteProfilesView = (count) => {
    setCountPendienteProfiles(count)
}

const getActiveScopeView = (total, average) => {
    setTotalActiveScope(total)
    setAverageScope(average)
}

const getInfluencersInfoView = (camps,count) => {
    const genderData = camps.map(camp => camp.gender)
    const statusData = camps.map(camp => camp.status)
    const ageData = camps.map(camp => camp.dateBirth)
    
    let genderArray = []
    let statusArray = []
    let ageArray = [] 
    let maleCount = 0;
    let femaleCount = 0;
    let activeCount = 0;
    let bannedCount = 0;
    let rejectedCount = 0;


    for (let x=0; x<genderData.length; x++){
        if(genderData[x]==='Masculino' || genderData[x]==='Otro'){
            maleCount = maleCount + 1
        }
        if(genderData[x]==='Femenino'){
            femaleCount = femaleCount + 1
        }
    }        
    for (let x=0; x<statusData.length; x++){
        if(statusData[x]==='Active' || !statusData[x]){
            activeCount = activeCount + 1
        }
        if(statusData[x]==='Rejected'){
            bannedCount = bannedCount + 1
        }
        if(statusData[x]==='Banned'){
            rejectedCount = rejectedCount + 1
        }
    }        
    
    // setGeneralGenderState({"Masculino":maleCount,"Femenino":femaleCount});
    setGeneralUserStates({"Active":activeCount, "Banned":bannedCount, "Rejected":rejectedCount})
 
    genderData.forEach(element => genderArray.push({"gender":element}));
    statusData.forEach(element => statusArray.push({"status":element}));
    ageData.forEach(element => ageArray.push(calculate_age(new Date(element))));
    
    setGender(genderArray);
    setuserStatusArray(statusArray);
    setuserAgeArray(ageArray); 
    setCountInfluencers(count)
}

    const [filters, setFilters] = useState({})
    /* console.log(filters); */
    const goToEditPage = (id, name, createAt) => {
        let edit = {
            id,
            name,
            createAt,
        }
        props._setEdit(edit)

        window.$.fancybox.open({
            src: '#new-category-modal',
            //type: 'modal',
            opts: {
                modal: true,
                afterClose: () => props._clearEdit(),
            },
        })
    }

    function calculate_age(dob) { 
        var diff_ms = Date.now() - dob.getTime();
        var age_dt = new Date(diff_ms); 
      
        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }
    //calculate age
    function getUser() {
        try {
            return Auth.currentAuthenticatedUser();
        } catch (error) {
            return null;
        }
    }
///revisar
    function getInfluencers(myCampaigns) {
        let listUsers = [];
        myCampaigns.forEach(function (campaign) {
            if (campaign.influencers != undefined) {
                campaign.influencers.forEach(function (influencer) {
                    listUsers.push(influencer);
                });
            }
        });
        
        return listUsers;
    } 
    function getLogoByNameLocal(name,url) {
        switch (name) {
            case "Facebook":
                    return {img:Logo_facebook,url}
            case "Instagram":
                return {img:Logo_instagram, url}
            case "TikTok":
                return {img: Logo_tiktok, url}
                case "Tik Tok":
                return {img: Logo_tiktok, url}
            case "whatsapp":
                return logo_whatsapp
            case "facebook":
                    return {img:Logo_facebook,url}
            case "instagram":
                return {img: Logo_instagram, url}
            case "tiktok":
                return {img: Logo_tiktok, url}
            case "whatsapp":
                return logo_whatsapp
            default:
                break;
        }
    }
    function getName(status) {
        switch (status) {
            case "Pendiente":
                return "Pendiente"
                case "Active":
                    return "Activo"
            case "Finalizada":
                return "Finalizada"
            case "active":
                return  "Activo"
            case "En Revision":
                return "En revisión"
                case "En revision":
                return "En revisión"
            case "rejected":
            return "Rechazado"
            case "Rejected":
                return "Rechazado"
            case "Completado":
                    return "Completado"
            case "Finalizado":
            return "Finalizado"
            case "Cerrada":
                    return "Cerrada"
            case "Solicitado":
                return "Solicitado"
            case "En progreso":
            return "En progreso"
            case "Completado":
                return("Completado")
            default:
                return "Desconocido"
        }
    }

    const getDataView = (data) => {
  
        setTables({
            data: data.map(dat => ({
              name: dat.userprofile,
              delivery: {
                name: getName(dat.status),
                className: getClassNameByStatus(dat.status)
            },
            socialid: dat.type,
            updatedAt: new Date(dat.updatedAt).toLocaleString('es-GT'),
            source: getLogoByNameLocal(dat.socialmedia,dat.url),
            inscription: dat.scope.toLocaleString(),
            id: dat.id,
            userID: dat.userID,
            scope: dat.scope,
            email: dat.email,
            gender: dat.gender,
            socialNetwork:dat.socialmedia,
            followers: dat.followers
            }))
        }
        )

        setFilteredDataNormal(data.map(dat => ({
            name: dat.userprofile,
            delivery: {
              name: getName(dat.status),
              className: getClassNameByStatus(dat.status)
          },
          socialid: dat.type,
          updatedAt: new Date(dat.updatedAt).toLocaleString('es-GT'),
          source: getLogoByNameLocal(dat.socialmedia,dat.url),
          inscription: dat.scope.toLocaleString(),
          id: dat.id,
          userID: dat.userID,
          scope: dat.scope,
          email: dat.email,
          gender: dat.gender,
          socialNetwork:dat.socialmedia,
          followers: dat.followers
          })))

        setFilteredData(data.map(dat => ({
            name: dat.userprofile,
            delivery: {
              name: getName(dat.status),
              className: getClassNameByStatus(dat.status)
          },
          socialid: dat.type,
          updatedAt: new Date(dat.updatedAt).toLocaleString('es-GT'),
          source: getLogoByNameLocal(dat.socialmedia,dat.url),
          inscription: dat.scope.toLocaleString(),
          id: dat.id,
          userID: dat.userID,
          scope: dat.scope,
         email: dat.email,
         gender: dat.gender,
         socialNetwork:dat.socialmedia,
         followers: dat.followers
          })))
      }

      const handleFilterTextChange = (value) => {
            
        changeFilteredData(value)
      }
    
      const changeFilteredData= (filterText) =>  {
        setFilteredData(tables.data.filter( (item) =>  
        item?.name?.toString().toLowerCase().includes(filterText.toLowerCase()) || 
        item?.delivery?.name?.toString().toLowerCase().includes(filterText.toLowerCase()) || 
        item?.socialid?.toString().toLowerCase().includes(filterText.toLowerCase()) || 
        item?.socialNetwork?.toString().toLowerCase().includes(filterText.toLowerCase())
        ))
        
      }

    const handleFilterPerColumn = (column, type, value) => {
        /* console.log('Filtro', column, type, value) */
        setFilters((prev) => ({ ...prev, [column]: { type, value } }))
    }

    const handleShowColumnChange = (column, show) => {
    console.log('column', column)
    console.log('show', show)
    
    let index = columns.findIndex((col) => col.selector === column)

    let aux = columns.map((col) => col)
    console.log('Colums',aux)
    console.log('ColumsIndex',aux[index])
    aux[index].omit = show
    setColumns(aux)
    }

    const handleColumnSortChange = (positions) => {

        let aux = columns.map((col) => col)

        positions.forEach((columnId, position) => {
            let index = columns.findIndex((col) => col.selector === columnId)
            aux[index].position = position
        })
        aux = _.orderBy(aux, ['position'])

        setColumns(aux)
    }
    function percentAge(range){
        let count =0
        
        let percentage = 0; 
        let array = userAgeArray
        //let array = [16,17,18,19,20,21,22,23,24,25,16,17,18,19,20,21,22,23,24,25,
         //   25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,70,80]
        let max = array.length
        if(range===0){
            for(let x=0; x<max; x++){
                if(array[x]>=16 && array[x]<=17){
                    count++
                }
            }
            percentage = parseInt(100/max*count)
            return (percentage)
        }
        if(range===1){
            for(let x=0; x<max; x++){
                if(array[x]>=18 && array[x]<=24){
                    count++
                }
            }
            percentage = parseInt(100/max*count)
            return (percentage)
        }
        if(range===2){
            for(let x=0; x<max; x++){
                if(array[x]>=25 && array[x]<=29){
                    count++
                }
            }
            percentage = parseInt(100/max*count)
            return (percentage)
        }
        if(range===3){
            for(let x=0; x<max; x++){
                if(array[x]>=30 && array[x]<=35){
                    count++
                }
            }
            percentage = parseInt(100/max*count)
            return (percentage)
        }
        if(range===4){
            for(let x=0; x<max; x++){
                if(array[x]>=36){
                    count++
                }
            }
            percentage = parseInt(100/max*count)
            return (percentage)
        }
    
    }

    const sort= (rows,selector,direction)=>{
        if(selector==='updatedAt'){
            /* console.log('DIRECTION',direction) */
            if(direction==='asc'){
                setFilteredData(filteredData.reverse())
            }else{
                setFilteredData(filteredDataNormal)
            }
            
            return orderBy(rows, direction)
        }else{
            return orderBy(rows, selector, direction)
        }
    }
    const exportDataToExcel = () =>{
        console.log('tablesDataTopExport', tables.data)
        let headers = []
        let dataExcel=[]
        let selectors = []
        let excelData = [];
        var filteredColumns=columns.filter(function(col){
          return col.omit===false
        })
        
        filteredColumns.forEach((element)=>{
          headers.push(element.name)
          selectors.push(element.selector)
        })  
        
        dataExcel.push(headers)
      
             if(filteredData.length>0)
             {
                filteredData?.forEach((element) => {
                 excelData = [];
                   for(let k=0; k<selectors.length; k++)
                   {
                       excelData.push(
                         selectors[k]=="delivery" ? element.delivery.name:
                         selectors[k]=="source" ? element.socialNetwork:
                         element[selectors[k]],
                       );
                   }
                
                   dataExcel.push(excelData);
                 });
                 
             }
         console.log('dataParaExportar', dataExcel)
         return dataExcel;
      }

    return (
        <div className='form-dashboard contProfileList'>
            <div id='shadow' className='shadow hide'></div>
            <div className='Bar-content'>
                <div className='content-menu list'>
                    <select
                        id='viewSelect'
                        className='select-dashboard fa'
                    >
                        <option className='fa' value='1'>
                            {' '}
                            &#xf0ce; Vista de Perfiles
                        </option>
                        {/* <option className='fa' value='0'>
                            {' '}
                            &#xf0db; Vista de Kanban{' '}
                        </option>
                        <option className='fa' value='2'>
                            {' '}
                            &#xf073; Vista de calendario
                        </option> */}
                    </select>
                    <DataTableUtils
                        onChangeFilterText={handleFilterTextChange}>
                        <Share
                        contacts={utils.myContacts}
                        data={exportDataToExcel()}
                        isArray={true}
                        exportXLSX={true}
                        exportTXT={true}
                        exportDropbox={true}
                        exportDrive={true}
                        exportCSV={true}
                        printable={true}
                        exportPNG={true}
                        exportLink={true}
                        />
                        <ShowColumn
                            columns={columns}
                            showColumn={handleShowColumnChange}
                            onSortChange={handleColumnSortChange}
                        />
                      {/*   <FilterColumn
                            columns={columns}
                            onFilterColumn={handleFilterPerColumn}
                        /> */}
                    </DataTableUtils>
                    <div
                        className='btnOrange-dash'
                        onClick={e => setShowModal(true)}>
                        <i className='fas fa-plus'></i>
                    </div>
                </div>
            </div>
            <div className="title-grid TitleProfileList">
                Truers por red social
            </div>
            <div className="content-section social-networkModeration">
                {/* <ImageBox className="rectangle-img-info" image={Logo_facebook} num={dataFacebook?.num} text1={dataFacebook?.text1} text2={dataFacebook?.text2} />
                <ImageBox className="rectangle-img-info" image={Logo_instagram} num={dataInstagram?.num} text1={dataInstagram?.text1} text2={dataInstagram?.text2} /> */}
                <ImageBox className="rectangle-img-info" image={Logo_facebook} num="0" text1="Alcance promedio: 0" text2="Tasa de interacción promedio: 0" />
                <ImageBox className="rectangle-img-info" image={Logo_instagram} num="0" text1="Alcance promedio: 0" text2="Tasa de interacción promedio: 0" />
                <ImageBox className="rectangle-img-info" image={Logo_tiktok} num="0" text1="Alcance promedio: 0" text2="Tasa de interacción promedio: 0" />
                <ImageBox className="rectangle-img-info" image={logo_whatsapp} num="0" text1="Alcance promedio: 0" text2="Tasa de interacción promedio: 0" />
            </div>
            <div className="content-section section2 sect3Boxes">
                <div className='box'>
                    <div className="title-grid">
                        Truers por Género
                    </div>
                    <GraphBox nameClass="rectangle-graph-meddium margin" >
                        <div className="userByGender">
                        <GenderChart 
                            generalGenderState={generalGenderState} 
                            onlyFacebook={onlyFacebook}
                            onlyInstagram={onlyInstagram}
                            onlyTikTok={onlyTikTok}    
                        />
                        </div>
                    </GraphBox>
                </div>
                <div className='box'>
                    <div className="title-grid">
                        Truers por edad
                    </div>
                    <GraphBox nameClass="rectangle-graph-meddium graph boxGrafica" >
                        <div className="userByState">
                           
                        <PieChart data={dataGraphUsers}
                                    dataUsersAge={dataGraphUsers}
                                    width={170}
                                    height={170} />
                        </div>
                    </GraphBox>
                </div>
                <div className='box'>
                    <div className="title-grid">
                        Perfiles por estado
                    </div>
                    {/* {console.log(campaignsByState)} */}
                    <TargetContent listGeneralStatistics={campaignsByState} nameClass="targetContent-small" />
                </div>
            </div>
            <div className="content-section table1">
                <div className="title-grid">
                    Listado de Perfiles
                </div>

                {/* <select className='select-dashboard'>
                    <option value='1'>
                        &#xf0ce; Últimos 30 dias
                    </option>
                </select> */}
            </div>
            <ReactDataTable
                columns={columns}
                customStyles={customStyles}
                data={filteredData}
                persistTableHead
                pagination
                noDataComponent={"No hay datos para mostrar"}
                noHeader
                sortFunction={sort}
                paginationComponentOptions={{
                    rowsPerPageText: 'Elementos por página',
                    rangeSeparatorText: 'de',
                  }}
            />
         {showModal ? <AddProfile title="Agregar Perfil" showModal={setShowModal} /> : null}

        </div>
    );
};

  //**************************Age Graph************************* */
  
export default ProfileList;