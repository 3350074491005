import React from 'react';

import './asserts/css/styles.css'

const GraphBox = (props) => {
	
	const nameClass = props.nameClass;	

    return (
        <div className={nameClass}>
            {props.children}
        </div>
    )
}

export default GraphBox
