import React, { useState, useEffect } from "react";
import * as utils from "data.js";

import DataTableUtils, { Share } from "@easygosanet/react-datatable-utils";
import ReactDataTable, { DateRange } from "@easygosanet/react-datatable";

//helpers
import * as APILI from "../../helpers/apiLI";
import * as STORAGE from "helpers/storageLI";
//Images
import Logo_facebook from "./assets/img/logo_facebook.svg";
import Logo_instagram from "./assets/img/logo_instagram.png";


import TargetContentSubTasks from "../targetContentSubTasks";
import GalleryPost from "./Subtasks/galleryPost";
import Swal from "sweetalert2/dist/sweetalert2.js";
import MuiAlert from "@mui/material/Alert";
import {v4} from 'uuid'
import ChartStatusSubtask from './PieChart'
import DataProfile from './Profile'
import Subtasks from './Subtasks'
import StarsShow from '../starsShow'

//Delivery Column
import { getLogoByName, getClassNameByStatus } from "myUtil";

//css
import "./style.scss";
import IconBack from '../campaign/assets/img/arrow-left.png'

//LoaderReducer
import { showLoader } from 'redux/actions/loader.actions';
import {  useDispatch, connect } from 'react-redux';

const DeliveryCol = ({ text, className, onClick }) => (
  <div className={className}>
    <div className="delivery-text">{text}</div>
  </div>
);
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
//Tables style

const customStyles = {
  headCells: {
    style: {
      "&:nth-last-of-type(2)": {
        backgroundColor: "#E3E4E6",
        width: "40px",
      },
      "&:nth-last-of-type(4)": {
        backgroundColor: "#E3E4E6",
        width: "40px",
      },
      "&:nth-last-of-type(6)": {
        backgroundColor: "#E3E4E6",
      },
      "&:nth-last-of-type(7)": {
        font: "normal normal bold 16px / 12px PierSans",
        color: "#05252D",
      },
    },
  },
};

function calculate_age(dob) {
  var diff_ms = Date.now() - dob.getTime();
  var age_dt = new Date(diff_ms);

  return Math.abs(age_dt.getUTCFullYear() - 1970);
}
function calculateAge(fecha) {
  var hoy = new Date();
  var cumpleanos = new Date(fecha);
  var edad = hoy.getFullYear() - cumpleanos.getFullYear();
  var m = hoy.getMonth() - cumpleanos.getMonth();

  if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
  }

  return edad;
}

const Task = ({
  taskPage,
  refreshTable,
  setrefreshTable,
  setShowTask,
  showTask,
  currentCampaign,

  }) => {
  const {
    activityName,
    campaignName,
    id,
    inscription,
    name,
    price,
    scope,
    socialmedia,
    userID,
    image,
    campaignID,
  } = taskPage;

  const [tableTitle, setTableTitle] = useState("Iniciado");
  const [tables, setTables] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [firstState, setFirstState] = useState(true);
  const [secondState, setSecondState] = useState(false);
  const [thirdState, setThirdState] = useState(false);
  const [fourthState, setFourthState] = useState(false);
  const [mediaCategories, setMediaCategories] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [refreshSubtasks, setRefreshSubtasks] = useState(false);
  const [socialStatusText, setSocialStatus] = useState([]);
  const [open, setOpen] = useState(false);
  const [subTaskButton, setSubTaskButton] = useState({});
  //snackbar
  const [snackBarText, setSnackBarText] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [tags, setTags] = useState([]);
  const [ratingTitle, setRatingTitle] = useState({
    title: "Selecciona una tarea",
    id: "",
    comment: "",
  });
  const [ratingValue, setRatingValue] = useState(0);
  const [ratingId, setRatingId] = useState("");
  const [totalReach, setTotalReach] = useState(0);
  const [campaignData, setCampaignData] = useState({
    name: "",
    reward: 0,
    initDate: "",
    endDate: "",
    description: "",
    activity: [],
    hastags: [],
    socialNetworks: [],
    locationList: [],
    postImages: [],
  });

  const [loading, setLoading] = useState(false);
  const [categoryTags, setCategoryTags] = useState([]);
  //const [socialStatusValue, setSocialStatusValue] = useState(socialStatus);
  const [postList, setPostList] = useState([]);
  const [postImages, setPostImages] = useState([]);
  const [currentProfile, setCurrentProfile]= useState([]);
  const [subTasks, setSubTasks] = useState({
    subTasks: [],
    approved: 0,
    rejected: 0,
    Pendiente: 0,
    count: 0,
    rating: 0,
  });
  const [paymentData, setPaymentData] = useState({
    amount: 0,
    campaignName: "",
    createdAt: "",
    currency: "",
    id: "",
    socialmedia: "",
    state: "",
    updatedAt: "",
    userId: "",
  });
  const [walletData, setwalletData] = useState({
    balance: 0,
    currency: "USD",
    id: "",
    updatedAt: "",
  });
  const [influencerData, setInfluencerData] = useState({
    id: "0",
    phoneNumber: "0",
    dateBirth: "01-01-1990",
    status: "",
    tasks: [],
    email: "",
    country: "",
    name: "",
    gender: "",
    lastname: "",
    bankAccount: "",
    direction: "",
    socialNetworks: [],
    username: "",
    interested: [],
    rating: 0,
    averageInteraction: 0,
    averageScope: 0,
    interactionPercent: 0,
  });

  const [postImg, setPostImg] = useState([]);
  const [disabledButtonActiveTask, setbuttonActiveTask] = useState(true)
  const dispatch = useDispatch()
  let intereses = "";

  const getMediaCategories = (data) => {
    setCategoryTags(data);
  };
  //savecategory tag: create an array of categories listed
  const saveCategoryTag = (cat) => {
    if (cat) {
      const textHastag = cat.name;
      let newTags = categoryTags.filter((tag) => tag !== textHastag.trim());
      newTags.push(textHastag.trim());
      setCategoryTags(newTags);
      saveCategories(newTags);
    }
  };
  const deleteCategoryTag = (tagName) => {
    const newTags = categoryTags.filter((tag) => tag !== tagName);
    setCategoryTags(newTags);
    saveCategories(newTags);
  };

  //const {influencerData}=influencerData

  const getWalletData = (data) => {
    if (data) {
      setwalletData(data);
    } else {
      setwalletData({
        balance: 0,
        currency: "USD",
        id: "",
        updatedAt: "",
      });
    }
  };
 
  const getPaymentData = (data) => {
    // data.length > 0 ? setPaymentData(data) : setPaymentData([])
    setPaymentData(data[data.length - 1]);

    //let lastPayment = data[data.length-1]
  };
  const emptyArray = [];
  const listGeneralStatistics = [
    {
      text: "Estado",
      number: "Activo",
    },
    ,
    {
      text: "Campaña",
      number: campaignData.name,
    },
    {
      text: "Fecha de Inicio",
      number: campaignData.initDate,
    },
    {
      text: "Fecha de expiracion",
      number: campaignData.endDate,
    },
  ];
  /******************************************************** */

  const dataAccess = [
    {
      text: "Ultima direccion IP",
      number: "200.119.187.128",
    },
    {
      text: "Ultimo ingreso",
      number: "16 de febrero de 2020 H: 15:02:01",
    },
    {
      text: "Ultimo Backend",
      number: "Facebook",
    },
    {
      text: "Ultimo cliente",
      number: "LikeInc/1.2.4; IOS 14.4.0",
    },
    {
      text: "Ultima version",
      number: "1.2.4",
    },
  ];
  /******************************************************** */
  let dataBalance = [
    {
      text: "Saldo",
      number: "0.00 USD",
    },
    {
      text: "Ultimo monto de pago",
      number: "0.00 USD",
    },
    {
      text: "Ultimo fecha de pago",
      number: "",
    },
  ];
  /******************************************************** */
  const activityData = [
    {
      text: "Total de campañas",
      number: 0,
    },
    {
      text: "Total de post",
      number: "0",
    },
  ];
  /******************************************************** */

  const postActivityData = [
    {
      text: "Incompletas",
      number: "20",
    },
    {
      text: "En revisión",
      number: "10",
    },
    {
      text: "Aceptados",
      number: "35",
    },
    {
      text: "Rechazados",
      number: "10",
    },
    {
      text: "Listo para pagar",
      number: "10",
    },
    {
      text: "Pagado",
      number: "10",
    },
  ];
  /******************************************************** */

  const socialActivityData = [
    {
      text: "Alcance promedio",
      number: "0",
    },
    {
      text: "Interacción promedio",
      number: "0",
    },
    {
      text: "Tasa de interacción promedio",
      number: "0",
    },
    {
      text: "Redes Sociales",
      number: "",
    },
  ];

  /******************************************************** */
  const getDataView = (data) => {
    setTables({
      titles: [
        {
          name: "Nombre del reto o tarea",
          selector: "name",
          sortable: true,
          minWidth: "200px",
          style: {
            color: "#02025b",
          },
          cell: (row) => <DetailCol text={row.name} data={row} />,
        },
        {
          name: "Estado",
          selector: "delivery",
          sortable: true,
          center: true,
          style: {
            backgroundColor: "#E3E4E6",
            fontWeight: "bold",
          },
          type: "text",
          cell: (row) => (
            <DeliveryCol
              text={row.delivery.name}
              className={row.delivery.className}
            />
          ),
        },
        {
          name: "Activity",
          selector: "campaignName",
          sortable: true,
          center: true,
          style: {
            fontWeight: "bold",
          },
        },
        {
          name: "Ultimo cambio",
          selector: "updatedAt",
          sortable: true,
          center: true,
          style: {
            backgroundColor: "#E3E4E6",
            fontWeight: "bold",
          },
        },
        {
          name: "Red Social",
          selector: "source",
          sortable: true,
          center: true,
          style: {
            fontWeight: "bold",
          },
          cell: (row) => <img className="source-img" src={row.source} alt="" />,
        },
        {
          name: "Pago",
          selector: "amount",
          sortable: true,
          center: true,
          style: {
            fontWeight: "bold",
          },
        },
        {
          name: "Influencer",
          selector: "userID",
          sortable: true,
          center: true,
          style: {
            fontWeight: "bold",
          },
        },
      ],
      data: data.map((dat) => ({
        name: dat.campaignName,
        delivery: {
          name: dat.state,
          className: getClassNameByStatus(dat.state),
        },
        campaignName: dat.campaignName,
        updatedAt: new Date(dat.updatedAt).toString(),
        source: getLogoByName(dat.socialmedia),
        inscription: dat.scope,
        id: dat.id,
        userID: dat.userID,
        price: dat.price,
        socialmedia: dat.socialmedia,
        activityName: dat.name,
        amount: dat.currency + " " + parseFloat(dat.amount).toFixed(2),
      })),
    });
  };

  /******************************************************** */
  const DetailCol = ({ text, data }) => (
    <div className="firstColl w-100">
      <div className="icon-container" style={{ backgroundColor: "#FF9015" }}>
        <i onClick={() => MySwal(data)} class="fas fa-eye"></i>
        <i className="fas fa-edit"></i>
        <i class="fas fa-trash"></i>
        <i class="fab fa-telegram-plane"></i>
        <i class="fas fa-ellipsis-v"></i>
      </div>
      <p className="name-fc">{text}</p>
    </div>
  );
  
  const ValidateActiveTask=(subTasks)=>{
    if(subTasks.subTasks.length!=0)
    {
      let activesCount = 0
      for(let i=0; i<subTasks.subTasks.length; i++)
      {
        if(subTasks.subTasks[i].status=="Aprobado")
        {
          activesCount++
        }
      }
      if(activesCount==subTasks.subTasks.length)
      {
        setbuttonActiveTask(false)
      }
      else{
        setbuttonActiveTask(true)
      }
    }
  }
  //Pendiente first
  useEffect(async () => {
    let tempstate;
    if (firstState) tempstate = "Pendiente";
    if (secondState) tempstate = "Approved";
    if (thirdState) tempstate = "Billing";
    if (fourthState) tempstate = "Payment";
    const body = {
      state: tempstate,
    };
    await APILI.getPaymentByStatus(body)
      .then((res) => {
        getPaymentsData(res.data.registerPayment);
      })
      .catch((err) => {
        console.log(err);
      });

    const body2 = {
      id: id,
    };
    if(subTasks.subTasks.length!=0)
    {
      let activesCount = 0
      for(let i=0; i<subTasks.subTasks.length; i++)
      {
        if(subTasks.subTasks[i].status=="Aprobado")
        {
          activesCount++
        }
      }
      if(activesCount==subTasks.subTasks.length)
      {
        console.log('ya se puede activar')
      }
      else{
        console.log('aun no se puede activar')
      }
    }
  }, [refreshTable]);

  //get influencer data
  const getInfluencerData = (data) => {
    setInfluencerData(data);
  };
  //paymentsDat
  //Pendiente first
  useEffect(async () => {
    const body2 = {
      id: userID,
    };

    await APILI.getInfluencerDatabyUser(body2)
      .then((res) => {
        getInfluencerData(res.data.influencer[0]);
      })
      .catch((err) => {
        console.log(err);
      });
      
  }, [refreshTable]);
  const getPayments = async (dato) => {
    const body = {
      state: dato,
    };
    await APILI.getPaymentByStatus(body)
      .then((res) => {
        getPaymentsData(res.data.registerPayment);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPaymentsData = (paidsData) => {
    getDataView(paidsData);
    //return response.data
  };

  const handleClick = (e, data) => {
    e.preventDefault();

    setTableTitle(data);
    if (data === "Iniciado") {
      getPayments("Pendiente");
      setFirstState(true);
      setSecondState(false);
      setThirdState(false);
      setFourthState(false);
    }
    if (data === "Liquidacion") {
      getPayments("Approved");
      setFirstState(false);
      setSecondState(true);
      setThirdState(false);
      setFourthState(false);
    }
    if (data === "Facturacion") {
      getPayments("Billing");
      setFirstState(false);
      setSecondState(false);
      setThirdState(true);
      setFourthState(false);
    }
    if (data === "Completado") {
      getPayments("Payment");
      setFirstState(false);
      setSecondState(false);
      setThirdState(false);
      setFourthState(true);
    }
  };
  //saveModeration

  const saveCategories = async (newTags) => {
    const body = {
      id: id,
      categories: newTags,
    };
    await APILI.updateInfluencerCategories(body)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(async () => {
    dispatch(showLoader(true))
    const body = {
      id: campaignID,
    };

    let res = await APILI.getCampaignById(body)

        /* console.log("DATACAMPAIN", res.data.campaign[0]); */
        if (res.data.campaign[0].postImages.length > 0) {
          let arrayImg = [];
          res.data.campaign[0].postImages.forEach((element) => {
            if (element.name) {
              STORAGE.getUrlImage(element.name).then((url) => {
                arrayImg.push({
                  name: element.name,
                  link: url.toString(),
                });
              });
            }
          });
          setPostImg(arrayImg);
        }

        setCampaignData(res.data.campaign[0]);
        saveStateData(
          res.data.campaign[0].mediaCategories,
          res.data.campaign[0].postImages
        );

      let data = taskPage
    if(data.socialmedia==='Instagram'){
      /* console.log("hola desde instagram"); */
      
      fetch(`https://graph.facebook.com/v12.0/me?fields=id%2Cname%2Caccess_token%2Cinstagram_business_account%7Bid%2Cname%2C%20followers_count%2Cfollows_count%2Cmedia_count%2C%20profile_picture_url%2Cusername%2Cmedia%7D&access_token=${data?.temporalSubTask?.tokenRedSocial}`)
      .then((res) => res.json())
      .then((response) => {
          setCurrentProfile(response)
      })
  }else if(data.socialmedia=="Facebook"){
      /* console.log("hola desde facebook"); */
      fetch(`https://graph.facebook.com/v12.0/${data?.temporalSubTask?.idPaginaSocial}?fields=posts%7Breactions%2Cmessage%2Cmessage_tags%2Cfull_picture%2Ccreated_time%2Ccomments%2Cpermalink_url%2Cshares%2Cstatus_type%2Cvideo_buying_eligibility%2Cattachments%7D%2Cname&access_token=${data?.temporalSubTask?.tokenRedSocial}`)
      .then((res) => res.json())
      .then((response) => {
          setCurrentProfile(response)
      })
  }
  
  dispatch(showLoader(false))

  }, []);

  const saveStateData = (data1, data2) => {
    setCategoryTags(data1);
    setPostImages(data2);
  };

  //alert moderation

  useEffect(async () => {
    setLoading(true);
    const body = {
      taskID: id,
    };

    await APILI.getSubTasksByTask(body)
      .then((res) => {
        getSubTasks(res.data);
        setSubTaskButton(res.data.subTasks)
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refresh]);
  

  const getSubTasks = async (subTasksData) => {
    ValidateActiveTask(subTasksData)
    try {
      let res = await APILI.updateTaskRating({id: subTasksData.subTasks[0].taskID, rating: subTasksData.rating.toFixed(2)})
    }
    catch (err) {
    }
    /* console.log('res', res) */
    
    setSubTasks(subTasksData);
    setLoading(false);
  };
  const MySwal = (data) => {
    const updateInfluencerStatus = async (taskID, taskStatus) => {
      const update = {
        id: taskID,
        status: taskStatus,
      };
      const updateTable = () => {
        setrefreshTable(!refreshTable);
      };
      await APILI.updateInfluencerStatusByID(update).then((status) => {
        updateTable();
      });
    };

    Swal.fire({
      title: "Moderación usuario",
      icon: "question",
      showCancelButton: true,
      showDenyButton: true,
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonColor: "#00CA72",
      denyButtonText: "#d33",
      denyButtonText: '<i class="fa fa-thumbs-down"></i> Rechazar',
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> Activar',
    }).then((result) => {
      if (result.isConfirmed) {
        updateInfluencerStatus(data.id, "Active");
        Swal.fire({
          title: "Confirmado!",
          icon: "success",
          timer: 1500,
        });
      } else if (result.isDenied) {
        updateInfluencerStatus(data.id, "Rejected");
        Swal.fire({
          title: "Rechazado!",
          icon: "error",
          timer: 1500,
        });
      }
    });

    if (data.delivery.name === "Payment") {
      Swal.fire({
        title: "Ninguna actividad pendiente!",
        icon: "success",
        timer: 1500,
      });
      return;
    }
    let aprobeTask = "";
    let dennyTask = "";
    if (data.delivery.name === "Rejected") {
      aprobeTask = "Pendiente";
      dennyTask = "Rejected";
    }
    if (data.delivery.name === "Pendiente") {
      aprobeTask = "Approved";
      dennyTask = "Rejected";
    }
    if (data.delivery.name === "Approved") {
      aprobeTask = "Billing";
      dennyTask = "Pendiente";
    }
    if (data.delivery.name === "Billing") {
      aprobeTask = "Payment";
      dennyTask = "Approved";
    }
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarText("");
    setOpenSnackbar(false);
  };

  const taskCompleted = () => {
    Swal.fire({
      title: "Tarea completada",
      icon: "success",
      timer: 2000,
    });
    setShowTask(!showTask);
  };
  const taskRejected = () => {
    Swal.fire({
      title: "Tarea Incompleta",
      icon: "error",
      timer: 2000,
    });
    setShowTask(!showTask);
  };

  const updateComment = async (text, ratingId) => {
    const body = {
      id: ratingId,
      comment: text,
    };
    await APILI.updateSubTaskComment(body)
      .then((res) => {
        setRefresh(!refresh);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveComment = (ratingId) => {
    Swal.fire({
      title: "Input something",
      input: "textarea",
    }).then(function (result) {
      if (result.value === "") {
        //          updateComment(result.value,ratingId)
        Swal.fire({
          title: "Tienes que escribir algo!",
          icon: "error",
        });
      }
      if (result.value) {
        updateComment(result.value, ratingId);
        Swal.fire({
          title: "Comentario guardado!",
          icon: "success",
        });
      }
    });
  };

  const bodyNotification = (status) => {
    return {
      influencerID: influencerData.id,
      title:
        status === "Confirm"
          ? "Has completado un reto"
          : "No has podido completar el reto",
      message:
        status === "Confirm"
          ? `Felicidades! has completado el reto ${campaignData.name} `
          : `No has podido completar ${campaignData.name}`,
      image: "https://i.postimg.cc/m2dZWQTD/likeinclogo.jpg",
      type: "task",
      idType: "",
    };
  };

  const saveCompletedTask = async () => {
    if (influencerData?.averageScope === undefined) {
      setSnackBarText("Por favor agregue Alcance Promedio al influenciador");
      setOpenSnackbar(true);
      return;
    }
    if (influencerData?.averageInteraction === undefined) {
      setSnackBarText(
        "Por favor agregue Interaccion Promedio al influenciador"
      );
      setOpenSnackbar(true);
      return;
    } else {
      // console.log("este es el id",id)
      const body = {
        id: id,
        status: "Completed",
      };
      let bodyNot = bodyNotification("Confirm");

      await APILI.sendNotification(bodyNot)
        .then((data) => {})
        .catch((err) => {
          console.log(err);
        });

      await APILI.updateTaskStatusByID(body)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
      console.log({ id: id, scope: influencerData.averageScope });
      await APILI.updateTaskScope({ id: id, scope: totalReach })
        .then((res) => {
          console.log("Scope promedio actualizado");
        })
        .catch((err) => {
          console.log(err);
        });

      console.log({ id: id, interactions: influencerData.averageInteraction });
      await APILI.updateTaskInteractions({
        id: id,
        interactions: influencerData.averageInteraction,
      })
        .then((res) => {
          console.log("Interaccion promedio actualizado");
        })
        .catch((err) => {
          console.log(err);
        });

      setrefreshTable(!refreshTable);

      taskCompleted();
    }
  };
  const saveRejectedTask = async () => {
    if (influencerData?.averageScope === undefined) {
      setSnackBarText("Por favor agregue Alcance Promedio al influenciador");
      setOpenSnackbar(true);
      return;
    }
    if (influencerData?.averageInteraction === undefined) {
      setSnackBarText(
        "Por favor agregue Interaccion Promedio al influenciador"
      );
      setOpenSnackbar(true);
      return;
    } else {
      // console.log("este es el id",id)
      const body = {
        id: id,
        status: "Rejected",
      };
      let bodyNot = bodyNotification("Deny");

      await APILI.sendNotification(bodyNot)
        .then((data) => {})
        .catch((err) => {
          console.log(err);
        });

      await APILI.updateTaskStatusByID(body)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
      console.log({ id: id, scope: influencerData.averageScope });
      await APILI.updateTaskScope({
        id: id,
        scope: influencerData.averageScope,
      })
        .then((res) => {
          console.log("Scope promedio actualizado");
        })
        .catch((err) => {
          console.log(err);
        });

      console.log({ id: id, interactions: influencerData.averageInteraction });
      await APILI.updateTaskInteractions({
        id: id,
        interactions: influencerData.averageInteraction,
      })
        .then((res) => {
          console.log("Interaccion promedio actualizado");
        })
        .catch((err) => {
          console.log(err);
        });

      setrefreshTable(!refreshTable);

      taskRejected();
    }
  };

  const getActivitiesList = async (campaignID) =>{
       
    return   await APILI.getCampaignById({"id":campaignID}).then(res => 
        {
            return res.data.campaign[0].activity})
    .catch(error => {return error})       
        
}
const updateTruers = async (id) => {
  let res  = await APILI.updateApprovedTruers({id:id})
  setrefreshTable(!refreshTable);
  console.log('responseUpdateTruer', res)
  }

  const MySwal2 = async (data) => {
      let activities = await getActivitiesList(data.campaignID)
   
  const updateTaskStatus = async (taskID, taskStatus) => {
    const update = {
      id: taskID,
      status: taskStatus,
    };

    const updateTable = () => {
      setrefreshTable(!refreshTable);
    };
    APILI.updateTaskStatusByID(update)
      .then((status) => {
        if (taskStatus === "Pendiente") {
          activities.forEach((dat) => {
            for (let x = 1; x <= parseInt(dat.quantity); x++) {
              const body = {
                id: v4(),
                activity: dat.activity,
                quantity: 1,
                rating: 0,
                taskID: taskID,
                status: "Pendiente",
                comment: "Sin comentarios",
                price: 0,
                name: dat.name,
                createdAt: new Date(),
                temporalSubTask: data.temporalSubTask
              };

              APILI.createSubTask(body)
                .then((status) => {
                  console.log("status", status);
                })
                .catch((err) => {
                  console.log("err", err);
                });
            }
            
            Swal.fire({
              title: '¡Participación aprobada!', 
                icon: 'success',
                  timer: 1500})
          });
          
        }
        setTimeout(() => {
          updateTable();
        setRefresh(!refresh)
        setRefreshSubtasks(!refreshSubtasks)
        }, 2000);
        
      })
      .catch((err) => {
        console.log("err", err);
      });
  }; 
      
    
  
    if(data.delivery.name ==="Payment"){
        console.log(data.delivery.name)
        Swal.fire({
            title: 'Ninguna actividad pendiente!', 
            icon: 'success',
            timer: 1500
        })
        return
    }
    let aprobeTask = ''
    let dennyTask = ''
    if(data.delivery.name ==="Solicitado"){
      aprobeTask = 'Pendiente'
      dennyTask = 'Rejected'
  }
    if(data.delivery.name ==="Rejected"){
        aprobeTask = 'Pendiente'
        dennyTask = 'Rejected'
    }
    if(data.delivery.name ==="Pendiente"){
        aprobeTask = 'Active'
        dennyTask = 'Rejected'
    }
    if(data.delivery.name ==="Active"){
        aprobeTask = 'Completed'
        dennyTask = 'Pendiente'
    }
    if(data.delivery.name ==="Completed"){
        aprobeTask = 'Payment'
        dennyTask = 'Active'
    }


        Swal.fire({
            title: data.campaignName,
            html: 'EL usuario <strong>' + data.userID + '</strong> desea participar en la campaña'/*  + data.campaignName, */,
            icon: 'info',
            showCancelButton: true,
            showDenyButton: true,
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: '#00CA72',
            denyButtonText: '#d33',
            denyButtonText:
          '<i class="fa fa-thumbs-down"></i>',
            confirmButtonText:
          '<i class="fa fa-thumbs-up"></i> Aprobar',
        }).then((result) => {
            if (result.isConfirmed) {
                updateTaskStatus(data.id, aprobeTask, data) 
                updateTruers(data.campaignID)
                let body =  bodyNotification(data, 'Confirm'); 
                APILI.sendNotification(body)
                .then(data=>{
                    console.log({bodyNotification},"bodyNotification",data)
                    Swal.fire({
                        title: 'Confirmado!', 
                          icon: 'success',
                            timer: 1500})
                }).catch(err => {
                    console.log(err)
                })
              
            } else if (result.isDenied) {
                updateTaskStatus(data.id,dennyTask)
                let body =  bodyNotification(data, 'Rejected');  
                APILI.sendNotification(body).then(data=>{
                    console.log({bodyNotification},"bodyNotification",data)
                    Swal.fire({
                        title: 'Rechazado!', 
                          icon: 'error',
                            timer: 1500})
                }).catch(err => {
                    console.log(err)
                })   
              
            }
          })
     
}

const updateStatusSubTask = async (subTaskID) => {
  const body = {
    id: subTaskID,
    status: "Rejected",
  };
  console.log("bodySubTask", body);
  await APILI.updateSubTaskStatus(body)
    .then((res) => {
      console.log('response',res);
    })
    .catch((err) => {
      console.log('error',err);
    });
    setRefreshSubtasks(!refreshSubtasks)
};
const updateTaskStatus = async (taskID, taskStatus) => {
  const update = {
    id: taskID,
    status: taskStatus,
  };
  console.log("update", update);
  APILI.updateTaskStatusByID(update)
    .then((status) => {
      
      if(taskStatus == 'Aprobado')
      {
        Swal.fire("¡Aprobada!", "La tarea fue aprobada exitosamente", "success")
      }
      else if(taskStatus == 'Rejected')
      {
        if (subTasks.subTasks) {
          for (let i = 0; i < subTasks.subTasks.length; i++) {
            updateStatusSubTask(subTasks.subTasks[i].id);
          }
        }
        
        Swal.fire("Rechazada!", "La tarea fue rechazada exitosamente", "success")
      }
      setrefreshTable(!refreshTable);
    })
    .catch((err) => {
      console.log("err", err);
    });
}; 



const MySwal3 = async (data) => {
  console.log("data opciones",data)
  console.log("subTasks",subTasks.subTasks)
Swal.fire({
  title: 'Rechazar tarea',
  text: "Estás seguro de rechazar la tarea?",
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Rechazar',
  cancelButtonText: 'Cancelar',
}).then((result) => {
  if (result.isConfirmed) {
    updateTaskStatus(data.id, 'Rejected')   
  }
}) 
}

const MySwal4 = async (data) => {
  console.log("data opciones",data)
  console.log("subTasks",subTasks.subTasks)
Swal.fire({
  title: 'Aprobar tarea',
  text: "¿Estás seguro de aprobar la tarea?",
  icon: 'question',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Aprobar',
  cancelButtonText: 'Cancelar',
}).then((result) => {
  if (result.isConfirmed) {
    updateTaskStatus(data.id, 'Aprobado')
  }
}) 
}

  return (
    <div className="form-dashboard contTareas">
      <div className="Head">
        <i class="btnBack" onClick={() => setShowTask(false)} > <img src={IconBack} />Volver al listado de tareas</i>
        {/* {console.log('taskpage', taskPage)} */}
        {
          subTaskButton.length==0?
          (<button className="btnPendiente"  onClick={() => MySwal2(taskPage)}>Aprobar participación</button>):
          (<></>)
        }
        
      </div>
     
      
            
      <div className="container-boxes">
        <div className='box__grid'>
          <div class="title-grid">Información básica</div>
            <div className='box'>
              <div className='content'>
                <p> Usuario <span>{influencerData.username}</span></p>
              </div>
              <div className='content'>
                <p> Estado <span>{influencerData.status=='Active' ? 'Activo': influencerData.status}</span></p>
              </div>
              <div className='content'>
                <p> Edad <span>{calculateAge(influencerData?.dateBirth)}</span></p>
              </div>
              <div className='content'>
                <p> Sexo <span> {influencerData.gender}</span></p>
              </div>
          </div>
        </div>
        <div className='box__grid'>
          <div class="title-grid">Estado subtareas</div>
            <div className='box'>
              <div className="pieChart1">
                <ChartStatusSubtask/>
              </div>
          </div>
        </div>
        <div className='box__grid'>
          <div class="title-grid">Perfil</div>
            <div className='box'>
                <DataProfile
                currentProfile={currentProfile}
                />
          </div>
        </div>
        {/* <div  className='box__grid'>
          <div class="title-grid">Intereses</div>
            <div className='box'>
              <div className='content'>
                <p>Viajes</p>
                <p>Deportes</p>
                <p>Ambientalista</p>
              </div>
              <div className='content'>
                <p>Deportes</p>
              </div>
              <div className='content'>
                <p>Ambientalista</p>
              </div>
          </div>
        </div> */}
      </div>

      <div className="content-subtask1">
        <div class="title-grid">Subtareas</div>
        <div className="box">
          <Subtasks 
            taskPage={taskPage}
            subtasks={subTasks}
            setRefresh={(data) => setRefresh(data)}
            refresh={refresh}
            currentCampaign={currentCampaign}
            refreshTable={refreshTable} 
            setrefreshTable={setrefreshTable} 
            refreshSubtasks={refreshSubtasks}
            setRefreshSubtasks={setRefreshSubtasks}
            totalRating={subTasks.rating}
          />
        </div>
      </div>
      <div className="content-ModerationTask">
        <div class="title-grid">Moderación tarea</div>
        <div className="box">
          <div className="contStars">
            <StarsShow rating={subTasks.rating} />
            <p>Total Score: {subTasks.rating}</p>
          </div>
          <div className="contBtns">
            {(disabledButtonActiveTask==false)?
            (
              <button className="btn1" onClick={() => MySwal4(taskPage)}>Aprobar</button>
            ):(
              <></>
            )
              
            }
          
            <button className="btn2" onClick={() => MySwal3(taskPage)}>Rechazar</button>
          </div>
        </div>
      </div>
      {/* <div className="content-actions">  
        <div>
          <div className="row-info w-80">
            <div className="circle" />
            <p className="title-text"> Imagenes</p>
          </div>
          <div>

            <TargetContentSubTasks
              loading={loading}
              ratingTitle={(data) => setRatingTitle(data)}
              ratingId={(data) => setRatingId(data)}
              id={id}
              nameClass="targetContent-small contenedor-subtask "
              refresh={refresh}
              setRefresh={(data) => setRefresh(data)}
              subTasks={subTasks}
              saveRejectedTask={() => saveRejectedTask()}
              saveComment={(ratingId) => saveComment(ratingId)}
              totalReach={totalReach}
            />
          </div>
        </div>
      </div> */}
    </div>
  );
};


const mapDispatchToProps = dispatch => ({
  _showLoader: show => dispatch(showLoader(show))
})

const mapStateToProps = (state) => ({
  isLoading: state.app.isLoading,
  myUser: state.users.myUser
})

export default connect(mapStateToProps, mapDispatchToProps)(Task)
