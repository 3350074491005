import * as Actions from '../actions/app.actions'

const initialState = {
  isLoading: false,
  current_user: '',
  portal_name: "Let's Advertise",
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.APP_LOADED:
      return {
        ...state,
        isLoading: false,
      }
    case Actions.APP_SET_CURRENT_USER:
      return {
        ...state,
        current_user: action.user,
      }
    case Actions.APP_CLEAR_CURRENT_USER:
      return {
        ...state,
        current_user: '',
      }
    default:
      return state
  }
}
