import { APP_CLEAR_CURRENT_USER } from '../actions/app.actions'
import * as Actions from '../actions/clients.actions'

const initialState = []

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.CLIENTS_CLEAR:
      return []
    case Actions.CLIENTS_ADD_LIST:
      return [...state, action.clients]
    case Actions.CLIENTS_ADD_ONE:
      return [
        ...state.filter((client) => client.id !== action.client.id),
        action.client,
      ]
    case APP_CLEAR_CURRENT_USER:
      return initialState
    default:
      return state
  }
}
