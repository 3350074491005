import React from 'react';

import './asserts/css/styles.css';

const InfoBox = (props) => {

    const image = props.image;
    const num = props.num;
    const classNames = props.className;
    const text1 = props.text1;
    const text2 = props.text2;

    if (classNames === "rectangle-img-info-small") {
        return (
            <div className={classNames} >
                <img className="image" src={image} alt="Image"></img>
                <div className="text">
                    <p className="text1">{text1}</p>
                    <p className="text2">{text2}</p>
                </div>
            </div>
        )
    } else {
        return (
            <div className={classNames} >
                <img className="image" src={image} alt="Image"></img>
                <a className="number" href='/#'>{num}</a>
                <div className="text">
                    <p className="text1">{text1}</p>
                    <p className="text2">{text2}</p>
                </div>
            </div>
        )
    }
}

export default InfoBox
