import React, { useState, useEffect } from 'react'
import * as utils from 'data.js'
import * as APILI from 'helpers/apiLI';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import ModalModeration from 'components/modalModeration'

import { Auth } from 'aws-amplify';
import _ from 'lodash'
import { getLogoByName, getClassNameByStatus } from 'myUtil'
import DataTableUtils, {
    Share,
    ShowColumn,
    FilterColumn
} from '@easygosanet/react-datatable-utils'
import ReactDataTable from '@easygosanet/react-datatable'
import AddPost from 'components/addPost'
//images
import Logo_facebook from './assets/img/facebook.png'
///img/logo_facebook.svg'
import Logo_instagram from './assets/img/logo_instagram.png'
import Logo_tiktok from './assets/img/logo_tiktok.svg'
import logo_linkedin from './assets/img/logo_linkedin.svg'
import StarsRater from '../../starsRater';


import FaceIcon from '@mui/icons-material/Face';
import PrintIcon from '@mui/icons-material/Print';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CommentIcon from '@mui/icons-material/Comment';
import PreviewIcon from '@mui/icons-material/Preview';

import { AmplifyAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';

const Admin = () => {

    const[tables,setTables]= useState([]);
    const [showModal, setshowModal] = useState(false);
    let [taskTable, setTaskTable] = useState([])
    const [filters, setFilters] = useState({})
    const [dataModeration, setdataModeration] = useState('')
    const [showModeration, setshowModeration] = useState(false)
    const [refreshTable, setrefreshTable] = useState(false)
    const [refresh,setRefresh] = useState(false)
    
console.log(filters);

const MySwal = (data) => {
    console.log(data)
    let influencerName = ''
    let influencerLastName = ''
    console.log(influencerName)
    console.log(influencerLastName);

  const updateTaskStatus = async(taskID, taskStatus) => {
    const update = {
        id: taskID,
        status: taskStatus
    }
    const updateTable = ()=>{
        setrefreshTable(!refreshTable)
    }
    await APILI.updateTaskStatusByID(update).then((state) => {
        updateTable()
    })
    }

     
        Swal.fire({
            title: data.campaignName,
            text: 'Usuario:, ' + data.userID + ' Seguidores: '+ data.scope +
            'Desea participar del reto:, ' + data.campaignName,
            html:
    '<a href="//sweetalert2.github.io">Ver publicacion</a> ' +
    'and other HTML tags',
            icon: 'info',
            showCancelButton: true,
            showDenyButton: true,
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: '#00CA72',
            denyButtonText: '#d33',
            denyButtonText:
          '<i class="fa fa-thumbs-down"></i>',
            confirmButtonText:
          '<i class="fa fa-thumbs-up"></i> Aprobar',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                updateTaskStatus(data.id,"Active")      
              Swal.fire({
                  title: 'Confirmado!', 
                    icon: 'success',
                      timer: 1500})
            } else if (result.isDenied) {
                updateTaskStatus(data.id,"Rejected")
                Swal.fire({
                    title: 'Rechazado!', 
                      icon: 'error',
                        timer: 1500})
            }
          })
    
}

const WatchPost = (data) => {
    console.log(data)
    let influencerName = ''
    let influencerLastName = ''
    console.log(influencerName)
    console.log(influencerLastName);

  const updatePostStatusFN = async (postID, postStatus) =>{
    const update = {
        id: postID,
        status: postStatus
    }
    const updateTable = () => {
        setrefreshTable(!refreshTable)
    }
    await APILI.updatePostStatusByID(update).then((state) =>{
        updateTable();
    })
    }

     
        Swal.fire({
            title: data.campaignName,
            html: `Ver publicacion:  ${data.userID} ${data.campaignName}<b>test</b>
            `,  
            icon: 'info',
            showCancelButton: true,
            showDenyButton: true,
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: '#00CA72',
            denyButtonText: '#d33',
            denyButtonText:
          '<i class="fa fa-thumbs-down"></i>',
            confirmButtonText:
          '<i class="fa fa-thumbs-up"></i> Aprobar',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                console.log(data.id)
                updatePostStatusFN(data.id,"Active")      
              Swal.fire({
                  title: 'Confirmado!', 
                    icon: 'success',
                      timer: 1500})
            } else if (result.isDenied) {
                updatePostStatusFN(data.id,"Rejected")
                Swal.fire({
                    title: 'Rechazado!', 
                      icon: 'error',
                        timer: 1500})
            }
          })
    
}

const DeliveryCol = ({ text, className, onClick }) => (
    <div className={className} >
        <div className="delivery-text">
            {text}
        </div>
    </div>
)

const customStyles = {
    headCells: {
        style: {
            '&:nth-last-of-type(2)': {
                backgroundColor: '#E3E4E6',
                width: '40px'
            },
            '&:nth-last-of-type(4)': {
                backgroundColor: '#E3E4E6',
                width: '40px'
            }
        },
    },
}
    useEffect(() => {
        Auth.currentAuthenticatedUser().then((userData) => {
            APILI.getPostByUser({'influencerid':'lmenendez.tpp@gmail.com'}).then((influencerPosts) => {
                getDataView(influencerPosts.data.influencerPosts.filter(post => post.socialmedia === 'instagram' || post.socialmedia === 'Instagram' || post.socialmedia === 'INSTAGRAM'))
            })
        })
    }, [refreshTable, refresh])

    
    
    const goToEditPage = (id, name, createAt) => {
        let edit = {
            id,
            name,
            createAt,
        }
        //props._setEdit(edit)

        window.$.fancybox.open({
            src: '#new-category-modal',
            //type: 'modal',
            opts: {
                modal: true,
               // afterClose: () => props._clearEdit(),
            },
        })
    }

    const showModerationPopup = (data) => {
        setshowModeration(true)
        let objeto = {
            "title": data.campaignName,
            "date": data.date,
            "id": data.id,
            "name": data.name,
            "source": data.source,
            "username": data.userID,
        }
        setdataModeration(objeto)
       
    }
    const DetailCol = ({ text, data}) => (
        <div className="firstColl w-100">
            {/*<div className="icon-container">
                <i onClick={() =>WatchPost(data)}   className="fas fa-eye"></i>
                <i onClick={() => showModerationPopup(data)} className="fas fa-edit"></i>
                <i class="fas fa-trash"></i>
                <i class="fab fa-telegram-plane"></i>
                <i class="fas fa-ellipsis-v" ></i>
            </div>*/}
            <p className="name-fc">
                {text}
            </p>
        </div>
    )

    const [columns, setColumns] = useState([
        {
            name: 'Nombre del reto o tarea',
            selector: 'name',
            sortable: true,
            minWidth: '200px',
            style: {
                color: '#02025b',
            },
            cell: (row) => (
                <DetailCol
                    text={row.name}
                    data={row.id, row.name, row.createAt}
                />
            ),
        },
       /* {
            name: 'Estado',
            selector: 'delivery',
            sortable: true,
            center: true,
            style: {
                backgroundColor: '#E3E4E6',
                fontWeight: 'bold',
            },
            type: 'text',
            cell: (row) => (
                <DeliveryCol
                    text={row.status}
                    className={getClassNameByStatus(row.status)}
                    
                />
            )
        }, */
        {
            name: 'Caption',
            selector: 'hashtags',
            sortable: true,
            center: true,
            style: {
                fontWeight: 'bold',
            }
        },
        {
            name: 'Fecha de vencimiento',
            selector: 'scope',
            sortable: true,
            center: true,
            style: {
                backgroundColor: '#E3E4E6',
                fontWeight: 'bold',
            },
        },
        {
            name: 'Post',
            selector: 'source',
            sortable: true,
            center: true,
            style: {
                fontWeight: 'bold',
            },
            cell: (row) => <img  src={row.source} alt="" />
        },
        {
            name: 'Alcance',
            selector: 'inscription',
            sortable: true,
            center: true,
            style: {
                fontWeight: 'bold',
            },
            cell: (row) => <StarsRater id={row.id} />
        },
    ])

    const handleFilterPerColumn = (column, type, value) => {
        setFilters((prev) => ({ ...prev, [column]: { type, value } }))
    }

    const handleShowColumnChange = (column, show) => {
        let index = columns.findIndex((col) => col.selector === column)
        let aux = columns.map((col) => col)
        aux[index].omit = show
        setColumns(aux)
    }

    const handleColumnSortChange = (positions) => {

        let aux = columns.map((col) => col)

        positions.forEach((columnId, position) => {
            let index = columns.findIndex((col) => col.selector === columnId)
            aux[index].position = position
        })
        aux = _.orderBy(aux, ['position'])

        setColumns(aux)
    }

/******************************************************** */
const getDataView = (data) => {
  
    console.log(data)
    setTables({
        titles: [
            {
                name: 'Campaña',
                selector: 'message',
                sortable: true,
                minWidth: '200px',
                style: {
                    color: '#02025b',
                },
                cell: (row) => (
                    <DetailCol
                        text={row.message}
                        data={row}
                    />
                ),
            },
           /* {
                name: 'Estado',
                selector: 'delivery',
                sortable: true,
                maxWidth: '200px',
                center: true,
                style: {
                    backgroundColor: '#E3E4E6',
                    fontWeight: 'bold',
                },
                type: 'text',
                cell: (row) => (
                    <DeliveryCol 
                    onClick={() => setshowModeration(true)}
                        text={row.delivery.name}
                        className={row.delivery.className}
                    />
                )
            },*/

            {
                name: 'Comments',
                selector: 'comments_count',
                sortable: true,
                center: true,
                style: {
                    fontWeight: 'bold',
                },
                
                
            },
            {
                name: 'Likes',
                selector: 'likes_count',
                sortable: true,
                center: true,
                style: {
                    fontWeight: 'bold',
                },
                
                
            },
            {
                name: 'Engagement',
                selector: 'engagement',
                sortable: true,
                center: true,
                style: {
                    fontWeight: 'bold',
                },
                
                
            },
            {
                name: 'Impressions',
                selector: 'impressions',
                sortable: true,
                center: true,
                style: {
                    fontWeight: 'bold',
                },
                
                
            },
            {
                name: 'Saved',
                selector: 'saved',
                sortable: true,
                center: true,
                style: {
                    fontWeight: 'bold',
                },
                
                
            },
            {
                name: 'Reach',
                selector: 'reach',
                sortable: true,
                center: true,
                style: {
                    fontWeight: 'bold',
                },
                
                
            },
            {
                name: 'Tipo de Publicacion',
                selector: 'activity',
                maxWidth: '150px',
                sortable: true,
                center: true,
                style: {
                    backgroundColor: '#E3E4E6',
                    fontWeight: 'bold',
                },
            },
            {
                name: 'POST',
                selector: 'source',
                sortable: true,
                center: true,
                maxWidth: '120px',
                style: {
                    fontWeight: 'bold',
                },
                cell: (row) => <a href={row.urlPost} target="_blank" ><img style={{ maxWidth:"100px", maxHeight:"100px"}} className="w-70" src={row.source} alt="" /></a>
            },
            {
               name: 'Red Social',
               selector: 'socialMedia',
               maxWidth: '80px',
               sortable: true,
               center: true,
               style: {
                backgroundColor: '#E3E4E6',
                  fontWeight: 'bold',
                },
               cell: (row) => <a href={row.urlPost} target="_blank" ><img style={{ borderRadius:"50%"}}className="source-img" src={row.socialMedia} alt="" /></a>
            },
            {
                name: 'Rating',
                selector: 'rating',
                sortable: true,
                center: true,
                minWidth: '270px',
                style: {
                    fontWeight: 'bold',
                    
                
                },
                cell: (row) => <StarsRater rating={row.rating} id={row.id} updateRating={(dato1,dato2) => updateRating(dato1, dato2)} />
            },
        ],
        data: data.map(post => ({
          name: post.name,//post.message ? post.message : post.activity,
          delivery: {
            name: post.status,
            className: getClassNameByStatus(post.status)
        },
        campaignName: 'post.campaign,',
        hashtags: 'post.campaign,',
        activity: post.activity,
        source: post.picture,
        socialMedia: getLogoByName(post.socialmedia),
        rating: post?.rating ? post.rating : 0,
        id: post.id,
        userID: post.userID,
        urlPost: post.urlPost,
        message: post.message ? post.message : "No message",
       comments_count: post?.comments_count ? post.comments_count : 0,
       likes_count: post?.likes_count ? post.likes_count : 0,
       engagement: post?.insights?.engagement ? post.insights.engagement : 0,
       impressions: post?.insights?.impressions ? post.insights.impressions : 0,
       saved:  post?.insights?.saved ? post.insights.saved : 0, 
       reach: post?.insights?.reach ? post.insights.reach : 0
       // scope: {post.scope

        }))
    }
    )
  }

/******************************************************** */
  function getTasks(listCampaigns, category) {
    let myCampaigns = []
    listCampaigns.forEach(campaign => {
      campaign.mediaCategories.forEach(categoryE => {
        if (categoryE.name === category) {
          myCampaigns.push(campaign)
        }
      });
    });
    return myCampaigns
  }
/******************************************************** */
//update rating
/******************************************************** */
const updateRating = async (id, rate) =>  {
    
    const body={
        "id": id,
        "rating": rate
    }
    console.log(body)
    await APILI.updatePostRating(body).then(res => {
        setRefresh(!refresh)
    }).catch(err => {
        console.log(err)
    } )
}
/******************************************************** */
    return (
        <div>
             < AmplifySignOut className='desconectar'
        formFields={[
          {placeholder: "Cerrar Sesión",}
        ]}
           />
             <ReactDataTable
            columns={tables.titles}
            customStyles={customStyles}
            data={tables.data}
            persistTableHead
            noHeader
          />
        </div>
    )
}

export default Admin
