import * as Actions from '../actions/users.actions'

const initialState = {
    myUser: ' '
}


export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.ADD_USER:
            return {
                ...state,
                myUser: action.myUser
            }
        default:
            return state
    }
}


