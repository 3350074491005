/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Select from 'react-select'

import ReactDataTable from '@easygosanet/react-datatable'

import './assets/css/styles.css'

import {
  getPages,
  setProp,
  setDatos,
  setComments,
} from 'redux/actions/fanpageDatos.actions'
import { withUtils } from 'utils'

const Datos = (props) => {
  useEffect(() => {
    if (!document.getElementById('facebook-jssdk')) {
      ;(function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) {
          return
        }
        js = d.createElement(s)
        js.id = id
        js.src = 'https://connect.facebook.net/es_LA/sdk.js'
        fjs.parentNode.insertBefore(js, fjs)
      })(document, 'script', 'facebook-jssdk')
    }

    window.fbAsyncInit = () => {
      window.FB.init({
        version: 'v11.0',
      })
    }

    props.getPages()
  }, [])

  const changePage = (option) => {
    props.setProp({ name: 'selectedPage', value: option })
  }

  const consultar = (e) => {
    e.preventDefault()
    props.setDatos(props.selectedPage.token)
  }

  const consultarComentarios = (postId) => {
    props.setComments(postId, props.selectedPage.token)
  }
  
  const columnsAlcance = [
    {
      name: 'Fecha',
      selector: 'end_time',
      sortable: true,
      center: true,
    },
    {
      name: 'Alcance',
      selector: 'value',
      sortable: true,
      center: true,
      cell: (row) => JSON.stringify(row.value),

    },
  ]
  const columnsReaction = [
    {
      name: 'Fecha',
      selector: 'end_time',
      sortable: true,
      center: true,
    },
    {
      name: 'Reacciones',
      selector: 'value',
      sortable: true,
      center: true,
      cell: (row) => JSON.stringify(row.value),
    },
  ]
  
  const columnsPost = [
    {
      name: 'ID',
      selector: 'id',
      sortable: true,
      center: true,
      cell: (row) => (
        <span
          style={{
            color: '#02025b',
            cursor: 'pointer',
          }}
          onClick={() => consultarComentarios(row.id)}
        >
          {row.id}
        </span>
      ),
    },
    {
      name: 'Titulo Post',
      selector: 'message',
      sortable: true,
      center: true,
    },
    {
      name: 'Autor',
      selector: 'from',
      sortable: true,
      center: true,
      cell: (row) => {
        if (row.from) {
          return row.from.id + ' - ' + row.from.name
        }
        return ''
      },
    },
    {
      name: 'Fecha',
      selector: 'created_time',
      sortable: true,
      center: true,
    },
    {
      name: 'Imagen Post',
      selector: (row) => <img src={row.picture}/>,
      sortable: true,
      center: true,
    },
  ]

  const columnsComment = [
    {
      name: 'ID',
      selector: 'id',
      sortable: true,
      center: true,
    },
    {
      name: 'Mensaje',
      selector: 'message',
      sortable: true,
      center: true,
    },
    {
      name: 'Autor',
      selector: 'from',
      sortable: true,
      center: true,
      cell: (row) => {
        if (row.from) {
          return row.from.id + ' - ' + row.from.name
        }
        return ''
      },
    },
    {
      name: 'Fecha',
      selector: 'created_time',
      sortable: true,
      center: true,
    },
  ]

  return (
    <>
      <div>
        <Link className="btn-plus-form" to='/scan'>Regresar</Link>
      </div>
      <div>
        <label>Elegir fanpage:</label>
        <Select
          options={props.pages.map((page) => ({
            value: page.id,
            label: page.name,
            token: page.access_token,
          }))}
          onChange={changePage}
        />
      </div>
      <div hidden={!props.selectedPage}>
        <button className="btn-consultar" type='button' onClick={consultar}>
          Consultar
        </button>
        <div className='Kanbang-content grid'>
          <div className='List-category flex-container'>
            <div className='column'>
            <ReactDataTable
              title='Reacciones'
              columns={columnsReaction}
              data={props.reactions}
              pagination
            />
            </div>
            <div className='column'>
            <ReactDataTable
              title='Alcance'
              columns={columnsAlcance}
              data={props.alcance}
              pagination
            />
            </div>
          </div>
        </div>
        <div className='Kanbang-content'>
          <div className='List-category'>
            <ReactDataTable
              title='Posts'
              columns={columnsPost}
              data={props.posts}
              pagination
            />
          </div>
        </div>
        <div className='Kanbang-content'>
          <div className='List-category'>
            <ReactDataTable
              title={`Comentarios de Post ${props.postId}`}
              columns={columnsComment}
              data={props.comments}
              pagination
            />
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  pages: state.fanpageDatos.pages,
  selectedPage: state.fanpageDatos.selectedPage,
  posts: state.fanpageDatos.posts,
  postId: state.fanpageDatos.postId,
  reactions: state.fanpageDatos.reactions,
  alcance: state.fanpageDatos.alcance,
  comments: state.fanpageDatos.comments,
})

const mapDispatchToProps = {
  setProp,
  getPages,
  setDatos,
  setComments,
}

export default connect(mapStateToProps, mapDispatchToProps)(withUtils(withRouter(Datos)))
