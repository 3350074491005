import React, {useState} from 'react'
import * as utils from 'data.js'


import DataTableUtils, {
  Share
} from '@easygosanet/react-datatable-utils'

//Components
import TargetContent from 'components/targetContent'
import ImageBox from 'components/imageBox'

//images
import Logo_facebook from './assets/img/logo_facebook.svg'
import Logo_instagram from './assets/img/logo_instagram.png'
import Logo_tiktok from './assets/img/logo_tiktok.svg'
import logo_linkedin from './assets/img/logo_linkedin.svg'
import NewAdvertiser from 'components/newAdvertiser'



const Administration = (props) => {

  const [showModal, setshowModal] = useState(false);

  return (
    <div className='form-dashboard'>
      <div className='Bar-content'>
        <div className='content-menu list'>
          <select
            id='viewSelect'
            className='select-dashboard fa'
          >
            <option className='fa' value='1'>
              {' '}
            &#xf0ce; Espacio 1
          </option>
            <option className='fa' value='0'>
              {' '}
            &#xf0db; Vista de Kanban{' '}
            </option>
            <option className='fa' value='2'>
              {' '}
            &#xf073; Vista de calendario
          </option>
          </select>
          < DataTableUtils>
            <Share
              contacts={utils.myContacts}
            />
            <a id="colum-btn" className="btn-dashboard" href="/#">
            <i className="fas fa-columns"></i>
          </a>
          <a id="filter-btn" className="btn-dashboard" href="/#">
            <i className="fas fa-filter"></i>
          </a>
          </DataTableUtils>
          <div
            className='btnOrange-dash'
          >
            <i className='fas fa-plus' onClick={e => setshowModal(true)}></i>
          </div>
        </div>
      </div>
      <div className="row-section">
        <div className="row-info">
          <div className="circle" />
          <p className="title-text">   Campañas por red social</p>
        </div>
        <div className="content-section social-network2 ">
          <ImageBox className="rectangle-img-info" image={Logo_facebook} num="47" text1="Alcance promedio: 1,111" text2="Tasa de interaccion promedio: 1.17" />
          <ImageBox className="rectangle-img-info" image={Logo_instagram} num="18" text1="Alcance promedio: 1,111" text2="Tasa de interaccion promedio: 1.17" />
          <ImageBox className="rectangle-img-info" image={Logo_tiktok} num="10" text1="Alcance promedio: 1,111" text2="Tasa de interaccion promedio: 1.17" />
          <ImageBox className="rectangle-img-info" image={logo_linkedin} num="35" text1="Alcance promedio: 1,111" text2="Tasa de interaccion promedio: 1.17" />
        </div>
      </div>
      <div className="row-section row-three">
        <div className="content-section containers administration">
          <div>
            <div className="row-info">
              <div className="circle" />
              <p className="title-text">   Post por estado</p>
            </div>
            <TargetContent listGeneralStatistics={utils.campaignsByState} nameClass="targetContent-small" />
          </div>
          <div>
            <div className="row-info">
              <div className="circle" />
              <p className="title-text">   Estadísticas Generales</p>
            </div>
            <TargetContent listGeneralStatistics={utils.listGeneralStatistics} nameClass="targetContent 
              general-statistics" />
          </div>
        </div>
      </div>
      {showModal ? <NewAdvertiser title="Crear nuevo anunciante" showModal={setshowModal}/> : <div/>}
    </div>
  )
}

export default Administration
