import React, {useState, useEffect} from 'react';
import { Auth, Hub } from 'aws-amplify';
import NativeSelect from '@material-ui/core/NativeSelect';
import "react-google-flight-datepicker/dist/main.css";
import { useDispatch, connect } from 'react-redux';
import { showLoader } from 'redux/actions/loader.actions';
import InputModal from './inputModal';
import * as Modal from 'redux/actions/modal.actions'
import * as APILI from 'helpers/apiLI';
import { getLogoByName, getClassNameByStatus } from 'myUtil'
import Swal from 'sweetalert2/dist/sweetalert2.js'

const ModalModeration = ({ userRol, data, functionName, showModeration, _showAlertModal }) => {
// console.log("hello*******************")
const currentUser = JSON.parse(localStorage.getItem('CurrentUser')) ?? [];
// console.log(data)
const saveLog = async (body) => {
    try {
      APILI.createNewLog(body).then(res => {
        console.log('Log Creado')
      }).catch(err => {
      console.log('err', err) 
      })
      
  } catch (err) {
      console.log("No se pudo guardar el log", err)
  }
  }
  const saveErrorLog = async (body) => {
    try {
      APILI.createNewErrorLog(body).then(res => {
        console.log('Error log Creado')
      }).catch(err => {
      console.log('err', err) 
      })
      
  } catch (err) {
      console.log("No se pudo guardar el log de error", err)
  }
  }

const updateModeration = async (data, alert) => {
    console.log(alert)
    
    if (functionName ==='campaign'){
        const update = {
            id: data.id,
            state: alert.status
        }
    
        try {
            const response = await APILI.updateCampaignState(update);
             const bodyLog = {
            Tipo: "Update",
            UsuarioId: currentUser.username,
            UsuarioName: currentUser.attributes.name,
            Email: currentUser.attributes.email,
            Accion: "Modificar estado campaña",
            Data: {"id":data.id, "status":alert.status},
            Historico: {"id":update.id, "status":'En revisión'},
            CodeHttp: response.status
        }
        saveLog(bodyLog)
            
        } catch (error) {
            const bodyLog = {
                Tipo: "Update error",
                UsuarioId: currentUser.username,
                UsuarioName: currentUser.attributes.name,
                Email: currentUser.attributes.email,
                Accion: "Modificar estado campaña",
                Data: {"id":data.id, "status":alert.status},
                Historico: {"id":update.id, "status":'En revisión'},
                CodeHttp: error
            }
            saveErrorLog(bodyLog)
        }
    }
    const update = {
        id: data.id,
        status: alert.status
    }
    if (functionName ==='post'){
        APILI.updatePostStatusByID(update)
    }
    

    Swal.fire({
        title: alert.message, 
          icon: alert.icon,
            timer: 1500})
    showModeration(false)
}

const moderationAction = (value, data ) => {
    if (!value) {
   let alert= {
     status: 'Rejected',
     message : functionName+" rechazado",
     icon : 'error'
   }
   console.log(data.id)
   updateModeration(data, alert) 
    } else {
        let alert= {
            status: 'Active',
            message : functionName+" aprobado",
            icon : 'success'
          }
          updateModeration(data, alert) 
    }
}
let title = data.title
    return (
        <div className="blur-div">
            <div className="moderation-modal ">
                <div className="closeModal-content">
                    <i className="fas fa-times-circle" onClick={() => showModeration(false)}></i>
                </div>
                <div>
                    <h3 className="titleModal">{title}</h3>
                </div>
                <div className="body-modal ml-5 mr-5 display-block">
                   
                <span className="align-center"> <p classname="w-90">Descripción de la Campaña </p> <br/>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam est lacus, porta at ligula non, congue consequat odio. Pellentesque ullamcorper nunc nisl, eget fermentum libero venenatis eu. Nunc ut justo enim. Nunc sagittis vestibulum dui, vitae venenatis arcu efficitur semper. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Sed ornare magna diam, vel hendrerit libero faucibus id. Duis ultricies iaculis eros, quis efficitur lorem aliquam ac. In hac habitasse platea dictumst. In imperdiet mollis tempus. Mauris venenatis pulvinar ante non vehicula. Fusce malesuada, diam a posuere venenatis, neque purus posuere purus, quis lacinia turpis arcu at nibh. Quisque viverra mauris vel euismod suscipit. Curabitur mattis venenatis elementum. Phasellus dictum, lectus vel imperdiet mattis, dolor magna luctus nulla, tempor commodo neque felis non velit. Proin vehicula tortor at nibh ultrices bibendum. Nullam nec finibus est.

Phasellus nec ultrices odio, a lacinia mauris. Praesent quis dictum velit. Vivamus porttitor efficitur efficitur. Nam vitae ante vel augue rutrum imperdiet. Cras malesuada eros eu laoreet feugiat. Etiam consequat in dolor a mollis. Aliquam vitae condimentum metus, et ultricies mi.

Ut libero magna, venenatis quis quam eu, molestie sodales magna. Donec in purus a lorem ultricies tempus eu vel ex. Suspendisse tristique tristique odio vel consequat. Morbi vulputate ligula odio, in molestie leo euismod semper. Morbi eu sollicitudin nunc. Nam sed dolor metus. Praesent lorem nibh, sollicitudin at ex auctor, tempus lacinia urna. Suspendisse fringilla dapibus quam, quis pellentesque justo faucibus vel. Fusce eleifend nibh sed sollicitudin gravida. Pellentesque dignissim mollis tempor.

In semper facilisis tempus. Nunc id ultrices nunc, non mattis enim. Etiam maximus tristique odio, vitae gravida mauris sollicitudin vel. Nunc ut pellentesque velit, vel congue magna. Proin hendrerit faucibus feugiat. Suspendisse faucibus porta ex, id mollis mauris laoreet id. Suspendisse eu tincidunt risus. Maecenas luctus elementum ex nec semper.

Sed est velit, aliquet vitae libero quis, scelerisque posuere ligula. Etiam commodo lectus sit amet lacus malesuada, vel eleifend dolor hendrerit. Sed ultricies risus in erat porta, vitae imperdiet diam porttitor. Cras aliquet nisi nec turpis dictum euismod. Pellentesque mattis quam eget leo aliquam, vel eleifend justo auctor. Vestibulum posuere, risus non dictum maximus, quam nunc fringilla lacus, id posuere lorem risus nec turpis. Nulla est nibh, molestie eget leo at, fermentum tempus neque. Mauris volutpat enim a elementum imperdiet.

</span>
                </div>
                <div className="footer-Modal">
                    <div className="buttons-Moderation-Modal">
                        <button className={"delivery-stop"} onClick={() => moderationAction(false, data)}>Declinar</button>
                        <button className={"delivery-running"} onClick = {e => moderationAction(true, data) }>Aprobar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
    _showLoader: show => dispatch(showLoader(show)),
    _showAlertModal: (title, message, type, callback) => dispatch(Modal.showAlertModal(title, message, type, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalModeration)