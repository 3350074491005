import  React, {useState, useEffect} from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import CardMedia from '@mui/material/CardMedia';

import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Badge from '@mui/material/Badge';
import * as APILI from 'helpers/apiLI';


export default function TitlebarStoryList(props) {
  const {stories} = props;
  const [storyList, setStoryList] = useState([]);  

  return (

    <>
    <ImageList >
      {stories.map((item) => (
        <ImageListItem key={item.id}>
         {item.media_type==='IMAGE' && 
         <CardMedia sx={{paddingBottom:7.4}}
         component="img"
          image={item.media_url}
          title={item.title}

          
        />}
       {item.media_type==='VIDEO' && <CardMedia
        component="video"
          image={item.media_url}
          title={item.title}
          controls
         
        />}
         
          <ImageListItemBar
            title={item.media_product_type}
            subtitle={item.username}

            actionIcon={
              <IconButton>
              <InfoIcon color="action" />
             </IconButton>
            
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
    
    </>
  );
}
