import React from 'react'

// components
import MetaTags from '../../components/metatags'
import PasswordRecoveryForm from '../../components/auth/password_recovery_form'

const PasswordRecovery = props => (
  <>
    <MetaTags title='Recuperación de Contraseña' description='Página de recuperación de contraseña' />

    <PasswordRecoveryForm {...props} />
  </>
)

export default PasswordRecovery
