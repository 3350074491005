import React from 'react';
import Input from '@material-ui/core/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';

const InputCurrency = ({title, placeholder, value, change, type}) => {
  
  const tipo = type ? type : 'number'

  return(
    <>
      <p>{title}</p>
    <Input
            id="standard-adornment-amount"
      placeholder={placeholder} value={value>0&&value} onChange={e => change(e.target.value)} className="w-90" type={tipo} 
      startAdornment={<InputAdornment position="start">$</InputAdornment>}/>
      <div className="mb-15" />
    </>
  )
}

export default InputCurrency