import React, { useState, useEffect } from "react";
import FacebookLogin from "react-facebook-login";
import * as APILI from 'helpers/apiLI';
import { fontSize } from "@mui/system";
import './assets/css/facebook.css'
import {Table, Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';




export default function Facebook() {

    const [isLoggedIn, setisLoggedIn] = useState(false)
    const [userID, setUserID] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [picture, setPicture] = useState("")
    const [gender, setGender] = useState("")
    const [birthday, setBirthday] = useState("")
    const [link , setLink ] = useState("")
    const [reactions, setReactions] = useState([])
    const [comments, setComments] = useState([])
    const [dataPost,setDataPost] =useState([])
    const [accessToken, setAccessToken] = useState("")
    const [dataAccessExpirationTime, setDataAccessExpirationTime] = useState("")
    const [influenciador, setinfluenciador] = useState("")
    const [influenciadores, setinfluenciadores] = useState([])
    const [influencerId,setInfluencerId] = useState("")
    const [responsePost,setResponsePost] = useState([])
    const [estadoPost, setEstadoPost] = useState ("Pendiente")
    const [refreshTable, setrefreshTable] = useState(false)
    const [activeTab, setActiveTab] = useState('1');

    


    const savePost =  (data) => {
      
      let {posts } = data
      console.log(posts?.data)
      posts?.data?.forEach(element => {
        let hastags = ''
        if (element.to !== undefined) {
        if (element.to.data.length > 0) {
            element.to.data.forEach(dat => {
             hastags = hastags + dat.name + ','
           })}
           else {
             hastags = element.to.data[0].name
           }
     
        }
      //hastags === ''? hastags = 'Sin hashtags' : null  

      
        const body={
          "influencerid": data.email,
          "name":data.name,
        "createdAt": element.created_time.split("T")[0],
        "message":   element.message, 
        "id": element.id,
        "urlPost": element.link, 
        "socialmedia":data.graphDomain,
        "picture": element.picture, 
        "activity": element.type,
        "reactions": element.reactions,
        "status":"Pendiente",
        "campaign": hastags,
        "hastags":hastags
          }
         APILI.createPosts(body).then(res => {
          console.log(res) }).catch(err => {
          console.log(err)
        })
     
      
    
      });
    }
/*
  useEffect(() => {
    const fb2 = async () => {
      try {
        const data= await fetch(`https://graph.facebook.com/${userID}?fields=name,birthday,picture.type(large)&access_token=${accessToken}` )
        const userInfo = await data.json();
        console.log(userInfo)
      }
        catch(e){console.log(e)}
      }

      (async () => {
        const body = {
          fbuser: userID,
          id: userID,
          accessToken: accessToken,
          birthday: birthday,
          data_access_expiration_time: dataAccessExpirationTime,
          email: email,
          gender: gender,
          graphDomain: 'Facebook',
          link: link,
          picture: picture,
          influencerId: influencerId

        }
        console.log(body)
        try {
        //  dispatch( showLoader(true) )
         await APILI.createFacebookProfile(body)
         console.log("success")
        } 
        catch (err) {
          console.log("Error",err, body )
        }
      })()
  },[influencerId])

  useEffect(() => {
    (async () => {
     
      try {
        const body = {
          id:influenciador
        }
        //  dispatch( showLoader(true) )
         const response = await APILI.getInfluencerDynamic(body)
         console.log(response.data.influencer)
         setinfluenciadores(response.data.influencer)
      } catch (err) {
          console.log("Error",err )
      }
    })()
  },[influenciador])
 */
  const responseFacebook = (response) => {
    console.log("####################",response );
    savePost(response)
    if (response.status !== "connected") {
    
     setisLoggedIn(true) 
     setUserID(response.userID) 
     setAccessToken(response.accessToken)
     setBirthday(response.birthday)
     setDataAccessExpirationTime(response.data_access_expiration_time)
     setEmail(response.email)
     setGender(response.gender)
     setName(response.name)
     setPicture(response.picture.data.url)
     setLink(response.link )
     setResponsePost(response?.posts?.data)
     let array = []
     response?.posts?.data.forEach( (post) => {
       let check = true
       let response = ''
       if (!post.message || !post.id  || !post.link  || !post.picture  ||  !post.picture  || !post.type ) {
          check = false
          if (!post.message) {
            response = 'Falta titulo'
          }
          if (!post.id) {
            response = 'No tiene id'
          }
          if (!post.link) {
            response = 'Falta link'
          }
          if (!post.picture) {
            response = 'Falta imagen!'
          }
          if (!post.type) {
            response = 'Falta tipo de publicacion!'
          }
        }
      let object =  {
      "created_time": post.created_time, 
      "message": post.message,
      "id": post.id, 
      "link": post.link, 
      "picture": post.picture, 
      "type": post.type,
      "status":check,
      "response":response
    }
      array.push(object)
     
      })
      getDataPost(array)
      console.log(dataPost)
      
    }
    
  };


const getDataPost =  (data) => {
  setDataPost(data)
}
  const handleClick = () => {
    console.log("clicked");
  };
  {/*for (let x = 0; x < responsePost.length; x++) {
    const obj = responsePost[x];
    obj.reactions.data.type
    }*/}

    const MySwal = (dato) => {
    
      console.log(dato)

      const accion = (dat,stat) => {
        
        const indexToUpdate = dataPost.findIndex((todo) => todo.id === dat.id);
        const updatedTodos = [...dataPost];
        updatedTodos[indexToUpdate].status = stat
        setDataPost(updatedTodos)
      }
          Swal.fire({
              showCancelButton: true,
              showDenyButton: true,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: '#00CA72',
              denyButtonText: '#d33',
              denyButtonText:'<i class="fa fa-thumbs-down"></i>',
              confirmButtonText:'<i class="fa fa-thumbs-up"></i> Aprobar',
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                accion(dato,"Active")      
              Swal.fire({
                  title: 'Confirmado!', 
                    icon: 'success',
                      timer: 1500})
            } else if (result.isDenied) {
              accion(dato,"No Aprobado")
                Swal.fire({
                    title: 'Rechazado!',
                      text: 'Falta Imagen Post', 
                      icon: 'error',
                        timer: 2000})
            }
          })
      }

      const MyGift = () => {
        if (gender === 'male') {
          Swal.fire({
            imageUrl: 'https://blogger.googleusercontent.com/img/a/AVvXsEjgmmWUQuM6B_L1wX6QsaY9RqaKCc-a6FJvDbX25kLUXplmjKc1bz4oeebtj6bL3vctbjH8jHiXMd7iR0hqutQGksC56SkRWsUdOk41z3xocKFU9v3BHDa5GkkEd-h5lDvzQSqw8urb1Np4wRL6qswWf159mFGSnnOAPTSUzm1B7to9gyRyyHCq8uXwcw=s2048',
            imageWidth: 450,
            imageHeight: 300,
            text: 'Valido Unicamente En tu fecha de Cumpleaños',
          })
        } else {
          Swal.fire({
            imageUrl: 'https://blogger.googleusercontent.com/img/a/AVvXsEiq8fVbhkKDn9Wqp5zWKfMoJxQ2lKWzNvvehoK3Xd_LCrYgn39n7pn3k0hXTCfji9N7r_4obQS4u0_FQZuXAmhg9JNU3duhjD2ezIKBCly3aTHEmSkrarfrEBnW-LbD34s4bxu4zqpg53kTK5S5PBBT7wplvR8250wlyVBvhoRF56TS7HLxVaH4jiDOGg=s2048',
            imageWidth: 450,
            imageHeight: 300,
            text: 'Valido Unicamente En tu fecha de Cumpleaños',
          })
        }
      }

      

    let fbContent;

    if (isLoggedIn) {
      fbContent = (
        <div
          style={{
            width: "100%",
            margin: "auto",
            background: "#f4f4f4",
            padding: "20px"
          }}
        >
<Nav tabs>
        <NavItem>
          <NavLink style={{fontSize: "1.1rem", fontWeight:"bold", color: "#000",}} className={activeTab == '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
            Perfil
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink style={{fontSize: "1.1rem", fontWeight:"bold", color: "#000",}} className={activeTab == '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
            Mis Post
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1"> 
          <br/>
            Mi Perfil
          <br/>
          <br/>
          <br/>
          <img src={picture} alt={name} />
          <h2>¡Bienvenido {name}!</h2>
          <div>
            Email: {email}
          </div>
          <div>
            Perfil: <a href={link} target="blanc"> Ver perfil </a>
          </div>
          <div>
            Genero: {gender} 
          </div>
          <div>
            Cumpleaños: {birthday}
          </div>
          <div>
          <Button onClick={() =>MyGift()} color="danger" style={{marginTop: "15px", fontSize: "1.8rem",}}> Ver cupon de cumpleaños</Button>
          </div>
        </TabPane>
        <TabPane tabId="2">
        <br/>
        <br/>
        <h2>Posts De {name}</h2>
        <br/>
        <br/>
        <div> 
          <Table>
            <thead>
              <th>Titulo Post</th>
              <th>Tipo de Post</th>
              <th>Fecha Creación</th>
              <th>Id Post</th>
              <th>Ir al Post</th>
              <th>Estado</th>
              <th>Validación</th>
            </thead>
            <tbody>
             {dataPost.map(dat =>(
              <tr>
               <td><p className="title">{dat.message}</p></td>
               <td><p className="title">{dat.type}</p></td>
               <td><p className="date">{dat.created_time}</p></td>
               <td><p className="id">{dat.id}</p></td>
               <td><a className="url" href={dat.link} target="blanc"><img src={dat.picture}/></a> </td>
               <td>{dat.status ? <CheckCircleOutlineIcon/> : <CancelIcon/>}</td>
               <td><p className="text-validacion">{dat.response}</p></td>
               
               
              </tr>      
             ))}
            </tbody>
          </Table>
          </div>
          </TabPane>
      </TabContent>
  
 {/* <h4> Selecciona un influenciador: </h4> */}
 {/*<input onChange={e => setinfluenciador(e.target.value)} className="w-100" type="text" placeHolder="Ingresa un correo electronico"></input>*/}
        </div>
      );
    } else {
      fbContent = (
        <FacebookLogin
          appId="556458266055799"
          autoLoad={false}
          fields="name,email,picture.type(large),birthday,gender,link,location,age_range,accounts"
          onClick={handleClick}
          callback={responseFacebook}
          icon="fa fa-facebook"
        />
      );
    }
    return <div>{fbContent}{influenciadores ? influenciadores.map(data => 
     (<p onClick={e => setInfluencerId(data.id)}>{data.id}</p>) ) : null }
     </div>;
  
}
