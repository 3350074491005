import React, {useState, useEffect} from 'react';
import Dropzone from 'libs/dropzone';
import { Auth, Hub } from 'aws-amplify';
import { v4 } from 'uuid'
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from "@material-ui/core/InputLabel";
import Divider from "@material-ui/core/Divider";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Container, Col, Row} from "react-bootstrap"

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import Slider from '@material-ui/core/Slider';  
import Input from '@material-ui/core/Input';
import { RangeDatePicker } from "react-google-flight-datepicker";
import './assets/css/styles.scss';


import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';

import "react-google-flight-datepicker/dist/main.css";

import Tag from './tag';
import * as APILI from 'helpers/apiLI';
import * as STORAGE from 'helpers/storageLI';
import * as Logger from 'utils/logger'
import { useDispatch, connect } from 'react-redux';
import { showLoader } from 'redux/actions/loader.actions';
import AutoComplete from './autoComplete';
import InputModal from 'components/newAdvertiser/inputModal';
import InputCurrency from './inputCurrency';
import * as Modal from 'redux/actions/modal.actions'
import Swal from 'sweetalert2'
import Maps from "./Maps"
import { isBuffer } from 'lodash-es';

const TAG = 'Images'
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const NewCampaign = ({title, showModal, _showAlertModal, campaign,refresh, setRefresh }) => {
  const currentUser = JSON.parse(localStorage.getItem('CurrentUser')) ?? [];
  const [age, setAge] = React.useState('');

    const handleChangelist = (event) => {
      setAge(event.target.value);
    };

    const [user, setUser] = useState(null)
    const [calculoPercent,setCalculoPercent] = useState(false)
    const [markers, setMarkers] = useState([]);
    const [reward, setReward] = useState(0);
    const [editablePercent, setEditablePercent] = useState(false)
    const [alcancePercent,setAlcancePercent] = useState(0)
    const [interaccionPercent,setInteraccionPercent] = useState(0)
    const [bestpostPercent,setBestPostPercent] = useState(0)
    const [hashtagText, setHashtagText] = useState('');
    const [advertiser, setAdvertiserText] = useState('');
    const [name, setNameText] = useState('');
    const [slogan, setSloganText] = useState('');
    const [tags, setTags] = useState([]);
    const [snackBarText, setSnackBarText] = useState('');
    const [description, setDescriptionText] = useState('');
    const [presupuesto, setPresupuestoText] = useState('');
    const [initDate, setInitDateText] = useState(new Date());
    const [endDate, setEndDateText] = useState(new Date());
    const [ov, setOvText] = useState('');
    const [logo, setLogo] = useState([])
    const [postImages, setPostImages] = useState([])
    const [advertisers, setAdvertisers] = useState([])
    const [categories, setCategories] = useState([])
    const [nonCategories, setNonCategories] = useState([])
    const [socialNetworks, setSocialNetworks] = useState([])
    const [open, setOpen] = useState(false);
    const [showMap, setShowMap] = useState(true)
    const [openNonCat, setOpenNonCat] = useState(false);
    const [openSocial, setOpenSocial] = useState(false);
    const [categoryTags, setCategoryTags] = useState([]);
    const [openCountry, setOpenCountry] = useState(false);
    const [inputCountry, setInputCountry] = useState(''); 
    const [counter, setCounter] = useState(0);
    const [operator, setOperator] = useState(false)
    //const [influenciadores, setinfluenciadores] = useState(0)
    const [influencersCategory, setInfluencersCategory] = useState('')
    const [socialMediaPrices, setsocialMediaPrices] = useState([])
    const [availableFormats, setAvailableFormats] = useState([])
    const [totalFormats, setTotalFormats] = useState(0)
    const [countFormats, setCountFormats] = useState(0)
    const [averageCPM, setaverageCPM] = useState(0)
    const [totalInfluencers, settotalInfluencers] = useState(0)
    const [activity, setActivity] = useState([])
    const [profitPercent, setProfitPercent] = useState(80.00)
    const [remainderPercent, setRemainderPercent] = useState(20.00)
    const [totalProfit, setTotalProfit] = useState(0)
    const [totalRemainder, setTotalRemainder] = useState(0)
    const [totalAlcance, setTotalAlcance] = useState(0)
    const [totalCreatividad, setTotalCreatividad] = useState(0)
    const [totalInteraction, setTotalInteraction] = useState(0)
    const [minSocialStatus, setMinSocialStatus] = useState({})
    const [minSocialStatuss, setMinSocialStatuss] = useState({})
    const [maxSocialStatus, setMaxSocialStatus] = useState({})
    const [maxSocialStatuss, setMaxSocialStatuss] = useState({})
    const [reachTotal, setReachTotal] = useState(0)
    const [maxInfluencers, setMaxInfluencers] = useState(0)
    const [minInfluencers, setMinInfluencers] = useState(0)
    const [maxRating, setMaxRating] = useState(0)
    const [predictedInteraction, setPredictedInteraction] = useState(0)
    const [categoriesAux,setCategoriesAux] = useState([]) 
    const [genderCampaign, setGenderCampaign] = useState([])
    
  //  const [totalInteraction,setTotalInteraction] = useState(0)
   // alcancePercent,interaccionPercent, profitPercent,remainderPercent])


  const redOptions = { color: 'blue' }

    ///////////////////////non category
    const [nonCategoryTags, setNonCategoryTags] = useState([]);
    const [socialTags, setSocialTags] = useState([]);
    const [countryTags, setCountryTags] = useState([]);
    const [moderator,setModerator] = useState([]);

    const dispatch = useDispatch()
    const loading = open && categories.length === 0;
    const loadingNonCat = openNonCat && nonCategories.length === 0;
    const loadingSocial = openSocial && socialNetworks.length === 0;

//snackbar

    const [openSnackbar, setOpenSnackbar] = useState(false);



    const handleCloseSnackBar = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnackBarText('')
      setOpenSnackbar(false);
    };

    let rangos={}
    rangos=[
      {
        name:"Bajo",
        id: 0
      },
      {
        name:"Medio-Bajo",
        id: 1
      },
      {
        name:"Medio",
        id: 2
      },
      {
        name:"Medio-Alto",
        id: 3
      },
      {
        name:"Alto",
        id: 4
      }
  ]
    let gender = ["Masculino", "Femenino", "Ambos"]

    let edad = []
    for (let x=16; x<=70; x++){
        edad.push(x)
    }
    const getUser = () => {
        return Auth.currentAuthenticatedUser()
        .then((userData) => userData)
        .catch(() => console.log('Not signed in'))
    }

    const useStyles = makeStyles({
        root: {
          width: 300,
        },
        input :{
           height:40
        }
      });
      /*quitar categorias de lista */
      const uptadeCategories = () => {
        let cats = categories 
        for(let i = 0; i < categoryTags.length; i++)
        {
          console.log('categoryTags[i]',categoryTags[i])
          let categoriesFiltered = cats.filter((item) =>
          item.name!=categoryTags[i].name
        )
        cats = categoriesFiltered
        /* setMediaCategories(categoriesFiltered) */
        }
        /* console.log('cats', cats) */
        setCategories(cats)
      }
      useEffect(() => {
        uptadeCategories()
      }, [open])
      /*quitar categorias excluyentes de lista */
      const uptadeNoCategories = () => {
        let cats = nonCategories 
        for(let i = 0; i < nonCategoryTags.length; i++)
        {
          console.log('categoryTags[i]',nonCategoryTags[i])
          let categoriesFiltered = cats.filter((item) =>
          item.name!=nonCategoryTags[i].name
        )
        cats = categoriesFiltered
        /* setMediaCategories(categoriesFiltered) */
        }
        /* console.log('cats', cats) */
        setNonCategories(cats)
      }
      useEffect(() => {
        uptadeNoCategories()
      }, [openNonCat])
    
      const btnGroupStyle = makeStyles((theme) => ({
        root: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          '& > *': {
            margin: theme.spacing(1),
          },
        },
      }));

      const groupBtnClass = btnGroupStyle();

      function valuetext(value) {
        return value;
      }

    useEffect(() => {
        let active = true;

        if (!loading) {
          return undefined;
        }
        (async () => {
          const res = await APILI.getMediaCategories()
          if (active && res.data && res.data.categories && res.data.categories.length > 0) {
            setCategories(res.data.categories)
            setNonCategories(res.data.categories)
            setCategoriesAux(res.data.categories)
          }
        })();

        return () => {
          active = false;
        };
    }, [loading]);

    useEffect(() => {
        let active = true;

        if (!loadingSocial) {
          return undefined;
        }

        (async () => {
          const res = await APILI.getSocialNetworks()

          if (active && res.data && res.data.socialNetworks && res.data.socialNetworks.length > 0) {

            setSocialNetworks(res.data.socialNetworks)
          }
        })();

        return () => {
          active = false;
        };
    }, [loadingSocial]);

    let minIndex;
    
    // const [budgetEditar,setBudgetEditar] = useState(0) 

    useEffect(() => {
      if(campaign){
        // setBudgetEditar(campaign.budget)
        console.log('campaña recivida', campaign)
        setAdvertiserText(campaign.advertiser)
        setCategoryTags(campaign.mediaCategories)
        setNonCategoryTags(campaign.nonMediaCategories)
        setMinAge(new Number(campaign.ageValue[0]))
        setMaxAge(new Number(campaign.ageValue[1]))
        setNameText(campaign.name)
        setSloganText(campaign.slogan)
        setTags(campaign.hastags)
        setHashtagText('')
        let initDateSplit = campaign.initDate.split('/')

        setInitDateText(new Date(initDateSplit[1]+'/'+initDateSplit[0]+'/'+initDateSplit[2]))
        

        let finalDateSplit = campaign.endDate.split('/')
        console.log('endDate', finalDateSplit[1]+'/'+finalDateSplit[0]+'/'+finalDateSplit[2])
        setEndDateText(new Date(finalDateSplit[1]+'/'+finalDateSplit[0]+'/'+finalDateSplit[2]))
        setDescriptionText(campaign.description)
        setMarkers(campaign.locationList)
        setGenderCampaign(campaign.genderCampaign)
        /* setSocialTags(campaign.campOriginal.socialNetworks) */ 
       
        //  setBudgetEditar(campaign.budget)
        // console.log('Hola recibiendo budget o presupuesto ',campaign.budget)
        
        /*const myArray = campaign.budget.split("$");
        let numberBudget = Number(myArray[1])
        setPresupuestoText(numberBudget)*/
        
        
        
        setPresupuestoText(campaign.budget)
        // console.log('Hola recibiendo bukkdget o presupuesto22 ',campaign.budget)
        /* for(let i = 0; i < rangos.length; i++){
          if(campaign.socialStatusValue[0] ===  rangos[i].name){
            minIndex=i;
            console.log('rango minimo',rangos[i])
            console.log('rango minimo campaign.socialStatusValue[0]' ,campaign.socialStatusValue[0])
          }
          if(campaign.socialStatusValue[1] === range.name){
            setMaxSocialStatus(range)
            console.log('rango maximo',range)
          }
        } */
        setProfitPercent(campaign.totalProfit / 10) 
        console.log("porcentajeMargen", campaign.totalProfit, totalProfit)
        setRemainderPercent(campaign.totalRemainder /10)

       
        
        if(campaign.campOriginal.countryOrZone){
          setCountryTags(campaign.campOriginal.countryOrZone)
        }
        rangos.forEach(range=>{
          
          if(campaign.socialStatusValue[0] ===  range.name){
            setMinSocialStatuss(range)
            setMinSocialStatus(range)
            console.log('rango minimo',range)
            console.log('rango minimo campaign.socialStatusValue[0]' ,campaign.socialStatusValue[0])
          }
          if(campaign.socialStatusValue[1] === range.name){
            setMaxSocialStatuss(range)
            setMaxSocialStatus(range)
            console.log('rango maximo',range)
          }
        })
        if(campaign.logo.length>0){
          let arrayAux=[]
          for(let i=0;i<campaign.logo.length;i++){
            if(campaign.logo[i].name){
              STORAGE.getUrlImage(campaign.logo[i].name).then(res => {
                arrayAux.push({
                  name: campaign.logo[i].name,
                  link: res.toString()
                })
              })
            }
          }
          setLogo(arrayAux)
        }
        if(campaign.postImages.length>0){
          
          let arrayAux=[]
          for(let i=0; i<campaign.postImages.length; i++){
            if(campaign.postImages[i].name){
              STORAGE.getUrlImage(campaign.postImages[i].name).then(res => {
                arrayAux.push({
                  name: campaign.postImages[i].name,
                  link: res.toString()
                })
              })
            }
          }
          setPostImages(arrayAux)
        }

      }
        
        Hub.listen('auth', ({ payload: { event } }) => {
          switch (event) {
            case 'signIn':
            case 'cognitoHostedUI':
              getUser().then((userData) => {
                  setUser(userData)
                  setModerator(userData.attributes['custom:moderator'])
                })
              break
            case 'signOut':
              setUser(null)
              break
            default:
              break
          }
        })

        getUser().then((userData) => setUser(userData))

        APILI.getAdvertiser().then(res => {
            if (res.data && res.data.advertisers && res.data.advertisers.length > 0) {
              let advertiserFiltered = res.data.advertisers.filter((item) =>
                item.status=="Active"
              )
                setAdvertisers(advertiserFiltered)
            }
        }).catch(console.error)


        APILI.getSocialMediaUsers().then(res => {
            if (res.data && res.data.socialmediausers && res.data.socialmediausers.length > 0) {
               /*  console.log("Social mediaUsers",res.data.socialmediausers) */
                setInfluencersCategory(res.data.socialmediausers)
            }
        }).catch(console.error)

        APILI.getSocialMediaPrice().then(res => {
            if (res.data && res.data.socialmediaprice && res.data.socialmediaprice.length > 0) {

                setsocialMediaPrices(res.data.socialmediaprice)
                /* console.log('socialMediaPrice',res.data.socialmediaprice) */
                if(campaign){
                  saveSocialTagInit(campaign.campOriginal.socialNetworks[0],res.data.socialmediaprice,campaign.budget)
                 
                }
            }
        }).catch(console.error)
        if(!campaign){
          setMinSocialStatus(rangos[0])
        }
    }, [])
    useEffect (() =>{
        let influencerspivot =0;

        for (let i = 0; i < influencersCategory.length; i++){

               influencerspivot = influencerspivot + influencersCategory[i]['total']

           }
           settotalInfluencers(influencerspivot)
    })

       // settotalInfluencers(influencerspivot);

    useEffect(() => {

     //   setReward( parseInt(presupuesto * .33 / (presupuesto/averageCPM*1000/3500)))
        setTotalProfit((presupuesto * (profitPercent/100)).toFixed(2))
     // eslint   setTotalInteraction((presupuesto * (interactionPercent/100)).toFixed(2))
        setTotalRemainder((presupuesto * (remainderPercent/100)).toFixed(2))

    }, [presupuesto,bestpostPercent,alcancePercent,profitPercent,remainderPercent])



    useEffect(() => {
        setTotalCreatividad((presupuesto * (bestpostPercent/100) * (remainderPercent/100)).toFixed(2))
        setTotalInteraction((presupuesto * (interaccionPercent/100) * (remainderPercent/100)).toFixed(2))
        setTotalAlcance((presupuesto * (alcancePercent/100) * (remainderPercent/100)).toFixed(2))


  }, [presupuesto,bestpostPercent,interaccionPercent,alcancePercent,remainderPercent])

  // .filter(tag => tag.name === textHastag.trim())
  // console.log("quantityInfluencers",quantityInfluencers)

  useEffect(() => {
    if(categoryTags.length>0){
      let arrayAux=[]
      for (let i = 0; i < categoryTags.length; i++){
        arrayAux=nonCategories.filter(item => categoryTags[i].name !== item.name)
      }
      setNonCategories(arrayAux)
    }else{
      setNonCategories(categoriesAux)
    }
  } , [categoryTags])

    const saveTag = e => {
        if (e.code === 'Enter' || e.code ==='Space' && hashtagText.trim().length > 0) {
            const textHastag = hashtagText.includes('#') ? hashtagText : `#${hashtagText.trim()}`
            let newTags = tags.filter(tag => tag.name !== textHastag.trim())
            if (hashtagText.trim().length > 0) {
                newTags.push({name:textHastag.trim()})
                setTags(newTags)
            }
            setHashtagText('')
        }
    }

    const saveCategoryTag = cat => {
        if (cat) {
            const textHastag = cat.name
            let newTags = categoryTags.filter(tag => tag.name !== textHastag.trim())
            let uid = v4();
            let quantityInfluencers = influencersCategory
                .filter(tag => tag.name === textHastag.trim())
                console.log("quantityInfluencers",quantityInfluencers)

              // let quantityField=0;
              // try{
              //   let objeto = {
              //     uid: v4(),
              //     name: cat.name,
              //     operador: false,
              //     quantity: quantityInfluencers[0].total
              //   }
              //   newTags.push( objeto )
              //   setCategoryTags([...categoryTags, objeto])

              // }catch(err){
              //   setErrorCategory('No puede agregar una categoria si el influencer no la tiene asignada')
              //   quantityField=0;
              // }


              let objeto = {
                uid: v4(),
                name: cat.name,
                operador: false,
                quantity: quantityInfluencers[0]?.total
              }
              newTags.push( objeto )
              setCategoryTags([...categoryTags, objeto])
            //--------------------------------------

           //setinfluenciadores(influenciadores+quantityInfluencers[0].total)

        }
    }

   const handleUpdateOperator = (e, tag) => {
    const indexToUpdate = categoryTags.findIndex((todo) => todo.uid === tag.uid);
    const updatedTodos = [...categoryTags];

    for(let i = 0; i < updatedTodos.length; i++)
    {
      updatedTodos[i].operador = !updatedTodos[i].operador;
    }
    
    console.log('updateTodos', updatedTodos)
    setCategoryTags(updatedTodos);


    /*if(categoryTags[indexToUpdate].operador=== true) {
        setinfluenciadores(influenciadores-parseInt(updatedTodos[indexToUpdate].quantity))
    }
    if(categoryTags[indexToUpdate].operador=== false) {
            setinfluenciadores(influenciadores-parseInt(updatedTodos[indexToUpdate].quantity))
        }
      */
  }

//average cpm
  /*useEffect(() => {
    setaverageCPM(totalFormats/countFormats);
    },[totalFormats,countFormats]);
*/

  const handleUpdateFormat = (e, format) => {
    e.preventDefault();
    const indexToUpdate = availableFormats.findIndex((todo) => todo.id === format.id);
    const updatedTodos = [...availableFormats];
    
    updatedTodos[indexToUpdate].quantity = e.target.value
    

    setAvailableFormats(updatedTodos)
    calcularCPM(updatedTodos)

    let formatsToSave = updatedTodos.filter(tag => parseInt(tag.quantity) !==0)
    setActivity(formatsToSave)
  }

  const handleUpdateFormatInit = (newArr,budget) => {
    console.log('newArr', newArr)
    setAvailableFormats(newArr)
    console.log('formats',availableFormats)

    function formatNumber(number) {
      return new Intl.NumberFormat('es-GT').format(Number.parseInt(number.replace(/(,)/, ""),10))
    }
    

    let myArray = budget.split("$");
    let numberBudget2 = formatNumber(myArray[1])
    let numberBudget3= numberBudget2.replace(",", "")
    console.log("numeroooo", numberBudget3)


    let auxArr= newArr
    console.log('como viene',newArr)
    console.log('campaign',campaign.activity)
    campaign.activity.forEach(item => {
      console.log('item',item)
      const indexToUpdate = newArr.findIndex((todo) => todo.id === item.id);
    console.log('indexToUpdate',indexToUpdate)
    newArr[indexToUpdate].quantity = item.quantity
    console.log('newArrFinal',newArr)
    calcularCPMEditar(newArr,budget)
    /* const indexToUpdate = auxArr.findIndex((todo) => todo.id === item.id);
    console.log('indexToUpdate',indexToUpdate)
    const updatedTodos= [...auxArr];
    console.log('updatedTodos',updatedTodos)
    updatedTodos[indexToUpdate].quantity = item.quantity
    console.log('updatedTodosAct',updatedTodos)
    setAvailableFormats(updatedTodos)
    calcularCPM(updatedTodos)
    auxArr=updatedTodos */
    })
    setActivity(campaign.activity)
    
//CALCULADON INTERACCION ESTIMADA
    console.log("hola desde calcularInteraccion")
    let acumulator = 0
    let sumaProducto = []
    let count = 0
    let presupuestoIndividual = []
    let acumulatorInteraction = 0
    newArr.forEach(value => {
        if(parseInt(value.quantity)>0){
          acumulator = acumulator + (parseFloat(value.quantity)*parseInt(value.price))
          sumaProducto.push(parseFloat(value.quantity)*parseInt(value.price))
        }
    })
   
    
    
    console.log("sumaProducto",sumaProducto)
    console.log("acumulator",acumulator)
    sumaProducto.forEach(value => {
      
      console.log("value de suma producto",acumulator)
        presupuestoIndividual.push(value/acumulator*numberBudget3)

    })
    console.log("presupuestoIndividual ", budget)
    console.log("antes availableFormats ahora newArr ",newArr)

    newArr.forEach(value => {
        if(parseInt(value.quantity)>0){
          acumulatorInteraction = acumulatorInteraction + (presupuestoIndividual[count]/value.price*1000*value.percent)
          count = count + 1
           // individualInteraction = individualInteraction + (parseFloat(value.percent)*parseInt(value.price))
        }
        console.log("calculoInteracion", )
    })
    
    console.log("hola desde calcularInteraccion 2", acumulatorInteraction)
    setPredictedInteraction(acumulatorInteraction)

    // CALCULADON INFLUENCIADORES SOLICITADOS
      // setMaxInfluencers(calcularInfluenciador(2500))
      // setMaxRating(calcularInfluenciador(2500)*5)
      setMinInfluencers(editarCalcularInfluenciador(newArr,3500,numberBudget3));
      setMaxInfluencers(editarCalcularInfluenciador(newArr,2500,numberBudget3));
      setMaxRating(editarCalcularInfluenciador(newArr,2500,numberBudget3)*5);

      let alcancePor= (new Number(campaign.totalAlcance) / new Number(campaign.totalRemainder))*100
      let interaccionPor= (new Number(campaign.totalInteraction) / new Number(campaign.totalRemainder))*100
      let creatividadPor= (new Number(campaign.totalCreatividad) / new Number(campaign.totalRemainder))*100
      console.log('ALCANCE', alcancePor)
      setAlcancePercent(alcancePor)
      setInteraccionPercent(interaccionPor)
      setBestPostPercent(creatividadPor)
    // return parseInt(acumulatorInteractio.n)

    /* let auxArr= newArr
    console.log('como viene',newArr)
    console.log('campaign',campaign.activity)
    campaign.activity.forEach(item => {
      console.log('item',item)
    const indexToUpdate = auxArr.findIndex((todo) => todo.id === item.id);
    console.log('indexToUpdate',indexToUpdate)
    const updatedTodos= [...auxArr];
    console.log('updatedTodos',updatedTodos)
    updatedTodos[indexToUpdate].quantity = item.quantity
    console.log('updatedTodosAct',updatedTodos)
    setAvailableFormats(updatedTodos)
    calcularCPM(updatedTodos)
    auxArr=updatedTodos
    })
    setActivity(campaign.activity) */
  }
  const editarCalcularInfluenciador=(newArr,valor,budget)=>{
    console.log('editarCalcularInfluenciador 1 ',valor)
    let acumulator = 0
    let sumaProducto = []
    let count = 0
    let presupuestoIndividual = []
    let acumulatorInteraction = 0
    newArr.forEach(value => {
        if(parseInt(value.quantity)>0){
          acumulator = acumulator + (parseFloat(value.quantity)*parseInt(value.price))
          sumaProducto.push(parseFloat(value.quantity)*parseInt(value.price))
        }
    })
    console.log(acumulator,sumaProducto)
    sumaProducto.forEach(value => {
        presupuestoIndividual.push(value/acumulator*budget)

    })
    newArr.forEach(value => {
        if(parseInt(value.quantity)>0){
         acumulatorInteraction = acumulatorInteraction + ((presupuestoIndividual[count]/value.price*1000)/valor/value.quantity)
        // console.log("testing",presupuestoIndividual, presupuestoIndividual[count]/value.price*1000,valor,value.quantity)
          count = count +1
           // individualInteraction = individualInteraction + (parseFloat(value.percent)*parseInt(value.price))
        }
    })
    console.log('cuenta cada vez ',parseInt(acumulatorInteraction/count))
    return parseInt(acumulatorInteraction/count)
  }
  const updateBudget = (number) => {
     setPresupuestoText(number)
  }

  const calcularCPMEditar = (format,budget) => {
    console.log("Hola desde calcularCPM ", format)
      let count = 0
      let sum = 0
      let avg = 0


      let sumatoria= format.forEach(value => {
          if(parseInt(value.quantity)>0){
                count= count+parseInt(value.quantity)
                sum = sum +(parseInt(value.quantity)*parseInt(value.price))
                avg = (sum / count).toFixed(2)
                console.log("entraste para el quantity ",avg)
          }
      })
      setaverageCPM(avg)

      // Eh aqui el clavo del camote
      function formatNumber(number) {
        return new Intl.NumberFormat('es-GT').format(Number.parseInt(number.replace(/(,)/, ""),10))
      }
      console.log("Hola desde calcular presupuesto editable ",budget)
      let myArray = budget.split("$");
      let numberBudget = formatNumber(myArray[1])
      console.log("numero sin $", myArray[1])
      let numberBudget2= numberBudget.replace(",", "")
      
      updateBudget(numberBudget2)
      
      console.log("numero sin p", presupuesto)
      console.log("Hola desde calcular presupuesto por editar ",presupuesto)
      console.log("Hola desde calcular averageCPM ",averageCPM) 
      setReachTotal(parseInt((numberBudget2/avg) * 1000)) 
      // setMaxRating(calcularInfluenciador(2500)*5)
      console.log("ver Presupuesto", budget )
  
      
      // setPredictedInteraction(calcularInteraccion())

  }
  const calcularCPM = (format) => {
      let count = 0
      let sum = 0
      let avg = 0

      let sumatoria= format.forEach(value => {
          if(parseInt(value.quantity)>0){
                count= count+parseInt(value.quantity)
                sum = sum +(parseInt(value.quantity)*parseInt(value.price))
                avg = (sum / count).toFixed(2)
          }
      })
      setaverageCPM(avg)

  }

  const calcularInteraccion = () => {
    /* console.log("hola desde calcularInteraccion") */
    let acumulator = 0
    let sumaProducto = []
    let count = 0
    let presupuestoIndividual = []
    let acumulatorInteraction = 0
    availableFormats.forEach(value => {
        if(parseInt(value.quantity)>0){
          acumulator = acumulator + (parseFloat(value.quantity)*parseInt(value.price))
          sumaProducto.push(parseFloat(value.quantity)*parseInt(value.price))
        }
    })
    /* console.log(acumulator,sumaProducto) */
    sumaProducto.forEach(value => {
        presupuestoIndividual.push(value/acumulator*presupuesto)

    })
    /* console.log("availableFormats ",availableFormats) */

    availableFormats.forEach(value => {
        if(parseInt(value.quantity)>0){
          acumulatorInteraction = acumulatorInteraction + (presupuestoIndividual[count]/value.price*1000*value.percent)
          count = count +1
           // individualInteraction = individualInteraction + (parseFloat(value.percent)*parseInt(value.price))
        }
    })
    /* console.log("hola desde calcularInteraccion",acumulatorInteraction) */
    return parseInt(acumulatorInteraction)
  }
  const calcularInfluenciador = (valor) => {
    let acumulator = 0
    let sumaProducto = []
    let count = 0
    let presupuestoIndividual = []
    let acumulatorInteraction = 0
    availableFormats.forEach(value => {
        if(parseInt(value.quantity)>0){
          acumulator = acumulator + (parseFloat(value.quantity)*parseInt(value.price))
          sumaProducto.push(parseFloat(value.quantity)*parseInt(value.price))
        }
    })
    /* console.log(acumulator,sumaProducto) */
    sumaProducto.forEach(value => {
        presupuestoIndividual.push(value/acumulator*presupuesto)

    })
    availableFormats.forEach(value => {
        if(parseInt(value.quantity)>0){
         acumulatorInteraction = acumulatorInteraction + ((presupuestoIndividual[count]/value.price*1000)/valor/value.quantity)
        // console.log("testing",presupuestoIndividual, presupuestoIndividual[count]/value.price*1000,valor,value.quantity)
          count = count +1
           // individualInteraction = individualInteraction + (parseFloat(value.percent)*parseInt(value.price))
        }
    })
    return parseInt(acumulatorInteraction/count)
  }

  useEffect(() => {
    setReachTotal(parseInt((presupuesto/averageCPM) * 1000))
    setMinInfluencers(calcularInfluenciador(3500))
    setMaxInfluencers(calcularInfluenciador(2500))
    setMaxRating(calcularInfluenciador(2500)*5)
    // console.log("Hola si estas entrando ",reachTotal)


    setPredictedInteraction(calcularInteraccion())


  },[presupuesto,availableFormats])

 //console.log(reachTotal)

    const saveNonCategoryTag = noncat => {
        if (noncat) {
            const textHastag = noncat.name
            let newTags = nonCategoryTags.filter(tag => tag.name !== textHastag.trim())
            newTags.push(noncat)
            setNonCategoryTags(newTags)
        }
    }
    ////////////////////country tag
    const saveCountryTag = noncat => {
        if (noncat) {
            const textHastag = noncat.name
            let newTags = countryTags.filter(tag => tag.name !== textHastag.trim())
            newTags.push(noncat)
            setCountryTags(newTags)
        }
    }

    const saveSocialTagInit = (cat,availablePrice,budget) => {
      // console.log("socialTags.length",socialTags.length)
      if (cat && socialTags.length === 0 ) {
          const textHastag = cat.name
          let newTags = socialTags.filter(tag => tag.name !== textHastag.trim())
          newTags.push(cat)
          // console.log('socialMediaPrice',socialMediaPrices)
          setSocialTags(newTags)
          let availablePrices = availablePrice.filter(tag => tag.name === textHastag.trim()) 
          let checkTags = socialTags.some(tag => tag.name === textHastag.trim())
          // console.log("checkTags",checkTags)
          if(!checkTags) {
              let newArr = availableFormats.concat(availablePrices);
              setAvailableFormats(newArr)
              console.log("Array a enviar",newArr)
              handleUpdateFormatInit(newArr,budget)
          } else if(socialTags.length !== 0) {
          }
      }
  }

    const saveSocialTag = cat => {
        if (cat && socialTags.length === 0) {
            const textHastag = cat.name
            let newTags = socialTags.filter(tag => tag.name !== textHastag.trim())
            newTags.push(cat)
            setSocialTags(newTags)
            let availablePrices = socialMediaPrices.filter(tag => tag.name === textHastag.trim())

            let checkTags = socialTags.some(tag => tag.name === textHastag.trim())
            if(!checkTags) {
                let newArr = availableFormats.concat(availablePrices);
                setAvailableFormats(newArr)

            } else if(socialTags.length !== 0) {


            }



        }
    }


    const handleFileChange = files => {
        console.log(files);
    }

    const handleUpload = async files => {
        dispatch( showLoader(true) )

        const logoArr = logo.map(image => (image))
        console.log(logoArr)
        //STORAGE.uploadFile(files)
        for (const file of files) {
            let options = {
                contentType: file.type,
                progressCallback(progress) {
                Logger.log(TAG, `uploaded: ${progress.loaded}/${progress.total}`)
                }
            }

            const uploadedFile = await STORAGE.uploadFile(file.name, file, options)
            const url = await STORAGE.listFiles(uploadedFile.key)
            const newLogo = {
                name: uploadedFile.key,
                link: url
            }
            logoArr.push(newLogo)
            Logger.log(TAG, newLogo)
        }
        if (logoArr.length > 0) {
            setLogo(logoArr)
        }
        dispatch( showLoader(false) )
    }

    const handleUploadPostImages = async files => {
        dispatch( showLoader(true) )
        const imagesArr = postImages.map(image => (image))
        //STORAGE.uploadFile(files)
        for (const file of files) {
            let options = {
                contentType: file.type,
                progressCallback(progress) {
                Logger.log(TAG, `uploaded: ${progress.loaded}/${progress.total}`)
                }
            }

            const uploadedFile = await STORAGE.uploadFile(file.name, file, options)
            const url = await STORAGE.listFiles(uploadedFile.key)
            const newImage = {
                name: uploadedFile.key,
                link: url
            }
            console.log("NEWIMAGE::: ",newImage)
            imagesArr.push(newImage)
            Logger.log(TAG, newImage)
        }
        if (imagesArr.length > 0) {
            setPostImages(imagesArr)
        }
        dispatch( showLoader(false) )
    }

    const deleteTag = tagName => { 

        const newTags = tags.filter(tag => tag.name !== tagName)
        setTags(newTags)
    }
    const deleteCountryTag = tagName => {

        const newTags = tags.filter(tag => tag.name !== tagName)
        setCountryTags(newTags)
    }

    const deleteCategoryTag = (e, tagName) => {

        let auxArray= nonCategories
        auxArray.push(tagName)
        setNonCategories(auxArray)
        console.log("NonCategories",nonCategories )
        const textHastag = tagName.name
        const newTags = categoryTags.filter(tag => tag.uid !== tagName.uid)
        let quantityInfluencers = influencersCategory
                .filter(tag => tag.name === textHastag.trim())
        //setinfluenciadores(influenciadores-quantityInfluencers[0].total)
        setCategoryTags(newTags)
    }
    const deleteNonCategoryTag = nonTagName => {

        const textHastag = nonTagName.name

        const newTags = nonCategoryTags.filter(tag => tag.name !== nonTagName)

        setNonCategoryTags(newTags)
    }

    const deleteSocialTag = tagName => {

        const newTags = socialTags.filter(tag => tag.name !== tagName)
        setSocialTags(newTags)
        let sum = 0
        let count = 0
        for (let i = 0; i < availableFormats.length; i++) {
            if (availableFormats[i].name===tagName && availableFormats[i].status) {
                sum=sum+parseFloat(availableFormats[i].price)

                //availableFormats[i].status=false;
                count++


            }
        }
        setTotalFormats(totalFormats-sum);
        setCountFormats(countFormats-count);
        let newArr = availableFormats
                .filter(tag => tag.name !== tagName)
        setAvailableFormats(newArr)

    }

    const deleteImageLoader = image => {
        console.log(image)
        const newArr = postImages.filter(img => img.name !== image.name)
        setPostImages(newArr)
    }

    const deleteLogoLoader = image => {
        console.log(image)
        const newArr = logo.filter(img => img.name !== image.name)
        setLogo(newArr)
    }

    const saveLog = async (body) => {
      let res = await APILI.createNewLog(body)
      console.log('responseCampaing', res)
    }

    const saveErrorLog = async (body) => {
      try {
        APILI.createNewErrorLog(body).then(res => {
          console.log('Error log Creado')
        }).catch(err => {
        console.log('err', err) 
        })
        
    } catch (err) {
        console.log("No se pudo guardar el log de error", err)
    }
    }
    
    let confirmation =false;

    const saveCampaing = async (e) => {
      var tipoAccion = ""
      var Accion = ""
      
  //  console.log("tipo de presupuesto",typeof(parseInt(presupuesto)))
      
    if ( !advertiser ) {  setSnackBarText("Por favor agregue anunciante de campaña"); setOpenSnackbar(true); return }
    else if (!presupuesto ) {  setSnackBarText("Por favor agregue presupuesto"); setOpenSnackbar(true); return }
    else if (parseInt(presupuesto)<0 ) {  setSnackBarText("El presupuesto debe ser positivo"); setOpenSnackbar(true); return }
    
    else if (categoryTags.length === 0 ) {  setSnackBarText("Por favor agregue categorias de campaña"); setOpenSnackbar(true); return }
   // else if (nonCategoryTags.length === 0 ) {  setSnackBarText("Por favor agregue categorias Excluyentes"); setOpenSnackbar(true); return }
   else if (minSocialStatus.name ==='' || maxSocialStatus.name==='' ) {  setSnackBarText("Por favor agregue rango de edades"); setOpenSnackbar(true); return }
   else if (minSocialStatus.name ==='' || maxSocialStatus.name==='' ) {  setSnackBarText("Por favor agregue status socio economico"); setOpenSnackbar(true); return }
   else if (name ==='' || name==='' ) {  setSnackBarText("Ingresa nombre de la campaña"); setOpenSnackbar(true); return }
   else if (!slogan ) {  setSnackBarText("Por favor agregue slogan de campaña"); setOpenSnackbar(true); return }
   else if (tags.length === 0 ) {  setSnackBarText("Por favor agregue hashtags de campaña"); setOpenSnackbar(true); return }
   else if (!description ) {  setSnackBarText("Por favor agregue descripcion de campaña"); setOpenSnackbar(true); return }
   else if (socialTags.length === 0 ) {  setSnackBarText("Por favor agregue una red social"); setOpenSnackbar(true); return }
   
   else if (!presupuesto ) {  setSnackBarText("Por favor agregue presupuesto"); setOpenSnackbar(true); return }
   else if (parseInt(presupuesto)<0 ){  setSnackBarText("El presupuesto debe ser positivo"); setOpenSnackbar(true); return }
   
   else if (logo.length === 0 ) {  setSnackBarText("Por favor agregue logo del anunciante"); setOpenSnackbar(true); return }
   else if (postImages.length === 0 ) {  setSnackBarText("Por favor agregue imagenes de publicación"); setOpenSnackbar(true); return }
  else if(activity.length<1)  {setSnackBarText("Por favor agregue al menos una subtask"); setOpenSnackbar(true); return }
   
    else if (influencersCategory.length === 0 ) {  setSnackBarText("La campaña no puede tener 0 influencer seleccionados, revise sus filtros"); setOpenSnackbar(true); return }
    else if (!initDate ) {  setSnackBarText("Por favor agregue fecha de inicio"); setOpenSnackbar(true); return }
    else if (!endDate ) {  setSnackBarText("Por favor agregue fecha de fin"); setOpenSnackbar(true); return }
    else if (!name ) {  setSnackBarText("Por favor agregue titulo de campaña"); setOpenSnackbar(true); return }
    else if (genderCampaign === "Elige un género" || genderCampaign == "" ) {  setSnackBarText("Por favor selecciona un género"); setOpenSnackbar(true); return }
    else if (markers.length === 0 ) {  setSnackBarText("Por favor agregue ubicacion de campaña"); setOpenSnackbar(true); return }
    else if(true===(100!==parseFloat(bestpostPercent)+parseFloat(alcancePercent)+parseFloat(interaccionPercent))) {
      setSnackBarText('La suma de Alcance, Interaccion, Creatividad tiene que ser 100%');
         setOpenSnackbar(true); return}

    else if(true===(100!==parseFloat(remainderPercent)+parseFloat(profitPercent))) {
          setSnackBarText('La suma de Ganancia y Restante tiene que ser 100%');
         setOpenSnackbar(true); return}


   else {
    let body={}
    let res = await APILI.getAdvertiserById({id:advertiser})
    Swal.fire(
      { 
        title: 'Guardar campaña',
         text: "Por favor verifica tus cambios antes de continuar",
          icon: 'warning',
           showCancelButton: true,
            confirmButtonColor: '#3085d6',
             cancelButtonColor: '#d33',
              confirmButtonText: 'Guardar'
             }).then((result) => { 
              if (result.isConfirmed) {
                console.log("confirmation1",confirmation)
                dispatch(showLoader(true))
                
                if(campaign)
                {
                  tipoAccion="Update"
                  Accion="Modificar campaña"
                  body = {
                    id: campaign.id,
                    account: user.username,
                    advertiser,
                    advertiserName:res?.data?.advertiser[0]?.name,
                    mediaCategories: categoryTags,
                    nonMediaCategories: nonCategoryTags,
                    genderCampaign: genderCampaign,
                    ageValue: [new Number(minAge), new Number(maxAge)],
                    socialStatusValue: [minSocialStatus.name, maxSocialStatus.name],
                    name,
                    moderator:moderator,
                    slogan,
                    hastags: tags,
                    description,
                    socialNetworks: socialTags,
                    ov,
                    influencers:[],
                    // initDate: initDate.toLocaleDateString(),
                    // endDate: endDate.toLocaleDateString(), 0
                    initDate: initDate.toLocaleDateString("es-GT"),
                    endDate: endDate.toLocaleDateString("es-GT"),
                    logo,
                    postImages,
                    state: campaign.state,
                    countryOrZone: countryTags,
                    locationList: markers,
                    CPM: averageCPM,
                    budget: presupuesto,
                    activity: activity,
                    totalAlcance: totalAlcance,
                    totalInteraction: totalInteraction,
                    totalProfit: totalProfit,
                    totalRemainder: totalRemainder,
                    totalCreatividad: totalCreatividad,
                    reach: reachTotal, //parseInt(presupuesto/averageCPM) * 1000,
                    interaction: predictedInteraction,
                    minInfluencers: minInfluencers,
                    maxInfluencers: maxInfluencers,
                    maxRating: maxRating,
                }
                }
                else
                {
                  tipoAccion="Insert"
                  Accion="Crear nueva campaña"
                  body = {
                    id: v4(),
                    account: user.username,
                    advertiser,
                    advertiserName:res?.data?.advertiser[0]?.name,
                    mediaCategories: categoryTags,
                    nonMediaCategories: nonCategoryTags,
                    genderCampaign: genderCampaign,
                    ageValue: [new Number(minAge), new Number(maxAge)],
                    socialStatusValue: [minSocialStatus.name, maxSocialStatus.name],
                    name,
                    moderator:moderator,
                    slogan,
                    hastags: tags,
                    description,
                    socialNetworks: socialTags,
                    ov,
                    influencers:[],
                    // initDate: initDate.toLocaleDateString(),
                    // endDate: endDate.toLocaleDateString(), 0
                    initDate: initDate.toLocaleDateString("es-GT"),
                    endDate: endDate.toLocaleDateString("es-GT"),
                    logo,
                    postImages,
                    state: 'En Revision',
                    countryOrZone: countryTags,
                    locationList: markers,
                    CPM: averageCPM,
                    budget: presupuesto,
                    activity: activity,
                    totalAlcance: totalAlcance,
                    totalInteraction: totalInteraction,
                    totalProfit: totalProfit,
                    totalRemainder: totalRemainder,
                    totalCreatividad: totalCreatividad,
                    reach: reachTotal, //parseInt(presupuesto/averageCPM) * 1000,
                    interaction: predictedInteraction,
                    minInfluencers: minInfluencers,
                    maxInfluencers: maxInfluencers,
                    maxRating: maxRating,
                    approvedTruers: "0"
                }
                }

                APILI.createCampaign(body)
                .then((res)=>{
                  Swal.fire('¡Campaña creada con éxito!', '', 'success')
                    const bodyLog = {
                      Tipo: tipoAccion,
                      Accion: Accion,
                      Data: body,
                      Historico: campaign,
                      CodeHttp: res.status,
                      UsuarioId: currentUser.username,
                      UsuarioName: currentUser.attributes.name,
                      Email: currentUser.attributes.email,
                  }
                  saveLog(bodyLog)
                  setRefresh(!refresh)
                  dispatch( showLoader(false) )
                  showModal(false)
                })
                .catch((err)=>{
                  Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'No se pudo crear la campaña'
                  })

                  const bodyLog = {
                    Tipo: tipoAccion + 'error',
                    Accion: Accion,
                    Data: body,
                    Historico: campaign,
                    CodeHttp: err,
                    UsuarioId: currentUser.username,
                    UsuarioName: currentUser.attributes.name,
                    Email: currentUser.attributes.email,
                }
                saveErrorLog(bodyLog)
                dispatch( showLoader(false) )
                })
            } 
        })
    }
    }
    const onDateChange = (startDate, endDate) => {
        startDate ? setInitDateText(startDate) : setInitDateText('')
        endDate ? setEndDateText(endDate) : setEndDateText('')
    }

   //   const decrease = (e) => {
   //     console.log(range);
   //     if (range>=5) {
    //      setRange( range-1 );
    //    }
    //  }

    const classes = useStyles();
    const [minAge,setMinAge] = useState(16);
    const [maxAge,setMaxAge] = useState(0);

    const [socialStatusValue, setSocialStatusValue] = useState('');
    const [socialValue, setSocialValue] = useState([1, 100]);
    const [totalValues, settotalValues] = useState(0)

   /* useEffect(() => {
        if ( (totalInfluencers>=influenciadores && totalValues<=totalInfluencers))
        settotalValues(totalInfluencers-influenciadores);
        },[totalInfluencers, influenciadores]);
*/
   /* const handleChange = (e, newValue) => {
      setAgeValue(newValue);
    };*/
    const handleChange2 = (e, newValue) => {
        setSocialValue(newValue)
        let min=''
        let max=''

            if (newValue[0]< 20 ) {
                min="Bajo";
            }
            if (newValue[0]>20 && newValue[0] <40)
            {
                min="Medio";
            }
            if (newValue[0]>40 && newValue[0] <60) {
                min="Medio-Alto";
            }
            if (newValue[0]> 60) {
                min="Alto";
             }

            if (newValue[1]< 20 ) {
                max="Bajo";
            }
            if (newValue[1]>20 && newValue[1] <40)
            {
                max="Medio";
            }
            if (newValue[1]>40 && newValue[1] <60) {
                max="Medio-Alto";
            }
            if (newValue[1]> 60) {
                max="Alto";
             }

        setSocialStatusValue({"minStatus":min, "maxStatus":max})
      };


    const marks = [
        {
            value: 0,
            label: 'Bajo',
          },
          {
            value: 25,
            label: 'Medio-Bajo',
          },
          {
            value: 50,
            label: 'Medio',
          },
          {
            value: 75,
            label: 'Medio-Alto',
          },
          {
            value: 100,
            label: 'Alto',
          },
      ];
      const marksAge = [
          {
            value: 20,
            label: 20,
          },
          {
            value: 30,
            label: 30,
          },
          {
            value: 40,
            label: 40,
          },
          {
            value: 50,
            label: 50,
          },
          {
            value: 60,
            label: 60,
          },
          {
            value: 70,
            label: 70,
          },
          {
            value: 80,
            label: 80,
          },
      ];

   const addminRango= (value)=>{
     console.log('valor rango',value)
    const myArray = value.split("|");
    setMinSocialStatus(
      {
        name:myArray[0],
        id:myArray[1]
      }
    )
    console.log("addminRango",value)
   }

   const addmaxRango= (value)=>{
    const myArray = value.split("|");
    setMaxSocialStatus(
      {
        name:myArray[0],
        id:myArray[1]
      }
    )
    console.log("addminRango",value)
   }

    const closeModal=()=>{
    Swal.fire(
       { 
         title: '¡Alerta!',
          text: "¿Estas seguro que deseas salir?",
           icon: 'warning',
            showCancelButton: true,
             confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
               confirmButtonText: 'Continuar'
              }).then((result) => { 
               if (result.isConfirmed) {
                showModal(false)
                 } 
         })
    }
    function valideKey(evt){
      let code = (evt.which) ? evt.which : evt.keyCode;
      if(code==8) { 
        return true;
      } else if(code>=48 && code<=57) {
        return true;
      } else{
        return false;
      }
    }

    function justNumbers(e){
      let keynum = window.event ? window.event.keyCode : e.which;
        if ((keynum == 8) || (keynum == 46))
          return true; 
          return /\d/.test(String.fromCharCode(keynum));
      }
      /* console.log("cambios", presupuesto) */
      const percentage = (totalValue) => {
        let partialValue = campaign.totalProfit
        return ((100 * partialValue) / totalValue).toFixed(2);
     } 
      const percentage2 = (totalValue) => {
        let partialValue = campaign.totalRemainder
        return ((100 * partialValue) / totalValue).toFixed(2);
     } 
//////////////////////////////////////////////MAPAS
  return (
    <div className="blur-div">
      <div className="content-modal">
        <div className="closeModal-content">
          <i className="fas fa-times-circle" onClick={e => closeModal()}></i>
        </div>
        <div>
          <h3 className="titleModal">{title}</h3>
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
              <Alert onClose={handleCloseSnackBar} severity="error" sx={{ width: '100%' }}>
                {snackBarText}
              </Alert>
            </Snackbar>
          </Stack>
        </div>
        <div className="body-modal">
          <div className="part-BodyModal">
            <p>Anunciante</p>
              <NativeSelect value={advertiser} onChange={e => setAdvertiserText(e.target.value)} name="age" className="w-90">
                <option value="" disabled>Selecciona un anunciante</option>
                  {advertisers.map(adr => (<option value={adr.id}>{adr.name}</option>))}
              </NativeSelect>
            <div className="mb-15"/>
            <p>Categoría</p>
              <AutoComplete 
                data={categories} 
                open={open} 
                loading={loading} 
                setOpen={setOpen} 
                saveTag={saveCategoryTag} 
                placeholder="  Ingresa categoria" 
              />
            <div className="tagContent">
              {categoryTags.map(tag => (<>
                <Tag 
                  key={tag.uid} 
                  name={tag.name} 
                  deleteTag={(e) => deleteCategoryTag(e, tag)} 
                />
                <span onClick={(e) =>
                  {handleUpdateOperator(e, tag)}}>
                  {tag.operador ? 'and' : 'or' }{" "}
                </span>
              </>))}
            </div>
            <div className="mb-15"/>
            <p>Categoría Excluyente</p>
              <AutoComplete 
                data={nonCategories} 
                open={openNonCat} 
                loading={loadingNonCat} 
                setOpen={setOpenNonCat} 
                saveTag={saveNonCategoryTag} 
                placeholder="  Ingresa categoria excluyente" 
              />
            <div className="tagContent">
              {nonCategoryTags.map(tag => (<Tag key={tag.name} name={tag.name} deleteTag={deleteNonCategoryTag} />))}
            </div>
            <div className="mb-15"/>
                      <div>
                        <Typography id="range-slider" gutterBottom>
                          Género
                        </Typography>
                        <Paper elevation={4}>
                        <NativeSelect 
                          value={genderCampaign}   
                          className="w-90" 
                          onChange={(e)=> setGenderCampaign(e.target.value)}
                        >
                          <option value="" disabled>Elige un género</option>
                          {gender.map(adr => (<option value={adr}>{adr}</option>))}

                        </NativeSelect>
                        </Paper>
                      </div>
                        <div className="mb-15"/>
                        <div className="tagContent">
                        <Typography id="range-slider" gutterBottom>
                                Rango de Edad
                            </Typography>
                            <div className="mb-15"/>
                         <Divider className="mb-20"/>
                            <div>

                            <div className="mb-15"/>
                         <Divider className="mb-20"/>
                            </div>

                         </div>
                         <div className="mb-15"/>
                         <div>
                             <div className="row ">
                                 <div className="col-sm-5 selectAge">
                                <Paper elevation={4}>
                                    <NativeSelect 
                                      value={minAge}   
                                      className="w-90" 
                                      onChange={(e)=> setMinAge(e.target.value)}
                                    >
                                    <option value="" disabled>Edad</option>
                                     {edad.map(adr => (<option value={adr}>{adr}{" AÑOS"}</option>))}

                                        </NativeSelect>
                                    </Paper>
                                 </div>
                                 <div className="col-sm-5 selectAge">
                                <Paper elevation={5} >
                                <NativeSelect value={maxAge} className="border-0 w-90"  onChange={(e)=> setMaxAge(e.target.value)}>
                                <option value="" disabled>Edad</option>
                                    {

                                    edad.map(adr => {
                                       if(adr>minAge) {
                                          return  <option value={adr}>{adr}{" AÑOS"}</option>
                                        }
                                        }
                                      )


                                    }

                                </NativeSelect>
                                </Paper>

                                 </div>
                             </div>
                         </div>
                         <div className="row contenedorRangoEco">
                            <Typography id="range-slider" gutterBottom className='TituloRango'>
                                Rango Socioeconómico
                            </Typography>
                            <div className='contendorSelect'>
                             <div className="row mt-25">
                                 <div className="col-sm-5 selectSocialStatus">

                                <Paper elevation={4} style={{marginTop:"20px"}}>
                                    <NativeSelect  className="w-90" onChange={(e)=>{ console.log('select value',e.target);addminRango(e.target.value)}}>
                                      {
                                        campaign ?
                                        <>
                                          <option  value={minSocialStatuss.name+"|"+minSocialStatuss.id}>{minSocialStatuss.name}</option>
                                         {rangos.map((adr,index)=>{
                                            if(minSocialStatuss.id!==adr.id ){
                                              return  <option value={adr.name+"|"+adr.id}>{adr.name}</option>
                                            }
                                          })
                                          } 
                                            </>
                                        :
                                             rangos.map((adr,index)=>(
                                              <option key={adr.id} value={adr.name+"|"+adr.id}>{adr.name}</option>
                                              ))
                                      }
                                    </NativeSelect>
                                </Paper>
                                 </div>
                                 <div className="col-sm-5 selectSocialStatus">
                                 <Paper elevation={4} style={{marginTop:"20px"}}>
                                  <NativeSelect  className="border-0 w-90"   onChange={(e)=> addmaxRango(e.target.value)}>
                                  {
                                     campaign ?
                                     <>
                                       <option  value={maxSocialStatuss.name+"|"+maxSocialStatuss.id}>{maxSocialStatuss.name}</option>
                                       {         
                                        rangos.map(adr2 => {
                                          if(adr2.id>=minSocialStatus.id) {
                                            return  <option value={adr2.name+"|"+adr2.id}>{adr2.name}</option>
                                          }})
                                      }
                                     </>
                                        :
                                        rangos.map(adr2 => {
                                          if(adr2.id>=minSocialStatus.id) {
                                            return  <option value={adr2.name+"|"+adr2.id}>{adr2.name}</option>
                                          }
                                          }
                                        )
                                  }

                                </NativeSelect>
                                </Paper>
                                <div className="mb-15"/>
                                 </div>
                             </div>
                         </div>

                         </div>
                        <div className="mb-15"/>
                        <InputModal title="Nombre" placeholder="  Ingresa nombre de la campaña" value={name} change={setNameText} />
                        <InputModal title="Slogan" placeholder="  Ingresa tu slogan" value={slogan} change={setSloganText} />
                        <p>Hashtag</p>
                        <Input placeholder="  Ingresa un nuevo hashtag" className="w-90" value={hashtagText} onChange={e => setHashtagText(e.target.value)} onKeyDown={e => saveTag(e)} />
                        <div className="tagContent">
                            {tags.map(tag => (<Tag key={tag.name} name={tag.name} deleteTag={deleteTag} />))}
                        </div>
                        <p>Descripcion</p>
                        <div className="mb-15"/>
                        <TextField
                       id="outlined-textarea"
                       placeholder="Descripcion"
                       variant="standard"
                       rows={8}
                       multiline = {true}
                      className="w-100"
                      value={description}
                       onChange={e => setDescriptionText(e.target.value)}
                         />
                       <div className="mb-15"/>
                        <p>Red social</p>
                        <AutoComplete 
                          data={socialNetworks} 
                          open={openSocial} 
                          loading={loadingSocial} 
                          setOpen={setOpenSocial} 
                          saveTag={saveSocialTag} 
                          placeholder="Ingresa una red social" 
                        />
                        <div className="tagContent">
                            {socialTags.map(tag => (<Tag key={tag.name} name={tag.name} deleteTag={deleteSocialTag} />))}
                        </div>
                        <div className="mb-15"/>
                        {
                          availableFormats.length > 0 ? (
                            <p className="rowsubtask">Número de subtask</p>
                          ):
                          (<></>)
                        }
                        {availableFormats.map(format =>
                       <div >
                           <div class="row">
                        <div class="col-sm">
                        <p className="font-size: xx-small font-weight-normal"><span className="font-weight-bold">{format.name}</span>{" - "}{format.activity} {" $"} {format.price} {" "} </p>
                             </div>
                            <div class="col-sm-2">
                            {format.status ?
                            <TextField
                              onChange={(e) =>
                              {handleUpdateFormat(e, format)}}
                              value={format.quantity}
                              id={format.id}
                              type="number"
                              variant="outlined"
                              InputProps={{ inputProps: { min: 0, max: 99 } }}
                          />
                            : "" }
                            </div>
                            </div>
                        </div>
                        )}

                        <Divider />
                         <div className="mb-15"/>
                         <InputCurrency 
                          title="Presupuesto" 
                          placeholder="  Ingresa un Presupuesto" 
                          value={presupuesto} change={setPresupuestoText} 
                          />
                         {presupuesto  > 0 &&
                         <div className="bg-light p-2" style={{borderRadius:10}}>
                        <InputLabel >CPM: ${averageCPM ? parseFloat(averageCPM).toFixed(2) : 0}</InputLabel>
                        <InputLabel >Alcance Garantizado: {averageCPM && presupuesto ? reachTotal : 0}</InputLabel>
                        <InputLabel >Interacción Estimada: {averageCPM && presupuesto ? predictedInteraction : 0}</InputLabel>
                        <InputLabel >Influenciadores solicitados (Mínimo): {averageCPM && presupuesto ? minInfluencers : 0}</InputLabel>
                        <InputLabel >Influenciadores solicitados (Máximo): {averageCPM && presupuesto ? maxInfluencers : 0}</InputLabel>
                        <InputLabel >Rating Máximo: {averageCPM && presupuesto ? maxRating : 0}</InputLabel>
                        </div>
                        }
                        <div className="mb-15"/>
                        <Divider />
                         <div className="mb-15"/>

                        <div className="justify-content-md-center row">
                       {presupuesto ?
                       <>
                        <div className="container" style={{flexDirection: 'column'}}>

                         <div className="mb-30"/>
                         <div className="row">
                        <div className="col-sm-4">Margen</div>
                        <div className="col-sm-3">
                          <span className="text-info">{presupuesto ? "$"+totalProfit: null}</span>
                        </div>
                        <div className="col-sm-3">{ !editablePercent ?
                               <p onClick={() =>setEditablePercent(true)}>
                                {title === "Edita tu campaña" ? percentage(presupuesto) : profitPercent.toFixed(2)}%
                                </p>
                              : <input 
                                  onChange={e =>setProfitPercent(e.target.value)} 
                                  placeholder={profitPercent} 
                                  className="inputPresupuesto" 
                                  type="number" 
                                  min="0" 
                                  pattern={'[0-9]*'}
                            />}</div>
                            <div className="col-sm-1">{editablePercent ? <span className="text-info">%</span>: null}
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-sm-4">Restante</div>
                        <div className="col-sm-3"><span className="text-info">{presupuesto ? "$"+totalRemainder: null }</span>
                            </div>
                        <div className="col-sm-3">{ !editablePercent ?
                                <p onClick={() =>setEditablePercent(true)}>
                                  {title === "Edita tu campaña" ? percentage2(presupuesto) : remainderPercent.toFixed(2)}%
                                </p>
                              : <input 
                                  onChange={e =>setRemainderPercent(e.target.value)} 
                                  placeholder={remainderPercent} 
                                  className="inputPresupuesto" 
                                  type="number" 
                                  min="0" 
                                  pattern={'[0-9]*'}
                            />}</div>
                            <div className="col-sm-1">{editablePercent ?<span className="text-info">%</span>: null}
                            </div>
                        </div>
                        <Divider className="mb-15"/>
                        <div className="row">

                        <div className="col-sm-4">Alcance</div>
                        <div className="col-sm-3"><span className="text-warning">{presupuesto ? "$"+totalAlcance  : null }</span>
                            </div>
                        <div className="col-sm-3">{ !editablePercent ?
                                <p onClick={() =>setEditablePercent(true)}>{alcancePercent}%</p>
                              : <input  
                                  onChange={e =>setAlcancePercent(e.target.value)} 
                                  placeholder={alcancePercent} 
                                  className="inputPresupuesto" 
                                  type="number" 
                                  min="0" 
                                  pattern={'[0-9]*'}
                            />
                        }</div>
                            <div className="col-sm-1">{editablePercent ?<span className="text-warning">%</span>: null}
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-sm-4">Interaccion</div>
                        <div className="col-sm-3">
                        <span className="text-warning">{presupuesto ? "$"+totalInteraction: null }</span></div>
                        <div className="col-sm-3">{ !editablePercent ?
                                <p onClick={() =>setEditablePercent(true)}>{interaccionPercent}%</p>
                              : <input 
                                  onChange={e => setInteraccionPercent(e.target.value)} 
                                  placeholder={interaccionPercent} 
                                  className="inputPresupuesto" 
                                  type="number" 
                                  min="0" 
                                  pattern={'[0-9]*'}
                                  
                            />}</div>
                            <div className="col-sm-1">{editablePercent ?<span className="text-warning">%</span>: null}
                            </div>
                            </div>
                        <div className="row">
                        <div className="col-sm-4">Creatividad</div>
                        <div className="col-sm-3">
                        <span className="text-warning">{presupuesto ? "$"+totalCreatividad  : null }</span>
                        </div>
                        <div className="col-sm-3">{ !editablePercent ?
                                <p onClick={() =>setEditablePercent(true)}>{bestpostPercent}%</p>
                              : <input 
                                  onChange={e => setBestPostPercent(e.target.value)} 
                                  placeholder={bestpostPercent} 
                                  className="inputPresupuesto" 
                                  type="number" 
                                  min="0" 
                                  pattern={'[0-9]*'}
                            />}</div>
                            <div className="col-sm-1"> {editablePercent ?<span className="text-warning">%</span>: null}
                            </div>
                        </div>
                        <div className="row bg-warning">

                        </div>
                        </div>





                           </>
                        : null }

                        </div>
                        <div className="mb-15"/>
                        <RangeDatePicker
                            startDate={initDate}
                            endDate={endDate}
                            minDate={new Date()}
                            onChange={(startDate, endDate) => onDateChange(startDate, endDate)}
                            startDatePlaceholder="Fecha de inicio"
                            endDatePlaceholder="Fecha fin"
                            dateFormat="DD/MM/YYYY"
                            /* style={{marginBottom:"20"}} */
                        />


                    </div>
                    <div className="part-BodyModal">
                        <div className="imageSetter-content " style={{overflowX:"auto"}}>
                            <p className='txtSpacing'>Logo del anunciante</p>
                            <Dropzone onUpload={handleUpload} onChange={handleFileChange}/>
                            <div className="content-section">
                                {logo.map(log =>(
                                    <div className="imageLoaderContent">
                                        <i className="fas fa-times-circle deleteImageLoader" onClick={e => deleteLogoLoader(log)}></i>
                                        <img className="imageLoader" src={log.link}></img>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="imageSetter-content">
                            <p>Imagen de la publicación</p>
                            <Dropzone onUpload={handleUploadPostImages} onChange={handleFileChange}/>
                            <div className="content-section">
                                {postImages.map(image =>(
                                    <div className="imageLoaderContent">
                                        <i className="fas fa-times-circle deleteImageLoader" onClick={e => deleteImageLoader(image)}></i>
                                        <img className="imageLoader" src={image.link}></img>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="mb-15"/>
                        <div className={groupBtnClass.root}>
                        <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                        {/* <Button  onClick={e => setShowMap(false)}>Pais/Zona</Button> */}
                        <Button /* onClick={e => setShowMap(true)} */>Mapa</Button>
                        </ButtonGroup>
                        </div>

                        { showMap ?
                        /////Map picker
                        <div> <Maps  markers={markers} setMarkers={value => setMarkers(value)}/>
                         </div>:
                        ////Country picker

                        <div>
                            <AutoComplete 
                              data={countryList} 
                              open={openCountry} 
                              loading={loading} 
                              setOpen={setOpenCountry} 
                              saveTag={saveCountryTag} 
                              placeholder="  Ingresa Pais / Zona" 
                            />
                      <div className="tagContent">
                            {countryTags.map(tag => (<Tag key={tag.name} name={tag.name} deleteTag={deleteCountryTag} />))}


      </div>


                         </div>

                        }

                    </div>

                </div>
                <div className="footer-Modal">
                    <div className="buttons-Modal-content">
                        <button onClick={e => closeModal()}>Cancelar</button>
                        <button onClick={e => saveCampaing()}>Guardar</button>
                    </div>
                </div>
            </div>
        </div>

        )
}

const countryList = [
    { name: "Guatemala, Guatemala", short: "GT" },
    { name: "Guatemala", short: "GT" },
    { name: "Ciudad de Guatemala, Guatemala, Guatemala", short: "GT" },
    { name: "Fraijanes, Guatemala, Guatemala", short: "GT" },
    { name: "San Cristobal, Guatemala, Guatemala", short: "GT" },
    { name: "Mixco, Guatemala, Guatemala", short: "GT" },
    { name: "Boca del Monte, Guatemala, Guatemala", short: "GT" },
    { name: "Amatitlan, Guatemala, Guatemala", short: "GT" },
    { name: "San Jose Pinula, Guatemala, Guatemala", short: "GT" },
    { name: "Costa Rica", short: "CR" },
    { name: "San Jose, Costa Rica", short: "CR" },
    { name: "Antigua Guatemala, Sacatepequez, Guatemala", short: "CR" }
     ];


const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
    _showLoader: show => dispatch(showLoader(show)),
    _showAlertModal: (title, message, type, callback) => dispatch(Modal.showAlertModal(title, message, type, callback)),
})


export default connect(mapStateToProps, mapDispatchToProps)(NewCampaign)