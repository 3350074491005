import React, {useState, useEffect} from 'react';
//helpers
import * as APILI from '../../../../helpers/apiLI'
import './styleGallery.scss'
import { Carousel } from 'antd';
import 'antd/dist/antd.css';
import noImage from './noImage.jpg'
import { set } from 'lodash';
import Swal from "sweetalert2/dist/sweetalert2.js";
import icoComment from './icons/comment.png'
import icoEngagement from './icons/engagement.png'
import icoGallery from './icons/gallery.png'
import icoImage from './icons/image.png'
import icoImpressions from './icons/impression.png'
import icoLike from './icons/like.png'
import icoReach from './icons/reach.png'
import icoSaved from './icons/saved.png'
import icoVideo from './icons/video.png'
import icoLink from './icons/link.png'
import logoTru from './icons/logo.png'
import { TikTok } from "react-tiktok";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
const GalleryPost = (props) => {

  const currentUser = JSON.parse(localStorage.getItem('CurrentUser')) ?? [];
  const {matchPosts, find,currentSubTask, taskPage, getPostsAndInfo}=props
  const [spacing, setSpacing] = useState(2);
  const [postView,setPostView] = useState([])
  const [tempState,setTempState] = useState([])
  const saveErrorLog = async (body) => {
    try {
      APILI.createNewErrorLog(body).then(res => {
        console.log('Error log Creado')
      }).catch(err => {
      console.log('err', err) 
      })
      
  } catch (err) {
      console.log("No se pudo guardar el log de error", err)
  }
  }
  
  /* useEffect( () => {
    let reachTotal=0
    hastags.forEach(data => {
      let newHash= data.name.toLowerCase()
      const body={       
        "hashtag":{name: newHash},
        "userID":userID,
        "socialmedia":socialmedia[0]?.name.toLowerCase()
          }
          console.log("boooooooooooooooooooody", body)
          APILI.getPostsByHashtagAndUser(body)
         .then((posts) => {
          //reachTotal=reachTotal+posts?.data?.totalReach
          console.log('POST GALERRY',posts)
          if(reachTotal<posts?.data?.totalReach){
            reachTotal=posts?.data?.totalReach
          }
          
          console.log("reachTotal", reachTotal)
          
          getPostView(posts.data.influencerPosts)
          setTempState(...tempState,posts.data.influencerPosts)
          totalReach(reachTotal)
          //console.log("from gallery",posts.data.influencerPosts)
        
    }).catch((err) => {

      console.log('error webhook', err)
      const bodyLog = {
        Tipo: "Lectura de webhook error",
        Accion: "Busqueda de post",
        Data: {},
        Historico: {},
        CodeHttp: err,
        UsuarioId: currentUser.username,
        UsuarioName: currentUser.attributes.name,
        Email: currentUser.attributes.email,
    }
    saveErrorLog(bodyLog);
    }) 
    console.log("reachTotal",reachTotal)}) 
  }, [hastags])
   */
  

  let photo = []
  const getPostView = (post) => {
    let tempIDs = []
    let reachTemp = 0
    let counter=0
    post.forEach(element => {
   
    if(element.picture){
    photo.push({
      id:element.id,
      src: element.picture,
      comments: element.comments,
      insights: element.insights,
      likes_count: element.likes_count,
      comments_count: element.comments_count,
      message: element.message,
      activity: element.activity,
      urlPost: element.urlPost,
  
    })}
    console.log("photo",photo)
  });
    console.log("reachTemp",reachTemp, counter)
    eliminarRepetidos(photo)
  }

  const eliminarRepetidos = (photo)=>{
    var hash = {};
    let auxArray=[]
    auxArray = photo.filter(function(current) {
      var exists = !hash[current.id];
      hash[current.id] = true;
      return exists;
    });
    setPostView(auxArray)
  }

  const FormatTextNameMessage = (message)=>{
    let newMessage = message.split("#")
    return (
      <div className='content'>
        {newMessage.map((individual) => (
          <p><span>#</span>{individual}</p>
        ))}
      </div>
    )
  }

  const TipoImagen = (activity)=>{
    if(activity === "IMAGE" || activity === "photo"){
      return <div>
        <img src={icoImage}/>
        <span className='tipo'>Tipo imagen</span>
      </div>
    } else if(activity === "VIDEO" || activity === "video"){
      return<div>
        <img src={icoVideo}/>
        <span className='tipo'>Tipo video</span>
      </div>
    }
    else if(activity === "CAROUSEL_ALBUM"){
      return<div>
        <img src={icoGallery}/>
        <span className='tipo'>Tipo album</span>
      </div>
    } 
  } 
  const Comentarios =(comments_count) =>{
      return<div>
        <img src={icoComment}/> <p>{comments_count} </p>
        <span>Comentarios</span>
      </div>
  }
  const MeGusta = (likes_count) =>{
      return<div> 
        <img src={icoLike}/> <p>{likes_count}</p>
        <span>Likes</span>
      </div>
  }
  const Interacciones = (engagement)=>{
      return<div>
        <img src={icoEngagement}/> <p>{engagement} </p>
        <span>Interacciones</span>
      </div>
  }
  const Impresiones =(impressions)=>{
      return<div>
        <img src={icoImpressions}/> <p>{impressions}</p>
        <span>Visualizaciones</span>
      </div>
  }
  const Guardados = (saved) =>{
      return<div>
        <img src={icoSaved}/><p>{saved? saved:0}</p>
        <span>Guardados</span>
      </div>
  }
  const Alcance = (reach) => {
      return<div>
        <img src={icoReach}/><p>{reach}</p> 
        <span>Alcance</span>
      </div>
  }

  const savePost = (post)=>{
    console.log('current Post', post)
    console.log('currentSubtask', currentSubTask)
    console.log('taskPage', taskPage)
    Swal.fire({
      title: 'Validación',
      text: "¿Está seguro que desea validar la publicación?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Validar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        APILI.createPosts(post)
        .then(res => {
          /* setRefresh(!refresh) */
            Swal.fire(
            '¡Validado!',
            'La publicación fue validada.',
            'success'
          )
    console.log(res) 
    })
    .catch(err => {
    console.log(err)
    })
    setTimeout(() => {
      getPostsAndInfo(currentSubTask, taskPage)
    }, 2000);
      
      }
    })

    
  }
  const getIdTikTok=(url, socialmedia)=>{
    if(socialmedia=="Facebook")
    {
    const urlEmbed = `${url}`
    const myArray = urlEmbed.split(`src="`);
    const urlToShow = myArray[1].split(`" width`)
    return urlToShow[0]
    }
    else if(socialmedia=="Instagram")
    {
    const myArray = url.split("/?utm_source");
    return myArray[0] + "/embed"
    }
    else if(socialmedia=="Tiktok"){
      const myArray = url.split("/");
      const id = myArray[5].split("?")
      return "https://www.tiktok.com/embed/"+ id[0]
    }
  } 

  return (
    <>
    <Carousel arrows={true}>
      {matchPosts.length !== 0 ?
        matchPosts?.map((post) => {
          let url = ""
          if(post.activitypost === "CUSTOM")
          {
            url = getIdTikTok(post?.urlPost, post?.socialmedia)
          }
          
          return (
            <div key={post.id} className="container-post">
              <div className='containerImgVideo'>
                {(post.activity === "IMAGE" || post.activity==="photo"|| post.activity==="CAROUSEL_ALBUM") &&
                ( <img src={post?.picture} alt="imagenPost" width={"65%"} height={"300"}/>)}
  
                {(post.activity === "VIDEO" || post.activity==="video")&& (
                  <video width="65%" height="300" controls>
                  <source src={post?.picture} type="video/mp4" /> </video>
                )
                }
                {post.activitypost === "CUSTOM" && (
                  
                  <iframe 
                    src={url}
                    width="65%" 
                    height="300" 
                    frameborder="0" 
                    scrolling="yes"
                    allowtransparency="true">
                  </iframe>
                  
                )}
              </div>
              <div className='containerText'>
              <div className='insights1'>
                <p>{Comentarios(post?.comments_count)}</p>
                <p>{MeGusta(post?.likes_count)}</p>
                {/* <p>{Interacciones(post?.insights?.engagement)}</p> */}
                <p>{Impresiones(post?.insights?.impressions)}</p>    
                <p>{Guardados(post?.insights?.saved)}</p>
                <p>{Alcance(post?.insights?.reach)}</p>
                <p>{TipoImagen(post?.activity)}</p>
                <a href={post?.urlPost} target="_blank"><img src={icoLink}/> <span className='LinkPost'>Ir al post</span></a>
              </div>
            </div>
            <div className='section2'>
              <div className='Hashtags2'> 
                <h5>Hashtags</h5>
                <span>{FormatTextNameMessage(post?.message)}</span>
              </div>
              {(find==true)?
              (
              <></>
              ):(
                <div className='validationPost'>
                <h5>Aprobar Publicación</h5>
                <div className='ico'>
                  <button className='Btn12' onClick={e => savePost(post)}><i class="fas fa-check-circle"></i></button>
                  <button className='Btn12' /* onClick={e => savePost(post)} */><i class="fas fa-times-circle"></i></button>
                  
                </div>
              </div>
              )}
            </div>
            </div>
          )
        })
      : <div className='sinPosts'>
        <img src={logoTru}/>
        {/* <div style={{height: "500px", overflowY: "auto"}}>
        <TikTok url="https://www.tiktok.com/@jennifer_aplicano/video/7110237429084245254" />
        </div> */}
          
      </div>
      }
    </Carousel>
    </>
  );
}
export default GalleryPost;