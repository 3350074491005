import { APP_CLEAR_CURRENT_USER } from '../actions/app.actions'
import * as Actions from '../actions/accounts.actions'

const initialState = {
  edit: {},
  list: [],
  tags: [],
  default: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.ACCOUNTS_ADD_TAG:
      return {
        ...state,
        tags: [
          ...state.tags.filter((tag) => tag.id !== action.tag.id),
          action.tag,
        ],
      }
    case Actions.ACCOUNTS_SET_EDIT:
      return {
        ...state,
        edit: action.edit,
      }
    case Actions.ACCOUNTS_CLEAR_EDIT:
      return {
        ...state,
        edit: {},
      }
    case Actions.ACCOUNTS_SET_DEFAULT:
      return {
        ...state,
        default: action.account,
      }
    case Actions.ACCOUNTS_CLEAR:
      return {
        ...state,
        list: [],
      }
    case Actions.ACCOUNTS_TAGS_CLEAR:
      return {
        ...state,
        tags: [],
      }
    case Actions.ACCOUNTS_ADD_ONE:
      return {
        ...state,
        list: [
          ...state.list.filter((account) => account.id !== action.account.id),
          action.account,
        ],
      }
    case APP_CLEAR_CURRENT_USER:
      return initialState
    default:
      return state
  }
}