import React, {useState, useEffect} from 'react';
import { Auth, Hub } from 'aws-amplify';
import NativeSelect from '@material-ui/core/NativeSelect';
import "react-google-flight-datepicker/dist/main.css";
import { useDispatch, connect } from 'react-redux';
import { showLoader } from 'redux/actions/loader.actions';
import InputModal from './inputModal';
import * as Modal from 'redux/actions/modal.actions'
import * as APILI from 'helpers/apiLI';
import Switch from '@material-ui/core/Switch';
import { getCampaign } from 'helpers/apiLI';
import { v4 } from 'uuid'
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import { getLogoByName, getClassNameByStatus } from 'myUtil'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import moment from 'moment/moment.js'
import IconButton from '@mui/material/IconButton';
import LaunchIcon from '@mui/icons-material/Launch';
import TextField from '@material-ui/core/TextField'
import { FacebookProvider, EmbeddedPost } from 'react-facebook';
 import PostEmbed from 'components/embedPost'
import ShowSocialMedia from '../showSocialMedia'
import PostAddIcon from '@material-ui/icons/PostAdd';
import { DateTimePicker } from "@material-ui/pickers";


 
const AddPost = ({ userRol, title, showModal, _showAlertModal }) => {

    const [influencers, setInfluencers] = useState([])
    
    const [campaigns,setCampaigns] = useState([]);
    const [scopeCompromiso, setscopeCompromiso] = useState(0)
    const [email, setemail] = useState('')
    const [username, setusername] = useState('')
    const [profilesData, setProfilesData] = useState([])
    const [showInfluencers, setshowInfluencers] = useState(false)

    const [showSocial,setShowSocial] = useState(false)
    
    
    const [activity, setactivity] = useState([])
    const [campaignData, setCampaignData] = useState({
        "name" : '',
        "id":'',
        "activity": [],
        "endDate" : '',
        "socialmedia" : [],
        "price": 0
    })
    const [influencerData, setinfluencerData] = useState({
        "id": "",
        "phoneNumber": "",
        "dateBirth": "",
        "status": "",
        "email": "",
        "country": "",
        "name": "",
        "gender": "",
        "language": "",
        "lastname": "",
        "direction": "",
        "username": ""
       }
    )
    const [activityData, setactivityData] = useState({
        "name": "",
        "quantity": "",
        "id": "",
        "activity": "",
        "price": "",
        "status": true
    })
    const [taskData, settaskData] = useState({
        
        "id": '',
        "activity": '',
        "campaignID": '',
        "campaignName": '',
        "enable": true,
        "endDate": '',
        "price": 0,
        "scope": 0,
        "socialmedia": '',
        "status": '',
        "userID": ''
        
    })
    
    const [selectedDate, handleDateChange] = useState(new Date());
    const [endDate,setendDate] =  useState('')
    const [influencerID,setinfluencerID] =  useState('')
    const [socialmedia,setsocialmedia] =  useState('')
    const [scope,setscope] =  useState('')
    const [price,setprice] =  useState('')
    const [countProfiles, setCountProfiles] = useState(0)
    const [campaignID,setcampaignID] =  useState('')
    const [influencerTasks, setinfluencerTasks] = useState([])
    const [showOn, setshowOn] = useState(false)  
    const dispatch = useDispatch()
    const [postDate, setPostDate] = useState('')
    const [urlPost, setUrlPost] = useState('')
    const getUser = () => {
    
        return Auth.currentAuthenticatedUser()
        .then((userData) => userData)
        .catch(() => console.log('Not signed in'))
    }

   

    const savePost = async () => {
        var today = new Date();

        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            const body = {
                
                    id: v4(),
                    activity: taskData.activity,
                    influencerid: influencerData.id,
                    taskID: taskData.id,
                    userID_socialMedia: "",
                    status: "Pendiente",
                    createdAt: date,
                    scope: scope,
                    scopeCompromiso: scopeCompromiso,
                    time_socialMedia: date,
                    campaignName: taskData.campaignName,
                    updatedAt: date,
                    socialmedia: taskData.socialmedia,
                    campaignID: taskData.campaignID,
                    postID_socialMedia: "",
                    urlPost: urlPost
                   
            }
            try {
                console.log(body);
               const response = await APILI.createPosts(body)
            } catch (err) {
                console.log("Error")
                
            }
      
    }
   const getTask = (user) => {
       
        APILI.getTasksByUser({ userID: user}).then(res => {
                if (res.data && res.data.tasks && res.data.tasks.length > 0) {                    
                    getInfluencerTasks(res.data.tasks)
                    
                }
            }).catch(console.error)
            
        }

        const findProfileData = () => {
        
      
            let body = {
                'influencerid': email,
                'userprofile': username
              }
              console.log(body);
            APILI.getProfilesByUserorEmail(body
              /*  { id: email, 
                name: name, 
                lastname: lastname, 
                phoneNumber: phoneNumber
            }*/).then(res => {
                    if (res.data && res.data.profile && res.data.profile.length > 0) {                    
                        console.log(res)
                        getProfilesData(res.data.profile, res.data.count)
                        setshowInfluencers(true)
                                     } 
                        
                }).catch(console.error)
                
        };
        
        const getProfilesData = (data, count) => {
            setProfilesData (data)
            setCountProfiles (count)
            console.log("daaaaaat",data)
        }
const getInfluencerTasks = (data) => {
    setinfluencerTasks(data)
}
    useEffect( () => {
        APILI.getInfluencersData().then(res => {
                if (res.data && res.data.influencerdata && res.data.influencerdata.length > 0) {                    
                    getInfluencersInfoView(res.data.influencerdata)
                    
                   // setinfluencersData(res.data.influencerdata)
                                 }
            }).catch(console.error)
            
    }, []);
    
    const getCampaignView = (data) => {
        setCampaigns (data)
    }

   
    function amount(item){
        return item.scope;
        }

        function sum(prev, next){
        return prev + next;
        }

    const activityCamp = (dat) => {
        let data = campaignData.activity.filter(data => data.id ===dat)
        console.log(data, dat)
        let thisscope = 0
    
        let objeto ={
        "name": data[0].name,
        "quantity": data[0].quantity,
        "id": data[0].id,
        "activity": data[0].activity,
        "price": data[0].price,
        "status": true,
        "scope": thisscope
        }
        setactivityData(objeto)
        console.log(objeto)
        
    }

    const taskCamp = (dat) => {
        let index = 0
        for (let i = 0; i <influencerTasks.length; i++){
            if (influencerTasks[i].id === dat){
                index = i
            }
        }
        let data = influencerTasks
        console.log(influencerTasks, dat)
        let thisscope = 0
    
        let objeto ={
        "id": data[index].id,
        "activity": data[index].activity,
        "campaignID": data[index].campaignID,
        "campaignName": data[index].campaignName,
        "enable":  true,
        "endDate": data[index].endDate,
        "price": data[index].price,
        "scope": data[index].scope,
        "socialmedia": data[index].socialmedia,
        "status": data[index].status,
        "userID": data[index].userID
        }
        settaskData(objeto)
        
         
    }
    const influencerCamp = (e, dat) =>{
        e.preventDefault()
     
        let data = dat
        let scope = 0
    
        if (data.socialNetworks && data.socialNetworks.length>0){
             scope = data.socialNetworks.map.map(amount).reduce(sum,0)
        } 
        
        
        let objeto = {
        id: data.id,
        dateBirth: data.dateBirth,
        status: data.status,
        name: data.name,
        gender: data.gender,
        language: data.language,
        lastname: data.lastname,
        direction: data.direction,
        username: data.userprofile,
        socialNetworks: data.socialNetworks,
        scope: scope
        }
        setinfluencerData(objeto)
        getTask(dat)
      
    }
    const getInfluencersInfoView = (camps) => {
        setInfluencers(camps)
    }
  
    
    const [state, setState] = React.useState({
        checkedA: false,
        checkedB: false,
      });
    
    

      const [modifyProfile, setModifyProfile] = useState(false)
      const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        setModifyProfile(!modifyProfile)
      };

const showProfile = (e,url) => {
    e.preventDefault()
    console.log(url)
    Swal.fire({
        title: '<strong>HTML <u>example</u></strong>',
        icon: 'info',
        html:
        `<iframe id="inlineFrameExample"
          title="Inline Frame Example"
          width="300"
          height="200"
          src="${url}">
      </iframe>`,
      
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText:
          '<i class="fa fa-thumbs-up"></i> Great!',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonText:
          '<i class="fa fa-thumbs-down"></i>',
        cancelButtonAriaLabel: 'Thumbs down'
      })
}

    return (
        <>
        <div className="blur-div">
            <div className="content-modal firstmodal">
                <div className="closeModal-content">
                    
                   
                    <i className="fas fa-times-circle" onClick={e => showModal(false)}></i>
                </div>
                <div>
                    <h2 className="titleModal">{title}</h2>
                </div>
                <div className="body-modal">
                    <div className="part-BodyModal">
                       
                        
                        <p>Selecciona un usuario</p>
                    
                        {!showOn ? <>
                            <p>Selecciona una Tarea</p>
                    <NativeSelect value={taskData.campaignName+' '+taskData.activity+' - '+taskData.socialmedia} onChange={e => taskCamp(e.target.value)} name="age" className="w-90">
                            <option value="" disabled>Selecciona un influencer</option>name
                            {influencerTasks.map(adr => (<option value={adr.id}>{adr.campaignName}{' '}{adr.activity}{' - '}{adr.socialmedia}</option>))}
                        </NativeSelect>
                        <InputModal lockornot={modifyProfile} title="Usuario" placeholder={influencerData.username} value={influencerData.username}  />
                        
                        <p>Fecha de Publicacion</p>
              <input type="date" id="start" name="trip-start"
                         value={postDate}
                          className="w-100" onChange={e => setPostDate(e.target.value)}></input>

                         <div className="mb-15"/>
                         <p>Actividades a realizar</p>
                         <Divider />
                         <div className="mb-15"/>
{campaignData.activity.map(format => 
                       <div >
                           
                           <div class="row">
                        <div class="col-sm">
                        <p className="font-size: xx-small"  className="font-weight-normal"><span className="font-weight-bold">{format.quantity} {" - "} {format.name}</span>{" - "}{format.activity}</p>
                             </div>
                            </div>
                        </div>
                        )}
                        <div className="mb-15"/>
                       
                        <TextField
                        className="w-100"
        id="standard-name"
        label="Embeber publicacion"
        
        
        placeholder={urlPost} value={urlPost} onChange={e =>setUrlPost(e.target.value)} 
        placeholder="Ejemplo: https://facebook.com/idPublicacion?"
        InputProps={{
            endAdornment:  <IconButton>    <PostAddIcon/></IconButton>}}
           

      />
      
{urlPost ? <PostEmbed className="w-90" embed={urlPost} /> : null}

<div className="mb-15"/>
<InputModal lockornot={false} title="Alcance Compromiso" placeholder={scopeCompromiso} value={scopeCompromiso} change={setscopeCompromiso}  />
<div className="mb-15"/>
<InputModal lockornot={true} title="Alcance Total" placeholder={scope} value={scope} change={setscope}  />
      
</>
                        : null}
                    
                    
                 </div>
     
                    <div className="part-BodyModal">
                                 <p>Buscar Perfil</p>
                        <Divider />
                        <div className="mb-15"/>
                    <InputModal lockornot={true} title="Usuario" placeholder={email} value={email} change={setemail}  />
                  
                    
                    <Button onClick={() => findProfileData()} variant="contained" color="secondary">Buscar</Button>
                    {showInfluencers ?  
                    <div class="list-group">
                    
                    <div className="mb-15"/>
                        <Box
      sx={{ width: '100%', height: 300, bgcolor: 'background.paper' }}
    >
     

     <List dense  sx={{
        width: '100%',
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
        '& ul': { padding: 0 },
      }}>
      {profilesData.map((datos) => {
       
        return (
            <>
          <ListItem
            key={datos.id}
            secondaryAction={
                <Button
                borderRadius={50}
                onClick = {(e) => showProfile(e,datos.url)}
                >
                    <Avatar
                alt={datos.socialmedia}
                borderRadius={50}
                src={getLogoByName(datos.socialmedia)}
                
              />
                </Button>
                
              }
            disablePadding
          >
            <ListItemButton>
              <ListItemAvatar>
              <Avatar
                 src={datos.image}
                />
              </ListItemAvatar>
              <ListItemText onClick={e => influencerCamp(e, datos)}  id={datos.id} primary={'@'+datos.userprofile} secondary={datos.influencerid} />
            </ListItemButton>
          </ListItem>
          
          </>
        );
      })
      }
    </List>
  
    </Box>
                    </div>
                    : <p> No hay influencers seleccionados para mostrar</p> }
                    </div>
                </div>
              
                <div className="footer-Modal">
                    <div className="buttons-Modal-content">
                        <button onClick={e => showModal(false)}>Cancelar</button>
                        <button onClick={e => savePost()}>Guardar</button>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
    _showLoader: show => dispatch(showLoader(show)),
    _showAlertModal: (title, message, type, callback) => dispatch(Modal.showAlertModal(title, message, type, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddPost)