import moment from 'moment'

import * as API from 'helpers/api'
import * as Logger from 'utils/logger'
import * as Modal from './modal.actions'
import { showLoader } from './loader.actions'

export const FB_PROP = 'FACEBOOK:PROP'
export const FB_PAGES = 'FACEBOOK:PAGES'
export const FB_DATOS = 'FACEBOOK:DATOS'
export const FB_MESSAGES = 'FACEBOOK:MESSAGES'

const TAG = 'FACEBOOK:ACTION'

export const setProp = prop => dispatch => {
    dispatch({
        type: FB_PROP,
        prop,
    })
}

export const getPages = () => (dispatch) => {
    dispatch(showLoader(true))
    API.getPages()
        .then(response => {
            Logger.log(TAG, 'getPages', response)

            dispatch({
                type: FB_PAGES,
                pages: response.data
            })
        })
        .catch(err => {
            Logger.log(TAG, 'getPages', err)
        })
        .then(() => {
            dispatch(showLoader(false))
        })
}

export const setDatos = (token) => (dispatch) => {
    dispatch(showLoader(true))
    window.FB.api(`/me/conversations`, { access_token: token, fields: ['participants', 'link', 'updated_time'] }, function(response) {
        console.log('FBapi', response);
        dispatch({
            type: FB_DATOS,
            conversations: response.data,
        })
        dispatch(showLoader(false))
    });
}

export const setMessages = (conversationId, token) => dispatch => {
    dispatch(showLoader(true))
    window.FB.api(`/${conversationId}/messages`, { access_token: token, fields: ['message', 'created_time', 'from'] }, function(response) {
        console.log('FBapi', response);
        dispatch({
            type: FB_MESSAGES,
            conversationId,
            messages: response.data,
        })
        dispatch(showLoader(false))
    });
}

export const enviarMensaje = (textarea, destino, token) => dispatch => {
    dispatch(showLoader(true))
    const text = document.getElementById(textarea).value.trim();
    window.FB.api(`/me/messages`, 'post', { access_token: token, message: { text }, recipient: { id: destino } }, function(response) {
        console.log('FBapi', response);
         dispatch(showLoader(false))
        if (!response || response.error) {
            dispatch(Modal.showAlertModal('Error!', 'Hubo un error al enviar el mensaje', Modal.MODAL_TYPE_ERROR))
        } else {
            dispatch(Modal.showAlertModal('', 'Mensaje enviado exitosamente', Modal.MODAL_TYPE_SUCCESS, () => {
            }));
        }
    });
}