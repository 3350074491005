import React from 'react'
// components
import ErrorLogs from 'components/errorLogs'

const errorLogPage= (props) => { 
  const {userRol} = props;
  return (
    <>
    {userRol==='admin'?
    <ErrorLogs />:
    <h1>No tienes privilegios</h1>
    }
    </>
    )  
  }
  

export default errorLogPage