import React, { useState, useEffect } from 'react'; // se elimino useEffect no se esta usando
import * as utils from 'data.js';
import * as APILI from 'helpers/apiLI';
import TargetContentMap from 'components/targetContentMap'
import Maps from "./Maps"
//Libraries
import DataTableUtils, {
    Share,
} from '@easygosanet/react-datatable-utils'
import _ from 'lodash'
import moment from "moment";
//Components
import TargetContent from 'components/targetContent'
import GraphBox from 'components/graphBox'
import Galery from 'components/galery'
import TargetContentCategories from 'components/targetContentCategories'
import { getLogoByName, getClassNameByStatus } from 'myUtil'
import ReactDataTable from '@easygosanet/react-datatable'
import TargetContentTasks from 'components/targetContentTasks'
import Paper from '@material-ui/core/Paper';
import IconBack from './assets/img/arrow-left.png'
import ProgresiveBar from './ProgresiveBar';
import Task from 'components/task';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import {v4} from 'uuid'
import { showLoader } from 'redux/actions/loader.actions';
import { connect } from 'react-redux';
//styles
import './info.scss'
import "./Maps.css";
import { aprobe } from 'helpers/apiLA';

const Info = (props) => {
    

    const { currentCampaign, infoCampaign, categories, hashtags, coords, changeWindow,
        campOriginal, name, delivery, budget, reward, date, source, inscription, hastags,
        description, logo, postImages, locationList, mediaCategories, id, ageValue,
        advertiser, CPM, activity, totalAlcance, totalInteraction,
        totalProfit, totalRemainder, totalCreatividad, setWindow, refresh, setRefresh, userRol, showLoader
    } = props
    
    const [showModal, setshowModal] = useState(false);
    let [state, setState] = useState(currentCampaign.campOriginal.state)
    const [tables, setTables] = useState([]);
    const [paymentTables, setPaymentTables] = useState([]);
    const [refreshTable, setrefreshTable] = useState(false)
    const [campaingState, setCampaingState] = useState()
    const [filters, setFilters] = useState({})
    const [result, setResult] = useState({})
    const [count, setCount] = useState(0)
    const [countSolicitadas, setCountcountSolicitadas] = useState(0)
    const [countPendientes, setCountPendientes] = useState(0)
    const [countProgreso, setCountProgreso] = useState(0)
    const [countRechazadas, setCountRechazadas] = useState(0)
    const [countFinalizadas, setCountFinalizadas] = useState(0)
    const [countAprobadas, setCountAprobadas] = useState(0)
    const [statistics, setStatistics] = useState({
        approved: 0,
        rejected: 0,
        Pendiente: 0,
        rating: 0,
        completed: 0,
        scope: 0,
        interaction: 0
    })
    const [showTask, setShowTask] = useState(false)
    const [taskPage, setTaskPage] = useState({
        "activityName": "",
        "campaignName":"",
        "id": "",
        "name":"",
        "temporalSubTask":[],
        "status" : '',
        "price":0.00,
        "scope":0,
        "socialmedia":"",
        "userID":"",
        "image":"",
        "campaignID":"",
        "enable":"",
        "subTasks": []
    })
    const [canFinish, setCanFinish] = useState(false)
    const [currentTasks, setCurrentTasks] = useState([]);
    const [reachPayment, setReachPayment] = useState(0)
    const [interactionPayment, setInteractionPayment] = useState(0)
    const [maxRating,setMaxRating] = useState(0)
    async function findHastags(resCampain, body){
        var hola = false;
        await body.hashtag.forEach(async hastagE =>{
           await resCampain.hastags.forEach(async hastagC =>{
            //console.log('has',hastagE.name ,'cam',hastagC.name);
            if(hastagE.name.toLowerCase()  == hastagC.name.toLowerCase() ){
             // console.log("macth");
               hola = true
            };
           });
        });
        return hola
      }

    const TaskPage = async (data) => {
        setTaskPage(data)
        setShowTask(true)
    }

    const getActivitiesList = async (campaignID) =>{
        return   await APILI.getCampaignById({"id":campaignID}).then(res => 
            {
                return res.data.campaign[0].activity})
        .catch(error => {return error})             
    }
    const MySwal = async (data) => {
        console.log("data",data.status)
       
          let activities = await getActivitiesList(data.campaignID)
          //console.log(activities)
          
        APILI.getCampaignById({"id":data.campaignID})
        .then(res =>
            activities= res.data.campaign[0].activity).catch(err => console.log(err))
        
        let influencerName = ''
        let influencerLastName = ''
        console.log(influencerName)
        console.log(influencerLastName);
       
      const updateTaskStatus = async (taskID, taskStatus, data) => {
          console.log("taskID",taskID)
          console.log("taskStatus",taskStatus)
        const update = {
            id: taskID,
            status: taskStatus
        }

        const updateTable =() => {
            setrefreshTable(!refreshTable)
        }
         APILI.updateTaskStatusByID(update).then((status) => {
           
          
            if(taskStatus === 'Pendiente'){
                
                activities.forEach(dat => {
                    for (let x=1; x<= parseInt(dat.quantity); x++){
                        const body = {
                            id: v4(),
                            activity: dat.activity,
                            quantity: 1,
                            rating: 0,
                            taskID: taskID,
                            status: "Pendiente",
                            comment: "Sin comentarios",
                            price: 0,
                            name: dat.name,
                            }
                        
                         APILI.createSubTask(body).then((status) => {
                            console.log("status",status)
                        }).catch((err) => {
                            console.log("err",err)
                        })
                    }
                    
                        })
                    }
                    updateTable()
                }).catch((err) => {
                    console.log("err",err)
                })

                }
          
        
      
        if(data.delivery.name ==="Payment"){
            console.log(data.delivery.name)
            Swal.fire({
                title: 'Ninguna actividad pendiente!', 
                icon: 'success',
                timer: 1500
            })
            return
        }
        let aprobeTask = ''
        let dennyTask = ''
        if(data.delivery.name ==="Rejected"){
            aprobeTask = 'Pendiente'
            dennyTask = 'Rejected'
        }
        if(data.delivery.name ==="Pendiente"){
            aprobeTask = 'Active'
            dennyTask = 'Rejected'
        }
        if(data.delivery.name ==="Solicitado"){
            aprobeTask = 'Pendiente'
            dennyTask = 'Rejected'
        }
        if(data.delivery.name ==="Active"){
            aprobeTask = 'Completed'
            dennyTask = 'Pendiente'
        }
        if(data.delivery.name ==="Completed"){
            aprobeTask = 'Payment'
            dennyTask = 'Active'
        }


            Swal.fire({
                title: data.campaignName,
                text: 'Usuario:, ' + data.userID + ' Seguidores: '+ data.scope +
                'Desea participar del reto:, ' + data.campaignName,
                icon: 'info',
                showCancelButton: true,
                showDenyButton: true,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: '#00CA72',
                denyButtonText: '#d33',
                denyButtonText:
              '<i class="fa fa-thumbs-down"></i>',
                confirmButtonText:
              '<i class="fa fa-thumbs-up"></i> Aprobar',
            }).then((result) => {
                ///* Read more about isConfirmed, isDenied below 
                if (result.isConfirmed) {
                    updateTaskStatus(data.id, aprobeTask, data)      
                  Swal.fire({
                      title: 'Confirmado!', 
                        icon: 'success',
                          timer: 1500})
                } else if (result.isDenied) {
                    updateTaskStatus(data.id,dennyTask)
                    Swal.fire({
                        title: 'Rechazado!', 
                          icon: 'error',
                            timer: 1500})
                }
              })
        
    } 
    const [campaignData, setCampaignData] = useState({
        id: currentCampaign.id,
        name: currentCampaign.name,
        delivery: currentCampaign.delivery,
        budget: currentCampaign.budget,
        reward: currentCampaign.reward,
        date: currentCampaign.date,
        source: currentCampaign.source,
        gender: currentCampaign.genderCampaign,
        inscription: currentCampaign.inscription,
        hastags: currentCampaign.hastags,
        description: currentCampaign.description,
        logo: currentCampaign.logo,
        postImages: currentCampaign.postImages,
        locationList: currentCampaign.locationList,
        mediaCategories: currentCampaign.mediaCategories,
        nonMediaCategories: currentCampaign.nonMediaCategories,
        ageValue: currentCampaign.ageValue,
        advertiser: currentCampaign.advertiser,
        CPM: currentCampaign.CPM,
        activity: currentCampaign.activity,
        totalAlcance: currentCampaign.totalAlcance,
        totalInteraction: currentCampaign.totalInteraction,
        totalProfit: currentCampaign.totalProfit,
        totalRemainder: currentCampaign.totalRemainder,
        totalCreatividad: currentCampaign.totalCreatividad,
        reach: formatNumber(currentCampaign.reach),
        state: returnState(currentCampaign.state),
        slogan: currentCampaign.slogan,
        socialStatusValue: currentCampaign.socialStatusValue,
        initDate: currentCampaign.initDate,
        endDate: currentCampaign.endDate,

    })
    
    const [nameAdvertiser, setNameAdvertiser] = useState([])
    useEffect(() =>{
        APILI.getAdvertiser()
        .then((res) => {
            
            setNameAdvertiser(res.data)
        })
        .catch((err)=>{
        }) 
    },[])

   /* let advertiserName 
    let idAdvertiser = campaignData.advertiser
    let listAdvertiser = nameAdvertiser.advertisers
    for(let i= 0; i < listAdvertiser.length; i++ ) {
        console.log("nameAdvertiser",  listAdvertiser[i])
        let advertiserFiltered = idAdvertiser.filter((item) => item.id!=listAdvertiser[i])
        console.log("otrooo", advertiserFiltered)
    }*/
    
   

    function returnState(state) {
        switch (state) {
            case 'Approved':
                return 'Aprobada'
            case 'Rejected':
                return 'Rechazada'
            case 'Pendiente':
                return 'Pendiente'
            case 'Completado':
                return 'Completado'
            case 'Active':
                return 'Activa'
            case 'Finalizada':
            return 'Finalizada'
            case 'En Revision':
            return 'En revisión'
            default:
                return 'Desconocido'
        }
    }
    const finanzas = [{
        text: "CPM: ",
        number: formatNumberCurrency(campaignData.CPM),
    },
    /*{
        text: "Presupuesto: ",
        number: (campaignData.budget )|| 0,
    },*/
    {
        text: "Margen: ",
        number: formatNumberCurrency(campaignData.totalProfit),
    },
    {
        text: " Restante: ",
        number: formatNumberCurrency(campaignData.totalRemainder),
    },
    {
        text: "Alcance: ",
        number: formatNumberCurrency(campaignData.totalAlcance),
    },

    {
        text: "Creatividad: ",
        number: formatNumberCurrency(campaignData.totalCreatividad),
    }, {
        text: "Interaccion: ",
        number: formatNumberCurrency(campaignData.totalInteraction),
    },
    ]
    let socialStatusValue2
    if(campaignData.socialStatusValue[1] === null){
        socialStatusValue2 = campaignData.socialStatusValue[0]
    } else {
        socialStatusValue2 = campaignData.socialStatusValue[1]
    }
    const resumen = [{
        text: "Estado: ",
        number: campaignData.state,
    },
    {
        text: "Campaña: ",
        number: campaignData.name,
    },
    /*{
        text: "Anunciante: ",
        number: campaignData.advertiser,
    },*/
    {
        text: "Presupuesto: ",
        number: (campaignData.budget )|| 0,
    },
    {
        text: "Rango de edad: ",
        number: "De " + campaignData.ageValue[0] + " a " + campaignData.ageValue[1],
    },
    {
        text: "Nivel socioeconómico: ",
        number: campaignData.socialStatusValue[0] + " / " + socialStatusValue2,
    }, 
    /*{
        text: "Genero: ",
        number: campaignData.gender,
    },*/ 
    {
        text: "Alcance: ",
        number: campaignData.reach,
    },
    {
        text: "Vigencia: ",
        number: "Desde " + campaignData.initDate + " hasta " + campaignData.endDate,
    },
    ]

    const isOutOfDate = (date) => {
      let today = new Date();
      let dateExpired = new Date(date);
      if (today < dateExpired) {
        return true;
      } else {
        return false;
      }
    };
    
    function updateCampaignState(campaignId, campaignState) {
        var dateMomentObject = moment(campaignData.initDate, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
        var dateObject = dateMomentObject.toDate();
        if (isOutOfDate(dateObject)) {
          return Swal.fire({
            title:
              "No se puede cambiar el estado de la campaña porque la fecha aun no inicia",
            text: "Muy pronto para iniciar la campana",
            icon: "warning",
            confirmButtonText: "Aceptar",
          });
        }
        const update = {
          id: campaignId,
          state: campaignState,
        };
        APILI.updateCampaignState(update)
        .then((res) => {
            if(campaignState =='Active')
            {
                Swal.fire(
                    '¡Activación exitosa!',
                    '¡La campaña fue activada exitosamente!',
                    'success'
                  )
            }
            else if(campaignState =='Cerrada')
            {
                Swal.fire(
                    '¡Cierre exitoso!',
                    '¡La campaña fue cerrada exitosamente!',
                    'success'
                  )
            }
            setTimeout(() => {
                setState(campaignState);
                setRefresh(!refresh);
                setrefreshTable(!refreshTable)
              }, 2000);
        })
        
    }

    const findSubTaskToFinish =  (taskID, taskStatus) => {
    const body = {
        taskID: taskID,
        };

        APILI.getSubTasksByTask(body)
          .then((res) => {
            let subTasks = res.data.subTasks
            /* console.log('subTasksPelt', subTasks) */
            for(let i = 0; i < subTasks.length; i++)
            {
                const body = {
                    id: subTasks[i].id,
                    status: taskStatus,
                  };
                console.log('currentSubTask'+i, subTasks[i])
                APILI.updateSubTaskStatus(body)
                .then((res) => {
                    /* console.log('resSubTask', res) */
                })
                .catch((err) => {
                    /* console.log('errSubTask', err) */
                });
            }

          })
          .catch((err) => {
            
          });
    };

    const updateTaskStatus = async (taskID, taskStatus) => {
        const update = {
          id: taskID,
          status: taskStatus,
        };
    
        await APILI.updateTaskStatusByID(update)
          .then((res) => {
            findSubTaskToFinish(taskID, taskStatus)
          })
          .catch((err) => {

          });
    };  

    const updateTaskStatusToClose = async (taskID, taskStatus) => {
        const update = {
          id: taskID,
          status: taskStatus,
        };
        await APILI.updateTaskStatusByID(update)
          .then((res) => {
          })
          .catch((err) => {
          });
    }; 

    const finishTasks=()=>{
        for(let i=0; i<currentTasks.length; i++)
        {
            if(currentTasks[i].status=='Aprobado' /* || currentTasks[i].status=='Finalizado' */)
            {
                //Pasa a finalizado
                updateTaskStatus(currentTasks[i].id,'Finalizado')
                
            }
            else if(currentTasks[i].status=='Pendiente')
            {
                //Pasa a rechazado
                updateTaskStatus(currentTasks[i].id,'Rejected')
            }
        }
        setrefreshTable(!refreshTable)
        setRefresh(!refresh);
    }
    const paymentCalculator = async ()=>{
        showLoader(true)
        /* console.log('current camp para pagos', currentCampaign) */
        let finishTasksCount = 0

        let totalReachPaymentPrice = currentCampaign.totalAlcance
        let totalInteractionPaymentPrice = currentCampaign.totalInteraction
        let totalCreativityPaymentPrice = currentCampaign.totalCreatividad 
        for(let i=0; i<currentTasks.length; i++)
         {
            if(currentTasks[i].status=="Aprobado")
            { 
                let totalReachByTask = 0
                let totalInteractionByTask = 0
                let paymentCreativity = 0

                let paymentReachPercent = 0
                let paymentInteractionPercent = 0
                let paymentCreativityPercent = 0

                let paymentReach = 0
                let paymentInteraction = 0
                let result =  await APILI.getSubTasksByTask({taskID: currentTasks[i].id})
                    let AllSubTasks = result.data.subTasks
                    for(let j = 0; j < AllSubTasks.length; j++) 
                    {
                        let res = await APILI.getPostsBySubTask({subtaskID: AllSubTasks[j].id})
                            if(res.data.influencerPosts.length>0)
                            {
                                if(res.data.influencerPosts[0].activitypost == "CUSTOM")
                                {
                                    /* console.log('PostAsignado',res.data.influencerPosts[0]) */
                                    let reach = Number(res.data.influencerPosts[0].insights.reach)
                                    let saved = Number(res.data.influencerPosts[0].insights.saved)
                                    let likes = Number(res.data.influencerPosts[0].likes_count)
                                    let comments = Number(res.data.influencerPosts[0].comments_count)
                                    totalInteractionByTask = totalInteractionByTask + saved + likes + comments
                                    totalReachByTask = totalReachByTask + reach
                                    
                                }
                                else if(currentTasks[i].socialmedia==='Instagram')
                                {
                                   await fetch(`https://graph.facebook.com/v12.0/${res.data.influencerPosts[0].idPost}?fields=id%2Cmedia_type%2Cmedia_url%2Cusername%2Ctimestamp%2Ccaption%2Ccomments_count%2Clike_count%2Cis_comment_enabled%2Cmedia_product_type%2Cpermalink%2Ccomments%7Bid%2Clike_count%2Cmedia%2Ctext%2Cuser%2Cusername%2Ctimestamp%7D%2Cinsights.metric(impressions%2Cengagement%2Creach%2Csaved)&access_token=${currentTasks[i].temporalSubTask.tokenRedSocial}`)
                                    .then((res) => res.json())
                                    .then( (response1) => { 
                                        let reach = response1.insights.data[2].values[0].value
                                        let saved = response1.insights.data[3].values[0].value
                                        let likes = response1.like_count
                                        let comments = response1.comments_count
                                        totalInteractionByTask = totalInteractionByTask + saved + likes + comments
                                        totalReachByTask = totalReachByTask + reach
                                    })
                                }
                                else if(currentTasks[i].socialmedia=="Facebook")
                                {
                                    await fetch(`https://graph.facebook.com/v12.0/${res.data.influencerPosts[0].idPost}/insights?metric=post_engaged_users%2Cpost_impressions%2Cpost_impressions_unique&access_token=${currentTasks[i].temporalSubTask.tokenRedSocial}`)
                                    .then(res3 => res3.json())
                                    .then( (res3) => { 
                                        let reach = res3.data[2].values[0].value
                                        let interaction = res3.data[1].values[0].value
                                        totalInteractionByTask = totalInteractionByTask + interaction
                                        totalReachByTask = totalReachByTask + reach
                                        /* console.log('respuestaPostGuardado',response) */
                                        /* let reach = response.insights.data[2].values[0].value
                                        let saved = response.insights.data[3].values[0].value
                                        let likes = response.like_count
                                        let comments = response.comments_count
                                        totalInteractionByTask = totalInteractionByTask + saved + likes + comments
                                        totalReachByTask = totalReachByTask + reach */
                                    })
                                }
                                /* console.log('res.data', res.data.influencerPosts) */ 
                                
                            }
                            
                    }
                    paymentCreativityPercent = (currentTasks[i].rating/maxRating)*100
                    paymentReachPercent = (totalReachByTask/reachPayment)*100
                    paymentInteractionPercent = (totalInteractionByTask/interactionPayment)*100

                    paymentCreativity = (currentTasks[i].rating/maxRating)*totalCreativityPaymentPrice
                    paymentReach = (totalReachByTask/reachPayment)*totalReachPaymentPrice
                    paymentInteraction = (totalInteractionByTask/interactionPayment)*totalInteractionPaymentPrice

                    let totalAmount = paymentCreativity + paymentReach + paymentInteraction
                    console.log('interactionByTask', totalInteractionByTask)
                    console.log('totalReachByTask', totalReachByTask)
                    console.log('PaymentCreativity',paymentCreativity)
                    console.log('PaymentReach',paymentReach)
                    console.log('PaymentInteraction',paymentInteraction)
                finishTasksCount++
                const bodyPayment = {
                    campaignName: currentTasks[i].campaignName,
                    state: 'Solicitado',
                    createdAt: new Date(),
                    currency: 'USD',
                    endDate: currentTasks[i].endDate,
                    updatedAt: new Date(),
                    id: currentTasks[i].id,
                    userId: currentTasks[i].userID,
                    socialmedia: currentTasks[i].socialmedia,
                    campaignID: currentTasks[i].campaignID,
                    enable: currentTasks[i].enable,
                    reachByTask:totalReachByTask,
                    interactionByTask:totalInteractionByTask ,
                    paymentInteractionPercent: paymentInteractionPercent,
                    paymentInteraction: paymentInteraction,
                    paymentReachPercent: paymentReachPercent,
                    paymentReach: paymentReach,
                    paymentCreativityPercent: paymentCreativityPercent,
                    paymentCreativity: paymentCreativity,
                    totalAmount: totalAmount
                }
                console.log('data para payment', bodyPayment)

               let res = await APILI.createPayment(bodyPayment)
            }
        }
        /* updateCampaignState(currentCampaign.id, "Cerrada") */
        showLoader(false)
    }

    const paymentCalculatorTest = async ()=>{
        showLoader(true)
        /* console.log('current camp para pagos', currentCampaign) */
        let finishTasksCount = 0
        let maxRating =currentCampaign.maxRating

        let totalReachPaymentPrice = currentCampaign.totalAlcance
        let totalInteractionPaymentPrice = currentCampaign.totalInteraction
        let totalCreativityPaymentPrice = currentCampaign.totalCreatividad 
        for(let i=0; i<currentTasks.length; i++)
         {
            if(currentTasks[i].status=="Finalizado")
            { 
                let totalReachByTask = 0
                let totalInteractionByTask = 0
                let paymentCreativity = 0

                let paymentReachPercent = 0
                let paymentInteractionPercent = 0
                let paymentCreativityPercent = 0

                let paymentReach = 0
                let paymentInteraction = 0
                let result =  await APILI.getSubTasksByTask({taskID: currentTasks[i].id})
                    let AllSubTasks = result.data.subTasks
                    for(let j = 0; j < AllSubTasks.length; j++) 
                    {
                        let res = await APILI.getPostsBySubTask({subtaskID: AllSubTasks[j].id})
                            if(res.data.influencerPosts.length>0)
                            {
                                if(res.data.influencerPosts[0].activitypost == "CUSTOM")
                                {
                                    /* console.log('PostAsignado',res.data.influencerPosts[0]) */
                                    let reach = Number(res.data.influencerPosts[0].insights.reach)
                                    let saved = Number(res.data.influencerPosts[0].insights.saved)
                                    let likes = Number(res.data.influencerPosts[0].likes_count)
                                    let comments = Number(res.data.influencerPosts[0].comments_count)
                                    totalInteractionByTask = totalInteractionByTask + saved + likes + comments
                                    totalReachByTask = totalReachByTask + reach
                                    
                                }
                                else if(currentTasks[i].socialmedia==='Instagram')
                                {
                                    await fetch(`https://graph.facebook.com/v12.0/${res.data.influencerPosts[0].idPost}?fields=id%2Cmedia_type%2Cmedia_url%2Cusername%2Ctimestamp%2Ccaption%2Ccomments_count%2Clike_count%2Cis_comment_enabled%2Cmedia_product_type%2Cpermalink%2Ccomments%7Bid%2Clike_count%2Cmedia%2Ctext%2Cuser%2Cusername%2Ctimestamp%7D%2Cinsights.metric(impressions%2Cengagement%2Creach%2Csaved)&access_token=${currentTasks[i].temporalSubTask.tokenRedSocial}`)
                                    .then((res) => res.json())
                                    .then( (response1) => { 
                                        console.log('respose1Post', response1)
                                        let reach = response1.insights.data[2].values[0].value
                                        let saved = response1.insights.data[3].values[0].value
                                        let likes = response1.like_count
                                        let comments = response1.comments_count
                                        totalInteractionByTask = totalInteractionByTask + saved + likes + comments
                                        totalReachByTask = totalReachByTask + reach
                                    })
                                }
                                else if(currentTasks[i].socialmedia=="Facebook")
                                {
                                    await fetch(`https://graph.facebook.com/v12.0/${res.data.influencerPosts[0].idPost}/insights?metric=post_engaged_users%2Cpost_impressions%2Cpost_impressions_unique&access_token=${currentTasks[i].temporalSubTask.tokenRedSocial}`)
                                    .then(res3 => res3.json())
                                    .then( (res3) => { 
                                        let reach = res3.data[2].values[0].value
                                        let interaction = res3.data[1].values[0].value
                                        totalInteractionByTask = totalInteractionByTask + interaction
                                        totalReachByTask = totalReachByTask + reach
                                        /* console.log('respuestaPostGuardado',response) */
                                        /* let reach = response.insights.data[2].values[0].value
                                        let saved = response.insights.data[3].values[0].value
                                        let likes = response.like_count
                                        let comments = response.comments_count
                                        totalInteractionByTask = totalInteractionByTask + saved + likes + comments
                                        totalReachByTask = totalReachByTask + reach */
                                    })
                                }
                                /* console.log('res.data', res.data.influencerPosts) */ 
                                
                            }
                            
                    }
                    paymentCreativityPercent = (currentTasks[i].rating/maxRating)*100
                    paymentReachPercent = (totalReachByTask/reachPayment)*100
                    paymentInteractionPercent = (totalInteractionByTask/interactionPayment)*100

                    paymentCreativity = (currentTasks[i].rating/maxRating)*totalCreativityPaymentPrice
                    paymentReach = (totalReachByTask/reachPayment)*totalReachPaymentPrice
                    paymentInteraction = (totalInteractionByTask/interactionPayment)*totalInteractionPaymentPrice

                    let totalAmount = paymentCreativity + paymentReach + paymentInteraction
                    console.log('interactionByTask', totalInteractionByTask)
                    console.log('totalReachByTask', totalReachByTask)
                    console.log('PaymentCreativity',paymentCreativity)
                    console.log('PaymentReach',paymentReach)
                    console.log('PaymentInteraction',paymentInteraction)
                finishTasksCount++
                const bodyPayment = {
                    campaignName: currentTasks[i].campaignName,
                    state: 'Solicitado',
                    createdAt: new Date(),
                    currency: 'USD',
                    endDate: currentTasks[i].endDate,
                    updatedAt: new Date(),
                    id: currentTasks[i].id,
                    userId: currentTasks[i].userID,
                    socialmedia: currentTasks[i].socialmedia,
                    campaignID: currentTasks[i].campaignID,
                    enable: currentTasks[i].enable,
                    reachByTask:totalReachByTask,
                    interactionByTask:totalInteractionByTask ,
                    paymentInteractionPercent: paymentInteractionPercent,
                    paymentInteraction: paymentInteraction,
                    paymentReachPercent: paymentReachPercent,
                    paymentReach: paymentReach,
                    paymentCreativityPercent: paymentCreativityPercent,
                    paymentCreativity: paymentCreativity,
                    totalAmount: totalAmount
                }
                console.log('data para payment', bodyPayment)

                /* APILI.createPayment(bodyPayment)
                .then(res => {
                console.log(res)
                }).catch(err => {
                console.log(err)
                })  */
            }
        }
        /* updateCampaignState(currentCampaign.id, "Cerrada") */
        showLoader(false)
    }

    const finishCampaing=(campaignId, campaignState)=>{
        /* if(currentCampaign.state!='Completado')
        {
            Swal.fire({
                icon: 'error',
                title: 'Ups...',
                text: 'No puedes finalizar la campaña que aún no ha sido completada!'
              }) 
              return
        } */
        console.log('currentTasks', currentTasks)
        if(canFinish==false)
        {
            Swal.fire({
                icon: 'error',
                title: 'Ups...',
                text: 'No puedes finalizar la campaña, aún tiene tareas sin aprobar!'
              }) 
        }
        else 
        {
        var dateMomentObject = moment(campaignData.endDate, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
        var dateObject = dateMomentObject.toDate();
        if (isOutOfDate(dateObject)) {
          return Swal.fire({
            title:
              "Muy pronto para finalizar la campana",
            text: "No se puede finalizar la campaña porque su fecha de finalización aún no llega",
            icon: "warning",
            confirmButtonText: "Aceptar",
          });
        }
        else{
            Swal.fire({
                title: '¿Deseas finalizar la campaña?',
                text: " ",
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Finalizar',
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (result.isConfirmed) {
                    showLoader(true)
                    const update = {
                        id: campaignId,
                        state: campaignState,
                      };
                      APILI.updateCampaignState(update)
                      .then((result) => {
                        setrefreshTable(!refreshTable)
                        paymentCalculator()
                        finishTasks()
                        Swal.fire(
                            'Finalizada',
                            'La campaña fue finalizada exitosamente',
                            'success'
                        )
                        
                      })
                      
                }
              })
           
        }
            
        }
    }

    

    const StateOption = () => {
        console.log("viendo botones, ", state)
        console.log("viendo botones, ", userRol)
        console.log("CAMOTES, ")
        switch (state) {
            case "Pendiente":
                return (
                    <>
                        {userRol === 'admin' &&
                            <div className="row-info p-btn-right">
                                <button className="rejectedButton" onClick={e => updateCampaignState(currentCampaign.id, "Rejected")}>
                                    <i class="fas fa-times"></i>
                                    Rechazar
                                </button>
                                {console.log('currentStateDesdeINfo', currentCampaign.state)}
                                {(currentCampaign.state=='Active')?
                                (<></>
                                ):(
                                    <button className="acceptButton" onClick={e => updateCampaignState(currentCampaign.id, "Active")}>
                                    <i class="fas fa-check" ></i>
                                    Activar
                                </button>
                                )}
                                
                            </div>
                        }
                    </>

                )
            case "active":
                return (<div></div>);
            default:
                return (<div></div>);
        }
    }

    const DeliveryCol = ({ text, className, onClick }) => (
        <div className={className}>
            <div className={"deliverytask" + (text)}>
                {text}
            </div>
        </div>
    )
    const DetailCol = ({ text, data }) => (
        <div className="firstColl w-100">
            <div className="icon-container icon-container1">
                <i onClick={() => TaskPage(data)}  class="fas fa-eye"></i>
            </div>
   
            <p className="name-fc">
                {text}
            </p>
        </div>
    )

    useEffect(async () => {
        const body = {
            campaignID: currentCampaign.id,
        }

        await APILI.getCampaignById({id:currentCampaign.id})
        .then((campaing) => {
            let state = returnState(campaing.data.campaign[0].state)
            if(state!=undefined){
                setCampaingState(state)
            }
        })
        .catch((err) => {
            console.log('errCampaing', err)
        })

        await APILI.findTaskByCampaignID(body)
        .then((tasks) => {
            getDataView(tasks.data)
            let allTasks = tasks.data.tasks
            setCurrentTasks(allTasks)
            let aprovvedTasks=0
            let aprovvedTasksToFinish=0
            for(let i = 0; i < allTasks.length; i++)
            {
                if(allTasks[i].status=='Aprobado' || allTasks[i].status=='Rejected' || allTasks[i].status=='Pendiente' || allTasks[i].status=='Finalizado')
                {
                    aprovvedTasks++
                }
            }
            for(let i = 0; i < allTasks.length; i++)
            {
                if(allTasks[i].status=='Aprobado')
                {
                    aprovvedTasksToFinish++
                }
            }
            let starsFinish = aprovvedTasksToFinish * 5
            setMaxRating(starsFinish)
            if(aprovvedTasks==allTasks.length)
            {
                setCanFinish(true)
            }
            else if(aprovvedTasks<allTasks.length)
            {
                setCanFinish(false)
            }
        })
        await APILI.getPaymentByCampaign(body).then((payment) => {
            /* console.log('paymentData', payment.data.registerPayment) */
            getPaymentView(payment.data.registerPayment)
        }).catch((error) => {
            console.log("error", error)
        })


    }, [refreshTable, campaignData])

    useEffect( async () => {
        showLoader(true)
        /* console.log('currentCampaing', currentCampaign) */
        let totalReach   = 0
        let totalInteraction = 0
        if(currentTasks.length>0){
            for(let i=0; i<currentTasks.length; i++)
                {
                    if(currentTasks[i].status=="Aprobado")
                    {
                        let totalReachByTask = 0
                        let totalInteractionByTask = 0

                        let result =  await APILI.getSubTasksByTask({taskID: currentTasks[i].id})
                            let AllSubTasks = result.data.subTasks
                            /* console.log('AllSubTasks', AllSubTasks) */
                            for(let j = 0; j < AllSubTasks.length; j++) 
                            {
                                let res = await APILI.getPostsBySubTask({subtaskID: AllSubTasks[j].id})
                                    if(res.data.influencerPosts.length>0)
                                    {
                                        if(res.data.influencerPosts[0].activitypost == "CUSTOM")
                                        {
                                            let reach = Number(res.data.influencerPosts[0].insights.reach)
                                            let saved = Number(res.data.influencerPosts[0].insights.saved)
                                            let likes = Number(res.data.influencerPosts[0].likes_count)
                                            let comments = Number(res.data.influencerPosts[0].comments_count)
                                            totalInteractionByTask = totalInteractionByTask + saved + likes + comments
                                            totalReachByTask = totalReachByTask + reach
                                        }
                                        else if(currentTasks[i].socialmedia==='Instagram')
                                        {
                                            
                                            await fetch(`https://graph.facebook.com/v12.0/${res.data.influencerPosts[0].idPost}?fields=id%2Cmedia_type%2Cmedia_url%2Cusername%2Ctimestamp%2Ccaption%2Ccomments_count%2Clike_count%2Cis_comment_enabled%2Cmedia_product_type%2Cpermalink%2Ccomments%7Bid%2Clike_count%2Cmedia%2Ctext%2Cuser%2Cusername%2Ctimestamp%7D%2Cinsights.metric(impressions%2Cengagement%2Creach%2Csaved)&access_token=${currentTasks[i].temporalSubTask.tokenRedSocial}`)
                                            .then((res2) => res2.json())
                                            .then( (res2) => { 
                                                /* console.log('respons1', response1)
                                                console.log('reach', response1.insights.data[2].values[0].value) */
                                                let reach = res2?.insights?.data[2]?.values[0]?.value
                                                let saved = res2?.insights?.data[3]?.values[0]?.value
                                                let likes = res2?.like_count
                                                let comments = res2?.comments_count
                                                totalInteractionByTask = totalInteractionByTask + saved + likes + comments
                                                totalReachByTask = totalReachByTask + reach
                                            })
                                        }
                                        else if(currentTasks[i].socialmedia=="Facebook")
                                        {
                                            await fetch(`https://graph.facebook.com/v12.0/${res.data.influencerPosts[0].idPost}/insights?metric=post_engaged_users%2Cpost_impressions%2Cpost_impressions_unique&access_token=${currentTasks[i].temporalSubTask.tokenRedSocial}`)
                                            .then(res3 => res3.json())
                                            .then( (res3) => { 
                                                console.log('resFblog', res3.data)
                                                /* fetch(`https://graph.facebook.com/v12.0/${currentTasks[i].temporalSubTask.idPaginaSocial}?fields=posts%7Breactions%2Cmessage%2Cmessage_tags%2Cfull_picture%2Ccreated_time%2Ccomments%2Cpermalink_url%2Cshares%2Cstatus_type%2Cvideo_buying_eligibility%2Cattachments%7D%2Cname&access_token=${currentTasks[i].temporalSubTask.tokenRedSocial}`)
                                                .then((res4) => res4.json())
                                                .then((res4) => {
                                                    console.log('RESFBdesdeUseEffect', res4)
                                                    let currentPost = res4.posts.data.filter((post) => post.id == res.data.influencerPosts[0].idPost)
                                                    console.log('currentPost', currentPost)
                                                    let reach = res3.data[2].values[0].value
                                                    totalInteractionByTask = totalInteractionByTask + res3.data[1].values[0].value
                                                    totalReachByTask = totalReachByTask + reach
                                                }) */

                                                let reach = res3.data[2].values[0].value
                                                let interaction = res3.data[1].values[0].value
                                                totalInteractionByTask = totalInteractionByTask + interaction
                                                totalReachByTask = totalReachByTask + reach
                                                
                                            })
                                        }
                                    }
                            }
                            /* console.log('totalReachByTask',totalReachByTask)
                            console.log('totalInteractionByTask',totalInteractionByTask) */
                            totalReach = totalReach +  totalReachByTask
                            totalInteraction = totalInteraction + totalInteractionByTask
                    }
                }
        if(currentCampaign.reach>totalReach)
        {
            setReachPayment(currentCampaign.reach)
        }
        else if(currentCampaign.reach<=totalReach)
        {
            setReachPayment(totalReach)
        }

        if(currentCampaign.interaction>totalInteraction)
        {
            setInteractionPayment(currentCampaign.interaction)
        }
        else if(currentCampaign.interaction<=totalInteraction)
        {
            setInteractionPayment(totalInteraction)
        }

        }
        showLoader(false)
    }, [currentTasks])

    const customStyles = {
        headCells: {
          style: {
            '&:nth-of-type(2n)': {
              backgroundColor: '#E3E4E6',
              width: '40px',
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
            },
          },
        },
        cells: {
          style: {
            "&:nth-of-type(2n)": {
              backgroundColor: "#E3E4E6"
            },
          },
        }
    }

    const [columns, setColumns] = useState([
        {
            name: 'Nombre del reto o tarea',
            selector: 'name',
            sortable: true,
            minWidth: '200px',
            style: {
                color: '#02025b',
            },
            cell: (row) => (
                <DetailCol
                    text={row.name}
                    data={row}
                />
            ),
        },
        {
            name: 'Estado',
            selector: 'delivery',
            sortable: true,
            center: true,
            style: {
                backgroundColor: '#E3E4E6',
                fontWeight: 'bold',
            },
            type: 'text',
            cell: (row) => (
                <DeliveryCol
                    text={row.status}
                    className={getClassNameByStatus(row.status)}
                />
            )
        },
        {
            name: 'Campaña',
            selector: 'campaignName',
            sortable: true,
            center: true,
            style: {
                fontWeight: 'bold',
            }
        },
        {
            name: 'Fecha de vencimiento',
            selector: 'scope',
            sortable: true,
            center: true,
            style: {
                backgroundColor: '#E3E4E6',
                fontWeight: 'bold',
            },
        },
        {
            name: 'Red Social',
            selector: 'source',
            sortable: true,
            center: true,
            style: {
                fontWeight: 'bold',
            },
            cell: (row) => <img className="source-img" src={row.source} alt="" />
        },
        {
            name: 'Alcance',
            selector: 'inscription',
            sortable: true,
            center: true,
            style: {
                fontWeight: 'bold',
            },
        },
    ])
    const handleFilterPerColumn = (column, type, value) => {
        setFilters((prev) => ({ ...prev, [column]: { type, value } }))
    }

    const handleShowColumnChange = (column, show) => {
        let index = columns.findIndex((col) => col.selector === column)
        let aux = columns.map((col) => col)
        aux[index].omit = show
        setColumns(aux)
    }

    const handleColumnSortChange = (positions) => {

        let aux = columns.map((col) => col)

        positions.forEach((columnId, position) => {
            let index = columns.findIndex((col) => col.selector === columnId)
            aux[index].position = position
        })
        aux = _.orderBy(aux, ['position'])

        setColumns(aux)
    }

    function getName(status) {
        switch (status) {
            case "Pendiente":
                return "Pendiente"
                case "Aprobado":
                return "Aprobado"
                case "Active":
                    return "Activa"
            case "Finalizada":
                return "Finalizada"
            case "active":
                return  "Activa"
            case "En Revision":
                return "En revisión"
            case "rejected":
            return "Rechazado"
            case "Rejected":
                return "Rechazado"
            case "Completado":
                    return "Completado"
            case "Finalizado":
            return "Finalizado"
            case "Cerrada":
                    return "Cerrada"
            case "Solicitado":
                return "Solicitado"
                case "En progreso":
                return("En progreso")
                case "Completado":
                return("Completada")
            default:
                return "Desconocido"
        }
    }

    const getDataView = async (data) => {
        let solicitadas = 0;
        let pendientes = 0;
        let progreso = 0;
        let rechazadas = 0; 
        let finalizadas = 0; 
        let aprobadas = 0;
        for(let i = 0; i < data.tasks.length; i++) {
            if(data.tasks[i].status=="Solicitado")
            {
                solicitadas++
            }
            else if(data.tasks[i].status=="Pendiente")
            {
                pendientes++
            }
            else if(data.tasks[i].status=="En progreso")
            {
                progreso++
            }
            else if(data.tasks[i].status=="Rejected")
            {
                rechazadas++
            }
            else if(data.tasks[i].status=="Finalizado")
            {
                finalizadas++
            }
            else if(data.tasks[i].status=="Aprobado")
            {
                aprobadas++
            }
        }
        setCountcountSolicitadas(solicitadas)
        setCountPendientes(pendientes)
        setCountProgreso(progreso)
        setCountRechazadas(rechazadas)
        setCountFinalizadas(finalizadas)
        setCountAprobadas(aprobadas)
        setStatistics(data.statistics)
        setCount(data.count)
        setTables({
            titles: [
                {
                    name: 'Nombre del reto o tarea',
                    selector: 'name',
                    sortable: true,
                    minWidth: '200px',
                    style: {
                        color: '#02025b',
                    },
                    cell: (row) => (
                        <DetailCol
                            text={row.activityName}
                            data={row}
                        />
                    ),
                },
                {
                    name: 'Estado',
                    selector: 'delivery',
                    sortable: true,
                    center: true,
                    style: {
                        backgroundColor: '#E3E4E6',
                        fontWeight: 'bold',
                    },
                    type: 'text',
                    cell: (row) => (
                        <DeliveryCol
                            text={row.delivery.name}
                            className={row.delivery.className}
                        />
                    )
                },
                {
                    name: 'Creatividad',
                    selector: 'ratingPercent',
                    sortable: true,
                    center: true,
                    style: {
                        fontWeight: 'bold',
                    }
                },
                {
                    name: 'Pago Creatividad',
                    selector: 'ratingFee',
                    sortable: true,
                    center: true,
                    style: {
                        fontWeight: 'bold',
                    }
                },
                {
                    name: 'Alcance',
                    selector: 'scopePercent',
                    sortable: true,
                    center: true,
                    style: {
                        fontWeight: 'bold',
                    }
                },
                {
                    name: 'Pago Alcance',
                    selector: 'scopeFee',
                    sortable: true,
                    center: true,
                    style: {
                        fontWeight: 'bold',
                    }
                },
                {
                    name: 'Interaccion',
                    selector: 'interactionPercent',
                    sortable: true,
                    center: true,
                    style: {
                        fontWeight: 'bold',
                    }
                },
                {
                    name: 'Pago Interaccion',
                    selector: 'interactionFee',
                    sortable: true,
                    center: true,
                    style: {
                        fontWeight: 'bold',
                    },
                },
                {
                    name: 'Total',
                    selector: 'total',
                    sortable: true,
                    center: true,
                    style: {
                        fontWeight: 'bold',
                    }

                },
            ],
            data: data.tasks.map(tasks => ({
                name: tasks.userID,
                delivery: {
                    name: getName(tasks.status),
                    className: getClassNameByStatus(tasks.status)
                },
                campaignName: tasks.campaignName,
                scope: tasks.endDate,
                source: getLogoByName(tasks.socialmedia),
                inscription: tasks.scope,
                id: tasks.id,
                userID: tasks.userID,
                //scope: tasks.scope,
                price: tasks.price,
                socialmedia: tasks.socialmedia,
                activityName: tasks.userID,//tasks.activity, 
                image: '',//tasks.image.split('?')[0],
                campaignID: tasks.campaignID,
                enable: tasks.enable,
                status: tasks.status,
                temporalSubTask : tasks.temporalSubTask,
                interactionPercent: (tasks.interactionFee * 100).toFixed(2) + '%',
                interactionFee: formatNumberCurrency(tasks.interactionFee * campaignData.totalInteraction),
                scopePercent: (tasks.scopeFee * 100).toFixed(2) + '%',
                scopeFee: formatNumberCurrency(tasks.scopeFee * campaignData.totalAlcance),
                ratingPercent: (tasks.ratingFee * 100).toFixed(2) + '%',
                ratingFee: ((tasks.ratingFee) * parseInt(campaignData.totalCreatividad)).toFixed(2),
                total: ((tasks.interactionFee * parseInt(campaignData.totalInteraction)) + (tasks.scopeFee * parseInt(campaignData.totalAlcance)) + (tasks.ratingFee * parseInt(campaignData.totalCreatividad))).toFixed(2)
            }))
        }
        )
    }

    const getPaymentView = (data) => {
        setPaymentTables({
            titles: [
                {
                    name: 'Nombre del reto o tarea',
                    selector: 'activityName',
                    sortable: true,
                    minWidth: '200px',

                    cell: (row) => (
                        <DetailCol
                            text={row.name}
                            data={row}
                        />
                    ),
                },
                {
                    name: 'Estado',
                    selector: 'delivery',
                    sortable: true,
                    center: true,
                    style: {
                        backgroundColor: '#E3E4E6',
                        fontWeight: 'bold',
                    },
                    type: 'text',
                    cell: (row) => (
                        <DeliveryCol
                            text={row.delivery.name}
                            className={row.delivery.className}
                        />
                    )
                },
                {
                    name: 'Actividad',
                    selector: 'campaignName',
                    sortable: true,
                    center: true,
                    style: {
                        fontWeight: 'bold',
                    }
                },
                {
                    name: 'Fecha Solicitado',
                    selector: 'createdAt',
                    sortable: true,
                    center: true,
                    style: {
                        backgroundColor: '#E3E4E6',
                        fontWeight: 'bold',
                    },
                },
                {
                    name: 'Fecha Autorizado',
                    selector: 'authorizedAt',
                    sortable: true,
                    center: true,
                    style: {

                        fontWeight: 'bold',
                    },
                },
                ,
                {
                    name: 'Fecha Acreditado',
                    selector: 'creditedAt',
                    sortable: true,
                    center: true,
                    style: {
                        backgroundColor: '#E3E4E6',
                        fontWeight: 'bold',
                    },
                },
                {
                    name: 'Creatividad',
                    selector: 'ratingFee',
                    sortable: true,
                    center: true,
                    style: {
                        fontWeight: 'bold',
                    },
                },
                {
                    name: 'Alcance',
                    selector: 'scopeFee',
                    sortable: true,
                    center: true,
                    style: {
                        backgroundColor: '#E3E4E6',
                        fontWeight: 'bold',
                    },
                },
                {
                    name: 'Interacción',
                    selector: 'interactionFee',
                    sortable: true,
                    center: true,
                    style: {
                        fontWeight: 'bold',
                    },
                },
                {
                    name: 'Pago',
                    selector: 'amount',
                    sortable: true,
                    center: true,
                    style: {
                        backgroundColor: '#E3E4E6',
                        fontWeight: 'bold',
                    },
                },
                {
                    name: 'Billetera',
                    selector: 'wallet',
                    sortable: true,
                    center: true,
                    style: {
                        fontWeight: 'bold',
                    },
                },
                {
                    name: 'Influencer',
                    selector: 'userId',
                    sortable: true,
                    center: true,
                    style: {
                        backgroundColor: '#E3E4E6',
                        fontWeight: 'bold',
                    },
                },
                {
                    name: 'Red Social',
                    selector: 'source',
                    sortable: true,
                    center: true,
                    style: {
                        fontWeight: 'bold',
                    },
                    cell: (row) => <img className="source-img" src={row.source} alt="" />
                },
            ],
            data: data.map(dat => ({
                
                name: dat.userId,
                delivery: {
                    name: dat.state,
                    className: getClassNameByStatus(dat.state)
                },
                campaignName: dat.campaignName,
                createdAt: new Date(dat.createdAt).toISOString().substring(0, 10),
                source: getLogoByName(dat.socialmedia),
                inscription: dat.scope,
                id: dat.id,
                userId: dat.userId,
                price: dat.price,
                socialmedia: dat.socialmedia,
                activityName: dat.activityName,
                value: dat.amount,
                interactionFee: dat.currency + " " + dat?.paymentInteraction?.toFixed(2),
                ratingFee: dat.currency + " " + dat?.paymentCreativity?.toFixed(2),
                scopeFee: dat.currency + " " + dat?.paymentReach?.toFixed(2),
                amount: dat.currency + " " + dat?.totalAmount?.toFixed(2),
                wallet: dat.currency + " " + dat.wallet,
                authorizedAt: dat?.authorizedAt ? dat.authorizedAt : "Sin Autorizar",
                creditedAt: dat?.creditedAt ? dat.creditedAt : "Sin Acreditar",
            }))
        }
        )
    }

    const completeCampaign = () => {
        tables.data.map(tasks => {
            const data = {
                campaignName: tasks.campaignName,
                state: 'Pendiente',
                createdAt: new Date(),
                currency: 'USD',
                scope: tasks.endDate,
                updatedAt: new Date(),
                id: tasks.id,
                userId: tasks.userID,
                socialmedia: tasks.socialmedia,
                activityName: tasks.activityName,
                campaignID: tasks.campaignID,
                enable: tasks.enable,
                interactionPercent: tasks.interactionPercent,
                interactionFee: tasks.interactionFee,
                scopePercent: tasks.scopePercent,
                scopeFee: tasks.scopeFee,
                ratingPercent: tasks.ratingPercent,
                ratingFee: tasks.ratingFee,
                amount: tasks.total,
            }
            APILI.createPayment(data).then(res => {
                console.log(res)


            }).catch(err => {
                console.log(err)
            })



            Swal.fire({
                title: 'Campaña completada',
                icon: 'success',
                timer: 2000
            })


        })
        APILI.updateCampaignState(
            {
                id: currentCampaign.id,
                'state': "Completed"
            })
            .then(res => {
                APILI.deleteTemporalSubTask({
                    idcampaing: currentCampaign.id
                }).then(res => {
                    console.log("Campaña completada")
                    changeWindow(1)
                }).catch(err => {
                    console.log(err)
                })

            }).catch(err => console.log(err))

    }

    const changeCampaignState = (state) => {


        Swal.fire({
            title: 'Campaña ' + state,
            icon: state === 'Rejected' ? 'error' : 'warning',
            timer: 2000
        })
        const body = {
            id: currentCampaign.id,
            'state': state
        }
        APILI.updateCampaignState(body)
            .then(res => {
                setRefresh(!refresh)
                console.log("Campaña" + state)
                changeWindow(1)
            }).catch(err => console.log(err))

    }
    function formatNumber(number) {
        return new Intl.NumberFormat("es-MX").format(number)
    }
    function formatNumberCurrency(number) {
        return new Intl.NumberFormat("en-US", {style: 'currency',currency: 'USD', minimumFractionDigits: 2}).format(number)
      }
    return (
        <>
        {showTask ? 
            <Task 
            setShowTask={(data) => setShowTask(data)} 
            showTask={showTask} 
            refreshTable={refreshTable} 
            setrefreshTable={(data)=>setrefreshTable(data)} 
            taskPage={taskPage}
            currentCampaign={currentCampaign}
            />
        :<div className='form-dashboard detailCampaign'>
            <div className="Head">
                <i class="btnBack" onClick={() => changeWindow(1)} > <img src={IconBack} />Volver al listado campañas</i>
                <h2>{currentCampaign.slogan} </h2>
            </div>
            {/* información  */}
            <div className="container-boxes">
                <div className='box__grid'>
                    <div class="title-grid">Información básica</div>
                    <div className='box'>
                        {resumen.map((data) => (
                            <div className='content'>
                                <p>{data.text} <span>{data.number}</span></p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='box__grid'>
                    <div class="title-grid">Descripción</div>
                    <div className='box'>
                        <textarea disabled>{currentCampaign.description}</textarea>
                    </div>
                </div>
                <div className='box__grid'>
                    <div class="title-grid">Categorías</div>
                    <div className='box'>
                        {categories.map((data) => (
                            <div className='content'>
                                <p>{data.name}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <div  className='box__grid'>
                    <div class="title-grid">Categorias excluyentes</div>
                    <div className='box'>
                    {campaignData.nonMediaCategories.map((data) => (
                            <div className='content'>
                                <p>{data.name}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <div  className='box__grid'>
                    <div class="title-grid">Hashtags</div>
                    <div className='box'>
                        {hashtags.map((data) => (
                            <div className='content'>
                                <p>{data.text}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='box__grid'>
                    <div className="title-grid">Datos financieros</div>
                    <div className='box'>
                        {finanzas.map((data) => (
                            <div className='content'>
                                <p>{data.text} <span>{data.number}</span></p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* galeria y mapa  */}
            <div className='container__flex'>
                <div className='box-flex'>
                    <div class="title-grid">Imagenes de inspiración</div>
                    <div className='boxContent'>
                        <Galery names={currentCampaign.postImages} />
                    </div>
                </div>
                <div className='box-flex'>
                    <div class="title-grid">Mapa de ubicaciones</div>
                    <div className='boxContent'>
                        <Maps markers={campaignData.locationList} />
                    </div>
                </div>
            </div>
            {/* moderacion campaña */}
            <div className='ContainerCampain'>
                <div class="title-grid">Moderación de Campaña</div>
                <div className='boxes'>
                    <div className='box box1'>
                        <div className='content'>
                            <p>Tareas: <span>{count}</span></p>
                        </div>
                        <div className='content'>
                            <p>Solicitadas: <span>{countSolicitadas}</span></p>
                        </div>
                        <div className='content'>
                            <p>Pendientes: <span>{countPendientes}</span></p>
                        </div>
                        <div className='content'>
                            <p>En progreso: <span>{countProgreso}</span></p>
                        </div>
                        <div className='content'>
                            <p>Rechazadas: <span>{countRechazadas}</span></p>
                        </div>
                    </div>
                    <div className='box box1'>
                        <div className='content'>
                            <p>Aprobadas: <span>{countAprobadas}</span></p>
                        </div>
                        <div className='content'>
                            <p>Finalizadas: <span>{countFinalizadas}</span></p>
                        </div>
                        <div className='content'>
                            <p>Acreditadas: <span>0</span></p>
                        </div>
                        <div className='content'>
                            <p>Pagadas: <span>0</span></p>
                        </div>
                    </div>
                    <div className='box box2'>
                        <ProgresiveBar
                            Estado={campaingState}
                        />
                        <div className='btns'>
                            {/* {console.log('campaingState: ', campaingState)} */}
                                {(campaingState=='Activa' || campaingState=='Completado' || campaingState=='Finalizada')?
                                (<></>
                                ):(
                                    <button onClick={e => updateCampaignState(currentCampaign.id, "Active")}>Activar</button>
                                )}
                                {(/* currentCampaign.state!='Finalizada' */  currentCampaign.state!='ola')?
                                (<button onClick={e => finishCampaing(currentCampaign.id, "Finalizada")}>Finalizar</button>  
                                ):(
                                    <></>
                                )}
                            {/* <button onClick={e => paymentCalculatorTest()}>Calcular pagos</button>   */}
                        </div>
                    </div>
                </div>
            </div>
            {/* tablas */}
            <div className='boxTables'>
                <div class="title-grid">Tareas de campaña</div>
                <div className='box'>
                <ReactDataTable
                    columns={tables.titles}
                    customStyles={customStyles}
                    data={tables.data}
                    persistTableHead
                    noHeader
                    style={{ maxWidth: '800px' }}
                    noDataComponent={"No hay datos para mostrar"}
                    paginationComponentOptions={{
                        rowsPerPageText: 'Elementos por página',
                        rangeSeparatorText: 'de',
                    }}
                />
                </div>
                {userRol === 'admin' &&
                <>
                <div class="title-grid">Pagos</div>
                <div className='box'>
                <ReactDataTable
                    columns={paymentTables.titles}
                    customStyles={customStyles}
                    data={paymentTables.data}
                    persistTableHead
                    noHeader
                    noDataComponent={"No hay datos para mostrar"}
                    paginationComponentOptions={{
                        rowsPerPageText: 'Elementos por página',
                        rangeSeparatorText: 'de',
                    }}
                    style={{ maxWidth: '800px' }}
                    fixedColumns={{
                        left: 1,
                        right: 1,
                    }}
                />
                </div> 
                </> }
            </div>
        </div>
        }
        </>
    );
};

const mapDispatchToProps = dispatch => ({
    _showLoader: show => dispatch(showLoader(show))
  })
  
  const mapStateToProps = (state) => ({
    isLoading: state.app.isLoading,
    myUser: state.users.myUser
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(Info)