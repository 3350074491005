import React, { useState, useEffect } from "react";
import { Auth, Hub } from "aws-amplify";
import NativeSelect from "@material-ui/core/NativeSelect";
import "react-google-flight-datepicker/dist/main.css";
import { useDispatch, connect } from "react-redux";
import { showLoader } from "redux/actions/loader.actions";
import InputModal from "./inputModal";
import * as Modal from "redux/actions/modal.actions";
import * as APILI from "helpers/apiLI";
import Switch from "@material-ui/core/Switch";
import { getLogoByName, getClassNameByStatus } from "myUtil";
import { getCampaign } from "helpers/apiLI";
import { v4 } from "uuid";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Swal from "sweetalert2/dist/sweetalert2.js";

const AddTask = ({ userRol, title, showModal, _showAlertModal }) => {
  const currentUser = JSON.parse(localStorage.getItem("CurrentUser")) ?? [];
  const [user, setUser] = useState(null);
  const [userTxt, setUserTxt] = useState("");
  const [nameRegistered, setNameRegistered] = useState("");
  const [emailRegistered, setEmailRegistered] = useState("");
  const [passwordTxt, setPasswordTxt] = useState("");
  const [countryTxt, setCountryTxt] = useState("");
  const [languageTxt, setLanguageTxt] = useState("");
  const [companyTxt, setCompanyTxt] = useState("");
  const [nameContactTxt, setNameContactTxt] = useState("");
  const [stallContactTxt, setStallContactTxt] = useState("");
  const [phoneContactTxt, setPhoneContactTxt] = useState("");
  const [nitTxt, setNitTxt] = useState([]);
  const [showInfluencers, setshowInfluencers] = useState(false);
  const [influencers, setInfluencers] = useState([]);
  const [moderator, setModerator] = useState();
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [selectedCampaignList, setSelectedCampaignList] = useState([]);
  const [activity, setactivity] = useState([]);
  const [campaignData, setCampaignData] = useState({
    name: "",
    id: "",
    activity: [],
    endDate: "",
    socialmedia: [],
    price: 0,
  });
  const [influencerData, setinfluencerData] = useState({
    id: "",
    createdAt: "",
    image: "",
    influencerid: "",
    scope: "",
    socialmedia: "",
    status: "",
    type: "",
    updateAt: "",
    url: "",
    userprofile: "",
  });
  const [activityData, setactivityData] = useState({
    name: "",
    quantity: "",
    id: "",
    activity: "",
    price: "",
    status: true,
  });

  const [endDate, setendDate] = useState("");
  const [influencerID, setinfluencerID] = useState("");
  const [socialmedia, setsocialmedia] = useState("");
  const [scope, setscope] = useState("");
  const [price, setprice] = useState("");
  const [profilesData, setProfilesData] = useState([]);
  const [email, setemail] = useState("");
  const [username, setusername] = useState("");
  const [lastname, setlastname] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [campaignID, setcampaignID] = useState("");
  const [showOn, setshowOn] = useState(false);
  const [showInfluencer, setShowInfluencer] = useState(false);
  const [advertisers, setAdvertisers] = useState([]);

  const dispatch = useDispatch();

  const getUser = () => {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.log("Not signed in"));
  };

  const saveLog = async (body) => {
    try {
      APILI.createNewLog(body)
        .then((res) => {
          console.log("Log Creado");
        })
        .catch((err) => {
          console.log("err", err);
        });
    } catch (err) {
      console.log("No se pudo guardar el log", err);
    }
  };

  const saveErrorLog = async (body) => {
    try {
      APILI.createNewErrorLog(body)
        .then((res) => {
          console.log("Error log Creado");
        })
        .catch((err) => {
          console.log("err", err);
        });
    } catch (err) {
      console.log("No se pudo guardar el log de error", err);
    }
  };

  const saveTask = async () => {
    //for (let i = 0; i <campaignData.activity.length; i++) {
    const body = {
      id: v4(),
      activity: selectedCampaign[0].name,
      campaignName: selectedCampaign[0].name,
      endDate: selectedCampaign[0].endDate,
      userID: influencerData.influencerid,
      status: "Solicitado",
      socialmedia: selectedCampaign[0]?.socialNetworks[0]?.name,
      scope: scope,
      //price: campaignData.price,
      campaignID: selectedCampaign[0].id,
      enable: true,
      influencerid: influencerData.influencerid,
      quantity: selectedCampaign[0].activity.length,
      createdAt: new Date(),
      updatedAt: new Date(),
    };
    await APILI.createTask(body)
      .then((res) => {
        Swal.fire({
          title: '¡Tarea creada con éxito!',
          icon: "success",
        });
        const bodyLog = {
          Tipo: "Insert",
          Accion: "Crear nueva tarea",
          Data: body,
          Historico: {},
          CodeHttp: res.status,
          UsuarioId: currentUser.username,
          UsuarioName: currentUser.attributes.name,
          Email: currentUser.attributes.email,
        };
        saveLog(bodyLog);
      })
      .catch((err) => {
        const bodyLog = {
          Tipo: "Insert error",
          Accion: "Crear nueva tarea",
          Data: body,
          Historico: {},
          CodeHttp: err,
          UsuarioId: currentUser.username,
          UsuarioName: currentUser.attributes.name,
          Email: currentUser.attributes.email,
        };

        saveErrorLog(bodyLog);
        console.log(err);
        Swal.fire({
          title: `Algo salio mal!!`,
          icon: "errpr",
        });
      });

    // }
  };
  useEffect(() => {
    APILI.getCampaign()
      .then((res) => {
        if (res.data && res.data.campaigns && res.data.campaigns.length > 0) {
          getCampaignView(res.data.campaigns, res.data.count);
          console.log(res.data);
        }
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    APILI.getInfluencersData()
      .then((res) => {
        if (
          res.data &&
          res.data.influencerdata &&
          res.data.influencerdata.length > 0
        ) {
          getInfluencersInfoView(res.data.influencerdata);

          // setinfluencersData(res.data.influencerdata)
        }
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    APILI.getAdvertiser()
      .then((res) => {
        if (
          res.data &&
          res.data.advertisers &&
          res.data.advertisers.length > 0
        ) {
          //getInfluencersInfoView(res.data.advertisers)
          //console.log(res.data.advertisers)
          getDataAdvertiser(res.data.advertisers);
          // setinfluencersData(res.data.influencerdata)
        }
      })
      .catch(console.error);
  }, []);

  const getCampaignView = (data) => {
    setCampaigns(data);
  };

  const dataCamp = (dat) => {
    setshowOn(true);
    let data = campaigns.filter((data) => data.id === dat);

    let objeto = {
      name: data[0].name,
      id: data[0].id,
      activity: data[0].activity,
      endDate: data[0].endDate,
      socialmedia: data[0].socialNetworks,
      price: data[0].reward,
    };
    setCampaignData(objeto);
  };

  const selectAdvertiser = (dato) => {
    setshowOn(true);
    console.log(dato);
    let data = campaigns.filter((dat) => dat.advertiser === dato);
    console.log("holamundo", data);
    setSelectedCampaignList(data);
  };

  const selectCamp = (dato) => {
    console.log(dato);
    let data = selectedCampaignList.filter((dat) => dat.id === dato);
    console.log(data);
    setSelectedCampaign(data);
  };

  const getDataAdvertiser = (dat) => {
    // setshowOn(true)
    //let data = campaigns.filter(data => data.id ===dat)

    /* let objeto = {
            name: data[0].name,
            id: data[0].id,
            activity: data[0].activity,
            endDate : data[0].endDate,
            socialmedia: data[0].socialNetworks,
            price: data[0].reward
        }*/
    setAdvertisers(dat);
  };
  function amount(item) {
    return item.scope;
  }

  function sum(prev, next) {
    return prev + next;
  }

  const findProfileData = () => {
    let body = {
      influencerid: email,
      userprofile: username,
      socialmedia: selectedCampaign[0]?.socialNetworks[0]?.name,
    };
    console.log(body);
    APILI.getProfilesByUserorEmail(
      body
      /*  { id: email, 
                name: name, 
                lastname: lastname, 
                phoneNumber: phoneNumber
            }*/
    )
      .then((res) => {
        if (res.data && res.data.profile && res.data.profile.length > 0) {
          console.log(res);
          getProfilesData(res.data.profile);
          setshowInfluencers(true);
        }
      })
      .catch(console.error);
  };

  const getProfilesData = (data) => {
    setProfilesData(data);
    console.log("daaaaaat", data);
  };

  const asignProfile = (data) => {
    console.log(data);
  };

  const activityCamp = (dat) => {
    let data = campaignData.activity.filter((data) => data.id === dat);
    console.log(data, dat);
    let thisscope = 0;

    let objeto = {
      name: data[0].name,
      quantity: data[0].quantity,
      id: data[0].id,
      activity: data[0].activity,
      price: data[0].price,
      status: true,
      scope: thisscope,
    };
    setactivityData(objeto);
    console.log(objeto);
  };
  const influencerCamp = (e, dat) => {
    console.log(dat);
    setShowInfluencer(true);
    //console.log(scope);
    setinfluencerData(dat);
  };
  const getInfluencersInfoView = (camps) => {
    setInfluencers(camps);
  };

  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
  });

  console.log(campaignData);
  const [modifyProfile, setModifyProfile] = useState(false);
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    setModifyProfile(!modifyProfile);
  };

  return (
    <div className="blur-div">
      <div className="content-modal">
        <div className="closeModal-content">
          <i
            className="fas fa-times-circle"
            onClick={(e) => showModal(false)}
          ></i>
        </div>
        <div>
          <h2 className="titleModal">{title}</h2>
        </div>
        <div className="body-modal">
          <div className="part-BodyModal">
            <p>Selecciona un advertiser</p>
            <NativeSelect
              value={advertisers.name}
              onChange={(e) => selectAdvertiser(e.target.value)}
              name="age"
              className="w-90"
            >
              <option value="" default>
                Selecciona un advertiser
              </option>
              {advertisers.map((adr) => (
                <option value={adr.id}>{adr.name}</option>
              ))}
            </NativeSelect>
            {showOn ? (
              <>
                <p>Selecciona un campaña</p>
                {/*onChange={e => selectCamp(e.target.value)} */}
                <NativeSelect
                  value={selectedCampaign.name}
                  onChange={(e) => selectCamp(e.target.value)}
                  name="age"
                  className="w-90"
                >
                  <option value="" default>
                    Selecciona una campaña
                  </option>
                  {selectedCampaignList.length > 0 ? (
                    selectedCampaignList.map((adr) => (
                      <option value={adr.id}>{adr.name}</option>
                    ))
                  ) : (
                    <option value="" disabled>
                      No hay campañas
                    </option>
                  )}
                </NativeSelect>
                {/* <InputModal lockornot={modifyProfile} title="Usuario" placeholder={influencerData.userprofile} value={influencerData.userprofile}  />
                        <InputModal lockornot={modifyProfile} title="Influencer" placeholder={influencerData.influencerid} value={influencerData.influencerid}  />
                        <InputModal lockornot={false} title="Precio" placeholder={campaignData.price} value={campaignData.price} change={setactivity}  />
                        <InputModal lockornot={false} title="Fecha de Finalizacion" placeholder={campaignData.endDate} value={campaignData.endDate} change={setactivity}  /> */}

                <div className="mb-15" />
                <p>Actividades a realizar</p>
                <Divider />
                <div className="mb-15" />
                {selectedCampaign[0]?.activity.map((format) => (
                  <div>
                    <div class="row">
                      <div class="col-sm">
                        <p
                          className="font-size: xx-small"
                          className="font-weight-normal"
                        >
                          <span className="font-weight-bold">
                            {format.quantity} {" - "} {format.name}
                          </span>
                          {" - "}
                          {format.activity}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="mb-15" />

                <p>
                  Buscar Perfil en Red Social{" "}
                  {selectedCampaign[0]?.socialNetworks[0]?.name}
                </p>
                <Divider />
                <div className="mb-15" />
                <InputModal
                  lockornot={true}
                  title="Email"
                  placeholder={email}
                  value={email}
                  change={setemail}
                />
                <InputModal
                  lockornot={true}
                  title="Username"
                  placeholder={username}
                  value={username}
                  change={setusername}
                />

                <Button
                  onClick={() => findProfileData()}
                  variant="contained"
                  color="secondary"
                >
                  Buscar
                </Button>
                {showInfluencers ? (
                  <div class="list-group">
                    {profilesData.map((datos) => (
                      <div>
                        {" "}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={(e) => influencerCamp(e, datos)}
                          className="list-group-item list-group-item-action"
                        >
                          @{datos.userprofile}{" "}
                          <img
                            className="source-img"
                            src={getLogoByName(datos.socialmedia)}
                            alt=""
                          />{" "}
                          <i className="fa fa-file-plus-fill"></i>
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p> No hay influencers seleccionados para mostrar</p>
                )}
              </>
            ) : null}
          </div>

          <div className="part-BodyModal">
            {showInfluencer && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="mb-15" />

                <img src={influencerData.image} alt="" />
                <div style={{ display: "inline-block" }}>
                  <img
                    className="source-img"
                    src={getLogoByName(influencerData.socialmedia)}
                    alt=""
                  />
                  <p>@{influencerData.userprofile}</p>
                </div>
                <p>{influencerData.influencerid}</p>
                <p>{influencerData.socialmedia}</p>
                <p>{influencerData.status}</p>
                <p>{influencerData.username}</p>
                <p>{influencerData.createdAt}</p>
              </div>
            )}
          </div>
        </div>
        <div className="footer-Modal">
          <div className="buttons-Modal-content">
            <button onClick={(e) => showModal(false)}>Cancelar</button>
            <button onClick={(e) => saveTask()}>Guardar</button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  _showLoader: (show) => dispatch(showLoader(show)),
  _showAlertModal: (title, message, type, callback) =>
    dispatch(Modal.showAlertModal(title, message, type, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTask);
