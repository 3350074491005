import React from 'react';
import { Line } from 'react-chartjs-2';



const AreaChart = (props) => {
    const { array } = props;
    console.log("estado tareass", array?.pendiente)

    const data = {
        type: 'line',
        labels: array?.months,
        datasets: [
            {
                label: "Inscripciones",
                data: array?.Pendiente,
                fill: true,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "#57d4fd",
                tension: 0.4
            },
            {
                label: "Tareas completadas",
                data: array?.completed,
                fill: false,
                borderColor: "#5cf993",
                tension: 0.4
            }
        ],
    };


    return (
        <div className="App">
            <Line data={data}
                width={650}
                height={200}
                options={{
                    responsive: true,
                    plugins: {
                        title: {
                            display: false
                        },
                        legend: {
                            display: true,
                            labels: {
                                usePointStyle: true,
                            },
                            position: "top"
                        },
                        datalabels: {
                            display: true,
                            color: 'white'
                        }
                    }
                }} />
        </div>
    );

}

export default AreaChart