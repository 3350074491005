import React, { useState } from 'react'
import * as utils from 'data.js'
import _ from 'lodash'
import DataTableUtils, {
  Share,
  ShowColumn,
  FilterColumn
} from '@easygosanet/react-datatable-utils'
import ReactDataTable from '@easygosanet/react-datatable'

//Components
import ImageBox from 'components/imageBox'
import GraphBox from 'components/graphBox'

//Charts
import GenderChart from 'components/genderChart'
import PieChart from 'components/pieChart'

//images
import Logo_facebook from './assets/img/logo_facebook.svg'
import Logo_instagram from './assets/img/logo_instagram.png'
import Logo_tiktok from './assets/img/logo_tiktok.svg'
import logo_linkedin from './assets/img/logo_linkedin.svg'

const DetailCol = ({ text, className, onClick }) => (
  <div className="firstColl w-100">
    <div className="icon-container">
      <i class="fas fa-eye"></i>
      <i className="fas fa-edit"></i>
      <i class="fas fa-trash"></i>
      <i class="fab fa-telegram-plane"></i>
      <i class="fas fa-ellipsis-v"></i>
    </div>
    <p className="name-fc">
      {text}
    </p>
  </div>
)

const customStyles = {
  headCells: {
    style: {
      '&:nth-last-of-type(3)': {
        backgroundColor: '#E3E4E6',
        width: '40px'
      },
      '&:nth-last-of-type(5)': {
        backgroundColor: '#E3E4E6',
      },
    },
  },
}

const Debug = (props) => {

  const [filters, setFilters] = useState({})
  console.log(filters);
  const goToEditPage = (id, name, createAt) => {
    let edit = {
      id,
      name,
      createAt,
    }
    props._setEdit(edit)

    window.$.fancybox.open({
      src: '#new-category-modal',
      //type: 'modal',
      opts: {
        modal: true,
        afterClose: () => props._clearEdit(),
      },
    })
  }

  let filteredData = [
    {
      name: "Reto numero 1",
      delivery: "texto",
      budget: "$ 2,500.00",
      scope: "10,000",
      source: Logo_facebook,
      inscription: "Q. 459.00"
    },
    {
      name: "Reto numero 2",
      delivery: "texto",
      budget: "$ 2,500.00",
      scope: "2,647",
      source: Logo_instagram,
      inscription: "Q. 459.00"
    },
    {
      name: "Reto numero 3",
      delivery: "texto",
      budget: "$ 2,500.00",
      scope: "25,641",
      source: Logo_facebook,
      inscription: "Q. 459.00"
    },
    {
      name: "Reto numero 4",
      delivery: "texto",
      budget: "$ 2,500.00",
      scope: "25,641",
      source: Logo_facebook,
      inscription: "Q. 459.00"
    },
    {
      name: "Reto numero 5",
      delivery: "texto",
      budget: "$ 2,500.00",
      scope: "25,641",
      source: Logo_instagram,
      inscription: "Q. 459.00"
    },
    {
      name: "Reto numero 6",
      delivery: "texto",
      budget: "$ 2,500.00",
      scope: "25,641",
      source: Logo_facebook,
      inscription: "Q. 459.00"
    }
  ]

  const [columns, setColumns] = useState([
    {
      name: 'Identificador',
      selector: 'name',
      sortable: true,
      minWidth: '200px',
      style: {
        color: '#02025b',
      },
      cell: (row) => (
        <DetailCol
          text={row.name}
          onClick={() =>
            goToEditPage(row.id, row.name, row.createAt)
          }
          className='edit-fc'
        />
      ),
    },
    {
      name: 'Status',
      selector: 'delivery',
      sortable: true,
      center: true,
      style: {
        backgroundColor: '#E3E4E6',
        fontWeight: 'bold',
      },
      type: 'text'
    },
    {
      name: 'Nombre',
      selector: 'budget',
      sortable: true,
      center: true,
      style: {
        fontWeight: 'bold',
      }
    },
    {
      name: 'Username',
      selector: 'scope',
      sortable: true,
      center: true,
      style: {
        backgroundColor: '#E3E4E6',
        fontWeight: 'bold',
      },
    },
    {
      name: 'E-mail',
      selector: 'source',
      sortable: true,
      center: true,
      style: {
        fontWeight: 'bold',
      },
      cell: (row) => <img className="source-img" src={row.source} alt="" />
    },
    {
      name: 'Joined',
      selector: 'inscription',
      sortable: true,
      center: true,
      style: {
        fontWeight: 'bold',
      },
    },
  ])

  const handleFilterPerColumn = (column, type, value) => {
    setFilters((prev) => ({ ...prev, [column]: { type, value } }))
  }

  const handleShowColumnChange = (column, show) => {
    let index = columns.findIndex((col) => col.selector === column)
    let aux = columns.map((col) => col)
    aux[index].omit = show
    setColumns(aux)
  }

  const handleColumnSortChange = (positions) => {

    let aux = columns.map((col) => col)

    positions.forEach((columnId, position) => {
      let index = columns.findIndex((col) => col.selector === columnId)
      aux[index].position = position
    })
    aux = _.orderBy(aux, ['position'])

    setColumns(aux)
  }

  return (
    <div className='form-dashboard'>
      <div className='Bar-content'>
        <div className='content-menu list'>
          <select
            id='viewSelect'
            className='select-dashboard fa'
          >
            <option className='fa' value='1'>
              {' '}
              &#xf0ce; Espacio 1
            </option>
            <option className='fa' value='0'>
              {' '}
              &#xf0db; Vista de Kanban{' '}
            </option>
            <option className='fa' value='2'>
              {' '}
              &#xf073; Vista de calendario
            </option>
          </select>
          < DataTableUtils>
            <Share
              contacts={utils.myContacts}
            />
            <ShowColumn
              columns={columns}
              showColumn={handleShowColumnChange}
              onSortChange={handleColumnSortChange}
            />
            <FilterColumn
              columns={columns}
              onFilterColumn={handleFilterPerColumn}
            />
          </DataTableUtils>

          <div
            className='btnOrange-dash'
          >
            <i className='fas fa-plus'></i>
          </div>
        </div>
      </div>
      <div className="row-section">
        <div className="row-info">
          <div className="circle" />
          <p className="title-text">   Campañas por red social</p>
        </div>
        <div className="content-section social-network2">
          <ImageBox className="rectangle-img-info" image={Logo_facebook} num="47" text1="Alcance promedio: 1,111" text2="Tasa de interaccion promedio: 1.17" />
          <ImageBox className="rectangle-img-info" image={Logo_instagram} num="18" text1="Alcance promedio: 1,111" text2="Tasa de interaccion promedio: 1.17" />
          <ImageBox className="rectangle-img-info" image={Logo_tiktok} num="10" text1="Alcance promedio: 1,111" text2="Tasa de interaccion promedio: 1.17" />
          <ImageBox className="rectangle-img-info" image={logo_linkedin} num="35" text1="Alcance promedio: 1,111" text2="Tasa de interaccion promedio: 1.17" />
        </div>
      </div>
      <div className="row-section">
        <div className="content-section content-graph-cricle margin-top">
          <div>
            <div className="row-info">
              <div className="circle" />
              <p className="title-text">   Usuario por genero</p>
            </div>
            <GraphBox nameClass="rectangle-graph-meddium margin" >
              <div className="userByGender">
                <GenderChart />
              </div>
            </GraphBox>
          </div>
          <div>
            <div className="row-info">
              <div className="circle" />
              <p className="title-text">   Usuario por edad</p>
            </div>
            <GraphBox nameClass="rectangle-graph-meddium graph" >
              <div className="userByState">
                <PieChart data={utils.dataGraphUsers}
                  dataUsersAge={utils.dataUsersAge}
                  width={170}
                  height={170} />
              </div>
            </GraphBox>
          </div>
          <div>
            <div className="row-info">
              <div className="circle" />
              <p className="title-text">   Perfiles por estado</p>
            </div>
            <GraphBox nameClass="rectangle-graph-meddium" >
              <div className="hashtagTop">

              </div>
            </GraphBox>
          </div>
        </div>
      </div>
      <div className="row-section">
        <div className="content-section">
          <div className="row-info">
            <div className="circle" />
            <p className="title-text">   Rendimiento de la cuenta</p>
          </div>
        </div>
        <ReactDataTable
          columns={columns}
          customStyles={customStyles}
          data={filteredData}
          persistTableHead
          pagination
          noHeader
        />
      </div>
    </div>
  )
}

export default Debug
