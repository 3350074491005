import moment from 'moment'

import * as API from 'helpers/api'
import * as Logger from 'utils/logger'
import * as Modal from './modal.actions'
import { showLoader } from './loader.actions'

export const FB_PROP = 'FACEBOOK:PROP'
export const FB_DATOS = 'FACEBOOK:DATOS'

const TAG = 'FACEBOOK:ACTION'

export const setProp = prop => dispatch => {
    dispatch({
        type: FB_PROP,
        prop,
    })
}

export const getDatos = () => (dispatch) => {
    dispatch(showLoader(true))
    API.getDatosWH({
        key: 'object',
        value: 'page',
    })
        .then(response => {
            Logger.log(TAG, 'getDatos', response)

            const info = [];
            if (response.data && response.data.length) {
                response.data.forEach(wh => {
                    if (wh.entry[0].changes) {
                        info.push({
                            id: wh.entry[0].id,
                            message: wh.entry[0].changes[0].value.message,
                            type: wh.entry[0].changes[0].field,
                            from: wh.entry[0].changes[0].value.from,
                            date: new Date(wh.entry[0].time * 1000).toISOString()
                        });
                    } else if (wh.entry[0].standby && wh.entry[0].standby[0].message) {
                        info.push({
                            id: wh.entry[0].id,
                            message: wh.entry[0].standby[0].message.text,
                            type: 'inbox',
                            from: wh.entry[0].standby[0].sender,
                            date: new Date(wh.entry[0].time).toISOString()
                        });
                    }
                });
            }

            dispatch({
                type: FB_DATOS,
                info
            })
        })
        .catch(err => {
            Logger.log(TAG, 'getDatos', err)
        })
        .then(() => {
            dispatch(showLoader(false))
        })
}
