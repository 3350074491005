import  React, {useState, useEffect} from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Badge from '@mui/icons-material/Badge';
import * as APILI from 'helpers/apiLI';


export default function TitlebarImageList(props) {
  const {mediaData} = props;
  console.log("from post",mediaData)
  const [mediaList, setMediaList] = useState(mediaData);
  

  return (
    <>
  
      {mediaData.map((item) => (
        <ImageListItem key={item.id} sx={{margin:1}}>
          <img
            src={item.media_url}
            srcSet={item.media_url}
            alt={item.caption}
            loading="lazy"
          />
          <ImageListItemBar
            title={item.caption}
            subtitle={"Likes:"+item.like_count+" Comments:"+item.comments_count}
            actionIcon={
              <IconButton>
                <InfoIcon color="action" />
              </IconButton>           
            }
          />
        </ImageListItem>
      ))}
    
    </>
  );
}
