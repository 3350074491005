import { APP_CLEAR_CURRENT_USER } from '../actions/app.actions'
import * as Actions from '../actions/loader.actions'

const initialState = {
  isLoading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.SHOW_LOADER:
      return {
        ...state,
        isLoading: action.show,
      }
    case APP_CLEAR_CURRENT_USER:
      return initialState
    default:
      return state
  }
}
