/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "campaing-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "advertiser-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "mediaCategory-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "socialNetwork-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "influencerInfo-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "task-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "countries-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "languages-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "socialmediaprice-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "socialmediausers-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "influencerdata-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "socialprofiles-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "influencerPosts-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "fbapps-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "fb-tokens",
            "region": "us-east-1"
        },
        {
            "tableName": "fbwebhook",
            "region": "us-east-1"
        },
        {
            "tableName": "fbpagesapi-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "fbappsapi-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "fbcommentsapi-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "fbdailyapi-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "fbreactionsapi-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "fbtokensapi-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "fbuniusersapi-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "fbwebhookapi-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "fbusersapi-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "facebookUsersData-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "userRoles-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "igauthCodeApi-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "iguserTokenApi-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "iguserDataApi-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "iguserPostsApi-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "igmediaDataApi-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "igstoriesApi-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "igstoryDataApi-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "iguserProfileApi-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "wallet-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "currency-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "bankList-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "registerPayment-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "subTasks-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "bigQueryApi-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "influencerDevices-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "notificator-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "webHook-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "dataPage-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "advertisers-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "errorLog-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "bitacoras-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "temporalSubTask-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "infoLogs-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "tasksSubTasks-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "permissions-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "permissionsv2-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "roles-dev",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "likeincimages222610-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "LikeInc",
            "endpoint": "https://zqg0e4rvsc.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:a280d60e-9dd9-46dc-adc1-0acb218c30dc",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_H1qj2ZoSp",
    "aws_user_pools_web_client_id": "ue6holbn0ieoh2n9nbdpf194j",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
