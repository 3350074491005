import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);



export default function ChartStatusSubtask() {
  const data = {
    labels: ['Pendiente', 'En revisión', 'Aprobado', 'Rechazado'],
    datasets: [
      {
        label: 'Rainfall',
        data: [1, 5, 2, 2,],
        backgroundColor: [
          '#7e7e80',
          '#ff642e',
          '#00ca72',
          '#ff3d57',
        ],
        borderColor: [
          '#fff',
        ],
        borderWidth: 2,
      },
    ],
  };



  return (
    <Pie 
      data={data}
      options={{
        datalabels:{
          borderRadius: "50%"
        },
        legend: {
          borderRadius: "50%"
        }
      }}
    />
  )
}
