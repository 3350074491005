/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Select from 'react-select'


import ReactDataTable from '@easygosanet/react-datatable'
import TitlebarImageList from './GalleryPosts'
import TitlebarStoryList from './GalleryStories'
import StarsShow from 'components/starsShow'


import './assets/css/styles.css'
import { getLogoByName, getClassNameByStatus } from 'myUtil'

/****material components */
import NativeSelect from '@material-ui/core/NativeSelect';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import FaceIcon from '@mui/icons-material/Face';
import AccountBoxIcon from '@mui/icons-material/AccountBox';


import { getDatos, setProp } from 'redux/actions/fanpageWebHook.actions'
import { withUtils } from 'utils'
import * as APILI from 'helpers/apiLI';
import { v4 } from 'uuid'

import Swal from 'sweetalert2/dist/sweetalert2.js'


import "./styles.css";



const Facebook = (props) => {
  
  const {importedUser} = props
  //console.log("importedUser",importedUser)
  
  const [userData, setUserData] = useState([])
  const [userPosts, setUserPosts] = useState([])
  const [storyData, setStoryData] = useState([])
  
  
  const [user, setUser] = useState({
      "user_id": "1234567890",
      "media_count": 11,
      "username": "DummyUser",
      "account_type": "MARKET_PLACE"
  })
  const [posts, setPosts] = useState([])
  const [media, setMedia] = useState('')
  const [mystories, setmyStories] = useState([])
  const [story, setStory] = useState('')
  const [userProfile, setuserProfile] = useState('')
  const [imageUrl, setImageUrl] = useState("");
  const [postCaption, setPostCaption] = useState("");
  const [isSharingPost, setIsSharingPost] = useState(false);
  const [facebookUserAccessToken, setFacebookUserAccessToken] = useState("");
  const [responseData, setResponseData] = useState({
    biography: "",
    followers_count: 0,
    follows_count: 0,
    id: "",
    media:{
      data:[]
    },
    stories:{
      data:[]
    },
    media_count: 0,
    name: "",
    profile_picture_url: "",
    username: "",
    website: "",
  });

  
  const [mediaData,setMediaData]=useState([])
  const [stories,setStories]= useState([])
  const [refresh,setRefresh]=useState(true)
  const [insightsStory,setInsightsStory] = useState([])
  const [insights,setInsights] = useState([])
  const [comments,setComments] = useState([])
  const [flag,setFlag] = useState(false)
  const [email, setEmail] = useState("");
  const [influencer,setInfluencer] = useState([]); 
  const[tables,setTables]= useState([]);

  let tokenT= ''
  /* --------------------------------------------------------
   *                      FACEBOOK LOGIN
   * --------------------------------------------------------
   */

  // Check if the user is authenticated with Facebook
 
  
  const getInfluencer = async() => {
    if(importedUser && influencer.length===0) {
     await  APILI.getProfilesByEmail({"email":importedUser.id}).then(res => {
        setInfluencer(res.data.facebookData);
      })
    }
  }
    getInfluencer()
  

  const saveToken =(token)=>{
    setFacebookUserAccessToken(token)
    getUserData(token)
  }
  const logInToFB = () => {
    window.FB.login(
      (response) => {
        saveToken(response.authResponse?.accessToken);
        
      },
      {
        scope: "instagram_basic,pages_show_list",
      }
    );
  };

  const saveProfile = () => {
    const body ={
      'fbuser':responseData.id,
      'accessToken':facebookUserAccessToken,
      'birthday':'000',
      'data_access_expiration_time':'000',
      'email': importedUser?.id ? importedUser?.id : email,
      'graphDomain':'Facebook',
      'id': responseData.id,
      'influencerid': importedUser?.id ? importedUser?.id : email,
      'link':'',
      'name':responseData.name,
      'picture':responseData?.picture?.data?.url
    }
    APILI.createFacebookProfile(body).then(res=>{
      console.log(res)
      console.log("profile facebook data saved")
    }).catch(err=>{
      console.log(err)
    })

    const body2 ={
        "id": responseData.id,
        "influencerid": importedUser?.id ? importedUser?.id : email,
        "userprofile": responseData.name,
        "image": responseData?.picture?.data?.url,
        "updatedAt": new Date(),
        "status": "Pendiente",
        "createdAt": new Date(),
        "socialmedia": "Facebook",
        "scope": 0,
        "url": "",
        "type": "FanPage"
       }
    APILI.createProfiles(body2).then(res=>{
      console.log(res)
      console.log("profile save")
      Swal.fire({
        title: 'Perfil Guardado',
        text: 'Tu perfíl ha sido guardado, ahora puedes participar de las campañas',
        icon: 'success',
        confirmButtonText: 'Ok'
      })
    }).catch(err=>{
      console.log(err)
    })
  
    console.log(body)
   



  }

  const savePost = () => {
    let dataMedia = mediaData
    let dataInsight = insights
    let dataComment = comments
  
    dataMedia?.forEach(element => {
      let objecto = insights.filter(dataInsight => element.id === dataInsight.id)
      let objecto2 = comments.filter(dataComment => element.id === dataComment.id)
      const caption= element.caption
      let hashtagWord =''
      let startHashTag = false
      let hashtag =[]
      
      for(let x=0; x<=caption.length-1; x++){
        if(caption[x]==='#')
          startHashTag=true
       if(startHashTag===true && caption[x]!==' ')
           hashtagWord= hashtagWord.concat(caption[x]) 
      if(caption[x]===' ' && startHashTag===true ||  x===caption.length-1 && startHashTag===true){
         startHashTag=false
         hashtag.push({"name":hashtagWord.toLowerCase()})
       hashtagWord=''
        }
      } 

  const body={
  "influencerid": importedUser?.id ? importedUser?.id : email,
  "name":responseData.name,
  "createdAt": element.timestamp,
  "message":   element.caption, 
  "id": element.id,
  "urlPost": element.permalink, 
  "socialmedia":"instagram",
  "picture": element.media_url, 
  "activity": element.media_type,
  "reactions": element.comments_count + element.likes_count,
  "comments_count": element.comments_count,
  "likes_count": element.like_count,
  "status":"Pendiente",
  "hashtag": hashtag,
  "campaign": hashtag,
  "insights": {
    "engagement":objecto[0].response.data[0].values[0].value,
    "impressions":objecto[0].response.data[1].values[0].value,
    "reach":objecto[0].response.data[2].values[0].value,
    "saved":objecto[0].response.data[3].values[0].value},  
  "comments": objecto2[0].response.data
    }
    
    //console.log(body)
 APILI.createPosts(body).then(res => {
    console.log(res) }).catch(err => {
    console.log(err)
  })
})
Swal.fire({
  title: 'Publicaciones Guardadas',
  text: 'Tus publicaciones han sido guardadas',
  icon: 'success',
  confirmButtonText: 'Ok'
})
  }

  //console.log(getInstagramData())

  const logOutOfFB = () => {
    window.FB.logout(() => {
      setFacebookUserAccessToken(undefined);
    });
  };

  /* --------------------------------------------------------
   *             INSTAGRAM AND FACEBOOK GRAPH APIs
   * --------------------------------------------------------
   */

  const getFacebookPages = () => {
    return new Promise((resolve) => {
      window.FB.api(
        "/me",
        "GET",
        {

          fields: "id,name,email,posts,friends,picture,accounts{access_token,emails,engagement,followers_count,fan_count,likes,id,link,name,phone,username}",
        },
        { access_token: facebookUserAccessToken },
        (response) => {
          console.log(response)
          resolve(response.data);
          
        }
      );
    });
  };

   
  const getResponseData =async (data /*,arrayPosts */)=>{
    //setResponseData(data)
  
  setResponseData(data)
  /*await getPosts(arrayPosts).then(dataPost => {
     updatePostData(dataPost)
  }
  )*/
  }
  
  const updatePostData = (dataPost)=>{
    setMediaData(dataPost[0])
    setInsights(dataPost[1])
    setComments(dataPost[2])
  }
  const  getUserData =  (token) => {
    getFacebookPages()
    console.log("token",token)
    let arrayPosts =[]
    let arrayStories =[]
    if(token){
      window.FB.api(
        '/me',
        'GET',
        {"fields":"id,name,email,posts,friends,picture.type(large),accounts{access_token,emails,engagement,followers_count,fan_count,likes,id,link,name,phone,username,category}"},
        function(response) {
        
         //getPosts(response.data[0].instagram_business_account?.media?.data)
         //getStories(response.data[0].instagram_business_account?.stories?.data)
         //arrayPosts=getPosts(response.data[0].instagram_business_account?.media?.data)
        // console.log(getPosts(response.data[0].instagram_business_account?.media?.data))
      
        if(response.error){
          console.log(response,"ocurrio un error")
        } else {
          console.log(response)
        getResponseData(response)
        //arrayStories=response?.data[0]?.instagram_business_account?.stories?.data
        
        } 
        
       }
      );
    
      }
    window.FB.api(
      '/me',
      'GET',
      {"fields":"email",token:token},
      function(response) {
        setEmail(response.email)
      }
    );
    
  }
 
  const saveStories = (data,data2) => {
    if(data){
      setStories(data)
    }
    if(data2){
      setInsightsStory(data2)
    }
    
    
  }

  const getStories = (stories) =>{
    let array = []
    let array2 = []
    if(stories!==undefined){
      stories.forEach((element) =>{
        window.FB.api(
          '/'+element.id+'',
          'GET',
          {"fields":"accounts{access_token,emails,engagement,followers_count,fan_count,likes,id,link,name,phone,username}"},
          function(response) {
            array.push(response)
              //console.log(response)
             
          }
        )
        window.FB.api(
          '/'+element.id+'/insights',
          'GET',
          {"metric":"exits,impressions,reach,replies,taps_forward,taps_back"},
          function(response) {
            response.error?
            array2.push({
              id:element.id,
              error: true,
              message:"El numero de visualizaciones es muy bajo para tomar una metrica"})
              :
              array2.push({
                id:element.id,
                error:false,
                response:response})
          }
        );
      }
      )
      saveStories(array,array2)
    }
   
  }
  
  const getPosts =  (media) => {
   return new Promise((resolve) => {

    let array = []
    let array2 = []
    let array3 = []
    media.forEach((element) => {
       window.FB.api(
        '/'+element.id+'',
        'GET',
        {"fields":"id,media_type,media_url,username,timestamp,caption,comments_count, like_count,is_comment_enabled,media_product_type,permalink"},
        function(response) {
          array.push(response)
            //console.log(response)
           
        }
      );
      
      window.FB.api(
        '/'+element.id+'/insights',
        'GET',
        {"metric":"engagement,impressions,reach,saved"},
        function(response) {
          array2.push({
            id:element.id,
            response:response})
            //console.log(response)
           
        }
      );

      window.FB.api(
        '/'+element.id+'/comments',
        'GET',
        {},
        function(response) {
          array3.push({
            id:element.id,
            response:response})
            //console.log(response)
           
        }
      );
  });
  //saveMedia(array,array2,array3)
  //console.log([array,array2,array3]);
  resolve([array,array2,array3])
})
  }
const saveMedia = ()=> {
  //setMediaData(data1)
  //setInsights(data2)
  //setComments(data3)
  savePost()
  setRefresh(true)
}



  const getInstagramAccountId = (facebookPageId) => {
    return new Promise((resolve) => {
      window.FB.api(
        facebookPageId,
        {
          access_token: facebookUserAccessToken,
          fields: "name,id,instagram_business_account{id,biography,followers_count,follows_count,media_count,name,profile_picture_url,username,media,stories,live_media,website,tags}",
        },
        (response) => {
          resolve(response.instagram_business_account.id);
          //console.log(response.instagram_business_account)
        }
      );
    });
  };

  const createMediaObjectContainer = (instagramAccountId) => {
    return new Promise((resolve) => {
      window.FB.api(
        `${instagramAccountId}/media`,
        "POST",
        {
          access_token: facebookUserAccessToken,
          image_url: imageUrl,
          caption: postCaption,
        },
        (response) => {
          resolve(response.id);
        }
      );
    });
  };

  const publishMediaObjectContainer = (
    instagramAccountId,
    mediaObjectContainerId
  ) => {
    return new Promise((resolve) => {
      window.FB.api(
        `${instagramAccountId}/media_publish`,
        "POST",
        {
          access_token: facebookUserAccessToken,
          creation_id: mediaObjectContainerId,
        },
        (response) => {
          resolve(response.id);
        }
      );
    });
  };

  const shareInstagramPost = async () => {
    setIsSharingPost(true);
    const facebookPages = await getFacebookPages();
    const instagramAccountId = await getInstagramAccountId(facebookPages[0].id);
    const mediaObjectContainerId = await createMediaObjectContainer(
      instagramAccountId
    );

    await publishMediaObjectContainer(
      instagramAccountId,
      mediaObjectContainerId
    );

    setIsSharingPost(false);

    // Reset the form state
    setImageUrl("");
    setPostCaption("");
  };
  const theme = useTheme();
  useEffect(() => {
    window.FB.getLoginStatus((response) => {
      //console.log("toooooooooooooooooookeeeeeeeeeen",response.authResponse?.accessToken)
      saveToken(response.authResponse?.accessToken);
    });
  }, []);
  
  /**********************table properties**************************** */
  const DetailCol = ({ text, data}) => (
    <div className="firstColl w-100">
     {/*  <div className="icon-container">
            <i onClick={() =>WatchPost(data)}   className="fas fa-eye"></i>
            <i onClick={() => showModerationPopup(data)} className="fas fa-edit"></i>
            <i class="fas fa-trash"></i>
            <i class="fab fa-telegram-plane"></i>
            <i class="fas fa-ellipsis-v" ></i>
  </div> */}
        <p className="name-fc">
            {text}
        </p>
    </div>
)


const DeliveryCol = ({ text, className, onClick }) => (
  <div className={className} >
      <div className="delivery-text">
          {text}
      </div>
  </div>
)

const customStyles = {
  headCells: {
      style: {
          '&:nth-last-of-type(2)': {
              backgroundColor: '#E3E4E6',
              width: '40px'
          },
          '&:nth-last-of-type(4)': {
              backgroundColor: '#E3E4E6',
              width: '40px'
          }
      },
  },
}
  /******************************************************** */

  /******************************************************** */
  function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }

const getDataView = (data) => {
  
  console.log("mediadataaaa:",data)
  setTables({
      titles: [
          {
              name: 'Campaña',
              selector: 'message',
              sortable: true,
              minWidth: '200px',
              style: {
                  color: '#02025b',
              },
              cell: (row) => (
                  <DetailCol
                      text={row.message}
                      data={row}
                  />
              ),
          },
          /*{
              name: 'Estado',
              selector: 'delivery',
              sortable: true,
              maxWidth: '200px',
              center: true,
              style: {
                  
                  fontWeight: 'bold',
              },
              type: 'text',
              cell: (row) => (
                  <DeliveryCol 
                      text={row.delivery.name}
                      className={row.delivery.className}
                  />
              )
          },*/

          {
              name: 'Comments',
              selector: 'comments_count',
              maxWidth: '100px',
              sortable: true,
              center: true,
              style: {
                  fontWeight: 'bold',
                  maxWidth: '100px',
              },
              
              
          },
          {
              name: 'Likes',
              selector: 'likes_count',
              maxWidth: '100px',
              sortable: true,
              center: true,
              style: {
                  fontWeight: 'bold',
                  maxWidth: '100px',
              },
              
              
          },
          {
              name: 'Tipo de Publicacion',
              selector: 'activity',
              maxWidth: '150px',
              sortable: true,
              center: true,
              style: {
                  backgroundColor: '#E3E4E6',
                  fontWeight: 'bold',
                  maxWidth: '150px',
              },
          },
          {
              name: 'POST',
              selector: 'source',
              sortable: true,
              center: true,
              maxWidth: '120px',
              style: {
                  fontWeight: 'bold',
              },
              cell: (row) => <a href={row.urlPost} target="_blank" ><img style={{ maxWidth:"100px", maxHeight:"100px"}} className="w-70" src={row.source} alt="" /></a>
          },
          {
             name: 'Red Social',
             selector: 'socialMedia',
             maxWidth: '80px',
             sortable: true,
             center: true,
             style: {
              backgroundColor: '#E3E4E6',
                fontWeight: 'bold',
              },
             cell: (row) => <a href={row.urlPost} target="_blank" ><img style={{ borderRadius:"50%"}}className="source-img" src={row.socialMedia} alt="" /></a>
          },
          {
              name: 'Rating',
              selector: 'rating',
              sortable: true,
              center: true,
              minWidth: '200px',
              style: {
                  fontWeight: 'bold',
                  
              
              },
              cell: (row) => <StarsShow rating={row.rating}  />
          },
      ],
      data: data.map(post =>{
        let status ='Active'
        APILI.getPostById({"id":post.id}).then(res => {
          console.log(res.data.influencerPosts)
          if(res.data.influencerPosts.length===1){
            status=res.data.influencerPosts[0].status
            console.log(status)
          }
          else{
            status="New"
          }
        }).catch(error => {
          console.log(error)
        })
        return ({
        name: post.caption,//post.message ? post.message : post.activity,
        delivery: {
          name: status,
          className: getClassNameByStatus(status)
      },
      campaignName: 'post.campaign,',
      hashtags: 'post.campaign,',
      activity: post.media_type,
      source: post.media_url,
      socialMedia: getLogoByName('instagram'),
      rating: post?.rating ? post.rating : getRandomArbitrary(0.5,5),
      id: post.id,
      userID: post.userID,
      urlPost: post.urlPost,
      message: post.caption ? post.caption : "No message",
     comments_count: post?.comments_count ? post.comments_count : 0,
     likes_count: post?.like_count ? post.like_count : 0,
     engagement: post?.insights?.engagement ? post.insights.engagement : 0,
     impressions: post?.insights?.impressions ? post.insights.impressions : 0,
     saved:  post?.insights?.saved ? post.insights.saved : 0, 
     reach: post?.insights?.reach ? post.insights.reach : 0
     // scope: {post.scope

      })})
  }
  )
}

/******************************************************** */
  return (
    <>
<div className="container">
<div className="row">
 

<div className="col-md-3" />
<div className="col-md-6">

        
        </div>
        <div className="col-md-3" />
      </div>
      <div className="row">
      {importedUser ? 
<div>
  <p>Asocia una cuenta para participar en campañas, usuario: {importedUser?.id} {" - "} {importedUser?.type}, Perfiles asociados: {influencer.length}.</p>
</div> 
: null}
        <div className="col-md-3" />
 
        <div className="col-md-6">
          <div className="row"> 
          <main id="app-main">
        <section className="app-section">
          {!facebookUserAccessToken ?
            <>
             <h3>Inicia sesion con Facebook</h3>
          <h6>Recuerda que tu cuenta de instagram y Facebook deben ser business y estar previamente asociadas.</h6>
         
            </>
            :null}
          {facebookUserAccessToken ? (
            <button onClick={logOutOfFB} className="btn action-btn">
              Log out of Facebook
            </button>
          ) : (
            <button onClick={logInToFB} className="btn action-btn">
            {importedUser? `Login With ${importedUser.type}` : "Login with Facebook" }  
            </button>
          )}
        </section>
      </main>
        </div>
        {facebookUserAccessToken ? 
        <div className="row ">
         <Card sx={{ display: 'flex', width:'100%' }}>
        <CardMedia className="ml-3" 
        component="img"
        sx={{ width: 151 }}
        image={responseData?.picture?.data?.url}
        alt="Profile picture"
      />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h5">
            {responseData.name}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            {responseData.username}
          </Typography>
          <Typography variant="overline" color="text.secondary" component="div">
            {responseData.biography}
          </Typography>
          <Typography variant="caption" color="text.secondary" component="div">
            <a href={responseData.website}>{responseData.website}</a>
          </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
        <Typography variant="subtitle1" color="text.secondary" component="div">
            {responseData?.friends?.data?.length}
          </Typography>
          <IconButton aria-label="play/pause">
            <FaceIcon sx={{ height: 38, width: 38 }} />
          </IconButton>
          

          
            
          <Typography variant="subtitle1" color="text.secondary" component="div">
            {responseData.follows_count}
          </Typography>
        </Box>
       
        
      </Box>
      
      
    </Card>
    <Typography variant="title" color="primary" >
            Paginas
          </Typography>
   
    <Box sx={{ display: 'flex', margin:'20px',  alignItems: 'center', pl: 1, pb: 1 , width:'300px' }}>
       
         
          {responseData?.accounts?.data?.map(dat => {
            return (
            
              <Paper elevation={4} className="mt-3" style={{marginLeft:'10px', padding: '10px', borderRadius:'10px'}}>
             
             <a href={dat.link} target="_blank"> <Typography variant="subtitle1" color="text.secondary" component="div">
            {dat.name}
          </Typography>
          </a>
          <Typography variant="caption" color="text.secondary" component="div">
            {dat.category}
          </Typography>
          <Typography variant="caption" color="text.secondary" component="div">
            Followers {dat.followers_count}
          </Typography>
          <Typography variant="caption" color="text.secondary" component="div">
            Fans {dat.fan_count}
          </Typography>
          <Typography variant="caption" color="text.secondary" component="div">
            Engagement {dat.engagement.count}
          </Typography>
          
         </Paper>
            ) }
          )}
           
          </Box>
      
        </div>
        : null}
</div>
       
      <div className="col-md-3" />
      
      </div>
      <div className="row mb-10">
    
      </div>
      
      <div className="row mt-10">
        {facebookUserAccessToken ? 
        <>
      <button type="button" onClick={()=> saveProfile()}>Guardar Perfil</button> 
      <button type="button" onClick={()=> {setFlag(!flag); getDataView(mediaData)} }>Cargar Publicaciones</button> 
      </>
      : null}
      {flag && facebookUserAccessToken? 
      <>
       <button type="button" onClick={()=> savePost()}>Guardar Publicaciones</button> 
       <div className="col-md-12">
   {/*    <h2>POSTS</h2>
        <TitlebarImageList mediaData={mediaData}/>
       </div>
       <div className="col-md-12">
        <h2>STORIES</h2>
       <TitlebarStoryList stories={stories}/>
   */}
           <ReactDataTable
            columns={tables.titles}
            customStyles={customStyles}
            data={tables.data}
            persistTableHead
            noHeader
          />
      </div>
      </>
     : null}
      </div>
      
</div>
      
    </>
  )
}

const mapStateToProps = (state) => ({
  info: state.fanpageDatosWeb.info,
})

const mapDispatchToProps = {
  setProp,
  getDatos,
}

export default connect(mapStateToProps, mapDispatchToProps)(withUtils(withRouter(Facebook)))
