import React from 'react'
// components
import Debug from 'components/debug'

const DebugPage = () => (
  <>
    <Debug />
  </>
)

export default DebugPage