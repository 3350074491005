import * as API from 'helpers/api'
import * as Logger from 'utils/logger'
import * as Modal from './modal.actions'
import { showLoader } from './loader.actions'

export const CATEGORIES_CLEAR = 'CATEGORIES:CLEAR'
export const CATEGORIES_REMOVE = 'CATEGORIES:REMOVE'
export const CATEGORIES_ADD_ONE = 'CATEGORIES:ADD:ONE'
export const CATEGORIES_SET_EDIT = 'CATEGORIES:SET:EDIT'
export const CATEGORIES_CLEAR_EDIT = 'CATEGORIES:CLEAR:EDIT'
export const CATEGORIES_SET_FILTER_TEXT = 'CATEGORIES:SET:FILTER:TEXT'

const TAG = 'Categories:Actions'

export const getCategories = (account) => async (dispatch) => {
  dispatch(showLoader(true))
  dispatch(clearCategories())

  try {
    const response = await API.getCategories(account)

    Logger.log(TAG, 'getCategories', response)
    const { categories } = response.data

    categories.forEach((category) => {
      dispatch(addCategory(category))
    })
  } catch (err) {
    Logger.log(TAG, 'error getCategories', err)
  }

  dispatch(showLoader(false))
}

export const setFilterText = text => ({
  type: CATEGORIES_SET_FILTER_TEXT,
  text
})

export const clearCategories = () => ({
  type: CATEGORIES_CLEAR
})

export const createCategory_logout = (id, name, account, edit, createAt) => dispatch => {
  //dispatch(showLoader(true))
  let now = Date.now()

  let body = {
    id,
    name,
    account,
    createAt: edit ? createAt : now,
    updateAt: now,
  }

  return API.createCategory(body)
}

export const createCategory = (id, name, account, edit) => async (dispatch) => {
  dispatch(showLoader(true))
  
  const body = {
    id,
    name,
    account,
  }

  try {
    const response = edit
      ? await API.updateCategory(body)
      : await API.createCategory(body)

    Logger.log(TAG, 'createCategory', response)
    const title = edit
      ? 'Categoría Editada'
      : 'Categoría Creada'
    const message = edit
      ? 'La categoría fue editada exitosamente.'
      : 'La categoría fue creada exitosamente.'

    dispatch(Modal.showAlertModal(title, message, Modal.MODAL_TYPE_SUCCESS, () => {
      dispatch(getCategories(account))
    }))
  } catch (err) {
    Logger.log(TAG, 'error createCategory', err)
    const message = edit ? 'Ocurrió un error al editar la categoría' : 'Ocurrió un error al crear la categoría'
    dispatch(Modal.showAlertModal('Error!', message, Modal.MODAL_TYPE_ERROR))
  }

  dispatch(showLoader(false))
}

export const upsertCategory = (id, name, account, edit) => async (dispatch) => {
  const body = {
    id,
    name,
    account,
  }

  try {
    const response = edit
      ? await API.updateCategory(body)
      : await API.createCategory(body)

    Logger.log(TAG, 'createCategory', response)
    dispatch(getCategories(account))
  } catch (err) {
    Logger.log(TAG, 'error createCategory', err)
  }
}

export const deleteCategory = (id) => async (dispatch) => {
  dispatch(showLoader(true))

  try {
    const response = await API.deleteCategory(id)

    Logger.log(TAG, 'deleteCategory', response)
    dispatch(Modal.showAlertModal('Categoría Eliminada', 'La categoría fue eliminada exitosamente.', Modal.MODAL_TYPE_SUCCESS, () => {
      dispatch(removeCategory(id))
    }))
  } catch (err) {
    Logger.log(TAG, 'error deleteCategory', err)
    dispatch(Modal.showAlertModal('Error!', 'Ocurrió un error al eliminar la categoría', Modal.MODAL_TYPE_ERROR))
  }

  dispatch(showLoader(false))
}

export const setEdit = edit => ({
  type: CATEGORIES_SET_EDIT,
  edit,
})

export const clearEdit = () => ({
  type: CATEGORIES_CLEAR_EDIT,
})

export const addCategory = category => ({
  type: CATEGORIES_ADD_ONE,
  category
})

const removeCategory = category => ({
  type: CATEGORIES_REMOVE,
  category
})