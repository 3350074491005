import * as API from 'helpers/api'
import * as Logger from 'utils/logger'
import * as Modal from './modal.actions'
import { showLoader } from './loader.actions'

export const AUDIENCES_CLEAR = 'AUDIENCES:CLEAR'
export const AUDIENCES_REMOVE = 'AUDIENCES:REMOVE'
export const AUDIENCES_ADD_ONE = 'AUDIENCES:ADD:ONE'
export const AUDIENCES_SET_EDIT = 'AUDIENCES:SET:EDIT'
export const AUDIENCES_CLEAR_EDIT = 'AUDIENCES:CLEAR:EDIT'
export const AUDIENCES_SET_FILTER_TEXT = 'AUDIENCES:SET:FILTER:TEXT'

const TAG = 'Audiences:Actions'

export const getAudiences = (account) => async (dispatch) => {
  dispatch(showLoader(true))
  dispatch(clearAudiences())

  try {
    const response = await API.getAudiences(account)

    Logger.log(TAG, 'getAudiences', response)
    const { audiences } = response.data

    audiences.forEach((audience) => {
      dispatch(addAudience(audience))
    })
  } catch (err) {
    Logger.log(TAG, 'error getAudiences', err)
  }

  dispatch(showLoader(false))
}

export const setFilterText = text => ({
  type: AUDIENCES_SET_FILTER_TEXT,
  text
})

export const clearAudiences = () => ({
  type: AUDIENCES_CLEAR
})

export const createAudience = (id, name, account, edit, tags) => async (dispatch) => {
  dispatch(showLoader(true))
  
  const body = {
    id,
    name,
    account,
    tags
  }

  try {
    const response = edit
      ? await API.updateAudience(body)
      : await API.createAudience(body)

    Logger.log(TAG, 'createAudience', response)
    const title = edit ? 'Audiencia Editada' : 'Audiencia Creada'
    const message = edit
      ? 'La Audiencia fue editada exitosamente.'
      : 'La audiencia fue creada exitosamente.'

    dispatch(
      Modal.showAlertModal(title, message, Modal.MODAL_TYPE_SUCCESS, () => {
        dispatch(getAudiences(account))
      })
    )
  } catch (err) {
    Logger.log(TAG, 'error createAudience', err)
    const message = edit
      ? 'Ocurrió un error al editar la audiencia'
      : 'Ocurrió un error al crear la audiencia'
    dispatch(Modal.showAlertModal('Error!', message, Modal.MODAL_TYPE_ERROR))
  }

  dispatch(showLoader(false))
}

export const createNewAudience = (id, name, account, tags) => async (dispatch) => {
  dispatch(showLoader(true))
  
  const body = {
    id,
    name,
    account,
    tags,
  }

  try {
    await API.createAudience(body)
  } catch (err) {
    Logger.log(TAG, 'error createAudience', err)
  }

  dispatch(showLoader(false))
}

export const deleteAudience = (id) => async (dispatch) => {
  dispatch(showLoader(true))

  try {
    const response = await API.deleteAudience(id)

    Logger.log(TAG, 'deleteAudience', response)
    dispatch(Modal.showAlertModal('Audiencia Eliminada', 'La audiencia fue eliminada exitosamente.', Modal.MODAL_TYPE_SUCCESS, () => {
      dispatch(removeAudience(id))
    }))
  } catch (err) {
    Logger.log(TAG, 'error deleteAudience', err)
    dispatch(Modal.showAlertModal('Error!', 'Ocurrió un error al eliminar la audiencia', Modal.MODAL_TYPE_ERROR))
  }

  dispatch(showLoader(false))
}

export const setEdit = edit => ({
  type: AUDIENCES_SET_EDIT,
  edit,
})

export const clearEdit = () => ({
  type: AUDIENCES_CLEAR_EDIT,
})

const addAudience = audience => ({
  type: AUDIENCES_ADD_ONE,
  audience
})

const removeAudience = audience => ({
  type: AUDIENCES_REMOVE,
  audience
})