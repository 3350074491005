import React, {useState, useEffect} from 'react';
import { MapContainer, TileLayer, useMapEvents,
   } from 'react-leaflet'
  import { makeStyles } from '@material-ui/core/styles';
import * as APILI from 'helpers/apiLI';

import AddMarkerToClick from './AddMarkerToClick'
import "leaflet/dist/leaflet.css";
import L, { marker } from "leaflet";
import { v4 } from 'uuid'
import "./styles.css";



const useStyles = makeStyles({
  root: {
    height: 300,
  },
});




export default function Map(props) {
  
 
 const {markers} = props
 const [markInternal, setMarkInternal] = useState([])
 useEffect(() => {
  saveMarks(markers)
  }, [markers]) 

  const saveMarks = (mark) => {
    setMarkInternal(mark)
  }

 console.log("lengthh",markInternal?.length)
 console.log("lat",markInternal[0]?.lat)
 console.log("lng",markInternal[0]?.lng)
 
return (

<div className="App" style={{ width: "100%"}}>
 {markInternal.length === 1 ?
  <MapContainer  
  center={[markInternal[0]?.lat,markInternal[0]?.lng]}
  zoom={12}
  style={{ height: "60vh", zIndex: "1" }} > {/* omit onClick */}
  <TileLayer
    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  />
  
    <AddMarkerToClick markers={markers} />
  </MapContainer>
                  : 
                  <MapContainer  
                  center={[14.628434, -90.522713]}
                  zoom={12}
                  style={{ height: "60vh", zIndex: "1" }} > {/* omit onClick */}
                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  
                    <AddMarkerToClick markers={markers} />
                  </MapContainer>}
</div>

);
}