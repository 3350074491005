import React,{useState} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';


// Pages
import Home from 'pages/home'
import Dashboard from 'pages/resume'
import Campaign from 'pages/campaign'
import CampaignInfo from 'pages/campaign/info'
import Moderation from 'pages/moderation'
import ListTask from 'pages/moderation/listTask'
import ProfileList from 'pages/moderation/profileList'
import Finance from 'pages/finance'
import Advertiser from 'pages/advertiser'
import Debug from 'pages/debug'
import Administration from 'pages/administration'
import AdministrationPanel from 'pages/administrationPanel'
import System from 'pages/system'
import Logs from 'pages/logs'
import ErrorLogs from 'pages/errorLogs'
import UserList from 'pages/moderation/userList'
import PostList from 'pages/moderation/postList'
import Scan from 'pages/scan'
import Influencer from 'pages/influencer'




const Content = () => {
const [userRol,setuserRol] = useState(null);

  const getUser = () => { 
    return Auth.currentAuthenticatedUser()
      .then((userData) => {
        // custom:userRol: "admin
       let user_rol = userData?.attributes['custom:userRol'];
       setuserRol(user_rol);
        // console.log("seteando desde content Rutas ",user_rol)
      })
      .catch(() => console.log('Not signed in campaing'))
  }
  getUser();
  
  /* console.log("cambiosssss3333333333", userRol) */
  return (
    <>
      <Switch>
        <Route path='/' component={Home} exact />
        <Route path='/dashboard'  component={()=><Dashboard userRol={userRol}/>}/>
        {/* <Route path='/resume' component={Resume}/> */}
        <Route path='/campaign' component={()=><Campaign userRol={userRol}/>}/>
        {/* <Route path='/campaignInfo' component={CampaignInfo} exact/> */}
        <Route path='/campaignInfo' component={()=><CampaignInfo userRol={userRol}/>}exact/>
        {/* <Route path='/moderation' component={Moderation} exact /> */}
        <Route path='/moderation'  component={()=><Moderation userRol={userRol}/>}exact />
        {/* <Route path='/listTask' component={ListTask} exact/> */}
        <Route path='/listTask'  component={()=><ListTask userRol={userRol}/>}exact />
        {/* <Route path='/profileList' component={ProfileList} exact /> */}
        <Route path='/profileList' component={()=><ProfileList userRol={userRol}/>} exact />
        {/* <Route path='/userList' component={UserList}/> */}
        <Route path='/userList' component={()=><UserList userRol={userRol}/>}/>
        {/* <Route path='/postList' component={PostList}/> */}
        <Route path='/postList' component={()=><PostList userRol={userRol}/>}/>
        {/* <Route path='/myProfile' component={Influencer} exact/> */}
        <Route path='/myProfile' component={()=><Influencer userRol={userRol}/>} exact/>
        <Route path="/myProfile/:userToken">
          <Influencer/>
        </Route>
        {/* <Route path='/finance' component={Finance}/> */}
        <Route path='/finance' component={()=><Finance userRol={userRol}/>}/>
        {/* <Route path='/advertiser' component={Advertiser} exact/> */}
        <Route path='/advertiser' component={()=><Advertiser userRol={userRol}/>}exact/>
        {/* <Route path='/debug' component={Debug}/> */}
        <Route path='/debug' component={()=><Debug userRol={userRol}/>}/>
        <Route path='/administration' component={()=><Administration userRol={userRol}/>}/>
        {/* <Route path='/administration' component={Administration}/> */}
        {/* <Route path='/administrationPanel' component={AdministrationPanel}/> */}
        <Route path='/administrationPanel' component={()=><AdministrationPanel userRol={userRol}/>}/>
        {/* <Route path='/log' component={Logs}/> */}
        <Route path='/logs' component={()=><Logs userRol={userRol}/>}/>
        {/* <Route path='/errorLogs' component={errorLogs}/> */}
        <Route path='/errorLogs' component={()=><ErrorLogs userRol={userRol}/>}/>
        {/* <Route path='/system' component={System}/> */}
        <Route path='/system' component={()=><System userRol={userRol}/>}/>
        {/* <Route path='/scan' component={Scan}/> */}
        <Route path='/scan' component={()=><Scan userRol={userRol}/>}/>
        <Route path='*'>
          <Redirect to='/' />
        </Route>
      </Switch>
    </>
  )
}


export default Content
