import * as React from 'react';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';

const labels = {
  0.5: '0.5',
  1: '1',
  1.5: '1.5',
  2: '2',
  2.5: '2.5',
  3: '3',
  3.5: '3.5',
  4: '4',
  4.5: '4.5',
  5: '5',
};

export default function StarsShow(props) {
  const { rating } = props;
  const [value, setValue] = React.useState(rating);
  const [hover, setHover] = React.useState(-1);

  return (
    <Box
      sx={{
        width: 300,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Rating
        name="read-only"
        value={rating}
        precision={0.5}
        style={{ fontSize: 40 }}
        readOnly
        
        
      />
      {rating !== null && (
        <Box sx={{ ml: 0.1  }}>{labels[hover !== -1 ? hover : rating]}</Box>
      )}
    </Box>
  );
}