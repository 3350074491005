export const SAVE_NAME = 'CAMPAIGN:NAME'
export const SAVE_SOCIAL = 'CAMPAIGN:SOCIAL'
export const SAVE_DATES = 'CAMPAIGN:DATES'
export const SAVE_OBJETIVE = 'CAMPAIGN:OBJETIVE'
export const SAVE_TAGS = 'CAMPAIGN:TAGS'
export const SAVE_STEP = 'CAMPAIGN:STEP'
export const SAVE_BUDGET = 'CAMPAIGN:BUDGET'
export const SAVE_CATEGORY = 'CAMPAIGN:CATEGORY'
export const SAVE_PRODUCT = 'CAMPAIGN:PRODUCT'
export const SAVE_ID_MEDIUM = 'CAMPAIGN:IDMEDIUM'
export const SAVE_MEDIUM = 'CAMPAIGN:MEDIUMDESCRIP'
export const CLEAN_CAMPAIGN = 'CAMPAIGN:CLEAN'
export const CLEAN_CAMPAIGN2 = 'CAMPAIGN:CLEAN2'
export const SAVE_ACCOUNT = 'CAMPAIGN:ACCOUNT'
export const SAVE_PUBLIC_NAME = 'CAMPAIGN:SAVE_PUBLICNAME'

export const saveMedium = (medium) => (dispatch) => {
  dispatch({
    type: SAVE_MEDIUM,
    medium,
  })
}

export const saveIdmedium = (idmedium) => (dispatch) => {
  dispatch({
    type: SAVE_ID_MEDIUM,
    idmedium,
  })
}

export const saveProduct = (product) => (dispatch) => {
  dispatch({
    type: SAVE_PRODUCT,
    product,
  })
}

export const saveName = (name) => (dispatch) => {
  dispatch({
    type: SAVE_NAME,
    name,
  })
}

export const saveSocial = (redSocial) => (dispatch) => {
  dispatch({
    type: SAVE_SOCIAL,
    redSocial,
  })
}

export const saveDate = (iniDate, endDate) => (dispatch) => {
  dispatch({
    type: SAVE_DATES,
    iniDate,
    endDate,
  })
}

export const saveObjet = (objetivo) => (dispatch) => {
  dispatch({
    type: SAVE_OBJETIVE,
    objetivo,
  })
}

export const saveTag = (tags) => (dispatch) => {
  dispatch({
    type: SAVE_TAGS,
    tags,
  })
}

export const saveStep = (step) => (dispatch) => {
  dispatch({
    type: SAVE_STEP,
    step,
  })
}

export const saveBudget = (budget) => (dispatch) => {
  dispatch({
    type: SAVE_BUDGET,
    budget,
  })
}

export const saveCategory = (category) => (dispatch) => {
  dispatch({
    type: SAVE_CATEGORY,
    category: category,
  })
}

export const cleanCampaign = () => (dispatch) => {
  dispatch({
    type: CLEAN_CAMPAIGN,
  })
}

export const cleanCampaign2 = () => (dispatch) => {
  dispatch({
    type: CLEAN_CAMPAIGN2,
  })
}

export const saveAccount = (account) => (dispatch) => {
  dispatch({
    type: SAVE_ACCOUNT,
    account,
  })
}

export const savePublicName = (publicName) => (dispatch) => {
  dispatch({
    type: SAVE_PUBLIC_NAME,
    public: publicName,
  })
}