import { DateRange } from '@easygosanet/react-datatable'
import * as STORAGE from 'helpers/storageLI';

//Logos
import Logo_facebook from 'img/logo_facebook.svg'
import Logo_instagram from 'img/logo_instagram.png'
import Logo_tiktok from 'img/logo_tiktok.svg'
import logo_linkedin from 'img/logo_linkedin.svg'

export function getLogoByName(name) {
    switch (name) {
        case "Facebook":
            return Logo_facebook
        case "Instagram":
            return Logo_instagram
        case "Tiktok":
            return Logo_tiktok
        case "Linkedin":
            return logo_linkedin
        case "facebook":
                return Logo_facebook
            case "instagram":
                return Logo_instagram
            case "tiktok":
                return Logo_tiktok
            case "linkedin":
                return logo_linkedin
        default:
            break;
    }
}

export function getLogosListByNames(names) {
    let listLogos = []
    names.forEach(name => {
      //  console.log("social name::::::::::::::::::", name.name)
        listLogos.push(getLogoByName(name.name));
    });
    //console.log("social logo list::::::::::::::::::", listLogos)
    return listLogos
}

export function getClassStyleByStatus(status) {
    switch (status) {
        case "Pendiente":
            return {
                name: "Pendiente",
                className: "delivery-tail"
            }
            case "Active":
                return {
                    name: "Activa",
                    className: "delivery-running"
                }
        case "Finalizada":
            return {
                name: "Finalizada",
                className: "delivery-tail"
            }
        case "active":
            return {
                name: "Activa",
                className: "delivery-running"
            }
        case "En Revision":
            return {
                name: "En revisión",
                className: "delivery-stop"
            }
        case "rejected":
        return {
            name: "Rechazado",
            className: "delivery-stop"
        }
        case "Rejected":
            return {
                name: "Rechazado",
                className: "delivery-stop"
            }
        case "Completado":
                return {
                    name: "Completado",
                    className: "delivery-complete"
                }
        case "Finalizado":
        return {
            name: "Finalizado",
            className: "delivery-tail"
        }
        case "Cerrada":
                return {
                    name: "Cerrada",
                    className: "delivery-complete"
                }
        default:
            return {
                name: "Desconocido",
                className: "delivery-tail"
            }
    }
}

export function getClassNameByStatus(status) {
    switch (status) {
        case "Pendiente":
            return "delivery-tail"
        case "Active":
            return "delivery-running"
        case "earring":
            return "delivery-tail"
        case "active":
            return "delivery-running"
        case "Approved":
            return "delivery-running"
        case "rejected":
            return "delivery-stop"
        case "Rechazado":
            return "delivery-stop"
        case "Rejected":
             return "delivery-stop"
        case "Banned":
             return "delivery-stop"
        case "banned":
             return "delivery-stop"
        case "completed":
             return "delivery-complete"
        case "Completed":
             return "delivery-complete"
        case "payment":
             return "delivery-payment"
        case "Payment":
             return "delivery-payment"
        case "billing":
             return "delivery-complete"
        case "Billing":
             return "delivery-complete"
        case "Credited":
             return "delivery-credited"
        case "credited":
             return "delivery-credited"
        default:
            break;
    }
}
export async function getImage(name) {
    const url = await STORAGE.listFiles(name)
    return url
}