import React from 'react';
import { Pie } from 'react-chartjs-2';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import './asserts/css/styles.css'
import ChartDataLabels from 'chartjs-plugin-datalabels';

const PieChart = (props) => {
    const userRol = props.userRol
    /* console.log(props) */
    const listGender = props.dataUsersAge.gender ? props.dataUsersAge.gender  : [];
    // const listGender = props.dataUsersAge.gender ? props.dataUsersAge.gender  : [];

    const listRange = props.dataUsersAge.range ? props.dataUsersAge.range  : [];

    const dataGraphUsers = {
        labels: listRange.map(range=>{
            return range.ageRange
        }),
        datasets: [
            {
                label: 'Rainfall',
                backgroundColor: [
                    '#FF3D57',
                    '#FDAB3D',
                    '#00CA72',
                    '#009AFF',
                    '#794FCF'
                ],
                hoverBackgroundColor: [
                    '#501800',
                    '#4B5000',
                    '#175000',
                    '#003350',
                    '#35014F'
                ],
                data: props.dataUsersAge.porcentage
            }
        ]
    }

    const listRangeItems = listRange.map((range) =>
        <div className="dataBox">
           <FiberManualRecordIcon className={`${range.color}`} />

           
            <p><i classMame="fas fa-circle"></i>{range.ageRange}</p>
        </div>
    );

    const listItems = listGender.map((genders) =>
    
        <div className="dataBox">
            <FiberManualRecordIcon className={` ${genders.color}`} />
            <span>Mujeres: &#160; {genders.women}</span><br/>
            <span>Hombres: &#160;{genders.mens}</span>
        </div>
    );

    return (
        <>
            <div className="chartData">
                <div className="pieChart">
                    <Pie
                        
                        data={dataGraphUsers}
                        width={props.width}
                        height={props.height}
                        options={{
                            responsive: true,
                            pieceLabel: {
                                render: function(args){
                                    return args.value+"%";
                                },
                                fontSize: 15,
                                fontColor: "#fff"
                            },
                            plugins: {
                                title: {
                                    display: false
                                },
                                legend: {
                                    display: false
                                },
                                datalabels: {
                                    display: true,
                                    anchor: "",
                                    /* Podemos modificar el texto a mostrar */
                                    formatter: (dato) => dato + "%",
                                    /* Color del texto */
                                    color: "black",
                                    /* Formato de la fuente */
                                    font: {
                                      size: "14",
                                      weight: "normal",
                                    },
                                },
                                
                            }
                        }}
                    />
                </div>
                <div className="dataLegend">
                    {listRangeItems}
                </div>
            </div>
            {userRol === "admin" && 
            <div className="dataLabels">
                {listItems}
            </div>}
        </>
    )
}

export default PieChart