import { v4 } from 'uuid'

import * as Logger from 'utils/logger'
import * as APILA from 'helpers/apiLA'
import * as Modal from './modal.actions'
import { showLoader } from './loader.actions'

export const UPD_OBJ = 'OBJCAMPAINS:UPD'
export const LIST_OBJ = 'OBJCAMPAIN:ADD'
export const SAVE_NAME = 'CAMPAIGN:NAME'
export const OBJ_CLEAR = 'OBJCAMPAIGNS:CLEAR'
export const CAMPAIGNS_CLEAR = 'CAMPAIGNS:CLEAR'
export const CAMPAIGNS_ADD_ONE = 'CAMPAIGNS:ADD:ONE'

const TAG = 'ObjectCampain:Actions'

export const saveId = (id,name) => dispatch => {
  let pauta ={ide:id,namee:name}
  console.log("####################################")
  console.log(id)
  console.log("####################################")
  dispatch({
    type: UPD_OBJ,
    pauta
  })
}

export const clearObjectCampain = () => dispatch => {
  dispatch({
    type: OBJ_CLEAR,
  })
}

export const getObjectCampain = () => dispatch => {

  dispatch(showLoader(true))
  APILA.getObjectCampain()
    .then(response => {
      Logger.log(TAG, 'getObjectCampainget este es log', response)
      let { objectcampainget } = response.data

      response.data.campaigns.forEach(campaign => {
          dispatch(addCampaign(campaign))
      })
    })
    .catch(err => {
      Logger.log(TAG, 'error getObjectcampain', err)
    })
    .then(() => {
      dispatch(showLoader(false))
    })
}

export const updatePauta = (id,name,status) => dispatch => {
  dispatch(showLoader(true))

  let date = new Date()

  let day = date.getDate()
  let month = date.getMonth() + 1
  let year = date.getFullYear()

  let fech = ""

  if(month < 10){
    fech =`${day}-0${month}-${year}`
  }else{
    fech =`${day}-${month}-${year}`
  }

  let body = {
    id:id,
    name:name,
    datecreate:fech,
    status:status
  }

  APILA.updateObjectCampain(body)
    .then(response => {
      Logger.log(TAG, 'createObjectCampain', response)
      dispatch(Modal.showAlertModal('Solicitud Enviada', 'El objetivo fue enviado exitosamente.', Modal.MODAL_TYPE_SUCCESS, () => {
        dispatch({
          type: CAMPAIGNS_CLEAR
        })
        dispatch(showLoader(true))
        APILA.getObjectCampain()
          .then(response => {
            Logger.log(TAG, 'getObjectCampainget este es log', response)
            let { objectcampainget } = response.data
      
            response.data.campaigns.forEach(campaign => {
                dispatch(addCampaign(campaign))
            })
          })
          .catch(err => {
            Logger.log(TAG, 'error getObjectcampain', err)
          })
          .then(() => {
            dispatch(showLoader(false))
          })
      }))
    })
    .catch(err => {
      Logger.log(TAG, 'error createObjectCampain', err)
      dispatch(Modal.showAlertModal('Error!', 'Ocurrió un error al crear el objetivo', Modal.MODAL_TYPE_ERROR))
    })
    .then(() => {
      dispatch(showLoader(false))
    })
}

export const saveName = name => dispatch => {
  dispatch(showLoader(true))

  let date = new Date()

  let day = date.getDate()
  let month = date.getMonth() + 1
  let year = date.getFullYear()

  let fech = ""

  if(month < 10){
    fech =`${day}-0${month}-${year}`
  }else{
    fech =`${day}-${month}-${year}`
  }

  let body = {
    id:v4(),
    //id:"9abf53b7-7871-4fc1-83db-894b45876dde",
    name:name,
    datecreate:fech,
    status:1
  }

  APILA.createObjectCampain(body)
    .then(response => {
      Logger.log(TAG, 'createObjectCampain', response)
      dispatch(Modal.showAlertModal('Solicitud Enviada', 'El objetivo fue enviado exitosamente.', Modal.MODAL_TYPE_SUCCESS, () => {
          dispatch(addCampaign(body))
          Logger.log(TAG, 'createObjectCampain guardo exitosamente ******************', response)
      }))
    })
    .catch(err => {
      Logger.log(TAG, 'error createObjectCampain', err)
      dispatch(Modal.showAlertModal('Error!', 'Ocurrió un error al crear el objetivo', Modal.MODAL_TYPE_ERROR))
    })
    .then(() => {
      dispatch(showLoader(false))
    })
}

const addCampaign = objcampain => {
  return dispatch => {
    dispatch({
      type: LIST_OBJ,
      objcampain
    })
  }
}
