import React, { useState, useEffect } from 'react'
import * as utils from 'data.js'
import * as APILI from 'helpers/apiLI';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { showLoader } from 'redux/actions/loader.actions';
import {v4} from 'uuid'
import { connect } from 'react-redux';

import { Auth } from 'aws-amplify';
import _ from 'lodash'
import { getLogoByName, getClassNameByStatus } from 'myUtil'
import DataTableUtils, {
    Share,
    ShowColumn,
    FilterColumn
} from '@easygosanet/react-datatable-utils'
import ReactDataTable from '@easygosanet/react-datatable'
import AddTask from 'components/addTask'

//images
import Logo_facebook from './assets/img/logo_facebook.svg'
import Logo_instagram from './assets/img/logo_instagram.png'
import Logo_tiktok from './assets/img/logo_tiktok.svg'
import logo_linkedin from './assets/img/logo_linkedin.svg'
import Task from 'components/task';
import * as Modal from 'redux/actions/modal.actions'

import { CSVLink, CSVDownload } from "react-csv";
import { TokenFileWebIdentityCredentials } from 'aws-sdk';
import orderBy from'lodash/orderBy'
const TaskList = (props) => {
    const currentUser = JSON.parse(localStorage.getItem('CurrentUser')) ?? [];
    const[tables,setTables]= useState([]);
    const[data,setData]= useState([]);
    const [showModal, setshowModal] = useState(false);
    let [taskTable, setTaskTable] = useState([])
    const [filters, setFilters] = useState({})
    const [position, setPosition]= useState(0)
    const [showTask, setShowTask] = useState(false)
    const [refreshTable, setrefreshTable] = useState(false)
    const [taskPage, setTaskPage] = useState({
        "activityName": "",
  "campaignName":"",
  "id": "",
  "name":"",
  "price":0.00,
  "scope":0,
  "temporalSubTask":[],
  "socialmedia":"",
  "userID":"",
  "image":"",
  "campaignID":"",
  "enable":"",
  "subTasks": []
      
    })
    //VARIABLES INSTAGRAM
    const [imageUrl, setImageUrl] = useState("");
    const [postCaption, setPostCaption] = useState("");
    const [isSharingPost, setIsSharingPost] = useState(false);
    const [facebookUserAccessToken, setFacebookUserAccessToken] = useState("");
    const [responseData, setResponseData] = useState({
     
        biography: "",
      followers_count: 0,
      follows_count: 0,
      id: "",
      media:{
        data:[]
      },
      stories:{
        data:[]
      },
      media_count: 0,
      name: "",
      profile_picture_url: "",
      username: "",
      website: "",
    });
  
    
    const [mediaData,setMediaData]=useState([])
    const [stories,setStories]= useState([])
    const [refresh,setRefresh]=useState(false)
    const [insightsStory,setInsightsStory] = useState([])
    const [insights,setInsights] = useState([])
    const [comments,setComments] = useState([])
    const [flag,setFlag] = useState(false)
    const [email, setEmail] = useState("");
    const [influencer,setInfluencer] = useState([]); 
    const [arrPost, setArrPost] = useState([])

    const [filteredData,setFilteredData]= useState([])
    const [filteredDataNormal,setFilteredDataNormal] = useState([])
    //FIN VARIABLES INSTAGRAM
    /* console.log(filters); */
    
    const getPosts =  (media,token,influencerID,dataEye, position) => {
        console.log('TokenPelt', token)
        let positionINsta = 0
         let array = []
         let array2 = []
         let array3 = []
         let body = {}
         try {
            media.forEach((element) => {
                console.log('id del elemento', element.id)
                fetch(
                    `https://graph.facebook.com/v12.0/${element.id}?fields=id%2Cmedia_type%2Cmedia_url%2Cusername%2Ctimestamp%2Ccaption%2Ccomments_count%2C%20like_count%2Cis_comment_enabled%2Cmedia_product_type%2Cpermalink&access_token=${token}`)
                    .then((res) => res.json())
                    .then((response) => {

                        /* const bodyLog = {
                            Tipo: "Read",
                            Accion: "Lectura de webhook",
                            Data: body,
                            Historico: {},
                            CodeHttp: res.status,
                            UsuarioId: currentUser.username,
                            UsuarioName: currentUser.attributes.name,
                            Email: currentUser.attributes.email,
                        }
              
                        saveLog(bodyLog) */

                        /* console.log('RESMEDIA',response) */
                        array.push(response)
                        let responseMedia= response
                        console.log('RESPONSEE',response)
                        try {
                            fetch(
                                `https://graph.facebook.com/v12.0/${element.id}/insights?metric=engagement%2Cimpressions%2Creach%2Csaved&access_token=${token}`)
                                .then((res) => res.json())
                                .then((responseIn) => {
                                    if(responseIn.error)
                                    {
                                        const bodyLog = {
                                            Tipo: "Lectura de webhook erronea",
                                            Accion: "Leer webhook",
                                            Data: {},
                                            Historico: {},
                                            CodeHttp: responseIn.error.code,
                                            UsuarioId: currentUser.username,
                                            UsuarioName: currentUser.attributes.name,
                                            Email: currentUser.attributes.email,
                                        }
                                  
                                        saveErrorLog(bodyLog)
                                    }
                                    console.log('response Insta', responseIn)
                                    array2.push({
                                        id:element.id,
                                        response:response})
                                        let responseInsights   =   responseIn
                                        fetch(
                                            `https://graph.facebook.com/v12.0/${element.id}/comments?access_token=${token}`)
                                            .then((res) => res.json())
                                            .then((responseCmt) => {
                                                /* console.log('RESCOMMENTS',response) */
                                                array3.push({
                                                    id:element.id,
                                                    response:response})
                                                let responseComments= responseCmt
                                                
                                                let objecto = responseIn
                                                console.log('responseIn', responseIn)
                                                console.log('OBJECTO', objecto)
                                                console.log('responseMedia', responseMedia)
                                                let objecto2 = responseCmt
                                                console.log('RESCOMMENTS',objecto2)
                                                const caption= responseMedia.caption
                                                console.log('CAPTION antes del for', caption)
                                                let hashtagWord =''
                                                let startHashTag = false
                                                let hashtag =[]
                                                if(caption===undefined)
                                                {
                                                    hashtag.push({"name":"sin hashtag"})
                                                }
                                                else{
                                                    
                                                    for(let x=0; x<=caption.length-1; x++){
                                                        if(caption[x]==='#')
                                                        startHashTag=true
                                                        if(startHashTag===true && caption[x]!==' ')
                                                            hashtagWord= hashtagWord.concat(caption[x]) 
                                                    if(caption[x]===' ' && startHashTag===true ||  x===caption.length-1 && startHashTag===true){
                                                        startHashTag=false
                                                        hashtag.push({"name":hashtagWord.toLowerCase()})
                                                        hashtagWord=''
                                                        }
                                                    }
                                                }
                                                
                                            
                                                let react=0
            
                                            if(responseMedia.comments_count && responseMedia.like_count){
                                                react= responseMedia.comments_count + responseMedia.like_count
                                            }
                                            
                                            if(objecto.data)
                                            {
                                                 body={
                                                    "influencerid": influencerID,
                                                    "name":responseData.name,
                                                    "createdAt": responseMedia.timestamp,
                                                    "message":   responseMedia.caption, 
                                                    "id": responseMedia.id,
                                                    "urlPost": responseMedia.permalink, 
                                                    "socialmedia":"instagram",
                                                    "picture": responseMedia.media_url, 
                                                    "activity": responseMedia.media_type,
                                                    "reactions": react,
                                                    "comments_count": responseMedia.comments_count,
                                                    "likes_count": responseMedia.like_count,
                                                    "status":"Pendiente",
                                                    "hashtag": hashtag,
                                                    "campaign": hashtag,
                                                    "insights": {
                                                        "engagement":objecto?.data[0]?.values[0]?.value || 0,
                                                    "impressions":objecto?.data[1]?.values[0]?.value || 0,
                                                    "reach":objecto?.data[2]?.values[0]?.value || 0,
                                                    "saved":objecto?.data[3]?.values[0]?.value || 0
                                                },  
                                                    "comments": objecto2.data
                                                        }
                                            }
                                            else{
                                                 body={
                                                    "influencerid": influencerID,
                                                    "name":responseData.name,
                                                    "createdAt": responseMedia.timestamp,
                                                    "message":   responseMedia.caption, 
                                                    "id": responseMedia.id,
                                                    "urlPost": responseMedia.permalink, 
                                                    "socialmedia":"instagram",
                                                    "picture": responseMedia.media_url, 
                                                    "activity": responseMedia.media_type,
                                                    "reactions": react,
                                                    "comments_count": responseMedia.comments_count,
                                                    "likes_count": responseMedia.like_count,
                                                    "status":"Pendiente",
                                                    "hashtag": hashtag,
                                                    "campaign": hashtag,
                                                    "insights": {
                                                        "engagement":0,
                                                    "impressions":0,
                                                    "reach":0,
                                                    "saved":0
                                                },  
                                                    "comments": objecto2.data
                                                        }
                                            }
                                            
                                                
                                                console.log('ELBODY IG',body)
                                              APILI.createPosts(body).then(res => {
                                                console.log(res) })
                                                .catch(err => {
                                                console.log(err)}) 
                                            })
                                }).catch(err => {
                                         console.log('error de then', err);
                                })

                        } catch (error) {
                        }
    
                    })
                
           });

         } catch (error) {
             const bodyLog = {
                              Tipo: "Lectura de webhook erronea",
                              Accion: "Lectura perfil de instagram",
                              Data: {}, 
                              Historico: {},
                              CodeHttp: 1,
                              UsuarioId: currentUser.username,
                              UsuarioName: currentUser.attributes.name,
                              Email: currentUser.attributes.email,
                          }
                    
                          saveErrorLog(bodyLog)
         }
       
       setTaskPage(dataEye)
                setShowTask(true) 
       //saveMedia(array,array2,array3)
       //console.log([array,array2,array3]);
       /* resolve([array,array2,array3]) */
     
       }

       const getPostsFb =  (media,token,influencerID,dataEye) => {
           console.log('HOLA DESDE POSTSFB')
           fetch(
            `https://graph.facebook.com/v12.0/${media.id}?fields=posts%7Breactions%2Cmessage%2Cfull_picture%2Ccreated_time%2Ccomments%2Cpermalink_url%2Cshares%2Cstatus_type%2Cvideo_buying_eligibility%2Cattachments%7D%2Cname&access_token=${media.access_token}`)
            .then((res) => res.json())
            .then((response) => {
                console.log('RESMEDIA',response)
                let responseMedia= response
                response.posts.data.forEach((element) => {
                    console.log('ELEMENT ID', element)
                     fetch(
                         `https://graph.facebook.com/v12.0/${element.id}/insights?metric=post_engaged_users%2Cpost_impressions%2Cpost_impressions_unique&access_token=${media.access_token}`)
                         .then((res) => res.json())
                         .then((response) => {

                             console.log('RESINSIGHTS',response)
                             let hashtagWord =''
                             let startHashTag = false
                             let hashtag =[]
                             if(element.message){
                                const caption= element.message
                             
                             for(let x=0; x<=caption.length-1; x++){
                                 if(caption[x]==='#')
                                 startHashTag=true
                                 if(startHashTag===true && caption[x]!==' ')
                                     hashtagWord= hashtagWord.concat(caption[x]) 
                             if(caption[x]===' ' && startHashTag===true ||  x===caption.length-1 && startHashTag===true){
                                 startHashTag=false
                                 hashtag.push({"name":hashtagWord.toLowerCase()})
                                 hashtagWord=''
                                 }
                             } 
                             }
                             else{
                                 hashtag=[]
                             }

                             let reactions=0
                             if(element.reactions || element.comments){
                                 if(element.reactions && element.comments){
                                     reactions= element.reactions.data.length + element.comments.data.length
                                 }
                                else if(element.reactions){
                                    reactions= element.reactions.data.length
                                }
                                else if(element.comments){
                                    reactions= element.comments.data.length
                                }
                             }
                         
                         const body={
                         "influencerid": influencerID,
                         "name":responseMedia.name,
                         "createdAt": element.created_time,
                         "message":   element.message ? element.message : '', 
                         "id": element.id,
                         "urlPost":  element.permalink_url, 
                         "socialmedia":"facebook",
                         "picture": element.attachments.data[0].type === 'video_inline' ? element.attachments.data[0].media.source  : element.full_picture, 
                         "activity": element.status_type === 'added_video' ? 'VIDEO': 'IMAGE' ,
                         "reactions": reactions,
                         "comments_count": element.comments ? element.comments.data.length : 0,
                         "likes_count": element.reactions ? element.reactions.data.length : 0,
                         "status":"Pendiente",
                         "hashtag": hashtag,
                         "campaign": hashtag,
                         "insights": {
                             "engagement":response.data[0].name=='post_engaged_users' ? response.data[0].values[0].value : 0,
                             "impressions":response.data[1].name=='post_impressions' ? response.data[1].values[0].value : 0,
                             "reach":response.data[2].name=='post_impressions_unique' ? response.data[2].values[0].value : 0,
                             "saved": element.shares? element.shares.count : 0},  
                         "comments": element.comments ? element.comments.data : [],
                             }
                             
                             console.log('ELBODY',body)
                           APILI.createPosts(body).then(res => {
                             console.log(res) }).catch(err => {
                             console.log(err)
                         }) 
                         })
               });
               setTaskPage(dataEye)
                setShowTask(true) 
            })
       }
       
       const updatePostData = (dataPost)=>{
        setMediaData(dataPost[0])
        setInsights(dataPost[1])
        setComments(dataPost[2])
        
      }
      const getResponseData =async (data,arrayPosts,token,influencerID,dataEye, position)=>{
        console.log('response data',data)
        console.log('array posts',arrayPosts)
        setResponseData(data)
      
      setResponseData(data)
      setArrPost(arrayPosts)
    
        getPosts(arrayPosts,token,influencerID,dataEye, position)
         /* setTaskPage(dataEye)
    setShowTask(true)  */
        }
        
      const saveToken =(token,influencerID,data)=>{
        /* setFacebookUserAccessToken(token)
        getUserData(token) */
        let arrayStories =[]
        console.log('ELTOKEN',token)
        
          fetch(
            `https://graph.facebook.com/v12.0/me/accounts?fields=name%2Cid%2Cinstagram_business_account%7Bid%2Cbiography%2Cfollowers_count%2Cfollows_count%2Cmedia_count%2Cname%2Cprofile_picture_url%2Cusername%2Cmedia%2Cstories%2Clive_media%2Cwebsite%2Ctags%7D&access_token=${token}`)
                        .then((res) => res.json())
                        .then((response) => {
                            console.log('RESPELT', response)
                            for(let i=0; i<response.data.length; i++) {
                                if(response?.data[i]?.instagram_business_account)
                                {
                                    getResponseData(response?.data[i]?.instagram_business_account,response?.data[i]?.instagram_business_account?.media?.data,token,influencerID,data, i)
                                    arrayStories=response?.data[i]?.instagram_business_account?.stories?.data
                                }
                            }
                            /* console.log('response insta',response?.data[1]?.instagram_business_account,response?.data[1]?.instagram_business_account?.media?.data)

                            console.log('RESINSTA',response)
                            getResponseData(response?.data[1]?.instagram_business_account,response?.data[1]?.instagram_business_account?.media?.data,token,influencerID,data)
                            arrayStories=response?.data[1]?.instagram_business_account?.stories?.data */
                        })
                        .catch(err => {
                            const bodyLog = {
                              Tipo: "Lectura de webhook erronea",
                              Accion: "Lectura perfil de instagram",
                              Data: {}, 
                              Historico: {},
                              CodeHttp: err,
                              UsuarioId: currentUser.username,
                              UsuarioName: currentUser.attributes.name,
                              Email: currentUser.attributes.email,
                          }
                    
                          saveErrorLog(bodyLog)
                                 console.log(err);
                             })
      }
    
      const saveTokenFb =(token,influencerID,data)=>{
        /* setFacebookUserAccessToken(token)
        getUserData(token) */
        
        console.log('ELTOKEN',token)
        console.log('datdaata',data)
          fetch(
            `https://graph.facebook.com/v12.0/me/accounts?fields=id%2Cname%2Cemail%2Cposts%2Cfriends%2Cpicture.type(large)%2Caccess_token%2Caccounts%7Baccess_token%2Cemails%2Cengagement%2Cfollowers_count%2Cfan_count%2Clikes%2Cid%2Clink%2Cname%2Cphone%2Cusername%2Ccategory%7D&access_token=${token}`)
                        .then((res) => res.json())
                        .then((response) => {
                            console.log('RESFB',response)
                            getResponseDataFb(response.data,token,influencerID,data)
                            
                        })
      }
      
    const getResponseDataFb = async (response,token,influencerID,dataEye)=>{
        try {
            console.log('received response',response[0])
        getPostsFb(response[0],token,influencerID,dataEye)
        } catch (error) {
            console.log('error')
        }
    }

    async function findHastags(resCampain, body){
        var hola = false;
        await body.hashtag.forEach(async hastagE =>{
           await resCampain.hastags.forEach(async hastagC =>{
            //console.log('has',hastagE.name ,'cam',hastagC.name);
            if(hastagE.name.toLowerCase()  == hastagC.name.toLowerCase() ){
             // console.log("macth");
               hola = true
            };
           });
        });
        return hola
      }

    async  function getPostdata (post,influencerID,temporalSubTask,task,resCampain,data) {
        let  arrayPosts=[];
        console.log('datdaata',data)
        await post.posts.data.forEach(async( element)  => {
            // console.log('ELEMENT ID', element)
             await fetch(`https://graph.facebook.com/v12.0/${element.id}/insights?metric=post_engaged_users%2Cpost_impressions%2Cpost_impressions_unique&access_token=${temporalSubTask.tokenRedSocial}`)
             .then(response => response.json())
             .then( async response =>   {
                //console.log('body', response);
                 let hashtagWord =''
                 let startHashTag = false
                 let hashtag =[]
                 if(element.message){
                    const caption= element.message
                 
                 for(let x=0; x<=caption.length-1; x++){
                     if(caption[x]==='#')
                     startHashTag=true
                     if(startHashTag===true && caption[x]!==' ')
                         hashtagWord= hashtagWord.concat(caption[x]) 
                 if(caption[x]===' ' && startHashTag===true ||  x===caption.length-1 && startHashTag===true){
                     startHashTag=false
                     hashtag.push({"name":hashtagWord.toLowerCase()})
                     hashtagWord=''
                     }
                 } 
                 }
                 else{
                     hashtag=[]
                 }
 
                 let reactions=0
                 if(element.reactions || element.comments){
                     if(element.reactions && element.comments){
                         reactions= element.reactions.data.length + element.comments.data.length
                     }
                    else if(element.reactions){
                        reactions= element.reactions.data.length
                    }
                    else if(element.comments){
                        reactions= element.comments.data.length
                    }
                 }
             
                let body={
                "influencerid": influencerID,
                "name":post.name,
                "createdAt": element.created_time,
                "message":   element.message ? element.message : '', 
                "id": element.id,
                "urlPost":  element.permalink_url, 
                "socialmedia":"facebook",
                "picture": element.attachments.data[0].type === 'video_inline' ? element.attachments.data[0].media.source  : element.full_picture, 
                 "activity": element.status_type === 'added_video' ? 'VIDEO': 'IMAGE' ,
                "activitypost": element.status_type,
                "reactions": reactions,
                "comments_count": element.comments ? element.comments.data.length : 0,
                "likes_count": element.reactions ? element.reactions.data.length : 0,
                "hashtag": hashtag,
                "campaign": task.campaignID,
                'task' :task.id,
                "insights": {
                    "engagement":response.data[0].name=='post_engaged_users' ? response.data[0].values[0].value : 0,
                    "impressions":response.data[1].name=='post_impressions' ? response.data[1].values[0].value : 0,
                    "reach":response.data[2].name=='post_impressions_unique' ? response.data[2].values[0].value : 0,
                    "saved": element.shares? element.shares.count : 0},  
                "comments": element.comments ? element.comments.data : [],
                    }
 
                 let validacion =  await findHastags(resCampain,body);
                 if(validacion){
                     console.log("bingo");
 
                     APILI.createPosts(body).then(res => {
                     console.log(res) }).catch(err => {
                     console.log(err)
                     })
                     arrayPosts.push(body)
 
                 }
               
             })
             .catch(err => console.error(err));
         });
 
         console.log('arrar',arrayPosts);
         setTaskPage(data)
         setShowTask(true)
     }
       

    const TaskPage = async (data)  => {
        console.log('my task data',data)

        await APILI.getCampaignById({"id":data.campaignID}).then(resCampain => 
        {
        console.log('campain',resCampain.data.campaign[0]);
            if(data.status==="Solicitado"){
                MySwal(data)
            }
            else {

            if(data.socialmedia==='Instagram'){
//https://graph.facebook.com/v12.0/me?fields=id%2Cname%2Caccess_token%2Cinstagram_business_account%7Bid%2Cname%2C%20followers_count%2Cfollows_count%2Cmedia_count%2C%20profile_picture_url%2Cusername%2Cmedia%7D&access_token=

            }else{

                fetch(`https://graph.facebook.com/v12.0/${data.temporalSubTask.idPaginaSocial}?fields=posts%7Breactions%2Cmessage%2Cmessage_tags%2Cfull_picture%2Ccreated_time%2Ccomments%2Cpermalink_url%2Cshares%2Cstatus_type%2Cvideo_buying_eligibility%2Cattachments%7D%2Cname&access_token=${data.temporalSubTask.tokenRedSocial}`)
                .then((res) => res.json())
                .then((response) => {
                    console.log('RESFB',response)
                    getPostdata(response, data.userID,data.temporalSubTask,data,resCampain.data.campaign[0],data)
                    // getResponseDataFb(response.accounts.data,token,influencerID,data)
                })
            }
        
        
                    /*if(data.socialmedia==='Instagram'){
                        
                        APILI.getTemporalSubTask({
                            IdInfluencerApp: data.userID,
                            idcampaing: data.campaignID
                        }).then(res=>{
                            console.log('respuesta temporal',res)
                            if(res.data.count>0){
                                saveToken(res.data.temporalSubTask[0].tokenRedSocial, data.userID,data)
                            }else{
                                console.log('data',data)
                                setTaskPage(data)
                                setShowTask(true)
                            }
                           
                            
                            
                        }).catch(err=>{
                            console.log('error TemporalSubtask', err)
                            const bodyLog = {
                                Tipo: "Lectura de webhook erronea",
                                Accion: "Leer webhook",
                                Data: {},
                                Historico: {},
                                CodeHttp: err,
                                UsuarioId: currentUser.username,
                                UsuarioName: currentUser.attributes.name,
                                Email: currentUser.attributes.email,
                            }
                      
                            saveErrorLog(bodyLog)
                        })
                        /* setTaskPage(data)
                        setShowTask(true) 
                    }else{
                       
                      /*  APILI.getTemporalSubTask({
                            IdInfluencerApp: data.userID,
                            idcampaing: data.campaignID
                        }).then(res=>{
                            console.log('respuesta temporal',res)
                            if(res.data.count>0){
                                saveTokenFb(res.data.temporalSubTask[0].tokenParent, data.userID,data)
                            }else{
                                console.log('data',data)
                                setTaskPage(data)
                                setShowTask(true)
                            }
                            
                            
                            
                        }).catch(err=>{
                            console.log('error TemporalSubtask', err)
                        }) */
                    
                }
        })
        
      
    }   

    const getActivitiesList = async (campaignID) =>{
       
        return   await APILI.getCampaignById({"id":campaignID}).then(res => 
            {
                return res.data.campaign[0].activity})
        .catch(error => {return error})       
            
    }

    const bodyNotification = (data, status) =>{
        return {
        "influencerID": data.userID,
        "title": "Aplicar al reto",
        "message": status==='Confirm' ? `Se te ha aprobado para participar en ${data.campaignName} `  : `Tu solicitud para participar en ${data.campaignName} no ha sido aprobada`,
        "image":"https://i.postimg.cc/m2dZWQTD/likeinclogo.jpg",
        "type":"campaign",
        "idType": data.campaignID
        }
    }

    const MySwal = async (data) => {
        console.log("data opciones",data)
       
          let activities = await getActivitiesList(data.campaignID)
          //console.log(activities)
          
        APILI.getCampaignById({"id":data.campaignID}).then(res =>
            activities= res.data.campaign[0].activity).catch(err => console.log(err))
        
        let influencerName = ''
        let influencerLastName = ''
        console.log(influencerName)
        console.log(influencerLastName);
       
      const updateTaskStatus = async (taskID, taskStatus, data) => {
          console.log("taskID",taskID)
          console.log("taskStatus",taskStatus)
        const update = {
            id: taskID,
            status: taskStatus
        }
        
        const updateTable =() => {
            setrefreshTable(!refreshTable)
        }
         APILI.updateTaskStatusByID(update).then((status) => {
           
          
            if(taskStatus === 'Active'){
                
                activities.forEach(dat => {
                    for (let x=1; x<= parseInt(dat.quantity); x++){
                        const body = {
                            id: v4(),
                            activity: dat.activity,
                            quantity: 1,
                            rating: 0,
                            taskID: taskID,
                            status: "Pendiente",
                            comment: "Sin comentarios",
                            price: 0,
                            name: dat.name,
                            createdAt: new Date(),
                        }
                        
                         APILI.createSubTask(body).then((status) => {
                            console.log("status",status)
                        }).catch((err) => {
                            console.log("err",err)
                        })
                    }
                    
                        })
                    }
                    updateTable()
                }).catch((err) => {
                    console.log("err",err)
                })

                }
          
        
      
        if(data.delivery.name ==="Payment"){
            console.log(data.delivery.name)
            Swal.fire({
                title: 'Ninguna actividad pendiente!', 
                icon: 'success',
                timer: 1500
            })
            return
        }
        let aprobeTask = ''
        let dennyTask = ''
        if(data.delivery.name ==="Rejected"){
            aprobeTask = 'Pendiente'
            dennyTask = 'Rejected'
        }
        if(data.delivery.name ==="Pendiente"){
            aprobeTask = 'Active'
            dennyTask = 'Rejected'
        }
        if(data.delivery.name ==="Active"){
            aprobeTask = 'Completed'
            dennyTask = 'Pendiente'
        }
        if(data.delivery.name ==="Completed"){
            aprobeTask = 'Payment'
            dennyTask = 'Active'
        }


            Swal.fire({
                title: data.campaignName,
                text: 'Usuario:, ' + data.userID + ' Seguidores: '+ data.scope +
                'Desea participar del reto:, ' + data.campaignName,
                icon: 'info',
                showCancelButton: true,
                showDenyButton: true,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: '#00CA72',
                denyButtonText: '#d33',
                denyButtonText:
              '<i class="fa fa-thumbs-down"></i>',
                confirmButtonText:
              '<i class="fa fa-thumbs-up"></i> Aprobar',
            }).then((result) => {
                ///* Read more about isConfirmed, isDenied below 
                if (result.isConfirmed) {
                    updateTaskStatus(data.id, aprobeTask, data) 
                    let body =  bodyNotification(data, 'Confirm'); 
                    APILI.sendNotification(body).then(data=>{
                        console.log({bodyNotification},"bodyNotification",data)
                        Swal.fire({
                            title: 'Confirmado!', 
                              icon: 'success',
                                timer: 1500})
                    }).catch(err => {
                        console.log(err)
                    })
                  
                } else if (result.isDenied) {
                    updateTaskStatus(data.id,dennyTask)
                    let body =  bodyNotification(data, 'Rejected');  
                    APILI.sendNotification(body).then(data=>{
                        console.log({bodyNotification},"bodyNotification",data)
                        Swal.fire({
                            title: 'Rechazado!', 
                              icon: 'error',
                                timer: 1500})
                    }).catch(err => {
                        console.log(err)
                    })   
                  
                }
              })
        
    }
    
    const DeliveryCol = ({ text, className, onClick }) => (
            <div className={"deliverytask" + (text)}>
                {text}
            </div>
    )
    
    const customStyles = {
        headCells: {
          style: {
            '&:nth-of-type(2n)': {
              backgroundColor: '#E3E4E6',
              width: '40px',
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
            },
          },
        },
        cells: {
          style: {
            "&:nth-of-type(2n)": {
              backgroundColor: "#E3E4E6"
            },
          },
        }
    }
    // AQUI
    useEffect(() => {
        Auth.currentAuthenticatedUser().then((userData) => {
            APILI.getTask().then((tasks) => {
                console.log('TASKA', tasks.data.task)

                let arrayData= tasks.data.task
                arrayData.forEach(element => {
                    let updated = element.endDate.split(['/'])
                    console.log('updated',updated)
                    let newDate = updated[1]+'/'+updated[0]+'/'+updated[2];
                    element.endDate = newDate;
                })

                for (let x = 0; x < arrayData.length; x++) {
                    for (let y = 0; y < arrayData.length - 1; y++) {
                        let elementoActual = arrayData[y];
                        let elementoSiguiente = arrayData[y + 1];
                        if (new Date(elementoActual.endDate) < new Date(elementoSiguiente.endDate)) {
                            arrayData[y] = elementoSiguiente;
                            arrayData[y + 1] = elementoActual;
                        }
                    }
                  }
                  

                setData(arrayData);
                getDataView(arrayData)
            })
        })
    }, [refreshTable])

 
    
    const goToEditPage = (id, name, createAt) => {
        let edit = {
            id,
            name,
            createAt,
        }
        props._setEdit(edit)

        window.$.fancybox.open({
            src: '#new-category-modal',
            //type: 'modal',
            opts: {
                modal: true,
                afterClose: () => props._clearEdit(),
            },
        })
    }

    const saveLog = async (body) => {
        try {
          APILI.createNewLog(body).then(res => {
            console.log('Log Creado')
          }).catch(err => {
          console.log('err', err) 
          })
          
      } catch (err) {
          console.log("No se pudo guardar el log", err)
      }
      }

      const saveErrorLog = async (body) => {
        try {
          APILI.createNewErrorLog(body).then(res => {
            console.log('Error log Creado')
          }).catch(err => {
          console.log('err', err) 
          })
          
      } catch (err) {
          console.log("No se pudo guardar el log de error", err)
      }
      }


    const removeTask = (task) => {
        
        console.log("remove state",task)
        props._showAlertModal('Eliminar Tarea?', `Esta seguro que desea eliminar la tarea`, Modal.MODAL_TYPE_CONFIRMATION, () => {
            APILI.deleteTask({id: task.id}).then(res => {
            console.log('Eliminado')
            props._showLoader(true);
            
            const bodyLog = {
                Tipo: "Delete",
                UsuarioId: currentUser.username,
                UsuarioName: currentUser.attributes.name,
                Email: currentUser.attributes.email,
                Accion: "Eliminar tarea",
                Data: {"id":task.id, "campaignName":task.campaignName},
                Historico: task,
                CodeHttp: res.status
            }
            saveLog(bodyLog)

              APILI.getTask()
                .then((res) => {
                    setrefreshTable(res.data.task)
                  props._showLoader(false);
                })
                .catch(console.error);

          }).catch(err => {

            const bodyLog = {
                Tipo: "Delete error",
                UsuarioId: currentUser.username,
                UsuarioName: currentUser.attributes.name,
                Email: currentUser.attributes.email,
                Accion: "Eliminar tarea",
                Data: {"id":task.id, "campaignName":task.campaignName},
                Historico: task,
                CodeHttp: err
            }
            saveErrorLog(bodyLog)
          console.log('err', err) 
          })
          
        })
      }


    const DetailCol = ({ text, data}) => (
        <div className="firstColl w-100">
            <div className="icon-container">
                <i onClick={() =>TaskPage(data)} class="fas fa-eye"></i>
                <i className="fas fa-edit"></i>
                <i class="fas fa-trash" onClick={() => removeTask(data)}></i>
                <i class="fab fa-telegram-plane"></i>
                <i class="fas fa-ellipsis-v"></i>
            </div>
            <p className="name-fc">
                {text}
            </p>
        </div>
    )

    const [columns, setColumns] = useState(
        [
            {
                id: 'name',
                name: 'Email del Truer',
                selector: 'name',
                sortable: true,
                omit:false,
                minWidth: '300px',
                style: {
                    color: '#02025b',
                },
                cell: (row) => (
                    <DetailCol
                        text={row.name}
                        data={row}
                    />
                ),
            },
            {
                id: 'delivery',
                name: 'Estado',
                selector: 'delivery',
                sortable: true,
                omit:false,
                center: true,
                style: {
                    backgroundColor: '#E3E4E6',
                    fontWeight: 'bold',
                },
                type: 'text',
                cell: (row) => (
                    //console.log("estados tareas2022", row.status)
                    <DeliveryCol
                        text={getName(row.status)}
                    />
                )
            },
            {
                id: 'campaignName',
                name: 'Campaña',
                selector: 'campaignName',
                sortable: true,
                omit:false,
                center: true,
                style: {
                    fontWeight: 'bold',
                }
            },
            {
                id: 'scope',
                name: 'Fecha de vencimiento',
                selector: 'scope',
                sortable: true,
                omit:false,
                center: true,
                style: {
                    backgroundColor: '#E3E4E6',
                    fontWeight: 'bold',
                },
            },
            {
                id: 'source',
                name: 'Red Social',
                selector: 'source',
                sortable: true,
                omit:false,
                center: true,
                style: {
                    fontWeight: 'bold',
                },
                cell: (row) => <img className="source-img" src={row.source} alt="" />
            },
            {
                id: 'inscription',
                name: 'Alcance',
                selector: 'inscription',
                sortable: true,
                omit:false,
                center: true,
                style: {
                    fontWeight: 'bold',
                },
            },
        ]
    )

    const handleFilterPerColumn = (column, type, value) => {
        setFilters((prev) => ({ ...prev, [column]: { type, value } }))
    }

    const handleShowColumnChange = (column, show) => {
        let index = columns.findIndex((col) => col.selector === column)
        let aux = columns.map((col) => col)
        aux[index].omit = show
        setColumns(aux)
    }

    const handleColumnSortChange = (positions) => {

        let aux = columns.map((col) => col)

        positions.forEach((columnId, position) => {
            let index = columns.findIndex((col) => col.selector === columnId)
            aux[index].position = position
        })
        aux = _.orderBy(aux, ['position'])

        setColumns(aux)
    }
    function getName(status) {
        switch (status) {
            case "Pendiente":
                return "Pendiente"
                case "Active":
                    return "Activa"
            case "Finalizada":
                return "Finalizada"
            case "active":
                return  "Activa"
            case "En Revision":
                return "En revisión"
            case "rejected":
            return "Rechazado"
            case "Rejected":
                return "Rechazado"
            case "Completado":
                    return "Completado"
            case "Finalizado":
            return "Finalizado"
            case "Cerrada":
                    return "Cerrada"
            case "Solicitado":
                return "Solicitado"
            case "En progreso":
            return "En progreso"
            case "Completado":
                return("Completado")
                case "Aprobado":
                return("Aprobado")
            default:
                return "Desconocido"
        }
    }
/******************************************************** */
const getDataView = (data) => {
  
    console.log(data)
    setTables({
        data: data.map(tasks => ({
          name: tasks.userID,
          delivery: {
            name: getName(tasks.status),
            className: getClassNameByStatus(tasks.status)
        },
        campaignName: tasks.campaignName,
        scope: new Date(tasks.endDate).toLocaleString('es-GT'),
        source: getLogoByName(tasks.socialmedia),
        inscription: tasks.scope,
        id: tasks.id,
        userID: tasks.userID,
        
        price: tasks.price,
        socialmedia: tasks.socialmedia,
        activityName: tasks.name, 
       // image: tasks.image.split('?')[0],
        campaignID:tasks.campaignID,
        enable: tasks.enable,
        subTasks: tasks.subTasks,
        status: tasks.status,
        serchableStatus: getName(tasks.status)

         
        })) 
    }
    )

    setFilteredDataNormal(
        data.map(tasks => ({
            name: tasks.userID,
            delivery: {
              name: getName(tasks.status),
              className: getClassNameByStatus(tasks.status)
          },
          campaignName: tasks.campaignName,
          scope: new Date(tasks.endDate).toLocaleString('es-GT'),
          source: getLogoByName(tasks.socialmedia),
          inscription: tasks.scope,
          id: tasks.id,
          userID: tasks.userID,
          temporalSubTask : tasks.temporalSubTask,
          price: tasks.price,
          socialmedia: tasks.socialmedia,
          activityName: tasks.name, 
         // image: tasks.image.split('?')[0],
          campaignID:tasks.campaignID,
          enable: tasks.enable,
          subTasks: tasks.subTasks,
          status: tasks.status,
          serchableStatus: getName(tasks.status)
           
          }))
    )
    setFilteredData(data.map(tasks => ({
        name: tasks.userID,
        delivery: {
          name: getName(tasks.status),
          className: getClassNameByStatus(tasks.status)
      },
      campaignName: tasks.campaignName,
      scope: new Date(tasks.endDate).toLocaleString('es-GT'),
      source: getLogoByName(tasks.socialmedia),
      inscription: tasks.scope,
      id: tasks.id,
      userID: tasks.userID,
      temporalSubTask : tasks.temporalSubTask,
      price: tasks.price,
      socialmedia: tasks.socialmedia,
      activityName: tasks.name, 
     // image: tasks.image.split('?')[0],
      campaignID:tasks.campaignID,
      enable: tasks.enable,
      subTasks: tasks.subTasks,
      status: tasks.status,
      serchableStatus: getName(tasks.status)
       
      })) )
  }

  const handleFilterTextChange = (value) => {
    console.log(value);
    changeFilteredData(value)
  }

  const changeFilteredData= (filterText) =>  {
    setFilteredData(tables.data.filter( (item) =>  
    item?.name?.toString().toLowerCase().includes(filterText.toLowerCase()) || 
    item?.delivery?.name.toString().toLowerCase().includes(filterText.toLowerCase()) || 
    item?.campaignName?.toString().toLowerCase().includes(filterText.toLowerCase()) || 
    item?.socialmedia?.toString().toLowerCase().includes(filterText.toLowerCase())
    ))
    
  }
  

  

/******************************************************** */
  function getTasks(listCampaigns, category) {
    let myCampaigns = []
    listCampaigns.forEach(campaign => {
      campaign.mediaCategories.forEach(categoryE => {
        if (categoryE.name === category) {
          myCampaigns.push(campaign)
        }
      });
    });
    return myCampaigns
  }

  const sort= (rows,selector,direction)=>{
    if(selector==='scope'){
        console.log('DIRECTION',direction)
        if(direction==='asc'){
            setFilteredData(filteredData.reverse())
        }else{
            setFilteredData(filteredDataNormal)
        }
        
        return orderBy(rows, direction)
    }else{
        return orderBy(rows, selector, direction)
    }
}
const exportDataToExcel = () =>{
    console.log('FCampToExport', tables.data)
    let headers = []
    let dataExcel=[]
    let selectors = []
    let excelData = [];
    var filteredColumns=columns.filter(function(col){
      return col.omit===false
    })
    
    filteredColumns.forEach((element)=>{
      headers.push(element.name)
      selectors.push(element.selector)
    })  
    
    dataExcel.push(headers)
  
         if(filteredData.length>0)
         {
            filteredData?.forEach((element) => {
             excelData = [];
               for(let k=0; k<selectors.length; k++)
               {
                   excelData.push(
                     selectors[k]=="delivery" ? element.delivery.name:
                     selectors[k]=="source" ? element.socialmedia:
                     element[selectors[k]],
                   );
               }
            
               dataExcel.push(excelData);
             });
             
         }
     console.log('dataParaExportar', dataExcel)
     return dataExcel;
  }
/******************************************************** */
    return (
        <>
        {showTask ?
        <Task setShowTask={(data)=> setShowTask(data)} showTask={showTask} refreshTable={refreshTable} setrefreshTable={(data)=>setrefreshTable(data)} taskPage={taskPage}/> : 
        <div className='form-dashboard'>
            <div id='shadow' className='shadow hide'></div>
            <div className='Bar-content'>
                <div className='content-menu list'>
                    <select
                        id='viewSelect'
                        className='select-dashboard fa'
                    >
                        <option className='fa' value='1'>
                            {' '}
                            &#xf0ce; Lista de Tareas
                        </option>
                        {/* <option className='fa' value='0'>
                            {' '}
                            &#xf0db; Vista de Kanban{' '}
                        </option>
                        <option className='fa' value='2'>
                            {' '}
                            &#xf073; Vista de calendario
                        </option> */}
                    </select>
                    < DataTableUtils onChangeFilterText={handleFilterTextChange}>
                        {/* <Share
                            contacts={utils.myContacts}
                        /> */}
                        <Share
                        contacts={utils.myContacts}
                        data={exportDataToExcel()}
                        isArray={true}
                        exportXLSX={true}
                        exportTXT={true}
                        exportDropbox={true}
                        exportDrive={true}
                        exportCSV={true}
                        printable={true}
                        exportPNG={true}
                        exportLink={true}
                        />
                        <ShowColumn
                            columns={columns}
                            showColumn={handleShowColumnChange}
                            onSortChange={handleColumnSortChange}
                        />
                        {/* <FilterColumn
                            columns={columns}
                            onFilterColumn={handleFilterPerColumn}
                        /> */}
                    </DataTableUtils>
                    <div
                        className='btnOrange-dash'
                        onClick={e => setshowModal(true)}>
                        <i className='fas fa-plus'></i>
                    </div>
                </div>
            </div>
            <div className="body-container TitleListTask">
                <div className="content-row">
                    <div className="title-grid">
                        Listado de Tareas
                    </div>

                    {/* <select className='select-dashboard'>
                        <option value='1'>
                            &#xf0ce; Últimos 30 dias
                        </option>
                    </select> */}
                </div>
            </div>
        <div className="row-section">
          <ReactDataTable
            columns={columns}
            customStyles={customStyles}
            data={filteredData}
            noDataComponent={"No hay datos para mostrar"}
            paginationComponentOptions={{
                rowsPerPageText: 'Elementos por página',
                rangeSeparatorText: 'de',
            }}
            persistTableHead
            pagination
            noHeader
            sortFunction={sort}
          />
        </div>
        {showModal ? <AddTask title="Agregar Tareas" showModal={setshowModal} /> : null}

        </div>
 } </>
    )
}

const mapDispatchToProps = dispatch => ({
    _showLoader: show => dispatch(showLoader(show)),
    _showAlertModal: (title, message, type, callback) =>
    dispatch(Modal.showAlertModal(title, message, type, callback)),
  })
  
  const mapStateToProps = (state) => ({
    isLoading: state.app.isLoading,
    myUser: state.users.myUser
  })

export default connect(mapStateToProps, mapDispatchToProps)(TaskList)
