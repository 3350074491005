import React from 'react'

import { log } from './logger'
import * as Validator from './validator'

const UtilsContext = React.createContext({
  log: log,
  validator: Validator,
})

const withUtils = Component => props => (
  <UtilsContext.Consumer>
    {utils => <Component {...props} utils={utils} />}
  </UtilsContext.Consumer>
)

export { withUtils }
