import { v4 } from 'uuid'

import * as API from 'helpers/api'
import * as Logger from 'utils/logger'
import * as Modal from './modal.actions'
import { showLoader } from './loader.actions'
import { upsertAccountTag } from './accounts.actions'

export const BUDGETS_CLEAR = 'BUDGETS:CLEAR'
export const BUDGETS_ADD_ONE = 'BUDGETS:ADD:ONE'
export const BUDGETS_SET_EDIT = 'BUDGETS:SET:EDIT'
export const BUDGETS_CLEAR_EDIT = 'BUDGETS:CLEAR:EDIT'
export const BUDGETS_SET_FILTER_TEXT = 'BUDGETS:SET:FILTER:TEXT'

const TAG = 'Budgets:Actions'

export const getBudgets = account => dispatch => {
  dispatch(showLoader(true))
  dispatch(clearBudgets())

  API.getBudgets(account)
    .then(response => {
      Logger.log(TAG, 'getBudgets', response)
      let { budgets } = response.data

      budgets.forEach(budget => {
        dispatch(addBudget(budget))
      })
    })
    .catch(err => {
      Logger.log(TAG, 'error getBudgets', err)
    })
    .then(() => {
      dispatch(showLoader(false))
    })
}

export const setFilterText = text => ({
  type: BUDGETS_SET_FILTER_TEXT,
  text
})

export const clearBudgets = () => ({
  type: BUDGETS_CLEAR
})

export const createBudget_logout = (id, title, account, amount, edit, createAt) => dispatch => {
  dispatch(showLoader(true))
  let now = Date.now()

  let body = {
    id,
    title,
    amount,
    account,
    status: 1,
    available: amount,
    createAt: edit ? createAt : now,
    updateAt: now,
  }

  return API.createBudget(body)
}

export const createBudget = (id, title, account, amount, rawTags, status, createAt, edit) => async dispatch => {
  dispatch(showLoader(true))

  const parseTags = async (array) => {
    const result = []
    
    if (array) {
      const tagsCreated = array.filter((tag) => !tag.__isNew__)
      const tagsToCreate = array.filter((tag) => tag.__isNew__)
      Logger.log(TAG, 'tagsCreated', tagsCreated)
      Logger.log(TAG, 'tagsToCreate', tagsToCreate)

      tagsCreated.forEach((tag) => {
        result.push(tag.value)
      })

      for (const tag of tagsToCreate) {
        const tagId = v4()

        await dispatch(upsertAccountTag(tagId, tag.label, account, false))
        
        result.push(tagId)
      }
    }

    return result
  }

  const now = Date.now()

  const tags = await parseTags(rawTags)
  Logger.log(TAG, 'tags', tags)

  const body = {
    id,
    tags,
    title,
    amount,
    status,
    account,
    available: amount,
    createAt: edit ? createAt : now,
    updateAt: now,
  }

  try {
    const response = await API.createBudget(body)

    Logger.log(TAG, 'createBudget', response)
    const mTitle = status === 1
      ? 'Presupuesto Creado'
      : 'Solicitud Enviada'
    const mMessage = status === 1
      ? 'Su presupuesto fue creado exitosamente.'
      : 'Su presupuesto fue enviado exitosamente. Un Administrador lo estará revisando pronto.'

    dispatch(Modal.showAlertModal(mTitle, mMessage, Modal.MODAL_TYPE_SUCCESS, () => {
      dispatch(getBudgets(account))
    }))
  } catch (err) {
    Logger.log(TAG, 'error createBudget', err)
    dispatch(Modal.showAlertModal('Error!', 'Ocurrió un error al solicitar el presupuesto', Modal.MODAL_TYPE_ERROR))
  }

  dispatch(showLoader(false))
}

export const setEdit = edit => ({
  type: BUDGETS_SET_EDIT,
  edit,
})

export const clearEdit = () => ({
  type: BUDGETS_CLEAR_EDIT,
})

const addBudget = budget => ({
  type: BUDGETS_ADD_ONE,
  budget
})