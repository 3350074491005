import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Navbar, { Nav, NavLink } from '@easygosanet/react-navbar'
import logo from './assets/img/logo.png'
import { showModal } from 'redux/actions/status.actions'
import ProfileEdit from '../profileEdit'
import AdminPanel from '../adminPanel'
import Notificator from '../notificator'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as APILI from 'helpers/apiLI';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { AmplifyAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
//import "./assets/img/style.css"
import './assets/img/style.scss'




const NavBar = ({authState, location, onStateChange }) => {
  const [active, setActive] = useState('')
  const [showModalProfile, setShowModalProfile] = useState(false);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [showNotificator, setShowNotificator] = useState(false); 
  //onStateChange('signUp', {})
/*   const currentUser = JSON.parse(localStorage.getItem('CurrentUser')) ?? [];
 */  const [showAdvertiser, setShowAdvertiser] = useState(false);



  useEffect(() => {
    if (location.pathname !== active) {
      setActive(location.pathname)
    }

  }, [location.pathname])

 //comment by LM 23/07/2021
  //const goToLogin = (e) => {
  // e.preventDefault()
  // onStateChange('signUp', {})
  //}

  

  const goToSignIn = e => {
    e.preventDefault()
      onStateChange('signOut', {})
  }
  const goToHome = () => {
    // if (authState !== 'signedIn') {
    //   onStateChange('signIn', {})
    // }
  }

  const showPWA = () => {
    // if (authState !== 'signedIn') {
    //   onStateChange('PWA', {})
    // }
  }
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickDropMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
    <Navbar
      light
      tag={Link}
      expand='md'
      id='main-navbar'
      className='navbarFather'
    >
      <img
        src={logo}
        onClick={goToHome}
        className='img-fluid h-100'
        alt='...'
      />
      
      <Nav className='nav'>
        {/* campana */}
        {/* <NavLink tag={Link} to='#' onClick={showPWA} className='tab'>
          <i className="fas fa-bell fa-2x"></i>
        </NavLink> */}
        {/* Perfil */}
        <NavLink tag={Link} to='#' onClick={showPWA} className='tab'>
          <div onClick={handleClickDropMenu} >
          <i  className="fas fa-user fa-2x"></i>
          <a>Perfil</a>
          </div>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseDropMenu}
            style={{marginTop: "46px",}}
          >
        <MenuItem onClick={e=> {setShowModalProfile(true); handleCloseDropMenu()}}><CheckCircleOutlineIcon />Configuración Perfil</MenuItem>
        <MenuItem  id="btnDesconectar">
        < AmplifySignOut className='desconectar'
          onClick={() => (console.log('Cerrar sesion'))}
          formFields={[
          {placeholder: "Cerrar Sesión",}
          ]}
          style={{backgroundColor: "#5CF993 !important"}}
        />
        </MenuItem>
      </Menu>
        </NavLink>
        {/* Configuración */}
        {/* <NavLink tag={Link} to='#' onClick={e=> {setShowAdminModal(true)}}  className='tab'>
          <i className='fas fa-cog fa-2x'></i>
        </NavLink> */}
        {/* help */}
        {/* <NavLink tag={Link} to='#' onClick={showPWA} className='tab' >
          <i className="fas fa-question fa-2x"></i>
        </NavLink> */}
      </Nav>
     
      </Navbar> 
    {showModalProfile ? <ProfileEdit title="Perfil de Usuario" showModal={setShowModalProfile} /> : null}
    {showAdminModal ? <AdminPanel title="Administrar Roles" showAdminModal={setShowAdminModal} /> : null}
    {showNotificator ? <Notificator title="Enviar una nueva notificacion Push General" showNotificator={setShowNotificator} /> : null}
  
    </>
  )
}

export default withRouter(NavBar)
