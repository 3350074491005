import React from "react";
import { withRouter, Link } from 'react-router-dom'
//import InstagramLogin from "react-instagram-oauth";
import Facebook from "./facebook";

const AsociarUser = (props) => {
  
  return (
    <div className="App">
    <div>
        <Link className="btn-plus-form" to='/scan'>Regresar</Link>
        <br/>
        <br/>
        <br/>
      </div>
      <h2 style={{textAlign: "center"}}> Registra tu perfil </h2>
    <Facebook />
  </div>
    );
}

export default AsociarUser
