import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as utils from "data.js";
import * as APILI from "helpers/apiLI";
import { showLoader } from "redux/actions/loader.actions";
import { Auth, Hub } from "aws-amplify";
import { getLogoByName } from "utils";
import _ from "lodash";
import * as STORAGE from "helpers/storageLI";
import { getLogosListByNames, getClassStyleByStatus } from "myUtil";

//components
import PageLoader from "components/page_loader";
import InfoBox from "components/infoBox";
import ImageBox from "components/imageBox";
import TargetContent from "components/targetContent";
import GraphBox from "components/graphBox";
import NewCampaign from "components/newCampaign";
import NewAdvertiser from "../newAdvertiser";
import Info from "components/campaign/info";

//Charts
import GenderChart from "components/genderChart";
import PieChart from "components/pieChart";
import AreaChart from "components/areaChart";
import Doughnut from "components/doughnutChart";
import ChartDataLabels from 'chartjs-plugin-datalabels';


//images

import Logo_facebook from "./assets/img/logo_facebook.svg";
import Logo_instagram from "./assets/img/logo_instagram.png";
import Logo_tiktok from "./assets/img/logo_tiktok.svg";
import logo_linkedin from "./assets/img/logo_linkedin.svg";
import logo_whatsapp from "./assets/img/whatsapp.png";

import DataTableUtils, {
  Share,
  ShowColumn,
  FilterColumn,
} from "@easygosanet/react-datatable-utils";

import ReactDataTable, { DateRange } from "@easygosanet/react-datatable";
import "./assets/css/styles.scss";

import { Row } from "react-bootstrap";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import orderBy from'lodash/orderBy'

const DetailCol = ({ text, className, onClick }) => (
  <div className="firstColl w-100">
    <div className="icon-container">
      <i class="fas fa-eye"></i>
      <i className="fas fa-edit"></i>
      <i class="fas fa-trash"></i>
      <i class="fab fa-telegram-plane"></i>
      <i class="fas fa-ellipsis-v"></i>
    </div>
    <p className="name-fc">{text}</p>
  </div>
);

const DeliveryCol = ({ text, className, onClick }) => (
  <div className={className}>
    <div className="delivery-text">{text}</div>
  </div>
);

const customStyles = {
  headCells: {
    style: {
      "&:nth-last-of-type(4)": {
        backgroundColor: "#E3E4E6",
        width: "40px",
      },
      "&:nth-last-of-type(6)": {
        backgroundColor: "#E3E4E6",
      },
    },
  },
};

const Dashboard = (props) => {
  console.log("propiedadesssssss", props.myUser["custom:userRol"])
  const user = props.myUser
  const userRol = props.myUser["custom:userRol"]
  let [campaigns, setCampaigns] = useState([]);
  let [influencers, setInfluencers] = useState([]);
  const [filters, setFilters] = useState({});
  const [campaingFacebook, setCampaingFacebook] = useState(0);
  const [campaingInstagram, setCampaingInstagram] = useState(0);
  const [influencersData, setinfluencersData] = useState([]);
  const [campaingTikTok, setCampaingTikTok] = useState(0);
  const [gender, setGender] = useState([]);
  const [campaingLinkedin, setCampaingLinkedin] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [userStatusArray, setuserStatusArray] = useState([]);
  const [userAgeArray, setuserAgeArray] = useState([]);
  const [showAdvertiser, setShowAdvertiser] = useState(false);
  const [generalUserStates, setGeneralUserStates] = useState("");
  const [generalGenderState, setGeneralGenderState] = useState("");
  const [generalAgeState, setGeneralAgeState] = useState("");
  const [countProfiles, setCountProfiles] = useState(0);
  const [countInfluencers, setCountInfluencers] = useState(0);
  const [countPendiente, setCountPendiente] = useState(0);
  const [countFanPages, setCountFanPages] = useState(0);
  const [scopeFanPages, setScopeFanPages] = useState(0);
  const [totalActiveScope, setTotalActiveScope] = useState(0);
  const [averageScope, setAverageScope] = useState(0);
  const [onlyFacebook, setOnlyFacebook] = useState([])
  const [onlyInstagram, setOnlyInstagram] = useState([])
  const [onlyTikTok, setOnlyTikTok] = useState([])
  const [statusTasks, setStatusTasks] = useState([])
  const [refresh, setRefresh] = useState(false);
  let [currentCampaign, setCurrentCampaign] = useState({});
  let [infoCampaign, setInfoCampaign] = useState([]);
  let [categories, setCategories] = useState([]);
  let [coords, setCoords] = useState({});
  let [hashtags, setHashtags] = useState([]);
  const [fCamp,setFCamp]= useState([])
  const [filteredNormal, setFilteredNormal]= useState([])
  let [window, setWindow] = useState(1);
  const [summaryData, setSummaryData] = useState({
    count: 0,
    activeScope: 0,
    activeCount: 0,
    PendienteCount: 0,
    rejectedCount: 0,
    profilesFB: {
      activeCount: 0,
      PendienteCount: 0,
    },
    profilesIG: {
      activeCount: 0,
      PendienteCount: 0,
    },
    fanpagesFB: {
      activeCount: 0,
      PendienteCount: 0,
    },
    channelsTikTok: {
      activeCount: 0,
      PendienteCount: 0,
    },
  });
  //nuevos estados para resumen
  const [socialNetwork, setSocialNetwork] = useState([]);
  //nuevo estado para grafica de edad
  const [dataGraphUsers, setDataGraphUsers] = useState({});
  const [dataHeadersAgeGraph,setDataHeadersAgeGraph] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  const [activeCampaigns, setActiveCampaigns] = useState(0);
  const [alcanceCampaigns, setAlcanceCampaigns] = useState(0)
  const [followersReach, setFollowersReach] = useState(0)
  const [followersAvg, setFollowersAvg] = useState(0)
  const [avgCampaigns, setAvgCampaigns] = useState(0)
  const [profit,setProfit]= useState(0)
  const [userCount, setUserCount] = useState(0)
  const [socialProfilesCount, setProfilesCount] = useState(0)
  const [generalStats,setGeneralStats] = useState([])
  const [campaignsState, setCampaignsState] = useState([])
  const [postByState,setPostByState] = useState([])
  const [activeFbProfiles,setActiveFbProfiles] = useState(0)
  const [PendienteFbProfiles,setPendienteFbProfiles] = useState(0)
  const [activeIgProfiles,setActiveIgProfiles] = useState(0)
  const [PendienteIgProfiles,setPendienteIgProfiles] = useState(0)
  const [activeTkProfiles,setActiveTkProfiles] = useState(0)
  const [PendienteTkProfiles,setPendientTkProfiles] = useState(0)
  const [listHashtags, setListHashtags] = useState([])
  const [facebookInteractionAvg, setFacebookInteractionAvg] = useState()
  const [facebookReachAvg, setFacebookReachAvg] = useState()
  const [instagramInteractionAvg, setInstagramInteractionAvg] = useState()
  const [instagramReachAvg, setInstagramReachAvg] = useState()
  const [tiktokInteractionAvg, setTiktokInteractionAvg] = useState()
  const [tiktokReachAvg, setTiktokReachAvg] = useState()
  const [facebookCountGraph, setFacebookCountGraph] = useState()
  const [instagramCountGraph, setInstagramCountGraph] = useState()
  const [tikTokCountGraph, setTikTokCountGraph] = useState()
  const [graphTask,setGraphTask] = useState()
  const handleClickDropMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropMenu = () => {
    setAnchorEl(null);
  };
  //arguments example for calculate age
  //console.log(calculate_age(new Date(1982, 11, 4)));

  function calculate_age(dob) {
    var diff_ms = Date.now() - dob.getTime();
    var age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
  }
  //calculate age
  function getUser() {
    try {
      return Auth.currentAuthenticatedUser();
    } catch (error) {
      return null;
    }
  }
  ///revisar
  function getInfluencers(myCampaigns) {
    let listUsers = [];
    myCampaigns.forEach(function (campaign) {
      if (campaign.influencers != undefined) {
        campaign.influencers.forEach(function (influencer) {
          listUsers.push(influencer);
        });
      }
    });

    return listUsers;
  }

  function getSocialMedia(campaigns, socialMediaName) {
    let counter = 0;
    campaigns.forEach(function (campaign) {
      if (campaign.socialNetworks != undefined) {
        campaign.socialNetworks.forEach(function (socialNetwork) {
          if (socialNetwork.name == socialMediaName) {
            counter++;
          }
        });
      }
    });
    return counter;
  }

  function getListByState(list, state) {
    let counter = 0;
    list.forEach(function (element) {
      if (element.state == state) {
        counter++;
      }
    });
    return counter;
  }

  function getAvgBudget(campaigns) {
    let counter = 0;
    let value = 0;
    campaigns.forEach(function (campaign) {
      if (campaign.budget != undefined) {
        counter++;
        value += parseInt(campaign.budget);
      }
    });
    return "$" + parseFloat(value / counter).toFixed(2);
    return 0;
  }

  function getAvgCPM(campaigns) {
    let counter = 0;
    let value = 0;
    campaigns.forEach(function (campaign) {
      if (campaign.cpm != undefined) {
        counter++;
        value += campaign.cpm;
      }
    });
    console.log(value, counter);
    return "$" + parseFloat(value / counter).toFixed(2);
    return 0;
  }

  function genderCount(obj) {
    return obj.reduce(
      (acc, curVal) => {
        if (curVal.gender === "Masculino") {
          acc.Masculino += 1;
        } else {
          acc.Femenino += 1;
        } // else {
        //    acc.Otro += 1;
        // }
        return acc;
      },
      { Masculino: 0, Femenino: 0 }
    );
  }
  function statusCount(obj) {
    return obj.reduce(
      (acc, curVal) => {
        if (curVal.status === "Active") {
          acc.Active += 1;
        } else if (curVal.status === "Banned") {
          acc.Banned += 1;
        } else if (curVal.status === "Rejected") {
          acc.Rejected += 1;
        } else if (!curVal.status) {
          acc.Rejected += 1;
        }
        return acc;
      },
      { Active: 0, Banned: 0, Rejected: 0 }
    );
  }
  
  useEffect(() => {
    props._showLoader(true);
    Auth.currentAuthenticatedUser()
      .then((userData) => {
        //  APILI.getCampaignByUser({ account: userData.username })
        APILI.getCampaignByUser({ account: userData.username })
          .then((res) => {
            dataAccountCampaingsAndUsers()
            dataBySocialNetwork();
            console.log('CAMP',res.data.campaigns)
            let arrayData= res.data.campaigns
                arrayData.forEach(element => {
                    let updated = element.initDate.split(['/'])
                    console.log('updated',updated)
                    let newDate = updated[1]+'/'+updated[0]+'/'+updated[2];
                    element.initDate = newDate;
                })

                for (let x = 0; x < arrayData.length; x++) {
                  for (let y = 0; y < arrayData.length - 1; y++) {
                      let elementoActual = arrayData[y];
                      let elementoSiguiente = arrayData[y + 1];
                      if (new Date(elementoActual.initDate) < new Date(elementoSiguiente.initDate)) {
                          arrayData[y] = elementoSiguiente;
                          arrayData[y + 1] = elementoActual;
                      }
                  }
                }
                arrayData.forEach(element => {
                  let updated = element.initDate.split(['/'])
                  console.log('updated',updated)
                  let newDate = updated[1]+'/'+updated[0]+'/'+updated[2];
                  element.initDate = newDate;
              })
            getInfoView(arrayData, res.data.count)
            generalStadisticsCampaingAndInfluencer()
            dataGenderInfluencer();
            dataAgeInfluencer();
            
          })
          .catch(console.error);
      })
      .catch(() => console.log("Not signed in"));
    props._showLoader(true);
    APILI.getInfluencersData()
      .then((res) => {
        if (
          res.data &&
          res.data.influencerdata &&
          res.data.influencerdata.length > 0
        ) {
          getInfluencersInfoView(res.data.influencerdata, res.data.count);
          // setinfluencersData(res.data.influencerdata)
          props._showLoader(false);
        }
      })
      .catch(console.error);
    props._showLoader(true);
    APILI.getSocialProfiles()
      .then((res) => {
        if (
          res.data &&
          res.data.socialprofiles &&
          res.data.socialprofiles.length > 0
        ) {
          getProfilesView(res.data.summary);
          console.log(res.data);
          props._showLoader(false);
        }
      })
      .catch(console.error);
    
  }, [refresh]);


  const dataAccountCampaingsAndUsers = async () => {
    props._showLoader(true);
    await APILI.getCampaingsStadistic().then(res => {
     
        setActiveCampaigns(res.data.campaigns[0].data)
        setAlcanceCampaigns(res.data.campaigns[1].data)
        setAvgCampaigns(res.data.campaigns[1].avg)
        setProfit(res.data.campaigns[2].data)
        APILI.getInfluencerCount().then(res => {
          console.log('res.data.accountPerformanceData',res.data.accountPerformanceData)
          setUserCount(res.data.accountPerformanceData[0].data)
          APILI.getProfileCount().then(res =>{
            console.log('profileCount',res.data.socialprofiles)
            setProfilesCount(res.data.socialprofiles.data)
            
          }).catch(console.error)
        }).catch(console.error)
    }).catch(err => console.log(err))
    await APILI.getProfileStadistics()
    .then((res) => {
      setFollowersReach(res.data.stadistics[0].count)
      setFollowersAvg(res.data.stadistics[0].avg)
      console.log('resDataResPelt', res)
    })
    props._showLoader(false);
  }

  const generalStadisticsCampaingAndInfluencer = async (data) => {
    props._showLoader(true);
    await APILI.getGeneralStadisticsInfluencerAndCampaign().then(res => {
      
      
      setGeneralStats([
        {
          text: "Truers Activos: ",
          number: res.data.generalData[0].data,
        },
        {
          text: "Truers Pendientes: ",
          number: res.data.generalData[1].data,
        },
        {
          text: "Truers Rechazados: ",
          number: res.data.generalData[2].data,
        },
        {
          text: "Truers Expulsados: ",
          number: res.data.generalData[3].data,
        },
        /*{
          text: "Presupuesto promedio: ",
          number: res.data.generalData[4].data,
        },*/
      ])
      props._showLoader(false);
    }).catch(err => console.log(err))

    console.log('userPelt', user)
    let resUser = await Auth.currentAuthenticatedUser()
        console.log('currentUserFrom', resUser)
    if(userRol=='anunciante')
    {
      await APILI.findCampaignsByStateAndUser({account: user.sub})
      .then(res => {
        console.log('resDASdsada', res)
      setCampaignsState([
        {
          text: "Activas: ",
          number: res.data.campaigns[0].data,
        },
        {
          text: "En revisión: ",
          number: res.data.campaigns[1].data,
        },
        {
          text: "Completadas: ",
          number: res.data.campaigns[2].data,
        },
        {
          text: "Finalizadas: ",
          number: res.data.campaigns[3].data,
        }
      ])
      setActiveCampaigns(res.data.campaigns[0].data)
    }).catch(err =>{ console.log('ersdads', err)})

    await APILI.postActivityByUser({userID: user.email})
    .then(res => {
      setPostByState([
        {
          text: "Solicitadas: ",
          number: res.data.task[0].data
        },
        {
          text: "Pendientes: ",
          number: res.data.task[1].data
        },
        {
          text: "En progreso: ",
          number: res.data.task[2].data
        },
        {
          text: "Rechazadas: ",
          number: res.data.task[3].data
        },
        {
          text: "Aprobadas: ",
          number: res.data.task[4].data
        },
        {
          text: "Finalizadas: ",
          number: res.data.task[5].data
        }
      ])
    }).catch(err => console.log(err))


    }
    else if(userRol=='admin')
    {
      await APILI.findCampaignsByState()
      .then(res => {
        console.log('resDASdsada', res)
      setCampaignsState([
        {
          text: "Activas: ",
          number: res.data.campaigns[0].data,
        },
        {
          text: "En revisión: ",
          number: res.data.campaigns[1].data,
        },
        {
          text: "Completadas: ",
          number: res.data.campaigns[2].data,
        },
        {
          text: "Finalizadas: ",
          number: res.data.campaigns[3].data,
        }
      ])
    }).catch(err => console.log(err))

    await APILI.postActivity().then(res => {
      setPostByState([
        {
          text: "Solicitadas: ",
          number: res.data.task[0].data
        },
        {
          text: "Pendientes: ",
          number: res.data.task[1].data
        },
        {
          text: "En progreso: ",
          number: res.data.task[2].data
        },
        {
          text: "Rechazadas: ",
          number: res.data.task[3].data
        },
        {
          text: "Aprobadas: ",
          number: res.data.task[4].data
        },
        {
          text: "Finalizadas: ",
          number: res.data.task[5].data
        },
        /* {
          text: "Acreditadas: ",
          number: res.data.task[3].data
        },
        {
          text: "Pagadas: ",
          number: res.data.task[3].data
        }, */
      ])
      
    }).catch(err => console.log(err))

    await APILI.getTask().then(res =>{
      setStatusTasks(res.data.task)
    })

    }
    
    await APILI.getSocialProfiles().then(res =>{
      console.log("datos perfiles sociales", res)
        let perfilesRedes = res.data.socialprofiles
        console.log("perfiles solo tiktok", perfilesRedes)
        let onlyTiktok = perfilesRedes.filter(item => item.socialmedia === "TikTok" )
        console.log("solo tiktok", onlyTiktok)
          setOnlyTikTok(onlyTiktok)
        let onlyinstagram = perfilesRedes.filter(item => item.socialmedia === "Instagram")
        console.log("solo Instagram", onlyinstagram)
          setOnlyInstagram(onlyinstagram)
        let onlyfacebook = perfilesRedes.filter(item => item.socialmedia === "Facebook")
        console.log("solo Facebook", onlyfacebook)
          setOnlyFacebook(onlyfacebook)
    }).catch(err => console.error(err))
    


    
    await APILI.getSocialProfilesInfo().then(res => {
      console.log('resDataProfilesCount',res.data.socialprofiles.data)
      setActiveFbProfiles(res.data.socialprofiles.data[0].activeUsers)
      setPendienteFbProfiles(res.data.socialprofiles.data[0].pendingUsers)
      setActiveIgProfiles(res.data.socialprofiles.data[1].activeUsers)
      setPendienteIgProfiles(res.data.socialprofiles.data[1].pendingUsers)
      setActiveTkProfiles(res.data.socialprofiles.data[2].activeUsers)
      setPendientTkProfiles(res.data.socialprofiles.data[2].pendingUsers)
    }).catch(err => console.log(err))
    
    await APILI.getTop5Hashtags().then(res => {
      let arrayHashtags=[];
      let cont=0
      res.data.campaigns.forEach(element => {
        cont++
          let string= ''
          element.forEach(elementString =>{
            string= string + ' ' + elementString.name
          })
          arrayHashtags.push({
            text: 'Top '+ cont,
            number: string 
          })
      })
      console.log('topHashtag',new Date().toLocaleString('es-GT'))
      setListHashtags(arrayHashtags)
    }).catch(err => console.log(err))
    await APILI.stadisticBySocialMediaCampaing().then(res => {
      console.log('CampaignStadistic',res.data.campaigns)
      /* setFacebookInteractionAvg("Tasa de Interaccion Promedio: "+res.data.campaigns[0].interactionAvg)
      setFacebookReachAvg("Impacto Promedio: "+res.data.campaigns[0].reachAvg)
      setInstagramInteractionAvg("Tasa de Interaccion Promedio: "+res.data.campaigns[1].interactionAvg)
      setInstagramReachAvg("Impacto Promedio: "+res.data.campaigns[1].reachAvg) */
      
      setFacebookCountGraph(res.data.campaigns[0])
      setInstagramCountGraph(res.data.campaigns[1])
      setTikTokCountGraph(res.data.campaigns[2])  
      setFacebookInteractionAvg("Tasa de interacción promedio: "+ facebookCountGraph.interactionAvg)
      setFacebookReachAvg("Impacto promedio: "+ facebookCountGraph.reachAvg)
      setInstagramInteractionAvg("Tasa de interacción promedio: "+ instagramCountGraph.interactionAvg)
      setInstagramReachAvg("Impacto promedio: "+ instagramCountGraph.reachAvg)
      setTiktokInteractionAvg("Impacto promedio: " + tikTokCountGraph?.interactionAvg)
      setTiktokReachAvg("Impacto promedio: "+ tikTokCountGraph?.reachAvg  )
      
    }).catch(err => console.log(err))

    await APILI.subTaskGraph().then(res => {
      console.log('subtaskGraph',res.data)
    }).catch(err => console.log(err))

    await APILI.getTask()
    .then((res) => {
      console.log('TASKA', res.data.task)
      /* setGraphTask(res.data) */
    })
    .catch(err => {
      console.log('err',err)
    })
  }

 
  //obtener primera data para asi
  const dataBySocialNetwork = async () => {
    props._showLoader(true);
    if(userRol=='anunciante')
    {
      await APILI.getCampañasPorRedSocialUsuario({account: user.sub}).then((res) => {
        if (res.data.campaigns) {
          console.log('res.data.campaigns',res.data.campaigns)
          const socialN = res.data.campaigns;
          const socialTemp=[]
          console.log(socialN);
         
  
          socialN.forEach((e) => {
            if(e.name==="Campañas activas" || e.name==="Campañas En Revision" || e.name==="Campañas Completadas" || e.name==="Campañas Finalizadas"){
                  
                   socialTemp.push(e.data)
                  if(socialTemp){
                      let fbCount = 0;
                      let igCount = 0;
                      let lkCount = 0;
                      let tkCount = 0;
                      console.log(socialTemp)
                      socialTemp.forEach((a)=>{
                          console.log(a.Facebook)
                          fbCount=fbCount+a.Facebook
                          igCount=a.Instagram+igCount
                          tkCount=a.Tiktok+tkCount
                      })
                      setCampaingFacebook(fbCount)
                      setCampaingInstagram(igCount)
                      setCampaingTikTok(tkCount)
                  }
                  
              }
          });
          
          } else if(userRol=='admin') 
          {
          let fbCount = 0;
          let igCount = 0;
          let lkCount = 0;
          let tkCount = 0;
          setCampaingFacebook(fbCount)
          setCampaingInstagram(igCount)
          setCampaingTikTok(tkCount)
        }
        
        props._showLoader(false);
      });
    }
    else
    {
      await APILI.getCampañasPorRedSocial().then((res) => {
        if (res.data.campaigns) {
          const socialN = res.data.campaigns;
          const socialTemp=[]
          console.log('socialN',);
         console.log('resDataCamps', res.data)
  
          socialN.forEach((e) => {
              if(e.name==="Campañas activas" || e.name==="Campañas En Revision" || e.name==="Campañas Completadas" || e.name==="Campañas Finalizadas"){
                  
                   socialTemp.push(e.data)
                  if(socialTemp){
                      let fbCount = 0;
                      let igCount = 0;
                      let lkCount = 0;
                      let tkCount = 0;
                      console.log(socialTemp)
                      socialTemp.forEach((a)=>{
                          console.log(a.Facebook)
                          fbCount=fbCount+a.Facebook
                          igCount=a.Instagram+igCount
                          tkCount=a.Tiktok+tkCount
                      })
                      setCampaingFacebook(fbCount)
                      setCampaingInstagram(igCount)
                      setCampaingTikTok(tkCount)
                  }
                  
              }
          });
          
          } else {
          let fbCount = 0;
          let igCount = 0;
          let lkCount = 0;
          let tkCount = 0;
          setCampaingFacebook(fbCount)
          setCampaingInstagram(igCount)
          setCampaingTikTok(tkCount)
        }
        
        props._showLoader(false);
      });
    }
   
    console.log(campaingFacebook)
    console.log(campaingInstagram)
  };

  //Generos para porcentajes
  const dataGenderInfluencer = async () => {
    props._showLoader(true);
    await APILI.getGenderDataInfluencer().then((res) => {
      console.log("GenderData", res);
      if (res.data.generalData) {
        console.log("datossssss",res.data);
        setGeneralGenderState({
          Masculino: res.data.generalData[0].data,
          Femenino: res.data.generalData[1].data,
        });
        props._showLoader(false);
      } else {
        setGeneralGenderState({ Masculino: 0, Femenino: 0 });
      }
    });
  };

  //edades para grafica de pie
  const dataAgeInfluencer = async () => {
    props._showLoader(true);
    let listColors = ["color1", "color2", "color3", "color4", "color5"];
    await APILI.getAgeDataInfluencer()
    .then((res) => {
      console.log("AgeDataRes", res);
      if (res.data.ageData.length>0) {
        const dataUsersAge = {
          range: [],
          gender: [],
          porcentage: [],
        };
        for (let i = 0; i < res.data.ageData.length; i++) {
          let value = res.data.ageData[i];
          let color = listColors[i];
          let range = {
            ageRange: value.name,
            color: color,
          };
          console.log(range);
          dataUsersAge.range.push(range);
          if (value.data) {
            let gender = {
              women: value.data[0].femenino,
              mens: value.data[0].masculino,
              color: color,
            };
            let percentage = gender.women + gender.mens;
            console.log('Gebder',gender);
            dataUsersAge.gender.push(gender);
            dataUsersAge.porcentage.push(percentage);
          }
        }
        console.log('dataGraphUsers',dataUsersAge)
        setDataHeadersAgeGraph({
          labels: ['16 - 17 años', '18 - 24 años', '25 - 29 años',
            '30 - 35 años', '36 - + años'],
          datasets: [
            {
              label: 'Rainfall',
              backgroundColor: [
                '#FF3D57',
                '#FDAB3D',
                '#00CA72',
                '#009AFF',
                '#794FCF'
              ],
              hoverBackgroundColor: [
                '#501800',
                '#4B5000',
                '#175000',
                '#003350',
                '#35014F'
              ],
              data: dataUsersAge.porcentage
            }
          ]
        })
        setDataGraphUsers({
          range: [
            {ageRange: "16 - 17 años",color:'color1'}, 
            {ageRange: '18 - 24 años',color:'color2'}, 
            {ageRange: '25 - 29 años',color:'color3'},
            {ageRange: '30 - 35 años',color:'color4'}, 
            {ageRange: '36 - + años',color:'color5'}],
          porcentage: dataUsersAge.porcentage,
          gender: dataUsersAge.gender
        });
      }
      props._showLoader(false);
    })
    .catch((err)=>{
      console.log('errorAgeData', err)
    })
    ;
  };

  const handleFilterPerColumn = (column, type, value) => {
    setFilters((prev) => ({ ...prev, [column]: { type, value } }));
  };

  const handleShowColumnChange = (column, show) => {
    let index = columns.findIndex((col) => col.selector === column);
    let aux = columns.map((col) => col);
    aux[index].omit = show;
    setColumns(aux);
  };

  const handleColumnSortChange = (positions) => {
    let aux = columns.map((col) => col);

    positions.forEach((columnId, position) => {
      let index = columns.findIndex((col) => col.selector === columnId);
      aux[index].position = position;
    });
    aux = _.orderBy(aux, ["position"]);

    setColumns(aux);
  };

  function amount(item) {
    return parseInt(item.scope);
  }

  function sum(prev, next) {
    return prev + next;
  }

  const groupByHashTag = (group) => {
    function groupBy(objectArray, property) {
      return objectArray.reduce((acc, obj) => {
        const key = obj[property];
        if (!acc[key]) {
          acc[key] = [];
        }
        // Add object to list for given key's value
        acc[key].push(obj);
        return acc;
      }, {});
    }
    const groupedHashTag = groupBy(group, "name");
    return groupedHashTag;
  };

  const getInfoView = (camps, count) => {
  //     const socialNetwork = camps.map(camp => camp.socialNetworks)
  //   //  const myInfluencers = camps.map(camp => camp.influencers)
  //     const myBudget = camps.map(camp => camp.budget).reduce(sum)/count;
  //     console.log("sopas"+ JSON.stringify(myBudget))
  //     let fbCount = 0
  //     let igCount = 0
  //     let lkCount = 0
  //     let tkCount = 0

  //     socialNetwork.forEach(sn => {
  //         const Fb = sn.find(o => o.name === "Facebook");
  //         Fb && (fbCount = fbCount + 1)
  //         const Ig = sn.find(o => o.name === "Instagram");
  //         Ig && (igCount = igCount + 1)
  //         const Tk = sn.find(o => o.name === "TikTok");
  //         Tk && (tkCount = tkCount + 1)
  //         const Lk = sn.find(o => o.name === "Linkedin");
  //         Lk && (lkCount = lkCount + 1)
  //     });

        setCampaigns(camps)
        setFCamp(camps.map((campaign) => ({
          campOriginal: campaign,
          name: campaign.name,
          delivery: getClassStyleByStatus(campaign.state),
          budget: `Q ${campaign.budget ? campaign.budget : 0.0}`,
          scope: campaign?.reach,
          date: {
            start: campaign.initDate,
            end: campaign.endDate,
          },
          source: getLogosListByNames(campaign.socialNetworks),
          inscription: campaign.CPM,
          hastags: campaign.hastags,
          description: campaign.description,
          logo: campaign.logo,
          postImages: campaign.postImages,
          locationList: campaign.locationList,
          mediaCategories: campaign.mediaCategories,
          id: campaign.id,
          ageValue: campaign.ageValue,
          advertiser: campaign.advertiser,
          CPM: campaign.CPM,
          budget: campaign.budget,
          activity: campaign.activity,
          totalAlcance: campaign.totalAlcance,
          totalInteraction: campaign.totalInteraction,
          totalProfit: campaign.totalProfit,
          totalRemainder: campaign.totalRemainder,
          totalCreatividad: campaign.totalCreatividad,
          state: campaign.state,
          slogan: campaign.slogan,
          nonMediaCategories: campaign.nonMediaCategories,
          socialStatusValue: campaign.socialStatusValue,
          initDate: campaign.initDate,
          endDate: campaign.endDate,
        })))
        setFilteredNormal(camps.map((campaign) => ({
          campOriginal: campaign,
          name: campaign.name,
          delivery: getClassStyleByStatus(campaign.state),
          budget: `Q ${campaign.budget ? campaign.budget : 0.0}`,
          scope: campaign?.reach,
          date: {
            start: campaign.initDate,
            end: campaign.endDate,
          },
          source: getLogosListByNames(campaign.socialNetworks),
          inscription: campaign.CPM,
          hastags: campaign.hastags,
          description: campaign.description,
          logo: campaign.logo,
          postImages: campaign.postImages,
          locationList: campaign.locationList,
          mediaCategories: campaign.mediaCategories,
          id: campaign.id,
          ageValue: campaign.ageValue,
          advertiser: campaign.advertiser,
          CPM: campaign.CPM,
          budget: campaign.budget,
          activity: campaign.activity,
          totalAlcance: campaign.totalAlcance,
          totalInteraction: campaign.totalInteraction,
          totalProfit: campaign.totalProfit,
          totalRemainder: campaign.totalRemainder,
          totalCreatividad: campaign.totalCreatividad,
          state: campaign.state,
          slogan: campaign.slogan,
          nonMediaCategories: campaign.nonMediaCategories,
          socialStatusValue: campaign.socialStatusValue,
          initDate: campaign.initDate,
          endDate: campaign.endDate,
        })))
  }
  const getProfilesView = (summary) => {
    setSummaryData(summary);
    //setCountProfiles(count)
    //setTotalActiveScope(total)
    //setCountPendiente(Pendiente)
    // setAverageScope(average)
  };

  const getInfluencersInfoView = (camps, count) => {
    const genderData = camps.map((camp) => camp.gender);
    const statusData = camps.map((camp) => camp.status);
    const ageData = camps.map((camp) => camp.dateBirth);

    let genderArray = [];
    let statusArray = [];
    let ageArray = [];
    let maleCount = 0;
    let femaleCount = 0;
    let activeCount = 0;
    let bannedCount = 0;
    let rejectedCount = 0;
    let PendienteCount = 0;

    for (let x = 0; x < genderData.length; x++) {
      if (genderData[x] === "Masculino" || genderData[x] === "Otro") {
        maleCount = maleCount + 1;
      }
      if (genderData[x] === "Femenino") {
        femaleCount = femaleCount + 1;
      }
    }
    for (let x = 0; x < statusData.length; x++) {
      if (statusData[x] === "Active" || !statusData[x]) {
        activeCount = activeCount + 1;
      }
      if (statusData[x] === "Rejected") {
        rejectedCount = rejectedCount + 1;
      }
      if (statusData[x] === "Banned") {
        bannedCount = bannedCount + 1;
      }
      if (statusData[x] === "Pendiente") {
        PendienteCount = PendienteCount + 1;
      }
    }

    setGeneralUserStates({
      Active: activeCount,
      Banned: bannedCount,
      Rejected: rejectedCount,
      Pendiente: PendienteCount,
    });

    genderData.forEach((element) => genderArray.push({ gender: element }));
    statusData.forEach((element) => statusArray.push({ status: element }));
    ageData.forEach((element) =>
      ageArray.push(calculate_age(new Date(element)))
    );

    setGender(genderArray);
    setuserStatusArray(statusArray);
    setuserAgeArray(ageArray);
    setCountInfluencers(count);
  };

  const donGraphUsers = {
    labels: ["Facebook", "Instagram", "TikTok"],
    datasets: [
      {
        label: "Rainfall",
        backgroundColor: ["#0076B2", "#FF3D57", "#010101"],
        hoverBackgroundColor: [
          "#0076B8",
          "#FF3D59",
          "#191919"
        ],
        data: [
          //facebookCountGraph,
          //instagramCountGraph,
          //tikTokCountGraph,
          campaingFacebook,
          campaingInstagram,  
          campaingTikTok,
        ],
      },
    ],
  };


  const listGeneralStatistics = [
    {
      text: "Influencers Activos: ",
      number: generalUserStates.Active,
    },
    {
      text: "Influencers Pendientes: ",
      number: generalUserStates.Pendiente,
    },
    {
      text: "Influencers Rechazados: ",
      number: generalUserStates.Rejected,
    },
    {
      text: "Influencers Baneados: ",
      number: generalUserStates.Banned,
    },
    {
      text: "Presupuesto promedio: ",
      number: getAvgBudget(campaigns),
    },
    {
      text: "CPM promedio",
      number: getAvgCPM(campaigns),
    },
  ];

  const listCampaignsByState = [
    {
      text: "Pendiente: ",
      number: getListByState(campaigns, "earring"),
    },
    {
      text: "Activo: ",
      number: getListByState(campaigns, "active"),
    },
    {
      text: "Pausado: ",
      number: getListByState(campaigns, "slow"),
    },
    {
      text: "Inactivo: ",
      number: getListByState(campaigns, "inactive"),
    },
    {
      text: "Finalizado: ",
      number: getListByState(campaigns, "Finalized"),
    },
  ];

  const goToDetailPage = (camp, original) => {
    setCurrentCampaign(camp);
    console.log("camp ", camp);
    const category = camp.mediaCategories;
    console.log("categ ", category);
    setInfoCampaign([
      {
        text: "Estado: ",
        number: camp.delivery.name,
      },
      {
        text: "Presupuesto: ",
        number: camp.budget,
      },
      {
        text: "Recompensa: ",
        number: camp.reward,
      },
      {
        text: "Inscripcion: ",
        number: camp.inscription,
      },
      {
        text: "Fecha de Inicio: ",
        number: camp.date.start,
      },
      {
        text: "Fecha de Finalización: ",
        number: camp.date.end,
      },
    ]);
    setCategories(
      category.map((categ) => ({
        name: categ.name,
        operador: categ.operador,
        quantity: categ.quantity,
        uid: categ.uid,
      }))
    );
    setHashtags(
      camp.hastags.map((hash) => ({
        text: hash.name,
        number: "",
      }))
    );
    setCoords(
      camp.locationList.map((data) => ({
        text: data.locationList,
        number: "",
      }))
    );
    setWindow(0);
  };

  const goToEditPage = (id, name, createAt) => {
    let edit = {
      id,
      name,
      createAt,
    };
    props._setEdit(edit);

    window.$.fancybox.open({
      src: "#new-category-modal",
      //type: 'modal',
      opts: {
        modal: true,
        afterClose: () => props._clearEdit(),
      },
    });
  };

  //**************************Age Graph************************* */
  function percentAge(range) {
    let count = 0;

    let percentage = 0;
    let array = userAgeArray;
    //let array = [16,17,18,19,20,21,22,23,24,25,16,17,18,19,20,21,22,23,24,25,
    //   25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,70,80]
    let max = array.length;
    if (range === 0) {
      for (let x = 0; x < max; x++) {
        if (array[x] >= 16 && array[x] <= 17) {
          count++;
        }
      }
      percentage = parseInt((100 / max) * count);
      return percentage;
    }
    if (range === 1) {
      for (let x = 0; x < max; x++) {
        if (array[x] >= 18 && array[x] <= 24) {
          count++;
        }
      }
      percentage = parseInt((100 / max) * count);
      return percentage;
    }
    if (range === 2) {
      for (let x = 0; x < max; x++) {
        if (array[x] >= 25 && array[x] <= 29) {
          count++;
        }
      }
      percentage = parseInt((100 / max) * count);
      return percentage;
    }
    if (range === 3) {
      for (let x = 0; x < max; x++) {
        if (array[x] >= 30 && array[x] <= 35) {
          count++;
        }
      }
      percentage = parseInt((100 / max) * count);
      return percentage;
    }
    if (range === 4) {
      for (let x = 0; x < max; x++) {
        if (array[x] >= 36) {
          count++;
        }
      }
      percentage = parseInt((100 / max) * count);
      return percentage;
    }
  }
  //  const dataUsersAge = {
  //     range: [
  //       {ageRange: "16 - 17 años",color:'color1'},
  //       {ageRange: '18 - 24 años',color:'color2'},
  //       {ageRange: '25 - 29 años',color:'color3'},
  //       {ageRange: '30 - 35 años',color:'color4'},
  //       {ageRange: '36 - + años',color:'color5'}],
  //     porcentage: [percentAge(0), percentAge(1), percentAge(2), percentAge(3), percentAge(4)],
  //     gender: [
  //       {
  //         women: 581,
  //         mens: 581,
  //         color:'color1'
  //       },
  //       {
  //         women: 581,
  //         mens: 581,
  //         color:'color2'
  //       },
  //       {
  //         women: 581,
  //         mens: 581,
  //         color:'color3'
  //       },
  //       {
  //         women: 581,
  //         mens: 581,
  //         color:'color4'
  //       },
  //       {
  //         women: 581,
  //         mens: 581,
  //         color:'color5'
  //       }
  //     ]
  //   }

  const changeWindow = (number) => {
    setWindow(number);
  };
  function getDate(initDate) {
    var initial = initDate.split(/\//);
    var date = initial[1] + "/" + initial[0] + "/" + initial[2];
    console.log("fechassss: ", date);
    return date;
  }

  //*************************Age Graph********************** */
  const [columns, setColumns] = useState([
    {
      name: "Nombre de reto asociado",
      selector: "name",
      sortable: true,
      minWidth: "200px",
      style: {
        color: "#02025b",
      },
      cell: (row) => <DetailCol text={row.name} Camp={row} />,
    },
    {
      name: "Entrega",
      selector: "delivery",
      sortable: true,
      center: true,
      style: {
        backgroundColor: "#E3E4E6",
        fontWeight: "bold",
      },
      type: "text",
      cell: (row) => (
        <DeliveryCol
          text={row.delivery.name}
          className={row.delivery.className}
        />
      ),
    },
    {
      name: "Presupuesto",
      selector: "budget",
      sortable: true,
      center: true,
      style: {
        fontWeight: "bold",
      },
    },
    {
      name: "Alcance",
      selector: "scope",
      sortable: true,
      center: true,
      style: {
        backgroundColor: "#E3E4E6",
        fontWeight: "bold",
      },
    },
    {
      name: "Rango de fecha",
      selector: "date",
      sortable: true,
      center: true,
      minWidth: "250px",
      style: {
        fontWeight: "bold",
      },
      type: "text",
      cell: (row) => (
        <DateRange
          start={new Date(getDate(row.date.start))}
          end={new Date(getDate(row.date.end))}
          format="dd/mm/yyyy"
        />
      ),
    },
    {
      name: "Medio",
      selector: "source",
      sortable: true,
      center: true,
      style: {
        fontWeight: "bold",
        backgroundColor: "#E3E4E6",
      },
      cell: (row) => (
        <div>
          {row.source.map((rowa) => (
            <img className="source-img" src={rowa} alt="" />
          ))}
        </div>
      ),
    },
    {
      name: "Inscripcion",
      selector: "inscription",
      sortable: true,
      center: true,
      style: {
        fontWeight: "bold",
      },
    },
  ]);

  let profilesCount = parseInt(countProfiles) + parseInt(countFanPages);

  const filteredData = campaigns.map((campaign) => ({
    campOriginal: campaign,
    name: campaign.name,
    delivery: getClassStyleByStatus(campaign.state),
    budget: `Q ${campaign.budget ? campaign.budget : 0.0}`,
    scope: campaign?.reach,
    date: {
      start: campaign.initDate,
      end: campaign.endDate,
    },
    source: getLogosListByNames(campaign.socialNetworks),
    inscription: campaign.CPM,
    hastags: campaign.hastags,
    description: campaign.description,
    logo: campaign.logo,
    postImages: campaign.postImages,
    locationList: campaign.locationList,
    mediaCategories: campaign.mediaCategories,
    id: campaign.id,
    ageValue: campaign.ageValue,
    advertiser: campaign.advertiser,
    CPM: campaign.CPM,
    budget: campaign.budget,
    activity: campaign.activity,
    totalAlcance: campaign.totalAlcance,
    totalInteraction: campaign.totalInteraction,
    totalProfit: campaign.totalProfit,
    totalRemainder: campaign.totalRemainder,
    totalCreatividad: campaign.totalCreatividad,
    state: campaign.state,
    slogan: campaign.slogan,
    nonMediaCategories: campaign.nonMediaCategories,
    socialStatusValue: campaign.socialStatusValue,
    initDate: campaign.initDate,
    endDate: campaign.endDate,
  }));
  if (window === 0) {
    return (
      <Info
        refresh={refresh}
        setRefresh={(dat) => setRefresh(dat)}
        setWindow={(data) => setWindow(data)}
        currentCampaign={currentCampaign}
        infoCampaign={infoCampaign}
        categories={categories}
        hashtags={hashtags}
        coords={coords}
        changeWindow={changeWindow}
      />
    );
  }

  const handleFilterTextChange = (value) => {
     console.log('FILTER',value);
    changeFilteredData(value)
}

const changeFilteredData= (filterText) =>  {
  setFCamp(filteredNormal.filter(item => 
    item.name.toLowerCase().includes(filterText.toLowerCase()) ||
    item.delivery?.name?.toLowerCase().includes(filterText.toLowerCase()) ||
    item.email?.toLowerCase().includes(filterText.toLowerCase()))
  )
  
}


  const sort= (rows,selector,direction)=>{
    if(selector==='date'){
        console.log('DIRECTION',direction)
        if(direction==='asc'){
          setFCamp(fCamp.reverse())
        }else{
          setFCamp(filteredNormal)
        }
        
        return orderBy(rows, direction)
    }else{
        return orderBy(rows, selector, direction)
    }
  }
 

  
  //let userTask = statusTasks.filter(item => item.userID)
  const userTask = statusTasks.filter(function(ele , pos){
    return statusTasks.indexOf(ele) == pos;
  })
    
  let inscripciones 
  console.log("hola desde aqui", userTask)

  let tareasCompletadas1 = statusTasks.filter(item => item.status === "Aprobado")
  let tareasCompletadas2 = statusTasks.filter(item => item.status === "Finalizado")
  let tareasCompletadas3 = statusTasks.filter(item => item.status === "Acreditado")
  let tareasCompletadas4 = statusTasks.filter(item => item.status ===  "Pagado")
  let sumaTareasCompletadas = tareasCompletadas1.concat(tareasCompletadas2, tareasCompletadas3, tareasCompletadas4)
  console.log("hola desde tareasCompletadas", sumaTareasCompletadas)

  /*Return component*/
  function formatNumber(number) {
    return new Intl.NumberFormat("es-MX").format(number)
  }

  return (
    <div className={"form-dashboard " + (userRol)}>
      <div id="shadow" className="shadow hide"></div>
      <div className="Bar-content">
        <div className="content-menu list">
          <select id="viewSelect" className="select-dashboard fa">
            <option className="fa" value="1">
              {" "}
              &#xf0ce; Vista Resumen
            </option>
           
          </select>
          <DataTableUtils className="container-btn" onChangeFilterText={handleFilterTextChange}>
            <Share className="btn-frist" contacts={utils.myContacts} />
            <ShowColumn
              className="btn-second"
              columns={columns}
              showColumn={handleShowColumnChange}
              onSortChange={handleColumnSortChange}
            />
            <FilterColumn
              className="btn-third"
              columns={columns}
              onFilterColumn={handleFilterPerColumn}
            />
          </DataTableUtils>
          {userRol==='admin'?
          <div className="btnOrange-dash">
            <i onClick={handleClickDropMenu} className="fas fa-plus"></i>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseDropMenu}
            >
              <MenuItem
                onClick={(e) => {
                  setShowModal(true);
                  handleCloseDropMenu();
                }}
              >
                <CheckCircleOutlineIcon />
                Agregar Campaña
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  setShowAdvertiser(true);
                  handleCloseDropMenu();
                }}
              >
                <PersonAddIcon />
                Agregar Anunciante
              </MenuItem>
            </Menu>
          </div>
          : ''
          }
        </div>
      </div>
      <div className="contenedorGrid">
      <div className="row-section container-account grid1">
        <div className="content-section container-title ">
          <div className="row-info">
            <div className="circle" />
            <p className="title-text"> Resumen General</p>
            
          </div>
          {/* <select className='select-dashboard'>
                        <option value='1'>
                            &#xf0ce; Últimos 30 dias
                        </option>
                    </select> */}
        </div>
        <div className="content-section cards">
          <InfoBox
            className="box"
            title={"Campañas activas"}
            number={activeCampaigns}
          />
          {userRol === "admin" ? 
          <InfoBox
            className="box"
            title={"Perfiles"}
            number={socialProfilesCount}
          />:''}
          {userRol==="admin" ? 
          <InfoBox
            className="box"
            title={"Impacto"}
            number={formatNumber(followersReach)}
            description={
              "Impacto promedio " +
              (followersAvg)
            }
          />:''}
          {userRol==="admin"? 
          <InfoBox className="box" title={"Truers totales"} number={formatNumber(userCount)} />
          :''}
        </div>
      </div>
      <div className="row-section grid2">
        <div className="content-section section-one-resume">
          {userRol === "admin" ? 
          <div className="boxStatusTruers">
            <div className="row-info">
              <div className="circle" />
              <p className="title-text"> Status de Truers</p>
            </div>
            <TargetContent
              listGeneralStatistics={generalStats}
              nameClass="targetContent"
            />
          </div>:''}
          <div className="boxCampStatus">
            <div className="row-info">
              <div className="circle" />
              <p className="title-text"> Campañas por Estado</p>
            </div>
            <TargetContent
              listGeneralStatistics={campaignsState}
              nameClass="targetContent-small"
            />
          </div>
        </div>
      </div>
      <div className="row-section row-social-network grid3">
        <div className="row-info">
          <div className="circle" />
          <p className="title-text"> Campañas por red social</p>
        </div>
        <div className="content-section social-network">
          <ImageBox
            className="rectangle-img"
            image={Logo_facebook}
            num={campaingFacebook}
          />
          <ImageBox
            className="rectangle-img"
            image={Logo_instagram}
            num={campaingInstagram}
          />
          <ImageBox
            className="rectangle-img"
            image={Logo_tiktok}
            num={campaingTikTok}
          />
          <ImageBox
            className="rectangle-img"
            image={logo_whatsapp}
            num={0}
          />
          {/* <ImageBox
            className="rectangle-img"
            image={Logo_tiktok}
            num={campaingTikTok}
          /> */}
          {/* <ImageBox className="rectangle-img" image={logo_linkedin} num={campaingLinkedin} /> */}
        </div>
      </div>
      <div className="row-section row-line-graph grid4">
        <div className="content-section">
          <div className="boxGrid4">
            <div className="row-info">
              <div className="circle" />
              <p className="title-text"> Tareas por status</p>
            </div>
            <TargetContent
              listGeneralStatistics={postByState}
              nameClass="targetContent-small"
            />
          </div>
          <div className="boxGrid4">
            <div className="row-info row-content-statistics">
              <div className="circle" />
              <p className="title-text"> Actividad de Truers</p>
            </div>
            <GraphBox nameClass="rectangle-graph">
              <div className="graphGeneralStatistics">
                <AreaChart 
                  array={graphTask}
                  sumaTareasCompletadas={sumaTareasCompletadas}
                />
              </div>
            </GraphBox>
          </div>
        </div>
      </div>
      {userRol === "admin" ? 
      <div className="row-section container-account2 grid5">
        <div className="row-info">
          <div className="circle" />
          <p className="title-text">Estado de Perfiles</p>
        </div>
        <div className="content-section cards2">
          <InfoBox
            title={"Perfiles Facebook"}
            number={
              activeFbProfiles +
              " / " +
              PendienteFbProfiles
            }
            description="Aprobados / Pendientes"
          />
          <InfoBox
            title={"Perfiles Instagram"}
            number={
              activeIgProfiles +
              " / " +
              PendienteIgProfiles
            }
            description="Aprobados / Pendientes"
          />
          <InfoBox
            title={"Perfiles Tiktok"}
            number={
              activeTkProfiles +
              " / " +
              PendienteTkProfiles
            }
            description="Aprobados / Pendientes"
          />
          <InfoBox
            title={"Perfiles WhatsApp"}
            number={
              0 +
              " / " +
              0
            }
            description="Aprobados / Pendientes"
          />
          
          
        </div>
      </div>:''}
      <div className="row-section row-graph-cricle grid6">
        <div className="content-section">
          <div className="boxGrid6">
            <div className="row-info">
              <div className="circle" />
              <p className="title-text"> Truers por género</p>
            </div>
            <GraphBox nameClass="rectangle-graph-meddium">
              <div className="userByGender">
                <GenderChart 
                  generalGenderState={generalGenderState} 
                  userRol={userRol}
                  onlyFacebook={onlyFacebook}
                  onlyInstagram={onlyInstagram}
                  onlyTikTok={onlyTikTok}
                />
              </div>
            </GraphBox>
          </div>
          <div className="boxGrid6">
            <div className="row-info">
              <div className="circle" />
              <p className="title-text"> Truers por edad</p>
            </div>
            {console.log('dataHeadersAgeGraph',dataHeadersAgeGraph)}
            {console.log('dataGraphUsers',dataGraphUsers)}
            <GraphBox nameClass="rectangle-graph-meddium">
              <div className="userByState">
                <PieChart
                  data={dataHeadersAgeGraph}
                  dataUsersAge={dataGraphUsers}
                  width={170}
                  height={170}
                  userRol={userRol}
                  plugins={[ChartDataLabels]}
                />
              </div>
              .
            </GraphBox>
          </div>
          {userRol==="admin" ? 
          <div className="boxGrid6">
            <div className="row-info">
              <div className="circle" />
              <p className="title-text"> Top 5 hashtag</p>
            </div>
            <GraphBox nameClass="rectangle-graph-meddium">
              <div className="hashtagTop">
              <TargetContent
              listGeneralStatistics={ listHashtags}
              nameClass="targetContent-small"
            />
             
              </div>
            </GraphBox>
          </div>:""}
        </div>
      </div>
      <div className="row-section row-active-campaigns grid7">
        <div className="content-section">
         
          <div className="boxGrid7" style={{marginBottom: "60px"}}>
            <div className="row-info campaing-text">
              <div className="circle" />
              <p className="title-text "> Campañas activas por red social</p>
            </div>
            <GraphBox nameClass="rectangle-graph">
              <div className="doughnutChart">
                <Doughnut 
                  className="canvas" 
                  data={donGraphUsers} 
                  plugins={[ChartDataLabels]}
                />
              </div>
              <div className="info">
                <div>
                  <ImageBox
                    className="rectangle-img-info-small"
                    image={Logo_facebook}
                    text1={facebookReachAvg}
                    text2={facebookInteractionAvg}
                  />
                  <ImageBox
                    className="rectangle-img-info-small"
                    image={Logo_instagram}
                    text1={instagramReachAvg}
                    text2={instagramInteractionAvg}
                  />
                  <ImageBox
                    className="rectangle-img-info-small"
                    image={Logo_tiktok}
                    text1={tiktokInteractionAvg}
                    text2={tiktokReachAvg}
                  />
                  {/* <ImageBox
                    className="rectangle-img-info-small"
                    image={logo_linkedin}
                    text1="Alcance promedio: 1,111"
                    text2="Tasa de interaccion promedio: 1.17"
                  /> */}
                </div>
              </div>
            </GraphBox>
          </div>
          </div>
        </div>
      </div>
      {/* <div className="row-section  row-last-table">
        <div className="content-section">
          <div className="row-info">
            <div className="circle" />
            <p className="title-text"> Rendimiento de la cuenta</p>
          </div>
        </div>
        <div className="content-section last-table">
          <ReactDataTable
            columns={columns}
            customStyles={customStyles}
            data={fCamp}
            persistTableHead
            sortFunction={sort}
            // pagination
            noHeader
          />
        </div>
      </div> */}
      {showModal ? (
        <NewCampaign title="Crear nueva campaña" showModal={setShowModal} />
      ) : (
        <div />
      )}
      {showAdvertiser ? (
        <NewAdvertiser
          title="Crear nuevo anunciante"
          showAdvertiser={setShowAdvertiser}
          userRol={"admin"}
        />
      ) : (
        <div />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  _showLoader: (show) => dispatch(showLoader(show)),
});

const mapStateToProps = (state) => ({
  isLoading: state.app.isLoading,
  myUser: state.users.myUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
