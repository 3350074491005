import React from 'react';
import Input from '@material-ui/core/Input';

const InputModal = ({title, placeholder, value, change, type}) => {
  
  const tipo = type ? type : 'text'

  return(
    <>
      <p>{title}</p>
      <Input placeholder={placeholder} value={value} onChange={e => change(e.target.value)} className="w-90" type={tipo} />
      <div className="mb-15" />
    </>
  )
}

export default InputModal