import { useState, useEffect } from 'react'
import ReactDataTable from '@easygosanet/react-datatable'
import GalleryPost from './galleryPost'
import * as APILI from "../../../helpers/apiLI";
import * as STORAGE from "helpers/storageLI";
import StarsRater from 'components/starsRater';
import Swal from "sweetalert2/dist/sweetalert2.js";
import CustomTask from './customTask'
import CommentSubTask from './Comment'
import { ConsoleLogger } from '@aws-amplify/core';
import './customTask/modal.scss'
//LoaderReducer
import { showLoader } from 'redux/actions/loader.actions';
import {  useDispatch, connect } from 'react-redux';


const Subtasks = ({taskPage, subtasks, setRefresh, refresh, currentCampaign, setrefreshTable, refreshTable,refreshSubtasks,setRefreshSubtasks}) => {
const [dataSubtasks, setDataSubTasks] = useState([])
const [readWebHook,setReadWebHook] = useState(false)
  const {
    userID,
    campaignID,
  } = taskPage;

  const [campaignData, setCampaignData] = useState({
    name: "",
    reward: 0,
    initDate: "",
    endDate: "",
    description: "",
    activity: [],
    hastags: [],
    socialNetworks: [],
    locationList: [],
    postImages: [],
  });

  const [categoryTags, setCategoryTags] = useState([]);
  const [postImages, setPostImages] = useState([]);
  const [totalReach, setTotalReach] = useState(0);
  const [postImg, setPostImg] = useState([]);
  const [matchPosts, setMatchPosts] = useState([]);
  const [find, setFind] = useState();
  const [aprovved, setAprovved] = useState(false);
  const [currentSubTask, setCurrentSubTask] = useState(false);
  const [showModal, setShowModal] = useState(false)
  const [showModal2, setShowModal2] = useState(false)
  const [currentFormat, setCurrentFormat] = useState({})
  const [currentComment, setCurrentComment] = useState({})
  const dispatch = useDispatch()

  useEffect(async () => {
    const body = {
      id: campaignID,
    };
    await APILI.getCampaignById(body)
      .then((res) => {
        if (res.data.campaign[0].postImages.length > 0) {
          let arrayImg = [];
          res.data.campaign[0].postImages.forEach((element) => {
            if (element.name) {
              STORAGE.getUrlImage(element.name).then((url) => {
                arrayImg.push({
                  name: element.name,
                  link: url.toString(),
                });
              });
            }
          });
          setPostImg(arrayImg);
        }
        setCampaignData(res.data.campaign[0]);
        saveStateData(
          res.data.campaign[0].mediaCategories,
          res.data.campaign[0].postImages
        );
        
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  const getSubTasks =  async (taskID) => {
    dispatch(showLoader(true))
    const body = {
        taskID: taskID,
        };

       let res = await APILI.getSubTasksByTask(body)
            let subTasks = res.data.subTasks
            setDataSubTasks(subTasks)
            dispatch(showLoader(false))
    };

  useEffect(() => {
    getSubTasks(taskPage.id)
  }, [refreshSubtasks])
  
  const saveStateData = (data1, data2) => {
    setCategoryTags(data1);
    setPostImages(data2);
  };

  
  const Status = () => (
    <div className='status'>Pendiente</div>
  )

  const addComment = (data) => {
    setCurrentComment(data)
    setShowModal2(true)
  }

  const ButtonComment = (data) => (
    <button 
      className='btnComment'
      onClick={() => addComment(data)}
    >
      Comentario
    </button>
  )
  
  const editCustomTask = (data) => {
    setCurrentFormat(data)
    setShowModal(true)
  }   
 
  const ButtonCustom = (data) => (
    <button 
      className='btnCustom' 
      onClick={() =>  editCustomTask(data)}>
      Personalizar
      </button>
  )

  const updateTaskStatus = async (taskID, taskStatus) => {
    const update = {
      id: taskID,
      status: taskStatus,
    };
    APILI.updateTaskStatusByID(update)
      .then((res) => {
        
        setTimeout(() => {
          setRefresh(!refresh)
          setrefreshTable(!refreshTable)
          setRefreshSubtasks(!refreshSubtasks)
        }, 2000);
        /* console.log('response',res); */
      })
      .catch((err) => {
        console.log("err", err);
      });
      
  }; 

  const updateStatusSubTask = async (subTaskID, statusSubtask, taskID, statusTask) => {
    const body = {
      id: subTaskID,
      status:statusSubtask,
    };
    /* console.log("bodySubTask", body); */
    await APILI.updateSubTaskStatus(body)
      .then((res) => {
        setTimeout(() => {
          setRefresh(!refresh);
        }, 2000);
        
        if(taskID!='' && statusTask!='') {
          updateTaskStatus(taskID,statusTask)
        }
        
        /* console.log('response',res); */
      })
      .catch((err) => {
        console.log('error',err);
      });    
       
  };

  function findHastags(resCampain, body){
    var hola = false;
     body.hashtag.forEach( hastagE =>{
        resCampain.hastags.forEach( hastagC =>{
        //console.log('has',hastagE.name ,'cam',hastagC.name);
        if(hastagE.name.toLowerCase()  == hastagC.name.toLowerCase() ){
         // console.log("macth");
           hola = true
        };
       });
    });
    return hola
  }
let count =0;
  const validateArray = (array,subtask) =>{
    if(array.length==1)
    {
      updateStatusSubTask(subtask.id, 'En revisión', subtask.taskID, 'En progreso')
    }
  }
  function getPostdataInstagram (response, influencerID ,temporalSubTask,task,resCampain,data, type, type2, subTask) {
        APILI.getPostsBySubTask({"subtaskID":subTask.id})
         .then((res) => {
          if(res.data.count == 1 && (subTask.status=='Aprobado' || subTask.status=='En revisión'|| subTask.status=='Finalizado'))
          {
            setMatchPosts(res.data.influencerPosts)
            setFind(true)
          }
          else if(res.data.count == 0 || subTask.status=='Rejected')
          {
            setFind(false)
            let arrayPosts = []
    var find = false;
  response.instagram_business_account.media.data.forEach((element) => {
    
// "fields=id%2Cmedia_type%2Cmedia_url%2Cusername%2Ctimestamp%2Ccaption%2Ccomments_count%2Clike_count%2Cis_comment_enabled%2Cmedia_product_type%2Cpermalink%2Ccomments%7Bid%2Clike_count%2Cmedia%2Ctext%2Cuser%2Cusername%2Ctimestamp%7D%2Cinsights.metric(impressions%2Cengagement%2Creach%2Csaved)&access_token=
        fetch(`https://graph.facebook.com/v12.0/${element.id}?fields=id%2Cmedia_type%2Cmedia_url%2Cusername%2Ctimestamp%2Ccaption%2Ccomments_count%2Clike_count%2Cis_comment_enabled%2Cmedia_product_type%2Cpermalink%2Ccomments%7Bid%2Clike_count%2Cmedia%2Ctext%2Cuser%2Cusername%2Ctimestamp%7D%2Cinsights.metric(impressions%2Cengagement%2Creach%2Csaved)&access_token=${temporalSubTask.tokenRedSocial}`)
        .then((res) => res.json())
        .then( (response1) => {    
          /* console.log('response1: ', response1) */
            let hashtagWord =''
            let startHashTag = false
            let hashtag = []
            const caption= response1.caption
            if(caption===undefined)
            {
                hashtag.push({"name":"sin hashtag"})
            }
            else{ 
                for(let x=0; x<=caption.length-1; x++){
                    if(caption[x]==='#')
                    startHashTag=true
                    if(startHashTag===true && caption[x]!==' ')
                        hashtagWord= hashtagWord.concat(caption[x]) 
                if(caption[x]===' ' && startHashTag===true ||  x===caption.length-1 && startHashTag===true){
                    startHashTag=false
                    hashtag.push({"name":hashtagWord.toLowerCase()})
                    hashtagWord=''
                    }
                }
            }
            let react=0
            if(response1.comments_count && response1.like_count){
                react= response1.comments_count + response1.like_count
            }
            let objecto = response1.insights;
            let body={
                "influencerid": influencerID,
                "name":response.name,
                'idPost':element.id,
                "createdAt": response1.timestamp,
                "message":   response1.caption, 
                "id": response1.id,
                "urlPost": response1.permalink, 
                "socialmedia":"Instagram",
                "picture": response1.media_url, 
                "activity": response1.media_type,
                "comments_count": response1.comments_count,
                "likes_count": response1.like_count,
                "hashtag": hashtag,
                "campaign": task.campaignID,
                'taskID' :task.id,
                'datainsights' : objecto?.data,
                "insights": {
                    "impressions": response1?.insights?.data[0]?.values[0]?.value ? response1?.insights?.data[0]?.values[0]?.value :response1?.insights?.impressions ,
                    "engagement": response1?.insights?.data[1]?.values[0]?.value ? response1?.insights?.data[1]?.values[0]?.value : response1?.insights?.engagement,
                    "reach":response1?.insights?.data[2]?.values[0]?.value ? response1?.insights?.data[2]?.values[0]?.value : response1?.insights?.reach,
                    "saved":response1?.insights?.data[3]?.values[0]?.value ? response1?.insights?.data[3]?.values[0]?.value : response1?.insights?.saved,
                },  
                "comments": response1.comments,
                "subtaskID": subTask.id
            }
            /* console.log('body',body); */
            let validacion = findHastags(resCampain,body);
            if(validacion){
                /* APILI.createPosts(body)
                .then(res => {
                console.log(res) })
                .catch(err => {
                console.log(err)
                }) */
                if(body.activity===type)
                {
                  arrayPosts.push(body)
                  validateArray([...arrayPosts],subTask) 
                }
            }
            setMatchPosts([...arrayPosts])
        })
        });
          }
        })
        .catch(err => {
          console.log('error', err)
        })
        dispatch(showLoader(false))
}

 function getPostdataFacebook (post,influencerID,temporalSubTask,task,resCampain, data, type,type2, subTask) {
        APILI.getPostsBySubTask({"subtaskID":subTask.id})
       .then((res) => {
        if(res.data.count == 1 && (subTask.status=='Aprobado' || subTask.status=='En revisión'))
        {
          setMatchPosts(res.data.influencerPosts)
          setFind(true)
        }
        else if(res.data.count == 0 || subTask.status=='Rejected')
        {
          setFind(false)
          let  arrayPosts=[];
          post.posts.data.forEach(( element)  => {
              /* console.log('ELEMENT', element) */
              fetch(`https://graph.facebook.com/v12.0/${element.id}/insights?metric=post_engaged_users%2Cpost_impressions%2Cpost_impressions_unique&access_token=${temporalSubTask.tokenRedSocial}`)
               .then(response => response.json())
               .then( response =>   {
                  //console.log('body', response);
                   let hashtagWord =''
                   let startHashTag = false
                   let hashtag =[]
                   if(element.message){
                      const caption= element.message
                   
                   for(let x=0; x<=caption.length-1; x++){
                       if(caption[x]==='#')
                       startHashTag=true
                       if(startHashTag===true && caption[x]!==' ')
                           hashtagWord= hashtagWord.concat(caption[x]) 
                   if(caption[x]===' ' && startHashTag===true ||  x===caption.length-1 && startHashTag===true){
                       startHashTag=false
                       hashtag.push({"name":hashtagWord.toLowerCase()})
                       hashtagWord=''
                       }
                   } 
                   }
                   else{
                       hashtag=[]
                   }
        
                   let reactions=0
                   if(element.reactions || element.comments){
                       if(element.reactions && element.comments){
                           reactions= element.reactions.data.length + element.comments.data.length
                       }
                      else if(element.reactions){
                          reactions= element.reactions.data.length
                      }
                      else if(element.comments){
                          reactions= element.comments.data.length
                      }
                   }
                   
                  let body={
                  "influencerid": influencerID,
                  "name":element.name ? element.name :"",
                  "createdAt": element.created_time,
                  "message":   element.message ? element.message : '', 
                  'idPost':element.id,
                  "id": element.id,
                  "urlPost":  element.permalink_url, 
                  "socialmedia":"Facebook",
                  "picture": element.attachments.data[0].type === 'video_inline' ? element.attachments.data[0].media.source  : element.full_picture, 
                   "activity": element.status_type === 'added_video' ? 'VIDEO': 'IMAGE' ,
                  "activitypost": element.status_type,
                  "reactions": reactions,
                  "comments_count": element.comments ? element.comments.data.length : 0,
                  "likes_count": element.reactions ? element.reactions.data.length : 0,
                  "hashtag": hashtag,
                  "campaign": task.campaignID,
                  'task' :task.id,
                  "insights": {
                      "engagement":response.data[0].name=='post_engaged_users' ? response.data[0].values[0].value : 0,
                      "impressions":response.data[1].name=='post_impressions' ? response.data[1].values[0].value : 0,
                      "reach":response.data[2].name=='post_impressions_unique' ? response.data[2].values[0].value : 0,
                      "saved": element.shares? element.shares.count : 0},  
                  "comments": element.comments ? element.comments.data : [],
                  "subtaskID": subTask.id
                      }
      
                   let validacion = findHastags(resCampain,body);
                   if(validacion){
                       console.log('matchPost', body)
        
                       /* APILI.createPosts(body)
                       .then(res => {
                       console.log(res) })
                       .catch(err => {
                       console.log(err)
                       }) */
                       if(body.activity===type)
                        {
                          arrayPosts.push(body)
                          validateArray([...arrayPosts],subTask) 
                        }
                   }
                   setMatchPosts([...arrayPosts])
               })
               .catch(err => console.error(err));
           });
        }
      })
        
  /*  setTaskPage(data)
  setShowTask(true) */
  dispatch(showLoader(false))
}
function getPostdataCustom (subTask) {
  APILI.getPostsBySubTask({"subtaskID":subTask.id})
   .then((res) => {
    if(res.data.count == 1 /* && (subTask.status=='Aprobado' || subTask.status=='En revisión'|| subTask.status=='Finalizado') */)
    {
      setMatchPosts(res.data.influencerPosts)
      setFind(true)
    }
  })
  .catch(err => {
    console.log('error', err)
  })
  dispatch(showLoader(false))
}




const getPostsAndInfo = (subTask, data) => {
  setMatchPosts([])
  dispatch(showLoader(true))
  console.log('dataSubTask', data)
  console.log('subTask', subTask)
  setCurrentSubTask(subTask)
  if(subTask.status=='Aprobado' || subTask.status == 'Finalizado')
  {
    setAprovved(true)
  }
  else
  {
    setAprovved(false)
  }

  let type=""
  let type2=""
  if(subTask.activity=="Post Video")
  {
    type = 'VIDEO'
  }
  else if(subTask.activity=="Post Estático")
  {
    type = 'IMAGE'
  }
  /* else if(subTask.activity=="Post Carrusel")
  {
    type = 'CAROUSEL_ALBUM'
  } */
  if(data.socialmedia==='Instagram'){
    console.log("hola desde instagram");
    
    if(data?.temporalSubTask?.tokenRedSocial)
    {
      fetch(`https://graph.facebook.com/v12.0/me?fields=id%2Cname%2Caccess_token%2Cinstagram_business_account%7Bid%2Cname%2C%20followers_count%2Cfollows_count%2Cmedia_count%2C%20profile_picture_url%2Cusername%2Cmedia%7D&access_token=${data.temporalSubTask.tokenRedSocial}`)
    .then((res) => res.json())
    .then((response) => {
        console.log('RESInst',response)
        getPostdataInstagram(response, data.userID,data.temporalSubTask,data,currentCampaign,data, type, type2, subTask)
      })
    }
    else
    {
      getPostdataCustom(subTask)
    }
    
}
  else if(data.socialmedia=="Facebook"){
    console.log("hola desde facebook");
    fetch(`https://graph.facebook.com/v12.0/${data.temporalSubTask.idPaginaSocial}?fields=posts%7Breactions%2Cmessage%2Cmessage_tags%2Cfull_picture%2Ccreated_time%2Ccomments%2Cpermalink_url%2Cshares%2Cstatus_type%2Cvideo_buying_eligibility%2Cattachments%7D%2Cname&access_token=${data.temporalSubTask.tokenRedSocial}`)
    .then((res) => res.json())
    .then((response) => {
        console.log('RESFB',response)
        getPostdataFacebook(response, data.userID,data.temporalSubTask,data,currentCampaign, data, type, type2, subTask)
    })
}
else if(data.socialmedia=="Tiktok"){
  console.log("hola desde tiktok");
  getPostdataCustom(subTask)
}

}

const ApprovePost = (subTask)=>{
    Swal.fire({
      title: 'Aprobación',
      text: "¿Está seguro de que desea aprobar la publicación?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#57D4FD',
      cancelButtonColor: '#80949B',
      confirmButtonText: 'Aprobar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        updateStatusSubTask(subTask.id, 'Aprobado')
        setTimeout(() => {
          setRefresh(!refresh)
          setRefreshSubtasks(!refreshSubtasks)
          getPostsAndInfo(currentSubTask, taskPage)
        }, 2000);
      }
    })
  }

  const DennyPost = (subTask)=>{
    Swal.fire({
      title: 'Aprobación',
      text: "¿Está seguro de que desea rechazar la publicación?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#57D4FD',
      cancelButtonColor: '#80949B',
      confirmButtonText: 'Rechazar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        updateStatusSubTask(subTask.id, 'Rejected')
      }
    })
  }
const DetailCol = ({ text, data }) => (
  <div className="firstColl w-100">
      <div className="icon-container icon-container1">
          <i onClick={() => getPostsAndInfo(data, taskPage)}  class="fas fa-eye"></i>
          {/* <i className="fas fa-edit"></i>
            <i class="fas fa-trash"></i>
            <i class="fab fa-telegram-plane"></i>
            <i class="fas fa-ellipsis-v"></i> */}
      </div>
      <p className="name-fc">
          {text}
      </p>
  </div>
)
  const [columns, setColumns] = useState([
    {
      name: 'Subtarea',
      selector: 'name',
      sortable: true,
      minWidth: '200px',
      cell: (row) => 
      <DetailCol 
      text={row.activity} 
      data={row} 
      />,
    },
    {
      name: 'Estado',
      selector: 'state',
      sortable: false,
      center: true,
      type: 'text',
      /* cell: (row) => (
          <Status/>
      ) */
      cell:(row)=><div className={"deliverysubtask" + (row.status)}>{row.status}</div>
    },
    {
      name: 'Comentario',
      selector: 'comment',
      sortable: false,
      center: true,
      cell: (row) => (
        <ButtonComment data={row}/>
      ) 
    },
    {
      name: 'Personalizar Post',
      selector: 'scope',
      sortable: false,
      center: true,
      cell: (row) => (
        <ButtonCustom
          data={row}
        />
      )
    },
])

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        color: "#000",
        fontSize: "1rem",
        textAlign: "center",
        justifyContent: "center",
        backgroundColor: "#f7f7f7",
        "&:nth-of-type(1)":{
          maxWidth: "4rem",
        },
        "&:nth-of-type(2n)": {
          backgroundColor: "#E3E4E6",
          borderRadius: "10px 10px 0 0",
        },
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        backgroundColor: "#f7f7f7",
        "&:nth-of-type(1)": {
          justifyContent: "left",
        },
        "&:nth-of-type(2n)": {
          backgroundColor: "#E3E4E6",
        },
      },
    },
  };

  const updateRating = async (id, rate) =>  {  
    const body={
        "id": id,
        "rating": rate
    }
    APILI.updateSubTaskRating(body)
   .then(res => {
      console.log('resUpdate', res)
        setRefresh(!refresh)
    }).catch(err => {
        console.log(err)
    } )
  }

  return(
    <>
    <div className='containerSubtasks'>
      <section className='boxTable'>
      {/* <h2>Subtareas Aprobadas {subtasks.approved}/{subtasks.count}</h2> */}
        <ReactDataTable
          columns={columns}
          data={dataSubtasks}
          customStyles={customStyles}
          persistTableHead
          noHeader
          pagination
          paginationComponentOptions={{
            rowsPerPageText: 'Elementos por página',
            rangeSeparatorText: 'de',
          }}
          noDataComponent={"No hay datos para mostrar"}
        />
      </section>
      <section className='boxPosts'>
        <h2>Descripción Subtarea</h2>
        <GalleryPost
          find={find}
          className="galleryContent galleryPost"
          socialmedia={campaignData.socialNetworks}
          hastags={campaignData.hastags}
          totalReach={(data) => setTotalReach(isNaN(data) ? 0 : data)}
          matchPosts={matchPosts}
          setMatchPosts={setMatchPosts}
          aprovved={aprovved}
          setFind={setFind}
          currentSubTask={currentSubTask}
          getPostsAndInfo={getPostsAndInfo}
          taskPage={taskPage}
        />
        {(currentSubTask ==false) ? (
          <></>
        ):(find==true)&&
        (
          <div className='validationSubtask'>
          <div className='validarSubtarea'>
            <p>Aprobar Subtarea</p>
            <div className='ico'>
              <button className='Btn12' onClick={e => ApprovePost(currentSubTask)}><i class="fa fa-thumbs-up thumbsUP"></i></button>
              <button className='Btn12' onClick={e => DennyPost(currentSubTask)}><i class="fa fa-thumbs-down thumbsDOWN"></i></button>
            </div>
          </div>
          <div className='reatingSubtarea'>
            <p>Rating Subtarea</p>
            <div className='ico'>           
              <StarsRater 
                key={currentSubTask.id}
                className="stars1"
                rating={currentSubTask.rating} 
                id={currentSubTask.id} 
                updateRating={(dato1,dato2) => updateRating(dato1, dato2)} 
              />
            </div>
          </div>
        </div>
        )}
        
      </section>
    </div>
    <div>
    {showModal && 
        <CustomTask 
          showModal={showModal}
          setShowModal={setShowModal}
          currentFormat={currentFormat}
          taskPage={taskPage}
          influencerName={campaignData.name}
          setRefreshSubtasks={setRefreshSubtasks}
          refreshSubtasks={refreshSubtasks}
          setrefreshTable={setrefreshTable}
          refreshTable={refreshTable}
        /> 
      }
    </div>
    <div>
    {showModal2 && 
        <CommentSubTask 
          showModal2={showModal2}
          setShowModal2={setShowModal2}
          currentComment={currentComment}
          refresh={refresh}
          setRefresh={setRefresh}
        /> 
      }
    </div>
    </>
  )
}
const mapDispatchToProps = dispatch => ({
  _showLoader: show => dispatch(showLoader(show))
})

const mapStateToProps = (state) => ({
  isLoading: state.app.isLoading,
  myUser: state.users.myUser
})

export default connect(mapStateToProps, mapDispatchToProps)(Subtasks)