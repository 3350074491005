import React from 'react'

// components
import ConfirmSignUpForm from '../../components/auth/confirm_signup_form'

const ConfirmSignUp = props => (
  <>

    <ConfirmSignUpForm {...props} />
  </>
)

export default ConfirmSignUp
