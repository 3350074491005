import './style.css' 
import pdf from './TerminosyCondiciones.pdf'

const modalConditions = (props) => {
    return (
        <div className="blur-div">
            <div className="moderation-modal  modalPDF">
                <div className="body-modal bodyModal">
                <object
                    data={pdf}
                    type='application/pdf'
                    width='100%'
                    heght='100%'
                    className='contPDF'
                >

                </object>
                </div>
                <div className="footer-Modal">
                    <div className="buttons-Moderation-Modal">
                        <button className={"BtnPoli"} onClick={() => props.setShowModal(false)}>Aceptar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default modalConditions