import React from 'react'
import Lottie from 'react-lottie'
import logo from './assets/logo.png'

import animation from './assets/json/animation.json'

import './assets/css/page_loader.scss'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const PageLoader = () => (
  <div className="page-loader-container d-flex">
    <div className="align-self-center w-100 rebote">
      <img src={logo} />
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
)

export default PageLoader
