import { combineReducers } from 'redux'

import AppReducer from './app.reducer'
import PWAReducer from './pwa.reducer'
import ModalReducer from './modal.reducer'
import LoaderReducer from './loader.reducer'
import StatusReducer from './status.reducer'
import ReportReducer from './report.reducer'
import ClientsReducer from './clients.reducer'
import BudgetsReducer from './budgets.reducer'
import ProfileReducer from './profile.reducer'
import CampaignReducer from './campana.reducer'
import CreativeReducer from './creative.reducer'
import AccountsReducer from './accounts.reducer'
import AudiencesReducer from './audiences.reducer'
import CampaignsReducer from './dashboard.reducer'
import CategoriesReducer from './categories.reducer'
import MediumBannerReducer from './medioCamp.reducer'
import CampaignupdReducer from './dashboardupd.reducer'
import ModalDetaillReducer from './modalDetail.reducer'
import PautaReducer from './objectCampainpauta.reducer'
import campaignsbudget from './dashboardBudget.reducer'
import fanpageDatosReducer from './fanpageDatos.reducer'
import ObjectCampainReducer from './objectCampain.reducer'
import fanpageAsociarReducer from './fanpageAsociar.reducer'
import fanpageMessageReducer from './fanpageMessage.reducer'
import fanpageDatosWebReducer from './fanpageWebHook.reducer'
import MediumBannertypeReducer from './mediumMedioCamp.reducer'
import usersReducer from './users.reducer'
import filesPautaReducer from './files.reducer'


const rootReducer = combineReducers({
  app: AppReducer,
  pauta: PautaReducer,
  modal: ModalReducer,
  loader: LoaderReducer,
  report: ReportReducer,
  status: StatusReducer,
  profile: ProfileReducer,
  budgets: BudgetsReducer,
  clients: ClientsReducer,
  accounts: AccountsReducer,
  users: usersReducer,
  creative: CreativeReducer,
  campaign: CampaignReducer,
  campaigns: CampaignsReducer,
  audiences: AudiencesReducer,
  categories: CategoriesReducer,
  campaignupd: CampaignupdReducer,
  objcampain: ObjectCampainReducer,
  campaignsbudget: campaignsbudget,
  ModalDetaill: ModalDetaillReducer,
  fanpageDatos: fanpageDatosReducer,
  fanpageDatos: fanpageDatosReducer,
  mediumbanners: MediumBannerReducer,
  fanpageAsociar: fanpageAsociarReducer,
  fanpageMensajes: fanpageMessageReducer,
  fanpageDatosWeb: fanpageDatosWebReducer,
  mediumbannerstype: MediumBannertypeReducer,
  pwa: PWAReducer,
  filesPautaReducer: filesPautaReducer,
})

export default rootReducer