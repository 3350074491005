import React, {useState, useEffect} from 'react'

import './asserts/styles.css'
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';

import { getLogoByName, getClassNameByStatus } from 'myUtil'
import * as APILI from '../../helpers/apiLI'

const TargetContentNetworks = (props) => {

    const listGeneralStatistics = props.listGeneralStatistics;
    const nameClass = props.nameClass;
    
    const {averageScope, averageInteraction, percentInteraction,rating, 
        setAverageScope, setAverageInteraction, setPercentInteraction,
        editAverageScope,setEditAverageScope,editAverageInteraction,
        setEditAverageInteraction, editPercentInteraction,setEditPercentInteraction,
        updateSocialScopeValues, influencerID} = props
    const [listNetworks, setListNetworks] = useState([])
    
        useEffect(() => {
            console.log("perfiles de usuario", influencerID)
            APILI.getProfilesByUser({"influencerid":influencerID}).then(res => {
              //  if(res.data.profile.length > 0){
                influencerProfiles(res.data.profile)
               // }
            }).catch(err => {
                console.log(err)
            })
        }, [influencerID])    

        const influencerProfiles = (data) => {
            setListNetworks(data)
        }
    const listItems = 
            <>   <div >
                   <div style={{display:"inline-block", cursor:"pointer"}} >
                       <p  onClick={() => setEditAverageScope(!editAverageScope)}>
                       Alcance Promedio &#160;
                       </p>
                     { !editAverageScope ? averageScope: <input type="number"
                     onBlur={(e)=>{
                         setAverageScope(e.target.value);
                     updateSocialScopeValues(e.target.value,'averageScope');
                     setEditAverageScope(!editAverageScope)
                     }}/>}
                        
                            </div>
                 </div>
                 <div >
                   <div style={{display:"inline-block", cursor:"pointer"}} >
                       <p  onClick={() => setEditAverageInteraction(!editAverageInteraction) }>
                       Interaccion Promedio &#160;
                       </p>
                     { !editAverageInteraction ? averageInteraction: <input type="number"
                     onBlur={(e)=>{
                         setAverageInteraction(e.target.value);
                         updateSocialScopeValues(e.target.value,'averageInteraction');
                         setEditAverageInteraction(!editAverageInteraction) 
                         }}/>}
                        
                            </div>
                 </div>
                 <div >
                   <div style={{display:"inline-block",cursor:"pointer"}} >
                       <p  onClick={() => setEditPercentInteraction(!editPercentInteraction) }>
                       Porcentaje de  Interacion &#160;
                       </p>
                     { !editPercentInteraction ?<p onClick={() => setEditPercentInteraction(!editPercentInteraction) }> {percentInteraction}&#37;</p>: <input type="number"
                     onBlur={(e)=>{setPercentInteraction(e.target.value); 
                     updateSocialScopeValues(e.target.value,'percentInteraction');
                     setEditPercentInteraction(!editPercentInteraction)}}/>}
                        
                            </div>
                 </div>
             </>

    const socialMedia = 
            <>
           { listNetworks.map((item, index) => {
               console.log(item.picture)
                return (
                    <div key={index}>
                        <div className="row">
                            <div className="col-md-2">
                                <img src={getLogoByName(item.socialmedia)} alt={item.socialMedia}/>
                            </div>
                            <div className="col-md-6">
                              
                                        <p>@{item.userprofile}</p>
                                        <p>{item.name}</p>
                                    </div>
                                    <div className="col-md-2">
                                <img src={item.image} style={{width:50, borderRadius:50}} alt={item.picture}/>
                            </div>
                                </div>
                                
                            <div className="row">
                                <div className="col-md-12" >
                                    <p className={getClassNameByStatus(item.status)} style={{color:"white", textAlign:"center"}}>{item.status}</p>
                                </div>
                            </div>
                                <Divider/>
                            </div>
                )
            })
            }
            </>
    
    return (
        <div className={nameClass}>
            <div className="marginTarget">
                    {listItems}
                    {socialMedia}
            </div>
        </div>
    )

}

export default TargetContentNetworks