import React, {useState, useEffect} from 'react';
import "react-google-flight-datepicker/dist/main.css";


import InputModal from './inputModal';
import * as APILI from 'helpers/apiLI';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import logo from '../galleryPost/icons/logo-likeinc.png'
import './modal.scss'



const CommentSubTask = ({ userRol, showModal,setShowModal2, _showAlertModal, currentComment, refresh, setRefresh}) => {
    
    console.log("comentario", currentComment)
    const [addComments, setAddComments] = useState('')
    
    const updateComment = async () => {
        const body = {
          id: currentComment.data.id,
          comment: addComments,
        };

        if (!addComments) {
            Swal.fire({
                title: 'Error',
                icon: 'error',
                text: 'Agregue un comentario',
                type: 'error',
                confirmButtonText: 'Ok'
            })
            return
        } else {
            APILI.updateSubTaskComment(body)
                .then((res) => {
                setRefresh(!refresh);
            })
            .catch((err) => {
                console.log(err);
            });
            Swal.fire({
                title: 'Guardado',
                icon: 'success',
                text: 'Guardado correctamente',
                confirmButtonText: 'Ok'

            })
            cleanCustomTask()

        }
        
    };

    

    const cleanCustomTask = () => {
        setShowModal2(false)
        setAddComments('')
    }
    
    return (
        <div className="blur-div">
            <div className="content-modal modalFather">
                <div className="closeModal-content">
                    <i className="fas fa-times-circle" onClick={e => setShowModal2(false)}></i>
                </div>
                <div>
                    <h3 className="titleModal">{currentComment.data.activity}</h3>
                    <p>Agregue o Actualice el comentario</p>
                </div>

                <div className="body-modal bodyModalComment">
                    <textarea
                        placeholder='Ingrese sus comentarios'
                        value={addComments}
                        onChange={(e) => setAddComments(e.target.value)}
                    >
                    </textarea>
                    <textarea disabled>
                        {currentComment.data.comment}
                    </textarea>
                </div>

                <div className="footer-Modal">
                    <div className="buttons-Modal-content">
                        <button onClick={e => setShowModal2(false)}>Cancelar</button>
                        <button onClick={e => updateComment()} >Guardar</button>
                    </div>
                </div>
            </div>
        </div>

    )
}



export default CommentSubTask