import React from 'react'

//Components
import Loader from '../../components/loader'
import Modal from '../../components/modal'

// pages
import SignIn from './signin'
import SignUp from './signup'
import Register from './register'
import ConfirmSignUp from './confirm_signup'
import  PasswordRecovery from './password_recovery'
import ConfirmPassword from './confirm_new_password'
const Auth = props => {
  const validAuthStates = ['signIn', 'signUp', 'signedOut', 'signedUp', 'confirmSignUp', 'forgotPassword', 'register']
  const validLoginStates = ['signIn', 'signedOut', 'signedUp']
  const validSignUpStates = ['signUp']
  const register = ['register']

  const forgotPassword = ['forgotPassword']
  const requireNewPassword = ['requireNewPassword']

  const validConfirmSignUpStates = ['confirmSignUp']

 /*  console.log("ya viene con ",props.authState) */
  return (
    <>
      {validLoginStates.includes(props.authState) && <SignIn {...props} />}
      {validSignUpStates.includes(props.authState) && <SignUp {...props} />}
      {register.includes(props.authState) && <Register {...props} />}

      {forgotPassword.includes(props.authState) && <PasswordRecovery {...props} />}
      {requireNewPassword.includes(props.authState) && <ConfirmPassword {...props} />}
      
      {validConfirmSignUpStates.includes(props.authState) && <ConfirmSignUp {...props} />}

      <Loader />
      <Modal />
    </>
  )
}

export default Auth
