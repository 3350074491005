import React, { useState } from "react";
import { Link } from "react-router-dom";
import './style/style.css'
// components
import Asociar from 'components/fanpageAsociar'
import MetaTags from 'components/metatags'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import FacebookLogin from "react-facebook-login";
import Facebook from "./Facebook";
const AsociarPage = () => (
    <>
      <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
     
        },
      }}
    >

      <Paper className="m-20 p-5" elevation={3} >
      <MetaTags title='Asociar' description='Asociar páginas' />
        <Asociar className="asociar" />
        

          </Paper>
    </Box>
       
    </>
)

export default AsociarPage
