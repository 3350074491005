import React from 'react'
import Map from '../map'
import './asserts/styles.css'

const TargetContentMap = (props) => {

    const {markers} =props
    const nameClass = props.nameClass;
    console.log("targetcontentmap",markers)
    

    return (
        <div className={nameClass}>
            <div className="marginTarget">
                   <Map markers={markers}/>
            </div>
        </div>
    )

}

export default TargetContentMap