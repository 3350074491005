import React from 'react';

import './asserts/css/styles.css';

const InfoBox = (props) => {

    return (
        <div className="rectangle" >
            <p className="title">{props.title}</p>
            <p className="number">{props.number}</p>
            <p className="description">{props.description}</p>
        </div>
    )
}

export default InfoBox