import * as API from 'helpers/api'
import * as Logger from 'utils/logger'
import * as Modal from './modal.actions'
import { showLoader } from './loader.actions'

export const FB_APPS = 'FACEBOOK:APPS'
export const FB_PROP = 'FACEBOOK:PROP'
export const FB_USERS = 'FACEBOOK:USERS'

const TAG = 'FACEBOOK:ACTION'

export const getAppsu = () => dispatch => {
    dispatch(showLoader(true))
    API.getApps()
        .then(response => {
            Logger.log(TAG, 'getApps', response)

            dispatch({
                type: FB_APPS,
                apps: response.data
            })
        })
        .catch(err => {
            Logger.log(TAG, 'getApps', err)
        })
        .then(() => {
            dispatch(showLoader(false))
        })
}

export const setPropu = prop => dispatch => {
    dispatch({
        type: FB_PROP,
        prop,
    })
}

async function userDataGet(userToken){
    const profileData =  await fetch(`https://graph.facebook.com/me?fields=picture,name,id&access_token=${userToken}`)
    console.log("profileeeeeeedata::::::::",profileData)
}
export const getFbuser = (userToken) => (dispatch, getState) => {
    dispatch(showLoader(true))
    userDataGet(userToken)
    const {fanpageAsociar} = getState()
    API.getUserdata(fanpageAsociar.appId, userToken)
        .then(response => {
            
            Logger.log(TAG, 'getUserdata', response)
            console.log("respuesta", response.data)
            dispatch({
                type: FB_USERS,
                fbdata: response.data
            })
        })
        .catch(err => {
            Logger.log(TAG, 'getUserdata', err)
        })
        .then(() => {
            dispatch(showLoader(false))
        })
          
}

