import React from 'react';

// components
import CampaignInfo from 'components/campaign/info'

const Info = (props) =>{ 
    const {userRol} = props;
    return (
      <>
      {userRol==='admin'?
      <CampaignInfo campaign={props.campaign} />:
      <h1>No tienes privilegios</h1>
      }
      </>
      )  
    };

export default Info;