import React, { useState } from 'react'
import classnames from 'classnames'
import MultiselectTwoSides from 'react-multiselect-two-sides'

import './assets/css/styles.scss'

const MultiSelect = (props) => {
  const [value, setValue] = useState(props.value)

  const handleChange = (value) => {
    setValue(value)
    if (props.onChange) props.onChange(value)
  }

  return (
    <MultiselectTwoSides
      className={classnames('msts_theme_example', props.className)}
      onChange={handleChange}
      availableHeader='Disponibles'
      selectedHeader='Selecionados'
      labelKey={props.labelKey}
      options={props.options}
      value={value}
      showControls
    />
  )
}

export default MultiSelect
