import React from 'react'

const fileType = name => name.substring(name.lastIndexOf('.') + 1, name.length) || name

const fileSize = (size) => {
  if (size === 0) return '0 Bytes'
  const k = 1024
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  const i = Math.floor(Math.log(size) / Math.log(k))
  return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
}

const FileList = props => (
  <div className="file-display-container">
    {
      props.files.map((file, i) =>
        <div key={i} className="file-status-bar">
          <div className="d-flex align-items-center">
            <div className="file-type-logo"></div>
            <div className="file-type">
              {fileType(file.name)}
            </div>
            <span className="file-name">
              {file.name}
            </span>
            <span className="file-size">
              ({fileSize(file.size)})
            </span>
          </div>

          <div className="file-remove" onClick={() => props.onRemove(file.name)}>
            <i className="fas fa-times"></i>
          </div>
        </div>
      )
    }
  </div>
)

export default FileList